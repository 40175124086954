import { getTypedCallableFn } from "~/firebase";
import {
  switchMap,
  of,
  shareReplay,
  combineLatest,
  map,
  distinctUntilChanged,
  Observable,
  firstValueFrom,
  tap,
} from "rxjs";
import { collectionData, docData } from "~/utils/rxFireWrappers";
import { collectionRef, docRef } from "~/firestore.service";
import {
  IUserDoc,
  WithLocalData,
  IChannelGroupDoc,
  IOrganizationDoc,
  IOrganizationMemberDoc,
  IChannelDoc,
} from "@libs/firestore-models";
import {
  ASSERT_CURRENT_USER$,
  ASSERT_CURRENT_USER_ID$,
  catchNoCurrentUserError,
} from "./user.service";
import { useObservable } from "~/utils/useObservable";
import { isEqual } from "@libs/utils/isEqual";
import { stringComparer } from "~/utils/comparers";
import { limit, orderBy, query, where, getDocs } from "firebase/firestore";
import { uniqBy } from "lodash-es";
import {
  checkValueMatchesType,
  SetNonNullable,
} from "@libs/utils/type-helpers";
import { removeOneFromArray } from "@libs/utils/array-utils";

export const sendOrganizationInvite = getTypedCallableFn(
  "organizationmemberinvite",
);

export const createChannelGroup = getTypedCallableFn("channelgroupcreate");
export const updateChannelGroup = getTypedCallableFn("channelgroupupdate");

export const USER_OWNER_ORGANIZATION$ = ASSERT_CURRENT_USER$.pipe(
  map((u) => u.organizationId),
  distinctUntilChanged(),
  switchMap((orgId) => {
    if (!orgId) return of(null);

    return docData(docRef("organizations", orgId)).pipe(map((o) => o || null));
  }),
  catchNoCurrentUserError(() => null as IOrganizationDoc | null),
  distinctUntilChanged(isEqual),
  shareReplay(1),
);

export function useUserOwnerOrganization() {
  return useObservable(() => USER_OWNER_ORGANIZATION$);
}

export type IOrganizationDocWithCurrentUserData = WithLocalData<
  IOrganizationDoc,
  "IOrganizationDoc",
  {
    fromCurrentUser: IUserDoc["channelPermissions"][string];
  }
>;

export const USER_ORGANIZATIONS$ = ASSERT_CURRENT_USER$.pipe(
  map((u) => Object.entries(u.organizationPermissions)),
  tap((organizationPermissions) => {
    console.debug("USER_ORGANIZATIONS$ 1", { organizationPermissions });
  }),
  distinctUntilChanged(isEqual),
  switchMap((organizationPermissions) => {
    console.debug("USER_ORGANIZATIONS$ 2", { organizationPermissions });
    if (organizationPermissions.length === 0) return of([]);

    return combineLatest(
      organizationPermissions.map(([organizationId, permissionData]) => {
        console.debug("USER_ORGANIZATIONS$ 2.1", "requesting organization...", {
          organizationId,
          permissionData,
        });

        return docData(docRef("organizations", organizationId)).pipe(
          tap((organization) => {
            console.debug("USER_ORGANIZATIONS$ 2.2", {
              organizationId,
              permissionData,
              organization,
            });
          }),
          map((organization) => {
            return {
              ...organization,
              __docType: "IOrganizationDoc",
              __local: {
                fromCurrentUser: permissionData,
              },
            } as IOrganizationDocWithCurrentUserData;
          }),
        );
      }),
    );
  }),
  tap((organizations) => {
    console.debug("USER_ORGANIZATIONS$ 3", { organizations });
  }),
  catchNoCurrentUserError(() => []),
  distinctUntilChanged(isEqual),
  shareReplay(1),
);

export type IAcceptedOrganizationMemberDoc = WithLocalData<
  SetNonNullable<IOrganizationMemberDoc, "user" | "acceptedAt"> & {
    accepted: true;
    removed: false;
    removedAt: null;
  },
  "IOrganizationMemberDoc",
  {}
>;

/**
 * Technically, this is an observable of all the _accepted_
 * members of a users' organizations.
 *
 * TODO: this won't scale
 */
export const ALL_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$: Observable<
  IAcceptedOrganizationMemberDoc[]
> = ASSERT_CURRENT_USER_ID$.pipe(
  switchMap(() => USER_ORGANIZATIONS$),
  switchMap((organizations) => {
    if (organizations.length === 0) return of([]);

    return combineLatest(
      organizations.map(({ id }) =>
        collectionData(
          query(
            collectionRef<
              SetNonNullable<
                IAcceptedOrganizationMemberDoc,
                "user" | "acceptedAt"
              > & {
                accepted: true;
                removed: false;
                removedAt: null;
              }
            >("organizations", id, "organizationMemberships"),
            where("accepted", "==", true),
            where("removed", "==", false),
            orderBy("user.name", "asc"),
            orderBy("id", "asc"),
          ),
        ),
      ),
    );
  }),
  map((groupedMembers) => uniqBy(groupedMembers.flat(), "id")),
  catchNoCurrentUserError(() => []),
  distinctUntilChanged(isEqual),
  map((members) =>
    members
      .sort(
        (a, b) =>
          stringComparer(a.user.name, b.user.name) ||
          stringComparer(a.id, b.id),
      )
      .map((m) => ({
        ...m,
        __docType: "IOrganizationMemberDoc" as const,
        __local: {},
      })),
  ),
  shareReplay(1),
);

/**
 * Does not include the current user.
 * Technically, this is an observable of all the _accepted_
 * members of a users' organizations.
 */
export const ALL_OTHER_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$: Observable<
  IAcceptedOrganizationMemberDoc[]
> = combineLatest([
  ASSERT_CURRENT_USER_ID$,
  ALL_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$,
]).pipe(
  map(([currentUserId, uniqueMembers]) =>
    removeOneFromArray(uniqueMembers, (m) => m.id === currentUserId),
  ),
  catchNoCurrentUserError(() => []),
  shareReplay(1),
);

type IChannelGroupDocWithCurrentUserData = WithLocalData<
  IChannelGroupDoc,
  "IChannelGroupDoc",
  {
    fromOrganization: IOrganizationDocWithCurrentUserData;
  }
>;

export const USER_CHANNEL_GROUPS$ = USER_ORGANIZATIONS$.pipe(
  switchMap((organizations) => {
    return combineLatest(
      organizations.map((organization) =>
        collectionData(
          collectionRef("organizations", organization.id, "channelGroups"),
        ).pipe(
          map((channelGroups) =>
            channelGroups.map((channelGroup) => {
              return {
                ...channelGroup,
                __docType: "IChannelGroupDoc",
                __local: {
                  fromOrganization: organization,
                },
              } as IChannelGroupDocWithCurrentUserData;
            }),
          ),
        ),
      ),
    );
  }),
  map((channelGroups) => channelGroups.flat()),
  catchNoCurrentUserError(() => []),
  distinctUntilChanged(isEqual),
  shareReplay(1),
);

export function useOrganizations() {
  return useObservable(() => USER_ORGANIZATIONS$, {
    initialValue: [] as IOrganizationDocWithCurrentUserData[],
  });
}

export function useOrganization(organizationId?: string) {
  return useObservable(
    () => {
      if (!organizationId) return of(null);
      return USER_ORGANIZATIONS$.pipe(
        map(
          (organizations) =>
            organizations.find((w) => w.id === organizationId) || null,
        ),
        distinctUntilChanged(isEqual),
      );
    },
    {
      deps: [organizationId],
    },
  );
}

export function observeOrganizationMembers(organizationId: string) {
  return collectionData(
    query(
      collectionRef<
        SetNonNullable<IOrganizationMemberDoc, "user" | "acceptedAt"> & {
          accepted: true;
          removed: false;
          removedAt: null;
        }
      >("organizations", organizationId, "organizationMemberships"),
      where("accepted", "==", true),
      where("removed", "==", false),
      orderBy("user.name", "asc"),
      orderBy("id", "asc"),
    ),
  ).pipe(
    distinctUntilChanged(isEqual),
    map((acceptedMembers) =>
      acceptedMembers.map((m) => {
        return checkValueMatchesType<IAcceptedOrganizationMemberDoc>({
          ...m,
          __docType: "IOrganizationMemberDoc" as const,
          __local: {},
        });
      }),
    ),
  );
}

export function useOrganizationMembers(organizationId?: string) {
  return useObservable(
    () => {
      if (!organizationId) return of([]);
      return observeOrganizationMembers(organizationId);
    },
    {
      deps: [organizationId],
    },
  );
}

export function observeOrganizationMember(
  organizationId: string,
  userId: string,
) {
  return docData(
    docRef("organizations", organizationId, "organizationMemberships", userId),
  ).pipe(
    distinctUntilChanged(isEqual),
    map(
      (member) =>
        member && {
          ...member,
          __docType: "IOrganizationMemberDoc" as const,
          __local: {},
        },
    ),
  );
}

export function getOrganizationMember(organizationId: string, userId: string) {
  return firstValueFrom(observeOrganizationMember(organizationId, userId));
}

export async function getOrganizationMemberByEmail(
  organizationId: string,
  email: string,
) {
  const snaps = await getDocs(
    query(
      collectionRef("organizations", organizationId, "organizationMemberships"),
      where("lowercaseEmail", "==", email.toLowerCase()),
      limit(1),
    ),
  );

  return snaps.docs[0]?.data() || null;
}

export type IInvitedOrganizationMemberDoc = WithLocalData<
  IOrganizationMemberDoc & {
    user: null;
    removed: false;
    removedAt: null;
    accepted: false;
    acceptedAt: null;
  },
  "IOrganizationMemberDoc",
  {}
>;

export function useInvitedOrganizationMembers(organizationId?: string) {
  return useObservable(
    () => {
      if (!organizationId) return of([]);

      return collectionData(
        query(
          collectionRef<
            IOrganizationMemberDoc & {
              user: null;
              removed: false;
              removedAt: null;
              accepted: false;
              acceptedAt: null;
            }
          >("organizations", organizationId, "organizationMemberships"),
          where("accepted", "==", false),
          where("removed", "==", false),
          orderBy("id", "asc"),
        ),
      ).pipe(
        distinctUntilChanged(isEqual),
        map((invitedMembers) =>
          invitedMembers.map((m) => {
            return checkValueMatchesType<IInvitedOrganizationMemberDoc>({
              ...m,
              __docType: "IOrganizationMemberDoc" as const,
              __local: {},
            });
          }),
        ),
      );
    },
    {
      deps: [organizationId],
    },
  );
}

export function observePinnedChannelGroupsForOrganization(
  organizationId: string,
) {
  return ASSERT_CURRENT_USER_ID$.pipe(
    switchMap((currentUserId) => {
      return collectionData(
        query(
          collectionRef("users", currentUserId, "subscriptions"),
          where("type", "==", "channel"),
          where("isPinned", "==", true),
        ),
      );
    }),
    switchMap((subscriptions) => {
      if (subscriptions.length === 0) return of([]);

      return combineLatest(
        subscriptions.map((sub) => docData(docRef("channels", sub.id))),
      );
    }),
    map((channels) =>
      Array.from(
        new Set(
          channels
            .filter(
              (c): c is IChannelDoc => c?.organizationId === organizationId,
            )
            .flatMap((c) => c.channelGroupIds),
        ),
      ),
    ),
    distinctUntilChanged(isEqual),
    switchMap((channelGroupIds) => {
      if (channelGroupIds.length === 0) return of([]);

      return combineLatest([
        USER_OWNER_ORGANIZATION$,
        ...channelGroupIds.map((id) =>
          docData(docRef("organizations", organizationId, "channelGroups", id)),
        ),
      ]);
    }),
    map(([organization, ...channelGroups]) => {
      if (!organization) return [];

      return (channelGroups as IChannelGroupDoc[]).slice().sort((a, b) => {
        if (organization.defaultChannelGroupId === a.id) {
          return -1;
        } else if (organization.defaultChannelGroupId === b.id) {
          return 1;
        } else {
          return stringComparer(a.name, b.name) || stringComparer(a.id, b.id);
        }
      });
    }),
    catchNoCurrentUserError(() => []),
    distinctUntilChanged(isEqual),
    shareReplay(1),
  );
}

/**
 * The first channelGroup is the organization's default channelGroup.
 * Then they are in alphabetical order.
 */
export function usePinnedChannelGroupsForOrganization(
  organizationId?: string,
): IChannelGroupDoc[] | undefined {
  return useObservable(() => {
    if (!organizationId) return of([]);
    return observePinnedChannelGroupsForOrganization(organizationId);
  });
}

export function useChannelGroups() {
  return useObservable(() => USER_CHANNEL_GROUPS$, {
    initialValue: [] as IChannelGroupDocWithCurrentUserData[],
  });
}

export function useChannelGroup(channelGroupId?: string) {
  return useObservable(
    () => {
      if (!channelGroupId) return of(null);
      return USER_CHANNEL_GROUPS$.pipe(
        map(
          (channelGroups) =>
            channelGroups.find((w) => w.id === channelGroupId) || null,
        ),
        distinctUntilChanged(isEqual),
      );
    },
    {
      deps: [channelGroupId],
    },
  );
}
