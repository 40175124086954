import { unified } from "unified";
import rehypeParse from "rehype-parse";
import rehypeReact, { type Options as IRehypeReactOptions } from "rehype-react";
import rehypeStringify from "rehype-stringify";
import { visit } from "unist-util-visit";
import { toString, Node } from "hast-util-to-string";
import { lowlight, Root } from "lowlight";
import { Fragment, useState, createElement, useEffect, ReactNode } from "react";

export type IUseConvertHTMLToReactComponentOptions = (IRehypeReactOptions & {
  passNode: false;
})["components"];

/**
 * This hook accepts an HTML string and converts it to a React
 * component tree. The primary use case for this is to replace standard
 * anchor tags with react-router Link components.
 */
export function useConvertHTMLToReact(
  text: string,
  components?: IUseConvertHTMLToReactComponentOptions,
) {
  const [Content, setContent] = useState<ReactNode>(() => Fragment);

  useEffect(() => {
    convertHTMLToReact(text, components).then(setContent);
  }, [text, components]);

  return Content;
}

export function convertHTMLToReact(
  html: string,
  components?: IUseConvertHTMLToReactComponentOptions,
) {
  return unified()
    .use(rehypeParse, { fragment: true })
    .use(rehypeReact, {
      createElement,
      Fragment,
      components,
      fixTableCellAlign: false,
    })
    .process(html)
    .then((file) => file.result);
}

export async function parsePostHTML(html: string) {
  const file = await unified()
    .use(rehypeParse)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .use(rehypeLowlight as any)
    .use(rehypeStringify)
    .process(html);

  return file.toString();
}

/**
 * This "unified" plugin was built after consulting this example
 * in the "starry night" package:
 * https://github.com/wooorm/starry-night#example-integrate-with-unified-remark-and-rehype
 *
 * Even though we are using "lowlight", the code is very
 * similar.
 */

function rehypeLowlight() {
  const prefix = "language-";

  return function (tree: Node) {
    visit(tree, "element", function (node, index, parent) {
      if (!parent || index === null || node.tagName !== "pre") {
        return;
      }

      const head = node.children[0];

      if (
        !head ||
        head.type !== "element" ||
        head.tagName !== "code" ||
        !head.properties
      ) {
        return;
      }

      const classes = head.properties.className;

      const language = !Array.isArray(classes)
        ? undefined
        : classes
            .find(
              (d): d is string => typeof d === "string" && d.startsWith(prefix),
            )
            ?.slice(prefix.length);

      let fragment: Root;

      try {
        fragment = language
          ? lowlight.highlight(language, toString(head))
          : lowlight.highlightAuto(toString(head));
      } catch (e) {
        console.debug(
          `Error parsing codeblock with lowlight for language ${language}.`,
          e,
        );

        return;
      }

      parent.children.splice(index, 1, {
        type: "element",
        tagName: "pre",
        properties: {},
        children: [
          {
            type: "element",
            tagName: "code",
            properties: {
              className: language ? ["language-" + language] : [],
            },
            children: fragment.children,
          },
        ],
      });
    });
  };
}
