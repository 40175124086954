import Mention from "@tiptap/extension-mention";
import { mergeAttributes } from "@tiptap/react";
import { firstValueFrom, map, shareReplay } from "rxjs";
import { PluginKey } from "@tiptap/pm/state";
import {
  ALL_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$,
  IAcceptedOrganizationMemberDoc,
} from "~/services/organization.service";
import { UnreachableCaseError } from "@libs/utils/errors";
import {
  IChannelDocWithCurrentUserData,
  USER_NON_ORG_SHARED_CHANNELS$,
} from "~/services/channels.service";
import {
  applyAdditionalMentionSuggestionWeights,
  fuzzyMatchChannels,
  fuzzyMatchMembers,
} from "~/form-components/tiptap/suggestion-utils";
import {
  getUserOrChannelSuggestionRenderFn,
  TChannelSuggestion,
  TPersonSuggestion,
  TUserOrChannelSuggestion,
} from "./filters/getUserOrChannelSuggestionRenderFn";
import { getAndAssertCurrentUser } from "~/services/user.service";
import { observeCurrentUserSetting } from "~/services/settings.service";
import { useEffect } from "react";
import commandScore from "command-score";
import { numberComparer, stringComparer } from "@libs/utils/comparers";

export const MentionLevel1 = buildMentionExtension("@");
export const MentionLevel2 = buildMentionExtension("@@");
export const MentionLevel3 = buildMentionExtension("@@@");

const MENTION_FREQUENCY_SETTING$ = observeCurrentUserSetting(
  "MentionFrequencySettings",
).pipe(shareReplay({ bufferSize: 1, refCount: true }));

const USERS_WHO_CAN_BE_MENTIONED$ =
  ALL_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$.pipe(
    map((members) => {
      return members.map((m) => {
        const currentUser = getAndAssertCurrentUser();

        if (currentUser.id === m.id) {
          return {
            ...m,
            user: {
              ...m.user,
              name: "me",
            },
          };
        }

        return m;
      });
    }),
  );

/**
 * Maintains proper subscriptions to make opening the mentions list
 * speedy. Also, since the mentions list queries using promises
 * we can only return a single value. Firestore often returns partial
 * results initially and then updates later with more results.
 * By maintaing a subscription here, we ensure that the user gets
 * better results.
 */
export function useMentionExtensionSubscriptions() {
  useEffect(() => {
    const sub = USERS_WHO_CAN_BE_MENTIONED$.subscribe();
    sub.add(USER_NON_ORG_SHARED_CHANNELS$.subscribe());
    sub.add(MENTION_FREQUENCY_SETTING$.subscribe());
    return () => sub.unsubscribe();
  }, []);
}

function buildMentionExtension(char: "@" | "@@" | "@@@") {
  const extensionId = `mention-${char || "all"}`;
  const priority = char === "@" ? 300 : char === "@@" ? 200 : 100;

  return Mention.extend({
    name: extensionId,
    addAttributes() {
      return {
        ...this.parent?.(),

        subject: {
          default: null,
          parseHTML: (element) => element.getAttribute("data-subject"),
          renderHTML: (attributes) => {
            if (!attributes.subject) {
              return {};
            }

            return {
              "data-subject": attributes.subject,
            };
          },
        },

        priority: {
          default: priority,
          renderHTML: () => {
            return {
              "data-priority": priority,
            };
          },
        },
      };
    },
    parseHTML() {
      return [
        {
          tag: `span[data-type="mention"][data-priority="${priority}"]`,
        },
      ];
    },
    renderHTML({ node, HTMLAttributes }) {
      return [
        "span",
        mergeAttributes(
          { "data-type": "mention", class: "search-filter" },
          this.options.HTMLAttributes,
          HTMLAttributes,
        ),
        this.options.renderLabel({
          options: this.options,
          node,
        }),
      ];
    },
    renderText({ node }) {
      const subject = node.attrs.subject as "user" | "channel";

      let prefix: string;

      switch (subject) {
        case "user": {
          prefix = char.split("").fill("@").join("");
          break;
        }
        case "channel": {
          prefix = char.split("").fill("#").join("");
          break;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }

      return `<#${subject}::${prefix}::${node.attrs.id}>`;
    },
  }).configure({
    renderLabel({ node }) {
      const subject = node.attrs.subject as "user" | "channel";

      let prefix: string;

      switch (subject) {
        case "user": {
          prefix = char.split("").fill("@").join("");
          break;
        }
        case "channel": {
          prefix = char.split("").fill("#").join("");
          break;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }

      return `${prefix}${node.attrs.label ?? node.attrs.id}`;
    },
    suggestion: {
      pluginKey: new PluginKey(extensionId),
      char,
      items: async ({ query, editor }) => {
        const [members, channels, mentionFrequencySettingsDoc] =
          await Promise.all([
            firstValueFrom(USERS_WHO_CAN_BE_MENTIONED$),
            firstValueFrom(USER_NON_ORG_SHARED_CHANNELS$),
            firstValueFrom(MENTION_FREQUENCY_SETTING$),
          ]);

        const results = {
          people: [] as IAcceptedOrganizationMemberDoc[],
          channels: [] as IChannelDocWithCurrentUserData[],
        };

        const mentions = mentionFrequencySettingsDoc?.mentions || {};

        const mentionWeightAdjustments: Record<string, number> =
          editor.storage.mention?.mentionWeightAdjustments || {};

        const channelEntryScore = (
          c: IChannelDocWithCurrentUserData,
          query: string,
        ) => {
          const label =
            c.name +
            " " +
            c.__local.knownChannelGroups.map((g) => g.name).join(" ");

          let score = commandScore(label, query);

          if (score !== 0) {
            score = applyAdditionalMentionSuggestionWeights({
              score,
              id: c.id,
              frequencyDictionary: mentions,
              mentionWeightAdjustments: mentionWeightAdjustments,
            });
          }

          return score;
        };

        if (query.startsWith("#")) {
          results.channels = fuzzyMatchChannels({
            channels,
            query: query.slice(1),
            entryScore: channelEntryScore,
          });
        } else if (query) {
          results.people = fuzzyMatchMembers({
            members,
            query,
            entryScore: (m, query) => {
              const label = `${m.user.name} ${m.user.email}`;

              let score = commandScore(label, query);

              if (score !== 0) {
                score = applyAdditionalMentionSuggestionWeights({
                  score,
                  id: m.id,
                  frequencyDictionary: mentions,
                  mentionWeightAdjustments: mentionWeightAdjustments,
                });
              }

              return score;
            },
          });

          results.channels = fuzzyMatchChannels({
            channels,
            query,
            entryScore: channelEntryScore,
          });
        } else {
          results.people = members
            .map((member) => {
              return {
                member,
                score: applyAdditionalMentionSuggestionWeights({
                  score: 0,
                  id: member.id,
                  frequencyDictionary: mentions,
                  mentionWeightAdjustments,
                }),
              };
            })
            .sort(
              (a, b) =>
                numberComparer(b.score, a.score) ||
                stringComparer(a.member.user.name, b.member.user.name) ||
                stringComparer(a.member.id, b.member.id),
            )
            .slice(0, 5)
            .map((m) => m.member);

          results.channels = channels
            .map((channel) => {
              return {
                channel,
                score: applyAdditionalMentionSuggestionWeights({
                  score: 0,
                  id: channel.id,
                  frequencyDictionary: mentions,
                  mentionWeightAdjustments,
                }),
              };
            })
            .sort(
              (a, b) =>
                numberComparer(b.score, a.score) ||
                stringComparer(a.channel.name, b.channel.name) ||
                stringComparer(a.channel.id, b.channel.id),
            )
            .slice(0, 5)
            .map((m) => m.channel);
        }

        return [...results.people, ...results.channels].map((doc, index) => {
          switch (doc.__docType) {
            case "IChannelDoc": {
              return {
                ...doc,
                index,
                group: "Channels",
              } satisfies TChannelSuggestion;
            }
            case "IOrganizationMemberDoc": {
              return {
                __docType: "IUserFragment",
                id: doc.id,
                name: doc.user.name,
                photoURL: doc.user.photoURL,
                index,
                group: "People",
              } satisfies TPersonSuggestion;
            }
          }
        });
      },

      render: getUserOrChannelSuggestionRenderFn({
        getSuggestionAttributes(item: TUserOrChannelSuggestion) {
          return {
            id: item.id,
            label: item.name,
            subject: item.__docType === "IChannelDoc" ? "channel" : "user",
          };
        },
      }),
    },
  });
}
