import { ComponentType } from "react";
import * as ThreadLayout from "~/page-layouts/thread-layout";
import { PLATFORM_MODIFIER_KEY } from "~/services/command.service";

export const SearchSidebar: ComponentType<{}> = () => {
  return (
    <ThreadLayout.InfoPanel>
      <div className="prose mt-6 mb-20 mx-4">
        <h2>Search Guide</h2>

        <p>
          <em>
            This sidebar tries to explain how you can use Comms' search
            features. Note that, inside the search input, Control+Space will
            open up the filter suggestions list. Filter suggestions will also
            automatically appear as you type.
          </em>
        </p>

        <h2 className="mt-6">Basic Search</h2>

        <p>
          To peform a basic search, select the search input box (hotkey{" "}
          <code>/</code>), enter some text, and press{" "}
          <code>{PLATFORM_MODIFIER_KEY.name}+Enter</code> to perform the search
          (or press the "Submit" button). For convenience, you can format your
          search over multiple lines like you are writing a paragraph. This does
          not affect the search results (i.e. you can break your search up
          across multiple lines or cram it all into one line—it doesn't matter).
        </p>

        <p></p>

        <p>
          If you just search this way, then your search query will be matched
          against the subject and body text of messages (and only the subject
          and body text of messages). This is the most common way of searching.
        </p>

        <ExampleQuery
          description={`
            find all messages which either contain the phrase "what are you doing today?"
            in the subject line or in the body text.
          `}
        >
          what <br />
          are you <br />
          doing today?
        </ExampleQuery>

        <h2 className="mt-6">Available Filters</h2>

        <p>
          For more control over your search, you'll want to take advantage of
          the available search filters. An example of a search filter is{" "}
          <code>from: @John Carroll</code> which limits results to messages sent
          by the user <code>@John Carroll</code>. Search filters can be combined
          together (e.g. <code>from: @Josh Clemente to: @Sam Corcos</code>).
          Messages returned by a search must match ALL of the provided search
          criteria.
        </p>

        <p></p>

        <p>
          A list of all the available filters is provided below. Note: some
          filters allow providing multiple values seperated by <code>,</code>.
          If you use this feature in a search query, then a message will need to
          satisfy <em>all</em> the provided conditions in order to match. If
          you'd like a message to match <em>any</em> of the provided conditions
          (rather than <em>all</em> of them), then you'll need to use the{" "}
          <code>or()</code> filter (explained below). Using a comma to provide
          multiple values to a filter is the same as providing the filter
          multiple times with the same value (i.e. "to: John, Sam" is the same
          as "to: John to: Sam").
        </p>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="plain text">
          <p>
            If you just type some text into the search bar (i.e. text not
            associated with one of the filters below), then that text will be
            matched against the subject and body text of messages (and only the
            subject and body text of messages). This is the most common way of
            searching.
          </p>

          <ExampleQuery
            description={`
              this query finds all messages that were sent to both Sam and Josh
              and which contain the phrase "my weekly update R&D projects" in the
              subject and/or body text of the message. It returns the threads
              associated with these messages.
            `}
          >
            my weekly update to: <ExampleMention label="@Sam" /> ,{" "}
            <ExampleMention label="@Josh" /> R&D projects
          </ExampleQuery>

          <p className="mt-4">
            Another way of writing the example above would be the following
          </p>

          <ExampleQuery
            description={`
              this query is the same as the previous example.
            `}
          >
            my weekly update R&D projects to: <ExampleMention label="@Sam" />{" "}
            to: <ExampleMention label="@Josh" />
          </ExampleQuery>
        </H3HelpSection>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="from:">
          <p>
            Find messages sent by a specific person. The <code>from:</code>{" "}
            filter expects you to type <code>from:</code>, then a space, then
            @mention someone. You can also provide a plain text value, in which
            case the provided value will be matched against the person's name
            (e.g. "from: John" will find messages sent by users who have the
            phrase "John" in their name).
          </p>

          <ExampleQuery
            description={`
              find all messages sent by you. Return the associated threads.
            `}
          >
            from: <ExampleMention label="@me" />
          </ExampleQuery>
        </H3HelpSection>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="to:">
          <p>
            Find messages sent to a specific person or people. The{" "}
            <code>to:</code> filter expects you to type <code>to:</code>, then a
            space, then @mention someone. You can include multiple @mentions if
            you seperate them with a <code>,</code> when using <code>to:</code>.
            You can also provide a plain text value, in which case the provided
            value will be matched against the person's name.
          </p>

          <ExampleQuery
            description={`
              find all messages sent to Josh Clemente and also Miz and also 
              yourself. Return the associated threads.
            `}
          >
            to: <ExampleMention label="@Josh Clemente" /> ,{" "}
            <ExampleMention label="@Miz" /> , <ExampleMention label="@me" />
          </ExampleQuery>
        </H3HelpSection>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="channel:">
          <p>
            Find threads which are in a specific channel. The{" "}
            <code>channel:</code> filter expects you to type{" "}
            <code>channel:</code>, then a space, then @mention a channel. You
            can include multiple channel @mentions if you seperate them with a{" "}
            <code>,</code> when using <code>channel:</code>. You can also
            provide a number value such as <code>channels: 2</code>. In this
            case, threads which are in exactly two channels will be returned.
            You can also provide a plain text value, in which case the provided
            value will be matched against the channel's name.
          </p>

          <ExampleQuery
            description={`
              find all threads which are in exactly 3 channels. Two of these three 
              channels must be the "Daily Updates" and "General" channels.
            `}
          >
            channel: 3 channel: <ExampleMention label="#Daily Updates" /> ,{" "}
            <ExampleMention label="#General" />
          </ExampleQuery>
        </H3HelpSection>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="subject:">
          <p>
            Find threads with subjects containing the provided text. The{" "}
            <code>subject:</code> filter expects you to provide text surrounded
            in quotes as shown in the example below. If the text isn't in
            quotes, then only the word immediately following{" "}
            <code>subject:</code> will be used for filtering.
          </p>

          <ExampleQuery
            description={`
              find all threads with both "project update" and "redesign" in the subject.
            `}
          >
            subject: "project update" subject: "redesign"
          </ExampleQuery>
        </H3HelpSection>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="body:">
          <p>
            Find threads with body content containing the provided text. The{" "}
            <code>body:</code> filter expects you to provide text surrounded in
            quotes as shown in the example below. If the text isn't in quotes,
            then only the word immediately following <code>body:</code> will be
            used for filtering.
          </p>

          <ExampleQuery
            description={`
              find all messages with "Josh's R&D update" in the body text.
              Return the associated threads.
            `}
          >
            body: "Josh's R&D update"
          </ExampleQuery>
        </H3HelpSection>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="after:">
          <p>
            Find messages sent on or after a specific date. The{" "}
            <code>after:</code> filter expects you to provide dates in the
            format YYYY/M/D.
          </p>

          <ExampleQuery
            description={`
              find all messages sent on or after March 17th, 2023. 
              Return the associated threads.
            `}
          >
            after: 2023/3/17
          </ExampleQuery>
        </H3HelpSection>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="before:">
          <p>
            Find messages sent before a specific date. The <code>before:</code>{" "}
            filter expects you to provide dates in the format YYYY/M/D.
          </p>

          <ExampleQuery
            description={`
              find all messages sent before March 17th, 2023. 
              Return the associated threads.
            `}
          >
            before: 2023/3/17
          </ExampleQuery>
        </H3HelpSection>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="mentions:">
          <p>
            Find messages which mention a specific person or people. The{" "}
            <code>mentions:</code> filter expects you to type{" "}
            <code>mentions:</code>, then a space, then @mention someone. You can
            include multiple @mentions if you seperate them with a{" "}
            <code>,</code> when using the <code>mentions:</code> filter. You can
            also provide a plain text value, in which case the provided value
            will be matched against the person's name.
          </p>

          <ExampleQuery
            description={`
              find all messages that mention Sam Corcos. Return the 
              associated threads.
            `}
          >
            mentions: <ExampleMention label="@Sam Corcos" />
          </ExampleQuery>
        </H3HelpSection>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="priority:">
          <p>
            Find threads which have a specific priority in your inbox. This
            filter is similar to filtering for messages which{" "}
            <code>@@@mention</code> or
            <code>@@mention</code>, etc, you. An important difference is that a
            thread only has a single inbox priority level and that priority
            level changes as the thread is updated in your inbox. The{" "}
            <code>priority:</code> filter expects you to type{" "}
            <code>priority:</code>, then a space, then "@@@" or "@@" or "@" or
            "participating" or "subscriber".
          </p>

          <ExampleQuery
            description={`
              find all threads with @@@mention priority in your inbox.
            `}
          >
            priority: @@@
          </ExampleQuery>
        </H3HelpSection>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="participating:">
          <p>
            Find threads in which a specific person or people are currently
            participating. The <code>participating:</code> filter expects you to
            type <code>participating:</code>, then a space, then @mention
            someone. You can include multiple @mentions if you seperate them
            with a <code>,</code> when using the <code>participating:</code>{" "}
            filter. You can also provide a number value such as{" "}
            <code>participating: 2</code>. In this case, threads will be
            returned which <em>only</em> have exactly two participants. You can
            also provide a plain text value, in which case the provided value
            will be matched against the person's name.
          </p>

          <ExampleQuery
            description={`
              find all messages that Josh Clemente is currently participating
              in. Return the associated threads.
            `}
          >
            participating: <ExampleMention label="@Josh Clemente" />
          </ExampleQuery>
        </H3HelpSection>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="has:">
          <p>
            Find messages which "have" something. At the moment, the only
            accepted value for the
            <code>has:</code> filter is "reminder". Filtering for{" "}
            <code>has: reminder</code> will return threads which have a reminder
            set.
          </p>

          <ExampleQuery
            description={`
              find all threads which have a reminder set.
            `}
          >
            has:reminder
          </ExampleQuery>
        </H3HelpSection>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="remind-after:">
          <p>Find messages sent to a specific person or people.</p>

          <ExampleQuery
            description={`
              find all threads which have a reminder set for on or after
              May 20th, 2023.
            `}
          >
            remind-after: 2023/5/20
          </ExampleQuery>
        </H3HelpSection>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="remind-before:">
          <p>Find messages sent to a specific person or people.</p>

          <ExampleQuery
            description={`
              find all threads which have a reminder set for before
              May 20th, 2023.
            `}
          >
            remind-before: 2023/5/20
          </ExampleQuery>
        </H3HelpSection>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="is:">
          <p>
            Find messages satisfying a specific condition. At the moment, the
            only accepted values for the
            <code>is:</code> filter are "done", "private", "shared", or
            "branch". Filtering for <code>is: done</code> will return threads
            which are marked done (if you want to find threads which are not
            marked done use <code>not(is: done)</code>). Filtering for{" "}
            <code>is: private</code> will return threads which are marked
            private. Filtering for <code>is: shared</code> will return threads
            which are marked as shared. Filtering for <code>is: branch</code>{" "}
            will return threads which were created by branching off of another
            thread. You can also provide multiple values seperated by a
            <code>,</code>.
          </p>

          <ExampleQuery
            description={`
              find all threads which are private and also marked done (and which 
              you have permission to view).
            `}
          >
            is: private, done
          </ExampleQuery>
        </H3HelpSection>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="viewer:">
          <p>
            Find threads which a specific person or people have permission to
            view. The <code>viewer:</code> filter expects you to type{" "}
            <code>viewer:</code>, then a space, then @mention someone. You can
            include multiple @mentions if you seperate them with a{" "}
            <code>,</code> when using the <code>viewer:</code> filter. You can
            also provide a number value such as <code>viewer: 2</code>. In this
            case, threads will be returned which <em>only</em> have exactly two
            permitted viewers. You can also provide a plain text value, in which
            case the provided value will be matched against the person's name.
          </p>

          <ExampleQuery
            description={`
              find all threads which can be viewed by a user who's name contains 
              "Roland Boquiren" (and which you also have permission to view).
            `}
          >
            viewer: "Roland Boquiren"
          </ExampleQuery>
        </H3HelpSection>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="or()">
          <p>
            Find messages which match <em>any</em> of the provided filters
            (instead of <em>all</em> the provided filters, which is the
            default). To use, type <code>or()</code> and add your search
            terms/filters inside the parenthesis.
          </p>

          <ExampleQuery
            description={`
              find all messages that were sent by either Josh Clemente or
              Sam Corcos.
            `}
          >
            or( from: <ExampleMention label="@Josh Clemente" /> from:{" "}
            <ExampleMention label="@Sam Corcos" /> )
          </ExampleQuery>
        </H3HelpSection>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="and()">
          <p>
            Find messages that match all of the provided search filters. By
            default, all the filters you provide in a search query are joined
            together by an invisible <code>and()</code>. Because of this, the
            only time you may need to use the <code>and()</code> filter directly
            is inside of an
            <code>or()</code> filter. To use, type <code>and()</code> and add
            your search terms/filters inside the parenthesis.
          </p>

          <ExampleQuery
            description={`
              find all messages that are either from Josh Clemente or which
              [were sent to Sam Corcos and you and which also include "Daily 
              Update" in the subject]. Return the associated threads.
            `}
          >
            or(
            <br />
            &nbsp;&nbsp; from: <ExampleMention label="@Josh Clemente" />
            <br />
            &nbsp;&nbsp; and(
            <br />
            &nbsp;&nbsp; &nbsp;&nbsp; to: <ExampleMention label="@Sam Corcos" />{" "}
            , <ExampleMention label="@me" />
            <br />
            &nbsp;&nbsp; &nbsp;&nbsp; subject: "Daily Update"
            <br />
            &nbsp;&nbsp; )
            <br />)
          </ExampleQuery>
        </H3HelpSection>

        <hr className="mt-8 text-slate-7" />

        <H3HelpSection title="not()">
          <p>
            Find messages that <em>do not</em> match a search query. To use,
            type <code>not()</code> and add a query inside the parenthesis. You
            can pass any search query to <code>not()</code>.
          </p>

          <ExampleQuery
            description={`
              find all messages except those that were both sent by you and 
              mention Sam Corcos. Return the associated threads.
            `}
          >
            not( from: <ExampleMention label="@me" /> mentions:{" "}
            <ExampleMention label="@Sam Corcos" /> )
          </ExampleQuery>
        </H3HelpSection>
      </div>
    </ThreadLayout.InfoPanel>
  );
};

const H3HelpSection: ComponentType<{ title: string }> = (props) => {
  return (
    <>
      <h3 className="mt-8 mb-1">
        <code>
          <strong>{props.title}</strong>
        </code>
      </h3>

      <div className="ml-6">{props.children}</div>
    </>
  );
};

const ExampleQuery: ComponentType<{ description: string }> = (props) => {
  return (
    <>
      <p className="mt-2 mb-1">
        <small>
          <em>
            <strong className="leading-1">Example:</strong> {props.description}
          </em>
        </small>
      </p>

      <div className="px-2 py-1 rounded border border-slate-9 select-none">
        {props.children}
      </div>
    </>
  );
};

const ExampleMention: ComponentType<{ label: string }> = (props) => {
  return (
    <span data-type="mention" className="search-filter">
      {props.label}
    </span>
  );
};
