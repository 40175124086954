import { shareReplay, map, distinctUntilChanged, filter } from "rxjs";
import { collectionData } from "~/utils/rxFireWrappers";
import { collectionRef } from "~/firestore.service";
import { useObservable } from "~/utils/useObservable";
import { IAPIVersionDoc } from "@libs/firestore-models";
import { limit, orderBy, query } from "firebase/firestore";
import { isEqual } from "@libs/utils/isEqual";
import { auth } from "~/firebase";
import { isNonNullable } from "@libs/utils/predicates";

export const CLIENT_API_VERSION = 23;

console.debug("CLIENT_API_VERSION", CLIENT_API_VERSION);

// TODO
// Re-enable when we support Firestore persistence again
// console.debug(
//   "FIRESTORE_PERSISTENCE_API_VERSION",
//   Number(localStorage.getItem(`FIRESTORE_PERSISTENCE_API_VERSION`)),
// );

export const SERVER_API_VERSION$ = collectionData(
  query(collectionRef("apiVersion"), orderBy("version", "desc"), limit(1)),
).pipe(
  map((docs) => (docs[0] ?? null) as IAPIVersionDoc | null),
  distinctUntilChanged(isEqual),
  shareReplay(),
);

let serverAPIVersion: IAPIVersionDoc | null = null;
let ongoingMaintenance = false;

// we maintain one subscription to the current user state
// for speedier retrieval
SERVER_API_VERSION$.subscribe((doc) => {
  console.debug("SERVER_API_VERSION", doc);

  serverAPIVersion = doc;

  // TODO
  // Re-enable when we support Firestore persistence again
  // const firestorePersistenceCacheVersion = Number(
  //   localStorage.getItem(`FIRESTORE_PERSISTENCE_API_VERSION`),
  // );

  if (serverAPIVersion && serverAPIVersion.version > CLIENT_API_VERSION) {
    // then logout and they'll automatically be redirected to a
    // maintenance page
    ongoingMaintenance = true;
    setTimeout(() => auth.signOut(), 5);

    if (serverAPIVersion.isUpdateAvailable) {
      // Hack to force reload the page while bypassing the browser's cache
      window.location.href = new URL(
        `/?cachebust=${Date.now()}`,
        window.location.href,
      ).toString();
    }

    // TODO
    // Re-enable when we support Firestore persistence again
    // } else if (serverAPIVersion.version !== firestorePersistenceCacheVersion) {
    //   // if our client API version equals the server API version
    //   // but our firestorePersistenceCacheVersion doesn't equal the
    //   // server API version, then we need to clear the
    //   // firestore persistence cache
    //   const version = serverAPIVersion.version;
    //   ongoingMaintenance = true;

    //   auth.signOut().then(async () => {
    //     await terminate(db);
    //     await clearIndexedDbPersistence(db);
    //     localStorage.setItem(
    //       `FIRESTORE_PERSISTENCE_API_VERSION`,
    //       String(version),
    //     );
    //     location.reload();
    //   });
  } else {
    ongoingMaintenance = false;
  }
});

export function useServerAPIVersion() {
  return useObservable(
    () =>
      SERVER_API_VERSION$.pipe(
        filter(isNonNullable),
        map((serverVersion) => ({
          server: serverVersion,
          client: CLIENT_API_VERSION,
          matches: serverVersion.version <= CLIENT_API_VERSION,
        })),
      ),
    {
      initialValue: "loading" as const,
    },
  );
}

export function getServerAPIVersion() {
  return serverAPIVersion;
}

export function getAndAssertServerAPIVersion() {
  if (!serverAPIVersion) {
    throw new Error("Expected serverAPIVersion to be non-null.");
  }

  return serverAPIVersion;
}

export function isMaintenanceOccurring() {
  return ongoingMaintenance;
}
