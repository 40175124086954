import {
  IPostDoc,
  IThreadDoc,
  IThreadReadStatusDoc,
} from "@libs/firestore-models";
import {
  ComponentType,
  forwardRef,
  memo,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isEqual } from "@libs/utils/isEqual";
import { IListRef, useListScrollboxContext } from "~/components/list";
import { cx } from "@emotion/css";
import { Link, useSearchParams } from "react-router-dom";
import { observe } from "react-intersection-observer";
import { filter, firstValueFrom, Observable } from "rxjs";
import { CollapsedPost } from "~/components/thread-post-entry/CollapseThreadPost";
import { ExpandedPost } from "~/components/thread-post-entry/ExpandedThreadPost";
import { IPostDocFromDraft, unsendDraft } from "~/services/draft.service";
import { RiGitBranchLine } from "react-icons/ri";
import { Tooltip } from "~/components/Tooltip";
import {
  IObservePost,
  IUsePostReaction,
  mergePostReactions,
  observePostReactionForCurrentUser,
  usePostReactions,
} from "~/services/post.service";
import {
  MdEdit,
  MdEditOff,
  MdLink,
  MdOutlineAddReaction,
  MdScheduleSend,
} from "react-icons/md";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import type { Timestamp } from "@firebase/firestore-types";
import {
  copyLinkToFocusedPostCommand,
  createBranchedReplyCommand,
  focusReply,
  TThreadTimelineEntry,
} from "../utils";
import { useComposedRefs } from "~/utils/useComposedRefs";
import { getCurrentRouterLocation } from "~/services/navigate.service";
import {
  editPostCommand,
  reactToMessageCommand,
} from "../useRegisterThreadPostsCommands";
import { useThreadContext } from "../context";
import { warnThatEmailUsersWontSeeReaction } from "~/dialogs/post-reaction-picker/PostReactionPicker";

/* -------------------------------------------------------------------------------------------------
 * ViewThreadPostEntry
 * -----------------------------------------------------------------------------------------------*/

export interface IViewThreadPostEntryProps {
  post: IPostDocFromDraft | IObservePost;
  listRef: RefObject<IListRef<TThreadTimelineEntry>>;
  canUndoSend: boolean;
  canEdit: boolean;
  cannotEditReason: string;
  isFromSecretThread: boolean;
  isFirstPost: boolean;
  isLastPost: boolean;
  collapsePostEvents: Observable<"expand" | "collapse" | string>;
  isFromBranchId?: IThreadDoc["id"];
  onPostInView?: (post: IPostDoc) => void;
}

export const ViewThreadPostEntry = forwardRef<
  HTMLDivElement,
  IViewThreadPostEntryProps
>((props, ref) => {
  const {
    post,
    listRef,
    canUndoSend,
    canEdit,
    cannotEditReason,
    isFromSecretThread,
    isFirstPost,
    isLastPost,
    collapsePostEvents,
    isFromBranchId,
    onPostInView,
    ...otherProps
  } = props;

  const context = useThreadContext();
  const readStatus = context.useThreadReadStatus();

  const { isSeen, isRead } = useIsSeenAndIsRead({
    readStatus: readStatus,
    sentAt: post.sentAt,
    scheduledToBeSentAt: post.scheduledToBeSentAt,
  });

  const listEntryRef = useUpdateIsSeen({
    isSeen,
    post: post,
    onPostInView: onPostInView,
  });

  const { isClosed, setIsClosed } = useIsEntryClosedState({
    postId: post.id,
    isRead,
    isLastPost: isLastPost,
    collapsePostEvents: collapsePostEvents,
  });

  const composedEditorRefs = useComposedRefs(ref, listEntryRef);

  return (
    <div
      ref={composedEditorRefs}
      className={isClosed ? collapsedPostCSS : expandedPostCss}
      {...otherProps}
      onKeyDown={(e) => {
        if (
          e.key !== "Enter" ||
          e.target instanceof HTMLAnchorElement ||
          e.target instanceof HTMLButtonElement
        ) {
          // if the user has focused an anchor or button element and they've
          // pressed "Enter", we don't want to collapse the entry.
          return;
        }

        setIsClosed((s) => !s);
      }}
      onClick={() => {
        if (!isClosed) return;

        setIsClosed(false);
      }}
    >
      {isClosed ? (
        <CollapsedPost post={post} />
      ) : (
        <ExpandedPost
          post={post}
          postActions={
            isFromBranchId ? (
              <ViewOriginalPost threadId={post.threadId} postId={post.id} />
            ) : (
              <PostActions
                postId={post.id}
                postType={post.type}
                canUndoSend={canUndoSend}
                canEdit={canEdit}
                cannotEditReason={cannotEditReason}
                isFromSecretThread={isFromSecretThread}
                isFirstPost={isFirstPost}
                isFromDraft={!!post.__local.fromUnsafeDraft}
                listRef={listRef}
              />
            )
          }
          onHeaderClick={() => {
            setIsClosed(true);
          }}
        />
      )}
    </div>
  );
});

const collapsedPostCSS = cx(
  "Post flex p-4 sm-w:px-8 sm-w:py-4 my-1",
  "border-l-[3px] border-transparent bg-transparent",
  "focus:outline-none focus:border-black focus:bg-white focus:shadow-lg",
  "hover:cursor-pointer hover:outline-none hover:bg-white hover:shadow-lg",
);

const expandedPostCss = cx(
  "Post bg-white my-4 shadow-lg border-l-[3px] border-white",
  "focus:outline-none focus-within:border-black",
);

/* -----------------------------------------------------------------------------------------------*/

function useIsSeenAndIsRead(args: {
  readStatus: IThreadReadStatusDoc | null;
  sentAt: Timestamp;
  scheduledToBeSentAt: Timestamp;
}) {
  const isSeen = useMemo(() => {
    if (
      !args.readStatus?.seenToSentAt ||
      !args.readStatus?.seenToScheduledToBeSentAt
    ) {
      return false;
    }

    if (
      args.readStatus.seenToSentAt.valueOf() >= args.sentAt.valueOf() &&
      args.readStatus.seenToScheduledToBeSentAt.valueOf() >=
        args.scheduledToBeSentAt.valueOf()
    ) {
      return true;
    }

    return false;
  }, [args.readStatus, args.sentAt, args.scheduledToBeSentAt]);

  const isRead = useMemo(() => {
    if (
      !args.readStatus?.readToSentAt ||
      !args.readStatus?.readToScheduledToBeSentAt
    ) {
      return false;
    }

    if (
      args.readStatus.readToSentAt.valueOf() >= args.sentAt.valueOf() &&
      args.readStatus.readToScheduledToBeSentAt.valueOf() >=
        args.scheduledToBeSentAt.valueOf()
    ) {
      return true;
    }

    return false;
  }, [args.readStatus, args.sentAt, args.scheduledToBeSentAt]);

  return { isSeen, isRead };
}

/* -----------------------------------------------------------------------------------------------*/

function useUpdateIsSeen(args: {
  isSeen: boolean;
  post: IPostDoc;
  onPostInView?: (post: IPostDoc) => void;
}) {
  const { scrollboxRef } = useListScrollboxContext();
  const listEntryRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollboxRef.current || !listEntryRef.current) return;
    if (args.isSeen) return;

    const { onPostInView } = args;

    if (!onPostInView) return;

    return observe(
      listEntryRef.current,
      (inView) => {
        if (!inView) return;
        onPostInView(args.post);
      },
      {
        root: scrollboxRef.current,
        threshold: 0.5,
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args.isSeen, args.post, args.onPostInView]);

  return listEntryRef;
}

/* -----------------------------------------------------------------------------------------------*/

function useIsEntryClosedState(args: {
  postId: string;
  isRead: boolean;
  isLastPost: boolean;
  collapsePostEvents: Observable<"expand" | "collapse" | string>;
}) {
  const [searchParams] = useSearchParams();

  const isPostIdAQueryParam = searchParams.get("post") === args.postId;

  const [isClosed, setIsClosed] = useState(
    isPostIdAQueryParam || args.isLastPost ? false : args.isRead,
  );

  useEffect(() => {
    const sub = args.collapsePostEvents
      .pipe(
        filter((e) => e === "expand" || e === "collapse" || e === args.postId),
      )
      .subscribe((e) => {
        if (e === "expand" || e === "collapse") {
          setIsClosed(e === "collapse");
        } else {
          setIsClosed(false);
        }
      });

    return () => sub.unsubscribe();
  }, [args.postId, args.collapsePostEvents]);

  return { isClosed, setIsClosed };
}

/* -------------------------------------------------------------------------------------------------
 * PostActions
 * -----------------------------------------------------------------------------------------------*/

const PostActions: ComponentType<{
  postId: string;
  postType: "COMMS" | "EMAIL";
  canUndoSend: boolean;
  canEdit: boolean;
  cannotEditReason: string;
  isFromSecretThread: boolean;
  isFirstPost: boolean;
  isFromDraft: boolean;
  listRef: RefObject<IListRef<TThreadTimelineEntry>>;
  onEdit?: () => void;
}> = (props) => {
  const reactions = usePostReactions(props.postId);

  return (
    <>
      <Tooltip side="bottom" content="Copy link to this post">
        <button
          type="button"
          tabIndex={-1}
          className={cx(actionButtonCSS, "mr-4")}
          onClick={(e) => {
            e.preventDefault();
            copyLinkToFocusedPostCommand.trigger();
          }}
        >
          <MdLink />
        </button>
      </Tooltip>

      <Tooltip
        side="bottom"
        content={
          <span>
            New Branch <kbd>Shift</kbd> + <kbd>R</kbd>
          </span>
        }
      >
        <button
          type="button"
          tabIndex={-1}
          className={cx(actionButtonCSS, "mr-4")}
          onClick={(e) => {
            e.preventDefault();
            createBranchedReplyCommand.trigger();
          }}
        >
          <RiGitBranchLine />
        </button>
      </Tooltip>

      {props.cannotEditReason && (
        <Tooltip side="bottom" content={props.cannotEditReason}>
          <button
            type="button"
            tabIndex={-1}
            className={cx(actionButtonCSS, "mr-4")}
            disabled={true}
          >
            <MdEditOff />
          </button>
        </Tooltip>
      )}

      {props.canEdit && !props.cannotEditReason && (
        <Tooltip
          side="bottom"
          content={props.canUndoSend ? "Unsend and edit draft" : "Edit post"}
        >
          <button
            type="button"
            tabIndex={-1}
            className={cx(actionButtonCSS, "mr-4")}
            onClick={(e) => {
              e.preventDefault();
              editPostCommand.trigger();
            }}
          >
            <MdEdit />
          </button>
        </Tooltip>
      )}

      {!props.isFromSecretThread && (
        <Tooltip
          side="bottom"
          content={
            <span>
              Add reaction <kbd>Shift</kbd> + <kbd>;</kbd>
            </span>
          }
        >
          <button
            type="button"
            tabIndex={-1}
            className={cx(actionButtonCSS, "mr-1")}
            onClick={() => reactToMessageCommand.trigger()}
          >
            <MdOutlineAddReaction />
          </button>
        </Tooltip>
      )}

      {reactions?.map(([emoji, users]) => (
        <PostReaction
          key={emoji}
          emoji={emoji}
          users={users}
          onClick={() =>
            handleReaction({
              postId: props.postId,
              postType: props.postType,
              isFromSecretThread: props.isFromSecretThread,
              emoji,
            })
          }
        />
      ))}

      <span className="flex-1" />

      {props.isFromDraft && (
        <Tooltip
          side="bottom"
          content={`
            This post is scheduled to be sent but hasn't yet been sent by the 
            server. It will be sent in the background ASAP. Feel free to close 
            Comms.
          `}
        >
          <span className={cx(actionButtonCSS, "mr-1")}>
            <MdScheduleSend />
          </span>
        </Tooltip>
      )}
    </>
  );
};

const actionButtonCSS = cx(
  "flex justify-center items-center",
  "hover:cursor-pointer text-slate-8 scale-125",
  "hover:text-black",
);

const ViewOriginalPost: ComponentType<{
  threadId: string;
  postId: string;
}> = (props) => {
  return (
    <Link
      to={`/threads/${props.threadId}?post=${props.postId}`}
      className="hover:underline"
    >
      View original post
    </Link>
  );
};

/* -----------------------------------------------------------------------------------------------*/

/**
 * Directly adds/removes a reaction.
 *
 * When the member clicks on a reaction that already exists, it will directly
 * increment their reaction. If they click on a reaction they've already added,
 * it will remove their reaction.
 *
 * @param postId the id for the specific post the reaction belongs to.
 * @param emoji the emoji will immediately be added/removed for this user.
 */
const handleReaction = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(
    async ({
      postId,
      postType,
      isFromSecretThread,
      emoji,
    }: {
      postId: string;
      postType: "COMMS" | "EMAIL";
      isFromSecretThread: boolean;
      emoji: string;
    }) => {
      if (isFromSecretThread) return;

      const reactionDoc = await firstValueFrom(
        observePostReactionForCurrentUser(postId),
      );

      const userHasReactedAlready = !!reactionDoc;
      const reactedWithThisEmoji = reactionDoc?.reactions.includes(emoji);
      const shouldIncrementEmoji =
        !userHasReactedAlready || !reactedWithThisEmoji;

      const newReactions = shouldIncrementEmoji
        ? // the user wants to add the reaction for this emoji
          reactionDoc
          ? [...reactionDoc.reactions, emoji]
          : [emoji]
        : // they want to remove the reaction for this emoji
          reactionDoc.reactions.filter((reaction) => reaction !== emoji);

      mergePostReactions(postId, newReactions)
        .then(() =>
          console.debug(`Reaction submitted for post ${postId}`, newReactions),
        )
        .catch((e) =>
          console.error(`Failed to submit reaction for post ${postId}`, e),
        );

      if (postType === "EMAIL") {
        warnThatEmailUsersWontSeeReaction();
      }
    },
  ),
);

/* -----------------------------------------------------------------------------------------------*/

const PostReaction = memo(
  (props: {
    emoji: string;
    users: IUsePostReaction[];
    onClick: () => void;
  }) => {
    const { currentUser } = useAuthGuardContext();

    const isSelectedByCurrentUser = props.users.some(
      (user) => user.id === currentUser.id,
    );

    const tooltip = getReactionTooltip({
      users: props.users,
      isSelectedByCurrentUser,
      currentUserId: currentUser.id,
    });

    return (
      <Tooltip side="bottom" content={tooltip}>
        <button
          type="button"
          tabIndex={-1}
          className={cx(
            "px-2 flex justify-center items-center border rounded-full",
            "border-slate-8 hover:cursor-pointer ml-2",
            isSelectedByCurrentUser
              ? "bg-slate-3 border-slate-11 dark:bg-slateDark-7 dark:border-slateDark-11"
              : "border-slate-7",
          )}
          onClick={props.onClick}
        >
          <span>{props.emoji}</span>{" "}
          <span className="ml-2">{props.users.length}</span>
        </button>
      </Tooltip>
    );
  },
  isEqual,
);

function getReactionTooltip(props: {
  users: IUsePostReaction[];
  isSelectedByCurrentUser: boolean;
  currentUserId: string;
}) {
  let tooltip: string;

  if (props.users.length === 0) {
    tooltip = "";
  } else if (props.users.length === 1) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    tooltip = props.isSelectedByCurrentUser ? "You" : props.users[0]!.name;
  } else if (props.users.length < 11) {
    tooltip = props.isSelectedByCurrentUser ? "You, " : "";
    tooltip += props.users
      .filter((user) => user.id !== props.currentUserId)
      .map((user) => user.name)
      .join(", ");
  } else {
    tooltip = props.isSelectedByCurrentUser ? "You, " : "";
    tooltip += props.users
      .filter((user) => user.id !== props.currentUserId)
      .slice(0, 11)
      .map((user) => user.name)
      .join(", ");
    tooltip += `...`;
  }

  return tooltip;
}

/* -----------------------------------------------------------------------------------------------*/

export const unsendAndFocusDraft = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(async ({ postId }: { postId: string }) => {
    const draftLocation = getCurrentRouterLocation();

    await unsendDraft({ postId });

    focusReply(postId, draftLocation);
  }),
);

/* -----------------------------------------------------------------------------------------------*/
