import { memo, useMemo } from "react";
import { cx } from "@emotion/css";
import { Tooltip } from "~/components/Tooltip";
import { isEqual } from "@libs/utils/isEqual";
import {
  TInboxSection,
  useBlockingInboxSectionNotifications,
} from "~/services/inbox.service";

export const BlockingInboxProgressBar = memo<{
  inboxSection: TInboxSection;
}>((props) => {
  const inboxSectionNotifications = useBlockingInboxSectionNotifications(
    props.inboxSection.id,
  );

  const currentSubsectionIndex = useMemo(() => {
    if (!inboxSectionNotifications) return -1;
    return props.inboxSection.subsectionDocs.findIndex(
      (sub) => sub.id === inboxSectionNotifications.subsectionDoc.id,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.inboxSection, inboxSectionNotifications?.subsectionDoc.id]);

  const sharedBarCSS = "flex-1 py-[3px] flex justify-center items-center";
  const done = <span className="text-xl">🎉</span>;
  const notificationCount =
    inboxSectionNotifications?.notificationDocs.length ?? 0;

  return (
    <div className="flex flex-1 text-[20px] hover:cursor-help">
      {props.inboxSection.subsectionDocs.map((subsection, index) => {
        const isDone = index < currentSubsectionIndex;
        const tooltip = isDone
          ? `${subsection.name} messages are done`
          : subsection.name;

        return (
          <Tooltip key={index} side="bottom" content={tooltip}>
            <div
              className={cx(
                sharedBarCSS,
                index === 0 ? "rounded-l" : "border-l",
                index === props.inboxSection.subsectionDocs.length - 1
                  ? "rounded-r"
                  : "border-r",
                "text-black border-slate-8",
                currentSubsectionIndex === index ? "bg-mint-5" : "bg-slate-5",
              )}
            >
              {currentSubsectionIndex === index
                ? `${subsection?.name} - (${notificationCount})`
                : isDone
                ? done
                : null}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
}, isEqual);
