// This module handles one-time configuration for library
// code as well as other one-time functions which need to
// be called before the app is run.

// Log promise unhandledrejection events to the console.
addEventListener("unhandledrejection", console.error);

import { config } from "rxjs";
// Similar to promise "unhandledrejection" events,
// onUnhandledError will be called if an observable has an
// unhandled error.
config.onUnhandledError = console.error;

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

import { enableMapSet } from "immer";
// Enables using immer with Map and Set objects
enableMapSet();
