import { useControlState } from "~/form-components/utils";
import {
  ThreadRecipients,
  TThreadRecipientsRef,
} from "~/form-components/ThreadRecipients";
import { IComposeMessageForm } from "~/components/ComposeMessageContext";
import { Subject } from "../utils";
import { useEffect, useRef } from "react";
import { delay } from "rxjs";
import { cx } from "@emotion/css";
import { useCurrentUserMainSettings } from "~/services/settings.service";

export function BranchedThreadDraftHeader(props: {
  control: IComposeMessageForm;
  treatLessonAsCompleted: boolean;
}) {
  const threadType = useControlState(
    () => props.control.rawValue.type,
    [props.control],
  );

  const visibility = useControlState(
    () => props.control.rawValue.visibility,
    [props.control],
  );

  const recipientsRef = useRef<TThreadRecipientsRef>(null);
  const subjectRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const sub = props.control.controls.recipients.controls.to.data.focus
      // In practice, this delay is necessary to ensure that the
      // autocomplete input has rendered before we attempt to focus it.
      .pipe(delay(1))
      .subscribe(() => {
        recipientsRef.current?.focus();
      });

    const sub1 = props.control.controls.subject.data.focus
      // Not sure if this delay is necessary, but since it's needed
      // for the recipients input might as well add one here.
      .pipe(delay(1))
      .subscribe(() => {
        subjectRef.current?.focus();
      });

    sub.add(sub1);

    return () => sub.unsubscribe();
  }, [props.control]);

  const isToInvalid = useControlState(
    () => !props.control.controls.recipients.controls.to.isValid,
    [props.control],
  );

  const isToTouched = useControlState(
    () => props.control.controls.recipients.controls.to.isTouched,
    [props.control],
  );

  const isCcInvalid = useControlState(
    () => !props.control.controls.recipients.controls.cc.isValid,
    [props.control],
  );

  const isCcTouched = useControlState(
    () => props.control.controls.recipients.controls.cc.isTouched,
    [props.control],
  );

  const mainSettings = useCurrentUserMainSettings();

  const isEmailEnabled = mainSettings?.linkedGmailEmailAccount === true;

  return (
    <div className="flex flex-col mx-4 py-4 sm-w:mx-8 pb-0">
      <div className="PostSender flex pb-4">
        <strong>
          <span className="text-green-9">Draft</span>
        </strong>
      </div>

      <div className="flex items-baseline pb-2 border-b border-slate-6">
        <label
          htmlFor="to"
          className={cx(
            "mr-2 font-medium",
            isToTouched && isToInvalid && "text-red-9",
          )}
        >
          To
        </label>

        <ThreadRecipients
          ref={recipientsRef}
          name="to"
          control={props.control.controls.recipients.controls.to}
          threadType={threadType}
          canAddEmailRecipients={isEmailEnabled}
          isThreadPrivate={
            visibility === null ? null : visibility === "private"
          }
          wrapperClassName="flex-1"
        />
      </div>

      {threadType === "EMAIL" && (
        <div className="flex items-baseline py-2 border-b border-slate-6">
          <label
            htmlFor="cc"
            className={cx(
              "mr-2 font-medium",
              isCcInvalid && isCcTouched && "text-red-9",
            )}
          >
            Cc
          </label>

          <ThreadRecipients
            name="cc"
            control={props.control.controls.recipients.controls.cc}
            wrapperClassName="flex-1"
            threadType={threadType}
            canAddEmailRecipients={isEmailEnabled}
            isThreadPrivate={
              visibility === null ? null : visibility === "private"
            }
          />
        </div>
      )}

      <div className="flex items-center py-2 border-b border-slate-6">
        <Subject ref={subjectRef} control={props.control.controls.subject} />
      </div>
    </div>
  );
}
