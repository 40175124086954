import { ComponentType, PropsWithChildren, Ref } from "react";
import { css, cx } from "@emotion/css";
import { blue, red } from "@radix-ui/colors";
import {
  AutocompleteSelect,
  IOption,
  TAutocompleteSelectRef,
} from "~/form-components/AutocompleteSelect";
import {
  MultiValue,
  MultiValueGenericProps,
  OptionProps,
  SingleValue,
  SingleValueProps,
} from "react-select";
import { useChannels } from "~/services/channels.service";
import { IChannelDoc } from "@libs/firestore-models";
import { stringComparer } from "@libs/utils/comparers";
import { Tooltip } from "~/components/Tooltip";
import { BsLockFill } from "react-icons/bs";

export interface IChannelOption extends IOption<string> {
  classification: IChannelDoc["classification"];
  channelGroupNames: string[];
}

/**
 * Component for selecting a channel with autocomplete.
 */
export function ChannelSelect<M extends boolean>(
  props: PropsWithChildren<{
    autocompleteRef?: Ref<TAutocompleteSelectRef<IChannelOption, M>>;
    value?: M extends true
      ? MultiValue<IChannelOption>
      : SingleValue<IChannelOption>;
    touched?: boolean;
    error?: string;
    onChange?: M extends true
      ? (newValue: MultiValue<IChannelOption>) => void
      : (newValue: SingleValue<IChannelOption>) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    filterChannelsFn?: (channel: IChannelDoc) => boolean;
    autoFocus?: boolean;
    autocompleteMenuEl?: HTMLDivElement | null;
    multiple?: M;
    label?: string;
  }>,
) {
  const options: IChannelOption[] = useChannels({
    mapResults: (channels) => {
      const filteredChannels = props.filterChannelsFn
        ? channels.filter(props.filterChannelsFn)
        : channels;

      return filteredChannels.map((channel) => {
        return {
          label: channel.name,
          value: channel.id,
          classification: channel.classification,
          channelGroupNames: channel.__local.knownChannelGroups
            .map((c) => c.name)
            .sort(stringComparer),
        };
      });
    },
    deps: [props.filterChannelsFn],
  });

  return (
    <div
      className={cx("flex flex-1 py-2 border-b border-mauve-5", {
        [showLabelOnFocusCSS]: props.multiple
          ? (props.value as MultiValue<IOption>)?.length === 0
          : !props.value,
      })}
    >
      {props.label && (
        <label
          className={cx(
            "mr-2",
            props.touched && props.error ? "text-red-9" : "text-slateDark-11",
          )}
        >
          {props.label}
        </label>
      )}

      <AutocompleteSelect
        ref={props.autocompleteRef}
        name="channels"
        value={props.value}
        onBlur={props.onBlur}
        onChange={
          props.onChange as (
            newValue: MultiValue<IChannelOption> | SingleValue<IChannelOption>,
          ) => void
        }
        options={options}
        placeholder={
          props.touched && props.error ? "Channel required..." : "Channels..."
        }
        autoFocus={props.autoFocus}
        multiple={props.multiple}
        isClearable
        menuPortalTarget={props.autocompleteMenuEl}
        menuPlacement="bottom"
        classNames={
          (props.touched &&
            props.error &&
            `input-invalid ${channelsInputCSS}`) ||
          ""
        }
        components={components}
      />
    </div>
  );
}

const showLabelOnFocusCSS = css`
  &:not(:focus-within) label {
    display: none;
  }
`;

const channelsInputCSS = css`
  &.input-invalid .react-select-placeholder {
    color: ${red.red9};
  }
`;

const singleValueComponentCSS = css`
  .AutocompleteSelect-isFocused & {
    background-color: ${blue.blue5};
  }
`;

const SingleValueComponent: ComponentType<
  SingleValueProps<IChannelOption, boolean>
> = (props) => {
  const option = props.data as IChannelOption;

  return (
    <div
      className={cx(singleValueComponentCSS, "flex items-center px-1 rounded")}
    >
      # {option.label}{" "}
      {option.classification === "private" && (
        <BsLockFill className="ml-1 scale-75" />
      )}
    </div>
  );
};

const MultiValueLabel: ComponentType<
  MultiValueGenericProps<IChannelOption, boolean>
> = (props) => {
  const option = props.data as IChannelOption;

  return (
    <div className={cx("flex items-center text-sm mr-2")}>
      # {option.label}{" "}
      {option.classification === "private" && (
        <BsLockFill className="ml-1 scale-75" />
      )}
    </div>
  );
};

const Option: ComponentType<OptionProps<IChannelOption, boolean>> = (props) => {
  const option = props.data as IChannelOption;

  return (
    <Tooltip
      side="bottom"
      content={option.disabledReason || ""}
      open={option.disabledReason ? props.isFocused : false}
    >
      <div
        className={cx(
          "py-2 px-4 flex items-center hover:cursor-pointer",
          !option.isDisabled && "hover:bg-blue-5",
          props.isFocused
            ? option.isDisabled
              ? "bg-slateA-3"
              : "bg-blue-5"
            : "bg-transparent",
          option.isDisabled && "text-slateA-8",
        )}
        onClick={() => props.selectOption(option)}
      >
        # {option.label}{" "}
        {option.classification === "private" && (
          <BsLockFill className="ml-1 scale-75" />
        )}
        <span
          className={cx(
            "ml-4",
            option.isDisabled ? "text-slateA-8" : "text-slateA-9",
          )}
        >
          {option.channelGroupNames.join(", ")}
        </span>
      </div>
    </Tooltip>
  );
};

const components = {
  Option,
  SingleValue: SingleValueComponent,
  MultiValueLabel,
};
