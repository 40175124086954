import { Location } from "react-router-dom";

/**
 * If the user was attempting to navigate to a protected page in
 * our app, we might redirect them to another page but save the
 * location they were attempting to navigate to in
 * `location.state.from`.
 */
export function attemptToGetRedirectLocation({ state }: Location) {
  if (typeof state !== "object" || !state) return;

  const { from } = state as { from?: Location };

  return from;
}

export function openLinkInNewTabOrWindow(url: string | URL) {
  // If we don't use the "noopener" feature, then the new page will
  // share a rendering process with the current page in Google Chrome.
  // This can quickly lead to jank and high memory usage.
  window.open(new URL(url, location.origin), "_blank", "noopener");
}
