import { JSONContent } from "@tiptap/react";
import { Editor as CoreEditor } from "@tiptap/core";
import {
  ComponentType,
  forwardRef,
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { cx } from "@emotion/css";
import { observable, useControlState } from "../utils";
import { combineLatest, concat, map, Subject } from "rxjs";
import { IPostEditorRef, PostEditorBase } from "./PostEditorBase";
import {
  IEditorMention,
  IPostEditorContext,
  IPostEditorControl,
  PostEditorContext,
} from "./context";
import { useComposedRefs } from "~/utils/useComposedRefs";
import { PostMentionPriority } from "@libs/firestore-models";
import { UnreachableCaseError } from "@libs/utils/errors";
export { type IPostEditorRef as IRichTextEditorRef } from "./PostEditorBase";
import { BiError } from "react-icons/bi";
import { USER_CHANNELS$ } from "~/services/channels.service";
import { isNonNullable } from "@libs/utils/predicates";

export interface IPostEditorProps {
  control: IPostEditorControl;
  scrollboxRef?: Ref<HTMLDivElement>;
  onEditorStartOverflow?: () => void;
  onEditorEndOverflow?: () => void;
  initialTabIndex?: number;
}

export const PostEditor = forwardRef<IPostEditorRef, IPostEditorProps>(
  (props, forwardedRef) => {
    const control = props.control as IPostEditorContext["control"];

    const editorRef = useRef<IPostEditorRef>(null);
    const composeRefs = useComposedRefs(forwardedRef, editorRef);

    const isInvalid = useControlState(
      () => !control.controls.body.isValid,
      [control],
    );

    const isTouched = useControlState(
      () => control.controls.body.isTouched,
      [control],
    );

    const context = useMemo(() => ({ control }), [control]);

    useContentRequiredValidator(control);
    useChannelMentionsValidator(control);
    useSyncControlReadonlyAndDisabledStatusToEditor(control, editorRef);

    const getInitialValue = useCallback(() => {
      return control.rawValue.body.content;
      // we only use this once to get the initial value
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = useCallback(
      ({ editor }: { editor: CoreEditor }) => {
        const json = editor.getJSON();

        const { userMentions, channelMentions, mentionsCount } =
          extractMentionsFromEditorJSON(json);

        control.controls.body.patchValue({
          content: editor.isEmpty ? "" : editor.getHTML(),
          userMentions,
          channelMentions,
          // This property allows form code to observe these changes and
          // respond whenever a new `@mention` is added.
          possiblyIncorrectMentionsCount: mentionsCount,
        });
      },
      [control],
    );

    const onBlur = useCallback(() => {
      control.controls.body.markTouched(true);
    }, [control]);

    const isFieldEmpty = useControlState(
      () =>
        control.rawValue.body.content === "<p></p>" ||
        !control.rawValue.body.content,
      [control],
    );

    const postId = useControlState(() => control.rawValue.postId, [control]);

    const placeholder = (
      <span
        className={cx(
          "absolute whitespace-nowrap pointer-events-none",
          isTouched && isInvalid ? "text-red-9" : "text-slateDark-11",
          { hidden: !isFieldEmpty },
        )}
      >
        {isTouched && isInvalid ? `Content required...` : `Content...`}
      </span>
    );

    return (
      <PostEditorContext.Provider value={context}>
        <div
          ref={props.scrollboxRef}
          className="relative flex-1 overflow-y-scroll prose"
        >
          <div className="h-4 w-full" />

          {placeholder}

          <PostEditorBase
            ref={composeRefs}
            onChange={onChange}
            onBlur={onBlur}
            onEditorStartOverflow={props.onEditorStartOverflow}
            onEditorEndOverflow={props.onEditorEndOverflow}
            getInitialValue={getInitialValue}
            initialTabIndex={props.initialTabIndex}
            className={`Post-${postId}`}
          />

          <div className="h-4 w-full" />
        </div>
      </PostEditorContext.Provider>
    );
  },
);

function extractMentionsFromEditorJSON(json: JSONContent) {
  const { userMentions, channelMentions } = getMentions(json);

  const userMentionsMap = reduceMentionsByPriority(userMentions);
  const channelMentionsMap = reduceMentionsByPriority(channelMentions);

  return {
    userMentions: Array.from(userMentionsMap.values()),
    channelMentions: Array.from(channelMentionsMap.values()),
    mentionsCount: userMentionsMap.size + channelMentionsMap.size,
  };
}

function getMentions(
  json: JSONContent,
  userMentions: IEditorMention[] = [],
  channelMentions: IEditorMention[] = [],
) {
  // Mentions inside of a blockquote should be ignored
  if (json.type === "blockquote") {
    return { userMentions, channelMentions };
  }

  if (
    json.type === "mention-@" ||
    json.type === "mention-@@" ||
    json.type === "mention-@@@"
  ) {
    const attrs = json.attrs as {
      id: string;
      label: string;
      subject: "user" | "channel";
      priority: string;
    };

    const data: IEditorMention = {
      id: attrs.id,
      type: attrs.subject,
      priority: Number(attrs.priority) as PostMentionPriority,
    };

    switch (attrs.subject) {
      case "user": {
        userMentions.push(data);
        break;
      }
      case "channel": {
        channelMentions.push(data);
        break;
      }
      default: {
        throw new UnreachableCaseError(attrs.subject);
      }
    }
  } else if (json.content) {
    json.content.forEach((j) => getMentions(j, userMentions, channelMentions));
  }

  return { userMentions, channelMentions };
}

function reduceMentionsByPriority(mentions: IEditorMention[]) {
  const mentionsMap = new Map<IEditorMention["id"], IEditorMention>();

  for (const mention of mentions) {
    const existingPriority = mentionsMap.get(mention.id)?.priority || 400;

    const newPriority = mention.priority;

    if (existingPriority <= newPriority) continue;

    mentionsMap.set(mention.id, mention);
  }

  return mentionsMap;
}

function validateRequiredTextInput(value: string) {
  const text = value?.trim();

  if (!text || text === "<p></p>") return "Required.";
  return;
}

function useContentRequiredValidator(control: IPostEditorContext["control"]) {
  useEffect(() => {
    const source = { source: "required-validator" };

    const sub = combineLatest([
      observable(() => control.controls.body.controls.content.isRequired),
      observable(() => control.rawValue.body.content),
    ]).subscribe(([isRequired, content]) => {
      if (isRequired && validateRequiredTextInput(content)) {
        control.controls.body.controls.content.setErrors(
          { isEmpty: true },
          source,
        );
        return;
      }

      control.controls.body.controls.content.setErrors(null, source);
    });

    return () => sub.unsubscribe();
  }, [control]);
}

/**
 * Validate the channel mentions to make sure they are acceptible
 * given the thread's visibility. This is necessary because someone
 * could mention a public channel in a public thread draft and then,
 * before the draft has been sent, the channel is updated to be
 * private.
 */
function useChannelMentionsValidator(control: IPostEditorContext["control"]) {
  useEffect(() => {
    const errorKey = "channelMentionsValidator";

    const mentionedChannels$ = combineLatest([
      USER_CHANNELS$,
      observable(() => control.rawValue.body.channelMentions),
    ]).pipe(
      map(([channels, channelMentions]) =>
        channelMentions
          .map((m) => channels.find((c) => c.id === m.id))
          .filter(isNonNullable),
      ),
    );

    const sub = combineLatest([
      mentionedChannels$,
      observable(() => control.rawValue.visibility),
    ]).subscribe(([mentionedChannels, threadVisibility]) => {
      const channelMentionsControl =
        control.controls.body.controls.channelMentions;

      if (!threadVisibility) {
        channelMentionsControl.setErrors(null, {
          source: errorKey,
        });

        return;
      }

      let areChannelMentionsValid: boolean;

      switch (threadVisibility) {
        case "private": {
          areChannelMentionsValid = mentionedChannels.every(
            (c) => c.classification === "private",
          );

          break;
        }
        case "shared": {
          areChannelMentionsValid = mentionedChannels.every(
            (c) => c.classification === "public",
          );

          break;
        }
        default: {
          throw new UnreachableCaseError(threadVisibility);
        }
      }

      if (areChannelMentionsValid) {
        channelMentionsControl.setErrors(null, {
          source: errorKey,
        });
      } else {
        channelMentionsControl.setErrors(
          {
            invalidChannelMentions: true,
          },
          {
            source: errorKey,
          },
        );
      }
    });

    return () => sub.unsubscribe();
  }, [control]);
}

export const PostEditorErrors: ComponentType<{
  control: IPostEditorContext["control"];
}> = (props) => {
  const controlErrorMsg = useControlState(() => {
    if (props.control.status !== "INVALID") return;

    if (props.control.errors?.invalidChannelMentions) {
      return "invalidChannelMentions";
    }
  }, [props.control]);

  const visibility = useControlState(
    () => props.control.rawValue.visibility,
    [props.control],
  );

  if (!controlErrorMsg) return null;

  return (
    <div className="rounded-lg bg-red-5 px-4 py-2 mb-4 font-medium flex text-red-11">
      <div className="flex justify-center items-center">
        <BiError className=" text-2xl" />
      </div>

      <div className="ml-2">
        {visibility === "private"
          ? "Cannot mention public channels in a private thread."
          : "Cannot mention private channels in a shared thread."}
      </div>
    </div>
  );
};

function useSyncControlReadonlyAndDisabledStatusToEditor(
  control: IPostEditorContext["control"],
  editorRef: RefObject<IPostEditorRef>,
) {
  useEffect(() => {
    const editorInit$ = new Subject<void>();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    editorRef.current!.onCreate.push(() => editorInit$.complete());

    const sub = concat(
      editorInit$,
      observable(
        () =>
          control.controls.body.isDisabled || control.controls.body.isReadonly,
      ),
    ).subscribe((isDisabledOrReadonly) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      editorRef.current!.editor!.setEditable(!isDisabledOrReadonly);
    });

    return () => sub.unsubscribe();
  }, [control, editorRef]);
}
