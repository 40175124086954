import { RefObject, useMemo } from "react";
import { IListRef } from "~/components/list";
import { newPostReply } from "./ComposeReply";
import { IRichTextEditorRef } from "~/form-components/post-editor/PostEditor";
import { Location, useLocation } from "react-router-dom";
import { firstValueFrom, map, Subject } from "rxjs";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import uid from "@libs/utils/uid";
import { openEditThreadDialog } from "~/dialogs/thread-edit/EditThreadDialog";
import { showNotImplementedToastMsg } from "~/services/toast-service/toast.state";
import { ICommandArgs, useRegisterCommands } from "~/services/command.service";
import { getInboxNotification, triageThread } from "~/services/inbox.service";
import { oneLine, stripIndents } from "common-tags";
import { toast } from "~/services/toast-service";
import { RemindMeDialogState } from "~/dialogs/remind-me";
import {
  IObserveThread,
  observePostReactionForCurrentUser,
  updateThread,
} from "~/services/post.service";
import { isAppOnline } from "~/services/network-connection.service";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { PostReactionPickerDialogState } from "~/dialogs/post-reaction-picker/PostReactionPicker";
import { getSelectionAsHTMLString } from "./getSelectedPostHTMLString";
import { wait } from "@libs/utils/wait";
import {
  navigateBackOrToInbox,
  navigateService,
  updateSearchParams,
} from "~/services/navigate.service";
import {
  collapseAllPostsCommand,
  deleteDraftCommand,
  expandAllPostsCommand,
  getCommandFactory,
  markDoneCommand,
  markNotDoneCommand,
  removeThreadReminderCommand,
  setThreadReminderCommand,
  threadSubscriptionCommands,
} from "~/utils/common-commands";
import { FirebaseError } from "firebase/app";
import { writeToClipboard } from "~/utils/dom-helpers";
import { useThreadContext } from "./context";
import { IEditorMention } from "~/form-components/post-editor";
import {
  throwUnreachableCaseError,
  UnreachableCaseError,
} from "@libs/utils/errors";
import { createNewDraft, deleteDraft } from "~/services/draft.service";
import { docRef, waitForCacheToContainDoc } from "~/firestore.service";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";
import { getAndAssertCurrentUser } from "~/services/user.service";
import {
  closeThreadView,
  copyLinkToFocusedPostCommand,
  createBranchedReplyCommand,
  getLastPostEntry,
  getNearestPostEntry,
  replyToThreadCommand,
  TThreadTimelineEntry,
  updateThreadChannelsCommand,
} from "./utils";
import {
  getThreadViewPrevNextUrl,
  observeThreadViewPrevNextUrl,
} from "~/services/thread-prev-next.service";
import {
  getCurrentUserMainSettings,
  useCurrentUserMainSettings,
} from "~/services/settings.service";
import { unsendAndFocusDraft } from "./thread-post-entry/ViewThreadPostEntry";
import { SetOptional } from "type-fest";
import { ThreadVisibility } from "@libs/firestore-models";
import { EmailAddress, stringifyEmailAddress } from "@libs/utils/email-rfc";

/* -------------------------------------------------------------------------------------------------
 * useRegisterThreadPostsCommands
 * -----------------------------------------------------------------------------------------------*/

export function useRegisterThreadPostsCommands(args: {
  editorRef: RefObject<IRichTextEditorRef>;
  focusedEntry: TThreadTimelineEntry | null;
  listRef: RefObject<IListRef<TThreadTimelineEntry>>;
  collapsePostEvents$: Subject<string>;
  canEditFocusedEntry: false | "undo" | "edit";
  setIsEditingPostId: (postId: string | null) => void;
}) {
  const settings = useCurrentUserMainSettings();
  const context = useThreadContext();
  const reactRouterLocation = useLocation();
  const thread = context.useThread();
  const notification = context.useNotification();
  const {
    editorRef,
    focusedEntry,
    collapsePostEvents$,
    canEditFocusedEntry,
    setIsEditingPostId,
  } = args;

  const handleReplyCommand = useMemo(() => {
    return onlyCallFnOnceWhilePreviousCallIsPending(async () => {
      const selectedHTML = getSelectionAsHTMLString();

      await newPostReply({
        thread: thread,
        postId: uid(),
        bodyHTML:
          selectedHTML &&
          `<blockquote>${selectedHTML}</blockquote><p></p><p></p>`,
        recipients: {
          to: [],
          cc: [],
          bcc: [],
        },
        userMentions: [],
        channelMentions: [],
      });

      // Previously, we didn't have this call to `wait()` because
      // newPostReply already waits for the new draft to show up in
      // the local Firestore cache (which I thought guaranteed that
      // react would have rendered the editor before the
      // `newPostReply` promise resolves). But someone seemed to
      // report a bug caused by `editorRef.current` being null. It
      // could be that someone's environment (i.e. CPU speed,
      // browser extensions, etc) can affect the order that the
      // promise is resolved vs react rendering. We use `wait()`
      // here in an attempt to guard against this possibility.
      await wait(5);

      editorRef.current?.focus("end", { scrollIntoView: true });
    });
  }, [thread, editorRef]);

  const handleBranchedReplyCommand = useMemo(() => {
    return onlyCallFnOnceWhilePreviousCallIsPending(
      async (options: { includePostRecipients?: boolean } = {}) => {
        if (!args.listRef.current) return;

        const selectedHTML = getSelectionAsHTMLString();
        const currentUser = getAndAssertCurrentUser();
        const branchedPost =
          getNearestPostEntry({
            focusedEntry,
            listRef: args.listRef.current,
          }) || getLastPostEntry(args.listRef.current.entries);

        if (!branchedPost) return;
        if (branchedPost.__docType !== "IPostDoc") {
          alert(oneLine`
          You can only branch off of a focused post. Try focusing a post
          before creating a new branch.
        `);

          return;
        }

        const draftId = uid();

        const createNewDraftArgs: Parameters<typeof createNewDraft>[0] = {
          type: "COMMS",
          postId: draftId,
          visibility: thread.visibility,
          subject: `Branch: ${branchedPost.subject}`,
          bodyHTML:
            selectedHTML &&
            `<blockquote>${selectedHTML}</blockquote><p></p><p></p>`,
          branchedFrom: {
            threadId: branchedPost.threadId,
            postId: branchedPost.id,
            postSentAt: branchedPost.sentAt,
            postScheduledToBeSentAt: branchedPost.scheduledToBeSentAt,
          },
        };

        if (options.includePostRecipients) {
          switch (branchedPost.type) {
            case "COMMS": {
              createNewDraftArgs.to = [
                ...branchedPost.recipientChannelIds.map((id) => ({
                  type: "channelId" as const,
                  value: id,
                })),
                ...branchedPost.recipientUserIds.map((id) => ({
                  type: "userId" as const,
                  value: id,
                })),
              ];

              break;
            }
            case "EMAIL": {
              createNewDraftArgs.type = "EMAIL";

              const convertEmailToDraftRecipient = (email: EmailAddress) => ({
                type: "emailAddress" as const,
                value: stringifyEmailAddress(email),
              });

              createNewDraftArgs.to = [
                ...branchedPost.recipientChannelIds.map((id) => ({
                  type: "channelId" as const,
                  value: id,
                })),
                ...branchedPost.from.map(convertEmailToDraftRecipient),
                ...branchedPost.to.map(convertEmailToDraftRecipient),
              ];

              createNewDraftArgs.cc = branchedPost.cc.map(
                convertEmailToDraftRecipient,
              );

              break;
            }
            default: {
              throw new UnreachableCaseError(branchedPost);
            }
          }
        }

        const createNewDraftPromise = createNewDraft(createNewDraftArgs);

        await waitForCacheToContainDoc(
          docRef("users", currentUser.id, "unsafeDrafts", draftId),
          createNewDraftPromise,
        );

        // When the compose new thread dialog is closed we want to refocus the
        // correct post.
        updateSearchParams(
          (searchParams) => searchParams.set("post", branchedPost.id),
          { replace: true },
        );

        openComposeNewThreadDialog(draftId);
      },
    );
  }, [thread, args.listRef, focusedEntry]);

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        markNotDoneCommand({
          callback: () => {
            navigateBackOrToInbox();
            triageThread({
              threadId: thread.id,
              done: false,
            }).catch((e) =>
              console.error("failed to mark thread as not done", e),
            );
          },
        }),
        setThreadReminderCommand({
          callback: onlyCallFnOnceWhilePreviousCallIsPending(() =>
            openTriageDialog(thread, reactRouterLocation),
          ),
        }),
        {
          label: "Forward (n/a)",
          hotkeys: ["f"],
          callback: () => {
            toast("vanilla", {
              subject: "Not yet implemented 😭",
              description: stripIndents`
                Forwarding a message isn't implemented.
                What you can do is reply to this thread and @mention someone
                to loop them into the conversation.
              `,
              durationMs: 10_000,
            });
          },
        },
        {
          label: "Delete thread (n/a)",
          callback: () => showNotImplementedToastMsg(),
        },
        expandAllPostsCommand({
          callback: () => {
            collapsePostEvents$.next("expand");
          },
        }),
        collapseAllPostsCommand({
          callback: () => {
            collapsePostEvents$.next("collapse");
          },
        }),
        {
          label: "Delete post (n/a)",
          callback: () => showNotImplementedToastMsg(),
        },
        ...threadSubscriptionCommands({
          threadId: thread.id,
          notification: notification,
        }),
      ];

      if (thread.__local.fromSecretThread) {
        commands.push(
          replyToThreadCommand({
            label: `Reply`,
            callback: () => {
              handleBranchedReplyCommand({ includePostRecipients: true });

              toast("vanilla", {
                subject: "Branching protected thread",
                description: `
                  BCCing branches the conversation for the person BCCed. They don't receive
                  replies to the message they were BCC'd on. Because you were only BCC'd on
                  this thread, you don't have full access to the thread. You can't reply to
                  the thread directly, but you can branch off of it.
                `,
                durationMs: 10_000,
              });
            },
          }),
          createBranchedReplyCommand({
            callback: () => handleBranchedReplyCommand(),
          }),
        );
      } else {
        switch (thread.type) {
          case "COMMS": {
            commands.push(
              replyToThreadCommand({
                label: `Reply`,
                keywords: ["Focus reply"],
                callback: handleReplyCommand,
              }),
            );

            break;
          }
          case "EMAIL": {
            if (settings?.linkedGmailEmailAccount) {
              commands.push(
                replyToThreadCommand({
                  label: `Reply`,
                  keywords: ["Focus reply"],
                  callback: handleReplyCommand,
                }),
              );
            } else {
              commands.push(
                replyToThreadCommand({
                  label: `Reply`,
                  callback: () => {
                    handleBranchedReplyCommand({ includePostRecipients: true });

                    toast("vanilla", {
                      subject: "Branching email thread",
                      description: `
                        Creating a new Comms branch for this email thread.
                        You need to link an email account to Comms in order to send emails. 
                      `,
                      durationMs: 10_000,
                    });
                  },
                }),
              );
            }

            break;
          }
          default: {
            throw new UnreachableCaseError(thread);
          }
        }

        commands.push(
          createBranchedReplyCommand({
            callback: () => handleBranchedReplyCommand(),
          }),
          updateThreadChannelsCommand({
            callback: () => {
              openEditThreadDialog(
                thread.id,
                thread.__local.knownPermittedChannels,
                thread.visibility,
              );
            },
          }),
          toggleThreadVisibilityCommand({
            callback: () =>
              updateThreadVisibility(
                thread.id,
                thread.visibility === "private"
                  ? "shared"
                  : thread.visibility === "shared"
                  ? "private"
                  : throwUnreachableCaseError(thread.visibility),
              ),
          }),
          markThreadSharedCommand({
            callback: () => updateThreadVisibility(thread.id, "shared"),
          }),
          markThreadPrivateCommand({
            callback: () => updateThreadVisibility(thread.id, "private"),
          }),
        );

        if (focusedEntry) {
          commands.push(
            reactToMessageCommand({
              callback: onlyCallFnOnceWhilePreviousCallIsPending(
                withPendingRequestBar(async () => {
                  const reactionDoc = await firstValueFrom(
                    observePostReactionForCurrentUser(focusedEntry.id),
                  );

                  PostReactionPickerDialogState.toggle(true, {
                    postId: focusedEntry.id,
                    postType: thread.type,
                    reactionDoc,
                  });

                  // We intentionally don't await this Promise so that it doesn't
                  // impact `withPendingRequestBar`
                  firstValueFrom(
                    PostReactionPickerDialogState.afterClose$,
                  ).then((data) => {
                    if (!data?.success) return;
                    // this expands the post if it isn't already expanded
                    collapsePostEvents$.next(focusedEntry.id);
                  });
                }),
              ),
            }),
          );
        }
      }

      if (canEditFocusedEntry && focusedEntry?.__docType === "IPostDoc") {
        const postId = focusedEntry.id;

        commands.push(
          editPostCommand({
            callback() {
              if (canEditFocusedEntry === "undo") {
                unsendAndFocusDraft({ postId });
              } else {
                setIsEditingPostId(postId);
              }
            },
          }),
        );
      }

      if (focusedEntry?.__docType === "IPostDoc") {
        commands.push(
          copyLinkToFocusedPostCommand({
            callback: async () => {
              if (!("clipboard" in navigator)) {
                toast("vanilla", {
                  subject: "Unsupported",
                  description: `
                    Your browser doesn't support the Clipboard API.
                    Update your browser or switch to a different one ¯\\_(ツ)_/¯
                  `,
                });

                return;
              }

              const urlPrefix =
                thread.type === "COMMS"
                  ? "threads"
                  : thread.type === "EMAIL"
                  ? "emails"
                  : throwUnreachableCaseError(thread);

              const url = new URL(
                `/${urlPrefix}/${focusedEntry.threadId}?post=${focusedEntry.id}`,
                location.href,
              );

              await writeToClipboard({
                type: "text/plain",
                value: url.toString(),
              });

              toast("vanilla", {
                subject: "Link copied to clipboard",
              });
            },
          }),
        );
      } else if (focusedEntry?.__docType === "IUnsafeDraftDoc") {
        const draft = focusedEntry;

        commands.push(
          deleteDraftCommand({
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              deleteDraft({ postId: draft.id });

              const { newThread, branchedFrom } = draft;

              // Currently, there is only one type of draft which should be showing up
              // alongside posts in a thread's timeline: drafts for branched threads.
              // As such, this should always be false. In the future, we may have other
              // types of drafts in a thread's timeline, however.
              if (!newThread || !branchedFrom) {
                alert(oneLine`
                  We've deleted the selected draft, but "undo" won't work
                  in this case. Can you let the Comms developer, John Carroll,
                  know that you've run into this warning?
                `);

                return;
              }

              toast("undo", {
                subject: "Draft deleted.",
                onAction: async () => {
                  const channelMentions: IEditorMention[] = Object.entries(
                    draft.mentionedChannels,
                  ).map(([channelId, { priority }]) => ({
                    type: "channel",
                    id: channelId,
                    priority,
                  }));

                  const userMentions: IEditorMention[] = Object.entries(
                    draft.mentionedUsers,
                  ).map(([userId, { priority }]) => ({
                    type: "user",
                    id: userId,
                    priority,
                  }));

                  await createNewDraft({
                    type: draft.type,
                    postId: draft.id,
                    branchedFrom,
                    channelMentions,
                    userMentions,
                    visibility: newThread.visibility,
                    to: draft.to,
                    cc: draft.cc,
                    bcc: draft.bcc,
                    subject: newThread.subject,
                    bodyHTML: draft.bodyHTML,
                  });
                },
              });
            },
          }),
        );
      }

      if (notification) {
        commands.push(
          removeThreadReminderCommand({
            callback: () => {
              triageThread({
                threadId: thread.id,
                triagedUntil: null,
              });
            },
          }),
        );
      }

      return commands;
    },
    deps: [
      thread,
      notification,
      focusedEntry,
      reactRouterLocation,
      editorRef,
      collapsePostEvents$,
      canEditFocusedEntry,
      setIsEditingPostId,
      settings,
    ],
  });

  useRegisterCommands({
    commands() {
      return observeThreadViewPrevNextUrl(thread, reactRouterLocation).pipe(
        map((prevNextState) => [
          markDoneCommand({
            async callback() {
              const settingsDoc = await getCurrentUserMainSettings();

              const shouldNavigateBack =
                settingsDoc.enableNavBackOnThreadDone ||
                !prevNextState?.nextUrl;

              if (shouldNavigateBack) {
                // When we mark a message as done and
                // navigate back to the inbox (if we're navigating back
                // to the inbox), we want to focus the next entry in the
                // list. Currently, in order for this to work, when we
                // navigate back we need to be able to refocus the this
                // entry in the list. If this entry has already been marked
                // "Done" and is no longer in the list, then we won't be
                // able to refocus it. But, if it's focused when we mark it
                // done, then we'll automatically focus the next entry in
                // the list. By waiting for the navigation to complete + 100ms
                // before marking the message as Done,
                // we're able to refocus the appropriate message and then
                // transition focus to the next message.
                //
                // A better approach would be a more robust way
                // of tracking, between pages, the inbox entry which
                // should be focused.
                await closeThreadView();
                await wait(100);

                triageThread({
                  threadId: thread.id,
                  done: true,
                }).catch((e) =>
                  console.error("failed to mark thread as done", e),
                );

                return;
              }

              // Whenever we make a change to the Firestore in-memory cache,
              // anecdotally the cache needs to do some local processing before it
              // allows fetching any more data. This tends to take 1-5 seconds.
              // During this time, if you attempt to load something, you'll see a
              // loading spinner. By navigating to the next thread first, and then
              // waiting a few ms for that thread to load before marking this thread
              // as done, we mitigate the user impact of this behavior.
              await navigateService(prevNextState.nextUrl, {
                state: prevNextState.state,
              });

              await wait(100);

              triageThread({
                threadId: thread.id,
                done: true,
              }).catch((e) =>
                console.error("failed to mark thread as done", e),
              );
            },
          }),
        ]),
      );
    },
    deps: [thread, reactRouterLocation],
  });
}

/* -----------------------------------------------------------------------------------------------*/

async function openTriageDialog(
  thread: IObserveThread | null | undefined,
  location: Location,
) {
  if (!thread) return;

  const notification = await getInboxNotification(thread.id);

  RemindMeDialogState.toggle(true, {
    id: thread.id,
    triagedUntil: notification?.triagedUntil?.toDate() || null,
    isStarred: notification?.isStarred ?? false,
    navigateIfReminderSet: async () => {
      const [prevNextState, settingsDoc] = await Promise.all([
        getThreadViewPrevNextUrl(thread, location),
        getCurrentUserMainSettings(),
      ]);

      const shouldNavigateBack =
        settingsDoc.enableNavBackOnThreadDone || !prevNextState?.nextUrl;

      if (shouldNavigateBack) {
        navigateBackOrToInbox();
      } else {
        navigateService(prevNextState.nextUrl, {
          state: prevNextState.state,
        });
      }
    },
  });
}

/* -----------------------------------------------------------------------------------------------*/

function handleThreadUpdateFirebaseError(e: unknown) {
  if (e instanceof FirebaseError) {
    if (e.message.match("Thread with id .* does not exist")) {
      toast("vanilla", {
        subject: `
          Failed to update thread because it hasn't finished 
          being sent.        
        `,
        description: "Try waiting 30 seconds and trying again.",
      });

      return;
    }
  }

  console.error("Error updating thread", e);

  toast("vanilla", {
    subject: "Failed to update thread for an unknown reason.",
    description: "Try again?",
  });
}

/* -----------------------------------------------------------------------------------------------*/

export const editPostCommand = getCommandFactory(
  "EDIT_POST",
  (options: SetOptional<ICommandArgs, "label">): ICommandArgs => ({
    label: "Edit post",
    ...options,
  }),
);

export const reactToMessageCommand = getCommandFactory(
  "REACT_TO_MESSAGE",
  (
    options: SetOptional<ICommandArgs, "label" | "altLabels" | "hotkeys">,
  ): ICommandArgs => ({
    label: "React to message",
    altLabels: [
      "React to post",
      "Add emoji reaction to post",
      "Remove emoji reaction from post",
      "React to email",
      "Add emoji reaction to email",
      "Remove emoji reaction from email",
    ],
    hotkeys: [":", "Shift+;", "Shift+:"],
    ...options,
  }),
);

const toggleThreadVisibilityCommand = getCommandFactory(
  "TOGGLE_THREAD_VISIBILITY",
  (
    options: SetOptional<
      ICommandArgs,
      "label" | "hotkeys" | "triggerHotkeysWhenInputFocused"
    >,
  ): ICommandArgs => ({
    label: `Toggle thread visibility`,
    hotkeys: [
      "$mod+Alt+p",
      // Note that, depending on the browser, the emitted KeyboardEvent#key
      // value may be "p" or "P" (chrome on windows seems to do "p" whereas
      // Firefox does "P"). For this reason, we also add a second shortcut
      // using "KeyP". The first shortcut will be shown in the kbar
      "$mod+Alt+KeyP",
    ],
    triggerHotkeysWhenInputFocused: true,
    ...options,
  }),
);

const markThreadSharedCommand = getCommandFactory(
  "MARK_THREAD_SHARED",
  (
    options: SetOptional<
      ICommandArgs,
      "label" | "altLabels" | "keywords" | "triggerHotkeysWhenInputFocused"
    >,
  ): ICommandArgs => ({
    label: `Change thread visibility to "Shared"`,
    altLabels: ["Mark thread as shared"],
    keywords: ["make thread shared", "share"],
    triggerHotkeysWhenInputFocused: true,
    ...options,
  }),
);

const markThreadPrivateCommand = getCommandFactory(
  "MARK_THREAD_PRIVATE",
  (
    options: SetOptional<
      ICommandArgs,
      "label" | "altLabels" | "keywords" | "triggerHotkeysWhenInputFocused"
    >,
  ): ICommandArgs => ({
    label: `Change thread visibility to "Private"`,
    altLabels: ["Mark thread as private"],
    keywords: ["make thread private", "hide"],
    triggerHotkeysWhenInputFocused: true,
    ...options,
  }),
);

const updateThreadVisibility = withPendingRequestBar(
  async (threadId: string, visibility: ThreadVisibility) => {
    if (!isAppOnline()) {
      toast("vanilla", {
        subject: "Not available offline",
        description: `
        Cannot change thread visibility while offline.
      `,
      });

      return;
    }

    toast("vanilla", {
      subject: `Making thread ${visibility}...`,
    });

    try {
      await updateThread({
        threadId,
        visibility,
      });
    } catch (e) {
      handleThreadUpdateFirebaseError(e);
      return;
    }

    toast("vanilla", {
      subject: `Thread is now ${visibility}`,
    });
  },
);

/* -----------------------------------------------------------------------------------------------*/
