import { ComponentType, useRef } from "react";
import { ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import {
  ContentList,
  EmptyListMessage,
  useKBarAwareFocusedEntry,
  DraftEntry,
} from "~/components/content-list";
import { useTopScrollShadow } from "~/utils/useScrollShadow";
import { showNotImplementedToastMsg, toast } from "~/services/toast-service";
import { PendingRequestBar } from "~/components/PendingRequestBar";
import { useRegisterCommands } from "~/services/command.service";
import {
  deleteDraftCommand,
  ESCAPE_TO_INBOX_COMMAND,
  setThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import { deleteDraft, useDrafts } from "~/services/draft.service";
import { IDraftsEntry, onDraftsEntrySelect } from "./utils";
import * as MainLayout from "~/page-layouts/main-layout";

/* -------------------------------------------------------------------------------------------------
 * DraftsView
 * -----------------------------------------------------------------------------------------------*/

export const DraftsView: ComponentType<{}> = () => {
  const drafts = useDrafts();

  const [focusedDraft, setFocusedDraft] =
    useKBarAwareFocusedEntry<IDraftsEntry>();

  useRegisterDraftViewCommands(focusedDraft);

  const headerRef = useApplyScrollShadowToHeader();

  return (
    <>
      <Helmet>
        <title>Drafts | Comms</title>
      </Helmet>

      <MainLayout.Header ref={headerRef}>
        <h1 className="text-3xl">Drafts</h1>
      </MainLayout.Header>

      <ListScrollbox
        isBodyElement
        offsetHeaderEl={headerRef}
        onlyOffsetHeaderElIfSticky
      >
        {!drafts ? (
          <PendingRequestBar>
            <EmptyListMessage text="Nothing yet." />
          </PendingRequestBar>
        ) : drafts.length === 0 ? (
          <EmptyListMessage text="Nothing yet." />
        ) : (
          <ContentList<IDraftsEntry>
            onEntryFocused={setFocusedDraft}
            onEntryAction={onDraftsEntrySelect}
            className="mb-20"
            autoFocus
          >
            {drafts.map((draft, index) => (
              <DraftEntry key={draft.id} draft={draft} relativeOrder={index} />
            ))}
          </ContentList>
        )}
      </ListScrollbox>
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

function useApplyScrollShadowToHeader() {
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return headerRef;
}

/* -------------------------------------------------------------------------------------------------
 * useRegisterDraftViewCommands
 * -----------------------------------------------------------------------------------------------*/

function useRegisterDraftViewCommands(focusedDraft: IDraftsEntry | null) {
  useRegisterCommands({
    commands: () => [
      ESCAPE_TO_INBOX_COMMAND,
      deleteDraftCommand({
        callback: () => {
          if (!focusedDraft) {
            // if the user has no draft focused, but try to delete,
            // just tell them what they need to do to delete.
            toast("vanilla", {
              subject: "Oops, no draft is focused.",
              description: `
                  You first need to focus a draft by hovering your mouse  
                  over it or by using the arrow keys on your keyboard.
                `,
            });
          } else {
            deleteDraft({ postId: focusedDraft.id });
          }
        },
      }),
      setThreadReminderCommand({
        callback: () => {
          showNotImplementedToastMsg(`
            Unfortunately, you can't edit reminders from the drafts page.
          `);
        },
      }),
      starThreadCommand({
        callback: () => {
          showNotImplementedToastMsg(`
            Unfortunately, you can't star drafts.
          `);
        },
      }),
      unstarThreadCommand({
        callback: () => {
          showNotImplementedToastMsg(`
          Unfortunately, you can't star drafts.
          `);
        },
      }),
    ],
    deps: [focusedDraft],
  });
}

/* -----------------------------------------------------------------------------------------------*/
