import { ComponentType, memo, useCallback, useEffect, useRef } from "react";
import { NavLink, useMatch } from "react-router-dom";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { css, cx } from "@emotion/css";
import {
  ShortcutHint,
  sidebarEntryCSS,
  SidebarOrganizations,
} from "./SidebarChannelGroups";
import { IListRef, List, ListScrollbox } from "~/components/list";
import { TSidebarLayoutMode, useSidebarLayoutContext } from "./context";
import {
  delay,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  merge,
  withLatestFrom,
} from "rxjs";
import { useObservable } from "~/utils/useObservable";
import { Transition } from "@headlessui/react";
import {
  getMostRecentInboxPagePathname,
  navigateService,
  NAVIGATION_EVENTS,
} from "~/services/navigate.service";
import { HelpDialogState } from "~/dialogs/help/HelpDialog";
import { Avatar } from "~/components/Avatar";
import { useTopScrollShadow } from "~/utils/useScrollShadow";
import {
  callCommandById,
  ICommandArgs,
  useRegisterCommands,
  withNewCommandContext,
} from "~/services/command.service";
import { KBarState } from "~/dialogs/kbar";
import { EditChannelGroupDialogState } from "~/dialogs/channel-group-edit/EditChannelGroupDialog";
import { isAppOnline } from "~/services/network-connection.service";
import { toast } from "~/services/toast-service";
import {
  useOrganizations,
  useChannelGroups,
} from "~/services/organization.service";
import {
  CURRENT_USER_MAIN_SETTINGS$,
  mergeMainSettings,
} from "~/services/settings.service";
import { getAndAssertCurrentUser } from "~/services/user.service";
import { isEqual } from "@libs/utils/isEqual";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { getClassForPrivateMessageTourStep } from "~/services/lesson-service/lessons/private-message-walkthrough";
import { WINDOW_SIZE$ } from "~/services/window.service";
import {
  composeMessageCommand,
  openHelpCommand,
} from "~/utils/common-commands";
import { wait } from "@libs/utils/wait";
import { httpsCallable } from "firebase/functions";
import { functions } from "~/firebase";

export const Sidebar = withNewCommandContext<{
  mode: TSidebarLayoutMode;
}>((props) => {
  const context = useSidebarLayoutContext();

  const organizations = useOrganizations();
  const channelGroups = useChannelGroups();

  useRegisterCommands({
    commands: getGeneralNavigationCommands,
  });

  useRegisterCommands({
    commands: () => {
      if (!channelGroups) return [];

      return [
        {
          label: "Edit channel group...",
          altLabels: ["Update channel group...", "Rename channel group..."],
          closeKBarOnSelect: false,
          callback: () => {
            KBarState.toggle(true, {
              path: ["Edit channel group"],
              mode: "search",
            });
          },
        },
        ...channelGroups.flatMap((channelGroup) => {
          return [
            {
              id: `Edit "${channelGroup.id}" channel group`,
              label: `Edit "${channelGroup.name}" channel group`,
              altLabels: [
                `Update "${channelGroup.name}" channel group`,
                `Rename "${channelGroup.name}" channel group`,
              ],
              path: ["Edit channel group"],
              callback: () => {
                if (!isAppOnline()) {
                  toast("vanilla", {
                    subject: "Not supported in offline mode",
                    description: "Can't edit channel groups when offline.",
                  });

                  return;
                }

                if (
                  channelGroup.__local.fromOrganization
                    .defaultChannelGroupId === channelGroup.id
                ) {
                  toast("vanilla", {
                    subject: "Cannot edit this channel group",
                    description: `
                      You cannot edit an organization's default channel group
                      and "${channelGroup.name}" is a default channel group. 
                    `,
                  });

                  return;
                }

                EditChannelGroupDialogState.toggle(true, {
                  channelGroup: channelGroup,
                });
              },
            },
          ];
        }),
      ];
    },
    deps: [channelGroups],
  });

  useRegisterCommands({
    commands: () => {
      if (!organizations) return [];

      return [
        {
          label: "View organization members...",
          closeKBarOnSelect: false,
          callback: () => {
            KBarState.toggle(true, {
              path: ["Organization members"],
              mode: "search",
            });
          },
        },
        ...organizations.flatMap((organization) => {
          return [
            {
              id: `View "${organization.id}" members`,
              label: `View "${organization.name}" members`,
              path: ["Organization members"],
              callback: () => {
                navigateService(`/organizations/${organization.id}/members`);
              },
            },
          ];
        }),
      ];
    },
    deps: [organizations],
  });

  useRegisterCommands({
    commands: () => {
      return CURRENT_USER_MAIN_SETTINGS$.pipe(
        map((settingDoc) => {
          const commands: ICommandArgs[] = [];

          const isFocusModeEnabled = settingDoc?.enableFocusMode ?? false;

          if (isFocusModeEnabled) {
            commands.push({
              label: "Disable focus mode",
              keywords: ["Enable focus mode"],
              altLabels: ["Toggle focus mode"],
              callback: toggleFocusMode(false),
            });
          } else {
            commands.push({
              label: "Enable focus mode",
              keywords: ["Disable focus mode"],
              altLabels: ["Toggle focus mode"],
              callback: toggleFocusMode(true),
            });
          }

          commands.push({
            label: "Update focus mode",
            altLabels: ["Edit focus mode"],
            callback: () => {
              navigateService("/settings#focus-mode");
            },
          });

          commands.push({
            label: "Edit scheduled delivery",
            altLabels: [
              "Update scheduled delivery",
              "Edit batched delivery",
              "Update batched delivery",
            ],
            callback: () => {
              navigateService("/settings#scheduled-delivery");
            },
          });

          return commands;
        }),
      );
    },
  });

  useRegisterCommands({
    commands: () => {
      return [
        {
          label: "Edit your user profile",
          altLabels: [
            "Update your user profile",
            "Edit my profile",
            "Update my profile",
            "Change phone number",
            "Update phone number",
            "Edit phone number",
          ],
          callback: () => {
            if (!isAppOnline()) {
              toast("vanilla", {
                subject: "Not supported in offline mode",
                description: "Can't edit your profile while offline.",
              });

              return;
            }

            navigateService("/settings#user-profile");
          },
        },
        {
          label: "Notification preferences",
          altLabels: [
            "Edit notification preferences",
            "Update notification preferences",
          ],
          callback: () => {
            if (!isAppOnline()) {
              toast("vanilla", {
                subject: "Not supported in offline mode",
                description: "Can't edit your profile while offline.",
              });

              return;
            }

            navigateService("/settings#notification-preferences");
          },
        },
      ];
    },
  });

  useEffect(() => {
    const sub = context.focusEvent$
      .pipe(distinctUntilChanged())
      .subscribe((e) => {
        context.sidebarOpen$.next(e === "Sidebar");
      });

    return () => sub.unsubscribe();
  }, [context.focusEvent$, context.sidebarOpen$]);

  useEffect(() => {
    const sub = WINDOW_SIZE$.pipe(
      map(({ width }) => (width > 1000 ? "push" : "over")),
      distinctUntilChanged(),
    ).subscribe((mode) => {
      context.sidebarMode$.next(mode);
    });

    return () => sub.unsubscribe();
  }, [context]);

  useEffect(() => {
    // Automatically close the sidebar and focus the outlet
    // on a navigation event
    const sub = NAVIGATION_EVENTS.subscribe(() => {
      context.sidebarOpen$.next(false);
      context.focusEvent$.next("Outlet");
    });

    return () => sub.unsubscribe();
  }, [context]);

  useEffect(() => {
    if (props.mode === "push") return;

    // Automatically close the sidebar and focus the outlet
    // when the kbar is opened.
    const sub = KBarState.beforeOpen$
      .pipe(withLatestFrom(context.sidebarOpen$))
      .subscribe(([, isOpen]) => {
        if (!isOpen) return;
        context.sidebarOpen$.next(false);
        context.focusEvent$.next("Outlet");
      });

    return () => sub.unsubscribe();
  }, [props.mode, context]);

  return props.mode === "over" ? <SidebarModeOver /> : <SidebarContent />;
});

const SidebarModeOver = memo(() => {
  const context = useSidebarLayoutContext();

  const onBackdropClick = useCallback(() => {
    context.focusEvent$.next("Outlet");
  }, [context.focusEvent$]);

  const isSidebarOpen = useObservable(
    () => context.sidebarOpen$.pipe(distinctUntilChanged()),
    {
      synchronous: true,
      deps: [context.sidebarOpen$],
    },
  );

  return (
    <>
      <Transition
        // Note the "as" property. This will be rendered as an `<aside>`.
        as="aside"
        show={isSidebarOpen}
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        className={cx(
          "fixed top-0 left-0",
          "w-64 h-screen shrink-0 border-r",
          "border-gray-8 bg-inherit z-[201]",
          "ease-in-out duration-75",
          "flex flex-col",
        )}
      >
        {isSidebarOpen && (
          <>
            <SidebarHotkeys />
            <SidebarContent />
          </>
        )}
      </Transition>

      <Transition
        show={isSidebarOpen}
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={cx("ease-in-out duration-75")}
      >
        <div
          onClick={onBackdropClick}
          className={cx(
            "fixed top-0 left-0",
            "w-screen h-screen",
            "bg-blackA-10 z-[200]",
            "ease-in-out duration-75",
          )}
        />
      </Transition>
    </>
  );
}, isEqual);

const SidebarHotkeys = memo(
  withNewCommandContext({
    updateStrategy: "replace",
    Component: () => {
      const context = useSidebarLayoutContext();

      useRegisterCommands({
        commands: () => {
          return [
            ...getGeneralNavigationCommands(),
            {
              label: "Close sidebar",
              hotkeys: ["Escape"],
              callback: () => {
                context.focusEvent$.next("Outlet");
              },
            },
            {
              label: "Open Command Bar",
              hotkeys: ["$mod+k"],
              showInKBar: false,
              callback: () => {
                KBarState.toggle(true);
              },
            },
            openHelpCommand({
              callback: () => {
                context.focusEvent$.next("Outlet");
                setTimeout(() => HelpDialogState.toggle(true), 0);
              },
            }),
          ];
        },
        deps: [context.focusEvent$],
      });

      return null;
    },
  }),
  isEqual,
);

const SidebarContent = memo(() => {
  const { currentUser } = useAuthGuardContext();
  const context = useSidebarLayoutContext();
  const listRef = useRef<IListRef<string> | null>(null);
  const scrollboxRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sub = merge(
      context.sidebarOpen$,
      context.focusEvent$.pipe(map((e) => e === "Sidebar")),
    )
      .pipe(
        filter((open) => open),
        delay(1),
      )
      .subscribe(() => {
        listRef.current?.focus();
      });

    return () => sub.unsubscribe();
  }, [context]);

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  const onArrowRight = useCallback(() => {
    context.focusEvent$.next("Outlet");

    if (context.sidebarMode$.getValue() === "over") {
      context.sidebarOpen$.next(false);
    }
  }, [context]);

  return (
    <aside
      className={cx(
        "fixed top-0 left-0 h-screen w-64 bg-white flex flex-col",
        "border-r border-slate-7",
      )}
    >
      <div
        ref={headerRef}
        className="flex items-center pl-9 pr-4 py-[26px] transition-shadow duration-300"
      >
        <Avatar photoURL={currentUser.photoURL} label={currentUser.name} />
        <span className="ml-2 font-medium">{currentUser.name}</span>
      </div>

      <List<string>
        ref={listRef}
        focusEntryOnMouseOver
        onArrowRight={onArrowRight}
      >
        <ListScrollbox>
          <div ref={scrollboxRef} className="overflow-y-auto">
            <nav className="list-none pb-4">
              <InboxLink />
              <ComposeMessageButton />
              <SidebarNavLink to="starred" label="Starred" shortcutHint="g r" />
              <SidebarNavLink to="drafts" label="Drafts" shortcutHint="g d" />
              <SidebarNavLink to="sent" label="Sent" shortcutHint="g t" />
              <SidebarNavLink to="done" label="Done" shortcutHint="g e" />
              <SidebarNavLink
                to="reminders"
                label="Reminders"
                shortcutHint="g h"
              />
              <SidebarNavLink to="search" label="Search" shortcutHint="/" />
              <SidebarNavLink
                to="shared-messages"
                label="Shared Messages"
                shortcutHint="g s"
                className={getClassForPrivateMessageTourStep(3)}
              />

              {currentUser.organizationId && (
                <SidebarNavLink
                  to={`organizations/${currentUser.organizationId}/explore-channels`}
                  label="Explore Channels"
                />
              )}

              <HelpButton />
            </nav>

            <SidebarOrganizations />
          </div>
        </ListScrollbox>
      </List>
    </aside>
  );
}, isEqual);

const inboxLinkCSS = css`
  .notification-count {
    border-style: solid;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &:focus {
    .notification-count {
      border-width: 1px;
      padding-left: calc(0.5rem - 1px);
      padding-right: calc(0.5rem - 1px);
    }
  }
`;

const InboxLink: ComponentType<{}> = () => {
  return (
    <SidebarNavLink
      to="inbox"
      label="Inbox"
      shortcutHint="g i"
      className={inboxLinkCSS}
    />
  );
};

const ComposeMessageButton: ComponentType<{}> = () => {
  const context = useSidebarLayoutContext();

  return (
    <li>
      <List.Entry<never>
        id="compose-new-message-btn"
        onEntryAction={async () => {
          // The compose new message command isn't available if the
          // side bar is "open" and "over" so we make sure it's closed
          // before calling our command.
          context.sidebarOpen$.next(false);
          await wait(10);
          callCommandById(composeMessageCommand.id);
        }}
      >
        <button
          type="button"
          className={cx(sidebarEntryCSS, "hover:text-transparent w-full")}
        >
          Compose Message
          <span className="flex-1" />
          <ShortcutHint hint="C" />
        </button>
      </List.Entry>
    </li>
  );
};

const HelpButton: ComponentType<{}> = () => {
  return (
    <li>
      <List.Entry<never>
        id="nav-help"
        onEntryAction={() => {
          callCommandById(openHelpCommand.id);
        }}
      >
        <button
          type="button"
          className={cx(sidebarEntryCSS, "hover:text-transparent w-full")}
        >
          Help
          <span className="flex-1" />
          <ShortcutHint hint="Shift+/" />
        </button>
      </List.Entry>
    </li>
  );
};

const SidebarNavLink: ComponentType<{
  to: string;
  label: string;
  shortcutHint?: string;
  className?: string;
}> = ({ to, label, shortcutHint, className }) => {
  const isActive = !!useMatch(to);

  return (
    <li>
      <List.Entry<never> id={`nav-${label}`}>
        <NavLink
          to={to}
          className={cx(
            sidebarEntryCSS,
            "hover:text-transparent",
            { ["font-bold"]: isActive },
            className,
          )}
        >
          {label}
          {shortcutHint && <ShortcutHint hint={shortcutHint} />}
        </NavLink>
      </List.Entry>
    </li>
  );
};

function getGeneralNavigationCommands() {
  const commands: ICommandArgs[] = [
    {
      label: "Go to Inbox",
      hotkeys: ["g i"],
      callback: () => {
        navigateService(getMostRecentInboxPagePathname());
      },
    },
    {
      label: "Go to Starred",
      hotkeys: ["g r"],
      callback: () => {
        navigateService("/starred");
      },
    },
    {
      label: "Go to Drafts",
      hotkeys: ["g d"],
      callback: () => {
        navigateService("/drafts");
      },
    },
    {
      label: "Go to Sent",
      hotkeys: ["g t"],
      callback: () => {
        navigateService("/sent");
      },
    },
    {
      label: "Go to Done",
      hotkeys: ["g e"],
      callback: () => {
        navigateService("/done");
      },
    },
    {
      label: "Go to Reminders",
      hotkeys: ["g h"],
      callback: () => {
        navigateService("/reminders");
      },
    },
    {
      label: "Go to Search",
      hotkeys: ["/"],
      callback: () => {
        navigateService("/search");
      },
    },
    {
      label: "Go to Shared Messages",
      hotkeys: ["g s"],
      callback: () => {
        navigateService("/shared-messages");
      },
    },
    {
      // TODO:
      // Update this command when we support users being part of
      // multiple organizations and when we support users not
      // being a part of an organization.
      label: "Go to Explore Channels",
      altLabels: ["View all channels"],
      callback: () => {
        const currentUser = getAndAssertCurrentUser();

        if (!currentUser.organizationId) return;

        navigateService(
          `/organizations/${currentUser.organizationId}/explore-channels`,
        );
      },
    },
    {
      label: "Go to Settings",
      callback: () => {
        navigateService("/settings");
      },
    },
    {
      label: "Go to channel...",
      keywords: ["channel"],
      hotkeys: ["g c"],
      closeKBarOnSelect: false,
      callback: () => {
        KBarState.toggle(true, {
          path: ["Channels"],
          mode: "search",
        });
      },
    },
  ];

  if (import.meta.env.VITE_FIREBASE_EMULATORS === "true") {
    commands.push({
      label: "Seed emails",
      callback() {
        const fn = httpsCallable(
          functions,
          "onDevOnly-simulateGmailPushNotification",
        );

        fn({ email: "john@example.com" });
      },
    });
  }

  return commands;
}

const toggleFocusMode = (enableFocusMode: boolean) =>
  onlyCallFnOnceWhilePreviousCallIsPending(
    withPendingRequestBar(async () => {
      const settingsDoc = await firstValueFrom(CURRENT_USER_MAIN_SETTINGS$);

      const hasTheUserEverEnabledFocusModeBefore =
        !!settingsDoc?.focusModeExceptions;

      if (hasTheUserEverEnabledFocusModeBefore) {
        mergeMainSettings({ enableFocusMode });
      } else {
        // If the user has never enabled focus mode before,
        // we take them to the focus mode section of the
        // settings so that they can learn more about it
        // and do any initial setup that's necessary.
        navigateService("/settings#focus-mode");
      }
    }),
  );
