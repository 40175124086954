import {
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  DialogState,
  DIALOG_CONTAINER_CSS,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { css, cx } from "@emotion/css";
import {
  ICommand,
  normalizeCommand,
  useRegisterCommands,
} from "~/services/command.service";
import { IListRef, List, ListScrollbox } from "~/components/list";
import commandScore from "command-score";
import {
  FilterCommandsInput,
  IKBarHeaderRef,
  KBarHeader,
  KBarState,
} from "../kbar";
import {
  createFormControl,
  createFormGroup,
  IFormControl,
  IFormGroup,
  useControl,
} from "solid-forms-react";
import { observable, useControlState } from "~/form-components/utils";
import { OPTIONS } from "./OnResponsePicker";
import { CommandEntry } from "../kbar/CommandEntry";
import { getSuggestions, SOMEDAY } from "./suggestions";
import { uniqBy } from "lodash-es";
import dayjs from "dayjs";
import { triageThread } from "~/services/inbox.service";
import { INotificationDoc } from "@libs/firestore-models";
import uid from "@libs/utils/uid";
import { wait } from "@libs/utils/wait";

export const RemindMeDialogState = new DialogState<
  {
    id: string;
    triagedUntil: Date | null;
    isStarred: INotificationDoc["isStarred"];
    navigateIfReminderSet?: () => void | Promise<void>;
  },
  {
    success?: boolean;
  }
>();

type IReminderCommand = ICommand & { date: dayjs.Dayjs | null };

export const RemindMeDialog = withModalDialog({
  dialogState: RemindMeDialogState,
  containerCSS: cx(
    DIALOG_CONTAINER_CSS,
    css`
      max-height: 472px;
    `,
  ),
  Component: (props) => {
    if (!props.data?.id) {
      throw new Error("Requires passing a notificationId");
    }

    const listRef = useRef<IListRef<IReminderCommand>>(null);
    const headerRef = useRef<IKBarHeaderRef>(null);
    const scrollboxRef = useRef<HTMLDivElement>(null);

    const control = useControl(() =>
      createFormGroup({
        text: createFormControl(""),
        onResponse: createFormControl(OPTIONS[0]),
      }),
    );

    const { commands, enableEntryFocusOnMouseover } =
      useReminderSuggestionCommands({
        control,
        listRef,
        isStarred: props.data.isStarred,
        isTriaged: !!props.data.triagedUntil,
      });

    // Switch focus between search-input and not-search-input
    // when the mode changes.
    useEffect(() => {
      headerRef.current?.focusInput(true);
    }, []);

    const selectReminder = useCallback(async () => {
      // The remindme dialog operates similar to the command bar:
      // the browser is technically always focused on the filter input
      // but we pretend like one of the commands is also focused. Our
      // List component handles tracking the currently "focused"
      // command, so we need to grab the activeCommand from the
      // listRef.
      const activeCommand = listRef.current?.focusableOrActiveEntry()?.data;

      if (!activeCommand) return;

      RemindMeDialogState.toggle(false, {
        success: true,
      });

      if (activeCommand.label === "Remove reminder & move to inbox") {
        triageThread({
          threadId: props.data.id,
          done: false,
          triagedUntil: null,
        });
      } else if (["Star", "Unstar"].includes(activeCommand.label as string)) {
        triageThread({
          threadId: props.data.id,
          isStarred: activeCommand.label === "Star",
        });
      } else if (activeCommand.date) {
        const reminderDate = activeCommand.date.toDate();

        if (!props.data.navigateIfReminderSet) {
          triageThread({
            threadId: props.data.id,
            done: true,
            triagedUntil: reminderDate,
          });

          return;
        }

        await props.data.navigateIfReminderSet();

        // This is a hack. When we mark a message as done and
        // navigate back to the inbox (if we're navigating back
        // to the inbox), we want to focus the next entry in the
        // list. Currently, in order for this to work, when we
        // navigate back we need to be able to refocus the this
        // entry in the list. If this entry has already been marked
        // "Done" and is no longer in the list, then we won't be
        // able to refocus it. But, if it's focused when we mark it
        // done, then we'll automatically focus the next entry in
        // the list. By waiting 100ms after navigating back before
        // marking the message as Done, we're able to refocus the
        // appropriate message and then transition focus to the next
        // message.
        //
        // A better approach would be a more robust way
        // of tracking, between pages, the inbox entry which
        // should be focused.
        await wait(100);

        triageThread({
          threadId: props.data.id,
          done: true,
          triagedUntil: reminderDate,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data.id, props.data.navigateIfReminderSet]);

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Escape",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            showInKBar: false,
            callback: () => {
              if (control.rawValue.text.length > 0) {
                control.patchValue({ text: "" });
                return;
              }

              RemindMeDialogState.toggle(false);
            },
          },
          {
            label: "Back",
            path: ["global"],
            hotkeys: ["Backspace"],
            triggerHotkeysWhenInputFocused: true,
            showInKBar: false,
            callback: () => {
              if (control.rawValue.text.length > 0) {
                return false;
              }

              RemindMeDialogState.toggle(false);
              KBarState.toggle(true);
            },
          },
          {
            label: "Close dialog",
            hotkeys: ["$mot+k"],
            triggerHotkeysWhenInputFocused: true,
            showInKBar: false,
            callback: () => {
              RemindMeDialogState.toggle(false);
            },
          },
          {
            label: "Select",
            hotkeys: ["$mod+Enter", "Enter"],
            triggerHotkeysWhenInputFocused: true,
            showInKBar: false,
            callback: selectReminder,
          },
        ];
      },
      deps: [selectReminder],
    });

    return (
      <List
        ref={listRef}
        mode="active-descendent"
        focusEntryOnMouseOver={enableEntryFocusOnMouseover}
      >
        <div className={cx(dialogCSS, "dialog-test")}>
          <KBarHeader
            ref={headerRef}
            mode="search"
            scrollboxRef={scrollboxRef}
            currentPath={["Remind me"]}
          >
            <FilterSuggestionsInput control={control.controls.text} />

            {/* 
              // Expected to be used in the future but Sam didn't want this
              // prioritized at the moment.
              <OnResponsePicker
                control={control.controls.onResponse}
                onFocusInput={() => {
                  headerRef.current?.focusInput(true);
                }}
              /> 
            */}
          </KBarHeader>

          <ListScrollbox>
            <div
              ref={scrollboxRef}
              role="listbox"
              className="flex flex-col overflow-y-auto bg-white"
            >
              {commands.map((command, index) => {
                return (
                  <CommandEntry
                    key={command.id}
                    index={index}
                    currentPath={[]}
                    command={command}
                    mode="search"
                    onClick={(e) => {
                      e.preventDefault();
                      listRef.current?.focus(command.id);
                      selectReminder();
                    }}
                  >
                    <div className="flex flex-1">{command.label}</div>

                    {command.date && (
                      <div>
                        <DisplayDate date={command.date} />
                      </div>
                    )}
                  </CommandEntry>
                );
              })}
            </div>
          </ListScrollbox>
        </div>
      </List>
    );
  },
});

const dialogCSS = cx(
  DIALOG_CONTENT_WRAPPER_CSS,
  "overflow-hidden rounded",
  css`
    background-color: transparent;
    box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.2),
      0px 15px 50px 10px rgba(0, 0, 0, 0.3);
  `,
);

const FilterSuggestionsInput = forwardRef<
  HTMLInputElement,
  {
    ref: RefObject<HTMLInputElement>;
    control: IFormControl<string>;
  }
>((props, ref) => {
  const value = useControlState(() => props.control.value, [props.control]);

  return (
    <FilterCommandsInput
      ref={ref}
      value={value}
      onChange={(e) => props.control.setValue(e.currentTarget.value)}
      placeholder="Try: 8am, 3 days, aug 7"
    />
  );
});

function DisplayDate(props: { date: dayjs.Dayjs }) {
  if (props.date === SOMEDAY) {
    return <>never</>;
  }

  const now = dayjs();

  const isSameDay = props.date.isSame(now, "date");

  const formatStr = isSameDay
    ? "h:mm A"
    : props.date.diff(now, "days") < 7
    ? "ddd, h:mm A"
    : now.get("year") === props.date.get("year")
    ? "ddd, MMM DD, h:mm A"
    : "MMM DD, YYYY, h:mm A";

  return (
    <time dateTime={props.date.toISOString()}>
      {props.date.format(formatStr)}
    </time>
  );
}

/**
 * Updates commands in response to user input and changes to
 * the command service state
 */
function useReminderSuggestionCommands(args: {
  control: IFormGroup<{
    text: IFormControl<string>;
  }>;
  listRef: RefObject<IListRef<IReminderCommand>>;
  isTriaged: boolean;
  isStarred: boolean;
}) {
  const [commands, setCommands] = useState<Array<IReminderCommand>>([]);

  const [enableEntryFocusOnMouseover, setEnableEntryFocusOnMouseover] =
    useState(true);

  useEffect(() => {
    const sub = observable(() => args.control.rawValue.text.trim()).subscribe(
      (query) => {
        const [normalizedQuery, suggestions] = getSuggestions(query, {
          triaged: args.isTriaged,
          isStarred: args.isStarred,
        });

        let filteredSuggestions = suggestions
          .map((suggestion) => ({
            suggestion,
            score: commandScore(
              suggestion.keywords.join(" ") + " " + suggestion.content,
              normalizedQuery,
            ),
          }))
          .filter(
            (r) =>
              r.score > 0 &&
              (!r.suggestion.date ||
                (r.suggestion.date &&
                  r.suggestion.date.valueOf() > Date.now())),
          )
          .sort((a, b) => b.score - a.score);

        if (filteredSuggestions[0]) {
          // If we have a suggestion that seems like a good
          // match, we shouldn't bother showing other suggestions
          // that have a very low score.
          const threshold = filteredSuggestions[0].score - 0.2;

          filteredSuggestions = filteredSuggestions.filter(
            (s) => s.score >= threshold,
          );
        }

        filteredSuggestions = uniqBy(
          filteredSuggestions,
          // if the suggestion isn't associated with a date then we
          // consider it unique
          (s) => s.suggestion.date?.valueOf() || uid(),
        );

        const commands = filteredSuggestions.slice(0, 5).map((s) => {
          const command = normalizeCommand(
            {
              label: s.suggestion.content,
              callback: () => {
                console.error(
                  "unexpectedly triggered reminder suggestion callback",
                );
              },
            },
            1,
          ) as unknown as IReminderCommand;

          command.date = s.suggestion.date;

          return command;
        });

        // If the mouse is hovering over the list as entries move around
        // beneith it, mouseover events will be triggered which will cause
        // the entry beneith the mouse to be focused as someone types. We
        // want the top entry to be focused while someone types, so we
        // disable mouseover events while they type.
        setEnableEntryFocusOnMouseover(false);
        setCommands(commands);

        // After performing a search, we want to focus the first command
        // in the list. We use setTimeout to ensure that the list has
        // finished rendering.
        setTimeout(() => {
          const firstEntry = args.listRef.current?.entries[0];
          args.listRef.current?.focus(firstEntry?.id);
          setEnableEntryFocusOnMouseover(true);
        }, 20);
      },
    );

    return () => sub.unsubscribe();
  }, [args.control, args.listRef, args.isTriaged, args.isStarred]);

  return { commands, enableEntryFocusOnMouseover };
}
