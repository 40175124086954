import { ComponentType, Dispatch, memo, useState } from "react";
import { DialogState, withModalDialog } from "~/dialogs/withModalDialog";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import {
  createFormControl,
  createFormGroup,
  useControl,
} from "solid-forms-react";
import { handleSubmit, useControlState } from "~/form-components/utils";
import { useRegisterCommands } from "~/services/command.service";
import { IPostReactionDoc } from "@libs/firestore-models";
import { mergePostReactions } from "~/services/post.service";
import { cx } from "@emotion/css";
import { isEqual } from "@libs/utils/isEqual";
import { Tooltip } from "~/components/Tooltip";
import { SubmitDialogHint } from "../DialogLayout";
import { toast } from "~/services/toast-service";

export type IPostReactionPickerData =
  | {
      postId: string;
      postType: "COMMS" | "EMAIL";
      reactionDoc: IPostReactionDoc | null;
    }
  | undefined;

export type IPostReactionPickerDialogReturnData = { success: boolean } | void;

export const PostReactionPickerDialogState = new DialogState<
  IPostReactionPickerData,
  IPostReactionPickerDialogReturnData
>();

interface IFormValue {
  id: string;
  type: "COMMS" | "EMAIL";
  reactions: string[];
}

function createForm(data: NonNullable<IPostReactionPickerData>) {
  return createFormGroup({
    id: createFormControl(data.postId),
    type: createFormControl(data.postType),
    reactions: createFormControl(data.reactionDoc?.reactions || []),
  });
}

type IPostReactionPickerControl = ReturnType<typeof createForm>;

export const PostReactionPickerDialog = withModalDialog({
  dialogState: PostReactionPickerDialogState,
  Component: (props) => {
    const data = props.data;

    if (!data) {
      throw new Error(
        "Oops! You must provide data to PostReactionPickerDialog",
      );
    }

    const control = useControl(() => createForm(data));

    const [focusedIndex, setFocusedIndex] = useState(0);

    useRegisterPostReactionCommands({
      focusedIndex,
      setFocusedIndex,
      control,
    });

    const reactions = useControlState(
      () => control.rawValue.reactions,
      [control],
    );

    return (
      <>
        <div
          className="PostReactionPickerContainer flex flex-col items-center"
          onClick={(e) => {
            const wasClickOnContainerEl =
              e.target instanceof HTMLDivElement &&
              e.target.classList.contains("PostReactionPickerContainer");

            if (!wasClickOnContainerEl) return;

            PostReactionPickerDialogState.toggle(false);
          }}
        >
          <div className="flex">
            <div className="bg-blackA-11 text-white rounded-full px-4 py-1 text-lg">
              React to post
            </div>
          </div>

          <div className="flex flex-wrap m-4">
            {EMOJIS.map((emoji, index) => (
              <ReactionOption
                key={emoji}
                emoji={emoji}
                control={control}
                index={index}
                isFocused={focusedIndex === index}
                reactions={reactions}
              />
            ))}
          </div>

          <div className="flex mt-8">
            <SubmitDialogHint />
          </div>
        </div>
      </>
    );
  },
});

const EMOJIS = ["👍", "👀", "🙌", "😂", "❤️", "😮", "👎", "✅"];

const ReactionOption: ComponentType<{
  emoji: string;
  index: number;
  control: IPostReactionPickerControl;
  reactions: string[];
  isFocused: boolean;
}> = memo((props) => {
  const isSelected = props.reactions.some((r) => r === props.emoji);

  return (
    <Tooltip
      side="bottom"
      content={`Press "${
        props.index + 1
      }" to select or use the arrow keys + "Enter" to toggle.`}
    >
      <div
        className={cx(
          "p-3 mx-4 my-5 border-2 rounded flex justify-center items-center",
          "text-3xl relative hover:cursor-pointer",
          props.isFocused
            ? "border-blue-11 bg-blue-3"
            : "border-slate-4 bg-slate-4",
        )}
        onClick={() => {
          if (props.control.rawValue.reactions.includes(props.emoji)) {
            props.control.patchValue({
              reactions: props.control.rawValue.reactions.filter(
                (emoji) => emoji !== props.emoji,
              ),
            });
          } else {
            props.control.patchValue({
              reactions: [...props.control.rawValue.reactions, props.emoji],
            });
          }

          console.log("attempting submit");
          handleSubmit(props.control, submit);
        }}
      >
        {props.emoji}

        <div
          className={cx(
            "flex justify-center items-center absolute -bottom-8 right-[14px]",
            "w-6 h-6 text-white text-sm rounded-full",
            props.isFocused ? "bg-blueA-11" : "bg-blackA-11",
          )}
        >
          #{props.index + 1}
        </div>

        {isSelected && (
          <div
            className={cx(
              "flex justify-center items-center absolute -top-3 -right-3",
              "w-6 h-6 text-white text-sm rounded-full",
              props.isFocused ? "bg-blue-11" : "bg-black",
            )}
          >
            ✓
          </div>
        )}
      </div>
    </Tooltip>
  );
}, isEqual);

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  async (values: IFormValue) => {
    console.log("submitting...", values);

    mergePostReactions(values.id, values.reactions.slice())
      .then(() =>
        console.debug(
          `Reaction submitted for post ${values.id}`,
          values.reactions,
        ),
      )
      .catch((e) =>
        console.error(`Failed to submit reaction for post ${values.id}`, e),
      );

    if (values.type === "EMAIL") {
      warnThatEmailUsersWontSeeReaction();
    }

    PostReactionPickerDialogState.toggle(false, {
      success: true,
    });
  },
);

export function warnThatEmailUsersWontSeeReaction() {
  toast("vanilla", {
    subject: "Reactions are only visible to Comms users",
    description: `
      Folks receiving this message via email will never see
      this reaction.
    `,
    durationMs: 5000,
  });
}

function useRegisterPostReactionCommands(args: {
  focusedIndex: number;
  setFocusedIndex: Dispatch<React.SetStateAction<number>>;
  control: IPostReactionPickerControl;
}) {
  const { focusedIndex, setFocusedIndex, control } = args;

  useRegisterCommands({
    commands: () => {
      // for each emoji, get its index + 1 in a new array
      const emojiHotkeys = Array.from(
        { length: EMOJIS.length },
        (_, i) => `${i + 1}`,
      );

      return [
        {
          label: "Close dialog",
          hotkeys: ["Escape"],
          triggerHotkeysWhenInputFocused: true,
          callback: () => {
            PostReactionPickerDialogState.toggle(false);
          },
        },
        {
          label: "Submit form",
          hotkeys: ["$mod+Enter"],
          triggerHotkeysWhenInputFocused: true,
          callback: () => {
            console.log("attempting submit");
            handleSubmit(control, submit);
          },
        },
        {
          label: "Select emoji",
          hotkeys: ["Enter", ...emojiHotkeys],
          showInKBar: false,
          callback: (e) => {
            if (!e) {
              console.warn(
                "Select emoji command expects to be called via hotkey",
              );

              return;
            }

            const selectedEmoji =
              e.key === "Enter"
                ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  EMOJIS[focusedIndex]!
                : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  EMOJIS[Number(e.key) - 1]!;

            if (control.rawValue.reactions.includes(selectedEmoji)) {
              control.patchValue({
                reactions: control.rawValue.reactions.filter(
                  (emoji) => emoji !== selectedEmoji,
                ),
              });
            } else {
              control.patchValue({
                reactions: [...control.rawValue.reactions, selectedEmoji],
              });
            }

            if (e.key !== "Enter") {
              console.log("attempting submit");
              handleSubmit(control, submit);
            }
          },
        },
        {
          label: "Focus next emoji",
          hotkeys: ["ArrowRight"],
          callback: () => {
            if (focusedIndex === EMOJIS.length - 1) return;
            setFocusedIndex((i) => i + 1);
          },
        },
        {
          label: "Focus previous emoji",
          hotkeys: ["ArrowLeft"],
          callback: () => {
            if (focusedIndex === 0) return;
            setFocusedIndex((i) => i - 1);
          },
        },
      ];
    },
    deps: [focusedIndex],
  });
}
