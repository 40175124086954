import { filter, pairwise, throttleTime, Subscription } from "rxjs";
import {
  forwardRef,
  PropsWithChildren,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { IFormControl } from "solid-forms-react";
import { observable, useControlState } from "~/form-components/utils";
import { CheckboxInput } from "~/form-components/CheckboxInput";
import { Tooltip } from "~/components/Tooltip";
import Tippy from "@tippyjs/react";
import { css, cx } from "@emotion/css";
import { observeFocusWithin } from "~/utils/dom-helpers";
import { slate } from "@radix-ui/colors";
import { Slot } from "@radix-ui/react-slot";
import {
  useBottomScrollShadow,
  useTopScrollShadow,
} from "~/utils/useScrollShadow";
import { WINDOW_RESIZE_EVENT$ } from "~/services/window.service";
import { onlySearchSeenPostsControl } from "./useSearch";

/* -------------------------------------------------------------------------------------------------
 * SearchDropdown
 * -----------------------------------------------------------------------------------------------*/

export interface ISearchDropdownRef {
  visible: boolean;
  hide(): void;
}

export const SearchDropdown = forwardRef<
  ISearchDropdownRef,
  PropsWithChildren<{
    searchQueryHTMLControl: IFormControl<string>;
    isSearchPending: boolean;
  }>
>((props, ref) => {
  const searchWrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const windowResizeEventsSubscriptionRef = useRef<Subscription>();
  const [tippyVisibility, setTippyVisibility] = useState(false);
  const [popperInstance, setPopperInstance] = useState<HTMLElement | null>(
    null,
  );

  useShowDropdownOnInput({
    searchQueryHTMLControl: props.searchQueryHTMLControl,
    setTippyVisibility,
  });

  useShowDropdownOnFocus({
    searchWrapperRef,
    popperInstance,
    setTippyVisibility,
  });

  useHideDropdownOnSearch({
    isSearchPending: props.isSearchPending,
    setTippyVisibility,
  });

  // show the dropdown when the editor is focused

  useImperativeHandle(
    ref,
    () => ({
      visible: tippyVisibility,
      hide() {
        setTippyVisibility(false);
      },
    }),
    [tippyVisibility],
  );

  const queryHTML = useControlState(
    () => props.searchQueryHTMLControl.rawValue,
    [props.searchQueryHTMLControl],
  );

  const getReferenceClientRect = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => searchWrapperRef.current!.getBoundingClientRect(),
    // We want this function to be recreated anytime that
    // queryHTML changes to force the popper instance to re-
    // evaluate the clientRect.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryHTML],
  );

  const inputScrollboxRef = useRef<HTMLDivElement>(null);
  const inputTopRef = useRef<HTMLDivElement>(null);
  const inputBottomRef = useRef<HTMLDivElement>(null);

  const calcTopScrollShadow = useTopScrollShadow({
    scrollboxRef: inputScrollboxRef,
    targetRef: inputTopRef,
  });

  const calcBottomScrollShadow = useBottomScrollShadow({
    scrollboxRef: inputScrollboxRef,
    targetRef: inputBottomRef,
  });

  // useTopScrollShadow and useBottomScrollShadow only reevaluate the need for
  // a shadow on scroll events. If a user deletes content from the editor
  // we may no longer need a scroll shadow but a scroll event will not have
  // been emitted. We handle this possibility here.
  useEffect(() => {
    const sub = observable(() => props.searchQueryHTMLControl.rawValue)
      .pipe(throttleTime(100, undefined, { leading: false, trailing: true }))
      .subscribe(() => {
        calcTopScrollShadow();
        calcBottomScrollShadow();
      });

    return () => sub.unsubscribe();
  }, [
    props.searchQueryHTMLControl,
    calcTopScrollShadow,
    calcBottomScrollShadow,
  ]);

  return (
    <>
      <div
        ref={searchWrapperRef}
        className={cx(
          "relative flex-1 flex flex-col overflow-x-auto",
          tippyVisibility && "TippyFocused",
        )}
      >
        <div ref={inputTopRef} className={cx(topShadow, "w-full h-[1px]")} />
        <Slot ref={inputScrollboxRef}>{props.children}</Slot>
        <div
          ref={inputBottomRef}
          className={cx(bottomShadow, "w-full h-[1px]")}
        />
      </div>

      <Tippy
        zIndex={9999}
        content={<DropdownContent ref={dropdownRef} />}
        appendTo={"parent"}
        interactive
        getReferenceClientRect={getReferenceClientRect}
        offset={[0, 0]}
        maxWidth="none"
        placement="bottom-start"
        onCreate={(instance) => {
          setPopperInstance(instance.popper);

          const updateTippyWidth = () => {
            if (!searchWrapperRef.current) return;

            instance.popper.style.width =
              searchWrapperRef.current.offsetWidth + "px";
          };

          setTimeout(updateTippyWidth, 100);

          windowResizeEventsSubscriptionRef.current =
            WINDOW_RESIZE_EVENT$.subscribe(updateTippyWidth);
        }}
        onDestroy={() => {
          setPopperInstance(null);
          windowResizeEventsSubscriptionRef.current?.unsubscribe();
        }}
        visible={tippyVisibility}
        reference={searchWrapperRef}
      />
    </>
  );
});

const topShadow = css`
  &.shadow-md {
    box-shadow: 0 3px 2px ${slate.slate9};
    z-index: 5;
  }
`;

const bottomShadow = css`
  &.bottom-shadow {
    box-shadow: 0 -3px 2px ${slate.slate9};
    z-index: 5;
  }
`;

/* -----------------------------------------------------------------------------------------------*/

/**
 * Show the dropdown when the user adds search text but don't show it when
 * they delete text.
 */
function useShowDropdownOnInput(props: {
  searchQueryHTMLControl: IFormControl<string>;
  setTippyVisibility: (value: boolean) => void;
}) {
  const { searchQueryHTMLControl, setTippyVisibility } = props;

  useEffect(() => {
    const sub = observable(() => searchQueryHTMLControl.rawValue)
      .pipe(
        // We know that we'll always emit at least two values since the
        // control is initialized with a "" value and then we'll set the
        // control when it's value is updated.
        pairwise(),
        filter(([prev, curr]) => curr >= prev),
      )
      .subscribe(() => {
        setTippyVisibility(true);
      });

    return () => sub.unsubscribe();
  }, [searchQueryHTMLControl, setTippyVisibility]);
}

/* -----------------------------------------------------------------------------------------------*/

function useShowDropdownOnFocus(args: {
  searchWrapperRef: RefObject<HTMLDivElement>;
  popperInstance: HTMLElement | null;
  setTippyVisibility: (value: boolean) => void;
}) {
  const { searchWrapperRef, popperInstance, setTippyVisibility } = args;

  useEffect(() => {
    if (!searchWrapperRef.current) return;

    const observable = popperInstance
      ? observeFocusWithin(popperInstance, searchWrapperRef.current)
      : observeFocusWithin(searchWrapperRef.current);

    const sub = observable.subscribe((isFocused) => {
      setTippyVisibility(isFocused);
    });

    return () => sub.unsubscribe();
  }, [setTippyVisibility, popperInstance, searchWrapperRef]);
}

/* -----------------------------------------------------------------------------------------------*/

function useHideDropdownOnSearch({
  isSearchPending,
  setTippyVisibility,
}: {
  isSearchPending: boolean;
  setTippyVisibility: (value: boolean) => void;
}) {
  useEffect(() => {
    if (!isSearchPending) return;
    setTippyVisibility(false);
  }, [isSearchPending, setTippyVisibility]);
}

/* -----------------------------------------------------------------------------------------------*/

const DropdownContent = forwardRef<HTMLDivElement, {}>((props, ref) => {
  return (
    <div
      ref={ref}
      className={cx(
        "w-full max-h-[50vh] p-4 rounded overflow-y-auto",
        "bg-white shadow-lg border border-slate-8",
      )}
    >
      <Tooltip side="bottom" content="Only return messages which you've seen">
        <div className="flex items-center">
          <CheckboxInput
            id="only-seen"
            control={onlySearchSeenPostsControl}
            checkedValue={true}
            uncheckedValue={false}
          />

          <label htmlFor="only-seen" className="ml-2 font-medium">
            only search threads which you've opened or received a notification
            for
          </label>
        </div>
      </Tooltip>

      <p className="mt-2 text-slate-9">
        <em>
          <strong>You generally want this option checked.</strong> Most of the
          time, you're searching for something you've seen before.
        </em>
      </p>
    </div>
  );
});

/* -----------------------------------------------------------------------------------------------*/
