import { isEqual } from "@libs/utils/isEqual";
import { updatableBehaviorSubject } from "@libs/utils/updatableBehaviorSubject";
import { ACTIVE_PATH$, COMMAND_EVENTS$ } from "~/services/command.service";
import { DialogState } from "../withModalDialog";

export interface IKBarDialogData {
  path?: string[];
  query?: string;
  mode?: "hotkey" | "search";
}

class KBarState extends DialogState<IKBarDialogData, undefined> {
  readonly path$ = ACTIVE_PATH$;

  readonly query$ =
    updatableBehaviorSubject<NonNullable<IKBarDialogData["query"]>>("");

  readonly mode$ =
    updatableBehaviorSubject<NonNullable<IKBarDialogData["mode"]>>("search");

  /**
   * If called without arguments, toggles the current dialog open state.
   * Otherwise, sets the state to the provided value.
   */
  toggle(isOpen?: true, data?: IKBarDialogData): void;
  toggle(isOpen?: false, data?: undefined): void;
  toggle(isOpen?: boolean, data?: IKBarDialogData | undefined): void;
  toggle(isOpen?: boolean, data?: IKBarDialogData | undefined) {
    const originalValue = this.isOpen();
    const newValue = isOpen ?? !this.isOpen();

    if (newValue) {
      this._beforeOpen$.next(data as IKBarDialogData);
      this.afterBeforeOpenCallback(originalValue);
    } else {
      this._beforeClose$.next(data as undefined);
    }

    if (
      data?.mode !== undefined &&
      !isEqual(this.mode$.getValue(), data.mode)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.mode$.update(() => data!.mode!);
    }

    if (
      data?.path !== undefined &&
      !isEqual(this.path$.getValue(), data.path)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.path$.update(() => data!.path!);
      // We also reset the query when the path changes. Important that this
      // code block runs before the block which updates `query$` on
      // `data?.query` changes so that that change can overwrite this change.
      this.query$.update(() => "");
    }

    if (
      data?.query !== undefined &&
      !isEqual(this.query$.getValue(), data.query)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.query$.update(() => data!.query!);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._isOpen$.next({ isOpen: newValue, data: data as any });

    if (newValue) {
      this._afterOpen$.next(data as IKBarDialogData);
    } else {
      this.beforeAfterCloseCallback(originalValue);
      this._afterClose$.next(data as undefined);
    }
  }
}

const KBarStateSingleton = new KBarState();

export { KBarStateSingleton as KBarState };

export function resetKBarState() {
  KBarStateSingleton.query$.next("");
  KBarStateSingleton.mode$.next("search");
  KBarStateSingleton.path$.next([]);
}

if (import.meta.env.MODE !== "test") {
  // automaticaly close kbar after some commands are executed
  COMMAND_EVENTS$.subscribe(({ command }) => {
    // Important that we don't toggle the kbar if it is already closed
    // as that would cause the Kbar's ModalDialog isOpen$ subscription
    // to run causing the KBar to attempt to focus the previously focused
    // el (which is inappropriate if the kbar isn't actually being closed).
    if (!KBarStateSingleton.isOpen()) return;

    if (command.closeKBarOnSelect && command.showInKBar) {
      KBarStateSingleton.toggle(false);
    }
  });
}
