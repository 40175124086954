import { ComponentType } from "react";
import { NavLink, useMatch } from "react-router-dom";
import { cx } from "@emotion/css";
import {
  IChannelDoc,
  IChannelGroupDoc,
  IChannelSubscriptionDoc,
} from "@libs/firestore-models";
import { List } from "~/components/list";
import useLocalStorageState from "use-local-storage-state";
import { oneLine } from "common-tags";
import { ShortcutHintContents } from "~/services/hint-service/hints";
import {
  useOrganizations,
  usePinnedChannelGroupsForOrganization,
} from "~/services/organization.service";
import {
  IChannelDocWithCurrentUserData,
  PINNED_USER_CHANNELS$,
} from "~/services/channels.service";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { BsLockFill } from "react-icons/bs";
import { useCurrentUserChannelSubscription } from "~/services/subscription.service";
import { Tooltip } from "~/components/Tooltip";
import { useObservable } from "~/utils/useObservable";
import { map } from "rxjs";
import { UnreachableCaseError } from "@libs/utils/errors";
import { DEFAULT_SUBSCRIPTION_PREFERENCE } from "@libs/firestore-models/utils";

export function convertShortcutToTrigger(shortcut: number) {
  return `g ${shortcut.toString().split("").join(" ")}`;
}

export const SidebarOrganizations: ComponentType<{}> = () => {
  const organizations = useOrganizations();

  return (
    <>
      {organizations.map((organization) => (
        <div key={organization.id}>
          {/* <h4 className="mx-[37px] py-1 mb-1 font-bold text-xl text-slate-9">
            {organization.nameShort}
          </h4> */}

          <SidebarChannelGroups organizationId={organization.id} />
        </div>
      ))}
    </>
  );
};

const SidebarChannelGroups: ComponentType<{ organizationId: string }> = (
  props,
) => {
  const channelGroups =
    usePinnedChannelGroupsForOrganization(props.organizationId) || [];

  return (
    <ul className="list-none mb-10">
      {channelGroups.map((channelGroup, index) => (
        <ChannelGroup
          key={channelGroup.id}
          channelGroup={channelGroup}
          isDefaultChannelGroup={index === 0}
          relativeOrder={index}
        />
      ))}
    </ul>
  );
};

const ChannelGroup: ComponentType<{
  channelGroup: IChannelGroupDoc;
  isDefaultChannelGroup: boolean;
  relativeOrder: number;
}> = (props) => {
  const { currentUser } = useAuthGuardContext();

  const [isExpanded, setIsExpanded] = useLocalStorageState(
    // "channel groups" used to be called "workspaces" hence the
    // reference to "workspaceExpanded" in this localstorage key.
    `${currentUser.id}.sidebar.workspaceExpanded.${props.channelGroup.id}`,
    { defaultValue: true },
  );

  return (
    <li className={cx("flex flex-col", !props.isDefaultChannelGroup && "mt-3")}>
      <List.Entry<IChannelGroupDoc>
        id={props.channelGroup.id}
        data={props.channelGroup}
        relativeOrder={props.relativeOrder}
        onEntryAction={() => {
          setIsExpanded((e) => !e);
        }}
      >
        <button
          type="button"
          className={cx(
            `flex items-center pl-3 pr-4 py-2`,
            `focus:bg-slate-4 outline-none focus:border-black`,
            `border-l-2 border-white text-slate-9`,
          )}
        >
          <TriangleIcon className={cx({ "rotate-90": isExpanded })} />
          <strong className="text-left">{props.channelGroup.name}</strong>
        </button>
      </List.Entry>

      {isExpanded && (
        <ul className="list-none">
          <ChannelGroupChannels channelGroup={props.channelGroup} />
        </ul>
      )}
    </li>
  );
};

const TriangleIcon: ComponentType<{ className?: string }> = (props) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx("scale-150 mr-2", props.className)}
    >
      <path d="M6 11L6 4L10.5 7.5L6 11Z" fill="currentColor"></path>
    </svg>
  );
};

export const sidebarEntryCSS = oneLine`
  flex py-2 pl-9 pr-4 leading-6 focus:bg-slate-4 
  outline-none focus:border-black border-l-2 
  border-white group relative
`;

const ChannelGroupChannels: ComponentType<{
  channelGroup: IChannelGroupDoc;
}> = (props) => {
  const channels = useObservable(
    () =>
      PINNED_USER_CHANNELS$.pipe(
        map((channels) =>
          channels.filter(
            (c) =>
              c.channelGroupIds.includes(props.channelGroup.id) &&
              !c.isOrganizationSharedChannel,
          ),
        ),
      ),
    { initialValue: [], deps: [props.channelGroup.id] },
  );

  return (
    <>
      {channels.map((channel, index) => (
        <ChannelEntry
          key={channel.id}
          channel={channel}
          channelGroup={props.channelGroup}
          relativeOrder={index}
        />
      ))}
    </>
  );
};

const ChannelEntry: ComponentType<{
  channel: IChannelDocWithCurrentUserData;
  channelGroup: IChannelGroupDoc;
  relativeOrder: number;
}> = ({ channel, channelGroup, relativeOrder }) => {
  const subscription = useCurrentUserChannelSubscription(channel.id);
  const to = `channels/${channel.id}`;
  const isActive = !!useMatch(to);

  const { icon, hint } = subscriptionHint(subscription?.preference);

  return (
    <li>
      <List.Entry<IChannelDoc>
        id={`${channelGroup.id}-${channel.id}`}
        data={channel}
        relativeOrder={relativeOrder}
      >
        <NavLink
          to={to}
          className={cx(sidebarEntryCSS, "items-start items-center", {
            ["font-bold"]: isActive,
          })}
        >
          # <span className="ml-1">{channel.name}</span>
          {channel.classification === "private" && (
            <span className="inline-flex ml-1">
              <small>
                <BsLockFill />
              </small>
            </span>
          )}
          <span className="flex-1" />
          <Tooltip side="right" content={hint}>
            <span
              className={cx(
                "flex font-normal text-sm",
                "justify-center items-center",
                "ml-[2px] hover:cursor-help",
                "py-[2px] px-[6px]",
                "text-slate-9",
              )}
            >
              {icon}
            </span>
          </Tooltip>
        </NavLink>
      </List.Entry>
    </li>
  );
};

function subscriptionHint(
  preference?: IChannelSubscriptionDoc["preference"] | null,
): {
  icon: string;
  hint: string;
} {
  const normalizedPreference =
    preference === null ? DEFAULT_SUBSCRIPTION_PREFERENCE : preference;

  switch (normalizedPreference) {
    case "all": {
      return {
        icon: "A",
        hint: `Subscribed All. You will receive all notifications for
          this channel.`,
      };
    }
    case "all-new": {
      return {
        icon: "S",
        hint: `Subscribed. You will receive a notification for every new
          thread created in this channel.`,
      };
    }
    case "involved": {
      return {
        icon: "U",
        hint: `Unsubscribed. You will only receive notifications for 
          threads you are participating or @mentioned in.`,
      };
    }
    case undefined: {
      return {
        icon: "",
        hint: "",
      };
    }
    default: {
      throw new UnreachableCaseError(normalizedPreference);
    }
  }
}

export const ShortcutHint: ComponentType<{
  hint: string;
}> = ({ hint }) => {
  return (
    <div
      className={cx(
        "items-center hidden group-hover:flex",
        "absolute top-0 right-0 h-full text-white",
        "mr-4",
      )}
    >
      <ShortcutHintContents
        hint={hint}
        keyClassName="bg-slate-10"
        adverbClassName="text-slate-10 font-medium"
      />
    </div>
  );
};
