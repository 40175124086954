import { ComponentType } from "react";
import { Avatar } from "~/components/Avatar";
import {
  ALL_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$,
  IAcceptedOrganizationMemberDoc,
} from "~/services/organization.service";
import {
  IChannelDocWithCurrentUserData,
  USER_NON_ORG_SHARED_CHANNELS$,
} from "~/services/channels.service";
import { BsLockFill } from "react-icons/bs";
import {
  BaseEntry,
  getSuggestionRenderFn,
  ISuggestionEntryProps,
  WithIndexAndGroup,
} from "./BaseSuggestionsDropdown";
import { SuggestionOptions } from "@tiptap/suggestion";
import { firstValueFrom, shareReplay } from "rxjs";
import {
  applyAdditionalMentionSuggestionWeights,
  fuzzyMatchChannels,
  fuzzyMatchMembers,
} from "~/form-components/tiptap/suggestion-utils";
import { observeCurrentUserSetting } from "~/services/settings.service";
import commandScore from "command-score";

export type TPersonSuggestion = WithIndexAndGroup<
  {
    __docType: "IUserFragment";
    id: IAcceptedOrganizationMemberDoc["id"];
    name: IAcceptedOrganizationMemberDoc["user"]["name"];
    photoURL: IAcceptedOrganizationMemberDoc["user"]["photoURL"];
  },
  "People"
>;

export type TChannelSuggestion = WithIndexAndGroup<
  IChannelDocWithCurrentUserData,
  "Channels"
>;

export type TUserOrChannelSuggestion = TPersonSuggestion | TChannelSuggestion;

export function getUserOrChannelSuggestionRenderFn<
  T extends TUserOrChannelSuggestion,
>(
  args = {
    getSuggestionAttributes: (item: T) => {
      return {
        id: item.id,
        label: item.name,
      };
    },
  },
) {
  return getSuggestionRenderFn({
    getSuggestionAttributes: args.getSuggestionAttributes,
    suggestionEntryComponentMap: {
      People: PersonEntry,
      Channels: ChannelEntry,
    },
  });
}

const MENTION_FREQUENCY_SETTING$ = observeCurrentUserSetting(
  "MentionFrequencySettings",
).pipe(shareReplay({ bufferSize: 1, refCount: true }));

export const onUserQuery: SuggestionOptions<TPersonSuggestion>["items"] =
  async ({ query }) => {
    const [members, mentionFrequencySettingsDoc] = await Promise.all([
      firstValueFrom(ALL_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$),
      firstValueFrom(MENTION_FREQUENCY_SETTING$),
    ]);

    const mentions = mentionFrequencySettingsDoc?.mentions || {};

    const filteredMembers = query
      ? fuzzyMatchMembers({
          query,
          members,
          entryScore: (m, query) => {
            const label = `${m.user.name} ${m.user.email}`;

            let score = commandScore(label, query);

            if (score !== 0) {
              score = applyAdditionalMentionSuggestionWeights({
                score,
                id: m.id,
                frequencyDictionary: mentions,
              });
            }

            return score;
          },
        })
      : members.slice(0, 5);

    return filteredMembers.map((o, index) => ({
      __docType: "IUserFragment",
      id: o.id,
      name: o.user.name,
      photoURL: o.user.photoURL,
      index,
      group: "People",
    }));
  };

export const onChannelQuery: SuggestionOptions<TChannelSuggestion>["items"] =
  async ({ query }) => {
    const [channels, mentionFrequencySettingsDoc] = await Promise.all([
      firstValueFrom(USER_NON_ORG_SHARED_CHANNELS$),
      firstValueFrom(MENTION_FREQUENCY_SETTING$),
    ]);

    const mentions = mentionFrequencySettingsDoc?.mentions || {};

    const filteredChannels = query
      ? fuzzyMatchChannels({
          query,
          channels,
          entryScore: (c, query) => {
            const label =
              c.name +
              " " +
              c.__local.knownChannelGroups.map((g) => g.name).join(" ");

            let score = commandScore(label, query);

            if (score !== 0) {
              score = applyAdditionalMentionSuggestionWeights({
                score,
                id: c.id,
                frequencyDictionary: mentions,
              });
            }

            return score;
          },
        })
      : channels.slice(0, 5);

    return filteredChannels.map((o, index) => ({
      ...o,
      index,
      group: "Channels",
    }));
  };

const PersonEntry: ComponentType<ISuggestionEntryProps<TPersonSuggestion>> = (
  props,
) => {
  return (
    <BaseEntry
      item={props.entry}
      isSelected={props.selectedIndex === props.entry.index}
      onClick={() => props.selectItem(props.entry.index)}
    >
      <Avatar
        label={props.entry.name}
        photoURL={props.entry.photoURL}
        fontSize="11px"
        width="1.5rem"
        className="mx-3"
      />
      <span className="truncate">{props.entry.name}</span>
    </BaseEntry>
  );
};

const ChannelEntry: ComponentType<ISuggestionEntryProps<TChannelSuggestion>> = (
  props,
) => {
  return (
    <BaseEntry
      item={props.entry}
      isSelected={props.selectedIndex === props.entry.index}
      onClick={() => {
        props.selectItem(props.entry.index);
      }}
    >
      <span className="ml-3">#</span>
      <span className="inline-flex items-center shrink whitespace-nowrap overflow-hidden">
        <span className="truncate">{props.entry.name}</span>
        {props.entry.classification === "private" && (
          <BsLockFill className="ml-1 scale-75" />
        )}
      </span>
      <span className="ml-1 text-slate-9 truncate flex-1">
        ({props.entry.__local.knownChannelGroups.map((g) => g.name).join(", ")})
      </span>
    </BaseEntry>
  );
};
