import { ComponentType, memo } from "react";
import { useNextScheduledDeliveryDatetime } from "~/services/inbox.service";
import { EmptyListMessage } from "~/components/content-list";
import { isNonNullable } from "@libs/utils/predicates";
import { isEqual } from "@libs/utils/isEqual";
import { PendingRequestBar } from "~/components/PendingRequestBar";
import { mergeMainSettings } from "~/services/settings.service";
import dayjs from "dayjs";
import { IMainSettingsDoc } from "@libs/firestore-models";
import { Link } from "react-router-dom";

export const EmptyList: ComponentType<{
  settings?: IMainSettingsDoc | null;
  entriesAreLoading: boolean;
}> = memo(({ settings, entriesAreLoading }) => {
  let baseComponent: JSX.Element;

  if (settings?.enableFocusMode && settings?.enableScheduledDelivery) {
    baseComponent = (
      <FocusModeAndScheduledDeliveryEnabledMessage settings={settings} />
    );
  } else if (settings?.enableFocusMode) {
    baseComponent = (
      <FocusModeEnabledMessage
        focusModeExceptions={settings.focusModeExceptions}
      />
    );
  } else if (settings?.enableScheduledDelivery) {
    baseComponent = <ScheduledDeliveryEnabledMessage />;
  } else {
    baseComponent = <EmptyListMessage text="Inbox Zero &nbsp; 🎉" />;
  }

  if (entriesAreLoading) {
    return <PendingRequestBar>{baseComponent}</PendingRequestBar>;
  } else {
    return baseComponent;
  }
}, isEqual);

const FocusModeEnabledMessage: ComponentType<{
  focusModeExceptions: IMainSettingsDoc["focusModeExceptions"];
}> = memo((props) => {
  const exceptions =
    props.focusModeExceptions
      ?.map((priority) => {
        switch (priority) {
          case 100:
            return "@@@interrupts";
          case 200:
            return "@@response-requested";
          case 300:
            return "@mentions";
        }
      })
      .filter(isNonNullable) || [];

  return (
    <Wrapper>
      <span className="text-2xl">Focus Mode is Enabled</span>

      <DescriptionWrapper>
        <div className="prose text-center">
          <p>
            You've enabled Focus Mode. All inbox notifications are hidden
            {exceptions.length > 0 && ` except ${exceptions.join(" and ")}`}.
          </p>
        </div>

        <div className="flex flex-1 justify-center mt-4">
          <button
            type="button"
            className="border rounded py-1 px-3 hover:cursor-pointer hover:bg-slate-5"
            onClick={() => {
              mergeMainSettings({ enableFocusMode: false });
            }}
          >
            Disable Focus Mode
          </button>
        </div>
      </DescriptionWrapper>
    </Wrapper>
  );
}, isEqual);

const ScheduledDeliveryEnabledMessage: ComponentType<{}> = memo(() => {
  return (
    <Wrapper>
      <span className="text-2xl">Inbox Zero &nbsp; 🎉</span>

      <NextScheduledDelivery />

      <DescriptionWrapper>
        <div className="prose text-center">
          <p>
            Comms uses Scheduled Delivery. Messages only appear in your inbox at
            customizable times rather than immediately after the message is
            sent.
          </p>
        </div>

        <div className="flex flex-1 justify-center mt-4">
          <Link
            to="/settings#scheduled-delivery"
            className="border rounded py-1 px-3 hover:cursor-pointer hover:bg-slate-5"
          >
            Update Scheduled Delivery Settings
          </Link>
        </div>
      </DescriptionWrapper>
    </Wrapper>
  );
}, isEqual);

const FocusModeAndScheduledDeliveryEnabledMessage: ComponentType<{
  settings: IMainSettingsDoc;
}> = memo((props) => {
  const exceptions =
    props.settings.focusModeExceptions
      ?.map((priority) => {
        switch (priority) {
          case 100:
            return "@@@interrupts";
          case 200:
            return "@@response-requested";
          case 300:
            return "@mentions";
        }
      })
      .filter(isNonNullable) || [];

  return (
    <Wrapper>
      <span className="text-2xl">
        Focus Mode and Scheduled Delivery are Enabled
      </span>

      <NextScheduledDelivery />

      <DescriptionWrapper>
        <div className="prose text-center">
          <p>
            You've enabled Focus Mode. Focus Mode is hiding all inbox
            notifications
            {exceptions.length > 0 && ` except ${exceptions.join(" and ")}`}.
            Separately, Comms uses Scheduled Delivery. Scheduled delivery causes
            inbox notifications to appear in your inbox at customizable times
            rather than immediately after the message is sent.
          </p>
        </div>

        <div className="flex flex-1 justify-center mt-4">
          <button
            type="button"
            className="border rounded py-1 px-3 hover:cursor-pointer hover:bg-slate-5"
            onClick={() => {
              mergeMainSettings({ enableFocusMode: false });
            }}
          >
            Disable Focus Mode
          </button>

          <div className="w-8" />

          <Link
            to="/settings#scheduled-delivery"
            className="border rounded py-1 px-3 hover:cursor-pointer hover:bg-slate-5"
          >
            Update Scheduled Delivery Settings
          </Link>
        </div>
      </DescriptionWrapper>
    </Wrapper>
  );
}, isEqual);

const Wrapper: ComponentType<{}> = (props) => {
  return (
    <div className="mx-12 py-8 flex flex-col justify-center items-center bg-slate-2 rounded mb-20 text-slate-11">
      {props.children}
    </div>
  );
};

const DescriptionWrapper: ComponentType<{}> = (props) => {
  return (
    <div className="mx-10 flex flex-col max-w-[600px] min-w-0 rounded p-4">
      {props.children}
    </div>
  );
};

const NextScheduledDelivery: ComponentType<{}> = () => {
  const nextDeliveryDate = useNextScheduledDeliveryDatetime();

  if (!nextDeliveryDate) return null;

  return (
    <div className="mb-4 mx-10 flex justify-center prose max-w-[600px] min-w-0 p-4 text-lg">
      Your next delivery will be{" "}
      {nextDeliveryDate.isSame(dayjs(), "date")
        ? "today"
        : nextDeliveryDate.format("dddd")}{" "}
      at {nextDeliveryDate.format("h:mm a")}.
    </div>
  );
};
