import { useEffect } from "react";
import {
  DialogState,
  DialogTitle,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { createFormControl, useControl } from "solid-forms-react";
import {
  onSubmitFn,
  observable,
  useControlState,
} from "~/form-components/utils";
import { useRegisterCommands } from "~/services/command.service";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { firstValueFrom } from "rxjs";
import {
  CURRENT_USER_MAIN_SETTINGS$,
  mergeMainSettings,
  TNormalizedMainSettingsDoc,
} from "~/services/settings.service";
import { TextInput } from "~/form-components/TextInput";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { closeDialogCommand } from "~/utils/common-commands";
import * as DialogLayout from "~/dialogs/DialogLayout";
import { toast } from "~/services/toast-service";

export type IEditUndoSendWindowDialogData = {
  settings: TNormalizedMainSettingsDoc;
};

export type IEditUndoSendWindowDialogReturnData = {
  success: boolean;
} | void;

export const EditUndoSendWindowDialogState = new DialogState<
  IEditUndoSendWindowDialogData,
  IEditUndoSendWindowDialogReturnData
>();

export const EditUndoSendWindowDialog = withModalDialog({
  dialogState: EditUndoSendWindowDialogState,
  async loadData() {
    return {
      settings: await firstValueFrom(CURRENT_USER_MAIN_SETTINGS$),
    };
  },
  Component({ data }) {
    if (!data) {
      throw new Error("Missing required data");
    }

    const control = useControl(() =>
      createFormControl(String(data.settings.secondsForUndoingSentMessage)),
    );

    useEffect(() => {
      observable(() => control.rawValue).subscribe((rawValue) => {
        const value = parseInt(rawValue, 10);

        const isInvalid = isNaN(value) || value < 0;

        if (isInvalid) {
          control.setErrors({ invalid: true });
        } else {
          control.setErrors(null);
        }
      });
    }, [control]);

    const hasErrors = useControlState(() => !!control.errors, [control]);

    useRegisterCommands({
      commands: () => {
        return [
          closeDialogCommand({
            callback: () => {
              EditUndoSendWindowDialogState.toggle(false);
            },
          }),
        ];
      },
    });

    return (
      <>
        <DialogTitle>
          <h2>
            How many seconds do you want to have to undo sending a message?
          </h2>
        </DialogTitle>

        <div className={DIALOG_CONTENT_WRAPPER_CSS}>
          <form
            onSubmit={onSubmitFn(control, submit)}
            className="flex flex-col p-4"
          >
            <label htmlFor="undo-send-window-value">
              After sending a message, how many seconds should Comms wait before
              actually sending that message? During this time, you will be able
              to undo the sending of the message. A value of 0 means that the
              message will be sent instantly and you won't be able to undo
              sending it.
            </label>

            <TextInput
              id="undo-send-window-value"
              name="Undo send duration"
              type="number"
              control={control}
              className="px-2 py-1 my-2 border focus-within:border-blue-9 rounded border-slate-8"
            />

            {hasErrors && (
              <div className="text-red-10 font-medium">
                Must be greater than or equal to zero.
              </div>
            )}
          </form>

          <DialogLayout.DialogFooter>
            <DialogLayout.DialogSubmitButton
              onClick={() => {
                if (!control.isValid) {
                  control.markTouched(true);
                  return;
                }

                submit(control.value);
              }}
            />
          </DialogLayout.DialogFooter>
        </div>
      </>
    );
  },
});

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(async (value: string) => {
    console.log("submitting...");

    EditUndoSendWindowDialogState.toggle(false);

    mergeMainSettings({
      secondsForUndoingSentMessage: parseInt(value, 10),
    });

    toast("vanilla", {
      subject: "Saved.",
    });
  }),
);
