import { ComponentType, useMemo, Fragment, memo } from "react";
import { useCurrentUserThreadSubscription } from "~/services/subscription.service";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { DisplayDate } from "~/components/content-list";
import { Tooltip } from "~/components/Tooltip";
import { css, cx } from "@emotion/css";
import { slate, yellow } from "@radix-ui/colors";
import { BsFillStarFill, BsLockFill, BsStar } from "react-icons/bs";
import { ISubscriptionDoc } from "@libs/firestore-models";
import { Link } from "react-router-dom";
import {
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import { KBarState } from "~/dialogs/kbar";
import { DEFAULT_SUBSCRIPTION_PREFERENCE } from "@libs/firestore-models/utils";
import { UnreachableCaseError } from "@libs/utils/errors";
import { useObservable } from "~/utils/useObservable";
import { WINDOW_SIZE$ } from "~/services/window.service";
import { distinctUntilChanged, map } from "rxjs";
import { IconButton } from "@mui/material";
import { MdEmail, MdRssFeed } from "react-icons/md";
import { OutlineButton } from "~/components/OutlineButtons";
import { useThreadContext } from "./context";
import { RiGitBranchLine } from "react-icons/ri";

/* -------------------------------------------------------------------------------------------------
 * ThreadHeader
 * -----------------------------------------------------------------------------------------------*/

export const ThreadHeader: ComponentType<{}> = () => {
  const context = useThreadContext();
  const thread = context.useThread();
  const notification = context.useNotification();
  const branchedThread = context.useBranchedThread();

  const nonSharedChannels = thread.__local.knownPermittedChannels.filter(
    (channel) => !channel.isOrganizationSharedChannel,
  );

  const isNarrowScreen = useObservable(
    () => {
      return WINDOW_SIZE$.pipe(
        map(({ width }) => width <= 600),
        distinctUntilChanged(),
      );
    },
    { synchronous: true },
  );

  const showSubheader = !!(
    thread.permittedChannelIds.length > 0 || notification?.triagedUntil
  );

  const isStarred = notification?.isStarred || false;

  return (
    <div className="flex flex-col pt-7 pb-6 px-8 border-l-[3px] border-transparent">
      <div className="flex">
        {thread.visibility === "private" && (
          <Tooltip
            side="bottom"
            content="This thread is private and only visible to the recipients"
          >
            <span className="text-2xl inline-flex h-6 mr-2 hover:cursor-help mt-1">
              <small>
                <BsLockFill />
              </small>
            </span>
          </Tooltip>
        )}

        {thread.type === "EMAIL" && (
          <Tooltip side="bottom" content="This is an email thread.">
            <span className="text-2xl inline-flex h-5 mr-2 hover:cursor-help mt-1">
              <small>
                <MdEmail />
              </small>
            </span>
          </Tooltip>
        )}

        <Tooltip content={thread.subject} side="bottom">
          <h1 className="text-2xl line-clamp-3" style={{ lineHeight: 1.2 }}>
            {thread.subject}
          </h1>
        </Tooltip>

        <div className="flex-1" />

        {!isNarrowScreen && (
          <HeaderSubscriptionAndStarredButtons
            isStarred={isStarred}
            className="ml-4"
          />
        )}
      </div>

      {thread.branchedFrom && (
        <div className="mt-2 flex items-center">
          <RiGitBranchLine size="1.5rem" className="mr-2" />

          {(!branchedThread || branchedThread.visibility === "private") && (
            <BsLockFill size="1.2rem" className="mr-2" />
          )}

          {!branchedThread?.subject ? (
            <em>permission denied</em>
          ) : (
            <>
              {branchedThread.type === "EMAIL" && (
                <MdEmail className="mr-1 shrink-0" size="1.1rem" />
              )}

              <Tooltip side="bottom" content={branchedThread.subject}>
                <Link
                  to={`/threads/${thread.branchedFrom.threadId}`}
                  className="italic hover:underline truncate inline-flex items-center"
                >
                  {branchedThread.subject}
                </Link>
              </Tooltip>
            </>
          )}
        </div>
      )}

      {showSubheader && (
        <div className="mt-2">
          {notification?.triagedUntil && (
            <span
              className={cx(
                thread.visibility === "private" ? "text-plum-8" : "text-plum-9",
              )}
            >
              Remind me:{" "}
              <DisplayDate date={notification.triagedUntil} size="lg" />
            </span>
          )}

          {notification?.triagedUntil && nonSharedChannels.length > 0 && (
            <span className="mx-3">&bull;</span>
          )}

          {nonSharedChannels.map((channel, index) => {
            return (
              <Fragment key={channel.id}>
                {index !== 0 && <span className="mr-2">,</span>}

                <Link
                  to={`/channels/${channel.id}`}
                  className="hover:underline"
                >
                  #{channel.name}
                </Link>
              </Fragment>
            );
          })}
        </div>
      )}

      {isNarrowScreen && (
        <HeaderSubscriptionAndStarredButtons
          isStarred={isStarred}
          className="mt-3"
        />
      )}
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const HeaderSubscriptionAndStarredButtons: ComponentType<{
  isStarred: boolean;
  className?: string;
}> = (props) => {
  return (
    <div className={cx("flex space-x-2", props.className)}>
      {props.isStarred ? (
        <Tooltip side="bottom" content="Unstar thread (S → R)">
          {/* The span element is for layout purposes. */}
          <span>
            <IconButton
              tabIndex={-1}
              size="small"
              onClick={() => {
                unstarThreadCommand.trigger();
              }}
            >
              <BsFillStarFill className={starCSS} />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <Tooltip side="bottom" content="Star thread (S → R)">
          {/* The span element is for layout purposes. */}
          <span>
            <IconButton
              tabIndex={-1}
              size="small"
              onClick={() => {
                starThreadCommand.trigger();
              }}
            >
              <BsStar className={noStarCSS} />
            </IconButton>
          </span>
        </Tooltip>
      )}

      <SubscriptionLevel />
    </div>
  );
};

const starCSS = cx(
  "stroke-[0.5] text-yellow-10 stroke-yellow-11",
  css`
    &.text-yellow-10.stroke-yellow-11 {
      color: ${yellow.yellow10};
    }
  `,
);

const noStarCSS = cx(
  "stroke-[0.5] text-slate-10",
  css`
    &.text-slate-10 {
      color: ${slate.slate10};
    }
  `,
);

/* -----------------------------------------------------------------------------------------------*/

const SubscriptionLevel: ComponentType<{}> = memo(() => {
  const thread = useThreadContext().useThread();
  const { hintText, badgeText, shortcutEffect } = useSubscriptionText();

  return (
    <Tooltip
      side="bottom"
      content={
        <>
          <p className="text-center">{hintText}</p>
          <p className="mt-1">
            <em>
              (press <kbd>S</kbd> to {shortcutEffect} )
            </em>
          </p>
        </>
      }
    >
      <span>
        <OutlineButton
          tabIndex={-1} // Doesn't need to be tabbable because of hotkeys
          theme={thread.visibility === "private" ? "dark" : "light"}
          onClick={(e) => {
            if (badgeText === "loading") return;
            e.preventDefault();
            KBarState.toggle(true, {
              path: ["Update subscription"],
              mode: "hotkey",
            });
          }}
        >
          <MdRssFeed className="mr-1 text-slate-11" />{" "}
          <small>{badgeText}</small>
        </OutlineButton>
      </span>
    </Tooltip>
  );
});

/* -------------------------------------------------------------------------------------------------
 * useSubscriptionText
 * -----------------------------------------------------------------------------------------------*/

export function useSubscriptionText() {
  const { currentUser } = useAuthGuardContext();
  const thread = useThreadContext().useThread();
  const subscription = useCurrentUserThreadSubscription(thread.id);

  return useMemo(() => {
    const isParticipating = thread.participatingUserIds.includes(
      currentUser.id,
    );

    return getSubscriptionText(
      subscription && subscription.preference,
      isParticipating,
    );
  }, [thread, subscription, currentUser]);
}

/* -----------------------------------------------------------------------------------------------*/

function getSubscriptionText(
  preference: ISubscriptionDoc["preference"] | null | undefined,
  isParticipatingInThread: boolean,
) {
  const normalizedPreference: ISubscriptionDoc["preference"] | undefined =
    isParticipatingInThread
      ? "all"
      : preference === null
      ? DEFAULT_SUBSCRIPTION_PREFERENCE
      : preference;

  switch (normalizedPreference) {
    case "all": {
      return {
        badgeText: "Everything",
        hintText: "You will receive all notifications for this thread.",
        shortcutEffect: "unsubscribe",
      } as const;
    }
    case "all-new":
    case "involved": {
      return {
        badgeText: "Mentions",
        hintText: `You will only receive a notification if a reply is addressed
          to you or @mentions you.`,
        shortcutEffect: "subscribe",
      } as const;
    }
    case undefined: {
      return {
        badgeText: "loading",
        hintText: "loading",
        shortcutEffect: "",
      } as const;
    }
    default: {
      throw new UnreachableCaseError(normalizedPreference);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/
