import {
  DialogState,
  DialogTitle,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { useThread } from "~/services/post.service";
import { useRegisterCommands } from "~/services/command.service";
import { Tooltip } from "~/components/Tooltip";
import {
  UsersWhoCanViewThreadSection,
  UsersWhoWillReceiveThreadNotificationsSection,
} from "~/components/ThreadContext";

/* -------------------------------------------------------------------------------------------------
 * ThreadRecipientInfoDialog
 * -----------------------------------------------------------------------------------------------*/

export const ThreadRecipientInfoDialogState = new DialogState<unknown>();

export const ThreadRecipientInfoDialog = withModalDialog<{ threadId: string }>({
  dialogState: ThreadRecipientInfoDialogState,
  useOnDialogContainerRendered() {
    useRegisterCommands({
      commands() {
        return [
          {
            label: "View reply recipient details",
            altLabels: [
              {
                render: "View reply visibility details",
                keywords: ["Reply visibility"],
              },
            ],
            callback() {
              ThreadRecipientInfoDialogState.toggle(true);
            },
          },
        ];
      },
    });
  },
  Component: (props) => {
    const thread = useThread(props.threadId);

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              ThreadRecipientInfoDialogState.toggle(false);
            },
          },
        ];
      },
    });

    return (
      <>
        <DialogTitle>
          <h2>Thread Recipients</h2>
        </DialogTitle>

        <div className={DIALOG_CONTENT_WRAPPER_CSS}>
          <div className="m-4">
            <p className="text-slate-9">Have received notifications</p>

            <Tooltip
              side="bottom"
              content={`Comms currently does not record which users have 
            already received notifications for messages. The users who "will 
            receive replies" will often be very different from those  
            who have received replies previously.`}
            >
              <div className="mt-2 cursor-help">Unknown (learn more).</div>
            </Tooltip>
          </div>

          <UsersWhoWillReceiveThreadNotificationsSection
            thread={thread}
            onlyCountTheseSubscriptionPreferences={
              subscriptionsOfUsersWhoWillReceiveReplies
            }
          >
            Will receive replies
            <Tooltip
              side="bottom"
              content="Replies will be delivered to the inbox of these users."
            >
              <span className="ml-1 cursor-help">(?)</span>
            </Tooltip>
          </UsersWhoWillReceiveThreadNotificationsSection>

          <UsersWhoCanViewThreadSection thread={thread}>
            Can view
            <Tooltip
              side="bottom"
              content={`These users have permission to view this thread. 
            Separately, they may or may not be subscribed to notifications
            for this thread.`}
            >
              <span className="ml-1 cursor-help">(?)</span>
            </Tooltip>
          </UsersWhoCanViewThreadSection>
        </div>

        <div className="flex mt-4 justify-center">
          <div className="bg-blackA-11 text-white rounded px-4 py-1 text-sm">
            <strong>Hint:</strong> this dialog is available via the "View reply
            recipient details" command.
          </div>
        </div>
      </>
    );
  },
});

const subscriptionsOfUsersWhoWillReceiveReplies = ["all"] as const;
