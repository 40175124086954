import { ComponentType, forwardRef, useEffect } from "react";
import {
  AutocompleteSelect,
  getFuzzyFilteringFn,
  IOption,
  onChangeMultiHandler,
  TAutocompleteSelectRef,
} from "~/form-components/AutocompleteSelect";
import {
  createFormControl,
  createFormGroup,
  IFormControl,
  useControl,
} from "solid-forms-react";
import { observable, useControlState } from "~/form-components/utils";
import {
  mergeMainSettings,
  TNormalizedMainSettingsDoc,
  updateScheduledDelivery,
} from "~/services/settings.service";
import { distinctUntilChanged, throttleTime } from "rxjs";
import { SwitchInput, SwitchPrimitive } from "~/form-components/SwitchInput";
import { cx } from "@emotion/css";
import { isDefined, isNonNullable } from "@libs/utils/predicates";
import { IMainSettingsDoc } from "@libs/firestore-models";
import { getClassForScheduledDeliveryTourStep } from "~/services/lesson-service/lessons/scheduled-delivery-walkthrough";
import {
  getAndAssertCurrentUser,
  updateCurrentUser,
} from "~/services/user.service";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Tooltip } from "~/components/Tooltip";
import "react-phone-number-input/style.css";
import { markAllLessonsForCurrentUserIncomplete } from "~/services/lesson-service";
import { OutlineButton } from "~/components/OutlineButtons";
import {
  EditScheduledDeliveryDialog,
  EditScheduledDeliveryState,
  SCHEDULED_DELIVERY_TIMES_OPTIONS,
} from "./EditScheduledDeliveryDialog";
import { dayOfWeekComparer, stringComparer } from "@libs/utils/comparers";
import { ToggleScheduledDeliveryDialogState } from "~/dialogs/toggle-scheduled-delivery/ToggleScheduledDeliveryDialog";
import { isEqual } from "@libs/utils/isEqual";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import {
  EditUserProfileDialog,
  EditUserProfileState,
} from "./EditUserProfileDialog";
import {
  EditSecondsToWaitToDisableScheduledDeliveryDialog,
  EditSecondsToWaitToDisableScheduledDeliveryDialogState,
} from "./EditSecondsToWaitToDisableScheduledDeliveryDialog";
import { linkGmailEmailAccount as linkGmailEmailAccountServerFn } from "~/services/post.service";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import {
  EditUndoSendWindowDialog,
  EditUndoSendWindowDialogState,
} from "./EditUndoSendWindowDialog";
import { EditInboxSectionsDialogState } from "~/dialogs/edit-inbox-sections/EditInboxSectionsDialog";
import { useDarkModeContext } from "~/components/DarkMode";
import useLocalStorageState from "use-local-storage-state";

/* -------------------------------------------------------------------------------------------------
 * SettingsForm
 * -----------------------------------------------------------------------------------------------*/

const WHITELISTED_USER_IDS_FOR_EMAIL_MVP: string[] = [
  // "yEGLlRWaUwU6QYpTreu6ZlGwyth1", // sam
  // "4kUsq8LcjlOjt10oWeaCCYdkdYW2", // john
  // "eDmV79VHnJbUhiXZYj1m16SMPxh1", // miz
  // "tdy7xa0ErdPw5OLLixoEySWPRwy1", // josh
];

export const SettingsForm: ComponentType<{
  settings: TNormalizedMainSettingsDoc;
}> = (props) => {
  const { currentUser } = useAuthGuardContext();
  const control = useControl(() => controlFactory(props.settings));

  useAutosaveForm(control);

  const isFocusModeEnabled = useControlState(
    () => control.rawValue.enableFocusMode,
    [control],
  );

  return (
    <>
      <h3 id="user-profile" className="text-xl font-bold mb-2">
        User Profile
      </h3>

      <UserProfile />

      {(origin !== "https://commsbylevels.com" ||
        WHITELISTED_USER_IDS_FOR_EMAIL_MVP.includes(currentUser.id)) && (
        <>
          <h3 id="link-gmail-emails" className="text-xl font-bold mt-12 mb-2">
            Link Email Account
          </h3>

          <div className="mt-2 text-sm">
            <em>
              Linking your gmail email account will allow you to view your
              emails within Comms.
            </em>
          </div>

          <div className="my-2">
            {props.settings.linkedGmailEmailAccount === "reauthorize"
              ? "You need to re-link your email"
              : props.settings.linkedGmailEmailAccount
              ? `${currentUser.email} is linked`
              : "Email is not linked"}
          </div>

          {!props.settings.linkedGmailEmailAccount && (
            <OutlineButton onClick={() => linkGmailEmailAccount()}>
              Link your email account
            </OutlineButton>
          )}
        </>
      )}

      <h3
        id="notification-preferences"
        className="text-xl font-bold mt-12 mb-2"
      >
        Notification preferences
      </h3>

      <EnableUrgentTexts control={control.controls.interruptMessageText} />

      <h3 id="inbox-layout" className="text-xl font-bold mt-12">
        Inbox
      </h3>

      <EnableBlockingInbox control={control.controls.enableBlockingInbox} />

      <div className="prose mt-2 text-sm">
        <ul>
          <li>
            <em>
              When the blocking inbox layout is enabled, notifications in your
              inbox will be grouped by priority but only notifications in the
              highest priority group will be visible to you. You will need to
              triage (either by marking "Done" or by setting a reminder) all the
              messages in the highest priority group before you can see messages
              in the next highest priority group. The idea is that you force
              yourself to read higher priority messages before reading lower
              priority ones.
            </em>
          </li>
        </ul>
      </div>

      <NavigateBackWhenMarkingThreadDone
        control={control.controls.enableNavBackOnThreadDone}
      />

      <div className="prose mt-2 text-sm">
        <ul>
          <li>
            <em>
              By default, marking a thread as "Done" will also attempt to
              navigate you to the next thread, where "next thread" is context
              specific. E.g. if you open a thread in your inbox and then mark it
              as Done, you'll also be taken directly to the next thread in your
              inbox. Use this setting to instead navigate you "Back". E.g. with
              this setting enabled, if you open a thread in your inbox and then
              mark it as Done, you'll also be taken back to your inbox.
            </em>
          </li>
        </ul>
      </div>

      <UndoSendWindowDuration settings={props.settings} />

      <div className="mt-4">
        <OutlineButton
          onClick={() => {
            EditInboxSectionsDialogState.toggle(true);
          }}
        >
          Edit inbox sections
        </OutlineButton>
      </div>

      <div className={getClassForScheduledDeliveryTourStep(50)}>
        <h3 id="scheduled-delivery" className="text-xl font-bold mt-12">
          Scheduled Delivery (
          {props.settings.enableScheduledDelivery ? "on" : "off"})
        </h3>

        <div className="mt-2 text-sm">
          <em>
            Comms uses something we call <strong>Scheduled Delivery</strong>.
            Messages which aren't marked "@@@urgent" will only be delivered to
            your inbox at the specified days and times. Messages of other
            priority levels will be hidden from your inbox but will still be
            available via search or by viewing their thread directly. If you
            disable scheduled delivery, hidden messages will immediately become
            visible in your inbox.
          </em>
        </div>

        <EnableScheduledDelivery settings={props.settings} />

        {props.settings.enableScheduledDelivery && (
          <ScheduledDeliverySettings settings={props.settings} />
        )}

        <ChangeTheDisableScheduledDeliveryDifficulty />
      </div>

      <h3 id="focus-mode" className="text-xl font-bold mt-12">
        Focus Mode ({isFocusModeEnabled ? "on" : "off"})
      </h3>

      <div className="mt-2 text-sm">
        <em>
          When focus mode is enabled, only messages of the chosen priority
          levels will be displayed to your inbox. Messages of other priority
          levels will be hidden from your inbox but will still be available via
          search or by viewing their thread directly. When you disable focus
          mode, hidden messages will immediately become visible in your inbox.
        </em>
      </div>

      <EnableFocusMode control={control.controls.enableFocusMode} />

      <FocusModePriority control={control.controls.focusModeExceptions} />

      <h3 id="general" className="text-xl font-bold mt-12 mb-3">
        General
      </h3>

      <ToggleDarkMode />
      <ToggleAlwaysMakeHeaderSticky />

      <h3 id="guided-tours" className="text-xl font-bold mt-12 mb-3">
        Guided Tours
      </h3>

      <button
        type="button"
        onClick={markAllLessonsForCurrentUserIncomplete}
        className="border rounded py-1 px-3 hover:cursor-pointer hover:bg-slate-5"
      >
        Mark all guided tours as incomplete
      </button>
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

type TForm = ReturnType<typeof controlFactory>;

function controlFactory(settingsDoc: TNormalizedMainSettingsDoc) {
  const currentUser = getAndAssertCurrentUser();

  const focusModeExceptions = settingsDoc.focusModeExceptions
    .map((priority) =>
      FOCUS_MODE_PRIORITY_OPTIONS.find((o) => o.value === priority),
    )
    .filter(isNonNullable);

  return createFormGroup({
    interruptMessageText: createFormControl(
      currentUser.notificationPreferences.interruptMessageText,
    ),
    enableBlockingInbox: createFormControl(
      settingsDoc.inboxLayout === "blocking-inbox",
    ),
    enableNavBackOnThreadDone: createFormControl(
      settingsDoc.enableNavBackOnThreadDone,
    ),
    enableFocusMode: createFormControl(settingsDoc.enableFocusMode),
    focusModeExceptions:
      createFormControl<IOption<number>[]>(focusModeExceptions),
  });
}

/* -----------------------------------------------------------------------------------------------*/

function useAutosaveForm(control: TForm) {
  useEffect(() => {
    const save = () => {
      if (!control.isValid) return;

      const value = control.value;

      if (isDefined(value.interruptMessageText)) {
        updateCurrentUser({
          interruptMessageText: value.interruptMessageText,
        });
      }

      let inboxLayout: IMainSettingsDoc["inboxLayout"];

      if (isDefined(value.enableBlockingInbox)) {
        inboxLayout = value.enableBlockingInbox
          ? "blocking-inbox"
          : "consolidated-inbox";
      }

      // Note that this form doesn't handle ScheduledDelivery changes.
      // The EnableScheduledDelivery component handles that.
      mergeMainSettings({
        inboxLayout,
        enableNavBackOnThreadDone: value.enableNavBackOnThreadDone,
        enableFocusMode: value.enableFocusMode,
        focusModeExceptions: value.focusModeExceptions?.map((o) => o.value),
      });
    };

    const sub = observable(() => control.value)
      .pipe(
        throttleTime(500, undefined, { leading: false, trailing: true }),
        distinctUntilChanged(isEqual),
      )
      .subscribe(save);

    return () => {
      sub.unsubscribe();
      save();
    };
  }, [control]);
}

/* -----------------------------------------------------------------------------------------------*/

const UserProfile: ComponentType<{}> = () => {
  const { currentUser } = useAuthGuardContext();

  return (
    <>
      <div className="my-4 prose">
        <p>Your phone number?</p>

        <ul>
          <li>
            <p>
              <em>
                {currentUser.phoneNumber
                  ? formatPhoneNumberIntl(currentUser.phoneNumber)
                  : "unknown"}
              </em>
            </p>
          </li>
        </ul>
      </div>

      <OutlineButton onClick={() => EditUserProfileState.toggle(true)}>
        Edit User Profile
      </OutlineButton>

      <EditUserProfileDialog />
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const EnableUrgentTexts: ComponentType<{
  control: IFormControl<boolean>;
}> = (props) => {
  const { currentUser } = useAuthGuardContext();
  const hasPhoneNumber = !!currentUser.phoneNumber;

  // mark control as disabled if appropriate
  useEffect(() => {
    props.control.markDisabled(!hasPhoneNumber);
  }, [props.control, hasPhoneNumber]);

  const value = useControlState(
    () => (props.control.isDisabled ? false : props.control.value),
    [props.control],
  );

  const isDisabled = useControlState(
    () => props.control.isDisabled,
    [props.control],
  );

  return (
    <Tooltip
      side="bottom"
      content={
        isDisabled
          ? "You must provide a phone number to receive text notifications"
          : ""
      }
    >
      <div className={cx("flex items-center my-4", isDisabled && "opacity-30")}>
        <label htmlFor="enable-urgent-texts">
          Do you want to receive text notifications for{" "}
          <code className="prose">@@@urgent</code> messages?
        </label>

        <div className="w-4" />

        <SwitchPrimitive
          id="enable-urgent-texts"
          checked={value}
          onCheckedChange={(e) => {
            props.control.setValue(e);
          }}
          onBlur={() => props.control.markTouched(true)}
          disabled={isDisabled}
        />
      </div>
    </Tooltip>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const EnableBlockingInbox: ComponentType<{
  control: IFormControl<boolean>;
}> = (props) => {
  return (
    <div className="flex items-center my-4">
      <label htmlFor="enable-blocking-inbox">Enable blocking inbox</label>
      <div className="w-4" />
      <SwitchInput id="enable-blocking-inbox" control={props.control} />
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const NavigateBackWhenMarkingThreadDone: ComponentType<{
  control: IFormControl<boolean>;
}> = (props) => {
  return (
    <div className="flex items-center my-4">
      <label htmlFor="enable-nav-back-on-thread-done">
        Navigate "Back" when marking a thread as Done
      </label>

      <div className="w-4" />

      <SwitchInput
        id="enable-nav-back-on-thread-done"
        control={props.control}
      />
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const UndoSendWindowDuration: ComponentType<{
  settings: TNormalizedMainSettingsDoc;
}> = (props) => {
  return (
    <>
      <div className="my-4 prose">
        <p>How many seconds do you want to have to undo sending a message?</p>

        <ul>
          <li>
            <p>
              {props.settings.secondsForUndoingSentMessage} second
              {props.settings.secondsForUndoingSentMessage !== 1 && "s"}
            </p>
          </li>
        </ul>
      </div>

      <OutlineButton onClick={() => EditUndoSendWindowDialogState.toggle(true)}>
        Edit Sent Message Undo Window
      </OutlineButton>

      <EditUndoSendWindowDialog />
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const EnableFocusMode: ComponentType<{
  control: IFormControl<boolean>;
}> = (props) => {
  return (
    <div className="flex items-center my-4">
      <label htmlFor="enable-focus-mode">Enable focus mode</label>
      <div className="w-4" />
      <SwitchInput id="enable-focus-mode" control={props.control} />
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const FocusModePriority = forwardRef<
  TAutocompleteSelectRef<IOption<number>, true>,
  {
    control: IFormControl<IOption<number>[]>;
    autocompletePortalEl?: HTMLDivElement | null;
  }
>((props, ref) => {
  const value = useControlState(() => props.control.rawValue, [props.control]);

  return (
    <div className="flex flex-col my-4">
      <label htmlFor="focus-mode-priority">
        What messages should still be visible when focus mode is on?
      </label>

      <AutocompleteSelect
        ref={ref}
        multiple
        name="focus-mode-priority"
        placeholder="Select priority levels..."
        loadOptions={getFuzzyFilteringFn(FOCUS_MODE_PRIORITY_OPTIONS)}
        value={value}
        classNames="px-2 my-2 border border-slate-8 focus-within:border-blue-9 rounded"
        onBlur={() => props.control.markTouched(true)}
        onChange={onChangeMultiHandler(props.control)}
        menuPortalTarget={props.autocompletePortalEl}
      />
    </div>
  );
});

const FOCUS_MODE_PRIORITY_OPTIONS: IOption<number>[] = [
  { label: "Urgent (@@@)", value: 100 },
  {
    label: "Response requested (@@)",
    value: 200,
  },
  { label: "Mentioned (@)", value: 300 },
];

/* -----------------------------------------------------------------------------------------------*/

const EnableScheduledDelivery: ComponentType<{
  settings: TNormalizedMainSettingsDoc;
}> = (props) => {
  const control = useControl(() =>
    createFormControl(props.settings.enableScheduledDelivery, {
      disabled: true,
    }),
  );

  // ensure that the control value reflects the value persisted in
  // MainSettings
  useEffect(() => {
    control.markDisabled(false);
    control.setValue(!!props.settings.enableScheduledDelivery);
    control.markDisabled(true);
  }, [control, props.settings.enableScheduledDelivery]);

  return (
    <div className="flex items-center my-4">
      <label htmlFor="enable-scheduled-delivery">
        Enable scheduled delivery
      </label>
      <div className="w-4" />
      <span
        onClick={() => {
          if (
            props.settings.enableScheduledDelivery &&
            props.settings.secondsToWaitToDisableScheduledDelivery
          ) {
            ToggleScheduledDeliveryDialogState.toggle(true);
          } else {
            updateScheduledDelivery(!props.settings.enableScheduledDelivery);
          }
        }}
      >
        <SwitchInput
          id="enable-scheduled-delivery"
          control={control}
          className="pointer-events-none"
        />
      </span>
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ScheduledDeliverySettings: ComponentType<{
  settings: TNormalizedMainSettingsDoc;
}> = (props) => {
  const scheduledDays = props.settings.scheduledDays
    ?.toSorted(dayOfWeekComparer)
    .join(", ");

  const scheduledTimes = props.settings.scheduledTimes
    ?.toSorted(stringComparer)
    ?.map(
      (time) =>
        SCHEDULED_DELIVERY_TIMES_OPTIONS.find((o) => o.value === time)?.label,
    )
    .filter(isNonNullable)
    .join(", ");

  return (
    <>
      <div className="my-4 prose">
        <p>What days of the week should we deliver messages?</p>

        <ul>
          <li>
            <p>
              <em>{scheduledDays}</em>
            </p>
          </li>
        </ul>

        <p className="mt-4">
          What hours of the day should we deliver messages?
        </p>

        <ul>
          <li>
            <p>
              <em>{scheduledTimes}</em>
            </p>
          </li>
        </ul>
      </div>

      <OutlineButton
        onClick={() =>
          EditScheduledDeliveryState.toggle(true, {
            settings: props.settings,
          })
        }
      >
        Edit Scheduled Delivery Days/Times
      </OutlineButton>

      <EditScheduledDeliveryDialog />
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ChangeTheDisableScheduledDeliveryDifficulty: ComponentType<{}> = () => {
  return (
    <div className="mt-4">
      <OutlineButton
        onClick={() =>
          EditSecondsToWaitToDisableScheduledDeliveryDialogState.toggle(true)
        }
      >
        Change the "disable scheduled delivery" difficulty
      </OutlineButton>

      <EditSecondsToWaitToDisableScheduledDeliveryDialog />
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

// We may wish to use this in the future
//
// const toggleFocusMode = (enableFocusMode: boolean) =>
//   onlyCallFnOnceWhilePreviousCallIsPending(
//     withPendingRequestBar(async () => {
//       const settingsDoc = await firstValueFrom(CURRENT_USER_MAIN_SETTINGS$);
//
//       const hasTheUserEverEnabledFocusModeBefore =
//         !!settingsDoc?.focusModeExceptions;
//
//       if (hasTheUserEverEnabledFocusModeBefore) {
//         mergeMainSettings({ enableFocusMode });
//       } else {
//         // If the user has never enabled focus mode before,
//         // we take them to the focus mode section of the
//         // settings so that they can learn more about it
//         // and do any initial setup that's necessary.
//         EditMainSettingsDialogState.toggle(true, {
//           focus: "focus-mode-section",
//           settingsDoc,
//         });
//       }
//     }),
//   );

/* -----------------------------------------------------------------------------------------------*/

async function linkGmailEmailAccount() {
  const response = await withPendingRequestBar(
    linkGmailEmailAccountServerFn({
      redirectUrl: location.href,
    }),
  );

  if (!response.data.success) return;

  location.href = response.data.data.authorizationUrl;
}

/* -----------------------------------------------------------------------------------------------*/

const origin = new URL(location.href).origin;

/* -----------------------------------------------------------------------------------------------*/

const ToggleDarkMode: ComponentType<{}> = () => {
  const context = useDarkModeContext();

  const control = useControl(() =>
    createFormControl(context.isActive$.getValue()),
  );

  useEffect(() => {
    const sub = context.isActive$.subscribe((value) => {
      if (value === control.value) return;
      control.setValue(value);
    });

    return () => sub.unsubscribe();
  }, [control, context]);

  return (
    <div className="mt-4 flex">
      <label htmlFor="toggle-dark-mode">Enable dark mode</label>
      <div className="w-4" />
      <SwitchInput
        id="toggle-dark-mode"
        control={control}
        onCheckedChange={(e) => {
          if (e !== context.isActive$.getValue()) {
            context.toggle();
          }
        }}
      />
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ToggleAlwaysMakeHeaderSticky: ComponentType<{}> = () => {
  const [alwaysMakeHeaderSticky, setAlwaysMakeHeaderSticky] =
    useAlwaysMakeHeaderStickySetting();

  const control = useControl(() => createFormControl(alwaysMakeHeaderSticky));

  useEffect(() => {
    const sub = observable(() => control.value).subscribe(
      setAlwaysMakeHeaderSticky,
    );

    return () => sub.unsubscribe();
  }, [control, setAlwaysMakeHeaderSticky]);

  return (
    <div className="mt-4 flex">
      <label htmlFor="toggle-always-make-header-sticky">
        Force the header on Comms pages to always be "sticky"
      </label>
      <div className="w-4" />
      <SwitchInput id="toggle-always-make-header-sticky" control={control} />
    </div>
  );
};

export function useAlwaysMakeHeaderStickySetting() {
  const { currentUser } = useAuthGuardContext();

  return useLocalStorageState(
    `${currentUser.id}.settings.forceHeaderToBeSticky`,
    { defaultValue: false },
  );
}

/* -----------------------------------------------------------------------------------------------*/
