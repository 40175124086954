import { INotificationDoc, IThreadReadStatusDoc } from "@libs/firestore-models";
import { isEqual } from "@libs/utils/isEqual";
import { createContext, useContext, useMemo } from "react";
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
} from "rxjs";
import { IDraft, observeDraftForThread } from "~/services/draft.service";
import { observeInboxNotification } from "~/services/inbox.service";
import {
  IObservePost,
  IObserveThread,
  observeThread,
  observeThreadPosts,
  observeThreadReadStatus,
} from "~/services/post.service";
import { useObservable } from "~/utils/useObservable";
import { useLocation } from "react-router";
import { UnreachableCaseError } from "@libs/utils/errors";
import { startWith } from "@libs/utils/rxjs-operators";

/* -------------------------------------------------------------------------------------------------
 * ThreadContext
 * -----------------------------------------------------------------------------------------------*/

export type IThreadContext = {
  isLoading$: Observable<boolean>;
  thread$: Observable<IObserveThread>;
  threadPosts$: Observable<IObservePost[]>;
  draftForThread$: Observable<IDraft | null>;
  threadReadStatus$: Observable<IThreadReadStatusDoc | null>;
  notification$: Observable<INotificationDoc | null>;
  branchedThread$: Observable<IObserveThread | null>;
  branchedThreadPosts$: Observable<IObservePost[] | null>;
  isOnlyDraft$: Observable<boolean>;
  useIsLoading: () => boolean;
  useThread: () => IObserveThread;
  useThreadPosts: () => IObservePost[];
  useDraftForThread: () => IDraft | null;
  useThreadReadStatus: () => IThreadReadStatusDoc | null;
  useNotification: () => INotificationDoc | null;
  useBranchedThread: () => IObserveThread | null;
  useBranchedThreadPosts: () => IObservePost[] | null;
  useIsOnlyDraft: () => boolean;
};

export const ThreadContext = createContext<IThreadContext | null>(null);

export function useThreadContext() {
  const c = useContext(ThreadContext);

  if (c === null || c === undefined) {
    throw new Error(`Oops! You need to provide ThreadContext`);
  } else if ("isOnlyDraft" in c) {
    throw new Error(`Oops! Context equals "only-draft"`);
  }

  return c;
}

/* -------------------------------------------------------------------------------------------------
 * useGetThreadContextData
 * -----------------------------------------------------------------------------------------------*/

export function threadContextObservables(threadId: string) {
  const rawThread$ = observeThread(threadId);

  const branchedThread$ = rawThread$.pipe(
    map((t) => t?.branchedFrom),
    distinctUntilChanged(isEqual),
    switchMap((branchedFrom) =>
      !branchedFrom ? of(null) : observeThread(branchedFrom.threadId),
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  const branchedThreadPosts$ = rawThread$.pipe(
    map((t) => t?.branchedFrom),
    distinctUntilChanged(isEqual),
    switchMap((branchedFrom) =>
      !branchedFrom
        ? of(null)
        : observeThreadPosts(branchedFrom.threadId, {
            endAt: [
              branchedFrom.postSentAt,
              branchedFrom.postScheduledToBeSentAt,
            ],
            noFromCache: true,
          }),
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  const threadPosts$ = observeThreadPosts(threadId);
  const draftForThread$ = observeDraftForThread(threadId);
  const threadReadStatus$ = observeThreadReadStatus(threadId);
  const notification$ = observeInboxNotification(threadId);

  const thread$ = combineLatest([rawThread$, draftForThread$]).pipe(
    map(([thread, draft]) => {
      const threadExists =
        thread &&
        (!draft ||
          // When unsending the first post in a thread (i.e. unsending a thread),
          // it's possible for the draft observable to emit with the draft document
          // before the thread observable emits with `null`.
          thread.__local.fromUnsafeDraft?.id !== draft.id);

      return threadExists ? thread : null;
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  const isLoading$ = combineLatest([
    thread$,
    threadPosts$,
    draftForThread$,
    threadReadStatus$,
    notification$,
    branchedThread$,
    branchedThreadPosts$,
  ]).pipe(
    map(() => false),
    startWith(() => true),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  const isOnlyDraft$ = combineLatest([
    isLoading$,
    thread$,
    draftForThread$,
  ]).pipe(
    filter(([isLoading]) => !isLoading),
    map(([, thread, draft]) => {
      if (thread) {
        return false;
      } else if (draft) {
        return true;
      } else {
        return false;
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  return {
    isLoading$,
    thread$,
    threadPosts$,
    draftForThread$,
    threadReadStatus$,
    notification$,
    branchedThread$,
    branchedThreadPosts$,
    isOnlyDraft$,
  };
}

export function useGetThreadContext(threadId?: string) {
  const context = useGetThreadContextInner(threadId);

  return useMemo(() => {
    return {
      ...context,
      useIsLoading: () =>
        useObservable(() => context.isLoading$, {
          synchronous: true,
          deps: [context.isLoading$],
        }),
      useThread: () =>
        useObservable(() => context.thread$, {
          deps: [context.thread$],
        }),
      useThreadPosts: () =>
        useObservable(() => context.threadPosts$, {
          deps: [context.threadPosts$],
        }),
      useDraftForThread: () =>
        useObservable(() => context.draftForThread$, {
          deps: [context.draftForThread$],
        }),
      useThreadReadStatus: () =>
        useObservable(() => context.threadReadStatus$, {
          deps: [context.threadReadStatus$],
        }),
      useNotification: () =>
        useObservable(() => context.notification$, {
          deps: [context.notification$],
        }),
      useBranchedThread: () =>
        useObservable(() => context.branchedThread$, {
          deps: [context.branchedThread$],
        }),
      useBranchedThreadPosts: () =>
        useObservable(() => context.branchedThreadPosts$, {
          deps: [context.branchedThreadPosts$],
        }),
      useIsOnlyDraft: () =>
        useObservable(() => context.isOnlyDraft$, {
          deps: [context.isOnlyDraft$],
        }),
    };
  }, [context]);
}

function useGetThreadContextInner(threadId?: string) {
  const location = useLocation();

  if (!threadId) {
    // Hook order is always preserved
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo(() => {
      return {
        isLoading$: of(true),
        thread$: of(null),
        threadPosts$: of([]),
        draftForThread$: of(null),
        threadReadStatus$: of(null),
        notification$: of(null),
        branchedThread$: of(null),
        branchedThreadPosts$: of([]),
        isOnlyDraft$: of(false),
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadId, "null"]);
  } else if (location.pathname.startsWith("/threads")) {
    // Hook order is always preserved
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo(
      () => threadContextObservables(threadId),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [threadId, "/threads"],
    );
  } else {
    throw new UnreachableCaseError(location.pathname as never);
  }
}
