import { ComponentType, useEffect, useRef } from "react";
import { useParams } from "react-router";
import { useForceSidebarIntoMode } from "~/page-layouts/sidebar-layout";
import { Helmet } from "react-helmet-async";
import { NotFound } from "~/components/NotFound";
import { ListScrollbox } from "~/components/list";
import * as ThreadLayout from "~/page-layouts/thread-layout";
import { useTopScrollShadow } from "~/utils/useScrollShadow";
import { PendingRequestBar } from "~/components/PendingRequestBar";
import { ICommandArgs, useRegisterCommands } from "~/services/command.service";
import { slate } from "@radix-ui/colors";
import { useIsAppOnline } from "~/services/network-connection.service";
import { closeViewCommand } from "~/utils/common-commands";
import { useSetBackgroundColor } from "~/services/theme.service";
import { LoadingText } from "~/components/LoadingText";
import {
  IThreadContext,
  ThreadContext,
  useGetThreadContext,
  useThreadContext,
} from "./context";
import { ThreadPosts } from "./ThreadPosts";
import { closeThreadView } from "./utils";
import { ThreadHeader } from "./ThreadHeader";
import { navigationHistory } from "~/services/navigate.service";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";
import { ActionToolbar } from "./ActionToolbar";
import { useObservable } from "~/utils/useObservable";
import { map } from "rxjs";
import { IDraft } from "~/services/draft.service";

/* -------------------------------------------------------------------------------------------------
 * ThreadView
 * -----------------------------------------------------------------------------------------------*/

export const ThreadView: ComponentType<{}> = () => {
  const params = useParams();
  const context = useGetThreadContext(params.threadId);
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);
  const isAppOnline = useIsAppOnline();

  useSetBackgroundColor(slate.slate3);

  useRegisterThreadViewCommands();

  useForceSidebarIntoMode("over");

  const isLoading = context.useIsLoading();
  const isOnlyDraft = context.useIsOnlyDraft();
  const isNotFound = useObservable(
    () => context.thread$.pipe(map((thread) => !thread)),
    { deps: [context.thread$] },
  );

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
    deps: [isLoading, isNotFound],
  });

  useRedirectToDraftDialogIfOnlyDraft(
    context as {
      useIsOnlyDraft: () => boolean;
      useDraftForThread: () => IDraft | null;
    },
  );

  if (isLoading || isOnlyDraft) {
    return (
      <PendingRequestBar>
        <LoadingText />
      </PendingRequestBar>
    );
  }

  if (isNotFound) {
    if (!isAppOnline) {
      return <NotFound title="App Offline" />;
    }

    return <NotFound title="Thread Not Found" />;
  }

  return (
    <ThreadContext.Provider value={context as IThreadContext}>
      <div className="MainPanel">
        <ThreadViewHeader headerRef={headerRef} />

        <div className="flex">
          <ThreadLayout.ActionPanel>
            <ActionToolbar />
          </ThreadLayout.ActionPanel>

          <ThreadLayout.ContentPanel>
            <ListScrollbox
              isBodyElement
              offsetHeaderEl={headerRef}
              onlyOffsetHeaderElIfSticky
            >
              <ThreadPosts />
            </ListScrollbox>
          </ThreadLayout.ContentPanel>

          <div className="flex-1" />
        </div>
      </div>

      {/* 
        We're testing removing the sidebar in the thread view
        -- John 4/28/23
      */}
      {/* <ThreadInfoPanel thread={data.thread} /> */}
    </ThreadContext.Provider>
  );
};

const ThreadViewHeader: ComponentType<{
  headerRef: React.RefObject<HTMLElement>;
}> = (props) => {
  const thread = useThreadContext().useThread();

  return (
    <>
      <Helmet>
        <title>{thread.subject} | Thread | Comms</title>
      </Helmet>

      <ThreadLayout.Header
        ref={props.headerRef}
        theme={thread.visibility === "private" ? "dark" : "light"}
      >
        <ThreadLayout.ActionPanel />

        <ThreadLayout.ContentPanel>
          <ThreadHeader />
        </ThreadLayout.ContentPanel>

        <div className="flex-1" />
      </ThreadLayout.Header>
    </>
  );
};

/**
 * These commands will be available to someone on the thread
 * view even if the thread hasn't loaded.
 */
function useRegisterThreadViewCommands() {
  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        closeViewCommand({
          callback() {
            closeThreadView();
          },
        }),
      ];

      return commands;
    },
  });
}

/* -----------------------------------------------------------------------------------------------*/

function useRedirectToDraftDialogIfOnlyDraft(props: {
  useIsOnlyDraft: () => boolean;
  useDraftForThread: () => IDraft | null;
}) {
  const isOnlyDraft = props.useIsOnlyDraft();
  const draft = props.useDraftForThread();

  useEffect(() => {
    if (!isOnlyDraft || !draft?.id) return;

    if (navigationHistory.index === 0) {
      openComposeNewThreadDialog(draft.id, {
        andNavigateToPathname: "/index",
      });
    } else {
      openComposeNewThreadDialog(draft.id, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        andNavigateToPathname: navigationHistory.current(-1)!.pathname,
      });
    }
  }, [isOnlyDraft, draft?.id]);
}

/* -----------------------------------------------------------------------------------------------*/
