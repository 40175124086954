import { ComponentType, useEffect, useRef } from "react";
import { IListRef, ListScrollbox } from "~/components/list";
import * as ThreadLayout from "~/page-layouts/thread-layout";
import { slate } from "@radix-ui/colors";
import { useObservable } from "~/utils/useObservable";
import { useSetBackgroundColor } from "~/services/theme.service";
import { Header } from "../Header";
import { IPostDoc } from "@libs/firestore-models";
import { ContentList } from "~/components/content-list";
import useConstant from "use-constant";
import {
  combineLatest,
  distinctUntilChanged,
  map,
  NEVER,
  Subject,
  switchMap,
} from "rxjs";
import {
  observePost,
  observeThread,
  useBranchedThreadPosts,
} from "~/services/post.service";
import {
  useAddSharedChannelsOnRecipientChanges,
  useAutosaveDraft,
  useRegisterSharedComposeNewMessageCommands,
  useUpdateDraftTypeOnRecipientChanges,
  useUpdateRecipientsOnDraftTypeChanges,
  useUpdateRecipientsOnVisibilityChanges,
  useUpdateVisibilityOnRecipientChanges,
} from "../utils";
import { SetNonNullable } from "@libs/utils/type-helpers";
import { useControl } from "solid-forms-react";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { ComposeInfoPanel } from "../ComposeInfoPanel";
import { BranchedThreadDraft } from "./BranchedThreadDraft";
import {
  createComposeMessageForm,
  IComposeMessageFormValue,
  IComposeMessageForm,
} from "~/components/ComposeMessageContext";
import { useTopScrollShadow } from "~/utils/useScrollShadow";
import {
  getDraftDataStorageKey,
  mapRecipientOptionToDraftRecipient,
  unsendDraft,
  updateNewDraft,
} from "~/services/draft.service";
import dayjs from "dayjs";
import { sessionStorageService } from "~/services/session-storage.service";
import { toast } from "~/services/toast-service";
import {
  closeComposeNewThreadDialog,
  openComposeNewThreadDialog,
} from "~/page-dialogs/page-dialog-state";
import { isEqual } from "@libs/utils/isEqual";
import { focusDraft } from "~/components/ComposeReplyBase";
import { IRichTextEditorRef } from "~/form-components/post-editor";
import { useRegisterCommands } from "~/services/command.service";
import {
  collapseAllPostsCommand,
  expandAllPostsCommand,
} from "~/utils/common-commands";
import { QuoteBranchedThreadPosts } from "./QuoteBranchedThreadPosts";
import { observable, useControlState } from "~/form-components/utils";
import { PendingRequestBar } from "~/components/PendingRequestBar";
import { LoadingText } from "~/components/LoadingText";
import { TBranchedThreadEntry } from "./utils";

/* -------------------------------------------------------------------------------------------------
 * ComposeBranchedThread
 * -----------------------------------------------------------------------------------------------*/

export const ComposeBranchedThread: ComponentType<{
  initialFormValues: SetNonNullable<IComposeMessageFormValue, "branchedFrom">;
}> = (props) => {
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const listRef = useRef<IListRef<TBranchedThreadEntry>>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<IRichTextEditorRef>(null);

  const control = useControl(() =>
    createComposeMessageForm(props.initialFormValues, {
      recipientsRequired: true,
    }),
  );

  const branchedFrom = useSyncControlBranchedFromValueWithBranchedPost(control);

  useUpdateDraftTypeOnRecipientChanges(control);
  useUpdateRecipientsOnDraftTypeChanges(control);
  useUpdateVisibilityOnRecipientChanges(control);
  useUpdateRecipientsOnVisibilityChanges(control);
  useAddSharedChannelsOnRecipientChanges({
    control,
    walkthroughNotCompleted: false,
  });

  useSetBackgroundColor(slate.slate3);

  useAutosaveDraft(control);

  useRegisterSharedComposeNewMessageCommands({
    control,
    submit,
  });

  const {
    subject: branchedThreadSubject,
    visibility: branchedThreadVisibility,
  } = useThreadSubjectAndVisibility(
    props.initialFormValues.branchedFrom.threadId,
  );

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  const collapsePostEvents$ = useConstant(
    () => new Subject<"expand" | "collapse" | string>(),
  );

  const loadMorePostsButtonFocusEvents$ = useConstant(
    () => new Subject<void>(),
  );

  useRegisterComposeBranchedMessageCommands({
    collapsePostEvents$,
  });

  const branchedPosts = useBranchedThreadPosts(branchedFrom || undefined);

  if (!branchedFrom) {
    console.error("ComposeBranchedThread: control branchedFrom is falsey");
    return null;
  }

  return (
    <>
      <div className="MainPanel md-w:mr-[280px] flex flex-col">
        <Header
          ref={headerRef}
          control={control}
          isLessonComplete={true}
          branchedFromThreadId={branchedFrom.threadId}
          branchedThreadSubject={branchedThreadSubject}
          branchedThreadVisibility={branchedThreadVisibility}
        />

        {!branchedPosts ? (
          <PendingRequestBar>
            <LoadingText />
          </PendingRequestBar>
        ) : (
          <ListScrollbox
            isBodyElement
            offsetHeaderEl={headerRef}
            onlyOffsetHeaderElIfSticky
          >
            <ThreadLayout.ContentPanel className="mx-auto flex-1 flex flex-col">
              <ContentList<IPostDoc>
                focusOnMouseOver={false}
                onArrowDownOverflow={() => {
                  focusDraft(control, editorRef);
                }}
                initiallyFocusEntryId={undefined}
              >
                <QuoteBranchedThreadPosts
                  branchedFrom={branchedFrom}
                  firstBranchPosts={branchedPosts}
                  collapsePostEvents={collapsePostEvents$}
                  loadMorePostsButtonFocusEvents={
                    loadMorePostsButtonFocusEvents$
                  }
                />
              </ContentList>

              <BranchedThreadDraft
                ref={editorRef}
                control={control}
                listRef={listRef}
                treatLessonAsCompleted={true}
              />
            </ThreadLayout.ContentPanel>
          </ListScrollbox>
        )}
      </div>

      <ComposeInfoPanel control={control} />
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

/**
 * This hook observes the post that this draft has been branched from
 * and updates this draft's `branchedFrom` value as appropriate if
 * the associated post is updated.
 *
 * ### Why is this needed?
 *
 * If a user replies to a thread and then immediately branches off of
 * their reply post (i.e. while the post is still being optimistically rendered
 * from the unsent draft doc), the reply post will have estimated sentAt &
 * scheduledToBeSentAt values. These sentAt/scheduledToBeSentAt values will
 * update two times:
 *
 * 1. When sending the draft for the first time, Comms will set the draft's
 *    scheduledToBeSentAt to be a serverTimestamp(). The server timestamp is
 *    set on the server, but the local Firestore cache will update to
 *    optimistically reflect this change using `null` for the serverTimestamp()
 *    value while it's waiting for the server to return with the real
 *    serverTimestamp value. Our business logic will replace this `null`
 *    serverTimestamp() value with a timestamp generated from `new Date()`.
 *    Later, when the draft update is syncronized with the server, this client
 *    side estimated scheduledToBeSentAt value will be replaced with the
 *    timestamp set by the server.
 * 2. When the server picks up the draft and actually converts it to a post and
 *    sends it, the real post will have updated sentAt and scheduledToBeSentAt
 *    values.
 */
function useSyncControlBranchedFromValueWithBranchedPost(
  control: IComposeMessageForm,
) {
  useEffect(() => {
    const sub = observable(() => control.rawValue.branchedFrom?.postId)
      .pipe(
        switchMap((branchedFromPostId) => {
          if (!branchedFromPostId) return NEVER;

          return combineLatest([
            observable(() => control.rawValue.branchedFrom),
            observePost(branchedFromPostId),
          ]);
        }),
      )
      .subscribe(([branchedFrom, branchedFromPost]) => {
        if (!branchedFrom || !branchedFromPost) return;

        const isSentAtEqual =
          branchedFrom.postSentAt.valueOf() ===
          branchedFromPost.sentAt.valueOf();

        const isScheduledToBeSentAtEqual =
          branchedFrom.postScheduledToBeSentAt.valueOf() ===
          branchedFromPost.scheduledToBeSentAt.valueOf();

        if (isSentAtEqual && isScheduledToBeSentAtEqual) return;

        control.patchValue({
          branchedFrom: {
            postSentAt: branchedFromPost.sentAt,
            postScheduledToBeSentAt: branchedFromPost.scheduledToBeSentAt,
          },
        });
      });

    return () => sub.unsubscribe();
  }, [control]);

  const branchedFrom = useControlState(
    () => control.rawValue.branchedFrom,
    [control],
  );

  return branchedFrom;
}

/* -----------------------------------------------------------------------------------------------*/

function useRegisterComposeBranchedMessageCommands(args: {
  collapsePostEvents$: Subject<"expand" | "collapse" | string>;
}) {
  useRegisterCommands({
    commands() {
      return [
        expandAllPostsCommand({
          callback: () => {
            args.collapsePostEvents$.next("expand");
          },
        }),
        collapseAllPostsCommand({
          callback: () => {
            args.collapsePostEvents$.next("collapse");
          },
        }),
      ];
    },
    deps: [args.collapsePostEvents$],
  });
}

/* -----------------------------------------------------------------------------------------------*/

function useThreadSubjectAndVisibility(threadId: string) {
  return useObservable(
    () =>
      observeThread(threadId).pipe(
        map((thread) => ({
          subject: thread?.subject,
          visibility: thread?.visibility,
        })),
        distinctUntilChanged(isEqual),
      ),
    {
      initialValue: { subject: undefined, visibility: undefined },
      deps: [threadId],
    },
  );
}

/* -----------------------------------------------------------------------------------------------*/

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  async (
    values: IComposeMessageFormValue,
    options: { sendImmediately?: boolean } = {},
  ) => {
    console.log("submitting...", values);

    if (values.visibility === null) {
      console.error("Attempted to send post with visibility === null");
      return;
    }

    // We want the new post form to close immediately without
    // waiting for this promise to resolve.
    // See `createNewDraft` jsdoc.
    updateNewDraft({
      type: values.type,
      postId: values.postId,
      subject: values.subject,
      bodyHTML: values.body.content,
      branchedFrom: null,
      to: values.recipients.to.map((r) =>
        mapRecipientOptionToDraftRecipient(r, values.type),
      ),
      cc: values.recipients.cc.map((r) =>
        mapRecipientOptionToDraftRecipient(r, values.type),
      ),
      bcc: values.recipients.bcc.map((r) =>
        mapRecipientOptionToDraftRecipient(r, values.type),
      ),
      visibility: values.visibility,
      channelMentions: values.body.channelMentions,
      userMentions: values.body.userMentions,
      scheduledToBeSentAt: options.sendImmediately
        ? new Date()
        : dayjs().add(20_000, "ms").toDate(),
    })
      .then(() => console.log("submitted successfully!"))
      .catch(console.error);

    const key = getDraftDataStorageKey(values.postId);

    sessionStorageService.setItem(key, {
      sent: true,
    });

    sessionStorageService.deleteItem(key);

    if (options.sendImmediately) {
      toast("vanilla", {
        subject: "Message sent!",
      });
    } else {
      toast("undo", {
        subject: "Message sent!",
        onAction: async () => {
          const success = await unsendDraft({ postId: values.postId });

          if (!success) return;

          openComposeNewThreadDialog(values.postId);
        },
      });
    }

    closeComposeNewThreadDialog();
  },
);

/* -----------------------------------------------------------------------------------------------*/
