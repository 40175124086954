import type { EmailAddress, Mailbox } from "@libs/utils/email-rfc";

/**
 * If passed a group of addresses, will only return the `label`
 * value.
 */
export function parseEmailAddress(email: EmailAddress): {
  label: string | null;
  emailAddress: string | null;
  /** Formatted as either `"${label}" <${emailAddress}>` or label or emailAddress */
  rawValue: string | null;
};
export function parseEmailAddress(email?: null): null;
export function parseEmailAddress(email?: EmailAddress | null): null | {
  label: string | null;
  emailAddress: string | null;
  /** Formatted as either `"${label}" <${emailAddress}>` or label or emailAddress */
  rawValue: string | null;
};
export function parseEmailAddress(email?: EmailAddress | null) {
  if (!email) return null;

  const label = email?.label || email?.address || null;
  const emailAddress = email?.address || null;
  const rawValue =
    email?.label && email?.address
      ? `"${email.label}" <${email.address}>`
      : label;

  return {
    label,
    emailAddress,
    rawValue,
  };
}

export function parseStringToEmailAddress(input: string): Mailbox | null {
  let match =
    input.match(emailWithLabelAndQuotesRegex) ||
    input.match(emailWithLabelRegex);

  if (match) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const label = match[1]!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const email = match[2]!;

    return {
      label,
      address: email,
    };
  } else if ((match = input.match(EMAIL_ADDRESS_REGEXP))) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const email = match[0]!;

    return {
      address: email,
    };
  } else {
    console.debug("Failed to parse email string", input);
    return null;
  }
}

/**
 * Starts with one or more characters, then an "@" symbol,
 * then one or more characters, then a period, then one or
 * more characters.
 *
 * Note that, technically, the only requirement for an email is
 * a character, then "@", then a character. This regexp is
 * intentionally more strict.
 */
export const EMAIL_ADDRESS_REGEXP = /^.+@.+\..+/;
const emailWithLabelAndQuotesRegex = /^"(.*)" <(.+@.+\..+)>/;
const emailWithLabelRegex = /^(.*) <(.+@.+\..+)>/;
