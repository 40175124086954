import { IThreadDoc } from "@libs/firestore-models";
import { ComponentType } from "react";
import { Observable } from "rxjs";
import { BranchedPostEntry } from "./BranchedPostEntry";
import { QuoteBranchedThreadPostsBase } from "~/components/QuoteBranchedThreadPostsBase";
import { IObservePost } from "~/services/post.service";

/* -------------------------------------------------------------------------------------------------
 * QuoteBranchedThreadPosts
 * -----------------------------------------------------------------------------------------------*/

export const QuoteBranchedThreadPosts: ComponentType<{
  branchedFrom: NonNullable<IThreadDoc["branchedFrom"]>;
  firstBranchPosts: IObservePost[];
  collapsePostEvents: Observable<string>;
  loadMorePostsButtonFocusEvents: Observable<void>;
}> = (props) => {
  return (
    <QuoteBranchedThreadPostsBase
      branchCreatedAt="now"
      renderPostEntry={({ post, index, isFirstBranch, postsCount }) => {
        return (
          <BranchedPostEntry
            key={post.id}
            post={post}
            isLastPost={isFirstBranch && index === postsCount - 1}
            collapsePostEvents={props.collapsePostEvents}
            relativeOrder={index}
          />
        );
      }}
      {...props}
    />
  );
};
