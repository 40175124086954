import { Editor as CoreEditor } from "@tiptap/core";
import { forwardRef, memo, Ref, useCallback, useRef } from "react";
import { cx } from "@emotion/css";
import { useControlState } from "../utils";
import { useComposedRefs } from "~/utils/useComposedRefs";
import { ISearchEditorRef, SearchEditorBase } from "./SearchEditorBase";
import { IFormControl, IFormGroup } from "solid-forms-react";
import { ParsedToken, searchQueryParser } from "@libs/utils/searchQueryParser";
import { useRegisterCommands } from "~/services/command.service";
import { fromEventPattern, map, merge, switchMap } from "rxjs";
import { startWith } from "@libs/utils/rxjs-operators";
import { isEqual } from "@libs/utils/isEqual";

export interface ISearchEditorProps {
  control: IFormGroup<{
    queryHTML: IFormControl<string>;
    queryText: IFormControl<string>;
    parsedQuery: IFormControl<ParsedToken[]>;
  }>;
  editorRef?: Ref<ISearchEditorRef>;
  onEditorStartOverflow?: () => void;
  onEditorEndOverflow?: () => void;
  initialTabIndex?: number;
}

export const SearchEditor = memo(
  forwardRef<HTMLDivElement, ISearchEditorProps>((props, forwardedRef) => {
    const control = props.control;

    const editorRef = useRef<ISearchEditorRef>(null);
    const composeRefs = useComposedRefs(props.editorRef, editorRef);

    const isInvalid = useControlState(() => !control.isValid, [control]);

    const isTouched = useControlState(() => control.isTouched, [control]);

    const getInitialValue = useCallback(() => {
      return control.controls.queryHTML.rawValue;
      // we only use this once to get the initial value
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = useCallback(
      ({ editor }: { editor: CoreEditor }) => {
        const getTextOptions = { blockSeparator: "\n" };
        const errorOptions = { source: "QueryParser" };

        const parsedQuery = searchQueryParser.parse(
          editor.getText(getTextOptions),
        );

        if (parsedQuery.type === "ParseOK") {
          control.patchValue({
            queryHTML: editor.isEmpty ? "" : editor.getHTML(),
            queryText: editor.isEmpty ? "" : editor.getText(getTextOptions),
            parsedQuery: parsedQuery.value,
          });

          control.setErrors(null, errorOptions);
        } else {
          control.patchValue({
            queryHTML: editor.isEmpty ? "" : editor.getHTML(),
            queryText: editor.isEmpty ? "" : editor.getText(getTextOptions),
            parsedQuery: [],
          });

          control.setErrors({ error: "parsing error" }, errorOptions);
        }
      },
      [control],
    );

    const onBlur = useCallback(() => {
      control.markTouched(true);
    }, [control]);

    // Register "Show suggestions" command
    useRegisterCommands({
      commands() {
        const onCreate$ = editorRef.current?.onCreate$;

        if (!onCreate$) return [];

        return onCreate$.pipe(
          switchMap((editor) => {
            const editorEvent =
              (event: "focus" | "blur") =>
              (handler: (...args: unknown[]) => void) =>
                editor.on(event, handler);

            return merge(
              fromEventPattern(editorEvent("focus")).pipe(map(() => true)),
              fromEventPattern(editorEvent("blur")).pipe(map(() => false)),
            ).pipe(
              startWith(() => editor.isFocused),
              map((isFocused) => {
                if (!isFocused) return [];

                return [
                  {
                    label: "Show suggestions",
                    showInKbar: false,
                    hotkeys: ["Control+Space"],
                    triggerHotkeysWhenInputFocused: true,
                    callback: () => {
                      editor.commands.toggleSearchSuggestions();
                    },
                  },
                ];
              }),
            );
          }),
        );
      },
    });

    const isFieldEmpty = useControlState(
      () =>
        control.controls.queryHTML.rawValue === "<p></p>" ||
        !control.controls.queryHTML.rawValue,
      [control],
    );

    const placeholder = (
      <span
        className={cx(
          "absolute whitespace-nowrap pointer-events-none text-2xl",
          isTouched && isInvalid ? "text-red-9" : "text-slateDark-11",
          { hidden: !isFieldEmpty },
        )}
      >
        text...
      </span>
    );

    return (
      <div
        ref={forwardedRef}
        className={cx(
          "relative flex flex-col w-full px-4 py-2 pr-[5.5rem] rounded overflow-y-auto",
          "bg-slate-3 focus-within:bg-slate-3",
          "text-2xl text-slate-11",
          "border border-slate-5 focus-within:border-slate-11",
        )}
      >
        {placeholder}

        <SearchEditorBase
          ref={composeRefs}
          onChange={onChange}
          onBlur={onBlur}
          onEditorStartOverflow={props.onEditorStartOverflow}
          onEditorEndOverflow={props.onEditorEndOverflow}
          getInitialValue={getInitialValue}
          initialTabIndex={props.initialTabIndex}
          className="max-h-[140px]"
        />
      </div>
    );
  }),
  isEqual,
);
