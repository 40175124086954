/**
 * This service is responsible for designating one Comms tab as
 * the "Leader" tab. Important when there are multiple tabs open
 * but you only want work to be performed by one of them.
 */

import { BehaviorSubject } from "rxjs";
import { resolvablePromise } from "@libs/utils/resolvablePromise";
import { stripIndent } from "common-tags";

export const IS_LEADER$ = new BehaviorSubject(false);

function initializeTabLeaderService() {
  if (!("locks" in navigator)) {
    alert(stripIndent`
      Your web browser doesn't support the Web Locks API.
      Some functionality (e.g. image uploads) may not work.
    `);

    return;
  }

  const promise = resolvablePromise();

  // As tabs are created, they request a lock on the "LEADER_TAB"
  // key. The first tab will successfully receive that lock and
  // holds it by providing a promise that never resolves--until,
  // that is, the tab is closed. When the tab is closed the
  // next-most-recently-opened-tab will receive the lock and be
  // promoted to the leader.
  navigator.locks.request("LEADER_TAB", async () => {
    IS_LEADER$.next(true);
    return promise;
  });

  IS_LEADER$.subscribe((isLeader) => {
    console.debug("Tab is leader:", isLeader);
  });
}

if (import.meta.env.MODE !== "test") {
  initializeTabLeaderService();
}
