import { createContext, useContext, useEffect } from "react";
import { BehaviorSubject, Observable, Subject } from "rxjs";

export type TSidebarLayoutFocusEvent = "Sidebar" | "Outlet";
export type TSidebarLayoutMode = "push" | "over";

export interface ISidebarLayoutContext {
  focusEvent$: Subject<TSidebarLayoutFocusEvent>;
  sidebarOpen$: BehaviorSubject<boolean>;
  sidebarMode$: BehaviorSubject<TSidebarLayoutMode>;
  sidebarForceMode$: BehaviorSubject<TSidebarLayoutMode | null>;
  normalizedSidebarMode$: Observable<TSidebarLayoutMode>;
}

export const SidebarLayoutContext = createContext<ISidebarLayoutContext | null>(
  null,
);

export function useSidebarLayoutContext() {
  const context = useContext(SidebarLayoutContext);

  if (!context) {
    throw new Error(
      `Oops! Looks like you forgot to provide the SidebarLayoutContext`,
    );
  }

  return context;
}

export function useForceSidebarIntoMode(mode: TSidebarLayoutMode) {
  const { sidebarForceMode$ } = useSidebarLayoutContext();

  useEffect(() => {
    sidebarForceMode$.next(mode);
    return () => sidebarForceMode$.next(null);
  }, [mode, sidebarForceMode$]);
}
