import { ComponentType, useEffect, useState } from "react";
import {
  DialogState,
  DialogTitle,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { createFormControl, useControl } from "solid-forms-react";
import { useControlState } from "~/form-components/utils";
import { ICommandArgs, useRegisterCommands } from "~/services/command.service";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import {
  combineLatest,
  firstValueFrom,
  interval,
  map,
  of,
  scan,
  switchMap,
  takeWhile,
} from "rxjs";
import {
  CURRENT_USER_MAIN_SETTINGS$,
  TNormalizedMainSettingsDoc,
  updateScheduledDelivery,
} from "~/services/settings.service";
import { TextInput } from "~/form-components/TextInput";
import { WINDOW_FOCUSED$, WINDOW_VISIBLE$ } from "~/services/focus.service";
import { cx } from "@emotion/css";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { closeDialogCommand } from "~/utils/common-commands";
import { Link } from "react-router-dom";

export type IToggleScheduledDeliveryDialogData = {
  settings: TNormalizedMainSettingsDoc;
};

export type IToggleScheduledDeliveryDialogReturnData = {
  success: boolean;
} | void;

export const ToggleScheduledDeliveryDialogState = new DialogState<
  IToggleScheduledDeliveryDialogData,
  IToggleScheduledDeliveryDialogReturnData
>();

export const ToggleScheduledDeliveryDialog = withModalDialog({
  dialogState: ToggleScheduledDeliveryDialogState,
  useOnDialogContainerRendered() {
    useRegisterCommands({
      commands: () => {
        return CURRENT_USER_MAIN_SETTINGS$.pipe(
          map((settings) => {
            const commands: ICommandArgs[] = [];

            if (settings.enableScheduledDelivery) {
              const callback = settings.secondsToWaitToDisableScheduledDelivery
                ? () => ToggleScheduledDeliveryDialogState.toggle(true)
                : () => updateScheduledDelivery(false);

              commands.push({
                label: "Disable scheduled delivery",
                keywords: ["Enable scheduled delivery"],
                altLabels: ["Toggle scheduled delivery"],
                callback,
              });
            } else {
              commands.push({
                label: "Enable scheduled delivery",
                keywords: ["Disable scheduled delivery"],
                altLabels: ["Toggle scheduled delivery"],
                callback: () => {
                  updateScheduledDelivery(true);
                },
              });
            }

            return commands;
          }),
        );
      },
    });
  },
  async loadData() {
    return {
      settings: await firstValueFrom(CURRENT_USER_MAIN_SETTINGS$),
    };
  },
  Component({ data }) {
    if (!data) {
      throw new Error("Missing required data");
    }

    const [currentStep, setCurrentStep] = useState(1);
    const [submitAttempt, setSubmitAttempt] = useState(false);

    useRegisterCommands({
      commands: () => {
        return [
          closeDialogCommand({
            callback: () => {
              ToggleScheduledDeliveryDialogState.toggle(false);
            },
          }),
        ];
      },
    });

    return (
      <>
        <DialogTitle>
          <h2>Disable Scheduled Delivery</h2>
        </DialogTitle>

        <form
          // we intentionally don't allow submitting this form
          // with the keyboard
          onSubmit={(e) => {
            e.preventDefault();
            setSubmitAttempt(true);
          }}
          className={DIALOG_CONTENT_WRAPPER_CSS}
        >
          {currentStep === 2 ? (
            <SecondStep
              initialTimerValue={
                data.settings.secondsToWaitToDisableScheduledDelivery
              }
            />
          ) : (
            <FirstStep
              submitAttempt={submitAttempt}
              onClick={() => setCurrentStep(2)}
            />
          )}
        </form>
      </>
    );
  },
});

const FirstStep: ComponentType<{
  submitAttempt: boolean;
  onClick: () => void;
}> = (props) => {
  const control = useControl(() => {
    return createFormControl("", {
      validators: (value) =>
        value.toLowerCase() === firstStepPassword.toLowerCase()
          ? null
          : { invalid: true },
    });
  });

  useEffect(() => {
    if (!props.submitAttempt) return;
    control.markTouched(true);
  }, [props.submitAttempt, control]);

  const hasErrors = useControlState(() => !!control.errors, [control]);

  return (
    <>
      <div className="p-4">
        <p>
          Are you sure you wish to disable scheduled delivery? After doing this,
          messages will show up in your inbox immediately.
        </p>

        <div className="h-4" />

        <p>Type "{firstStepPassword}" then press continue.</p>

        <TextInput
          name="Type here"
          control={control}
          className="mt-4 border border-slate-9 w-full rounded px-2 py-1"
        />

        {props.submitAttempt && (
          <p className="mt-4 font-bold">
            You must use your mouse to click "Continue".
          </p>
        )}
      </div>

      <div className="p-4 border-t flex items-center">
        <button
          type="button"
          className={`rounded bg-slate-5 px-2 border 
            border-slate-9 text-sm hover:border-black hover:bg-slate-7`}
          onClick={() => ToggleScheduledDeliveryDialogState.toggle(false)}
        >
          Cancel
        </button>

        <div className="flex-1" />

        <button
          type="button"
          className={`rounded bg-slate-5 px-2 border 
            border-slate-9 text-sm hover:border-black hover:bg-slate-7`}
          disabled={
            import.meta.env.VITE_FIREBASE_EMULATORS === "true"
              ? false
              : hasErrors
          }
          onClick={props.onClick}
        >
          Continue
        </button>
      </div>
    </>
  );
};

const firstStepPassword = "I would like to disable scheduled delivery";

const SecondStep: ComponentType<{ initialTimerValue: number }> = (props) => {
  const [countdown, setCountdown] = useState<number | "cancelled">(
    props.initialTimerValue,
  );

  useEffect(() => {
    const sub = combineLatest([WINDOW_VISIBLE$, WINDOW_FOCUSED$])
      .pipe(
        switchMap(([visible, focused]) =>
          !visible || !focused
            ? of("cancelled" as const)
            : interval(1000).pipe(
                scan(
                  (acc) => (acc <= 0 ? 0 : acc - 1),
                  props.initialTimerValue,
                ),
                takeWhile((value) => value !== 0, true),
              ),
        ),
      )
      .subscribe(setCountdown);

    return () => sub.unsubscribe();
  }, [props.initialTimerValue]);

  return (
    <>
      <div className="p-4">
        <div className="prose">
          <p>
            Please wait for the countdown to finish then press "Disable
            scheduled delivery", below. You must keep this window active or else
            the timer will reset. Note that you can change the duration of this
            countdown on the <Link to="/settings">settings page</Link>.
          </p>
        </div>

        <div className="h-4" />

        <button
          type="button"
          disabled={
            import.meta.env.VITE_FIREBASE_EMULATORS === "true"
              ? false
              : countdown !== 0
          }
          className={cx(
            "text-3xl font-bold text-slate-11 bg-slate-3 border",
            "px-4 py-1 rounded disabled:text-slate-8 active:bg-slate-5",
          )}
          onClick={() => submit()}
        >
          {countdown === "cancelled"
            ? "Cancelled"
            : countdown === 0
            ? `Disable scheduled delivery`
            : `Disable scheduled delivery in ${countdown}s`}
        </button>
      </div>

      <div className="p-4 border-t flex items-center">
        <button
          type="button"
          className={`rounded bg-slate-5 px-2 border 
            border-slate-9 text-sm hover:border-black hover:bg-slate-7`}
          onClick={() => ToggleScheduledDeliveryDialogState.toggle(false)}
        >
          Cancel
        </button>
      </div>
    </>
  );
};

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(async () => {
    console.log("submitting...");

    ToggleScheduledDeliveryDialogState.toggle(false, {
      success: true,
    });

    await updateScheduledDelivery(false);
  }),
);
