import {
  collection,
  doc,
  collectionGroup,
  DocumentReference,
  getDoc,
  query,
  where,
  limit,
} from "firebase/firestore";
import { db, storage } from "~/firebase";
import { filter, firstValueFrom, map } from "rxjs";
import {
  TClientDocRefFunction,
  TClientCollectionRefFunction,
  TClientCollectionGroupRefFunction,
} from "@libs/utils/firestore";
import { ref, StorageReference } from "firebase/storage";
import { collectionData } from "~/utils/rxFireWrappers";
import { isNonNullable } from "@libs/utils/predicates";

/**
 * When creating a new Firestore document, Firestore's cache will
 * optimistically update to contain the new document even before
 * the promise to create the new document has resolved. The
 * promise to create the new document will only resolve after the
 * server has confirmed that the document has been created.
 *
 * Oftentimes when creating a new document, we want to
 * optimistically assume that creating the document will succeed
 * and update our UI accordingly. But, for our UI logic to work, we
 * want the Firestore cache to contain the new document. This
 * function receives a document reference and returns a promise that
 * will resolve as soon as the Firestore cache updates to contain
 * the referenced document. This function also receives the promise
 * returned by our document creation attempt. We need this promise
 * because it might throw an error. If it does, then the cache may
 * never update to contain our document. In this case, we want our
 * `waitForCacheToContainDoc` promise to also throw an error.
 *
 * @param ref reference for target document
 *
 * @param promiseForCreatingTheDocWeAreAwaiting promise returned by
 *   our function creating our target document.
 *
 * @returns promise which resolves when the Firestore cache has
 *   updated to contain our target document or which errors because
 *   our attempt to create the target document failed with an error.
 */
export function waitForCacheToContainDoc<T>(
  ref: DocumentReference<T>,
  /**
   * A common scenerio is to create a new document and then to wait
   * for the cache to update to optimistically contain that new
   * document. In this scenerio, if our attempt to create a new
   * document resulted in an error but we didn't wait for that
   * document creation promise to resolve, our call to
   * waitForCacheToContainDoc would never resolve. In this case, we
   * can pass our document creation promise to waitForCacheToContainDoc
   * which will throw an error if our document creation promise
   * throwns an error.
   */
  promiseForCreatingTheDocWeAreAwaiting: Promise<unknown>,
): Promise<T> {
  const awaitingCacheToContainDoc = firstValueFrom(
    collectionData(
      query(
        collection(db, ref.parent.path),
        where("id", "==", ref.id),
        limit(1),
      ),
    ).pipe(
      map((s) => (s[0] ?? null) as T | null),
      filter(isNonNullable),
    ),
  );

  return Promise.race([
    // This promise should only resolve before the
    // `awaitingCacheToContainDoc` promise if this promise
    // errors. Otherwise, the Firestore cache should always
    // optimistically update to contain our pending doc before
    // this promise resolves
    promiseForCreatingTheDocWeAreAwaiting.then(() => awaitingCacheToContainDoc),
    awaitingCacheToContainDoc,
  ]);
}

export async function docExists(ref: DocumentReference): Promise<boolean> {
  return getDoc(ref)
    .then((s) => s.exists())
    .catch((e) => {
      console.debug("Error while checking document existance", e);
      console.warn(`Document "${ref.path}" not found.`);
      return false;
    });
}

export const docRef: TClientDocRefFunction = function (
  path: string,
  ...pathSegments: string[]
) {
  return doc(db, path, ...pathSegments);
};

export const collectionRef: TClientCollectionRefFunction = function (
  path: string,
  ...pathSegments: string[]
) {
  return collection(db, path, ...pathSegments);
};

export const collectionGroupRef: TClientCollectionGroupRefFunction = function (
  collectionId: string,
) {
  return collectionGroup(db, collectionId);
};

export function storageRef(
  a: "images",
  userId: string,
  imageId: string,
): StorageReference;
export function storageRef(...args: string[]): StorageReference {
  return ref(storage, args.join("/"));
}
