import type Shepherd from "shepherd.js";
import { wait } from "@libs/utils/wait";
import { renderStep, createTour } from "~/services/lesson-service";
import { PLATFORM_MODIFIER_KEY } from "~/services/command.service";
import { navigateService } from "~/services/navigate.service";

export type TNewPrivateMessageWalkthroughEvent =
  | "deliver-now-dialog-enabled"
  | "deliver-now-dialog-disabled";

const LESSON_NAME = "scheduled-delivery";
const LESSON_VERSION = 1;

export function getClassForScheduledDeliveryTourStep(step: string | number) {
  return `WALKTHROUGH-${LESSON_NAME}-step-${step}`;
}

function getSelectorForStep(step: string | number) {
  return `.WALKTHROUGH-${LESSON_NAME}-step-${step}`;
}

const steps: Shepherd.Step.StepOptions[] = [
  {
    id: "10",
    text: () =>
      renderStep({
        noBack: true,
        showEscape: true,
        content: `
          The Comms inbox uses what we call "Scheduled Delivery".
          Only @@@mention messages show up in your inbox immediately after
          they are sent. Other messages (i.e. most messages) are delivered 
          at predefined hours of the day and days of the week. These
          messages are still "sent", and so they can still be found via
          search or by browsing channels, but notifications for them
          are only delivered to your inbox at your next scheduled
          delivery time.
        `,
      }),
    when: {
      show() {
        tour.navigationContext.allowEscape = true;
      },
      hide() {
        tour.navigationContext.allowEscape = false;
      },
    },
  },
  {
    id: "20",
    attachTo: {
      element: getSelectorForStep(20),
      on: "bottom",
    },
    modalOverlayOpeningPadding: 6,
    classes: "bottom",
    text: () =>
      renderStep({
        content: `
          You can see approximately how long it is until your next scheduled 
          delivery time via this button here.
        `,
      }),
  },
  {
    id: "30",
    text: () =>
      renderStep({
        content: `
          By default, messages are delivered Monday - Friday at 10am and 3pm 
          (your time). You can customize your scheduled delivery times by 
          navigating to the settings page.
        `,
      }),
  },
  {
    id: "40",
    attachTo: {
      element: getSelectorForStep(40),
      on: "bottom",
    },
    modalOverlayOpeningPadding: 6,
    classes: "bottom",
    canClickTarget: true,
    advanceOn: {
      selector: getSelectorForStep(40),
      event: "click",
    },
    text: () =>
      renderStep({
        noNext: true,
        noBack: true,
        content: (
          <>
            You can get to the settings page using the Command Bar (i.e.{" "}
            {PLATFORM_MODIFIER_KEY.name} + K) and searching for the "Go to
            Settings" command (though the Command Bar has been disabled for this
            walkthrough). You can also get to the settings page from your Inbox
            by clicking this button.
            <br />
            <br />
            <strong>Try clicking this button now to continue.</strong>
          </>
        ),
      }),
    async beforeShowPromise() {
      // // we make sure we're on the inbox page in case we're navigating
      // // from the next step (which takes the user to the settings page)
      // // back to this one.
      navigateService("/inbox");
      await wait(200);
    },
    when: {
      show() {
        tour.navigationContext.stopNext = true;
        tour.navigationContext.stopBack = true;
        tour.navigationContext.removeTransparentBackdrop();
      },
      hide() {
        tour.navigationContext.stopNext = false;
        tour.navigationContext.stopBack = false;
        tour.navigationContext.addTransparentBackdrop();
      },
    },
  },
  {
    id: "50",
    attachTo: {
      element: getSelectorForStep(50),
      on: "auto-end",
    },
    // We wait for content on the new page to load
    beforeShowPromise: async () => {
      await wait(50);
      navigateService("/settings");
      await wait(500);
    },
    modalOverlayOpeningPadding: 6,
    classes: "bottom",
    arrow: false,
    text: () =>
      renderStep({
        content: `
          Here you can customize which days of the week and hours of the day
          you want messages to be delivered to your inbox. Note that your
          scheduled delivery settings only affect *your* inbox. Other Comms
          users will receive inbox deliveries at different scheduled 
          days and times.
        `,
      }),
    when: {
      show() {
        document.querySelector(getSelectorForStep(50))?.scrollIntoView();
        tour.navigationContext.removeTransparentBackdrop();
      },
      hide() {
        tour.navigationContext.addTransparentBackdrop();
      },
    },
  },
  {
    id: "60",
    text: () =>
      renderStep({
        content: `
          Scheduled delivery doesn't actually affect when messages are sent.
          When you send a message, that still happens immediately. If you
          send a message to a channel, other users viewing that channel
          can immediately see that message even if they haven't yet received
          a notification for it in their inbox. Similarly, other users can
          still find your messages via search immediately after they have
          been sent (assuming they have the appropriate permissions to
          view the message). Scheduled delivery only affects the
          notifications in your inbox. Messages still show up immediately 
          elsewhere.
        `,
      }),
  },
  {
    id: "70",
    async beforeShowPromise() {
      // // we make sure we're on the inbox page in case we're navigating
      // // from the next step (which takes the user to the settings page)
      // // back to this one.
      navigateService("/inbox");
      await wait(200);
    },
    attachTo: {
      element: getSelectorForStep(20),
      on: "bottom",
    },
    modalOverlayOpeningPadding: 6,
    classes: "bottom",
    text: () =>
      renderStep({
        content: `
          Sometimes you may need to break scheduled delivery and have 
          messages delivered immediately. You can do this via the
          Command Bar using the "Deliver messages now" command. You
          can also do this when viewing your inbox by pressing this
          button. By choosing to have your messages delivered immediately,
          any inbox notifications you had received since your
          last scheduled delivery will become visible in your inbox.
          This being said, no more notifications will be delivered
          until your next scheduled delivery window.
        `,
      }),
    when: {
      show() {
        // In case the user is navigating from the next step back
        // to this one, we want to ensure the transparent backdrop
        // is present.
        tour.navigationContext.addTransparentBackdrop();
        tour.event$.next("deliver-now-dialog-disabled");
      },
    },
  },
  {
    id: "80",
    attachTo: {
      element: getSelectorForStep(20),
      on: "bottom",
    },
    advanceOn: {
      selector: getSelectorForStep(20),
      event: "click",
    },
    modalOverlayOpeningPadding: 6,
    classes: "bottom",
    canClickTarget: true,
    text: () =>
      renderStep({
        noNext: true,
        noBack: true,
        content: (
          <>
            Try pressing this button now and following the instructions.{" "}
            <strong>This will conclude the tour.</strong>
          </>
        ),
      }),
    when: {
      show() {
        tour.event$.next("deliver-now-dialog-enabled");
        tour.navigationContext.stopNext = true;
        tour.navigationContext.stopBack = true;
        tour.navigationContext.nextIsComplete = true;
        tour.navigationContext.removeTransparentBackdrop();
      },
      hide() {
        tour.navigationContext.stopNext = false;
        tour.navigationContext.stopBack = false;
        tour.navigationContext.nextIsComplete = false;
      },
    },
  },
  // {
  //   id: "100",
  //   text: () =>
  //     renderStep({
  //       /**
  //         Possible scheduled delivery text.

  //         (1) It is an attempt to reduce how often people check their inbox.
  //             If a message is truly urgent, someone can @@@mention you (which,
  //             for US users who have opted-in, will send a text notification).
  //             For messages that aren't urgent, don't worry about responding
  //             promptly. Instead, stay off Comms and get some work done.
  //         (2) It is an attempt to redefine the culture around message sending.
  //             Because everyone in the organization is using Scheduled Delivery,
  //             there should be less pressure for individuals to respond promptly,
  //             and less "ping-pong" back-and-forth conversations.
  //         (3) Similar to #1, hopefully this will reinforce a culture where
  //             people don't try and work over the weekend (whatever "weekend"
  //             is for them) since messages won't be delivered then.
  //        */
  //       content: `
  //         ${tour.context.organizationName} has decided to require everyone
  //         in the organization to use scheduled delivery so you cannot turn
  //         it off. Why require scheduled delivery? Scheduled delivery is
  //         intended to...
  //       `,
  //     }),
  // },
];

export const tour = createTour<
  TNewPrivateMessageWalkthroughEvent,
  {
    // organizationName: string;
  }
>(LESSON_NAME, LESSON_VERSION, {
  steps,
});
