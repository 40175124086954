import { ComponentType } from "react";
import {
  DialogState,
  DialogTitle,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { IUserCreateParams } from "@libs/firebase-functions-types";
import { TextInput } from "~/form-components/TextInput";
import { setIsLoading } from "~/services/loading.service";
import { createUser } from "~/services/user.service";
import { navigateService } from "~/services/navigate.service";
import {
  createFormControl,
  createFormGroup,
  IFormControl,
  useControl,
} from "solid-forms-react";
import { handleSubmit, useControlState } from "~/form-components/utils";
import {
  PLATFORM_MODIFIER_KEY,
  useRegisterCommands,
} from "~/services/command.service";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { css, cx } from "@emotion/css";
import { auth } from "~/firebase";
import { wait } from "@libs/utils/wait";

interface INewUserDialogData {
  name: string | null;
  email: string | null;
  phoneNumber: string | null;
}

type INewUserDialogReturnData = { success?: boolean } | null;

export const NewUserDialogState = new DialogState<
  INewUserDialogData,
  INewUserDialogReturnData
>();

type IFormValue = IUserCreateParams;

export const NewUserDialog = withModalDialog<
  {},
  INewUserDialogData,
  INewUserDialogReturnData
>({
  dialogState: NewUserDialogState,
  // This disables the default onBackdropClick action of "close"
  onBackdropClick: () => {},
  Component: (props) => {
    if (!props.data) {
      alert("Data must be supplied to the NewUserDialog");
      throw new Error("Data must be supplied to the NewUserDialog");
    }

    const control = useControl(() => {
      return createFormGroup({
        name: createFormControl(props.data?.name || "", {
          required: true,
        }),
        email: createFormControl(props.data?.email || "", {
          required: true,
        }),
        phoneNumber: createFormControl(props.data?.phoneNumber || "", {
          validators: (rawValue) =>
            !rawValue.trim() || isPossiblePhoneNumber(rawValue)
              ? null
              : { invalidPhoneNumber: true },
        }),
        interruptMessageText: createFormControl(false),
      });
    });

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Submit form",
            hotkeys: ["$mod+Enter"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              console.debug("attempting submit");
              handleSubmit(control, submit);
            },
          },
        ];
      },
    });

    return (
      <>
        <DialogTitle>
          <h2>New User Details</h2>
        </DialogTitle>

        <form
          onSubmit={(e) => e.preventDefault()}
          className={DIALOG_CONTENT_WRAPPER_CSS}
        >
          <p className="m-4">
            <em>
              Welcome to Comms! To get started, can we have your name, email
              address, and phone number? Press{" "}
              <kbd>{PLATFORM_MODIFIER_KEY.name}</kbd> + <kbd>Enter</kbd> to
              submit this form. You'll use {PLATFORM_MODIFIER_KEY.name} +{" "}
              <kbd>Enter</kbd> to submit all forms in the Comms app.
            </em>
          </p>
          <Name control={control.controls.name} />
          <Email control={control.controls.email} />
          <PhoneNumber control={control.controls.phoneNumber} />
        </form>
      </>
    );
  },
});

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  setIsLoading(async (_values: IFormValue) => {
    // Apparently Firebase callable functions error when receiving a proxy
    // object as an argument (and solid-forms controls are proxy objects)
    // See https://github.com/firebase/firebase-js-sdk/issues/6429
    const values = { ..._values };

    const result = await createUser(values);

    if (!result.data.success) {
      console.debug("submission failed", result);
      return;
    }

    await auth.currentUser?.getIdTokenResult(true);

    // We wait a few ms so that our user service picks up the
    // refreshed idToken
    await wait(10);

    console.debug("submitted successfully!");

    NewUserDialogState.toggle(false, {
      success: true,
    });

    navigateService("/inbox", { replace: true });
  }),
);

const Name: ComponentType<{
  control: IFormControl<string>;
}> = (props) => {
  return (
    <div className="flex px-4">
      <div className="flex flex-1 py-2 border-b border-mauve-5">
        <label htmlFor="name" className="mr-4">
          Name
        </label>

        <TextInput id="name" name="name" control={props.control} />
      </div>
    </div>
  );
};

const Email: ComponentType<{
  control: IFormControl<string>;
}> = (props) => {
  return (
    <div className="flex px-4">
      <div className="flex flex-1 py-2 border-b border-mauve-5">
        <label htmlFor="email" className="mr-4">
          Email
        </label>

        <TextInput
          id="email"
          name="email"
          type="email"
          control={props.control}
        />
      </div>
    </div>
  );
};

const PhoneNumber: ComponentType<{
  control: IFormControl<string>;
}> = (props) => {
  const value = useControlState(() => props.control.value, [props.control]);
  const isInvalid = useControlState(
    () => !props.control.isValid && props.control.isTouched,
    [props.control],
  );

  return (
    <div className="flex px-4">
      <div className="flex flex-1 py-2 border-b border-mauve-5">
        <label htmlFor="phone-number" className="mr-4">
          Phone number
        </label>

        <PhoneInput
          id="phone-number"
          international
          placeholder="Phone number"
          value={value}
          onChange={(value) => {
            props.control.setValue(value || "");
          }}
          onBlur={() => props.control.markTouched(true)}
          defaultCountry="US"
          autoFocus
          className={cx(phoneInputCSS, isInvalid && "is-invalid")}
        />
      </div>
    </div>
  );
};

const phoneInputCSS = css`
  flex: 1;

  & input {
    outline: none;
  }

  &.is-invalid {
    color: red;
    border: 1px solid red;
  }
`;
