import { css, cx } from "@emotion/css";
import { isEqual } from "@libs/utils/isEqual";
import {
  ComponentType,
  forwardRef,
  memo,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
} from "react";
import {
  focusLastListEntry,
  IListRef,
  useListScrollboxContext,
} from "~/components/list";
import {
  IEditorMention,
  IRichTextEditorRef,
  PostEditor,
  PostEditorErrors,
} from "~/form-components/post-editor";
import {
  IPostDocFromDraft,
  useSyncDraftBetweenTabs,
} from "~/services/draft.service";
import { observable, useControlState } from "~/form-components/utils";
import { useComposedRefs } from "~/utils/useComposedRefs";
import { useImageDropHandlers } from "~/form-components/post-editor/extensions/image";
import { DragTargetOverlay } from "~/components/DragTargetOverlay";
import {
  callCommandById,
  PLATFORM_MODIFIER_KEY,
} from "~/services/command.service";
import { deleteDraftCommand } from "~/utils/common-commands";
import { Tooltip } from "~/components/Tooltip";
import { MdOutlineDelete } from "react-icons/md";
import { OutlineButton } from "~/components/OutlineButtons";
import {
  IComposeMessageForm,
  SEND_MESSAGE_COMMAND_ID,
} from "./ComposeMessageContext";
import { IFormControl } from "solid-forms-react";
import { IRecipientOption } from "~/form-components/ThreadRecipients";
import { startWith } from "@libs/utils/rxjs-operators";
import { filter, map, pairwise, switchMap } from "rxjs";
import { ALL_OTHER_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$ } from "~/services/organization.service";
import { isNonNullable } from "@libs/utils/predicates";
import { USER_CHANNELS$ } from "~/services/channels.service";
import { setScrollTop } from "~/utils/dom-helpers";

/* -------------------------------------------------------------------------------------------------
 * ComposePostReplyBase
 * -----------------------------------------------------------------------------------------------*/

export interface IComposePostReplyBaseProps {
  control: IComposeMessageForm;
  listRef: RefObject<IListRef<unknown>>;
  formRef: RefObject<HTMLFormElement>;
  header: ReactNode;
  onClose: (post?: IPostDocFromDraft) => void;
  className?: string;
  focusOnInit?: boolean;
  draftActions?: ReactNode;
}

export const ComposePostReplyBase = memo(
  forwardRef<IRichTextEditorRef, IComposePostReplyBaseProps>((props, ref) => {
    const { control } = props;
    const editorRef = useRef<IRichTextEditorRef>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const { scrollboxRef } = useListScrollboxContext();

    const composedEditorRefs = useComposedRefs(ref, editorRef);

    const draftId = useControlState(() => control.rawValue.postId, [control]);

    useSyncDraftBetweenTabs(control, editorRef, props.onClose);

    useFocusDraftOnMount({
      focusOnInit: !!props.focusOnInit,
      control,
      editorRef,
    });

    const {
      showDragTarget,
      onDragEnter,
      onDragLeave,
      onDragOver,
      onDrop,
      onPaste,
    } = useImageDropHandlers(editorRef, draftId);

    const controlBorderCSS = useControlState(() => {
      if (control.isPending) return "border-blue-5 focus-within:border-blue-9";
      if (control.errors) return "border-red-5 focus-within:border-red-9";
      return "border-green-5 focus-within:border-green-9";
    }, [control]);

    return (
      <div
        ref={wrapperRef}
        className={cx(
          "bg-white shadow-lg border-l-[.4rem]",
          "focus:outline-none transition-colors relative",
          controlBorderCSS,
          props.className,
        )}
        onDragOver={onDragOver}
        // Note, onDragEnter/Leave events are fired when entering/exiting each
        // child element. Because of this, if we apply onDragEnter and
        // onDragLeave to this wrapper element, both events will be constantly
        // fired as someone moves their mouse over the elements in this div.
        // To get around this, we just attach the enter handler to this wrapper
        // element and we attach the leave handler to the drag target element
        // which, when visible, will be covering up all the other children.
        onDragEnter={onDragEnter}
        onDrop={onDrop}
        onPaste={onPaste}
      >
        {showDragTarget && (
          <DragTargetOverlay onDragLeave={onDragLeave}>
            Embed Image
          </DragTargetOverlay>
        )}

        {props.header}

        <form ref={props.formRef} onSubmit={(e) => e.preventDefault()}>
          <div className="flex flex-col flex-1 overflow-y-auto px-4 sm-w:px-8">
            <PostEditor
              ref={composedEditorRefs}
              onEditorStartOverflow={() => focusLastListEntry(props.listRef)}
              onEditorEndOverflow={() => {
                if (!scrollboxRef.current) return;
                setScrollTop(scrollboxRef.current, (value) => value + 100);
              }}
              initialTabIndex={0}
              control={control}
            />

            <PostEditorErrors control={control} />
          </div>

          <div className="flex px-4 sm-w:px-8 pt-2 pb-4 space-x-3">
            {props.draftActions || <DefaultDraftActions />}
          </div>
        </form>
      </div>
    );
  }),
  isEqual,
);

/* -------------------------------------------------------------------------------------------------
 * ComposeReplyHint
 * -----------------------------------------------------------------------------------------------*/

export const ComposeReplyHint: ComponentType<{}> = () => {
  return (
    <div className={cx(composeWrapperCSS, "mt-8 mx-10 prose text-slate-9")}>
      <p>
        <strong>Hint:</strong> To include additional recipients,{" "}
        <code>@mention</code> (or <code>@@request-response</code>, etc) them.
      </p>
    </div>
  );
};

// the bottom margin is equal to 50vh - the header height.
const composeWrapperCSS = css`
  margin-bottom: calc(50vh - 4.75rem);
`;

/* -----------------------------------------------------------------------------------------------*/

const DefaultDraftActions: ComponentType<{}> = () => {
  return (
    <>
      <SendDraftButton />

      <div className="flex-1" />

      <DeleteDraftButton />
    </>
  );
};

/* -------------------------------------------------------------------------------------------------
 * SendDraftButton
 * -----------------------------------------------------------------------------------------------*/

export const SendDraftButton: ComponentType<{}> = () => {
  return (
    <Tooltip
      side="bottom"
      content={
        <>
          Send
          <code className="flex items-center">
            (<PLATFORM_MODIFIER_KEY.symbol className="mr-1" /> + Enter)
          </code>
        </>
      }
    >
      <OutlineButton
        tabIndex={-1}
        onClick={(e) => {
          e.preventDefault();
          callCommandById(SEND_MESSAGE_COMMAND_ID);
        }}
      >
        <small>Send</small>
      </OutlineButton>
    </Tooltip>
  );
};

/* -------------------------------------------------------------------------------------------------
 * DeleteDraftButton
 * -----------------------------------------------------------------------------------------------*/

export const DeleteDraftButton: ComponentType<{}> = () => {
  return (
    <Tooltip
      side="left"
      content={
        <>
          Delete draft
          <code className="flex items-center">
            (<PLATFORM_MODIFIER_KEY.symbol className="mr-1" /> + Shift + ,)
          </code>
        </>
      }
    >
      <button
        type="button"
        tabIndex={-1}
        className="inline-flex justify-center items-center w-[29px] text-slate-9 hover:text-slate-12"
        onClick={(e) => {
          e.preventDefault();

          const isSure = confirm("Are you sure you want to delete this draft?");

          if (!isSure) return;

          callCommandById(deleteDraftCommand.id);
        }}
      >
        <MdOutlineDelete size={24} />
      </button>
    </Tooltip>
  );
};

/* -----------------------------------------------------------------------------------------------*/

function useFocusDraftOnMount(args: {
  focusOnInit: boolean;
  control: IComposeMessageForm;
  editorRef: RefObject<IRichTextEditorRef>;
}) {
  useEffect(() => {
    if (!args.focusOnInit) return;

    focusDraft(args.control, args.editorRef);

    // We only run this effect on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

/* -------------------------------------------------------------------------------------------------
 * focusDraft
 * -----------------------------------------------------------------------------------------------*/

export function focusDraft(
  control: IComposeMessageForm,
  editorRef: RefObject<IRichTextEditorRef>,
) {
  const isBranch = !!control.rawValue.branchedFrom;

  if (isBranch) {
    if (control.rawValue.recipients.to.length === 0) {
      control.controls.recipients.controls.to.data.focus.next();
      return;
    }

    if (!control.rawValue.subject) {
      control.controls.subject.data.focus.next();
      return;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  editorRef.current!.focus("start", { scrollIntoView: true });
}

/* -------------------------------------------------------------------------------------------------
 * useAddNewUserMentionsAsRecipients
 * -----------------------------------------------------------------------------------------------*/

export function useAddNewUserMentionsAsRecipients(
  userMentionsControl: IFormControl<IEditorMention[]>,
  recipientsControl: IFormControl<IRecipientOption[]>,
  isReadyToSync = true,
) {
  useEffect(() => {
    if (!isReadyToSync) return;

    const sub = observable(() => userMentionsControl.value)
      .pipe(
        startWith(() => []),
        pairwise(),
        filter(
          ([prevMentions, currMentions]) =>
            prevMentions.length < currMentions.length,
        ),
        map(([prevMentions, currMentions]) => {
          return currMentions.find(
            (curr) => !prevMentions.some((prev) => prev.id === curr.id),
          );
        }),
        switchMap((addedMention) =>
          ALL_OTHER_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$.pipe(
            map((memberships) =>
              memberships.find((m) => m.id === addedMention?.id),
            ),
          ),
        ),
        filter(isNonNullable),
      )
      .subscribe((addedUserMembership) => {
        const currentRecipients = recipientsControl.rawValue;

        const mentionIsAlreadyRecipient = currentRecipients.some(
          (recipient) => recipient.value === addedUserMembership.id,
        );

        if (mentionIsAlreadyRecipient) return;

        recipientsControl.setValue([
          ...currentRecipients,
          {
            type: "user",
            value: addedUserMembership.id,
            label: addedUserMembership.user.name,
            email: addedUserMembership.user.email,
          },
        ]);
      });

    return () => sub.unsubscribe();
  }, [userMentionsControl, recipientsControl, isReadyToSync]);
}

/* -------------------------------------------------------------------------------------------------
 * useAddNewChannelMentionsAsRecipients
 * -----------------------------------------------------------------------------------------------*/

export function useAddNewChannelMentionsAsRecipients(
  channelMentionsControl: IFormControl<IEditorMention[]>,
  recipientsControl: IFormControl<IRecipientOption[]>,
  isReadyToSync = true,
) {
  useEffect(() => {
    if (!isReadyToSync) return;

    const sub = observable(() => channelMentionsControl.value)
      .pipe(
        startWith(() => []),
        pairwise(),
        filter(
          ([prevMentions, currMentions]) =>
            prevMentions.length < currMentions.length,
        ),
        map(([prevMentions, currMentions]) => {
          return currMentions.find(
            (curr) => !prevMentions.some((prev) => prev.id === curr.id),
          );
        }),
        switchMap((addedMention) =>
          USER_CHANNELS$.pipe(
            map((channels) => channels.find((m) => m.id === addedMention?.id)),
          ),
        ),
        filter(isNonNullable),
      )
      .subscribe((addedChannel) => {
        const currentRecipients = recipientsControl.rawValue;

        const mentionIsAlreadyRecipient = currentRecipients.some(
          (recipient) => recipient.value === addedChannel.id,
        );

        if (mentionIsAlreadyRecipient) return;

        recipientsControl.setValue([
          ...currentRecipients,
          {
            type: "channel",
            value: addedChannel.id,
            label: addedChannel.name,
            classification: addedChannel.classification,
            channelGroupNames: addedChannel.__local.knownChannelGroups.map(
              (g) => g.name,
            ),
          },
        ]);
      });

    return () => sub.unsubscribe();
  }, [channelMentionsControl, recipientsControl, isReadyToSync]);
}

/* -----------------------------------------------------------------------------------------------*/
