import { EmailAddress, Mailbox, MailboxGroup } from "@libs/utils/email-rfc";
import { Decoder, DecoderSuccess, DecoderError } from "ts-decoders";
import * as d from "ts-decoders/decoders";
import {
  IBaseDraftDoc,
  ICommsDraftDoc,
  IEmailDraftDoc,
  IMainSettingsDoc,
  INotificationDoc,
  ISubscriptionDoc,
  IUnsafeDraftDoc,
} from ".";

export const IdD = d
  .stringD()
  .map((s) => s.trim())
  .chain(
    d.predicateD<string>((input) => input.length > 20, {
      errorMsg: "Must be more than 20 characters",
    }),
  );

export const TextD = d
  .stringD()
  .map((s) => s.trim())
  .chain(
    d.predicateD<string>((input) => input.length > 0, {
      errorMsg: "required",
    }),
  );

const recipientIdsD = d
  .arrayD(
    // user ids come from Firebase Auth so we can't assume
    // a specific length (hence we're not using the `IdD`
    // decoder)
    d.stringD(),
  )
  .chain((input) => {
    const set = new Set(input);

    if (set.size === input.length) {
      return new DecoderSuccess(input);
    }

    return new DecoderError(
      input,
      "invalid",
      "cannot contain duplicate recipient ids",
    );
  });

const mailboxD: Decoder<Mailbox> = d.objectD(
  {
    label: d.undefinableD(d.stringD()).map((s) => s || undefined),
    address: TextD,
  },
  { removeUndefinedProperties: true },
);

const mailboxGroupD: Decoder<MailboxGroup> = d.objectD({
  label: TextD,
  addresses: d.arrayD(mailboxD),
});

export const emailAddressD: Decoder<EmailAddress> = d.anyOfD(
  [mailboxD, mailboxGroupD],
  { decoderName: "emailAddressD" },
);

function buildBaseUnsafeDraftD<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Partial<Record<keyof IBaseDraftDoc, any>>,
>(
  props: {
    [P in keyof T]?: Decoder<T[P]>;
  },
  options: {
    timestampD: Decoder<T["createdAt"]>;
  },
) {
  const baseProps: { [K in keyof IBaseDraftDoc]: Decoder<IBaseDraftDoc[K]> } = {
    type: d.anyOfD([d.exactlyD("COMMS"), d.exactlyD("EMAIL")]),
    isInvalid: d.exactlyD(false),
    meta: d.undefinableD(
      d.objectD(
        {
          invalidReason: d.undefinableD(d.stringD()),
          firstAttemptToSendAt: d.undefinableD(options.timestampD),
        },
        {
          removeUndefinedProperties: true,
        },
      ),
    ),
    id: IdD,
    threadId: IdD,
    isFirstPostInThread: d.booleanD(),
    newThread: d.nullableD(
      d.objectD({
        subject: d.stringD().map((s) => s.trim()),
        visibility: d.anyOfD([
          d.exactlyD("shared"),
          d.exactlyD("private"),
          d.exactlyD(null),
        ]),
      }),
    ),
    branchedFrom: d.nullableD(
      d.objectD({
        threadId: d.stringD(),
        postId: d.stringD(),
        postSentAt: options.timestampD,
        postScheduledToBeSentAt: options.timestampD,
      }),
    ),
    recipientChannelIds: recipientIdsD.chain(
      d.predicateD<string[]>((input) => input.length <= 10, {
        errorMsg: "No more than 10 recipient channels are allowed",
      }),
    ),
    mentionedChannels: d
      .undefinableD(
        d.dictionaryD(
          d.objectD({
            priority: d.anyOfD([
              d.exactlyD(100),
              d.exactlyD(200),
              d.exactlyD(300),
            ]),
          }),
        ),
      )
      .map((input) => input || {}),
    mentionedUsers: d
      .undefinableD(
        d.dictionaryD(
          d.objectD({
            priority: d.anyOfD([
              d.exactlyD(100),
              d.exactlyD(200),
              d.exactlyD(300),
            ]),
          }),
        ),
      )
      .map((input) => input || {}),
    bodyHTML: d.stringD().map((s) => s.trim()),
    scheduledToBeSent: d.booleanD(),
    scheduledToBeSentAt: d.nullableD(options.timestampD),
    sent: d.booleanD(),
    sentAt: d.nullableD(options.timestampD),
    createdAt: options.timestampD,
    updatedAt: options.timestampD,
  };

  const commsProps: {
    [K in keyof ICommsDraftDoc]: Decoder<ICommsDraftDoc[K]>;
  } = {
    ...baseProps,
    type: d.exactlyD("COMMS"),
    recipientUserIds: recipientIdsD,
    ...props,
  };

  const emailProps: {
    [K in keyof IEmailDraftDoc]: Decoder<IEmailDraftDoc[K]>;
  } = {
    ...baseProps,
    type: d.exactlyD("EMAIL"),
    to: d.arrayD(emailAddressD),
    cc: d.arrayD(emailAddressD),
    ccChannelIds: d.arrayD(TextD),
    bcc: d.arrayD(emailAddressD),
    ...props,
  };

  const baseDecoder = d.anyOfD(
    [
      d.objectD(commsProps, {
        removeUndefinedProperties: true,
      }),
      d.objectD(emailProps, {
        removeUndefinedProperties: true,
      }),
    ],
    {
      errorMsg(_, errors) {
        const e = errors
          .filter(
            (e) =>
              !(
                e.child?.type === "invalid key value" && e.child?.key === "type"
              ),
          )
          // anyOfD always produces errors with a non-null child prop
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .map((e) => e.child!);

        return e.length === 0 ? errors : e;
      },
    },
  );

  return baseDecoder.chain((input) => {
    if (input.scheduledToBeSent !== !!input.scheduledToBeSentAt) {
      return new DecoderError(
        input,
        "invalid-prop",
        "scheduledToBeSentAt should be null unless scheduledToBeSent is true",
        {
          key: "scheduledToBeSentAt",
          location: "scheduledToBeSentAt",
        },
      );
    } else if (
      (input.isFirstPostInThread && input.newThread === null) ||
      (!input.isFirstPostInThread && input.newThread !== null)
    ) {
      return new DecoderError(
        input,
        "invalid-prop",
        `newThread must be provided if isFirstPostInThread === true 
          and must be null otherwise`,
        {
          key: "newThread",
          location: "newThread",
        },
      );
    }

    return new DecoderSuccess(input);
  });
}

/** Used for decoding WIP drafts */
export function buildWipUnsafeDraftD<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Partial<Record<keyof IUnsafeDraftDoc, any>>,
>(
  props: {
    [P in keyof T]?: Decoder<T[P]>;
  },
  options: {
    timestampD: Decoder<T["createdAt"]>;
  },
) {
  return buildBaseUnsafeDraftD(props, options).chain((input) => {
    if (input.scheduledToBeSent !== false) {
      return new DecoderError(
        input,
        "invalid-prop",
        "scheduledToBeSent must be `false` for a WIP draft",
        {
          key: "scheduledToBeSent",
          location: "scheduledToBeSent",
        },
      );
    } else if (input.scheduledToBeSentAt !== null) {
      return new DecoderError(
        input,
        "invalid-prop",
        "scheduledToBeSentAt must be `null` for a WIP draft",
        {
          key: "scheduledToBeSentAt",
          location: "scheduledToBeSentAt",
        },
      );
    }

    return new DecoderSuccess(input);
  });
}

export function buildSentUnsafeDraftD<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Partial<Record<keyof IUnsafeDraftDoc, any>>,
>(
  props: {
    [P in keyof T]?: Decoder<T[P]>;
  },
  options: {
    timestampD: Decoder<T["createdAt"]>;
  },
) {
  return buildBaseUnsafeDraftD(props, options).chain((input) => {
    if (input.scheduledToBeSent === false) {
      return new DecoderError(
        input,
        "invalid-prop",
        "scheduledToBeSent must be `true` for a sent draft",
        {
          key: "scheduledToBeSent",
          location: "scheduledToBeSent",
        },
      );
    } else if (input.scheduledToBeSentAt === null) {
      return new DecoderError(
        input,
        "invalid-prop",
        "scheduledToBeSentAt must not be `null` for a sent draft",
        {
          key: "scheduledToBeSentAt",
          location: "scheduledToBeSentAt",
        },
      );
    } else if (
      input.type === "COMMS" &&
      input.newThread &&
      input.recipientChannelIds.length === 0 &&
      input.recipientUserIds.length === 0
    ) {
      return new DecoderError(
        input,
        "invalid-prop",
        "at least one recipient is required",
        {
          location: "recipientChannelIds|recipientUserIds",
        },
      );
    } else if (
      input.type === "EMAIL" &&
      input.newThread &&
      input.to.length === 0 &&
      input.cc.length === 0
    ) {
      return new DecoderError(
        input,
        "invalid-prop",
        "at least one recipient is required",
        {
          location: "to|cc",
        },
      );
    } else if (input.bodyHTML.length === 0) {
      return new DecoderError(input, "invalid-prop", "bodyHTML is required", {
        key: "bodyHTML",
        location: "bodyHTML",
      });
    } else if (input.newThread && input.newThread.subject.length === 0) {
      return new DecoderError(input, "invalid-prop", "subject is required", {
        key: "subject",
        location: "subject",
      });
    } else if (input.newThread && input.newThread.visibility === null) {
      return new DecoderError(
        input,
        "invalid-prop",
        "visibility cannot be null",
        {
          location: "visibility",
        },
      );
    }

    return new DecoderSuccess(input);
  });
}

export function buildSubscriptionD<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Partial<Record<keyof ISubscriptionDoc, any>>,
>(
  props: {
    [P in keyof T]?: Decoder<T[P]>;
  },
  options: {
    timestampD: Decoder<T["createdAt"]>;
  },
) {
  const obj: { [K in keyof ISubscriptionDoc]: Decoder<ISubscriptionDoc[K]> } = {
    id: IdD,
    userId: IdD,
    type: d.anyOfD([d.exactlyD("channel"), d.exactlyD("thread")]),
    preference: d.anyOfD([
      d.exactlyD("all"),
      d.exactlyD("all-new"),
      d.exactlyD("involved"),
    ]),
    isPinned: d.undefinableD(d.booleanD()),
    createdAt: options.timestampD,
    updatedAt: options.timestampD,
    ...props,
  };

  return d.objectD(obj as { [K in keyof T]: Decoder<T[K]> }, {
    removeUndefinedProperties: true,
  });
}

export function buildNotificationD<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Partial<Record<keyof INotificationDoc, any>>,
>(
  props: {
    [P in keyof T]?: Decoder<T[P]>;
  },
  options: {
    timestampD: Decoder<T["createdAt"]>;
  },
) {
  const obj: {
    [K in keyof INotificationDoc]: Decoder<INotificationDoc[K]>;
  } = {
    type: d.anyOfD([d.exactlyD("new-post")]),
    id: IdD,
    postId: IdD,
    postType: d.anyOfD([d.exactlyD("COMMS"), d.exactlyD("EMAIL")]),
    subject: TextD,
    summary: TextD,
    done: d.booleanD(),
    doneAt: d.nullableD(options.timestampD),
    doneLastModifiedBy: d.anyOfD([
      d.exactlyD("user"),
      d.exactlyD("reminder"),
      d.exactlyD("system"),
    ]),
    from: d.dictionaryD(
      d.objectD({
        type: d.exactlyD("user"),
        name: TextD,
        email: TextD,
        photoURL: d.nullableD(TextD),
      }),
    ),
    fromIds: d.arrayD(IdD),
    priority: d.integerD(),
    reason: d.anyOfD([
      d.exactlyD("user-created"),
      d.exactlyD("mention"),
      d.exactlyD("participating"),
      d.exactlyD("subscription"),
    ]),
    sentAt: options.timestampD,
    scheduledToBeSentAt: options.timestampD,
    oldestSentAtValueNotMarkedDone: d.nullableD(options.timestampD),
    triaged: d.booleanD(),
    triagedUntil: d.nullableD(options.timestampD),
    isStarred: d.booleanD(),
    starredAt: d.nullableD(options.timestampD),
    isFirstPostInThread: d.booleanD(),
    threadVisibility: d.anyOfD([d.exactlyD("shared"), d.exactlyD("private")]),
    tagIds: d.arrayD(TextD),
    createdAt: options.timestampD,
    updatedAt: options.timestampD,
    serverUpdatedAt: d.optionalD(options.timestampD),
    ...props,
  };

  return d.objectD(obj as { [K in keyof T]: Decoder<T[K]> }, {
    removeUndefinedProperties: true,
  });
}

export function buildMainSettingsD<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Partial<Record<keyof IMainSettingsDoc, any>>,
>(
  props: {
    [P in keyof T]?: Decoder<T[P]>;
  },
  options: {
    timestampD: Decoder<T["updatedAt"]>;
  },
) {
  const obj: {
    [K in keyof IMainSettingsDoc]: Decoder<IMainSettingsDoc[K]>;
  } = {
    id: d.stringD(),
    inboxLayout: d.undefinableD(
      d.anyOfD([
        d.exactlyD("consolidated-inbox"),
        d.exactlyD("blocking-inbox"),
      ]),
    ),
    enableNavBackOnThreadDone: d.undefinableD(d.booleanD()),
    enableFocusMode: d.undefinableD(d.booleanD()),
    focusModeExceptions: d.undefinableD(d.arrayD(d.integerD())),
    enableScheduledDelivery: d.undefinableD(d.booleanD()),
    scheduledDays: d.undefinableD(d.arrayD(d.stringD())),
    scheduledTimes: d.undefinableD(d.arrayD(d.stringD())),
    secondsToWaitToDisableScheduledDelivery: d.undefinableD(
      d.integerD().chain(
        d.predicateD((input: number) => input >= 0, {
          errorMsg: "must be greater than or equal to zero",
        }),
      ),
    ),
    mostRecentDeliverNow: d.undefinableD(options.timestampD),
    secondsForUndoingSentMessage: d.undefinableD(
      d.integerD().chain(
        d.predicateD((input: number) => input >= 0, {
          errorMsg: "must be greater than or equal to zero",
        }),
      ),
    ),
    updatedAt: options.timestampD,
    ...props,
  };

  return d.objectD(obj as { [K in keyof T]: Decoder<T[K]> }, {
    removeUndefinedProperties: true,
  });
}
