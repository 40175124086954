import { ComponentType, useEffect, useState } from "react";
import {
  DialogState,
  DialogTitle,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { createFormControl, useControl } from "solid-forms-react";
import { observable, useControlState } from "~/form-components/utils";
import { useRegisterCommands } from "~/services/command.service";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import {
  combineLatest,
  firstValueFrom,
  interval,
  of,
  scan,
  switchMap,
  takeWhile,
} from "rxjs";
import {
  CURRENT_USER_MAIN_SETTINGS$,
  mergeMainSettings,
  TNormalizedMainSettingsDoc,
} from "~/services/settings.service";
import { TextInput } from "~/form-components/TextInput";
import { WINDOW_FOCUSED$, WINDOW_VISIBLE$ } from "~/services/focus.service";
import { cx } from "@emotion/css";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { closeDialogCommand } from "~/utils/common-commands";
import * as DialogLayout from "~/dialogs/DialogLayout";
import { toast } from "~/services/toast-service";

export type IEditSecondsToWaitToDisableScheduledDeliveryDialogData = {
  settings: TNormalizedMainSettingsDoc;
};

export type IEditSecondsToWaitToDisableScheduledDeliveryDialogReturnData = {
  success: boolean;
} | void;

export const EditSecondsToWaitToDisableScheduledDeliveryDialogState =
  new DialogState<
    IEditSecondsToWaitToDisableScheduledDeliveryDialogData,
    IEditSecondsToWaitToDisableScheduledDeliveryDialogReturnData
  >();

export const EditSecondsToWaitToDisableScheduledDeliveryDialog =
  withModalDialog({
    dialogState: EditSecondsToWaitToDisableScheduledDeliveryDialogState,
    async loadData() {
      return {
        settings: await firstValueFrom(CURRENT_USER_MAIN_SETTINGS$),
      };
    },
    Component({ data }) {
      if (!data) {
        throw new Error("Missing required data");
      }

      const [step, setStep] = useState(
        data.settings.secondsToWaitToDisableScheduledDelivery === 0 ? 2 : 1,
      );

      useRegisterCommands({
        commands: () => {
          return [
            closeDialogCommand({
              callback: () => {
                EditSecondsToWaitToDisableScheduledDeliveryDialogState.toggle(
                  false,
                );
              },
            }),
          ];
        },
      });

      return (
        <>
          <DialogTitle>
            <h2>Change the Difficulty to Disable Scheduled Delivery</h2>
          </DialogTitle>

          <div className={DIALOG_CONTENT_WRAPPER_CSS}>
            {step === 1 ? (
              <FirstStep
                initialTimerValue={
                  data.settings.secondsToWaitToDisableScheduledDelivery
                }
                onClick={() => setStep(2)}
              />
            ) : (
              <SecondStep
                initialTimerValue={
                  data.settings.secondsToWaitToDisableScheduledDelivery
                }
              />
            )}
          </div>
        </>
      );
    },
  });

const FirstStep: ComponentType<{
  initialTimerValue: number;
  onClick: () => void;
}> = (props) => {
  const [countdown, setCountdown] = useState<number | "cancelled">(
    props.initialTimerValue,
  );

  useEffect(() => {
    const sub = combineLatest([WINDOW_VISIBLE$, WINDOW_FOCUSED$])
      .pipe(
        switchMap(([visible, focused]) =>
          !visible || !focused
            ? of("cancelled" as const)
            : interval(1000).pipe(
                scan(
                  (acc) => (acc <= 0 ? 0 : acc - 1),
                  props.initialTimerValue,
                ),
                takeWhile((value) => value !== 0, true),
              ),
        ),
      )
      .subscribe(setCountdown);

    return () => sub.unsubscribe();
  }, [props.initialTimerValue]);

  return (
    <>
      <div className="p-4">
        <p>
          Please wait for the countdown to finish then press "Edit", below. You
          must keep this window active or else the timer will reset.
        </p>

        <div className="h-4" />

        <button
          type="button"
          disabled={countdown !== 0}
          className={cx(
            "text-3xl font-bold text-slate-11 bg-slate-3 border",
            "px-4 py-1 rounded disabled:text-slate-8 active:bg-slate-5",
          )}
          onClick={props.onClick}
        >
          {countdown === "cancelled"
            ? "Cancelled"
            : countdown === 0
            ? `Edit`
            : `Edit in ${countdown}s`}
        </button>
      </div>

      <DialogLayout.DialogFooter>
        <button
          type="button"
          className={`
          rounded bg-slate-5 border px-2
          border-slate-9 text-sm hover:border-black hover:bg-slate-7`}
          onClick={() =>
            EditSecondsToWaitToDisableScheduledDeliveryDialogState.toggle(false)
          }
        >
          Cancel
        </button>
      </DialogLayout.DialogFooter>
    </>
  );
};

const SecondStep: ComponentType<{
  initialTimerValue: number;
}> = (props) => {
  const control = useControl(() =>
    createFormControl(props.initialTimerValue.toString()),
  );

  useEffect(() => {
    observable(() => control.rawValue).subscribe((rawValue) => {
      const value = parseInt(rawValue, 10);

      const isInvalid = isNaN(value) || value < 0;

      if (isInvalid) {
        control.setErrors({ invalid: true });
      } else {
        control.setErrors(null);
      }
    });
  }, [control]);

  const hasErrors = useControlState(() => !!control.errors, [control]);

  return (
    <>
      <div className="flex flex-col p-4">
        <label htmlFor="initial-disable-timer-value">
          When you try to disable scheduled delivery, how many seconds should
          you be forced to wait? A value of 0 will disable the friction timer,
          allowing you to toggle scheduled delivery on or off instantly.
          Additionally, a value less than 30 will also be used for the "Deliver
          Messages Now" timer (and a value of 0 will also remove that timer).
        </label>

        <TextInput
          id="initial-disable-timer-value"
          name="Initial friction timer value"
          type="number"
          control={control}
          className="px-2 py-1 my-2 border focus-within:border-blue-9 rounded border-slate-8"
        />

        {hasErrors && (
          <div className="text-red-10 font-medium">
            Must be greater than or equal to zero.
          </div>
        )}
      </div>

      <DialogLayout.DialogFooter>
        <DialogLayout.DialogSubmitButton
          onClick={() => {
            if (!control.isValid) {
              control.markTouched(true);
              return;
            }

            submit(control.value);
          }}
        />
      </DialogLayout.DialogFooter>
    </>
  );
};

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(async (value: string) => {
    console.log("submitting...");

    EditSecondsToWaitToDisableScheduledDeliveryDialogState.toggle(false);

    mergeMainSettings({
      secondsToWaitToDisableScheduledDelivery: parseInt(value, 10),
    });

    toast("vanilla", {
      subject: "Saved.",
    });
  }),
);
