import { PropsWithChildren } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { LoadingText } from "~/components/LoadingText";
import { useServerAPIVersion } from "~/services/version.service";

/**
 * A HOC which ensures that the wrapped component is only rendered
 * if the client's API version is up-to-date.
 */
export function withAPIVersionGuard<P extends PropsWithChildren<unknown>>(
  Component: React.ComponentType<P>,
) {
  return function GuardedRoute(props: P) {
    const apiVersion = useServerAPIVersion();
    const location = useLocation();

    if (apiVersion === "loading") {
      return <LoadingText />;
    }

    if (!apiVersion.matches) {
      // Redirect them to the /maintenance page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they maintenance, which is a nicer user experience
      // than dropping them off on the home page.
      return (
        <Navigate to={"/maintenance"} state={{ from: location }} replace />
      );
    }

    return <Component {...props} />;
  };
}
