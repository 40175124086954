import { createKeybindingsHandler } from "@libs/tinykeys";
import { WINDOW_EVENTS$ } from "./command.service";

export let undoCallback: (() => void) | null = null;
let timeoutId: number | null = null;

export function clearUndo() {
  if (timeoutId) clearTimeout(timeoutId);
  undoCallback = null;
}

export function registerUndo(
  fn: () => void,
  options: {
    timeout?: number;
  },
) {
  undoCallback = () => {
    fn();
    undoCallback = null;
    if (timeoutId) clearTimeout(timeoutId);
  };

  if (options.timeout) {
    if (timeoutId) clearTimeout(timeoutId);

    timeoutId = setTimeout(
      () => (undoCallback = null),
      options.timeout,
    ) as unknown as number;
  }
}

const handler = (event: KeyboardEvent) => {
  if (!undoCallback) return;

  const target = event.target as HTMLElement | null;
  const tagName = target?.tagName;

  if (
    tagName === "INPUT" ||
    tagName === "SELECT" ||
    tagName === "TEXTAREA" ||
    target?.isContentEditable
  ) {
    return;
  }

  event.preventDefault();
  undoCallback();
};

WINDOW_EVENTS$.subscribe(
  createKeybindingsHandler({
    "$mod+z": handler,
    z: handler,
  }),
);
