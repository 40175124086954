import React, {
  ComponentType,
  forwardRef,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { css, cx } from "@emotion/css";
import {
  OptionProps,
  MultiValueGenericProps,
  MultiValueProps,
} from "react-select";
import { red } from "@radix-ui/colors";
import {
  getChannel,
  IChannelDocWithCurrentUserData,
  USER_CHANNELS$,
} from "~/services/channels.service";
import {
  AutocompleteSelect,
  IOption,
  TAutocompleteSelectRef,
} from "~/form-components/AutocompleteSelect";
import { useObservable } from "~/utils/useObservable";
import { stringComparer } from "@libs/utils/comparers";
import { IFormControl } from "solid-forms-react";
import { combineLatest, distinctUntilChanged, map } from "rxjs";
import { isEqual } from "@libs/utils/isEqual";
import { capitalize } from "lodash-es";
import {
  ALL_OTHER_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$,
  getOrganizationMember,
  getOrganizationMemberByEmail,
  IAcceptedOrganizationMemberDoc,
} from "~/services/organization.service";
import { FaQuestionCircle } from "react-icons/fa";
import { Tooltip } from "~/components/Tooltip";
import {
  PLATFORM_ALT_KEY,
  PLATFORM_MODIFIER_KEY,
} from "~/services/command.service";
import { useComposedRefs } from "~/utils/useComposedRefs";
import commandScore from "command-score";
import { IChannelDoc } from "@libs/firestore-models";
import { BsLockFill } from "react-icons/bs";
import { useControlState } from "~/form-components/utils";
import { getClassForPrivateMessageTourStep } from "~/services/lesson-service/lessons/private-message-walkthrough";
import { AVAILABLE_LESSON_BOX_SHADOW_PULSE } from "~/services/lesson-service";
import { useCurrentUserSetting } from "~/services/settings.service";
import { applyAdditionalMentionSuggestionWeights } from "./tiptap/suggestion-utils";
import {
  throwUnreachableCaseError,
  UnreachableCaseError,
} from "@libs/utils/errors";
import { TDraftRecipient } from "~/services/draft.service";
import { getAndAssertCurrentUser } from "~/services/user.service";
import { parseStringToEmailAddress } from "@libs/utils/parseEmailAddress";

export interface IUserRecipientOption extends IOption<string> {
  type: "user";
  email: string;
  emphasize?: boolean;
  dontPromptToRemoveOnMentionDeletion?: boolean;
}

export interface IChannelRecipientOption extends IOption<string> {
  type: "channel";
  classification: IChannelDoc["classification"];
  channelGroupNames: string[];
  sharedChannel?: null | {
    organizationName: string;
    showWalkthrough: boolean;
  };
  emphasize?: boolean;
  dontPromptToRemoveOnMentionDeletion?: boolean;
}

export interface IEmailRecipientOption extends IOption<string> {
  type: "email";
  email: string;
  emphasize?: boolean;
  dontPromptToRemoveOnMentionDeletion?: boolean;
}

export type ICommsThreadRecipientOption =
  | IUserRecipientOption
  | IChannelRecipientOption;

export type IEmailThreadRecipientOption =
  | IUserRecipientOption
  | IChannelRecipientOption
  | IEmailRecipientOption;

export type IRecipientOption =
  | IUserRecipientOption
  | IChannelRecipientOption
  | IEmailRecipientOption;

export type TThreadRecipientsRef = TAutocompleteSelectRef<
  IRecipientOption,
  true
>;

export const ThreadRecipients = forwardRef<
  TThreadRecipientsRef,
  {
    control: IFormControl<readonly IRecipientOption[]>;
    name: string;
    /**
     * `null` if the user hasn't picked a privacy status for the
     * thread yet.
     */
    isThreadPrivate: boolean | null;
    threadType: "COMMS" | "EMAIL";
    canAddEmailRecipients?: boolean;
    onlyRecipientsOfType?: "channel" | "user" | "email";
    autocompleteMenuPortalEl?: HTMLDivElement | null;
    autoFocus?: boolean;
    wrapperClassName?: string;
    autocompleteClassName?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  }
>((props, forwardedRef) => {
  const ref = useRef<TThreadRecipientsRef>(null);

  const composeRefs = useComposedRefs(ref, forwardedRef);

  const allOptions = useRecipientOptions({
    threadType: props.threadType,
    isThreadPrivate: props.isThreadPrivate,
  });

  const options = useMemo(() => {
    return !props.onlyRecipientsOfType
      ? allOptions
      : allOptions.filter((o) => o.type === props.onlyRecipientsOfType);
  }, [allOptions, props.onlyRecipientsOfType]);

  const mentionFrequencySettingsDoc = useCurrentUserSetting(
    "MentionFrequencySettings",
  );

  const mentions = useMemo(() => {
    return mentionFrequencySettingsDoc?.mentions || {};
  }, [mentionFrequencySettingsDoc?.mentions]);

  const value = useControlState(() => props.control.value, [props.control]);

  const isInvalid = useControlState(
    () => !props.control.isValid,
    [props.control],
  );

  const isTouched = useControlState(
    () => props.control.isTouched,
    [props.control],
  );

  const isDisabled = useControlState(
    () => props.control.isDisabled,
    [props.control],
  );

  const placeholderPrefix = !props.onlyRecipientsOfType
    ? "Recipient"
    : capitalize(props.onlyRecipientsOfType) + " recipient";

  const loadOptions = useCallback(
    async (input: string) => {
      let prefilteredOptions = options;
      let modifiedInput = input;

      if (input.startsWith("@")) {
        modifiedInput = modifiedInput.slice(1);
        prefilteredOptions = prefilteredOptions.filter(
          (o) => o.type === "user" || o.type === "email",
        );
      } else if (input.startsWith("#")) {
        modifiedInput = modifiedInput.slice(1);
        prefilteredOptions = prefilteredOptions.filter(
          (o) => o.type === "channel",
        );
      }

      const filteredOptions = prefilteredOptions
        .map((o) => {
          const label =
            o.type === "channel"
              ? `${o.label} ${o.channelGroupNames.join(" ")}`
              : `${o.label} ${o.email}`;

          let score = commandScore(label, modifiedInput);

          if (score !== 0) {
            score = applyAdditionalMentionSuggestionWeights({
              score,
              id: o.value,
              frequencyDictionary: mentions,
            });
          }

          return {
            option: o,
            score,
          };
        })
        .sort((a, b) => b.score - a.score)
        .slice(0, 9)
        .filter((r) => r.score > 0)
        .map((r) => ({ ...r.option }));

      return filteredOptions;
    },
    [options, mentions],
  );

  return (
    <div
      className={cx(
        `ThreadRecipients flex`,
        getClassForPrivateMessageTourStep(5),
        props.wrapperClassName,
      )}
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
    >
      <div className={cx("flex flex-1")}>
        <AutocompleteSelect<IRecipientOption, true>
          ref={composeRefs}
          name={props.name}
          value={value}
          isDisabled={isDisabled}
          canCreateCustomOption={props.canAddEmailRecipients}
          tabSelectsValue
          openMenuOnFocus={false}
          onBlur={() => props.control.markTouched(true)}
          onChange={(newValue, actionMeta) => {
            if (isDisabled) return;

            switch (actionMeta.action) {
              case "select-option": {
                const { option } = actionMeta;

                if (option) {
                  newValue = newValue.map((value) => {
                    if (value.value === option.value) {
                      return {
                        ...option,
                        dontPromptToRemoveOnMentionDeletion: true,
                      };
                    }

                    return value;
                  });
                }

                break;
              }
              case "remove-value":
              case "pop-value": {
                // if the user presses delete when there are no values
                // in the input, then `actionMeta.removedValue` is
                // undefined
                if (actionMeta.removedValue?.isFixed) {
                  return;
                }

                break;
              }
            }

            props.control.setValue(newValue.filter((v) => !v.isDisabled));
          }}
          onCreateOption={(inputValue) => {
            const email = parseStringToEmailAddress(inputValue);

            if (!email) {
              console.debug("onCreateOption: not a valid email address");
              return;
            }

            const option: IEmailRecipientOption = {
              type: "email",
              label: email.label || email.address,
              email: email.address,
              value: email.address,
            };

            props.control.setValue([...props.control.value, option]);
          }}
          loadOptions={loadOptions}
          placeholder={
            isTouched && isInvalid
              ? `${placeholderPrefix} required...`
              : `${placeholderPrefix}s...`
          }
          autoFocus={props.autoFocus}
          multiple
          components={components}
          menuPortalTarget={props.autocompleteMenuPortalEl}
          menuPlacement="bottom"
          classNames={cx(
            recipientsInputCSS,
            isTouched && isInvalid && `input-invalid`,
            props.autocompleteClassName,
          )}
        />
      </div>
    </div>
  );
});

const recipientsInputCSS = css`
  &.input-invalid .react-select-placeholder {
    color: ${red.red9};
  }
`;

const MultiValue: ComponentType<MultiValueProps<IRecipientOption, true>> = (
  props,
) => {
  const option = props.data as IRecipientOption;
  const sharedChannel = option.type === "channel" ? option.sharedChannel : null;

  const tooltipContent = () => {
    if (!sharedChannel) {
      return option.type === "user"
        ? option.label
        : option.type === "channel"
        ? option.label
        : option.type === "email"
        ? `"${option.label}" <${option.email}>`
        : throwUnreachableCaseError(option);
    } else if (sharedChannel.showWalkthrough) {
      if (props.isFocused) {
        return (
          <span>
            Press <kbd>Enter</kbd> to learn about Shared and Private messages.
          </span>
        );
      }

      return `Click to learn about Shared and Private messages.`;
    } else {
      return (
        <div>
          Your account is owned by {sharedChannel.organizationName}. By default,
          the "{option.label}" channel is included as a recipient in all
          conversations with {sharedChannel.organizationName} users. All{" "}
          {sharedChannel.organizationName} users (and only{" "}
          {sharedChannel.organizationName} users) can browse the "{option.label}
          " channel via the "Shared" button in the left-hand navigation sidebar.
          <br /> <br />
          <em>
            Use{" "}
            <code>
              {PLATFORM_MODIFIER_KEY.name}+{PLATFORM_ALT_KEY.name}+P
            </code>{" "}
            to mark this conversation as "Private" and remove the "
            {option.label}" channel as a recipient.
          </em>
        </div>
      );
    }
  };

  let styles = "";

  if (sharedChannel?.showWalkthrough) {
    styles = [
      "text-violet-11 border-violet-11 font-medium hover:cursor-help animate-pulse",
      props.isFocused ? "bg-blue-5" : "bg-violet-6",
      AVAILABLE_LESSON_BOX_SHADOW_PULSE,
    ].join(" ");
  } else if (props.isFocused) {
    styles = "bg-blue-5";
  }

  return (
    <>
      <Tooltip
        side="bottom"
        content={tooltipContent()}
        open={props.isFocused || undefined}
      >
        <div
          className={cx(
            styles,
            "flex px-2 rounded border items-center",
            "shrink-0 flex-wrap m-1",
            option.emphasize ? "font-bold" : "border-slate-8",
            multiValueCSS,
            props.isDisabled && "is-disabled bg-slate-3 text-slate-9",
            sharedChannel && "hover:cursor-help",
            props.isFocused && "option-is-focused",
            // note that we always include this class because, while the tutorial
            // is in progress, the tutorial will be treated as "complete" so
            // `sharedChannel?.showWalkthrough` will be `false`.
            getClassForPrivateMessageTourStep(1),
          )}
        >
          <div className="flex items-center text-sm mr-2">
            <OptionLabel option={option} />
          </div>

          {sharedChannel && (
            <div
              className={cx(!sharedChannel?.showWalkthrough && "opacity-60")}
            >
              <FaQuestionCircle />
            </div>
          )}

          {!option.isFixed && (
            <props.components.Remove
              data={props.data}
              innerProps={props.removeProps}
              selectProps={props.selectProps}
            />
          )}
        </div>
      </Tooltip>

      {sharedChannel?.showWalkthrough && <div className="m-1">and</div>}
    </>
  );
};

const multiValueCSS = css`
  &.is-disabled {
    [role="button"] {
      cursor: default;
    }
  }
`;

const MultiValueLabel: ComponentType<
  MultiValueGenericProps<IRecipientOption, true>
> = (props) => {
  const option = props.data as IRecipientOption;

  return (
    <div className={cx("flex items-center text-sm mr-2")}>
      <OptionLabel option={option} />
    </div>
  );
};

const Option: ComponentType<OptionProps<IRecipientOption, true>> = (props) => {
  const option = props.data as IRecipientOption;

  if (!("type" in option)) {
    return null;
  }

  return (
    <Tooltip
      side="bottom"
      content={option.disabledReason || ""}
      open={option.disabledReason ? props.isFocused : false}
    >
      <div
        className={cx(
          "py-2 px-4 flex items-center hover:cursor-pointer",
          !option.isDisabled && "hover:bg-blue-5",
          props.isFocused
            ? option.isDisabled
              ? "bg-slateA-3"
              : "bg-blue-5"
            : "bg-transparent",
          option.isDisabled && "text-slateA-8",
        )}
        onClick={() => props.selectOption(option)}
      >
        <OptionLabel option={option} />

        <span
          className={cx(
            "ml-4",
            option.isDisabled ? "text-slateA-8" : "text-slateA-9",
          )}
        >
          {option.type === "channel"
            ? option.channelGroupNames.join(", ")
            : option.email}
        </span>
      </div>
    </Tooltip>
  );
};

const OptionLabel: ComponentType<{ option: IRecipientOption }> = ({
  option,
}) => {
  switch (option.type) {
    case "user": {
      return <>@ {option.label}</>;
    }
    case "channel": {
      if (option.classification === "private") {
        return (
          <>
            # {option.label} <BsLockFill className="ml-1 scale-75" />
          </>
        );
      }

      return <># {option.label}</>;
    }
    case "email": {
      return <>{option.label}</>;
    }
    default: {
      throw new UnreachableCaseError(option);
    }
  }
};

const components = {
  Option,
  MultiValue,
  MultiValueLabel,
};

export function observeRecipientOptions(args: {
  threadType: "COMMS" | "EMAIL";
  isThreadPrivate: boolean | null;
}) {
  return combineLatest([
    ALL_OTHER_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$,
    USER_CHANNELS$,
  ]).pipe(
    map(([organizationMembers, channels]) => {
      return [
        ...channels.filter((c) => !c.isOrganizationSharedChannel),
        ...organizationMembers,
      ].map((doc) => buildRecipientOption({ ...args, doc }));
    }),
    distinctUntilChanged(isEqual),
  );
}

export function useRecipientOptions(args: {
  threadType: "COMMS" | "EMAIL";
  isThreadPrivate: boolean | null;
}) {
  return useObservable(() => observeRecipientOptions(args), {
    deps: [args.threadType, args.isThreadPrivate],
    initialValue: [] as IRecipientOption[],
  });
}

export function buildRecipientOption(args: {
  threadType: "COMMS";
  doc: IChannelDocWithCurrentUserData | IAcceptedOrganizationMemberDoc;
  isThreadPrivate: boolean | null;
}): ICommsThreadRecipientOption;
export function buildRecipientOption(args: {
  threadType: "EMAIL";
  doc: IChannelDocWithCurrentUserData | IAcceptedOrganizationMemberDoc;
  isThreadPrivate: boolean | null;
}): IEmailThreadRecipientOption;
export function buildRecipientOption(args: {
  threadType: "COMMS" | "EMAIL";
  doc: IChannelDocWithCurrentUserData | IAcceptedOrganizationMemberDoc;
  isThreadPrivate: boolean | null;
}): IRecipientOption;
export function buildRecipientOption(args: {
  threadType: "COMMS" | "EMAIL";
  doc: IChannelDocWithCurrentUserData | IAcceptedOrganizationMemberDoc;
  isThreadPrivate: boolean | null;
}): IRecipientOption {
  switch (args.doc.__docType) {
    case "IChannelDoc": {
      return buildChannelRecipientOption(args.doc, args.isThreadPrivate);
    }
    case "IOrganizationMemberDoc": {
      return buildUserRecipientOption(args.doc);
    }
    default: {
      throw new UnreachableCaseError(args.doc);
    }
  }
}

function buildChannelRecipientOption(
  doc: IChannelDocWithCurrentUserData,
  isThreadPrivate: boolean | null,
): IChannelRecipientOption {
  const isDisabled =
    isThreadPrivate === null
      ? false
      : isThreadPrivate !== (doc.classification === "private");

  const disabledReason = !isDisabled
    ? ""
    : isThreadPrivate
    ? "Cannot add shared channel to private thread."
    : "Cannot add private channel to shared thread.";

  return {
    type: "channel",
    value: doc.id,
    label: doc.name,
    channelGroupNames: doc.__local.knownChannelGroups
      .map((group) => group.name)
      .sort(stringComparer),
    classification: doc.classification,
    isDisabled,
    disabledReason,
  };
}

function buildUserRecipientOption(
  doc: IAcceptedOrganizationMemberDoc,
): IUserRecipientOption {
  return {
    type: "user",
    label: doc.user.name,
    value: doc.id,
    email: doc.user.email,
  };
}

export async function convertDraftRecipientToRecipientOption(
  recipient: TDraftRecipient,
  isThreadPrivate: boolean | null,
) {
  const currentUser = getAndAssertCurrentUser();

  if (!currentUser.organizationId) {
    throw new Error(
      "convertDraftRecipientToRecipientOption() !currentUser.organizationId",
    );
  }

  switch (recipient.type) {
    case "userId": {
      const member = await getOrganizationMember(
        currentUser.organizationId,
        recipient.value,
      );

      if (!member?.accepted) return null;

      return buildUserRecipientOption(member as IAcceptedOrganizationMemberDoc);
    }
    case "channelId": {
      const channel = await getChannel(recipient.value);

      if (!channel) return null;

      return buildChannelRecipientOption(channel, isThreadPrivate);
    }
    case "emailAddress": {
      const email = parseStringToEmailAddress(recipient.value);

      if (!email) {
        console.debug("Failed to parse email draft recipient", recipient);
        return null;
      }

      const member = await getOrganizationMemberByEmail(
        currentUser.organizationId,
        email.address,
      );

      if (member?.accepted && member.user) {
        return buildUserRecipientOption(
          member as IAcceptedOrganizationMemberDoc,
        );
      }

      return {
        type: "email",
        label: email.label || email.address,
        value: email.address,
        email: email.address,
      } satisfies IEmailRecipientOption as IEmailRecipientOption;
    }
  }
}
