import { cx } from "@emotion/css";
import { forwardRef, PropsWithChildren } from "react";
import { useAlwaysMakeHeaderStickySetting } from "~/pages/settings/SettingsForm";
import { SidebarIcon } from "./SidebarIcon";

export const Header = forwardRef<
  HTMLElement,
  PropsWithChildren<{
    theme?: "light" | "dark";
    className?: string;
  }>
>((props, ref) => {
  const [alwaysMakeHeaderSticky] = useAlwaysMakeHeaderStickySetting();

  return (
    <header
      ref={ref}
      className={cx(
        "relative top-0 flex z-20 transition-shadow duration-300",
        "py-6 px-12",
        props.theme === "dark" ? "bg-slateDark-5 text-white" : "bg-white",
        alwaysMakeHeaderSticky ? "sticky" : "md-max-h:shadow-none md-h:sticky",
        props.className,
      )}
    >
      <SidebarIcon />

      {props.children}
    </header>
  );
});

export const HeaderMenu = forwardRef<HTMLUListElement, PropsWithChildren<{}>>(
  (props, ref) => {
    return (
      <ul ref={ref} className="flex mt-4 space-x-2">
        {props.children}
      </ul>
    );
  },
);
