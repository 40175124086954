import { css } from "@emotion/css";
import {
  BehaviorSubject,
  distinctUntilChanged,
  fromEvent,
  map,
  merge,
  shareReplay,
} from "rxjs";
import { startWith } from "~/utils/rxjs-operators";
import { useObservable } from "~/utils/useObservable";

/** Default refresh interval is every second. */
export function isElementFocused$(el: HTMLElement) {
  return merge(fromEvent(el, "focus"), fromEvent(el, "blur")).pipe(
    map((e) => (e.type === "focus" ? true : false)),
    startWith(() => document.activeElement === el),
    distinctUntilChanged(),
    shareReplay(1),
  );
}

export const WINDOW_FOCUSED$ = merge(
  fromEvent(window, "focus"),
  fromEvent(window, "blur"),
).pipe(
  map((e) => (e.type === "focus" ? true : false)),
  startWith(() => document.hasFocus()),
  distinctUntilChanged(),
  shareReplay(1),
);

export const WINDOW_VISIBLE$ = fromEvent(document, "visibilitychange").pipe(
  map(() => document.visibilityState === "visible"),
  startWith(() => document.visibilityState === "visible"),
  shareReplay({ refCount: true, bufferSize: 1 }),
);

/**
 * Returns true if the window/tab is focused, false otherwise.
 * Note, this will return false if the window is focused but focus is
 * within an iframe (but at time of writing, the Comms app doesn't use
 * iframes).
 */
export function isWindowFocused() {
  return document.hasFocus();
}

export function useIsWindowFocused() {
  return useObservable(() => WINDOW_FOCUSED$, {
    synchronous: true,
  });
}

export type TAppInputMode = "keyboard" | "mouse";

const _APP_INPUT_MODE$ = new BehaviorSubject<TAppInputMode>("mouse");

export const APP_INPUT_MODE$ = _APP_INPUT_MODE$.pipe(distinctUntilChanged());

const removeCursorCSS = css`
  cursor: none !important;

  & * {
    cursor: none !important;
  }
`;

APP_INPUT_MODE$.subscribe((mode) => {
  console.debug("INPUT MODE", mode);

  if (mode === "keyboard") {
    document.body.classList.add(removeCursorCSS);
  } else {
    document.body.classList.remove(removeCursorCSS);
  }
});

const eventListenerOptions = {
  capture: true,
};

merge(
  fromEvent(document.body, "click", eventListenerOptions),
  fromEvent(document.body, "mousemove", eventListenerOptions),
).subscribe(() => _APP_INPUT_MODE$.next("mouse"));

fromEvent(document.body, "keydown", eventListenerOptions).subscribe(() =>
  _APP_INPUT_MODE$.next("keyboard"),
);

export function useAppInputMode() {
  return useObservable(() => APP_INPUT_MODE$, {
    synchronous: true,
  });
}
