import { getSelectionAsElement } from "~/utils/getSelectionAsElement";

/**
 * Gets what the user has currently "selected" in the DOM
 * (e.g. text), if anything, and returns it as an HTML
 * string.
 */
export function getSelectionAsHTMLString() {
  const selectionDIV = getSelectionAsElement();

  if (!selectionDIV) return "";

  // If the selection includes some text inside a post and some
  // text outside a post, only get the text inside the post. This
  // is necessary because double clicking a paragraph element
  // inside a post will include the parent elements of the
  // `div.PostBody` (at least in Google Chrome) which can cause
  // the pasting to get messed up.
  const postSelection = selectionDIV.querySelector(".PostBody");

  if (postSelection) return postSelection.innerHTML;

  return selectionDIV.innerHTML;
}
