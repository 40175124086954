import { ComponentType, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { IListOnEntryActionEvent, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import {
  ContentList,
  EmptyListMessage,
  navigateToEntry,
  useKBarAwareFocusedEntry,
} from "~/components/content-list";
import { useTopScrollShadow } from "~/utils/useScrollShadow";
import { cx } from "@emotion/css";
import { PendingRequestBar } from "~/components/PendingRequestBar";
import {
  ChannelEntry,
  IChannelEntry,
  TOrderChannelsBy,
  useChannelEntries,
} from "./ChannelEntry";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { useOrganization } from "~/services/organization.service";
import {
  ICommandArgs,
  isModKeyActive,
  useRegisterCommands,
} from "~/services/command.service";
import {
  channelSubscriptionCommands,
  ESCAPE_TO_INBOX_COMMAND,
  pinChannelCommand,
  unpinChannelCommand,
} from "~/utils/common-commands";
import { updateChannelSubscription } from "~/services/subscription.service";
import * as MainLayout from "~/page-layouts/main-layout";
import { openLinkInNewTabOrWindow } from "~/utils/navigation-helpers";

export const ExploreChannelsView: ComponentType<{}> = () => {
  const params = useParams();
  const organization = useOrganization(params.organizationId);
  const [orderBy, setOrderBy] = useState<TOrderChannelsBy>("subscriber-count");

  const channelEntries = useChannelEntries({
    orderBy,
    organizationId: params.organizationId,
  });

  const [focusedChannelEntry, setFocusedChannelEntry] =
    useKBarAwareFocusedEntry<IChannelEntry>();

  useRegisterCommands({
    commands() {
      const commands: ICommandArgs[] = [
        ESCAPE_TO_INBOX_COMMAND,
        ...channelSubscriptionCommands({
          channel: focusedChannelEntry?.channel,
          channelSubscription: focusedChannelEntry?.subscription,
        }),
      ];

      if (focusedChannelEntry) {
        commands.push(
          pinChannelCommand({
            callback: () => {
              updateChannelSubscription({
                channelId: focusedChannelEntry.channel.id,
                isPinned: true,
              });
            },
          }),
          unpinChannelCommand({
            callback: () => {
              updateChannelSubscription({
                channelId: focusedChannelEntry.channel.id,
                isPinned: false,
              });
            },
          }),
        );
      }

      return commands;
    },
    deps: [focusedChannelEntry],
  });

  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
    deps: [!!channelEntries],
  });

  const pageTitle = organization
    ? `Explore ${organization.nameShort}${
        organization.nameShort.endsWith("s") ? "'" : "'s"
      } Channels`
    : "Explore Channels";

  return (
    <>
      <Helmet>
        <title>{pageTitle} | Comms</title>
      </Helmet>

      <MainLayout.Header ref={headerRef} className="flex-col">
        <div className={cx("flex items-center")}>
          <h1 className="text-3xl">{pageTitle}</h1>
        </div>

        <MainLayout.HeaderMenu>
          <li className="flex">
            <div className="text-slate-9">
              <small>Order by:</small>
            </div>

            <div className="w-4" />

            <ToggleGroup.Root
              type="single"
              onValueChange={(e) => setOrderBy(e as TOrderChannelsBy)}
              onKeyDown={(e) => {
                const isLeftOrRightArrowKey =
                  e.key === "ArrowLeft" || e.key === "ArrowRight";

                if (!isLeftOrRightArrowKey) return;

                // If we don't do this, then ArrowLeft will focus the sidebar
                e.stopPropagation();
              }}
            >
              <ToggleItem
                value="subscriber-count"
                isSelected={orderBy === "subscriber-count"}
              >
                <small>Subscriber Count</small>
              </ToggleItem>

              <ToggleItem
                value="recently-active"
                isSelected={orderBy === "recently-active"}
              >
                <small>Recently Active</small>
              </ToggleItem>

              <ToggleItem value="name" isSelected={orderBy === "name"}>
                <small>Name</small>
              </ToggleItem>
            </ToggleGroup.Root>
          </li>
        </MainLayout.HeaderMenu>
      </MainLayout.Header>

      <ListScrollbox
        isBodyElement
        offsetHeaderEl={headerRef}
        onlyOffsetHeaderElIfSticky
      >
        {!channelEntries ? (
          <PendingRequestBar>
            <EmptyListMessage text="Nothing yet." />
          </PendingRequestBar>
        ) : channelEntries.length === 0 ? (
          <EmptyListMessage text="Nothing yet." />
        ) : (
          <ContentList<IChannelEntry>
            onEntryFocused={setFocusedChannelEntry}
            onEntryAction={onChannelSelectNavigateToChannel}
            className="mb-20"
            autoFocus
          >
            {channelEntries.map((entry, index) => (
              <ChannelEntry
                key={entry.id}
                entry={entry}
                relativeOrder={index}
                orderBy={orderBy}
              />
            ))}
          </ContentList>
        )}
      </ListScrollbox>
    </>
  );
};

const ToggleItem: ComponentType<{ value: string; isSelected: boolean }> = (
  props,
) => {
  return (
    <ToggleGroup.Item
      value={props.value}
      className={cx(
        "inline-flex items-center border group px-2 py-1",
        "first:rounded-l last:rounded-r",
        "border-r-0 last:border-r",
        "border-slate-9",

        props.isSelected ? "bg-slate-12 text-white" : "hover:bg-slateA-3",
      )}
    >
      {props.children}
    </ToggleGroup.Item>
  );
};

function onChannelSelectNavigateToChannel({
  entry,
  event,
}: IListOnEntryActionEvent<IChannelEntry>) {
  const to = `/channels/${entry.channel.id}`;

  if (isModKeyActive(event)) {
    openLinkInNewTabOrWindow(to);
  } else {
    navigateToEntry(entry.id, to);
  }
}
