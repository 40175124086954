import { getTypedCallableFn } from "~/firebase";
import {
  switchMap,
  of,
  shareReplay,
  combineLatest,
  map,
  distinctUntilChanged,
  firstValueFrom,
} from "rxjs";
import { collectionData, docData } from "~/utils/rxFireWrappers";
import { docRef, collectionRef } from "~/firestore.service";
import {
  IChannelDoc,
  IChannelGroupDoc,
  IChannelMemberDoc,
  IThreadDoc,
  IUserDoc,
  WithLocalData,
} from "@libs/firestore-models";
import {
  ASSERT_CURRENT_USER$,
  ASSERT_CURRENT_USER_ID$,
  catchNoCurrentUserError,
} from "./user.service";
import { useObservable } from "~/utils/useObservable";
import { isEqual } from "@libs/utils/isEqual";
import { stringComparer } from "~/utils/comparers";
import { startWith } from "~/utils/rxjs-operators";
import { isNonNullable } from "@libs/utils/predicates";
import {
  ALL_OTHER_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$,
  USER_CHANNEL_GROUPS$,
} from "./organization.service";
import { orderBy, query, where } from "firebase/firestore";
import {
  checkValueMatchesType,
  SetNonNullable,
} from "@libs/utils/type-helpers";

export type IChannelDocWithCurrentUserData = WithLocalData<
  IChannelDoc,
  "IChannelDoc",
  {
    fromCurrentUser: IUserDoc["channelPermissions"][string];
    knownChannelGroups: IChannelGroupDoc[];
  }
>;

export const createChannel = getTypedCallableFn("channelcreate");
export const sendChannelInvite = getTypedCallableFn("channelmemberinvite");
export const updateChannel = getTypedCallableFn("channelupdate");

export const USER_CHANNELS$ = ASSERT_CURRENT_USER$.pipe(
  map((userDoc) => userDoc.channelPermissions),
  distinctUntilChanged(isEqual),
  switchMap((userChannelPermissions) => {
    const userChannelPermissionsEntries = Object.entries(
      userChannelPermissions,
    );

    if (userChannelPermissionsEntries.length === 0) return of([]);

    const channels$ = combineLatest(
      userChannelPermissionsEntries.map(([channelId, channelPermissions]) =>
        docData(docRef("channels", channelId)).pipe(
          map((channel) => {
            return (
              channel && {
                ...channel,
                __docType: "IChannelDoc" as const,
                __local: {
                  fromCurrentUser: channelPermissions,
                },
              }
            );
          }),
          distinctUntilChanged(isEqual),
        ),
      ),
    );

    return combineLatest([USER_CHANNEL_GROUPS$, channels$]).pipe(
      map(([channelGroups, maybeChannels]) => {
        const channels = maybeChannels.filter(isNonNullable);

        return channels
          .map((channel) => {
            const channelWithData: IChannelDocWithCurrentUserData = {
              ...channel,
              __local: {
                ...channel.__local,
                knownChannelGroups: channel.channelGroupIds
                  .map((id) => channelGroups.find((c) => c.id === id))
                  .filter(isNonNullable),
              },
            };

            return channelWithData;
          })
          .sort(
            (a, b) =>
              stringComparer(a.name, b.name) || stringComparer(a.id, b.id),
          );
      }),
    );
  }),
  startWith(() => [] as Array<IChannelDocWithCurrentUserData>),
  catchNoCurrentUserError(() => []),
  shareReplay(1),
);

export const USER_NON_ORG_SHARED_CHANNELS$ = USER_CHANNELS$.pipe(
  map((channels) => channels.filter((c) => !c.isOrganizationSharedChannel)),
  shareReplay(1),
);

export const USER_ORG_SHARED_CHANNELS$ = USER_CHANNELS$.pipe(
  map((channels) => channels.filter((c) => c.isOrganizationSharedChannel)),
  distinctUntilChanged(isEqual),
  shareReplay(1),
);

export const PINNED_USER_CHANNELS$ = combineLatest([
  ASSERT_CURRENT_USER_ID$,
  USER_CHANNELS$,
]).pipe(
  switchMap(([userId, channels]) => {
    return collectionData(
      query(
        collectionRef("users", userId, "subscriptions"),
        where("type", "==", "channel"),
        where("isPinned", "==", true),
      ),
    ).pipe(
      map((subcriptions) => {
        return subcriptions
          .map((sub) => channels.find((c) => c.id === sub.id))
          .filter(isNonNullable);
      }),
    );
  }),
  catchNoCurrentUserError(() => []),
  distinctUntilChanged(isEqual),
);

export const CHANNELS_USER_IS_SUBSCRIBED_TO$ = combineLatest([
  ASSERT_CURRENT_USER_ID$,
  USER_CHANNELS$,
]).pipe(
  switchMap(([userId, channels]) => {
    return collectionData(
      query(
        collectionRef("users", userId, "subscriptions"),
        where("type", "==", "channel"),
        where("preference", "in", ["all", "all-new"]),
      ),
    ).pipe(
      map((subcriptions) => {
        return subcriptions
          .map((sub) => channels.find((c) => c.id === sub.id))
          .filter(isNonNullable);
      }),
    );
  }),
  catchNoCurrentUserError(() => []),
  distinctUntilChanged(isEqual),
  shareReplay(1),
);

export function useChannels<T>(options: {
  /**
   * Provide a function which will be used to map the observable results
   * before they are returned. These results will be checked if they are
   * deeply equal and only cause the component to rerender if something
   * actually changes.
   */
  mapResults: (channels: IChannelDocWithCurrentUserData[]) => T;
  deps?: unknown[];
}): T;

export function useChannels(options?: {
  mapResults?: (channels: IChannelDocWithCurrentUserData[]) => unknown;
  deps?: unknown[];
}): IChannelDocWithCurrentUserData[];

export function useChannels<T>({
  mapResults,
  deps,
}: {
  mapResults?: (channels: IChannelDocWithCurrentUserData[]) => T;
  deps?: unknown[];
} = {}): IChannelDocWithCurrentUserData[] | T {
  return useObservable<IChannelDocWithCurrentUserData[] | T>(
    () => {
      if (!mapResults) return USER_CHANNELS$;

      return USER_CHANNELS$.pipe(
        map(mapResults),
        distinctUntilChanged(isEqual),
      );
    },
    {
      synchronous: true,
      deps,
    },
  );
}

/**
 * Fetches a single channel when provided with a channelId.
 * Returns `null` if there is no channel for the given ID,
 * else returns the channel doc.
 */
export function observeChannel(channelId: string) {
  return USER_CHANNELS$.pipe(
    map(
      (channels) =>
        channels.find((channel) => channel.id === channelId) ?? null,
    ),
    distinctUntilChanged(isEqual),
  );
}

export function getChannel(channelId: string) {
  return firstValueFrom(observeChannel(channelId));
}

/**
 * Fetches a single channel when provided with a channelId.
 * While loading, returns `undefined`. Returns `null` if there
 * is no ID for the given channel, else returns the channel doc.
 */
export function useChannel(
  channelId?: string,
): IChannelDocWithCurrentUserData | null | undefined {
  return useObservable(
    () => {
      if (!channelId) return of(null);
      return observeChannel(channelId);
    },
    {
      deps: [channelId],
    },
  );
}

export type IAcceptedChannelMemberDoc = WithLocalData<
  SetNonNullable<IChannelMemberDoc, "user" | "acceptedAt"> & {
    accepted: true;
    removed: false;
    removedAt: null;
  },
  "IChannelMemberDoc",
  {}
>;

export function observeChannelMembers(channelId: string) {
  return collectionData(
    query(
      collectionRef<
        SetNonNullable<IChannelMemberDoc, "user" | "acceptedAt"> & {
          accepted: true;
          removed: false;
          removedAt: null;
        }
      >("channels", channelId, "channelMemberships"),
      where("accepted", "==", true),
      where("removed", "==", false),
      orderBy("user.name", "asc"),
      orderBy("id", "asc"),
    ),
  ).pipe(
    distinctUntilChanged(isEqual),
    map((acceptedMembers) =>
      acceptedMembers.map((m) => {
        return checkValueMatchesType<IAcceptedChannelMemberDoc>({
          ...m,
          __docType: "IChannelMemberDoc" as const,
          __local: {},
        });
      }),
    ),
  );
}

export function useChannelMembers(channelId?: string) {
  return useObservable(
    () => {
      if (!channelId) return of([]);
      return observeChannelMembers(channelId);
    },
    {
      deps: [channelId],
    },
  );
}

export function useUsersWhoCanViewThread(
  thread?:
    | Pick<IThreadDoc, "permittedChannelIds" | "permittedUserIds">
    | null
    | undefined,
) {
  return useObservable(
    () => {
      if (!thread) {
        return of({
          permittedUsers: [],
          unknownPermittedChannelsCount: 0,
          unknownPermittedUsersCount: 0,
        });
      }

      return ASSERT_CURRENT_USER$.pipe(
        switchMap((currentUser) => {
          const permittedChannelIdsUserIsAMemberOf =
            thread.permittedChannelIds.filter(
              (channelId) => channelId in currentUser.channelPermissions,
            );

          const permittedChannelIdsUserIsNotAMemberOf =
            thread.permittedChannelIds.filter(
              (channelId) => !(channelId in currentUser.channelPermissions),
            );

          const permittedChannelMembers$ =
            permittedChannelIdsUserIsAMemberOf.length === 0
              ? of({
                  knownPermittedChannelMembers: [],
                  unknownPermittedChannelIds: [],
                })
              : combineLatest(
                  permittedChannelIdsUserIsAMemberOf.map((channelId) =>
                    observeChannelMembers(channelId),
                  ),
                ).pipe(
                  map((groupedMembers) => {
                    return {
                      knownPermittedChannelMembers: Object.fromEntries(
                        groupedMembers.flatMap((members) =>
                          members.map((m) => [m.id, m.user.name]),
                        ),
                      ),
                      unknownPermittedChannelIds:
                        permittedChannelIdsUserIsNotAMemberOf,
                    };
                  }),
                  distinctUntilChanged(isEqual),
                );

          const permittedUsers$ =
            ALL_OTHER_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$.pipe(
              map((members) => {
                const knownPermittedUsers = members.filter(
                  (m) =>
                    thread.permittedUserIds.includes(m.id) ||
                    currentUser.id === m.id,
                );

                const unknownPermittedUserIds = thread.permittedUserIds.filter(
                  (id) =>
                    !members.some((m) => m.id === id) && currentUser.id !== id,
                );

                return {
                  knownPermittedUsers: Object.fromEntries([
                    ...knownPermittedUsers.map((m) => [m.id, m.user.name]),
                    // We already know the current user can view this thread
                    // because they are looking at it.
                    [currentUser.id, currentUser.name],
                  ] as Array<[string, string]>),
                  unknownPermittedUserIds,
                };
              }),
              distinctUntilChanged(isEqual),
            );

          return combineLatest([
            permittedChannelMembers$,
            permittedUsers$,
          ]).pipe(
            map(
              ([
                { knownPermittedChannelMembers, unknownPermittedChannelIds },
                { knownPermittedUsers, unknownPermittedUserIds },
              ]) => {
                const permittedUsers = Object.values({
                  ...knownPermittedChannelMembers,
                  ...knownPermittedUsers,
                }).sort(stringComparer);

                const unknownPermittedChannelsCount =
                  unknownPermittedChannelIds.length;

                const unknownPermittedUsersCount =
                  unknownPermittedUserIds.length;

                return {
                  permittedUsers,
                  unknownPermittedChannelsCount,
                  unknownPermittedUsersCount,
                };
              },
            ),
            distinctUntilChanged(isEqual),
          );
        }),
        catchNoCurrentUserError(() => ({
          permittedUsers: [],
          unknownPermittedChannelsCount: 0,
          unknownPermittedUsersCount: 0,
        })),
      );
    },
    {
      deps: [thread?.permittedChannelIds, thread?.permittedUserIds],
    },
  );
}
