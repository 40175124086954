import { Navigate } from "react-router-dom";
import { NotFound } from "./components/NotFound";
import { SidebarLayout } from "~/page-layouts/sidebar-layout";
import { Suspense } from "react";
import { LoadingText } from "./components/LoadingText";
import { OfflineMessageView } from "./pages/maintenance/OfflineMessageView";
import { createBrowserRouter } from "react-router-dom";
import { _setRouter } from "./services/navigate.service";
import { LoginView } from "./pages/login/LoginView";
import { MaintenanceView } from "./pages/maintenance/MaintenanceView";
import { InboxView } from "./pages/inbox/InboxView";
import { EditInboxSectionView } from "./pages/inbox/EditInboxSectionView";
import { StarredView } from "./pages/starred/StarredView";
import { DoneView } from "./pages/done/DoneView";
import { SentView } from "./pages/sent/SentView";
import { RemindersView } from "./pages/reminders/RemindersView";
import { SharedMessagesView } from "./pages/shared-messages/SharedMessagesView";
import { SearchView } from "./pages/search/SearchView";
import { DraftsView } from "./pages/drafts/DraftsView";
import { SubscribersView as ChannelSubscribersView } from "./pages/channel/SubscribersView";
import { ChannelView } from "./pages/channel/ChannelView";
import { ThreadView } from "./pages/thread/ThreadView";
import { ExploreChannelsView } from "./pages/organization/explore-channels/ExploreChannels";
import { SettingsView } from "./pages/settings/SettingsView";
import { MembersView as OrganizationMembersView } from "./pages/organization/MembersView";

// Edit 8/10/23: We're temporarily removing lazy loading. Eagerly preloading modules
// used by Comms has proven to not be a robust workaround for:
// https://www.notion.so/levelshealth/Bug-Failed-to-fetch-dynamically-imported-module-e94d27f2b2ae4ecf8250ef6994491863?pvs=4
//
// I think because Google Chrome will only cache a file that isn't being actively
// used for so long. In long running tabs, it appears as though some of these files
// are being removed from memory and later, when someone tries to load one of those
// files, they get an error if the file no longer exists. As a consequence, we're
// going to stop lazy loading these files.

// const InboxView = lazy(() =>
//   import("~/pages/inbox/InboxView").then((m) => ({ default: m.InboxView })),
// );

// const EditInboxSectionView = lazy(() =>
//   import("~/pages/inbox/EditInboxSectionView").then((m) => ({
//     default: m.EditInboxSectionView,
//   })),
// );

// const StarredView = lazy(() =>
//   import("~/pages/starred/StarredView").then((m) => ({
//     default: m.StarredView,
//   })),
// );

// const DoneView = lazy(() =>
//   import("~/pages/done/DoneView").then((m) => ({ default: m.DoneView })),
// );

// const SentView = lazy(() =>
//   import("~/pages/sent/SentView").then((m) => ({ default: m.SentView })),
// );

// const RemindersView = lazy(() =>
//   import("~/pages/reminders/RemindersView").then((m) => ({
//     default: m.RemindersView,
//   })),
// );

// const SharedMessagesView = lazy(() =>
//   import("~/pages/shared-messages/SharedMessagesView").then((m) => ({
//     default: m.SharedMessagesView,
//   })),
// );

// const ChannelView = lazy(() =>
//   import("~/pages/channel/ChannelView").then((m) => ({
//     default: m.ChannelView,
//   })),
// );

// const ExploreChannelsView = lazy(() =>
//   import("~/pages/organization/explore-channels/ExploreChannels").then((m) => ({
//     default: m.ExploreChannelsView,
//   })),
// );

// const ChannelSubscribersView = lazy(() =>
//   import("~/pages/channel/SubscribersView").then((m) => ({
//     default: m.SubscribersView,
//   })),
// );

// const ThreadView = lazy(() =>
//   import("~/pages/thread/ThreadView").then((m) => ({
//     default: m.ThreadView,
//   })),
// );

// const SearchView = lazy(() =>
//   import("~/pages/search/SearchView").then((m) => ({ default: m.SearchView })),
// );

// const DraftsView = lazy(() =>
//   import("~/pages/drafts/DraftsView").then((m) => ({ default: m.DraftsView })),
// );

// const OrganizationMembersView = lazy(() =>
//   import("~/pages/organization/MembersView").then((m) => ({
//     default: m.MembersView,
//   })),
// );

// const SettingsView = lazy(() =>
//   import("~/pages/settings/SettingsView").then((m) => ({
//     default: m.SettingsView,
//   })),
// );

// const MaintenanceView = lazy(() =>
//   import("~/pages/maintenance/MaintenanceView").then((m) => ({
//     default: m.MaintenanceView,
//   })),
// );

// const LoginView = lazy(() =>
//   import("~/pages/login/LoginView").then((m) => ({ default: m.LoginView })),
// );

// /**
//  * Edit: This isn't working in all situations. I think because Google Chrome will
//  * only cache a file that isn't being actively used for so long. In long running
//  * tabs, it appears as though some of these files are being removed from memory.
//  * As a consequence, we're going to stop lazy loading these files.
//  *
//  * We are eagerly preloading all the modules used by Comms as a workaround to:
//  * https://www.notion.so/levelshealth/Bug-Failed-to-fetch-dynamically-imported-module-e94d27f2b2ae4ecf8250ef6994491863?pvs=4
//  *
//  * When we reintroduce a service worker, it will handle preloading all the modules
//  * and caching them in the background.
//  */
// export function usePreloadModules() {
//   useEffect(() => {
//     Promise.allSettled([
//       import("~/pages/inbox/InboxView"),
//       import("~/pages/inbox/EditInboxSectionView"),
//       import("~/pages/starred/StarredView"),
//       import("~/pages/done/DoneView"),
//       import("~/pages/sent/SentView"),
//       import("~/pages/reminders/RemindersView"),
//       import("~/pages/shared-messages/SharedMessagesView"),
//       import("~/pages/channel/ChannelView"),
//       import("~/pages/organization/explore-channels/ExploreChannels"),
//       import("~/pages/channel/SubscribersView"),
//       import("~/pages/thread/ThreadView"),
//       import("~/pages/search/SearchView"),
//       import("~/pages/drafts/DraftsView"),
//       import("~/pages/organization/MembersView"),
//       import("~/pages/settings/SettingsView"),
//       import("~/pages/maintenance/MaintenanceView"),
//       import("~/pages/login/LoginView"),
//     ]);
//   }, []);
// }

export const router = createBrowserRouter([
  {
    path: "/offline",
    element: <OfflineMessageView />,
  },
  {
    path: "/login",
    element: (
      <Suspense fallback={<LoadingText />}>
        <LoginView />
      </Suspense>
    ),
  },
  {
    path: "/maintenance",
    element: (
      <Suspense fallback={<LoadingText />}>
        <MaintenanceView />
      </Suspense>
    ),
  },
  {
    path: "/",
    element: <SidebarLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="inbox" replace />,
      },
      {
        path: "inbox",
        element: (
          <Suspense fallback={<LoadingText />}>
            <InboxView />
          </Suspense>
        ),
      },
      {
        path: "inbox/new",
        element: (
          <Suspense fallback={<LoadingText />}>
            <EditInboxSectionView />
          </Suspense>
        ),
      },
      {
        path: "inbox/DEFAULT",
        element: <Navigate to="inbox" replace />,
      },
      {
        path: "inbox/DEFAULT/edit",
        element: <Navigate to="inbox" replace />,
      },
      {
        path: "inbox/:inboxSectionId",
        element: (
          <Suspense fallback={<LoadingText />}>
            <InboxView />
          </Suspense>
        ),
      },
      {
        path: "inbox/:inboxSectionId/edit",
        element: (
          <Suspense fallback={<LoadingText />}>
            <EditInboxSectionView />
          </Suspense>
        ),
      },
      {
        path: "starred",
        element: (
          <Suspense fallback={<LoadingText />}>
            <StarredView />
          </Suspense>
        ),
      },
      {
        path: "done",
        element: (
          <Suspense fallback={<LoadingText />}>
            <DoneView />
          </Suspense>
        ),
      },
      {
        path: "sent",
        element: (
          <Suspense fallback={<LoadingText />}>
            <SentView />
          </Suspense>
        ),
      },
      {
        path: "reminders",
        element: (
          <Suspense fallback={<LoadingText />}>
            <RemindersView />
          </Suspense>
        ),
      },
      {
        path: "shared-messages",
        element: (
          <Suspense fallback={<LoadingText />}>
            <SharedMessagesView />
          </Suspense>
        ),
      },
      {
        path: "search",
        element: (
          <Suspense fallback={<LoadingText />}>
            <SearchView />
          </Suspense>
        ),
      },
      {
        path: "drafts",
        element: (
          <Suspense fallback={<LoadingText />}>
            <DraftsView />
          </Suspense>
        ),
      },
      {
        path: "channels/:channelId/subscribers",
        element: (
          <Suspense fallback={<LoadingText />}>
            <ChannelSubscribersView />
          </Suspense>
        ),
      },
      {
        path: "channels/:channelId",
        element: (
          <Suspense fallback={<LoadingText />}>
            <ChannelView />
          </Suspense>
        ),
      },
      {
        path: "threads/:threadId",
        element: (
          <Suspense fallback={<LoadingText />}>
            <ThreadView />
          </Suspense>
        ),
      },
      {
        path: "emails/:threadId",
        element: (
          <Suspense fallback={<LoadingText />}>
            <ThreadView />
          </Suspense>
        ),
      },
      {
        path: "organizations/:organizationId/members",
        element: (
          <Suspense fallback={<LoadingText />}>
            <OrganizationMembersView />
          </Suspense>
        ),
      },
      {
        path: "organizations/:organizationId/explore-channels",
        element: (
          <Suspense fallback={<LoadingText />}>
            <ExploreChannelsView />
          </Suspense>
        ),
      },
      {
        path: "settings",
        element: (
          <Suspense fallback={<LoadingText />}>
            <SettingsView />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

_setRouter(router);
