import { ComponentType, useRef } from "react";
import {
  ITriagedNotificationDoc,
  triageThread,
  useTriagedNotifications,
} from "~/services/inbox.service";
import {
  IListOnEntryActionEvent,
  IListRef,
  ListScrollbox,
} from "~/components/list";
import { Helmet } from "react-helmet-async";
import {
  ContentList,
  EmptyListMessage,
  useKBarAwareFocusedEntry,
  NotificationEntry,
  onNotificationSelectNavigateToPost,
  useRegisterNotificationEntryCommands,
} from "~/components/content-list";
import { useTopScrollShadow } from "~/utils/useScrollShadow";
import { PendingRequestBar } from "~/components/PendingRequestBar";
import { ICommandArgs, useRegisterCommands } from "~/services/command.service";
import { RemindMeDialogState } from "~/dialogs/remind-me";
import {
  ESCAPE_TO_INBOX_COMMAND,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";

export const RemindersView: ComponentType<{}> = () => {
  const notifications = useTriagedNotifications();

  const listRef = useRef<IListRef<ITriagedNotificationDoc>>(null);

  useRegisterNotificationEntryCommands({
    priority: { delta: 1 },
    listRef,
    deps: [notifications?.length],
  });

  const [focusedNotification, setFocusedNotification] =
    useKBarAwareFocusedEntry<NonNullable<typeof notifications>[number]>();

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [ESCAPE_TO_INBOX_COMMAND];

      if (focusedNotification) {
        commands.push(
          markDoneCommand({
            callback: () => {
              triageThread({
                threadId: focusedNotification.id,
                done: true,
              });
            },
          }),
          markNotDoneCommand({
            callback: () => {
              triageThread({
                threadId: focusedNotification.id,
                done: false,
              });
            },
          }),
          setThreadReminderCommand({
            label: "Update reminder",
            callback: () => {
              RemindMeDialogState.toggle(true, {
                id: focusedNotification.id,
                triagedUntil:
                  focusedNotification.triagedUntil?.toDate() || null,
                isStarred: focusedNotification.isStarred ?? false,
              });
            },
          }),
          removeThreadReminderCommand({
            hotkeys: [],
            callback: () => {
              triageThread({
                threadId: focusedNotification.id,
                triagedUntil: null,
              });
            },
          }),
          {
            label: "Remove reminder & move to Inbox",
            altLabels: [
              "Unsnooze & move to Inbox",
              "Remove snooze & move to Inbox",
            ],
            hotkeys: ["Shift+H"],
            callback: () => {
              triageThread({
                threadId: focusedNotification.id,
                done: false,
                triagedUntil: null,
              });
            },
          },
        );

        if (focusedNotification.isStarred) {
          commands.push(
            unstarThreadCommand({
              callback: () => {
                triageThread({
                  threadId: focusedNotification.id,
                  isStarred: false,
                });
              },
            }),
          );
        } else {
          commands.push(
            starThreadCommand({
              callback: () => {
                triageThread({
                  threadId: focusedNotification.id,
                  isStarred: true,
                });
              },
            }),
          );
        }
      }

      return commands;
    },
    deps: [focusedNotification],
  });

  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <>
      <Helmet>
        <title>Reminders | Comms</title>
      </Helmet>

      <MainLayout.Header ref={headerRef}>
        <h1 className="text-3xl">Reminders</h1>
      </MainLayout.Header>

      <ListScrollbox
        isBodyElement
        offsetHeaderEl={headerRef}
        onlyOffsetHeaderElIfSticky
      >
        {!notifications ? (
          <PendingRequestBar>{NoRemindersMessage}</PendingRequestBar>
        ) : notifications.length === 0 ? (
          NoRemindersMessage
        ) : (
          <ContentList<ITriagedNotificationDoc>
            ref={listRef}
            onEntryFocused={setFocusedNotification}
            onEntryAction={
              onNotificationSelectNavigateToPost as (
                args: IListOnEntryActionEvent<ITriagedNotificationDoc>,
              ) => void
            }
            className="mb-20"
            autoFocus
          >
            {notifications.map((notification, index) => (
              <NotificationEntry
                key={notification.id}
                notification={notification}
                relativeOrder={index}
              />
            ))}
          </ContentList>
        )}
      </ListScrollbox>
    </>
  );
};

const NoRemindersMessage = (
  <EmptyListMessage text="None.">
    <div className="mt-8 mx-10 prose text-slate-11 max-w-[600px] min-w-0 rounded bg-slate-2 p-4">
      <p>
        <strong>Hint:</strong> focus an inbox notification and press{" "}
        <kbd>h</kbd> to temporarily remove it from your inbox and set a reminder
        for it. A.K.A. "snoozing" notifications.
      </p>
    </div>
  </EmptyListMessage>
);
