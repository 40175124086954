import { forwardRef, RefObject, useRef } from "react";
import {
  IComposeMessageForm,
  usePromptToRemoveRecipientOnMentionRemoval,
} from "~/components/ComposeMessageContext";
import { IListRef } from "~/components/list";
import { IRichTextEditorRef } from "~/form-components/post-editor";
import {
  ComposePostReplyBase,
  ComposeReplyHint,
  useAddNewChannelMentionsAsRecipients,
  useAddNewUserMentionsAsRecipients,
} from "~/components/ComposeReplyBase";
import { BranchedThreadDraftHeader } from "./BranchedThreadDraftHeader";
import { TBranchedThreadEntry } from "./utils";
import { DraftActions } from "../utils";
import { useControlState } from "~/form-components/utils";

export const BranchedThreadDraft = forwardRef<
  IRichTextEditorRef,
  {
    control: IComposeMessageForm;
    listRef: RefObject<IListRef<TBranchedThreadEntry>>;
    treatLessonAsCompleted: boolean;
  }
>((props, ref) => {
  const formRef = useRef<HTMLFormElement>(null);

  const visibility = useControlState(
    () => props.control.rawValue.visibility,
    [props.control],
  );

  const draftType = useControlState(
    () => props.control.rawValue.type,
    [props.control],
  );

  useAddNewChannelMentionsAsRecipients(
    props.control.controls.body.controls.channelMentions,
    props.control.controls.recipients.controls.to,
  );

  useAddNewUserMentionsAsRecipients(
    props.control.controls.body.controls.userMentions,
    props.control.controls.recipients.controls.to,
  );

  usePromptToRemoveRecipientOnMentionRemoval(props.control);

  return (
    <>
      <ComposePostReplyBase
        ref={ref}
        control={props.control}
        header={
          <BranchedThreadDraftHeader
            control={props.control}
            treatLessonAsCompleted={props.treatLessonAsCompleted}
          />
        }
        draftActions={
          <DraftActions visibility={visibility} draftType={draftType} />
        }
        formRef={formRef}
        listRef={props.listRef}
        onClose={() => {}}
        focusOnInit
      />

      <ComposeReplyHint />
    </>
  );
});
