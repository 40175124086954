import { ComponentType, useEffect, useRef } from "react";
import { useTopScrollShadow } from "~/utils/useScrollShadow";
import {
  ICommandArgs,
  useRegisterCommands,
  withNewCommandContext,
} from "~/services/command.service";
import {
  ISearchEditorRef,
  SearchEditor,
} from "~/form-components/search-editor";
import {
  createFormControl,
  createFormGroup,
  useControl,
} from "solid-forms-react";
import { TextInput } from "~/form-components/TextInput";
import { combineLatest, from } from "rxjs";
import { mapPlainTextSearchQueryToHTMLQuery } from "../search/useSearch/useGetSearchQueryFromURL";
import { ParsedToken } from "@libs/utils/searchQueryParser";
import { OutlineButton } from "~/components/OutlineButtons";

export interface IEditInboxSubsectionSubmitValue {
  id: string | null;
  name: string;
  description: string;
  search: {
    queryText: string;
    queryHTML: string;
    parsedQuery: ParsedToken[];
  };
}

export const EditInboxSubsection: ComponentType<{
  subsection?: {
    id: string;
    name: string;
    description?: string | null;
    query: string;
  };
  onCancel?: () => void;
  onDelete?: () => void;
  onSubmit?: (value: IEditInboxSubsectionSubmitValue) => void;
}> = withNewCommandContext((props) => {
  const isNewPage = !props.subsection;

  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);

  const editorRef = useRef<ISearchEditorRef>(null);

  const subsectionControl = useControl(() => {
    return createFormGroup({
      id: createFormControl<string | null>(null),
      name: createFormControl("", {
        required: true,
      }),
      description: createFormControl(""),
      search: createFormGroup({
        queryText: createFormControl(""),
        queryHTML: createFormControl(""),
        parsedQuery: createFormControl([]),
      }),
    });
  });

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        {
          label: "Submit",
          hotkeys: ["$mod+Enter"],
          triggerHotkeysWhenInputFocused: true,
          callback: () => {
            if (!subsectionControl.isValid) {
              subsectionControl.children.markTouched(true);
              return;
            }

            props.onSubmit?.(subsectionControl.rawValue);
          },
        },
      ];

      return commands;
    },
    deps: [subsectionControl],
  });

  // Initialize the subsectionControl
  useEffect(() => {
    if (!props.subsection) return;

    subsectionControl.patchValue({
      name: props.subsection.name,
      description: props.subsection.description || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subsection === undefined, subsectionControl]);

  // Initialize the query editor with the initial value of this subsection's
  // query.
  useEffect(() => {
    if (!editorRef.current) return;
    if (!props.subsection) return;

    const sub = combineLatest([
      from(mapPlainTextSearchQueryToHTMLQuery(props.subsection.query)),
      editorRef.current.onCreate$,
    ]).subscribe(([{ queryAsHTML }, editor]) => {
      if (!editorRef.current) return;
      if (queryAsHTML === null) return;

      editor.commands.setContent(queryAsHTML, true, {
        preserveWhitespace: true,
      });
    });

    return () => sub.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subsection === undefined]);

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <>
      <span className="text-lg">{isNewPage ? "Add" : "Edit"} subsection</span>

      <div className="flex mt-4">
        <h4 className="text-lg mr-3 text-slate-9">Name</h4>

        <TextInput
          control={subsectionControl.controls.name}
          name="name"
          placeholder="text"
          className="text-lg"
        />
      </div>

      <div className="flex mt-4">
        <h4 className="text-lg mr-3 text-slate-9">Description</h4>

        <TextInput
          control={subsectionControl.controls.description}
          name="description"
          placeholder="text"
          className="text-lg"
        />
      </div>

      <div className="flex mt-4">
        <h4 className="text-lg mr-3 mt-2 text-slate-9">Query</h4>

        <SearchEditor
          editorRef={editorRef}
          control={subsectionControl.controls.search}
        />
      </div>

      <div className="flex mt-4">
        <OutlineButton onClick={props.onCancel}>Cancel</OutlineButton>

        <div className="w-4" />

        <OutlineButton
          onClick={() => {
            if (!subsectionControl.isValid) {
              subsectionControl.children.markTouched(true);
              return;
            }

            props.onSubmit?.(subsectionControl.rawValue);
          }}
        >
          Save
        </OutlineButton>

        <div className="w-4" />

        {!isNewPage && props.onDelete && (
          <OutlineButton
            onClick={() => {
              const confirmed = confirm(
                "Are you sure you want to delete this subsection?",
              );

              if (!confirmed) return;

              props.onDelete?.();
            }}
          >
            Delete
          </OutlineButton>
        )}
      </div>
    </>
  );
});
