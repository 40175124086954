import { css, keyframes } from "@emotion/css";
import { slateA } from "@radix-ui/colors";
import { usePendingUpdates } from "~/services/loading.service";
import { Portal } from "@radix-ui/react-portal";
import { usePortalContext } from "~/services/portal.service";

export function PendingUpdatesSpinner() {
  const { container } = usePortalContext();
  const isPending = usePendingUpdates();

  return (
    <Portal container={container} asChild>
      <div
        id="pending-updates-spinner"
        className="fixed bottom-8 right-8 w-0 h-0 pointer-events-none z-[2000]"
      >
        {isPending && <div className={spinnerCSS} />}
      </div>
    </Portal>
  );
}

const spinnerKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const isInEmulator = import.meta.env.VITE_FIREBASE_EMULATORS === "true";

const spinnerCSS = css`
  display: inline-block;
  width: 24px;
  height: 24px;

  &:after {
    content: " ";
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 3px solid ${isInEmulator ? slateA.slateA10 : slateA.slateA6};
    border-color: ${isInEmulator ? slateA.slateA10 : slateA.slateA6} transparent
      transparent;
    animation: ${spinnerKeyframes} 0.15s linear infinite;
  }
`;
