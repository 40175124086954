import Mention from "@tiptap/extension-mention";
import tippy, { Instance, GetReferenceClientRect } from "tippy.js";
import { mergeAttributes, ReactRenderer } from "@tiptap/react";
import { MentionDropdown } from "./MentionDropdown";
import { firstValueFrom, shareReplay } from "rxjs";
import { useEffect } from "react";
import { PluginKey } from "@tiptap/pm/state";
import { SuggestionEntryComponent } from "../utils";
import {
  ALL_OTHER_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$,
  IAcceptedOrganizationMemberDoc,
} from "~/services/organization.service";
import commandScore from "command-score";
import { UnreachableCaseError } from "@libs/utils/errors";
import {
  IChannelDocWithCurrentUserData,
  USER_NON_ORG_SHARED_CHANNELS$,
} from "~/services/channels.service";
import { observeCurrentUserSetting } from "~/services/settings.service";
import { applyAdditionalMentionSuggestionWeights } from "~/form-components/tiptap/suggestion-utils";
import { numberComparer, stringComparer } from "@libs/utils/comparers";

export const MentionLevel1 = buildMentionExtension("@");
export const MentionLevel2 = buildMentionExtension("@@");
export const MentionLevel3 = buildMentionExtension("@@@");

const MENTION_FREQUENCY_SETTING$ = observeCurrentUserSetting(
  "MentionFrequencySettings",
).pipe(shareReplay({ bufferSize: 1, refCount: true }));

/**
 * Maintains proper subscriptions to make opening the mentions list
 * speedy. Also, since the mentions list queries using promises
 * we can only return a single value. Firestore often returns partial
 * results initially and then updates later with more results.
 * By maintaing a subscription here, we ensure that the user gets
 * better results.
 */
export function useMentionExtensionSubscriptions() {
  useEffect(() => {
    const sub = ALL_OTHER_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$.subscribe();
    sub.add(USER_NON_ORG_SHARED_CHANNELS$.subscribe());
    sub.add(MENTION_FREQUENCY_SETTING$.subscribe());
    return () => sub.unsubscribe();
  }, []);
}

function buildMentionExtension(char: "@" | "@@" | "@@@") {
  const extensionId = `mention-${char}`;
  const priority = char === "@" ? 300 : char === "@@" ? 200 : 100;

  return Mention.extend({
    name: extensionId,
    addAttributes() {
      return {
        ...this.parent?.(),

        subject: {
          default: null,
          parseHTML: (element) => element.getAttribute("data-subject"),
          renderHTML: (attributes) => {
            if (!attributes.subject) {
              return {};
            }

            return {
              "data-subject": attributes.subject,
            };
          },
        },

        priority: {
          default: priority,
          renderHTML: () => {
            return {
              "data-priority": priority,
            };
          },
        },
      };
    },
    parseHTML() {
      return [
        {
          tag: `span[data-type="mention"][data-priority="${priority}"]`,
        },
      ];
    },
    renderHTML({ node, HTMLAttributes }) {
      return [
        "span",
        mergeAttributes(
          { "data-type": "mention" },
          this.options.HTMLAttributes,
          HTMLAttributes,
        ),
        this.options.renderLabel({
          options: this.options,
          node,
        }),
      ];
    },
  }).configure({
    renderLabel({ node }) {
      const subject = node.attrs.subject as "user" | "channel";

      let prefix: string;

      switch (subject) {
        case "user": {
          prefix = char.split("").fill("@").join("");
          break;
        }
        case "channel": {
          prefix = char.split("").fill("#").join("");
          break;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }

      return `${prefix}${node.attrs.label ?? node.attrs.id}`;
    },
    suggestion: {
      pluginKey: new PluginKey(extensionId),
      char,
      items: async ({ query, editor }) => {
        const [members, channels, mentionFrequencySettingsDoc] =
          await Promise.all([
            firstValueFrom(ALL_OTHER_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$),
            firstValueFrom(USER_NON_ORG_SHARED_CHANNELS$),
            firstValueFrom(MENTION_FREQUENCY_SETTING$),
          ]);

        const results = {
          people: [] as IAcceptedOrganizationMemberDoc[],
          channels: [] as IChannelDocWithCurrentUserData[],
        };

        const mentions = mentionFrequencySettingsDoc?.mentions || {};

        const mentionWeightAdjustments: Record<string, number> =
          editor.storage.mention?.mentionWeightAdjustments || {};

        const filterItems = <T extends { id: string }>(
          items: T[],
          query: string,
          getLabel: (o: T) => string,
        ) =>
          items
            .map((c) => {
              const label = getLabel(c);
              let score = commandScore(label, query);

              if (score !== 0) {
                score = applyAdditionalMentionSuggestionWeights({
                  score,
                  id: c.id,
                  frequencyDictionary: mentions,
                  mentionWeightAdjustments,
                });
              }

              return {
                option: c,
                score,
              };
            })
            .sort((a, b) => b.score - a.score)
            .slice(0, 9)
            .filter((r) => r.score > 0)
            .map((r) => ({ ...r.option }));

        if (query.startsWith("#")) {
          results.channels = filterItems(
            channels,
            query.slice(1),
            (c) =>
              c.name +
              " " +
              c.__local.knownChannelGroups.map((g) => g.name).join(" "),
          );
        } else if (query) {
          results.people = filterItems(
            members,
            query,
            (m) => `${m.user.name} ${m.user.email}`,
          );

          results.channels = filterItems(
            channels,
            query,
            (c) =>
              c.name +
              " " +
              c.__local.knownChannelGroups.map((g) => g.name).join(" "),
          );
        } else {
          results.people = members
            .map((member) => {
              return {
                member,
                score: applyAdditionalMentionSuggestionWeights({
                  score: 0,
                  id: member.id,
                  frequencyDictionary: mentions,
                  mentionWeightAdjustments,
                }),
              };
            })
            .sort(
              (a, b) =>
                numberComparer(b.score, a.score) ||
                stringComparer(a.member.user.name, b.member.user.name) ||
                stringComparer(a.member.id, b.member.id),
            )
            .slice(0, 5)
            .map((m) => m.member);

          results.channels = channels
            .map((channel) => {
              return {
                channel,
                score: applyAdditionalMentionSuggestionWeights({
                  score: 0,
                  id: channel.id,
                  frequencyDictionary: mentions,
                  mentionWeightAdjustments,
                }),
              };
            })
            .sort(
              (a, b) =>
                numberComparer(b.score, a.score) ||
                stringComparer(a.channel.name, b.channel.name) ||
                stringComparer(a.channel.id, b.channel.id),
            )
            .slice(0, 5)
            .map((m) => m.channel);
        }

        return [...results.people, ...results.channels].map((o, index) => ({
          ...o,
          index,
        }));
      },

      render: () => {
        let component: SuggestionEntryComponent<{}>;
        let popup: Instance[] = [];

        return {
          onStart: (props) => {
            component = new ReactRenderer(MentionDropdown, {
              props,
              editor: props.editor,
            });

            if (!props.clientRect) {
              return;
            }

            popup = tippy("body", {
              getReferenceClientRect:
                props.clientRect as GetReferenceClientRect,
              appendTo: () => document.body,
              content: component.element,
              showOnCreate: true,
              interactive: true,
              trigger: "manual",
              placement: "bottom-start",
              zIndex: 150,
            });
          },

          onUpdate(props) {
            component.updateProps(props);

            if (!props.clientRect) {
              return;
            }

            popup[0]?.setProps({
              getReferenceClientRect:
                props.clientRect as GetReferenceClientRect,
            });
          },

          onKeyDown(props) {
            if (props.event.key === "Escape") {
              component.destroy();
              props.event.stopPropagation();
              return true;
            }

            return component.ref?.onKeyDown(props) || false;
          },

          onExit() {
            popup[0]?.destroy();
            component.destroy();
          },
        };
      },
    },
  });
}
