import {
  Editor,
  EditorContent,
  useEditor,
  EditorOptions,
  FocusPosition,
} from "@tiptap/react";
import { Editor as CoreEditor, Extensions } from "@tiptap/core";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import Typography from "@tiptap/extension-typography";
import { css, cx } from "@emotion/css";
import { red, slateDark } from "@radix-ui/colors";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import { lowlight } from "lowlight";
import Link from "@tiptap/extension-link";
import { ListItem } from "./extensions/ListItem";
import useConstant from "use-constant";
import { usePropAsRef } from "~/utils/usePropAsRef";
import { ImageExtension } from "./extensions/image";
import { buildEditorOverflowHandler } from "./extensions/EditorOverflowHandler";
import { CommsShortcuts } from "./extensions/CommsShortcuts";
import { CustomizedStarterKit } from "./extensions/CustomizedStarterKit";
import { PLATFORM_MODIFIER_KEY } from "~/services/command.service";
import Emoji from "./extensions/emoji";
import {
  MentionLevel3,
  MentionLevel1,
  MentionLevel2,
  useMentionExtensionSubscriptions,
} from "./extensions/mention";

export interface IPostEditorRef {
  editor: Editor | null;
  /**
   * An array of callback functions which are run after the
   * TipTap editor is created. Any code with access to this
   * property can add new callbacks to this array to have
   * them called after the TipTap editor is created.
   */
  onCreate: Array<(editor: CoreEditor) => void>;
  focus(position: FocusPosition, options?: { scrollIntoView?: boolean }): void;
}

export const PostEditorBase = forwardRef<
  IPostEditorRef,
  {
    className?: string;
    onChange?: (props: { editor: CoreEditor }) => void;
    onBlur?: () => void;
    onEditorStartOverflow?: () => void;
    onEditorEndOverflow?: () => void;
    getInitialValue?: () => string;
    initialTabIndex?: number;
  }
>((props, ref) => {
  useMentionExtensionSubscriptions();

  const onChangeRef = usePropAsRef(props.onChange);
  const onEditorStartOverflowRef = usePropAsRef(props.onEditorStartOverflow);
  const onEditorEndOverflowRef = usePropAsRef(props.onEditorEndOverflow);
  const editorRef = useRef<Editor | null>(null);
  const onEditorCreateRef = useRef<Array<(editor: CoreEditor) => void>>([]);

  const config = useConstant<Partial<EditorOptions>>(() => {
    // Note, the order of extensions can matter. E.g.
    // the order of these extensions dictates the order of precedence
    // for hotkey commands.
    const extensions: Extensions = [
      CustomizedStarterKit,
      Typography,
      ImageExtension,
      CommsShortcuts,
      CodeBlockLowlight.configure({
        lowlight,
      }),
      // ListItem needs to come before the @mention/etc extensions
      // so that the `@mention` extensions process Enter keydown events.
      // addresses https://github.com/levelshealth/comms/issues/482
      ListItem,
      MentionLevel1,
      MentionLevel2,
      MentionLevel3,
      Emoji,
      Link.configure({
        HTMLAttributes: {
          target: null,
        },
        openOnClick: false,
      }),
      buildEditorOverflowHandler(
        onEditorStartOverflowRef,
        onEditorEndOverflowRef,
      ),
    ];

    return {
      extensions,
      content: props.getInitialValue?.() || "",
      onCreate({ editor }) {
        onEditorCreateRef.current.forEach((fn) => fn(editor));
        onEditorCreateRef.current.length = 0;
      },
      onUpdate({ editor }) {
        onChangeRef.current?.({ editor });
      },
      editorProps: {
        attributes:
          (Number.isInteger(props.initialTabIndex) && {
            tabindex: String(props.initialTabIndex),
          }) ||
          undefined,
      },
    };
  });

  // We're using a ref for editorRef so that we have a stable reference
  // to the editor which we can use inside the config.
  editorRef.current = useEditor(config);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useImperativeHandle(
    ref,
    () => ({
      editor: editorRef.current,
      onCreate: onEditorCreateRef.current,
      /**
       * If the editor is initialized, will immediately focus it. Else,
       * will register onCreate callback to focus the editor after creation.
       */
      focus(
        position: FocusPosition,
        options: { scrollIntoView?: boolean } = {},
      ) {
        if (this.editor) {
          this.editor.commands.focus(position, options);
        } else {
          // In Safari especially, sometimes the editor is not initialized
          // syncronously or even on the next tick
          this.onCreate.push((editor) => {
            editor.commands.focus(position, options);
          });
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editorRef.current],
  );

  // After initialization, emit one change to sync the editors values
  // with the control.
  useEffect(() => {
    if (!editorRef.current) return;
    onChangeRef.current?.({ editor: editorRef.current });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef.current]);

  if (!editorRef.current) {
    return null;
  }

  return (
    <EditorContent
      onBlur={props.onBlur}
      className={cx("RichTextEditor", editorStyles, props.className)}
      editor={editorRef.current}
      onKeyDown={(e) => {
        if (
          (PLATFORM_MODIFIER_KEY.name === "Command" ? e.metaKey : e.ctrlKey) &&
          (e.key === "[" || e.key === "]")
        ) {
          e.preventDefault();
        }
      }}
    />
  );
});

const editorStyles = css`
  width: 100%;

  .ProseMirror-focused {
    outline: none;
  }

  .ProseMirror p.is-editor-empty:first-child::before {
    color: ${slateDark.slate11};
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  &.is-invalid .ProseMirror p:first-child::before {
    color: ${red.red9};
  }
`;
