import { setScrollTop } from "./dom-helpers";

function getElementPositionInContainer(args: {
  containerPos: Pick<DOMRect, "top" | "bottom">;
  elementPos: number;
  /**
   * Offsets the container position by the provided amount for
   * the top and/or bottom of the container.
   *
   * Useful if the container has a floating header/footer elements which
   * might hide content.
   */
  containerPosOffset?: {
    top?: number;
    bottom?: number;
  };
}) {
  const { elementPos, containerPos } = args;
  const topOffset = args.containerPosOffset?.top || 0;
  const bottomOffset = args.containerPosOffset?.bottom || 0;

  if (elementPos < containerPos.top + topOffset) {
    return "above" as const;
  } else if (elementPos > containerPos.bottom - bottomOffset) {
    return "below" as const;
  } else {
    return "visible" as const;
  }
}

/**
 * Get the position of an relevant it's container.
 */
export function elementPositionInContainer(args: {
  container: HTMLElement;
  element: HTMLElement;
  /**
   * Offsets the container position by the provided amount for
   * the top and/or bottom of the container.
   *
   * Useful if the container has a floating header/footer elements which
   * might hide content.
   */
  containerPosOffset?: {
    top?: number;
    bottom?: number;
  };
}) {
  const { container, element, containerPosOffset } = args;

  const elementPos = element.getBoundingClientRect();

  if (container === document.body) {
    const containerPos = {
      top: 0,
      // source https://stackoverflow.com/a/8876069/5490505
      bottom: Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0,
      ),
    };

    return {
      top: getElementPositionInContainer({
        containerPos,
        elementPos: elementPos.top,
        containerPosOffset,
      }),
      bottom: getElementPositionInContainer({
        containerPos,
        elementPos: elementPos.bottom,
        containerPosOffset,
      }),
    };
  }

  const containerPos = container.getBoundingClientRect();

  return {
    top: getElementPositionInContainer({
      containerPos,
      elementPos: elementPos.top,
      containerPosOffset,
    }),
    bottom: getElementPositionInContainer({
      containerPos,
      elementPos: elementPos.bottom,
      containerPosOffset,
    }),
  };
}

export function scrollContainerToBottomOfElement(args: {
  container: HTMLElement;
  element: HTMLElement;
  offset?: number;
}) {
  const { container, element, offset = 0 } = args;
  setScrollTop(container, element.offsetTop + element.offsetHeight - offset);
}

export function scrollContainerToTopOfElement(args: {
  container: HTMLElement;
  element: HTMLElement;
  offset?: number;
}) {
  const { container, element, offset = 0 } = args;
  setScrollTop(container, element.offsetTop + offset);
}
