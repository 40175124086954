import {
  switchMap,
  of,
  distinctUntilChanged,
  Observable,
  shareReplay,
  map,
  firstValueFrom,
  OperatorFunction,
} from "rxjs";
import { docData } from "~/utils/rxFireWrappers";
import { docRef } from "~/firestore.service";
import {
  ASSERT_CURRENT_USER_ID$,
  catchNoCurrentUserError,
  getAndAssertCurrentUser,
} from "./user.service";
import { useObservable } from "~/utils/useObservable";
import { isEqual } from "@libs/utils/isEqual";
import {
  IMainSettingsDoc,
  IMentionFrequencySettingsDoc,
  ISettingDoc,
} from "@libs/firestore-models";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { serverTimestamp, setDoc, Timestamp } from "firebase/firestore";
import { offlineAwareFirestoreCRUD } from "./network-connection.service";
import { validateMainSettings } from "~/utils/decoders";
import { withPendingUpdate } from "./loading.service";
import { SetRequired } from "type-fest";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { toast } from "./toast-service";
import { navigateService } from "./navigate.service";

export function observeCurrentUserSetting(
  settingId: "MainSettings",
): Observable<IMainSettingsDoc | null>;
export function observeCurrentUserSetting(
  settingId: "MentionFrequencySettings",
): Observable<IMentionFrequencySettingsDoc | null>;
export function observeCurrentUserSetting(
  settingId: ISettingDoc["id"],
): Observable<ISettingDoc | null>;
export function observeCurrentUserSetting(
  settingId: ISettingDoc["id"],
): Observable<ISettingDoc | null> {
  return ASSERT_CURRENT_USER_ID$.pipe(
    switchMap((userId) =>
      docData(docRef("users", userId, "settings", settingId)),
    ),
    catchNoCurrentUserError(() => null) as OperatorFunction<
      ISettingDoc | null,
      ISettingDoc | null
    >,
    distinctUntilChanged(isEqual),
  );
}

export function useCurrentUserSetting(
  settingId: "MainSettings",
): IMainSettingsDoc | null | undefined;
export function useCurrentUserSetting(
  settingId: "MentionFrequencySettings",
): IMentionFrequencySettingsDoc | null | undefined;
export function useCurrentUserSetting(
  settingId: ISettingDoc["id"],
): ISettingDoc | null | undefined;
export function useCurrentUserSetting(
  settingId?: ISettingDoc["id"],
): ISettingDoc | null | undefined {
  return useObservable(
    () => {
      if (!settingId) return of(null);
      return observeCurrentUserSetting(settingId);
    },
    {
      deps: [settingId],
    },
  );
}

export type TNormalizedMainSettingsDoc = SetRequired<
  IMainSettingsDoc,
  | "inboxLayout"
  | "enableNavBackOnThreadDone"
  | "enableFocusMode"
  | "focusModeExceptions"
  | "enableScheduledDelivery"
  | "scheduledDays"
  | "scheduledTimes"
  | "secondsToWaitToDisableScheduledDelivery"
  | "linkedGmailEmailAccount"
  | "secondsForUndoingSentMessage"
>;

export const CURRENT_USER_MAIN_SETTINGS$ = observeCurrentUserSetting(
  "MainSettings",
).pipe(
  map((settings) => {
    const hasValidScheduledDeliverySettings =
      settings &&
      settings.scheduledDays &&
      settings.scheduledTimes &&
      settings.scheduledDays.length > 0 &&
      settings.scheduledTimes.length > 0;

    if (!hasValidScheduledDeliverySettings) {
      return defaultMainSettings();
    }

    return {
      ...defaultMainSettings(),
      ...settings,
    };
  }),
  distinctUntilChanged(isEqual),
  shareReplay(1),
);

export function getCurrentUserMainSettings() {
  return firstValueFrom(CURRENT_USER_MAIN_SETTINGS$);
}

export function useCurrentUserMainSettings() {
  return useObservable(() => CURRENT_USER_MAIN_SETTINGS$);
}

export function defaultMainSettings(): TNormalizedMainSettingsDoc {
  return {
    id: "MainSettings",
    updatedAt: Timestamp.now(),
    inboxLayout: "consolidated-inbox",
    enableNavBackOnThreadDone: false,
    enableFocusMode: false,
    focusModeExceptions: [100],
    enableScheduledDelivery: false,
    scheduledDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    scheduledTimes: ["10:00", "15:00"],
    secondsToWaitToDisableScheduledDelivery: 60,
    linkedGmailEmailAccount: false,
    secondsForUndoingSentMessage: 10,
  } satisfies IMainSettingsDoc;
}

export const mergeMainSettings = withPendingRequestBar(
  withPendingUpdate(
    async (
      update: Partial<
        Omit<ReturnType<typeof validateMainSettings>, "id" | "updatedAt">
      >,
    ) => {
      const currentUser = getAndAssertCurrentUser();

      const doc = validateMainSettings({
        ...update,
        id: "MainSettings",
        updatedAt: serverTimestamp(),
      });

      const promise = setDoc(
        docRef("users", currentUser.id, "settings", doc.id),
        doc,
        { merge: true },
      );

      await offlineAwareFirestoreCRUD(promise);
    },
  ),
);

export const updateScheduledDelivery = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(async (enableScheduledDelivery: boolean) => {
    const settingsDoc = await getCurrentUserMainSettings();

    const hasTheUserEverEnabledScheduledDeliveryBefore =
      !!settingsDoc?.scheduledDays &&
      !!settingsDoc.scheduledTimes &&
      settingsDoc.scheduledDays.length > 0 &&
      settingsDoc.scheduledTimes.length > 0;

    if (hasTheUserEverEnabledScheduledDeliveryBefore) {
      mergeMainSettings({
        enableScheduledDelivery,
      });

      toast("vanilla", {
        subject: `Scheduled delivery ${
          enableScheduledDelivery ? "enabled" : "disabled"
        }`,
      });
    } else {
      // If the user has never enabled scheduled delivery before,
      // we take them to the scheduled delivery section of the
      // settings so that they can learn more about it
      // and do any initial setup that's necessary.
      navigateService("/settings#scheduled-delivery");
    }
  }),
);
