import { cx } from "@emotion/css";
import { useIsAppOnline } from "~/services/network-connection.service";

export function OfflineBanner() {
  const onLine = useIsAppOnline();

  if (onLine) return null;

  return (
    <div
      className={cx(
        "fixed bottom-0 h-8 w-full flex justify-center items-center",
        "font-medium bg-tealDark-6 text-white text-sm z-[8999]",
      )}
    >
      <span className="uppercase">Offline</span>&nbsp;- It appears as though you
      are offline. Comms currently doesn't have offline support.
    </div>
  );
}
