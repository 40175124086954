import {
  createContext,
  forwardRef,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  RefObject,
  useContext,
  useEffect,
  useRef,
} from "react";
import { fromEvent, throttleTime } from "rxjs";
import { Slot } from "@radix-ui/react-slot";
import { useComposedRefs } from "~/utils/useComposedRefs";
import { WINDOW_RESIZE_EVENT$ } from "~/services/window.service";

export interface IListScrollboxContext {
  enableFocusOnMouseover: MutableRefObject<boolean>;
  scrollboxRef: RefObject<HTMLElement>;
  /**
   * Offsets the "top" of the scrollbox by this number of pixels. This
   * is in addition to any offset provided by the "offsetHeaderEl".
   * Integrates with the List component so that focusing a list entry
   * leaves padding above it.
   */
  offsetTopPx: number;
  /**
   * If set to a ref containing an element, scrolling to list
   * items will be offset by the elements height. Useful if
   * the list scrollbox has a sticky header element which we
   * need to account for when scrolling to a list item in the
   * viewport.
   */
  offsetHeaderEl?: RefObject<HTMLElement>;
}

const ListScrollboxContext = createContext<IListScrollboxContext | null>(null);

export function useListScrollboxContext() {
  const context = useContext<IListScrollboxContext | null>(
    ListScrollboxContext,
  );

  if (!context) {
    throw new Error(
      "Must provide ListScrollboxContext. Use " +
        "ListScrollbox for the list container.",
    );
  }

  return context;
}

/**
 * This component is intended to be the scrollbox for `List.Entry` components.
 * It surpresses the List.Entry's focusOnMouseOver (if enabled) when this
 * scrollbox is scrolling. This is necessary to avoid scrolling from triggering
 * mouseover events and unintentionally focusing list entries when the user is
 * using keyboard navigation.
 */
export const ListScrollbox = forwardRef<
  HTMLElement,
  PropsWithChildren<{
    children: ReactElement;
    /** Indicates that the body element is the scrollbox for this list */
    isBodyElement?: boolean;
    /**
     * Offsets the "top" of the scrollbox by this number of pixels. This
     * is in addition to any offset provided by the "offsetHeaderEl".
     * Integrates with the List component so that focusing a list entry
     * leaves padding above it.
     *
     * @default 24
     */
    offsetTopPx?: number;
    /**
     * If provided, the list scrollbox will consider the "top" of the
     * scrollbox to be the bottom of the header element. Useful if a
     * floating header element is hiding the top of the scrollbox.
     */
    offsetHeaderEl?: RefObject<HTMLElement>;
    onlyOffsetHeaderElIfSticky?: boolean;
  }>
>((props, forwardedRef) => {
  const ref = useRef<HTMLElement | null>(
    props.isBodyElement ? document.body : null,
  );

  const enableFocusOnMouseover = useRef(true);
  const composedRefs = useComposedRefs(forwardedRef, ref);
  const offsetHeaderEl = useOffsetHeader(props);

  useEffect(() => {
    const scrollEvents = fromEvent(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      props.isBodyElement ? window : ref.current!,
      "scroll",
    );

    let timeout: number | null = null;

    const sub = scrollEvents.pipe(throttleTime(200)).subscribe(() => {
      if (timeout !== null) {
        clearTimeout(timeout);
        timeout = null;
      }

      enableFocusOnMouseover.current = false;

      timeout = setTimeout(() => {
        enableFocusOnMouseover.current = true;
      }, 300) as unknown as number;
    });

    return () => sub.unsubscribe();
  }, [props.isBodyElement]);

  return (
    <ListScrollboxContext.Provider
      value={{
        enableFocusOnMouseover,
        scrollboxRef: ref,
        offsetTopPx: props.offsetTopPx ?? 24,
        offsetHeaderEl,
      }}
    >
      {props.isBodyElement ? (
        props.children
      ) : (
        <Slot ref={composedRefs} className="relative">
          {props.children}
        </Slot>
      )}
    </ListScrollboxContext.Provider>
  );
});

function useOffsetHeader(args: {
  offsetHeaderEl?: RefObject<HTMLElement>;
  onlyOffsetHeaderElIfSticky?: boolean;
}) {
  const normalizedOffsetHeaderRef = useRef<HTMLElement | null>(null);

  // if args.onlyOffsetHeaderIfSticky === true
  // handles nullifying the normalizedOffsetHeaderRef if the provided
  // args.offsetHeader is not "sticky"
  useEffect(() => {
    if (!args.offsetHeaderEl?.current) return;
    if (!args.onlyOffsetHeaderElIfSticky) return;

    const callback = (el: HTMLElement) => {
      const computedStyles = getComputedStyle(el);

      if (computedStyles.position === "sticky") {
        normalizedOffsetHeaderRef.current =
          args.offsetHeaderEl?.current || null;
      } else {
        normalizedOffsetHeaderRef.current = null;
      }
    };

    const subscription = WINDOW_RESIZE_EVENT$.subscribe(() => {
      if (!args.offsetHeaderEl?.current) return;
      callback(args.offsetHeaderEl.current);
    });

    callback(args.offsetHeaderEl.current);

    return () => subscription.unsubscribe();
  }, [args.offsetHeaderEl, args.onlyOffsetHeaderElIfSticky]);

  return args.onlyOffsetHeaderElIfSticky
    ? normalizedOffsetHeaderRef
    : args.offsetHeaderEl;
}
