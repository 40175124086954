import {
  of,
  map,
  distinctUntilChanged,
  combineLatest,
  switchMap,
  shareReplay,
  firstValueFrom,
  filter,
  Observable,
} from "rxjs";
import { collectionData, docData } from "~/utils/rxFireWrappers";
import { collectionRef, docRef } from "~/firestore.service";
import {
  IBaseDraftDoc,
  IChannelDoc,
  ICommsDraftDoc,
  ICommsPostDoc,
  ICommsThreadDoc,
  IEmailDraftDoc,
  IEmailPostDoc,
  IEmailThreadDoc,
  IPostDoc,
  IThreadDoc,
  IUnsafeDraftDoc,
  ThreadVisibility,
  TUniqCommsDraftProps,
  TUniqEmailDraftProps,
  WithLocalData,
  WithServerTimestamp,
} from "@libs/firestore-models";
import {
  query,
  where,
  orderBy,
  serverTimestamp,
  getDoc,
  setDoc,
  limit,
  Timestamp,
  deleteDoc,
  updateDoc,
  UpdateData,
  WithFieldValue,
  deleteField,
} from "firebase/firestore";
import { useObservable } from "~/utils/useObservable";
import { isEqual } from "@libs/utils/isEqual";
import {
  ASSERT_CURRENT_USER$,
  ASSERT_CURRENT_USER_ID$,
  catchNoCurrentUserError,
  getAndAssertCurrentUser,
} from "./user.service";
import uid from "@libs/utils/uid";
import { isNonNullable } from "@libs/utils/predicates";
import { TimestampOrFieldValueD } from "~/utils/decoders";
import { stripIndent } from "common-tags";
import { USER_CHANNELS$ } from "./channels.service";
import { currentTime } from "~/utils/rxjs-operators";
import dayjs from "dayjs";
import { htmlToText } from "@libs/utils/htmlToText";
import {
  ALL_OTHER_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$,
  IAcceptedOrganizationMemberDoc,
} from "./organization.service";
import { withPendingUpdate } from "./loading.service";
import { stringComparer, timestampComparer } from "@libs/utils/comparers";
import {
  IPostEditorControl,
  IRichTextEditorRef,
} from "~/form-components/post-editor";
import { IFormControl, IFormGroup } from "solid-forms-react";
import { observable } from "~/form-components/utils";
import { useIsWindowFocused } from "~/services/focus.service";
import { sessionStorageService } from "~/services/session-storage.service";
import { RefObject, useEffect } from "react";
import { toast } from "./toast-service";
// This module is dynamically imported everywhere else in the app
// so we only want to grab the types here.
import type {
  IChannelRecipientOption,
  IEmailRecipientOption,
  IRecipientOption,
  IUserRecipientOption,
} from "~/form-components/ThreadRecipients";
import { wait } from "@libs/utils/wait";
import { offlineAwareFirestoreCRUD } from "./network-connection.service";
import { groupBy, pick, uniq } from "lodash-es";
import { SetNonNullable } from "@libs/utils/type-helpers";
import { IEditorMention } from "~/form-components/post-editor/context";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { areDecoderErrors, Decoder, assert } from "ts-decoders";
import {
  buildSentUnsafeDraftD,
  buildWipUnsafeDraftD,
} from "@libs/firestore-models/decoders";
import * as d from "ts-decoders/decoders";
import { UnreachableCaseError } from "@libs/utils/errors";
import { cacheReplayForTime } from "@libs/utils/rxjs-operators";
import {
  parseEmailAddress,
  parseStringToEmailAddress,
} from "@libs/utils/parseEmailAddress";
import { IComposeMessageFormValue } from "~/components/ComposeMessageContext";
import { getEmailReplyRecipientsFromLastPost } from "@libs/firestore-models/utils";
import { EmailAddress } from "@libs/utils/email-rfc";
import { FieldValue } from "@firebase/firestore-types";

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;

export interface IDraft<T = {}>
  extends Omit<IBaseDraftDoc, "recipientChannelIds"> {
  __docType: "IUnsafeDraftDoc";
  __local: T;

  to: TDraftRecipient[];

  cc: TDraftRecipient[];

  bcc: IDraftEmailRecipient[];

  /** Channels that are mentioned in this draft. */
  mentionedChannels: IPostDoc["mentionedChannels"];

  /** Users who are mentioned in this draft. */
  mentionedUsers: IPostDoc["mentionedUsers"];
}

export interface IDraftUserRecipient {
  type: "userId";
  value: string;
}

export interface IDraftChannelRecipient {
  type: "channelId";
  value: string;
}

export interface IDraftEmailRecipient {
  type: "emailAddress";
  value: string;
}

export type TDraftRecipient =
  | IDraftUserRecipient
  | IDraftChannelRecipient
  | IDraftEmailRecipient;

export function getDraftRecipientIdsOfType(
  draft: Pick<IDraft, "to" | "cc" | "bcc">,
  type: TDraftRecipient["type"],
) {
  return uniq([
    ...draft.to.filter((r) => r.type === type).map((r) => r.value),
    ...draft.cc.filter((r) => r.type === type).map((r) => r.value),
    ...draft.bcc.filter((r) => r.type === type).map((r) => r.value),
  ]);
}

export function mapRecipientOptionToDraftRecipient(
  option: IEmailRecipientOption,
  type: IDraft["type"],
): IDraftEmailRecipient;
export function mapRecipientOptionToDraftRecipient(
  option: IUserRecipientOption,
  type: IDraft["type"],
): IDraftUserRecipient;
export function mapRecipientOptionToDraftRecipient(
  option: IChannelRecipientOption,
  type: IDraft["type"],
): IDraftChannelRecipient;
export function mapRecipientOptionToDraftRecipient(
  option: IRecipientOption,
  type: IDraft["type"],
): TDraftRecipient;
export function mapRecipientOptionToDraftRecipient(
  option: IRecipientOption,
  type: IDraft["type"],
) {
  switch (option.type) {
    case "user": {
      switch (type) {
        case "COMMS": {
          return {
            type: "userId",
            value: option.value,
          } satisfies IDraftUserRecipient;
        }
        case "EMAIL": {
          return {
            type: "emailAddress",
            value: `"${option.label}" <${option.email}>`,
          } satisfies IDraftEmailRecipient;
        }
        default: {
          throw new UnreachableCaseError(type);
        }
      }
    }
    case "channel": {
      return {
        type: "channelId",
        value: option.value,
      } satisfies IDraftChannelRecipient;
    }
    case "email": {
      if (option.label === option.value) {
        return {
          type: "emailAddress",
          value: option.value,
        } satisfies IDraftEmailRecipient;
      }

      return {
        type: "emailAddress",
        value: `"${option.label}" <${option.value}>`,
      } satisfies IDraftEmailRecipient;
    }
  }
}

/**
 * The firestore in-memory cache will optimistically update
 * immediately but the promise will only resolve when the
 * changes have been committed on the server.
 */
export const createNewDraft = withPendingUpdate(
  async (
    input: Partial<IBaseNewFirestoreDraftDocArgs> & {
      type: IBaseNewFirestoreDraftDocArgs["type"];
    },
  ) => {
    const currentUser = getAndAssertCurrentUser();

    const defaultArgs: Omit<IBaseNewFirestoreDraftDocArgs, "type"> = {
      postId: uid(),
      visibility: null,
      to: [],
      cc: [],
      bcc: [],
      subject: "",
      bodyHTML: "",
      channelMentions: [],
      userMentions: [],
      branchedFrom: null,
    };

    const draft = await baseNewFirestoreDraftDoc({ ...defaultArgs, ...input });

    try {
      await offlineAwareFirestoreCRUD(
        setDoc(
          docRef("users", currentUser.id, "unsafeDrafts", draft.id),
          draft,
        ),
      );
    } catch (e) {
      console.error(e, draft, input);
    }
  },
);

interface IUpdateNewDraftArgs extends IBaseNewFirestoreDraftDocArgs {
  scheduledToBeSentAt?: Date;
}

/**
 * The firestore in-memory cache will optimistically update
 * immediately but the promise will only resolve when the
 * changes have been committed on the server.
 */
export const updateNewDraft = withPendingUpdate(
  async (args: IUpdateNewDraftArgs) => {
    const currentUser = getAndAssertCurrentUser();

    const draft = await baseNewFirestoreDraftDoc(args);

    let promise: Promise<void>;

    switch (draft.type) {
      case "COMMS": {
        const doc = pick(
          draft,
          "type",
          "newThread",
          "bodyHTML",
          "recipientChannelIds",
          "recipientUserIds",
          "mentionedChannels",
          "mentionedUsers",
          "updatedAt",
        ) as UpdateData<ICommsDraftDoc>;

        // If the draft type changed from EMAIL to COMMS, then we need to
        // delete any EMAIL draft specific props.
        const deleteEmailDraftProps: WithFieldValue<TUniqEmailDraftProps> = {
          to: deleteField(),
          cc: deleteField(),
          ccChannelIds: deleteField(),
          bcc: deleteField(),
        };

        if (args.scheduledToBeSentAt) {
          doc.scheduledToBeSent = true;
          doc.scheduledToBeSentAt = args.scheduledToBeSentAt
            ? Timestamp.fromDate(args.scheduledToBeSentAt)
            : Timestamp.now();
        }

        promise = updateDoc(
          docRef("users", currentUser.id, "unsafeDrafts", args.postId),
          { ...deleteEmailDraftProps, ...doc },
        );

        break;
      }
      case "EMAIL": {
        const doc = pick(
          draft,
          "type",
          "newThread",
          "bodyHTML",
          "recipientChannelIds",
          "to",
          "cc",
          "ccChannelIds",
          "bcc",
          "mentionedChannels",
          "mentionedUsers",
          "updatedAt",
        ) as UpdateData<IEmailDraftDoc>;

        // If the draft type changed from COMMS to EMAIL, then we need to
        // delete any COMMS draft specific props.
        const deleteCommsDraftProps: WithFieldValue<TUniqCommsDraftProps> = {
          recipientUserIds: deleteField(),
        };

        if (args.scheduledToBeSentAt) {
          doc.scheduledToBeSent = true;
          doc.scheduledToBeSentAt = args.scheduledToBeSentAt
            ? Timestamp.fromDate(args.scheduledToBeSentAt)
            : Timestamp.now();
        }

        promise = updateDoc(
          docRef("users", currentUser.id, "unsafeDrafts", args.postId),
          { ...deleteCommsDraftProps, ...doc },
        );

        break;
      }
      default: {
        throw new UnreachableCaseError(draft);
      }
    }

    await offlineAwareFirestoreCRUD(promise);
  },
);

/**
 * Will attempt to unsend a previously sent draft. Will return `true` on success
 * or `false` on failure. This promise can be awaited even when offline. You can
 * only unsend one draft at a time.
 */
export const unsendDraft = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingUpdate(async (args: { postId: string }) => {
    const currentUser = getAndAssertCurrentUser();
    const ref = docRef("users", currentUser.id, "unsafeDrafts", args.postId);

    const updatePromise = updateDoc(ref, {
      scheduledToBeSent: false,
      scheduledToBeSentAt: null,
      updatedAt: serverTimestamp(),
    }).then(
      () => true,
      (e) => {
        console.error(e);

        toast("vanilla", {
          subject: "Unable to cancel sending.",
        });

        return false;
      },
    );

    return offlineAwareFirestoreCRUD(async (onLine) => {
      if (onLine) {
        // If the user is online, then we wait for the promise to
        // resolve and then return `true` if we successfully unsent
        // the draft and false if we fail. If the draft has already
        // been sent, then this will fail because we're deleted the
        // draft doc and converted it to a post doc.

        return updatePromise;
      } else {
        // If the user is offline, then the updatePromise won't
        // resolve. Instead, we subscribe to the draft doc and
        // wait for it to update with scheduledToBeSent === false.
        // If this doesn't happen within 2 seconds, we consider the
        // update a failure.

        return Promise.race([
          wait(2000).then(() => false),
          firstValueFrom(
            observeDraft(args.postId).pipe(
              filter((doc) => doc?.scheduledToBeSent === false),
            ),
          ).then(() => true),
        ]);
      }
    });
  }),
);

export async function buildPostDocFromDraftReplyFormValues(
  args: Parameters<typeof buildUnsafeDraftDocFromReplyFormValues>[0],
) {
  const [draft, organizationMembers] = await Promise.all([
    buildUnsafeDraftDocFromReplyFormValues(args),
    firstValueFrom(ALL_OTHER_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$),
  ]);

  if (!draft) return null;

  const now = Timestamp.now();

  const doc = {
    ...draft,
    scheduledToBeSent: true,
    scheduledToBeSentAt: now,
    createdAt: now,
    updatedAt: now,
    // No idea why typescript requires using unknown here. The error message
    // makes no sense. Seems like a typescript bug of some kind.
  } as unknown as IUnsafeDraftDoc;

  return mapDraftToPostDoc({
    thread: args.thread,
    draft: mapUnsafeDraftDocToDraft(doc),
    organizationMembers,
  });
}

/**
 * The firestore in-memory cache will optimistically update
 * immediately but the promise will only resolve when the
 * changes have been committed on the server.
 */
export const createDraftReply = withPendingUpdate(
  async (
    args: Parameters<typeof buildUnsafeDraftDocFromReplyFormValues>[0],
  ) => {
    const draft = await buildUnsafeDraftDocFromReplyFormValues(args);

    if (!draft) return;

    const currentUser = getAndAssertCurrentUser();

    const promise = setDoc(
      docRef("users", currentUser.id, "unsafeDrafts", draft.id),
      draft,
    );

    await offlineAwareFirestoreCRUD(promise);
  },
);

/**
 * The firestore in-memory cache will optimistically update
 * immediately but the promise will only resolve when the
 * changes have been committed on the server.
 */
export const updateDraftReply = withPendingUpdate(
  async (args: {
    type: "COMMS" | "EMAIL";
    postId: string;
    bodyHTML: string;
    recipients: IComposeMessageFormValue["recipients"];
    userMentions: IEditorMention[];
    channelMentions: IEditorMention[];
    scheduledToBeSentAt?: Date;
  }) => {
    const currentUser = getAndAssertCurrentUser();

    const mentionedChannels: ICommsDraftDoc["mentionedChannels"] =
      Object.fromEntries(
        args.channelMentions.map(({ id, priority }) => [id, { priority }]),
      );

    const mentionedUsers: ICommsDraftDoc["mentionedUsers"] = Object.fromEntries(
      args.userMentions.map(({ id, priority }) => [id, { priority }]),
    );

    let promise: Promise<void>;

    switch (args.type) {
      case "COMMS": {
        const doc = {
          bodyHTML: args.bodyHTML,
          recipientChannelIds: args.recipients.to
            .filter((r) => r.type === "channel")
            .map((r) => r.value),
          recipientUserIds: args.recipients.to
            .filter((r) => r.type === "user")
            .map((r) => r.value),
          mentionedChannels,
          mentionedUsers,
          updatedAt: serverTimestamp(),
        } satisfies Partial<WithServerTimestamp<ICommsDraftDoc>>;

        if (args.scheduledToBeSentAt) {
          promise = updateDoc(
            docRef("users", currentUser.id, "unsafeDrafts", args.postId),
            {
              ...doc,
              scheduledToBeSent: true,
              scheduledToBeSentAt: args.scheduledToBeSentAt
                ? Timestamp.fromDate(args.scheduledToBeSentAt)
                : Timestamp.now(),
            } as unknown as UpdateData<
              typeof doc & {
                scheduledToBeSent: true;
                scheduledToBeSentAt: Timestamp;
              }
            >,
          );
        } else {
          promise = updateDoc(
            docRef("users", currentUser.id, "unsafeDrafts", args.postId),
            doc as UpdateData<typeof doc>,
          );
        }

        break;
      }
      case "EMAIL": {
        const doc = {
          bodyHTML: args.bodyHTML,
          recipientChannelIds: args.recipients.to
            .filter((r) => r.type === "channel")
            .map((r) => r.value),
          to: args.recipients.to
            .filter((r) => r.type === "email")
            .map((r) => ({ label: r.label, address: r.value })),
          cc: args.recipients.cc
            .filter((r) => r.type === "email")
            .map((r) => ({ label: r.label, address: r.value })),
          ccChannelIds: args.recipients.cc
            .filter((r) => r.type === "channel")
            .map((r) => r.value),
          bcc: args.recipients.bcc.map((r) => ({
            label: r.label,
            address: r.value,
          })),
          mentionedChannels,
          mentionedUsers,
          updatedAt: serverTimestamp(),
        } satisfies Partial<WithServerTimestamp<IEmailDraftDoc>>;

        if (args.scheduledToBeSentAt) {
          promise = updateDoc(
            docRef("users", currentUser.id, "unsafeDrafts", args.postId),
            {
              ...doc,
              scheduledToBeSent: true,
              scheduledToBeSentAt: args.scheduledToBeSentAt
                ? Timestamp.fromDate(args.scheduledToBeSentAt)
                : Timestamp.now(),
            } as unknown as UpdateData<
              typeof doc & {
                scheduledToBeSent: true;
                scheduledToBeSentAt: Timestamp;
              }
            >,
          );
        } else {
          promise = updateDoc(
            docRef("users", currentUser.id, "unsafeDrafts", args.postId),
            doc as UpdateData<typeof doc>,
          );
        }

        break;
      }
      default: {
        throw new UnreachableCaseError(args.type);
      }
    }

    await offlineAwareFirestoreCRUD(promise);
  },
);

/**
 * The firestore in-memory cache will optimistically update
 * immediately but the promise will only resolve when the
 * changes have been committed on the server.
 */
export const deleteDraft = withPendingUpdate(
  async (args: { postId: string }) => {
    console.debug("deleteDraft", args.postId);

    const currentUser = getAndAssertCurrentUser();

    const promise = deleteDoc(
      docRef("users", currentUser.id, "unsafeDrafts", args.postId),
    );

    await offlineAwareFirestoreCRUD(promise);
  },
);

export type ICommsPostDocFromDraft = WithLocalData<
  ICommsPostDoc,
  "IPostDoc",
  { fromUnsafeDraft: IDraft }
>;

export type IEmailPostDocFromDraft = WithLocalData<
  IEmailPostDoc,
  "IPostDoc",
  { fromUnsafeDraft: IDraft }
>;

export type IPostDocFromDraft = ICommsPostDocFromDraft | IEmailPostDocFromDraft;

function mapDraftToPostDoc(args: {
  thread?: Pick<
    IThreadDoc,
    "subject" | "participatingUsers" | "permittedChannelIds" | "lastPost"
  >;
  draft: IDraft;
  organizationMembers: IAcceptedOrganizationMemberDoc[];
}): IPostDocFromDraft | null {
  const { draft, organizationMembers, thread } = args;

  if (!draft.scheduledToBeSentAt) {
    throw new Error(
      `Oops! You can only map a draft with a non-null scheduledToBeSentAt value to a post`,
    );
  }

  const currentUser = getAndAssertCurrentUser();

  let recipientChannelIds: IPostDoc["recipientChannelIds"];
  let recipientUserIds: IPostDoc["recipientUserIds"];
  let recipientUsers: IPostDoc["recipientUsers"];
  let subject: IPostDoc["subject"];

  if (thread) {
    subject = thread.subject;

    recipientChannelIds = uniq([
      ...thread.permittedChannelIds,
      ...draft.to.filter((v) => v.type === "channelId").map((v) => v.value),
      ...draft.cc.filter((v) => v.type === "channelId").map((v) => v.value),
      ...Object.keys(draft.mentionedChannels),
    ]);

    recipientUserIds = uniq([
      ...draft.to.filter((v) => v.type === "userId").map((v) => v.value),
      ...draft.cc.filter((v) => v.type === "userId").map((v) => v.value),
      ...Object.keys(draft.mentionedUsers),
      ...Object.keys(thread.participatingUsers),
    ]);

    recipientUsers = {
      ...thread.participatingUsers,
      ...Object.fromEntries(
        recipientUserIds
          .map((userId) => {
            const member = organizationMembers.find(
              (member) => member.id === userId,
            );

            if (!member) return null;

            const doc: IPostDoc["recipientUsers"][string] = {
              name: member.user.name,
              email: member.user.email,
              photoURL: member.user.photoURL,
            };

            return [userId, doc];
          })
          .filter(isNonNullable),
      ),
    };
  } else if (draft.newThread) {
    subject = draft.newThread.subject;

    recipientChannelIds = uniq([
      ...draft.to.filter((v) => v.type === "channelId").map((v) => v.value),
      ...draft.cc.filter((v) => v.type === "channelId").map((v) => v.value),
      ...Object.keys(draft.mentionedChannels),
    ]);

    recipientUserIds = uniq([
      ...draft.to.filter((v) => v.type === "userId").map((v) => v.value),
      ...draft.cc.filter((v) => v.type === "userId").map((v) => v.value),
      ...Object.keys(draft.mentionedUsers),
    ]);

    recipientUsers = {
      ...Object.fromEntries(
        recipientUserIds
          .map((userId) => {
            const member = organizationMembers.find(
              (member) => member.id === userId,
            );

            if (!member) return null;

            const doc: IPostDoc["recipientUsers"][string] = {
              name: member.user.name,
              email: member.user.email,
              photoURL: member.user.photoURL,
            };

            return [userId, doc];
          })
          .filter(isNonNullable),
      ),
    };
  } else {
    console.error(`
      mapUnsafeDraftToPost() was called but draft.newThread is null
      and "thread" was not provided.
    `);

    return null;
  }

  switch (draft.type) {
    case "COMMS": {
      const noRecipients =
        recipientUserIds.length === 0 && recipientChannelIds.length === 0;

      if (noRecipients) {
        // This might happen if the user sends a post but then loses permission
        // to send to any of the recipients before the post is actually
        // sent by the backend.
        console.debug(
          "draft recipientUserIds and recipientChannelIds length === 0",
        );

        return null;
      }

      return {
        __docType: "IPostDoc",
        __local: {
          fromUnsafeDraft: draft,
        },
        type: "COMMS",
        id: draft.id,
        threadId: draft.threadId,
        branchedThreadIds: [],
        isFirstPostInThread: draft.isFirstPostInThread,
        recipientChannelIds,
        recipientUserIds,
        recipientUsers,
        mentionedChannels: draft.mentionedChannels,
        mentionedUsers: draft.mentionedUsers,
        creatorId: currentUser.id,
        creatorName: currentUser.name,
        creatorEmail: currentUser.email,
        creatorPhotoURL: currentUser.photoURL,
        subject,
        bodyHTML: draft.bodyHTML,
        // TODO: we should use innerText and a div element to
        // convert the HTML to text but it will require some
        // work since, if elements don't have spaces between
        // them (e.g. `<h1>Title</h1><p>Text</p>`) then
        // innerText wont render spaces between them. After
        // spending close to an hour trying to figure out how
        // to write a regexp to add spaces after each closing
        // tag, I gave up and decided to just use the htmlToText
        // server package (which adds 100+ kb gzipped and is
        // huge).
        bodyText: htmlToText(draft.bodyHTML),
        sentAt: draft.scheduledToBeSentAt,
        scheduledToBeSentAt: draft.scheduledToBeSentAt,
        wasEdited: false,
        lastEditedAt: null,
        createdAt: draft.createdAt,
        updatedAt: draft.updatedAt,
      };
    }
    case "EMAIL": {
      const lastPost = thread?.lastPost as IEmailPostDoc | undefined;

      const to: EmailAddress[] = draft.to
        .filter((r) => r.type === "emailAddress")
        .map((r) => parseStringToEmailAddress(r.value))
        .filter(isNonNullable);

      const cc: EmailAddress[] = draft.cc
        .filter((r) => r.type === "emailAddress")
        .map((r) => parseStringToEmailAddress(r.value))
        .filter(isNonNullable);

      if (lastPost) {
        const r = getEmailReplyRecipientsFromLastPost(
          lastPost,
          currentUser.lowercaseEmail,
        );

        to.unshift(...r.to);
        cc.unshift(...r.cc);
      }

      return {
        __docType: "IPostDoc",
        __local: {
          fromUnsafeDraft: draft,
        },
        type: "EMAIL",
        id: draft.id,
        threadId: draft.threadId,
        branchedThreadIds: [],
        isFirstPostInThread: draft.isFirstPostInThread,
        recipientChannelIds,
        recipientUserIds,
        recipientUsers,
        mentionedChannels: draft.mentionedChannels,
        mentionedUsers: draft.mentionedUsers,
        creatorId: currentUser.id,
        creatorName: currentUser.name,
        creatorEmail: currentUser.email,
        creatorPhotoURL: currentUser.photoURL,
        subject,
        bodyHTML: draft.bodyHTML,
        // TODO: we should use innerText and a div element to
        // convert the HTML to text but it will require some
        // work since, if elements don't have spaces between
        // them (e.g. `<h1>Title</h1><p>Text</p>`) then
        // innerText wont render spaces between them. After
        // spending close to an hour trying to figure out how
        // to write a regexp to add spaces after each closing
        // tag, I gave up and decided to just use the htmlToText
        // server package (which adds 100+ kb gzipped and is
        // huge).
        bodyText: htmlToText(draft.bodyHTML),
        sentAt: draft.scheduledToBeSentAt,
        scheduledToBeSentAt: draft.scheduledToBeSentAt,
        wasEdited: false,
        lastEditedAt: null,
        createdAt: draft.createdAt,
        updatedAt: draft.updatedAt,

        emailMessageId: "",
        emailReferences: [],
        sender: {
          label: currentUser.name,
          address: currentUser.email,
        },
        from: [
          {
            label: currentUser.name,
            address: currentUser.email,
          },
        ],
        to,
        cc,
      };
    }
    default: {
      throw new UnreachableCaseError(draft.type);
    }
  }
}

const SENT_DRAFTS$ = ASSERT_CURRENT_USER_ID$.pipe(
  switchMap((currentUserId) => {
    return collectionData(
      query(
        collectionRef("users", currentUserId, "unsafeDrafts"),
        where("isInvalid", "==", false),
        where("scheduledToBeSent", "==", true),
        orderBy("scheduledToBeSentAt", "asc"),
      ),
    );
  }),
  catchNoCurrentUserError(() => []),
  map((drafts) =>
    drafts
      .map((doc) => {
        const draft = mapToValidSentDraftOrNull(doc);
        if (!draft) return;
        return mapUnsafeDraftDocToDraft(draft) as SetNonNullable<
          IDraft,
          "scheduledToBeSent" | "scheduledToBeSentAt"
        >;
      })
      .filter(isNonNullable),
  ),
  switchMap((drafts) => {
    return currentTime(ONE_MINUTE * 5).pipe(
      map(() => {
        const target = dayjs().add(5, "minutes");

        return drafts.filter((draft) =>
          target.isAfter(draft.scheduledToBeSentAt.toDate()),
        );
      }),
      // This is OK because this observable will be recreated (from scratch)
      // whenever `drafts` changes. As such, `distinctUntilChanged()` is only
      // being applied to our` currentTime() filter operation.
      distinctUntilChanged((a, b) =>
        isEqual(
          a.map((aa) => aa.id),
          b.map((bb) => bb.id),
        ),
      ),
    );
  }),
);

export const SENT_DRAFTS_AS_POSTS$ = SENT_DRAFTS$.pipe(
  switchMap((sentDrafts) => {
    if (sentDrafts.length === 0) return of([]);

    return combineLatest(
      sentDrafts.map((draft) => {
        return combineLatest([
          _observeThread(draft.threadId),
          ALL_OTHER_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$,
        ]).pipe(
          map(([thread, organizationMembers]) => {
            if (draft.isFirstPostInThread) {
              return mapDraftToPostDoc({
                draft,
                organizationMembers,
              });
            }

            if (!thread) return null;

            return mapDraftToPostDoc({
              thread,
              draft,
              organizationMembers: organizationMembers,
            });
          }),
        );
      }),
    );
  }),
  map((value) => value.filter(isNonNullable)),
  catchNoCurrentUserError(() => []),
  shareReplay(1),
);

export type ICommsThreadDocFromDraft = WithLocalData<
  ICommsThreadDoc,
  "IThreadDoc",
  { fromUnsafeDraft: IDraft }
>;

export type IEmailThreadDocFromDraft = WithLocalData<
  IEmailThreadDoc,
  "IThreadDoc",
  { fromUnsafeDraft: IDraft }
>;

export type IThreadDocFromDraft =
  | ICommsThreadDocFromDraft
  | IEmailThreadDocFromDraft;

export const SENT_DRAFTS_AS_THREADS$: Observable<IThreadDocFromDraft[]> =
  combineLatest([
    SENT_DRAFTS$,
    ALL_OTHER_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$,
    ASSERT_CURRENT_USER$.pipe(
      map((user) => pick(user, "id", "name", "email", "photoURL")),
      distinctUntilChanged(isEqual),
    ),
  ]).pipe(
    map(([unsafeDrafts, organizationMembers, currentUser]) => {
      const draftsGroupedByThreadId = Object.values(
        groupBy(unsafeDrafts, (draft) => draft.threadId),
      )
        // we're only interested in threads that are entirely made up of drafts
        .filter((drafts) => drafts.some((draft) => draft.isFirstPostInThread))
        .map((drafts) =>
          drafts.sort((a, b) => {
            if (a.isFirstPostInThread) return -1;
            if (b.isFirstPostInThread) return 1;

            return timestampComparer(
              a.scheduledToBeSentAt,
              b.scheduledToBeSentAt,
            );
          }),
        );

      return draftsGroupedByThreadId
        .map((drafts) => {
          const [firstDraft, ...otherDrafts] = drafts;

          const firstPost = mapDraftToPostDoc({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            draft: firstDraft!,
            organizationMembers: organizationMembers,
          });

          if (!firstPost) return null;

          const otherPosts: IPostDocFromDraft[] = [];

          for (let i = 0; i < otherDrafts.length; i++) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const draft = otherDrafts[i]!;

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const lastPost = i === 0 ? firstPost : otherPosts[i - 1]!;

            const post = mapDraftToPostDoc({
              thread: {
                subject: lastPost.subject,
                participatingUsers: lastPost.recipientUsers,
                permittedChannelIds: lastPost.recipientChannelIds,
                lastPost,
              },
              draft,
              organizationMembers: organizationMembers,
            });

            if (!post) {
              console.warn("Unable to map draft to post", draft);
              return null;
            }

            otherPosts.push(post);
          }

          const lastPost = otherPosts.at(-1) || firstPost;

          const participatingUsers: IThreadDoc["participatingUsers"] = [
            firstPost,
            ...otherPosts,
          ].reduce(
            (prev, curr) => ({
              ...prev,
              ...curr.recipientUsers,
            }),
            {
              [currentUser.id]: {
                name: currentUser.name,
                email: currentUser.email,
                photoURL: currentUser.photoURL,
              },
            },
          );

          const permittedChannelIds = uniq(
            [firstPost, ...otherPosts].flatMap((p) => p.recipientChannelIds),
          );

          switch (firstPost.type) {
            case "COMMS": {
              return {
                __docType: "IThreadDoc",
                __local: {
                  fromUnsafeDraft: firstPost.__local.fromUnsafeDraft,
                },
                id: firstPost.threadId,
                type: firstPost.type,
                tagIds: [],
                permittedChannelIds,
                permittedUserIds: Object.keys(participatingUsers),
                userPermissions: participatingUsers,
                participatingUserIds: Object.keys(participatingUsers),
                participatingUsers,
                isBranch: !!firstDraft?.branchedFrom,
                branchedFrom: firstDraft?.branchedFrom || null,
                subject: firstPost.subject,
                visibility:
                  // The `mapToValidSentDraftOrNull()` function, used above, ensures
                  // that visibility is non-null for drafts representing threads.
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  firstPost.__local.fromUnsafeDraft.newThread!.visibility!,
                firstPost,
                lastPost: lastPost as ICommsPostDocFromDraft,
                createdAt: firstPost.__local.fromUnsafeDraft.createdAt,
                updatedAt: lastPost.__local.fromUnsafeDraft.updatedAt,
              } as ICommsThreadDocFromDraft;
            }
            case "EMAIL": {
              const permittedChannelIds = uniq(
                [firstPost, ...otherPosts].flatMap(
                  (p) => p.recipientChannelIds,
                ),
              );

              return {
                __docType: "IThreadDoc",
                __local: {
                  fromUnsafeDraft: firstPost.__local.fromUnsafeDraft,
                },
                id: firstPost.threadId,
                type: firstPost.type,
                permittedChannelIds,
                permittedUserIds: Object.keys(participatingUsers),
                userPermissions: participatingUsers,
                participatingUserIds: Object.keys(participatingUsers),
                participatingUsers,
                isBranch: !!firstDraft?.branchedFrom,
                branchedFrom: firstDraft?.branchedFrom || null,
                subject: firstPost.subject,
                visibility:
                  // The `mapToValidSentDraftOrNull()` function, used above, ensures
                  // that visibility is non-null for drafts representing threads.
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  firstPost.__local.fromUnsafeDraft.newThread!.visibility!,
                firstPost,
                lastPost: lastPost as IEmailPostDocFromDraft,
                tagIds: [],
                createdAt: firstPost.__local.fromUnsafeDraft.createdAt,
                updatedAt: lastPost.__local.fromUnsafeDraft.updatedAt,
              } satisfies IEmailThreadDocFromDraft;
            }
            default: {
              throw new UnreachableCaseError(firstPost);
            }
          }
        })
        .filter(isNonNullable);
    }),
    catchNoCurrentUserError(() => []),
    distinctUntilChanged(isEqual),
    shareReplay(1),
  );

export function observeDraft(postId: string) {
  return ASSERT_CURRENT_USER_ID$.pipe(
    switchMap((userId) =>
      docData(docRef("users", userId, "unsafeDrafts", postId)),
    ),
    map((value) => (!value ? null : mapToValidWipDraftOrNull(value))),
    map((value) => (!value ? null : mapUnsafeDraftDocToDraft(value))),
    catchNoCurrentUserError(() => null),
  );
}

export function useDraft(postId?: string): IDraft | null | undefined {
  return useObservable(
    () => {
      if (!postId) return of(null);

      return observeDraft(postId);
    },
    {
      deps: [postId],
    },
  );
}

export function observeDraftForThread(threadId: string) {
  return ASSERT_CURRENT_USER_ID$.pipe(
    switchMap((userId) =>
      collectionData(
        query(
          collectionRef("users", userId, "unsafeDrafts"),
          where("isInvalid", "==", false),
          where("scheduledToBeSent", "==", false),
          where("threadId", "==", threadId),
          limit(1),
        ),
      ),
    ),
    map((value) => (!value?.[0] ? null : mapToValidWipDraftOrNull(value[0]))),
    map((value) => (!value ? null : mapUnsafeDraftDocToDraft(value))),
    catchNoCurrentUserError(() => null),
    distinctUntilChanged(isEqual),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
}

export type IDraftWithThreadData = IDraft<{
  bodyText: string;
  fromThread: {
    subject: IThreadDoc["subject"];
    visibility: IThreadDoc["visibility"];
    recipientChannelIds: IThreadDoc["permittedChannelIds"];
    knownRecipientChannels: IChannelDoc[];
    recipientUserIds: IThreadDoc["participatingUserIds"];
    recipientUsers: Array<
      IThreadDoc["participatingUsers"][string] & { id: string }
    >;
  };
}>;

const ALL_DRAFTS$ = ASSERT_CURRENT_USER_ID$.pipe(
  switchMap((userId) =>
    collectionData(
      query(
        collectionRef("users", userId, "unsafeDrafts"),
        where("isInvalid", "==", false),
        where("scheduledToBeSent", "==", false),
      ),
    ),
  ),
).pipe(
  switchMap((rawDrafts) => {
    const drafts = rawDrafts
      .map(mapToValidWipDraftOrNull)
      .filter(isNonNullable)
      .map(mapUnsafeDraftDocToDraft);

    if (drafts.length === 0) return of([]);

    return combineLatest(
      drafts
        .map((draft) => {
          if (draft.newThread && !draft.scheduledToBeSent) {
            return of([draft, null] as const);
          }

          // Since the draft doesn't necessarily represent the recipient info,
          // we need to get the thread.
          return _observeThread(draft.threadId).pipe(
            map((threadDoc) => [draft, threadDoc] as const),
          );
        })
        .map((obs$) =>
          obs$.pipe(
            switchMap(([draft, threadDoc]) => {
              return combineLatest([
                USER_CHANNELS$,
                ALL_OTHER_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$,
              ]).pipe(
                map(([channels, organizationMembers]) => {
                  if (draft.newThread && !draft.scheduledToBeSent) {
                    // If the draft is for a new thread AND hasn't been
                    // sent yet, then the draft represents the thread so we
                    // can just use the recipient info in the draft.

                    const recipientChannelIds = getDraftRecipientIdsOfType(
                      draft,
                      "channelId",
                    );

                    const recipientUserIds = getDraftRecipientIdsOfType(
                      draft,
                      "userId",
                    );

                    const localDraft: IDraftWithThreadData = {
                      ...draft,
                      __docType: "IUnsafeDraftDoc",
                      __local: {
                        bodyText: htmlToText(draft.bodyHTML),
                        fromThread: {
                          subject: draft.newThread.subject,
                          visibility: draft.newThread.visibility || "shared",
                          recipientChannelIds,
                          knownRecipientChannels: getRecipientChannels(
                            recipientChannelIds,
                            channels,
                          ),
                          recipientUserIds: recipientUserIds,
                          recipientUsers: getRecipientUsers(
                            recipientUserIds,
                            organizationMembers,
                          ),
                        },
                      },
                    };

                    return localDraft;
                  }

                  if (!threadDoc) return null;

                  const localDraft: IDraftWithThreadData = {
                    ...draft,
                    __docType: "IUnsafeDraftDoc",
                    __local: {
                      bodyText: htmlToText(draft.bodyHTML),
                      fromThread: {
                        subject: threadDoc.subject,
                        visibility: threadDoc.visibility,
                        recipientChannelIds: threadDoc.permittedChannelIds,
                        knownRecipientChannels: getRecipientChannels(
                          threadDoc.permittedChannelIds,
                          channels,
                        ),
                        recipientUserIds: threadDoc.participatingUserIds,
                        recipientUsers: Object.entries(
                          threadDoc.participatingUsers,
                        ).map(([k, v]) => ({ id: k, ...v })),
                      },
                    },
                  };

                  return localDraft;
                }),
              );
            }),
          ),
        ),
    );
  }),
  map((drafts) =>
    drafts
      .filter(isNonNullable)
      .sort((a, b) => timestampComparer(b.createdAt, a.createdAt)),
  ),
  catchNoCurrentUserError(() => []),
  distinctUntilChanged(isEqual),
  cacheReplayForTime({ timeMs: 10_000 }),
);

export function observeDrafts() {
  return ALL_DRAFTS$;
}

export function useDrafts(): IDraftWithThreadData[] | undefined {
  return useObservable(() => observeDrafts());
}

export function useDraftsBranchedFromPost(postId?: string) {
  return useObservable(
    () => {
      if (!postId) return of([]);

      return ALL_DRAFTS$.pipe(
        map((drafts) =>
          drafts
            .filter((draft) => draft.branchedFrom?.postId === postId)
            .sort((a, b) => {
              return (
                timestampComparer(a.createdAt, b.createdAt) ||
                stringComparer(a.id, b.id)
              );
            }),
        ),
      );
    },
    {
      deps: [postId],
    },
  );
}

/* -----------------------------------------------------------------------------------------------*/

function getRecipientChannels(
  recipientChannelIds: string[],
  channels: IChannelDoc[],
) {
  return recipientChannelIds
    .map((id) => channels.find((channel) => channel.id === id))
    .filter(isNonNullable);
}

/* -----------------------------------------------------------------------------------------------*/

function getRecipientUsers(
  recipientUserIds: string[],
  organizationMembers: IAcceptedOrganizationMemberDoc[],
) {
  return recipientUserIds
    .map((id) => {
      const member = organizationMembers.find((member) => member.id === id);

      if (!member) return;

      return {
        id: member.id,
        ...member.user,
      };
    })
    .filter(isNonNullable);
}

/* -----------------------------------------------------------------------------------------------*/

type ILocalDraftData =
  | {
      type: IDraft["type"];
      visibility: ThreadVisibility | null;
      recipients?: IRecipientOption[];
      subject?: string;
      content: string;
    }
  | {
      sent: true;
      post: IPostDocFromDraft;
    };

/**
 * This function synchronously syncs the draft data to any
 * other tabs on this device. We can't simply rely on using the
 * database to synchronize data between tabs because it's too
 * slow. If someone has the same draft open in two different
 * tabs, and the user switch focus between the two different
 * tabs, we don't want stale data in one tab to overwrite
 * data in the other tab.
 */
export function useSyncDraftBetweenTabs(
  control:
    | IFormGroup<{
        postId: IFormControl<string>;
        body: IPostEditorControl["controls"]["body"];
      }>
    | IFormGroup<{
        postId: IFormControl<string>;
        type: IFormControl<IDraft["type"]>;
        visibility: IFormControl<ThreadVisibility | null>;
        recipients: IFormControl<IRecipientOption[]>;
        subject: IFormControl<string>;
        body: IPostEditorControl["controls"]["body"];
      }>,
  editorRef: RefObject<IRichTextEditorRef>,
  onClose: (post?: IPostDocFromDraft) => void,
): void;
export function useSyncDraftBetweenTabs(
  control: IFormGroup<{
    postId: IFormControl<string>;
    type?: IFormControl<IDraft["type"]>;
    visibility?: IFormControl<ThreadVisibility | null>;
    recipients?: IFormControl<IRecipientOption[]>;
    subject?: IFormControl<string>;
    body: IPostEditorControl["controls"]["body"];
  }>,
  editorRef: RefObject<IRichTextEditorRef>,
  onClose: (post?: IPostDocFromDraft) => void,
) {
  useEffect(() => {
    // On mount, we check to see if there is local draft data for this
    // draft and update this form with that data if so. After mounting,
    // an effect below will be responsible for handling updates to the
    // draft data in sessionStorage.

    const localDraftData = sessionStorageService.getItem<ILocalDraftData>(
      getDraftDataStorageKey(control.rawValue.postId),
    );

    if (!localDraftData) return;

    if ("sent" in localDraftData) {
      onClose(localDraftData.post);
      return;
    } else if (!localDraftData.content) {
      return;
    }

    editorRef.current?.editor?.commands.setContent(
      localDraftData.content,
      true,
      {
        preserveWhitespace: "full",
      },
    );

    if (localDraftData.recipients) {
      control.controls.recipients?.setValue(localDraftData.recipients);
    }

    if (localDraftData.subject) {
      control.controls.subject?.setValue(localDraftData.subject);
    }

    if (localDraftData.visibility) {
      control.controls.visibility?.setValue(localDraftData.visibility);
    }

    if (localDraftData.type) {
      control.controls.type?.setValue(localDraftData.type);
    }

    // onMount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isWindowFocused = useIsWindowFocused();

  useEffect(() => {
    if (!isWindowFocused) return;

    const sub = combineLatest([
      observable(() => control.rawValue.recipients),
      observable(() => control.rawValue.subject),
      observable(() => control.rawValue.visibility),
      observable(() => control.rawValue.type),
      observable(() => control.rawValue.body.content),
      observable(() => getDraftDataStorageKey(control.rawValue.postId)),
    ]).subscribe(
      ([recipients, subject, visibility, type, content, sessionStorageKey]) => {
        const obj = { recipients, subject, visibility, type, content };

        if (!obj.recipients) delete obj.recipients;
        if (!obj.subject) delete obj.subject;
        if (!obj.type) delete obj.type;
        if (obj.visibility === undefined) delete obj.visibility;

        sessionStorageService.setItem<ILocalDraftData>(
          sessionStorageKey,
          obj as ILocalDraftData,
        );
      },
    );

    return () => sub.unsubscribe();
    // eslint incorrectly thinks that "props" is a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control, isWindowFocused]);

  useEffect(() => {
    if (isWindowFocused) return;

    const sub = observable(() =>
      getDraftDataStorageKey(control.rawValue.postId),
    )
      .pipe(
        switchMap((sessionStorageKey) =>
          sessionStorageService.getItem$<ILocalDraftData>(sessionStorageKey),
        ),
      )
      .subscribe((value) => {
        if (!value) return;
        else if ("sent" in value) {
          onClose(value.post);
          return;
        }

        if (value.recipients) {
          control.controls.recipients?.setValue(value.recipients);
        }

        if (value.subject) {
          control.controls.subject?.setValue(value.subject);
        }

        if (value.visibility) {
          control.controls.visibility?.setValue(value.visibility);
        }

        if (value.type) {
          control.controls.type?.setValue(value.type);
        }

        const editor = editorRef.current?.editor;

        if (!editor) return;
        if (isEqual(editor.getHTML(), value.content)) return;

        editor.commands.setContent(value.content, true, {
          preserveWhitespace: "full",
        });
      });

    return () => sub.unsubscribe();
    // eslint incorrectly thinks that "props" is a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose, control, isWindowFocused]);

  // If the draft is deleted, close the editor.
  useEffect(() => {
    const sub = observable(() => control.rawValue.postId)
      .pipe(switchMap((postId) => observeDraft(postId)))
      .subscribe((draft) => {
        if (draft) return;
        onClose();
      });

    return () => sub.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);
}

export function getDraftDataStorageKey(postId: string) {
  return `DRAFT:${postId}`;
}

/* -----------------------------------------------------------------------------------------------*/

const _observeThreadCache = new Map<string, Observable<IThreadDoc | null>>();

function _observeThread(threadId: string) {
  let obs = _observeThreadCache.get(threadId);

  if (obs) return obs;

  obs = SENT_DRAFTS_AS_THREADS$.pipe(
    map((threads) => threads.find((thread) => thread.id === threadId)),
    switchMap((thread) =>
      thread ? of(thread) : docData(docRef("threads", threadId)),
    ),
    cacheReplayForTime({
      timeMs: 100,
      onInit() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        _observeThreadCache.set(threadId, obs!);
      },
      onCleanup() {
        _observeThreadCache.delete(threadId);
      },
    }),
  );

  return obs;
}

/* -----------------------------------------------------------------------------------------------*/

async function buildUnsafeDraftDocFromReplyFormValues(args: {
  // Previously, the implementation just called for passing a
  // `threadId` and then we queried for the related thread or
  // thread draft. Unfortunately, that querying process added
  // something like 500-1000ms to the response time which was
  // perceptibly laggy in the UI. By passing the entire thread
  // document we eliminate enough of the lag to make it feel
  // snappy.
  thread: IThreadDoc | IThreadDocFromDraft;
  postId: string;
  bodyHTML: string;
  recipients: IComposeMessageFormValue["recipients"];
  userMentions: IEditorMention[];
  channelMentions: IEditorMention[];
}): Promise<
  Omit<IUnsafeDraftDoc, "createdAt" | "updatedAt"> & {
    createdAt: FieldValue;
    updatedAt: FieldValue;
  }
> {
  const [organizationMembers, channels] = await Promise.all([
    firstValueFrom(ALL_OTHER_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$),
    firstValueFrom(USER_CHANNELS$),
  ]);

  const mentionedChannels = buildPostChannelMentions({
    channelMentions: args.channelMentions,
    userChannels: channels,
  });

  const mentionedUsers = buildPostUserMentions({
    userMentions: args.userMentions,
    channelGroupMembers: organizationMembers,
  });

  switch (args.thread.type) {
    case "COMMS": {
      return validateWipDraft({
        type: "COMMS",
        isInvalid: false,
        id: args.postId,
        threadId: args.thread.id,
        isFirstPostInThread: false,
        newThread: null,
        branchedFrom: null,
        recipientChannelIds: args.recipients.to
          .filter((r) => r.type === "channel")
          .map((r) => r.value),
        recipientUserIds: args.recipients.to
          .filter((r) => r.type === "user")
          .map((r) => r.value),
        mentionedChannels,
        mentionedUsers,
        bodyHTML: args.bodyHTML,
        scheduledToBeSent: false,
        scheduledToBeSentAt: null,
        sent: false,
        sentAt: null,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      } satisfies WithServerTimestamp<ICommsDraftDoc>) as Omit<
        ICommsDraftDoc,
        "createdAt" | "updatedAt"
      > & {
        createdAt: FieldValue;
        updatedAt: FieldValue;
      };
    }
    case "EMAIL": {
      return validateWipDraft({
        type: "EMAIL",
        isInvalid: false,
        id: args.postId,
        threadId: args.thread.id,
        isFirstPostInThread: false,
        newThread: null,
        branchedFrom: null,
        recipientChannelIds: args.recipients.to
          .filter((r) => r.type === "channel")
          .map((r) => r.value),
        to: args.recipients.to
          .filter((r) => r.type === "email")
          .map((r) => ({ label: r.label, address: r.value })),
        cc: args.recipients.cc
          .filter((r) => r.type === "email")
          .map((r) => ({ label: r.label, address: r.value })),
        ccChannelIds: args.recipients.cc
          .filter((r) => r.type === "channel")
          .map((r) => r.value),
        bcc: args.recipients.bcc
          .filter((r) => r.type === "email")
          .map((r) => ({ label: r.label, address: r.value })),
        mentionedChannels,
        mentionedUsers,
        bodyHTML: args.bodyHTML,
        scheduledToBeSent: false,
        scheduledToBeSentAt: null,
        sent: false,
        sentAt: null,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      } satisfies WithServerTimestamp<IEmailDraftDoc>) as Omit<
        IEmailDraftDoc,
        "createdAt" | "updatedAt"
      > & {
        createdAt: FieldValue;
        updatedAt: FieldValue;
      };
    }
    case "EMAIL_SECRET": {
      throw new Error(
        "buildUnsafeDraftDocFromReplyFormValues() EMAIL_SECRET not implemented yet",
      );
    }
    default: {
      throw new UnreachableCaseError(args.thread);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

function buildPostChannelMentions(args: {
  channelMentions: IEditorMention[];
  userChannels: IChannelDoc[];
}): IPostDoc["mentionedChannels"] {
  return Object.fromEntries(
    args.channelMentions
      .filter(({ id }) => {
        if (!args.userChannels.some((m) => m.id === id)) {
          console.error(
            stripIndent`
              Attempted to add invalid mention to draft: 
              <channel #${id}>
            `,
          );

          return false;
        }

        return true;
      })
      .map(({ id, priority }) => [id, { priority }]),
  );
}

/* -----------------------------------------------------------------------------------------------*/

function buildPostUserMentions(args: {
  userMentions: IEditorMention[];
  channelGroupMembers: IAcceptedOrganizationMemberDoc[];
}): IPostDoc["mentionedUsers"] {
  return Object.fromEntries(
    args.userMentions
      .filter(({ id }) => {
        if (!args.channelGroupMembers.some((m) => m.id === id)) {
          console.error(
            stripIndent`
              Attempted to add invalid mention to draft: 
              <user #${id}>
            `,
          );

          return false;
        }

        return true;
      })
      .map(({ id, priority }) => [id, { priority }]),
  );
}

/* -----------------------------------------------------------------------------------------------*/

export interface IBaseNewFirestoreDraftDocArgs<
  T extends IDraft["type"] = IDraft["type"],
> {
  type: T;
  postId: string;
  subject: string;
  bodyHTML: string;
  branchedFrom: IUnsafeDraftDoc["branchedFrom"];
  to: TDraftRecipient[];
  cc: TDraftRecipient[];
  bcc: IDraftEmailRecipient[];
  visibility: ThreadVisibility | null;
  userMentions: IEditorMention[];
  channelMentions: IEditorMention[];
}

async function baseNewFirestoreDraftDoc(args: IBaseNewFirestoreDraftDocArgs) {
  switch (args.type) {
    case "COMMS": {
      return baseNewCommsDraftDoc({
        ...args,
        type: args.type,
      });
    }
    case "EMAIL": {
      return baseNewEmailDraftDoc({
        ...args,
        type: args.type,
      });
    }
    default: {
      throw new UnreachableCaseError(args.type);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function baseNewCommsDraftDoc(
  args: IBaseNewFirestoreDraftDocArgs<"COMMS">,
) {
  // First we make sure that all of the recipients
  // exist and the user has access to them.

  const [organizationMembers, channels] = await Promise.all([
    firstValueFrom(ALL_OTHER_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$),
    firstValueFrom(USER_CHANNELS$),
  ]);

  const recipientUserIds = [...args.to, ...args.cc]
    .filter(({ type, value }) => {
      if (type !== "userId") return false;

      if (!organizationMembers.some((m) => m.id === value)) {
        console.error(
          stripIndent`
            Attempted to add invalid recipient to draft: 
            <user #${value}>
          `,
        );

        return false;
      }

      return true;
    })
    .map(({ value }) => value);

  const recipientChannelSnaps = await Promise.all(
    [...args.to, ...args.cc]
      .filter(({ type }) => type === "channelId")
      .map(({ value }) => getDoc(docRef("channels", value))),
  );

  const recipientChannels = recipientChannelSnaps
    .map((snap) => {
      if (!snap.exists()) {
        console.error(
          stripIndent`
            Attempted to add invalid recipient to draft: 
            <channel #${snap.id}>
          `,
        );
      }

      return snap.data();
    })
    .filter(isNonNullable);

  const mentionedChannels = buildPostChannelMentions({
    channelMentions: args.channelMentions,
    userChannels: channels,
  });

  const mentionedUsers = buildPostUserMentions({
    userMentions: args.userMentions,
    channelGroupMembers: organizationMembers,
  });

  type IWipDraft = WithServerTimestamp<
    SetNonNullable<ICommsDraftDoc, "newThread">
  >;

  return validateWipDraft({
    type: args.type,
    isInvalid: false,
    id: args.postId,
    threadId: uid(),
    isFirstPostInThread: true,
    newThread: {
      subject: args.subject,
      visibility: args.visibility,
    },
    branchedFrom: args.branchedFrom,
    recipientChannelIds: recipientChannels.map((channel) => channel.id),
    recipientUserIds,
    mentionedChannels,
    mentionedUsers,
    bodyHTML: args.bodyHTML,
    scheduledToBeSent: false,
    scheduledToBeSentAt: null,
    sent: false,
    sentAt: null,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  } satisfies IWipDraft) as IWipDraft;
}

/* -----------------------------------------------------------------------------------------------*/

async function baseNewEmailDraftDoc(
  args: IBaseNewFirestoreDraftDocArgs<"EMAIL">,
) {
  // First we make sure that all of the recipients
  // exist and the user has access to them.

  const toChannelQuery = Promise.all(
    args.to
      .filter(({ type }) => type === "channelId")
      .map(({ value }) => getDoc(docRef("channels", value))),
  );

  const ccChannelQuery = Promise.all(
    args.cc
      .filter(({ type }) => type === "channelId")
      .map(({ value }) => getDoc(docRef("channels", value))),
  );

  const [toChannelSnaps, ccChannelSnaps, organizationMembers, channels] =
    await Promise.all([
      toChannelQuery,
      ccChannelQuery,
      firstValueFrom(ALL_OTHER_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$),
      firstValueFrom(USER_CHANNELS$),
    ]);

  const recipientChannelIds = toChannelSnaps
    .map((snap) => {
      if (!snap.exists()) {
        console.error(
          stripIndent`
            Attempted to add invalid recipient to draft: 
            <channel #${snap.id}>
          `,
        );
      }

      return snap.data()?.id;
    })
    .filter(isNonNullable);

  const mentionedChannels = buildPostChannelMentions({
    channelMentions: args.channelMentions,
    userChannels: channels,
  });

  const mentionedUsers = buildPostUserMentions({
    userMentions: args.userMentions,
    channelGroupMembers: organizationMembers,
  });

  const ccChannelIds = ccChannelSnaps
    .map((snap) => {
      if (!snap.exists()) {
        console.error(
          stripIndent`
            Attempted to add invalid recipient to draft: 
            <channel #${snap.id}>
          `,
        );
      }

      return snap.data()?.id;
    })
    .filter(isNonNullable);

  type IWipDraft = WithServerTimestamp<
    SetNonNullable<IEmailDraftDoc, "newThread">
  >;

  return validateWipDraft({
    type: args.type,
    isInvalid: false,
    id: args.postId,
    threadId: uid(),
    isFirstPostInThread: true,
    newThread: {
      subject: args.subject,
      visibility: args.visibility,
    },
    branchedFrom: args.branchedFrom,
    recipientChannelIds,
    to: mapRecipientsToEmails(args.to),
    cc: mapRecipientsToEmails(args.cc),
    ccChannelIds,
    bcc: mapRecipientsToEmails(args.bcc),
    mentionedChannels,
    mentionedUsers,
    bodyHTML: args.bodyHTML,
    scheduledToBeSent: false,
    scheduledToBeSentAt: null,
    sent: false,
    sentAt: null,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  } satisfies IWipDraft) as IWipDraft;
}

function mapRecipientsToEmails(input: TDraftRecipient[]) {
  return input
    .filter((r) => r.type === "emailAddress")
    .map((r) => {
      const emailWithLabelMatch = r.value.match(emailWithLabelRegex);

      if (emailWithLabelMatch) {
        return {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          label: emailWithLabelMatch[1]!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          address: emailWithLabelMatch[2]!,
        };
      }

      return { address: r.value };
    });
}

const emailWithLabelRegex = /"(.*)" <(.*)>/;

/* -----------------------------------------------------------------------------------------------*/

function mapUnsafeDraftDocToDraft<T = {}>(
  doc: IUnsafeDraftDoc,
  __local: T = {} as T,
): IDraft<T> {
  const draftPartial = {
    __docType: "IUnsafeDraftDoc" as const,
    __local,
    type: doc.type,
    id: doc.id,
    threadId: doc.threadId,
    isInvalid: doc.isInvalid,
    meta: doc.meta,
    newThread: doc.newThread,
    branchedFrom: doc.branchedFrom,
    isFirstPostInThread: doc.isFirstPostInThread,
    scheduledToBeSent: doc.scheduledToBeSent,
    scheduledToBeSentAt: doc.scheduledToBeSentAt,
    sent: doc.sent,
    sentAt: doc.sentAt,
    bodyHTML: doc.bodyHTML,
    createdAt: doc.createdAt,
    updatedAt: doc.updatedAt,
  };

  switch (doc.type) {
    case "COMMS": {
      return {
        ...draftPartial,
        to: [
          ...doc.recipientChannelIds.map((id) => ({
            type: "channelId" as const,
            value: id,
          })),
          ...doc.recipientUserIds.map((id) => ({
            type: "userId" as const,
            value: id,
          })),
        ],
        cc: [],
        bcc: [],
        mentionedChannels: doc.mentionedChannels,
        mentionedUsers: doc.mentionedUsers,
      };
    }
    case "EMAIL": {
      return {
        ...draftPartial,
        to: [
          ...doc.recipientChannelIds.map((id) => ({
            type: "channelId" as const,
            value: id,
          })),
          ...doc.to.map((e) => ({
            type: "emailAddress" as const,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            value: parseEmailAddress(e).rawValue!,
          })),
        ],
        cc: [
          ...doc.ccChannelIds.map((id) => ({
            type: "channelId" as const,
            value: id,
          })),
          ...doc.cc.map((e) => ({
            type: "emailAddress" as const,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            value: parseEmailAddress(e).rawValue!,
          })),
        ],
        bcc: doc.bcc.map((e) => ({
          type: "emailAddress",
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          value: parseEmailAddress(e).rawValue!,
        })),
        mentionedChannels: {},
        mentionedUsers: {},
      };
    }
    default: {
      throw new UnreachableCaseError(doc);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

const WipUnsafeDraftD = buildWipUnsafeDraftD<IUnsafeDraftDoc>(
  {},
  {
    // When a new post is created on the client and a `serverTimestamp()` is set,
    // that serverTimestamp value is initially returned as `null` in documents
    // until the post is persisted on the server and the "rea" timestamp is set.
    // When this happens, we should just use `Timestamp.now()` as a placeholder.
    timestampD: d
      .nullableD(d.instanceOfD(Timestamp))
      .map((input) => input || Timestamp.now()),
  },
);

const WipUnsafeDraftCreateD = buildWipUnsafeDraftD<
  WithServerTimestamp<IUnsafeDraftDoc>
>(
  {},
  {
    // When a new post is created on the client and a `serverTimestamp()` is set,
    // that serverTimestamp value is initially returned as `null` in documents
    // until the post is persisted on the server and the "rea" timestamp is set.
    // When this happens, we should just use `Timestamp.now()` as a placeholder.
    timestampD: d
      .nullableD(TimestampOrFieldValueD)
      .map((input) => input || Timestamp.now()),
  },
);

const validateWipDraft = assert(WipUnsafeDraftCreateD);

const SentUnsafeDraftD = buildSentUnsafeDraftD<
  SetNonNullable<IUnsafeDraftDoc, "scheduledToBeSent" | "scheduledToBeSentAt">
>(
  {},
  {
    // When a new post is created on the client and a `serverTimestamp()` is set,
    // that serverTimestamp value is initially returned as `null` in documents
    // until the post is persisted on the server and the "rea" timestamp is set.
    // When this happens, we should just use `Timestamp.now()` as a placeholder.
    timestampD: d
      .nullableD(d.instanceOfD(Timestamp))
      .map((input) => input || Timestamp.now()),
  },
);

function getFnToMapToValidDecoderResultOrNull<T>(
  decoder: Decoder<T>,
  errorMsg?: string,
): (item: unknown) => T | null {
  return (item) => {
    const result = decoder.decode(item);

    if (areDecoderErrors(result)) {
      if (errorMsg) console.warn(errorMsg, result);
      return null;
    }

    return result.value;
  };
}

const mapToValidWipDraftOrNull = getFnToMapToValidDecoderResultOrNull(
  WipUnsafeDraftD,
  "Attempted to view invalid draft document.",
);

const mapToValidSentDraftOrNull = getFnToMapToValidDecoderResultOrNull(
  SentUnsafeDraftD,
  "Attempted to view invalid draft document.",
);

/* -----------------------------------------------------------------------------------------------*/
