import {
  ComponentType,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/utils/useScrollShadow";
import { ICommandArgs, useRegisterCommands } from "~/services/command.service";
import * as MainLayout from "~/page-layouts/main-layout";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  deleteInboxSection,
  getInboxSections,
  inboxSectionMerge,
  useInboxSection,
} from "~/services/inbox.service";
import {
  IInboxSubsectionDoc,
  WithServerTimestamp,
} from "@libs/firestore-models";
import { serverTimestamp } from "firebase/firestore";
import { entryCSSClasses } from "~/components/content-list";
import { RxDragHandleHorizontal } from "react-icons/rx";
import { isEqual } from "@libs/utils/isEqual";
import { useMatch, useParams } from "react-router-dom";
import { OutlineButton } from "~/components/OutlineButtons";
import {
  EditInboxSubsection,
  IEditInboxSubsectionSubmitValue,
} from "./EditInboxSubsection";
import { cx } from "@emotion/css";
import { createFormControl, useControl } from "solid-forms-react";
import uid from "@libs/utils/uid";
import { TextInput } from "~/form-components/TextInput";
import { Tooltip } from "~/components/Tooltip";
import { removeOneFromArray } from "@libs/utils/array-utils";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { toast } from "~/services/toast-service";
import { navigateService } from "~/services/navigate.service";
import { oneLine } from "common-tags";
import { ParsedToken } from "@libs/utils/searchQueryParser";

export const EditInboxSectionView: ComponentType<{}> = () => {
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);
  const params = useParams<{ inboxSectionId?: string }>();
  const inboxSectionId = params.inboxSectionId;
  const inboxSectionDoc = useInboxSection(inboxSectionId);

  const isNewPage = !!useMatch("/inbox/new");

  const [editSubsection, setEditSubsection] = useState<string | null>(
    isNewPage ? "new" : null,
  );

  const control = useControl(() => {
    return createFormControl("", {
      required: true,
      validators: (rawValue) => (rawValue.trim() ? null : { required: true }),
    });
  });

  const [orderedSubsections, setOrderedSubsections] = useState<
    WithServerTimestamp<IInboxSubsectionDoc>[]
  >(inboxSectionDoc?.subsectionDocs || []);

  // Run after inboxSectionDoc has initially loaded
  useEffect(() => {
    if (!inboxSectionDoc) return;
    setOrderedSubsections(inboxSectionDoc.subsectionDocs);
    control.setValue(inboxSectionDoc.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboxSectionDoc === undefined]);

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        {
          label: "Close dialog",
          hotkeys: ["Escape"],
          triggerHotkeysWhenInputFocused: true,
          callback() {
            navigateService(-1);
          },
        },
        {
          label: "Submit",
          hotkeys: ["$mod+Enter"],
          triggerHotkeysWhenInputFocused: true,
          callback: () => {
            if (!control.isValid || orderedSubsections.length === 0) {
              control.markTouched(true);
              return;
            }

            submit({
              id: inboxSectionDoc?.id || null,
              tagId: inboxSectionDoc?.tagId || null,
              name: control.value,
              order: inboxSectionDoc?.order || null,
              subsections: orderedSubsections,
            });
          },
        },
      ];

      if (inboxSectionDoc) {
        commands.push({
          label: "Delete inbox section",
          callback: () => deleteInboxSection(inboxSectionDoc.id),
        });
      }

      return commands;
    },
    deps: [inboxSectionDoc, orderedSubsections],
  });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  if (!isNewPage && !inboxSectionDoc) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{isNewPage ? "Add" : "Edit"} inbox section | Comms</title>
      </Helmet>

      <MainLayout.Header ref={headerRef} className="flex-col">
        <h1 className="text-3xl">{isNewPage ? "Add" : "Edit"} inbox section</h1>

        <div className="flex mt-4">
          <h4 className="text-lg mr-3">Section name:</h4>

          <TextInput
            control={control}
            name="name"
            placeholder="text"
            className="text-lg"
          />
        </div>
      </MainLayout.Header>

      <hr />

      <DragDropContext
        onDragEnd={(result) => {
          if (!result.destination) {
            return;
          }

          if (result.destination.index === result.source.index) {
            return;
          }

          const newOrderedSections = reorder(
            orderedSubsections,
            result.source.index,
            result.destination.index,
          );

          setOrderedSubsections(newOrderedSections);
        }}
      >
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {orderedSubsections.map((item, index) =>
                editSubsection === item.id ? (
                  <EditInboxSubsectionEntry
                    key={item.id}
                    index={index}
                    subsection={item}
                    subsections={orderedSubsections}
                    setEditSubsection={setEditSubsection}
                    setOrderedSubsections={setOrderedSubsections}
                  />
                ) : (
                  <InboxSubsectionEntry
                    key={item.id}
                    subsection={item}
                    relativeOrder={index}
                    canEdit={editSubsection === null}
                    onEdit={() => setEditSubsection(item.id)}
                  />
                ),
              )}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="mb-4" />

      {editSubsection === null && (
        <>
          <div className={entryCSSClasses}>
            <OutlineButton onClick={() => setEditSubsection("new")}>
              Add subsection
            </OutlineButton>
          </div>

          <div
            className={cx(
              entryCSSClasses,
              orderedSubsections.length === 0 &&
                "text-slate-9 cursor-not-allowed",
            )}
          >
            <Tooltip
              side="bottom"
              content={
                orderedSubsections.length === 0
                  ? "Must have at least one subsection"
                  : ""
              }
            >
              <OutlineButton
                onClick={() => {
                  if (!control.isValid || orderedSubsections.length === 0) {
                    control.markTouched(true);
                    return;
                  }

                  submit({
                    id: inboxSectionDoc?.id || null,
                    tagId: inboxSectionDoc?.tagId || null,
                    name: control.value,
                    order: inboxSectionDoc?.order || null,
                    subsections: orderedSubsections,
                  });
                }}
                disabled={orderedSubsections.length === 0}
              >
                Save
              </OutlineButton>
            </Tooltip>
          </div>
        </>
      )}

      {editSubsection === "new" && (
        <div className="px-4 sm-w:px-8 md-w:px-12">
          <div className="flex-1 border p-2">
            <EditInboxSubsection
              onCancel={() => setEditSubsection(null)}
              onSubmit={(value) => {
                if (hasFulltextFilter(value.search.parsedQuery)) {
                  return;
                }

                const subsectionId = uid();

                setEditSubsection(null);
                setOrderedSubsections((prev) => [
                  ...prev,
                  {
                    id: subsectionId,
                    tagId: subsectionId,
                    order: prev.length,
                    name: value.name,
                    description: value.description,
                    query: value.search.queryText,
                    parsedQuery: value.search.parsedQuery,
                    createdAt: serverTimestamp(),
                    updatedAt: serverTimestamp(),
                  },
                ]);
              }}
            />
          </div>
        </div>
      )}

      <div className="mb-20" />
    </>
  );
};

const reorder = (
  subsections: WithServerTimestamp<IInboxSubsectionDoc>[],
  startIndex: number,
  endIndex: number,
) => {
  const newList = Array.from(subsections);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const removed = newList.splice(startIndex, 1)[0]!;
  newList.splice(endIndex, 0, removed);

  return newList;
};

const EditInboxSubsectionEntry: ComponentType<{
  index: number;
  subsection: WithServerTimestamp<IInboxSubsectionDoc>;
  subsections: WithServerTimestamp<IInboxSubsectionDoc>[];
  setEditSubsection: (sectionId: string | null) => void;
  setOrderedSubsections: (
    subsections: WithServerTimestamp<IInboxSubsectionDoc>[],
  ) => void;
}> = (props) => {
  const {
    index,
    subsection,
    subsections,
    setEditSubsection,
    setOrderedSubsections,
  } = props;

  const removeSubsection = useCallback(() => {
    setEditSubsection(null);

    const newOrderedSubsections = removeOneFromArray(
      subsections,
      (el) => el.id === subsection.id,
    );

    setOrderedSubsections(newOrderedSubsections);
  }, [subsection, subsections, setEditSubsection, setOrderedSubsections]);

  const addSubsection = useCallback(
    (value: IEditInboxSubsectionSubmitValue) => {
      if (hasFulltextFilter(value.search.parsedQuery)) {
        return;
      }

      setEditSubsection(null);

      const newOrderedSubsections = subsections.slice();

      newOrderedSubsections[index] = {
        ...subsection,
        name: value.name,
        description: value.description,
        query: value.search.queryText,
        parsedQuery: value.search.parsedQuery,
      };

      setOrderedSubsections(newOrderedSubsections);
    },
    [subsection, setEditSubsection, index, subsections, setOrderedSubsections],
  );

  return (
    <div className="px-4 sm-w:px-8 md-w:px-12">
      <div className="flex-1 border p-2">
        <EditInboxSubsection
          subsection={subsection}
          onCancel={() => setEditSubsection(null)}
          onDelete={removeSubsection}
          onSubmit={addSubsection}
        />
      </div>
    </div>
  );
};

const hasFulltextFilter = (parsedQuery: ParsedToken[]) => {
  const isThereAFullTextSearchComponent = parsedQuery.some(
    (token) => token.type === "text",
  );

  if (isThereAFullTextSearchComponent) {
    alert(oneLine`
      It looks like this inbox section attempts to filter messages on
      plain text. Unfortunately, inbox sections don't currently 
      support searching all of a message's content. You can achieve similar
      functionality by using a body:"some text" and/or subject:"some text"
      filter. Please edit your inbox sections and save again.
    `);

    return true;
  }

  return false;
};

const InboxSubsectionEntry: ComponentType<{
  subsection: WithServerTimestamp<IInboxSubsectionDoc>;
  relativeOrder: number;
  canEdit: boolean;
  onEdit: () => void;
}> = memo((props) => {
  return (
    <Draggable
      draggableId={props.subsection.id}
      index={props.relativeOrder}
      isDragDisabled={!props.canEdit}
    >
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div className={entryCSSClasses}>
            <div {...provided.dragHandleProps}>
              <RxDragHandleHorizontal size={30} className="text-slate-8" />
            </div>

            <div className="font-medium">{props.subsection.name}</div>

            {props.subsection.description && (
              <div className="text-slate-10">
                {props.subsection.description}
              </div>
            )}

            <div className="flex-1" />

            {props.canEdit && (
              <OutlineButton onClick={props.onEdit}>Edit</OutlineButton>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
}, isEqual);

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(
    async (values: {
      id: string | null;
      tagId: string | null;
      name: string;
      order: number | null;
      subsections: WithServerTimestamp<IInboxSubsectionDoc>[];
    }) => {
      console.log("submitting...", values);

      const sectionId = values.id || uid();
      const inboxSections = await getInboxSections();
      const lastInboxSection = inboxSections.at(-1);

      toast("vanilla", {
        subject: "Saving inbox section...",
      });

      await inboxSectionMerge({
        inboxSection: {
          id: sectionId,
          name: values.name,
          order: values.order ?? (lastInboxSection?.order || 0) + 1,
          tagId: sectionId,
        },
        inboxSubsections: values.subsections.map((subsectionDoc, index) => ({
          ...subsectionDoc,
          order: index,
        })),
      });

      console.log("submitted successfully!");

      toast("vanilla", {
        subject: values.id
          ? "Inbox section updated."
          : "Inbox section created.",
      });

      navigateService(`/inbox/${sectionId}`);
    },
  ),
);
