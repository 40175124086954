import { IPostDoc } from "@libs/firestore-models";
import { isEqual } from "@libs/utils/isEqual";
import {
  ClipboardEvent,
  ComponentType,
  DragEvent,
  ForwardedRef,
  forwardRef,
  memo,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { IListRef, useListScrollboxContext } from "~/components/list";
import {
  IRichTextEditorRef,
  PostEditor,
  PostEditorErrors,
} from "~/form-components/post-editor";
import { useControlState } from "~/form-components/utils";
import { useComposedRefs } from "~/utils/useComposedRefs";
import {
  ICommandArgs,
  PLATFORM_MODIFIER_KEY,
  useRegisterCommands,
  withNewCommandContext,
} from "~/services/command.service";
import {
  createComposeMessageForm,
  IComposeMessageForm,
  IComposeMessageFormValue,
} from "~/components/ComposeMessageContext";
import { TThreadTimelineEntry } from "../utils";
import { OutlineButton } from "~/components/OutlineButtons";
import { Tooltip } from "~/components/Tooltip";
import { cx } from "@emotion/css";
import { DragTargetOverlay } from "~/components/DragTargetOverlay";
import { useImageDropHandlers } from "~/form-components/post-editor/extensions/image";
import { useControl } from "solid-forms-react";
import { IThreadRecipients, ReplyDraftHeader } from "../ReplyDraftHeader";
import {
  IThreadDocWithPermittedChannels,
  updatePost,
} from "~/services/post.service";
import { SetOptional } from "type-fest";
import { getCommandFactory } from "~/utils/common-commands";
import { fromEvent, Observable } from "rxjs";
import { setScrollTop } from "~/utils/dom-helpers";
import { oneLine } from "common-tags";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { setIsLoading } from "~/services/loading.service";
import { uniqBy } from "lodash-es";
import { ICommsThreadRecipientOption } from "~/form-components/ThreadRecipients";
import { toast } from "~/services/toast-service";

/* -------------------------------------------------------------------------------------------------
 * ComposePostReply
 * -----------------------------------------------------------------------------------------------*/

export interface IEditThreadPostEntryProps {
  thread: IThreadDocWithPermittedChannels;
  post: IPostDoc;
  initialFormValues: IComposeMessageFormValue;
  listRef: RefObject<IListRef<TThreadTimelineEntry>>;
  editorRef: RefObject<IRichTextEditorRef>;
  threadRecipients$: Observable<IThreadRecipients>;
  onDone: () => void;
}

export const EditThreadPostEntry = memo(
  withNewCommandContext({
    forwardRef: true,
    Component: forwardRef(
      (props: IEditThreadPostEntryProps, ref: ForwardedRef<HTMLDivElement>) => {
        const {
          thread,
          post,
          initialFormValues,
          listRef,
          editorRef,
          onDone,
          threadRecipients$,
          // This "otherProps" spread is necessary since this is a child of
          // List.Entry
          ...otherProps
        } = props;

        const formRef = useRef<HTMLFormElement>(null);
        const wrapperRef = useRef<HTMLDivElement>(null);
        const { scrollboxRef } = useListScrollboxContext();

        useRegisterCommands({
          commands() {
            const commands: ICommandArgs[] = [
              cancelEditingCommand({
                callback() {
                  const shouldDiscard = confirm(
                    `Are you sure you wish to discard your changes?`,
                  );

                  if (shouldDiscard) {
                    onDone();
                  }
                },
              }),
              savePostEditsCommand({
                async callback() {
                  if (!control.isValid) return;

                  control.markDisabled(true);

                  try {
                    await submit({
                      threadId: thread.id,
                      post,
                      values: control.rawValue,
                    });

                    toast("vanilla", {
                      subject: "Message updated",
                      description: `
                        FYI, notifications are *not* resent when editing a 
                        message. We plan on fixing this at some point
                        in the future.
                      `,
                      durationMs: 6000,
                    });
                  } catch (e) {
                    console.error(e);
                    control.markDisabled(false);
                    return;
                  }

                  onDone();
                },
              }),
            ];

            return commands;
          },
          deps: [onDone, post, thread.id],
        });

        // The list component subscribes directly to native keyboard events
        // and uses them to move focus between list entries. This means that
        // the list component bypasses React's synthetic event system. We need
        // to intercept these events before they react the list component
        // otherwise whenever someone is editing a draft and hits "ArrowUp/Down"
        // then the list component will move focus to the previous/next entry.
        useEffect(() => {
          if (!formRef.current) return;

          const sub = fromEvent<KeyboardEvent>(
            formRef.current,
            "keydown",
          ).subscribe((e) => {
            switch (e.key) {
              case "ArrowUp":
              case "ArrowDown": {
                e.stopPropagation();
                return;
              }
            }
          });

          return () => sub.unsubscribe();
        }, []);

        const control = useControl<IComposeMessageForm>(() => {
          return createComposeMessageForm(initialFormValues);
        });

        const {
          showDragTarget,
          setShowDragTarget,
          onDragEnter,
          onDragLeave,
          onDragOver,
        } = useImageDropHandlers(editorRef, post.id);

        const onDrop = useCallback(
          (e: DragEvent<HTMLElement>) => {
            e.preventDefault();
            setShowDragTarget(false);

            if (!editorRef.current?.editor) return;

            alert(oneLine`
              Comms currently doesn't support adding images when editing a post.
            `);
          },
          [setShowDragTarget, editorRef],
        );

        const onPaste = useCallback(
          (e: ClipboardEvent<HTMLElement>) => {
            e.preventDefault();
            if (!editorRef.current?.editor) return;

            alert(oneLine`
              Comms currently doesn't support adding images when editing a post.
            `);
          },
          [editorRef],
        );

        const controlBorderCSS = useControlState(() => {
          if (control.isPending)
            return "border-blue-5 focus-within:border-blue-9";
          if (control.errors) return "border-red-5 focus-within:border-red-9";
          return "border-green-5 focus-within:border-green-9";
        }, [control]);

        const composedEditorRefs = useComposedRefs(ref, wrapperRef);

        return (
          <div
            ref={composedEditorRefs}
            className={cx(
              "bg-white shadow-lg border-l-[.4rem]",
              "focus:outline-none transition-colors relative",
              controlBorderCSS,
            )}
            {...otherProps}
            onDragOver={onDragOver}
            // Note, onDragEnter/Leave events are fired when entering/exiting each
            // child element. Because of this, if we apply onDragEnter and
            // onDragLeave to this wrapper element, both events will be constantly
            // fired as someone moves their mouse over the elements in this div.
            // To get around this, we just attach the enter handler to this wrapper
            // element and we attach the leave handler to the drag target element
            // which, when visible, will be covering up all the other children.
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            onPaste={onPaste}
          >
            {showDragTarget && (
              <DragTargetOverlay onDragLeave={onDragLeave}>
                Embed Image
              </DragTargetOverlay>
            )}

            <ReplyDraftHeader
              thread={thread}
              listRef={listRef}
              control={control}
              threadRecipients$={threadRecipients$}
              isEditingExistingPost
            />

            <form ref={formRef} onSubmit={(e) => e.preventDefault()}>
              <div className="flex flex-col flex-1 overflow-y-auto px-4 sm-w:px-8">
                <PostEditor
                  ref={editorRef}
                  onEditorStartOverflow={() => {
                    if (!listRef.current) return;

                    onEditorOverflow({
                      move: "up",
                      entryId: post.id,
                      listRef: listRef.current,
                      scrollboxEl: scrollboxRef.current,
                    });
                  }}
                  onEditorEndOverflow={() => {
                    if (!listRef.current) return;

                    onEditorOverflow({
                      move: "down",
                      entryId: post.id,
                      listRef: listRef.current,
                      scrollboxEl: scrollboxRef.current,
                    });
                  }}
                  initialTabIndex={0}
                  control={control}
                />

                <PostEditorErrors control={control} />
              </div>

              <div className="flex px-4 sm-w:px-8 pt-2 pb-4 space-x-3">
                <EditPostActions />
              </div>
            </form>
          </div>
        );
      },
    ),
  }),
  isEqual,
);

const EditPostActions: ComponentType<{}> = () => {
  return (
    <>
      <SaveEditsButton />

      <div className="flex-1" />

      <CancelEditsButton />
    </>
  );
};

export const SaveEditsButton: ComponentType<{}> = () => {
  return (
    <Tooltip
      side="bottom"
      content={`Save changes (${PLATFORM_MODIFIER_KEY.name}+Enter)`}
    >
      <OutlineButton
        tabIndex={-1}
        onClick={(e) => {
          e.preventDefault();
          savePostEditsCommand.trigger();
        }}
      >
        <small>Save changes</small>
      </OutlineButton>
    </Tooltip>
  );
};

export const CancelEditsButton: ComponentType<{}> = () => {
  return (
    <Tooltip side="bottom" content="Discard changes (Escape)">
      <OutlineButton
        tabIndex={-1}
        onClick={(e) => {
          console.log("discard changes");
          e.preventDefault();
          cancelEditingCommand.trigger();
        }}
      >
        <small>Cancel</small>
      </OutlineButton>
    </Tooltip>
  );
};

function onEditorOverflow(args: {
  move: "up" | "down";
  entryId: string;
  listRef: IListRef<TThreadTimelineEntry>;
  scrollboxEl: HTMLElement | null;
}) {
  if (!args.listRef) return;

  const currentIndex = args.listRef.entries.findIndex(
    (e) => e.id === args.entryId,
  );

  if (currentIndex < 0) return;

  const entry = args.listRef.entries.at(
    currentIndex + (args.move === "up" ? -1 : 1),
  );

  if (entry) {
    args.listRef.focus(entry.id);
    return;
  }

  if (!args.scrollboxEl) return;

  setScrollTop(
    args.scrollboxEl,
    (value) => value + (args.move === "up" ? -100 : 100),
  );
}

/* -----------------------------------------------------------------------------------------------*/

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  setIsLoading(
    async (args: {
      values: IComposeMessageFormValue;
      threadId: string;
      post: IPostDoc;
    }) => {
      const { values, threadId, post } = args;

      console.log("submitting...", args);

      const recipients = uniqBy(
        [
          ...post.recipientChannelIds.map((id) => ({
            id,
            type: "channel" as const,
          })),
          ...post.recipientUserIds.map((id) => ({ id, type: "user" as const })),
          ...(values.recipients.to as ICommsThreadRecipientOption[]).map(
            (r) => ({
              id: r.value,
              type: r.type,
            }),
          ),
        ],
        "id",
      );

      await updatePost({
        postId: values.postId,
        threadId,
        recipients,
        content: values.body.content,
        userMentions: values.body.userMentions,
        channelMentions: values.body.channelMentions,
      });
    },
  ),
);

/* -----------------------------------------------------------------------------------------------*/

/**
 * Used to navigate "back" to the most recent route which is not
 * rendered by the current view.
 *
 * E.g. if viewing a specific thread (i.e. `/threads/fdlaskfj2343j90jfaf`),
 * then you are on the ThreadView. You might use this command to navigate you back
 * to the most recent route which does not start with `/threads`/.
 *
 * Note from John: I'm open to suggestions for a better name for
 * this command.
 */
export const cancelEditingCommand = getCommandFactory(
  "CANCEL_EDITING",
  (
    options: SetOptional<
      ICommandArgs,
      "label" | "altLabels" | "hotkeys" | "triggerHotkeysWhenInputFocused"
    >,
  ): ICommandArgs => ({
    label: "Cancel editing",
    altLabels: ["Discard edits"],
    hotkeys: ["Escape"],
    triggerHotkeysWhenInputFocused: true,
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const savePostEditsCommand = getCommandFactory(
  "SAVE_POST_EDITS",
  (
    options: SetOptional<
      ICommandArgs,
      "label" | "altLabels" | "hotkeys" | "triggerHotkeysWhenInputFocused"
    >,
  ): ICommandArgs => ({
    label: "Save post edits",
    hotkeys: ["$mod+Enter"],
    triggerHotkeysWhenInputFocused: true,
    ...options,
  }),
);
