import { css, cx } from "@emotion/css";
import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ComponentType,
  useMemo,
  Fragment,
} from "react";
import { SuggestionProps } from "@tiptap/suggestion";
import {
  getDropdownRenderFn,
  onSuggestionDialogClose,
  onSuggestionDialogOpen,
} from "~/form-components/tiptap/suggestion-utils";
import { groupBy } from "lodash-es";
import { UnreachableCaseError } from "@libs/utils/errors";

export type WithIndexAndGroup<T, Group extends string> = T & {
  index: number;
  group: Group;
};

export interface IBaseSuggestion {
  group: string;
  id: string | number;
  index: number;
}

export interface ISuggestionEntryProps<T extends IBaseSuggestion> {
  entry: T;
  selectedIndex: number;
  selectItem: (index: number) => void;
}

export type IBaseSuggestionDropdownProps<T extends IBaseSuggestion> =
  SuggestionProps<T> & {
    getSuggestionAttributes: (item: T) => Record<string, string>;
    suggestionEntryComponentMap: Record<
      string,
      // We're using `any` because the T suggestion entry type is
      // probably a union of different types of entries but each
      // of these suggestionEntry components can't handle being
      // passed a union type.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ComponentType<ISuggestionEntryProps<any>>
    >;
  };

export const BaseSuggestionsDropdown = forwardRef<
  { onKeyDown(o: { event: KeyboardEvent }): boolean | undefined },
  IBaseSuggestionDropdownProps<IBaseSuggestion>
>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    // We need to track whether a mentioned list is opened or not
    // for the EditorOverflowHandler. See `EditorOverflowHandler.ts`
    // for more info.
    onSuggestionDialogOpen();

    return onSuggestionDialogClose;
  });

  const suggestionEntriesByGroup = useMemo(() => {
    const groupOrder = Object.keys(props.suggestionEntryComponentMap);

    return Object.entries(groupBy(props.items, (item) => item.group)).sort(
      ([aGroupId], [bGroupId]) =>
        groupOrder.indexOf(aGroupId) - groupOrder.indexOf(bGroupId),
    );
  }, [props.items, props.suggestionEntryComponentMap]);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (!item) return;

    const suggestionAttrs = props.getSuggestionAttributes(item);

    // The SuggestionProps are typed as requiring props.command to
    // receive the same type as props.items[0] but, in actuality,
    // they don't need to be the same and we aren't passing the
    // same interface.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props.command(suggestionAttrs as any);
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length,
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    const item = props.items[selectedIndex];

    if (!item) return;

    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      console.log("onKeyDown", event);
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div
      className={cx(
        "bg-white rounded overflow-hidden",
        `w-[330px] pb-3`,
        suggestionDropdownStyles,
      )}
    >
      {suggestionEntriesByGroup.map(([groupId, groupEntries], index) => {
        const divider = index > 0 && <hr className="text-slate-5 mt-3 mb-1" />;
        const EntryComponent = props.suggestionEntryComponentMap[groupId];

        if (!EntryComponent) {
          throw new UnreachableCaseError(
            EntryComponent as never,
            "Unexpected suggestion group",
          );
        }

        return (
          <Fragment key={groupId}>
            {divider}

            <SectionWrapper>
              <SectionHeader label={groupId} />

              {groupEntries.map((entry) => {
                return (
                  <EntryComponent
                    key={entry.id}
                    entry={entry}
                    selectedIndex={selectedIndex}
                    selectItem={selectItem}
                  />
                );
              })}
            </SectionWrapper>
          </Fragment>
        );
      })}
    </div>
  );
});

const suggestionDropdownStyles = css`
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
`;

const SectionWrapper: ComponentType<{}> = (props) => {
  return <div className="flex flex-col">{props.children}</div>;
};

const SectionHeader: ComponentType<{
  label: string;
}> = (props) => {
  return (
    <h4 className="text-xs uppercase text-slate-9 font-medium mx-4 my-2">
      {props.label}
    </h4>
  );
};

export const BaseEntry: ComponentType<{
  item: { id: string };
  isSelected: boolean;
  isDisabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}> = (props) => {
  return (
    <button
      type="button"
      className={cx(
        "flex items-center text-left mx-1 pr-3 py-1 rounded",
        "relative text-sm",
        props.isSelected ? "bg-slate-5" : "bg-transparent",
        props.isDisabled && "text-slate-9",
      )}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export function getSuggestionRenderFn<T extends IBaseSuggestion>(
  args: Omit<IBaseSuggestionDropdownProps<T>, keyof SuggestionProps>,
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return getDropdownRenderFn<T>(BaseSuggestionsDropdown as any, {
    additionalProps: args,
  });
}
