import {
  IChannelDoc,
  IUserDoc,
  PostMentionPriority,
  ThreadVisibility,
} from "@libs/firestore-models";
import { createContext, useContext } from "react";
import { IAbstractControl, IFormControl, IFormGroup } from "solid-forms-react";

export interface IEditorMention {
  type: "user" | "channel";
  id: IUserDoc["id"] | IChannelDoc["id"];
  priority: PostMentionPriority;
}

export type IPostEditorControl = IFormGroup<{
  [key: string]: IAbstractControl<unknown>;
  postId: IFormControl<string>;
  type: IFormControl<"COMMS" | "EMAIL">;
  visibility: IFormControl<ThreadVisibility | null>;
  body: IFormGroup<{
    content: IFormControl<string>;
    userMentions: IFormControl<IEditorMention[]>;
    channelMentions: IFormControl<IEditorMention[]>;
    /**
     * Count of the total number of mentions in this post.
     * At the moment, the only reason why we have this property
     * is so that form code can respond when someone adds
     * or removes an `@mention`. We
     * don't actually care about the total number of mentions in
     * a post. Because of this, at time of writing code in the
     * NewThreadForm and ComposePostReply components are
     * initializing this control with the possibly incorrect
     * value of `0`. So currently we cannot trust the value of
     * this control, but we can trust that it will update when
     * the mentions count updates.
     *
     * In the future if we refactor this to accurately display
     * the mentions count, we should rename this property.
     */
    possiblyIncorrectMentionsCount: IFormControl<number>;
  }>;
}>;

/** This version of the type is for internal use */
type _IPostEditorControl = IFormGroup<{
  postId: IFormControl<string>;
  type: IFormControl<"COMMS" | "EMAIL">;
  visibility: IFormControl<ThreadVisibility | null>;
  body: IFormGroup<{
    content: IFormControl<string>;
    userMentions: IFormControl<IEditorMention[]>;
    channelMentions: IFormControl<IEditorMention[]>;
    possiblyIncorrectMentionsCount: IFormControl<number>;
  }>;
}>;

export interface IPostEditorContext {
  control: _IPostEditorControl;
}

export const PostEditorContext = createContext<IPostEditorContext | null>(null);

export function usePostEditorContext() {
  const context = useContext(PostEditorContext);

  if (!context) {
    throw new Error(
      "You've attempted to use PostEditorContext without providing it",
    );
  }

  return context;
}
