import { IPostDoc, IThreadDoc } from "@libs/firestore-models";
import {
  ComponentType,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ContentList,
  useKBarAwareFocusedEntry,
} from "~/components/content-list";
import { IListRef, useListScrollboxContext } from "~/components/list";
import { ComposePostReply, usePreloadThreadRecipients$ } from "./ComposeReply";
import { IRichTextEditorRef } from "~/form-components/post-editor/PostEditor";
import { elementPositionInContainer } from "~/utils/view-helpers";
import { ThreadPostEntry } from "./thread-post-entry/ThreadPostEntry";
import { useSearchParams } from "react-router-dom";
import useConstant from "use-constant";
import { Subject } from "rxjs";
import { EditThreadDialog } from "~/dialogs/thread-edit/EditThreadDialog";
import { stripIndents } from "common-tags";
import { getThreadSeenUpdaterFn, IObservePost } from "~/services/post.service";
import { PostReactionPickerDialog } from "~/dialogs/post-reaction-picker/PostReactionPicker";
import { setScrollTop } from "~/utils/dom-helpers";
import { useThreadContext } from "./context";
import { IEditorMention } from "~/form-components/post-editor";
import { UnreachableCaseError } from "@libs/utils/errors";
import {
  createComposeMessageForm,
  IComposeMessageFormValue,
} from "~/components/ComposeMessageContext";
import { useControl } from "solid-forms-react";
import { QuoteBranchedThreadPosts } from "./QuoteBranchedThreadPosts";
import {
  IDraft,
  IPostDocFromDraft,
  TDraftRecipient,
} from "~/services/draft.service";
import { getAndAssertCurrentUser } from "~/services/user.service";
import { TThreadTimelineEntry } from "./utils";
import { ThreadPostEntryContext } from "./thread-post-entry/context";
import {
  buildRecipientOption,
  IEmailRecipientOption,
} from "~/form-components/ThreadRecipients";
import { getChannel } from "~/services/channels.service";
import {
  getOrganizationMember,
  getOrganizationMemberByEmail,
  IAcceptedOrganizationMemberDoc,
} from "~/services/organization.service";
import { isNonNullable } from "@libs/utils/predicates";
import { useRegisterThreadPostsCommands } from "./useRegisterThreadPostsCommands";
import { parseStringToEmailAddress } from "@libs/utils/parseEmailAddress";

/* -------------------------------------------------------------------------------------------------
 * ThreadPosts
 * -----------------------------------------------------------------------------------------------*/

export const ThreadPosts: ComponentType<{}> = () => {
  const context = useThreadContext();
  const listRef = useRef<IListRef<TThreadTimelineEntry>>(null);
  const editorRef = useRef<IRichTextEditorRef>(null);
  const [isEditingPostId, setIsEditingPostId] = useState<string | null>(null);

  const draft = useThreadDraft();

  const {
    postsAndOptimisticallySentDraft,
    optimisticallyRenderedSentDraft,
    setOptimisticallyRenderedSentDraft,
  } = useThreadPosts();

  const initiallyFocusEntryId = useInitiallyFocusEntryId();

  const [focusedEntry, setFocusedEntry] =
    useKBarAwareFocusedEntry<TThreadTimelineEntry>();

  const loadMorePostsButtonFocusEvents$ = useConstant(
    () => new Subject<void>(),
  );

  const collapsePostEvents$ = useConstant(
    () => new Subject<"expand" | "collapse" | string>(),
  );

  const control = useReplyFormControl({
    draft,
    optimisticallyRenderedSentDraft,
  });

  const canEditFocusedEntry = useCanEditFocusedEntry({
    focusedEntry,
    isEditingPostId,
    isThereADraftReplyAssociatedWithThisThread: !!control,
    postsAndOptimisticallySentDraftLength:
      postsAndOptimisticallySentDraft.length,
    firstPostId: postsAndOptimisticallySentDraft[0]?.id,
  });

  const thread = context.useThread();

  const threadRecipients$ = usePreloadThreadRecipients$(thread.id);

  useRegisterThreadPostsCommands({
    focusedEntry,
    editorRef,
    listRef,
    collapsePostEvents$,
    canEditFocusedEntry,
    setIsEditingPostId,
  });

  const updateThreadSeen = useUpdateThreadSeenFn();

  const { onArrowUpOverflow, onArrowDownOverflow } = usePostListOverflowFns({
    editorRef,
    loadMorePostsButtonFocusEvents$,
  });

  return (
    <>
      <EditThreadDialog />
      <PostReactionPickerDialog />

      <ThreadPostEntryContext.Provider
        value={{ isEditingPostId, setIsEditingPostId }}
      >
        <ContentList<TThreadTimelineEntry>
          ref={listRef}
          onArrowUpOverflow={onArrowUpOverflow}
          onArrowDownOverflow={onArrowDownOverflow}
          focusOnMouseOver={false}
          initiallyFocusEntryId={
            initiallyFocusEntryId === "draft"
              ? undefined
              : initiallyFocusEntryId
          }
          onEntryFocused={setFocusedEntry}
        >
          {thread.branchedFrom && (
            <QuoteBranchedThreadPosts
              listRef={listRef}
              branchCreatedAt={thread.createdAt}
              branchedFrom={thread.branchedFrom}
              collapsePostEvents={collapsePostEvents$}
              loadMorePostsButtonFocusEvents={loadMorePostsButtonFocusEvents$}
              threadRecipients$={threadRecipients$}
            />
          )}

          {postsAndOptimisticallySentDraft.map((post, index) => {
            const { canEdit, cannotEditReason, canUndoSend } = canEditPost({
              post,
              isEditingPostId,
              isThereADraftReplyAssociatedWithThisThread: !!control,
              isThisPostOptimisticallySent: !!post.__local.fromUnsafeDraft,
              isThisTheOnlyPostInThreadOrIsThisNotTheFirstPostInThread:
                postsAndOptimisticallySentDraft.length === 1 || index !== 0,
            });

            return (
              <ThreadPostEntry
                key={post.id}
                post={post}
                thread={thread}
                listRef={listRef}
                canEdit={canEdit}
                canUndoSend={canUndoSend}
                cannotEditReason={cannotEditReason}
                isFromSecretThread={!!thread.__local.fromSecretThread}
                threadRecipients$={threadRecipients$}
                isFirstPost={index === 0}
                isLastPost={
                  index === postsAndOptimisticallySentDraft.length - 1
                }
                collapsePostEvents={collapsePostEvents$}
                relativeOrder={index}
                onPostInView={updateThreadSeen}
              />
            );
          })}
        </ContentList>
      </ThreadPostEntryContext.Provider>

      {control && (
        <ComposePostReply
          ref={editorRef}
          control={control}
          thread={thread}
          threadRecipients$={threadRecipients$}
          listRef={listRef}
          onClose={(post) => {
            if (!post) return;

            setOptimisticallyRenderedSentDraft(post);
          }}
          focusOnInit={initiallyFocusEntryId === "draft"}
        />
      )}

      {!control && (
        <div className="mt-8 mx-10 prose text-slate-9 sm-max-w:hidden">
          <p>
            <strong>Hint:</strong> press <kbd>r</kbd> to reply.
          </p>
        </div>
      )}

      <div className="h-20 sm-max-w:mb-8" />
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

/**
 * This is a Safari bugfix:
 * In Chrome and Firefox, after a draft was "sent" Firestore would synchronously
 * update and emit the `observeDraftForThread()` subscription. As a consequence,
 * after sending a draft "props.draft" would be null. In Safari, this does
 * not happen synchronously and there is some period of time in which a draft has
 * been "sent" but props.draft still reflects a stale value. In Safari, after
 * sending a draft the following would happen
 *
 * 1. sentDraft would be set causing the draft editor to unmount.
 * 2. The "props.posts" value would emit with our updated draft before "props.draft"
 *    would update.
 * 3. This would cause "sentDraft" to be cleared
 * 4. This would cause "showComposeEditor" to be equal to a stale draft value.
 * 5. Since showComposeEditor was truthy, the draft editor would be mounted again
 *    but it would mount with the stale draft value (e.g. "" for the body content).
 * 6. Then "props.draft" would finally update to "null" (reflecting the
 *    fact that the draft was sent) and this would cause the draft editor to unmount.
 * 7. On onmount, the draft editor would save the draft using the stale values of the
 *    old draft which would cause the "sent" post to be updated with stale values.
 * 8. If the stale body content was "" then the draft would be automatically deleted.
 */
function useThreadDraft() {
  const context = useThreadContext();
  const posts = context.useThreadPosts();
  const draft = context.useDraftForThread();

  return useMemo(() => {
    if (!draft) return null;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (posts.some((p) => p.id === draft!.id)) return null;
    return draft;
  }, [posts, draft]);
}

/* -----------------------------------------------------------------------------------------------*/

function useThreadPosts() {
  const context = useThreadContext();
  const posts = context.useThreadPosts();

  // After "sending" a draft (really, scheduling the draft to be sent in the future)
  // we optimistically render the draft as if it has been sent in order to
  // keep the UI snappy. To make this work, we convert the sent draft into a post
  // document and store it in this state.
  const [optimisticallyRenderedSentDraft, setOptimisticallyRenderedSentDraft] =
    useState<IPostDocFromDraft | null>(null);

  // Clear `optimisticallyRenderedSentDraft` if it's non-null but the server
  // has already picked up and sent the draft "for real"
  useEffect(() => {
    if (!optimisticallyRenderedSentDraft) return;

    const hasTheSentDraftBeenProcessedByTheServerAndSentForReal = posts.some(
      (p) => p.id === optimisticallyRenderedSentDraft.id,
    );

    if (!hasTheSentDraftBeenProcessedByTheServerAndSentForReal) {
      return;
    }

    setOptimisticallyRenderedSentDraft(null);
  }, [optimisticallyRenderedSentDraft, posts]);

  const postsAndOptimisticallySentDraft = useMemo(() => {
    if (!optimisticallyRenderedSentDraft) return posts;

    const hasTheSentDraftBeenProcessedByTheServerAndSentForReal = posts.some(
      (p) => p.id === optimisticallyRenderedSentDraft.id,
    );

    if (hasTheSentDraftBeenProcessedByTheServerAndSentForReal) {
      return posts;
    }

    return [...posts, optimisticallyRenderedSentDraft];
  }, [posts, optimisticallyRenderedSentDraft]);

  return {
    postsAndOptimisticallySentDraft,
    optimisticallyRenderedSentDraft,
    setOptimisticallyRenderedSentDraft,
  };
}

/* -----------------------------------------------------------------------------------------------*/

function useInitiallyFocusEntryId() {
  const context = useThreadContext();
  const readStatus = context.useThreadReadStatus();
  const posts = context.useThreadPosts();
  const [searchParams] = useSearchParams();
  const postIdQueryParam = searchParams.get("post") || undefined;

  const initiallyFocusEntryId = useConstant(() => {
    if (postIdQueryParam) return postIdQueryParam;

    if (!readStatus) {
      return posts[0]?.id;
    }

    const firstUnreadPost = posts.find((post) => {
      if (!readStatus.readToSentAt || !readStatus.readToScheduledToBeSentAt) {
        return true;
      }

      return (
        post.sentAt.valueOf() > readStatus.readToSentAt.valueOf() ||
        (post.sentAt.valueOf() == readStatus.readToSentAt.valueOf() &&
          post.scheduledToBeSentAt.valueOf() >
            readStatus.readToScheduledToBeSentAt.valueOf())
      );
    });

    if (firstUnreadPost) return firstUnreadPost.id;

    return posts.at(-1)?.id;
  });

  return initiallyFocusEntryId;
}

/* -----------------------------------------------------------------------------------------------*/

function useUpdateThreadSeenFn() {
  const context = useThreadContext();
  const thread = context.useThread();
  const readStatus = context.useThreadReadStatus();

  const updateThreadSeen = useMemo(() => {
    return getThreadSeenUpdaterFn({
      threadId: thread.id,
      initialThreadReadStatus: readStatus,
    });
    // Because we only want the initialThreadReadStatus of the
    // thread, we only want to rerun this memo when the thread changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thread.id, readStatus?.threadId]);

  return updateThreadSeen;
}

/* -----------------------------------------------------------------------------------------------*/

function usePostListOverflowFns(args: {
  editorRef: RefObject<IRichTextEditorRef>;
  loadMorePostsButtonFocusEvents$: Subject<void>;
}) {
  const { editorRef, loadMorePostsButtonFocusEvents$ } = args;
  const { scrollboxRef, offsetHeaderEl: scrollboxOffsetHeaderEl } =
    useListScrollboxContext();

  /** Scroll list container up if possible */
  const onArrowUpOverflow = useCallback((e) => {
    loadMorePostsButtonFocusEvents$.next();
    if (!scrollboxRef.current) return;
    e.preventDefault();
    setScrollTop(scrollboxRef.current, (oldValue) => oldValue - 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Focus and scroll to the compose draft editor */
  const onArrowDownOverflow = useCallback((e) => {
    const editorEl = editorRef.current?.editor?.view.dom;
    e.preventDefault();

    if (!editorEl || !scrollboxRef.current) return;

    editorEl.focus({ preventScroll: true });

    const { bottom } = elementPositionInContainer({
      container: scrollboxRef.current,
      element: editorEl,
      containerPosOffset: {
        top: scrollboxOffsetHeaderEl?.current?.offsetHeight,
      },
    });

    if (bottom !== "below") return;

    setScrollTop(scrollboxRef.current, (oldValue) => oldValue + 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onArrowUpOverflow,
    onArrowDownOverflow,
  };
}

/* -------------------------------------------------------------------------------------------------
 * useReplyFormControl
 * -----------------------------------------------------------------------------------------------*/

function useReplyFormControl(args: {
  draft: IDraft | null;
  optimisticallyRenderedSentDraft: IPostDoc | null;
}) {
  const { draft, optimisticallyRenderedSentDraft } = args;
  const thread = useThreadContext().useThread();
  const [initialFormValues, setInitialFormValues] =
    useState<IComposeMessageFormValue | null>(null);

  const showComposeEditor = !optimisticallyRenderedSentDraft && !!draft;

  useEffect(() => {
    if (!showComposeEditor) {
      setInitialFormValues(null);
      return;
    }

    getInitialFormValues(thread, draft).then(setInitialFormValues);

    // We only want to run this when initializing the reply draft form
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComposeEditor]);

  const control = useControl(() => {
    if (!initialFormValues) return;
    const control = createComposeMessageForm(initialFormValues);
    control.controls.subject.markDisabled(true);
    return control;
  }, [initialFormValues]);

  // respond to thread visibility changes
  useEffect(() => {
    if (!control) return;

    control.patchValue({
      visibility: thread.visibility,
    });
  }, [control, thread.visibility]);

  return control;
}

/* -----------------------------------------------------------------------------------------------*/

async function getInitialFormValues(
  thread: IThreadDoc,
  draft: IDraft,
): Promise<IComposeMessageFormValue> {
  return {
    type: draft.type,
    postId: draft.id,
    branchedFrom: draft.branchedFrom || null,
    visibility: thread.visibility,
    recipients: await getRecipientsFromDraft(
      draft,
      thread.visibility === "private",
    ),
    // subject is not used when composing reply
    subject: "",
    body: {
      content: draft.bodyHTML,
      channelMentions: getMentionsFromDraft("channel", draft),
      userMentions: getMentionsFromDraft("user", draft),
    },
  };
}

/* -----------------------------------------------------------------------------------------------*/

function getMentionsFromDraft(
  type: IEditorMention["type"],
  draft: IDraft,
): IEditorMention[] {
  let draftProp: keyof IDraft;

  switch (type) {
    case "user": {
      draftProp = "mentionedUsers";
      break;
    }
    case "channel": {
      draftProp = "mentionedChannels";
      break;
    }
    default: {
      throw new UnreachableCaseError(type);
    }
  }

  return Object.entries(draft[draftProp]).map(([id, { priority }]) => ({
    id,
    type,
    priority,
  }));
}

/* -----------------------------------------------------------------------------------------------*/

async function getRecipientsFromDraft(
  draft: IDraft,
  isThreadPrivate: boolean | null,
) {
  const { organizationId } = getAndAssertCurrentUser();

  // TODO: update when we support users who aren't part of an organization
  if (!organizationId) {
    throw new Error(
      "This code expects the current user to be part of an organization",
    );
  }

  const mapRecipientToOption = async (recipient: TDraftRecipient) => {
    switch (recipient.type) {
      case "channelId": {
        const channelDoc = await getChannel(recipient.value);

        if (!channelDoc) return;

        return buildRecipientOption({
          threadType: draft.type,
          doc: channelDoc,
          isThreadPrivate,
        });
      }
      case "userId": {
        const memberDoc = await getOrganizationMember(
          organizationId,
          recipient.value,
        );

        if (!memberDoc?.accepted) return;

        return buildRecipientOption({
          threadType: draft.type,
          doc: memberDoc as IAcceptedOrganizationMemberDoc,
          isThreadPrivate,
        });
      }
      case "emailAddress": {
        const email = parseStringToEmailAddress(recipient.value);

        if (!email) {
          console.debug("Failed to parse email draft recipient", recipient);
          return null;
        }

        const member = await getOrganizationMemberByEmail(
          organizationId,
          email.address,
        );

        if (
          member?.accepted &&
          member.acceptedAt &&
          !member.removed &&
          member.user
        ) {
          const acceptedMember: IAcceptedOrganizationMemberDoc = {
            ...member,
            __docType: "IOrganizationMemberDoc",
            __local: {},
            accepted: member.accepted,
            acceptedAt: member.acceptedAt,
            user: member.user,
            removed: false,
            removedAt: null,
          };

          return buildRecipientOption({
            threadType: draft.type,
            doc: acceptedMember,
            isThreadPrivate,
          }) as IEmailRecipientOption;
        }

        return {
          type: "email",
          label: email.label || email.address,
          value: email.address,
          email: email.address,
        } satisfies IEmailRecipientOption as IEmailRecipientOption;
      }
    }
  };

  const toQuery = Promise.all(draft.to.map(mapRecipientToOption));
  const ccQuery = Promise.all(draft.cc.map(mapRecipientToOption));
  const bccQuery = Promise.all(draft.bcc.map(mapRecipientToOption));

  const [to, cc, bcc] = await Promise.all([toQuery, ccQuery, bccQuery]);

  return {
    to: to.filter(isNonNullable),
    cc: cc.filter(isNonNullable),
    bcc: bcc.filter(isNonNullable) as IEmailRecipientOption[],
  };
}

/* -----------------------------------------------------------------------------------------------*/

function canEditPost(args: {
  post: IPostDocFromDraft | IObservePost;
  isEditingPostId: string | null;
  isThisPostOptimisticallySent: boolean;
  isThereADraftReplyAssociatedWithThisThread: boolean;
  isThisTheOnlyPostInThreadOrIsThisNotTheFirstPostInThread: boolean;
}) {
  const {
    post,
    isEditingPostId,
    isThisPostOptimisticallySent,
    isThereADraftReplyAssociatedWithThisThread,
    isThisTheOnlyPostInThreadOrIsThisNotTheFirstPostInThread,
  } = args;

  const currentUser = getAndAssertCurrentUser();

  const cannotEditReason =
    post.type === "EMAIL" &&
    (!isThisPostOptimisticallySent ||
      post.__local.fromUnsafeDraft?.sent === true)
      ? `This is an email thread. Emails don't support editing.`
      : isThisPostOptimisticallySent &&
        isThereADraftReplyAssociatedWithThisThread
      ? stripIndents`
          Cannot be edited. This post is scheduled to be sent but has not yet
          been sent. Editing it involves unsending this post and converting it
          back into a draft.  Currently Comms has a limitation that only a single 
          draft can be associated with a thread. Since you already have a draft associated
          with this thread, you cannot convert this post back into a draft for editing. 
          Discard or send the existing draft to edit this post. In the future this 
          restriction will be lifted.
        `
      : isEditingPostId && isEditingPostId !== post.id
      ? `Can only edit one post at a time`
      : "";

  const canUndoSend =
    !isThereADraftReplyAssociatedWithThisThread &&
    // The draft should not have been sent by the server. Most drafts
    // will be deleted after they've been sent by the server, but
    // email drafts can last for a bit even after the server sends them.
    !post.__local.fromUnsafeDraft?.sent &&
    isThisPostOptimisticallySent &&
    isThisTheOnlyPostInThreadOrIsThisNotTheFirstPostInThread;

  const canEdit =
    (post.type === "COMMS" ||
      (post.type === "EMAIL" &&
        post.__local.fromUnsafeDraft?.sent === false)) &&
    post.creatorId === currentUser.id &&
    (isThisPostOptimisticallySent ||
      !isEditingPostId ||
      isEditingPostId === post.id);

  return {
    canEdit,
    canUndoSend,
    cannotEditReason,
  };
}

/* -----------------------------------------------------------------------------------------------*/

function useCanEditFocusedEntry(args: {
  focusedEntry: TThreadTimelineEntry | null;
  isThereADraftReplyAssociatedWithThisThread: boolean;
  isEditingPostId: string | null;
  postsAndOptimisticallySentDraftLength: number;
  firstPostId: string | undefined;
}) {
  return useMemo(() => {
    if (args.focusedEntry?.__docType !== "IPostDoc") return false;

    const { canEdit, cannotEditReason, canUndoSend } = canEditPost({
      post: args.focusedEntry,
      isEditingPostId: args.isEditingPostId,
      isThereADraftReplyAssociatedWithThisThread:
        args.isThereADraftReplyAssociatedWithThisThread,
      isThisPostOptimisticallySent: !!args.focusedEntry.__local.fromUnsafeDraft,
      isThisTheOnlyPostInThreadOrIsThisNotTheFirstPostInThread:
        args.postsAndOptimisticallySentDraftLength === 1 ||
        args.firstPostId !== args.focusedEntry.id,
    });

    return canEdit && !cannotEditReason
      ? canUndoSend
        ? "undo"
        : "edit"
      : false;
  }, [
    args.focusedEntry,
    args.isThereADraftReplyAssociatedWithThisThread,
    args.isEditingPostId,
    args.postsAndOptimisticallySentDraftLength,
    args.firstPostId,
  ]);
}

/* -----------------------------------------------------------------------------------------------*/
