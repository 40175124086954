import { ComponentType, useRef, useMemo } from "react";
import { ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { ContentList, EmptyListMessage } from "~/components/content-list";
import { useTopScrollShadow } from "~/utils/useScrollShadow";
import { PendingRequestBar } from "~/components/PendingRequestBar";
import { useParams } from "react-router-dom";
import { NotFound } from "~/components/NotFound";
import { SubscriberEntry } from "./SubscriberEntry";
import { showNotImplementedToastMsg } from "~/services/toast-service";
import { useChannelSubscribers } from "~/services/subscription.service";
import { useChannel } from "~/services/channels.service";
import { groupBy } from "lodash-es";
import { useRegisterCommands } from "~/services/command.service";
import { ESCAPE_TO_BACK_COMMAND } from "~/utils/common-commands";
import { Tooltip } from "~/components/Tooltip";
import { BsLockFill } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { ChannelInviteDialogState } from "~/dialogs/channel-invite/ChannelInviteDialog";
import * as MainLayout from "~/page-layouts/main-layout";
import { OutlineButton } from "~/components/OutlineButtons";

export const SubscribersView: ComponentType<{}> = () => {
  const params = useParams();
  const channel = useChannel(params.channelId);
  const subscribers = useChannelSubscribers(params.channelId);

  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);

  useRegisterCommands({
    commands() {
      const commands = [ESCAPE_TO_BACK_COMMAND];

      if (channel) {
        commands.push({
          label: "Add subscribers...",
          altLabels: [
            "Update subscribers...",
            "Add channel members...",
            "Invite to channel...",
            "Invite subscribers...",
          ],
          callback: () => {
            ChannelInviteDialogState.toggle(true, {
              channel,
            });
          },
        });
      }

      return commands;
    },
    deps: [channel],
  });

  const [everythingSubs, newThreadsSubs, participatingSub] = useMemo(() => {
    const {
      all = [],
      "all-new": allNew = [],
      involved = [],
    } = groupBy(subscribers, (s) => s.preference);

    return [all, allNew, involved];
  }, [subscribers]);

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
    deps: [channel],
  });

  if (channel === undefined) {
    return <div>Loading...</div>;
  }

  if (channel === null) {
    return <NotFound title="Channel Not Found" />;
  }

  return (
    <>
      <Helmet>
        <title>#{channel.name} subscribers | Comms</title>
      </Helmet>

      <MainLayout.Header
        ref={headerRef}
        theme={channel.classification === "private" ? "dark" : "light"}
        className="flex-col"
      >
        <h1 className="text-3xl">
          <span># {channel.name} subscribers</span>
          {channel.classification === "private" && (
            <Tooltip
              side="bottom"
              content="This channel is private and only visible to invited members"
            >
              <span className="text-2xl inline-flex mx-2 hover:cursor-help mt-1 text-slate-8">
                <small>
                  <BsLockFill />
                </small>
              </span>
            </Tooltip>
          )}
        </h1>

        <MainLayout.HeaderMenu>
          <li>
            <OutlineButton
              theme={channel.classification === "private" ? "dark" : "light"}
              onClick={(e) => {
                e.preventDefault();

                ChannelInviteDialogState.toggle(true, {
                  channel,
                });
              }}
            >
              <FaPlus size={16} className="mr-1 text-slate-11" />{" "}
              <small>Update subscribers</small>
            </OutlineButton>
          </li>
        </MainLayout.HeaderMenu>
      </MainLayout.Header>

      <ListScrollbox
        isBodyElement
        offsetHeaderEl={headerRef}
        onlyOffsetHeaderElIfSticky
      >
        {!subscribers ? (
          <PendingRequestBar />
        ) : subscribers.length === 0 ? (
          <EmptyListMessage text="None." />
        ) : (
          <ContentList
            className="mb-20"
            onEntryAction={() => {
              showNotImplementedToastMsg(`
                  Unfortunately, you can't currently view user profiles. 
                  Annoying, I know. I want this feature too...
                `);
            }}
            autoFocus
          >
            {everythingSubs.length > 0 && (
              <>
                <SectionHeader label="Subscribed to all" />

                {everythingSubs.map((member, index) => (
                  <SubscriberEntry
                    key={member.id}
                    member={member}
                    relativeOrder={index}
                  />
                ))}
              </>
            )}

            {newThreadsSubs.length > 0 && (
              <>
                <SectionHeader label="Subscribed to new threads" />

                {newThreadsSubs.map((member, index) => (
                  <SubscriberEntry
                    key={member.id}
                    member={member}
                    relativeOrder={index}
                  />
                ))}
              </>
            )}

            {participatingSub.length > 0 && (
              <>
                <SectionHeader label="Unsubscribed but has access to channel" />

                {participatingSub.map((member, index) => (
                  <SubscriberEntry
                    key={member.id}
                    member={member}
                    relativeOrder={index}
                  />
                ))}
              </>
            )}
          </ContentList>
        )}
      </ListScrollbox>
    </>
  );
};

const SectionHeader: ComponentType<{ label: string }> = (props) => {
  return (
    <div className="mt-8 mb-2 mx-12 border-l-2 border-white text-black font-medium flex items-center">
      <h2 className="text-2xl">{props.label}</h2>
    </div>
  );
};
