import { css, cx } from "@emotion/css";
import type { Timestamp } from "@firebase/firestore-types";
import { IThreadDoc } from "@libs/firestore-models";
import { isEqual } from "@libs/utils/isEqual";
import dayjs from "dayjs";
import {
  ComponentType,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { BsLockFill } from "react-icons/bs";
import { MdEmail, MdError } from "react-icons/md";
import { RiGitBranchLine } from "react-icons/ri";
import { delay, fromEvent, Observable, skip, take } from "rxjs";
import { isModKeyActive } from "~/services/command.service";
import { navigateService } from "~/services/navigate.service";
import {
  IObservePost,
  useBranchedThreadPosts,
  useThread,
} from "~/services/post.service";
import { openLinkInNewTabOrWindow } from "~/utils/navigation-helpers";
import { convertDateTimeToRelativeString_DayAtTime } from "~/utils/time-formatting";
import { IListOnEntryActionEvent, List, useListContext } from "./list";
import { OutlineButton } from "./OutlineButtons";

/* -------------------------------------------------------------------------------------------------
 * QuoteBranchedThreadPostsBase
 * -----------------------------------------------------------------------------------------------*/

export const QuoteBranchedThreadPostsBase: ComponentType<{
  branchCreatedAt: Timestamp | "now";
  branchedFrom: NonNullable<IThreadDoc["branchedFrom"]>;
  collapsePostEvents: Observable<string>;
  loadMorePostsButtonFocusEvents: Observable<void>;
  /** Posts for the first branch should be eagerly loaded and provided */
  firstBranchPosts: IObservePost[];
  renderPostEntry(args: {
    post: IObservePost;
    index: number;
    isFirstBranch: boolean;
    postsCount: number;
  }): JSX.Element;
}> = (props) => {
  const branchedFromThread = useThread(props.branchedFrom.threadId);

  if (branchedFromThread === null) {
    return <PermissionToViewAncestorThreadDeniedTimelineEntry />;
  }

  return (
    <>
      <div className="pl-8 border-l-[6px] border-slate-8 text-slate-9">
        <BranchedThreadPosts
          branchCreatedAt={props.branchCreatedAt}
          branchedFrom={props.branchedFrom}
          renderPostEntry={props.renderPostEntry}
          collapsePostEvents={props.collapsePostEvents}
          loadMorePostsButtonFocusEvents={props.loadMorePostsButtonFocusEvents}
          firstBranchPosts={props.firstBranchPosts}
        />
      </div>

      <BranchedAtTimelineEntry
        branchCreatedAt={props.branchCreatedAt}
        branchedFrom={props.branchedFrom}
        subject={branchedFromThread?.subject}
        isPrivate={branchedFromThread?.visibility === "private"}
        threadType={branchedFromThread?.type}
      />
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const BranchedThreadPosts: ComponentType<{
  branchCreatedAt: Timestamp | "now";
  branchedFrom: NonNullable<IThreadDoc["branchedFrom"]>;
  collapsePostEvents: Observable<string>;
  loadMorePostsButtonFocusEvents: Observable<void>;
  /** Posts for the first branch should be eagerly loaded and provided */
  firstBranchPosts?: IObservePost[];
  renderPostEntry: (args: {
    post: IObservePost;
    index: number;
    isFirstBranch: boolean;
    postsCount: number;
  }) => JSX.Element;
}> = (props) => {
  const isFirstBranch = !!props.firstBranchPosts;

  const [loadBranch, setLoadBranch] = useState(isFirstBranch);

  const thread = useThread(props.branchedFrom.threadId);

  const fetchedPosts = useBranchedThreadPosts(
    isFirstBranch ? undefined : props.branchedFrom,
  );

  const posts = props.firstBranchPosts || fetchedPosts;

  if (thread === null) {
    return <PermissionToViewAncestorThreadDeniedTimelineEntry />;
  }

  if (!loadBranch) {
    return (
      <div className="flex justify-center items-center my-8">
        <LoadMorePostsButton
          loadMorePostsButtonFocusEvents={props.loadMorePostsButtonFocusEvents}
          setLoadBranch={setLoadBranch}
        />
      </div>
    );
  }

  return (
    <>
      {thread?.branchedFrom && (
        <BranchedThreadPosts
          branchCreatedAt={thread.createdAt}
          branchedFrom={thread.branchedFrom}
          renderPostEntry={props.renderPostEntry}
          collapsePostEvents={props.collapsePostEvents}
          loadMorePostsButtonFocusEvents={props.loadMorePostsButtonFocusEvents}
        />
      )}

      {posts?.map((post, index) =>
        props.renderPostEntry({
          post,
          index,
          isFirstBranch,
          postsCount: posts.length,
        }),
      )}

      {!isFirstBranch && (
        <BranchedAtTimelineEntry
          branchCreatedAt={props.branchCreatedAt}
          branchedFrom={props.branchedFrom}
          subject={thread?.subject}
          isPrivate={thread?.visibility === "private"}
          threadType={thread?.type}
        />
      )}
    </>
  );
};

/* -------------------------------------------------------------------------------------------------
 * BranchedAtTimelineEntry
 * -----------------------------------------------------------------------------------------------*/

export interface IBranchedAtTimelineEntryLocalDoc
  extends NonNullable<IThreadDoc["branchedFrom"]> {
  __docType: "BranchedAtTimelineEntry";
  id: string;
}

const BranchedAtTimelineEntry: ComponentType<{
  branchCreatedAt: Timestamp | "now";
  branchedFrom: NonNullable<IThreadDoc["branchedFrom"]>;
  subject?: string;
  isPrivate?: boolean;
  threadType?: IThreadDoc["type"];
}> = memo((props) => {
  const branchCreatedAt = useFormatBranchCreationTime(props.branchCreatedAt);
  const doc = useBranchedAtTimelineEntryDoc(props.branchedFrom);

  return (
    <List.Entry<IBranchedAtTimelineEntryLocalDoc>
      id={doc.id}
      data={doc}
      onEntryAction={onBranchedAtTimelineEntrySelectNavigateToThread}
    >
      <div
        className={cx(
          "flex items-center py-6 my-6",
          "border-l-[3px] border-transparent bg-transparent",
          "focus:outline-none focus:border-black ",
          "hover:cursor-pointer hover:outline-none ",
          "focus:bg-white hover:bg-white",
          "focus:shadow-lg hover:shadow-lg",
        )}
      >
        <RiGitBranchLine size="2.5rem" className="ml-8 mr-4" />

        <div
          className={cx(
            "flex flex-col w-full px-4 sm-w:px-8",
            branchedAtTimelineEntryCss,
          )}
        >
          <strong>Branched {branchCreatedAt}</strong>

          <div className="text-sm font-medium flex items-center">
            {props.isPrivate && <BsLockFill className="mt-[1px] mr-1" />}
            From:{" "}
            {props.threadType === "EMAIL" && (
              <MdEmail className="ml-2 mr-1 shrink-0" size="1rem" />
            )}{" "}
            <span className="truncate">{props.subject}</span>
          </div>
        </div>
      </div>
    </List.Entry>
  );
}, isEqual);

const branchedAtTimelineEntryCss = css`
  width: calc(100% - 5.5rem);
`;

function useBranchedAtTimelineEntryDoc(
  branchedFrom: NonNullable<IThreadDoc["branchedFrom"]>,
) {
  return useMemo<IBranchedAtTimelineEntryLocalDoc>(() => {
    return {
      __docType: "BranchedAtTimelineEntry" as const,
      id: `BranchedAtTimelineEntry:${branchedFrom.threadId}`,
      ...branchedFrom,
    };
  }, [branchedFrom]);
}

function onBranchedAtTimelineEntrySelectNavigateToThread({
  entry,
  event,
}: IListOnEntryActionEvent<IBranchedAtTimelineEntryLocalDoc>) {
  const to = `/threads/${entry.threadId}?post=${entry.postId}`;

  if (isModKeyActive(event)) {
    openLinkInNewTabOrWindow(to);
  } else {
    navigateService(to);
  }
}

/* -----------------------------------------------------------------------------------------------*/

const PermissionToViewAncestorThreadDeniedTimelineEntry: ComponentType<{}> =
  memo(() => {
    return (
      <div className="flex items-center py-8">
        <div className="flex justify-center items-center rounded-full w-10 h-10 ml-6 mr-4 bg-current">
          <MdError size="1.5rem" className="text-white" />
        </div>

        <div className="flex flex-col">
          <strong>Permission Denied</strong>

          <span className="text-sm font-medium">
            You do not have permission to view quoted thread.
          </span>
        </div>
      </div>
    );
  }, isEqual);

/* -----------------------------------------------------------------------------------------------*/

function useFormatBranchCreationTime(timestamp?: Timestamp | "now") {
  return useMemo(() => {
    if (!timestamp) return "";
    if (timestamp === "now") return "now";

    const now = dayjs();
    const date = dayjs(timestamp.toDate());

    if (date.add(1, "week").isBefore(now)) {
      return date.format("[on] D/M/YYYY [at] h:mma");
    }

    return convertDateTimeToRelativeString_DayAtTime(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp?.valueOf()]);
}

/* -----------------------------------------------------------------------------------------------*/

export const LoadMorePostsButton: ComponentType<{
  loadMorePostsButtonFocusEvents: Observable<void>;
  setLoadBranch: (value: boolean) => void;
}> = (props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const listContext = useListContext();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const buttonEl = buttonRef.current!;

    const sub = props.loadMorePostsButtonFocusEvents.subscribe(() =>
      buttonEl.focus(),
    );

    const sub1 = fromEvent<KeyboardEvent>(buttonEl, "keydown").subscribe(
      (e) => {
        if (e.key !== "ArrowDown") return;
        // e.preventDefault();
        // e.stopPropagation();
        listContext.focus();
      },
    );

    sub.add(sub1);

    return () => sub.unsubscribe();
  }, [buttonRef, listContext, props.loadMorePostsButtonFocusEvents]);

  return (
    <OutlineButton
      ref={buttonRef}
      tabIndex={0}
      onClick={() => {
        listContext.entries$
          .pipe(skip(1), delay(1), take(1))
          .subscribe(() => listContext.focus());

        props.setLoadBranch(true);
      }}
    >
      Load More Posts
    </OutlineButton>
  );
};
