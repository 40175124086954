/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  from,
  FieldMergeFunction,
  FieldFunctionOptions,
  split,
  OperationVariables,
  DocumentNode,
  FieldReadFunction,
  WatchQueryOptions,
  ApolloQueryResult,
} from "@apollo/client";
import { auth } from "~/firebase";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { cloneDeep, getMainDefinition } from "@apollo/client/utilities";
import { Types } from "~/gql";
import { Writable } from "type-fest";
import { OperationDefinitionNode, OperationTypeNode } from "graphql";
import { Query_Root } from "~/gql/graphql";
import { defer, finalize, from as rxjsFrom, Observable, share } from "rxjs";
import { isEqual } from "@libs/utils/isEqual";

let getToken: () => Promise<{ token: string | null }>;

// TODO
// I checked the build output and this isn't being tree-shaken
// away. At time of writing, it appears as those tree shaking
// isn't working in vite for some reason
// https://github.com/vitejs/vite/issues/8464
if (import.meta.env.VITE_FIREBASE_EMULATORS === "true") {
  // Hasura currently requires the auth token to be signed.
  // In development, the Firebase Auth emulator doesn't
  // sign the auth tokens. Here we manually sign the tokens
  // ourself as a work-around.

  const secret = new TextEncoder().encode(
    "secretsecretsecretsecretsecretsecret",
  );

  getToken = async () => {
    const [jose, firebaseToken] = await Promise.all([
      import("jose"),
      auth.currentUser?.getIdTokenResult(),
    ]);

    if (!firebaseToken) return { token: null };

    // jose.SignJWT is complaining because token doesn't have an index signature
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const token = await new jose.SignJWT(firebaseToken as any)
      .setProtectedHeader({ alg: "HS256" })
      .sign(secret);

    console.debug("Hasura token", token);

    return { token };
  };
} else {
  getToken = async () => {
    return {
      token: (await auth.currentUser?.getIdToken()) || null,
    };
  };
}

const SHARED_QUERY_HEADERS = {
  /**
   * This header is used in the Hasura console for analytics. The client name
   * label is saved and associated with operation analytics in the Monitoring
   * section.
   */
  "Hasura-Client-Name": "web-app",
};

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_GRAPHQL_URI,
  // Note that the httpLink headers can be set via the
  // apollo link context, but the wsLink "connectionParams"
  // cannot.
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: import.meta.env.VITE_GRAPHQL_WS_URI,
    lazy: true,
    // This callback will be called when the websocket connection
    // is created. Annoyingly, the httpLink uses a context link
    // to do this but the wsLink needs to use this connectionParams
    // callback
    connectionParams: async () => {
      const { token } = await getToken();

      console.debug("wsLink Authorization", `Bearer ${token}`);

      return {
        headers: token
          ? { ...SHARED_QUERY_HEADERS, Authorization: `Bearer ${token}` }
          : { ...SHARED_QUERY_HEADERS, "X-Hasura-Role": `anonymous` },
      };
    },
  }),
);

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink,
);

const contextLink = setContext(getToken);

const authLink = new ApolloLink((operation, forward) => {
  const { token } = operation.getContext();

  console.debug("authLink Authorization", `Bearer ${token}`);

  operation.setContext(() => ({
    // the headers property is only used by the httpLink
    // the wsLink seems to ignore the link context
    // see https://github.com/apollographql/apollo-client/issues/9893#issuecomment-1182974884
    headers: token
      ? { ...SHARED_QUERY_HEADERS, Authorization: `Bearer ${token}` }
      : { ...SHARED_QUERY_HEADERS, "X-Hasura-Role": `anonymous` },
  }));

  return forward(operation);
});

type TApolloMergeFn = FieldMergeFunction<
  any,
  any,
  FieldFunctionOptions<Record<string, any>, Record<string, any>>
>;

// Merge the incoming list items with
// the existing list items.
const mergeFn: TApolloMergeFn = (
  existing,
  incoming,
  { args: { offset = 0 } }: any,
) => {
  console.debug("ApolloClient mergeFn: existing", existing);
  console.debug("ApolloClient mergeFn: incoming", incoming);
  const merged = existing ? existing.slice(0) : [];

  for (let i = 0; i < incoming.length; ++i) {
    merged[offset + i] = incoming[i];
  }

  console.debug("ApolloClient mergeFn: merged", merged);
  return merged;
};

export const apollo = new ApolloClient({
  uri: import.meta.env.VITE_GRAPHQL_URI,
  cache: new InMemoryCache({
    // Here we define how `fetchMore` results should be
    // handled as described here
    // https://www.apollographql.com/docs/react/pagination/core-api/#merging-paginated-results
    typePolicies: {
      ChannelGroupChannels: {
        keyFields: ["channelFirestoreId", "channelGroupFirestoreId"],
      },
      ChannelGroups: {
        keyFields: ["firestoreId"],
      },
      ChannelMembers: {
        keyFields: ["userFirestoreId", "channelFirestoreId"],
      },
      ChannelSubscriptions: {
        keyFields: ["userFirestoreId", "channelFirestoreId"],
      },
      Channels: {
        keyFields: ["firestoreId"],
      },
      DraftMentionedChannels: {
        keyFields: ["draftFirestoreId", "channelFirestoreId"],
      },
      DraftMentionedUsers: {
        keyFields: ["draftFirestoreId", "userFirestoreId"],
      },
      DraftRecipientChannels: {
        keyFields: ["draftFirestoreId", "channelFirestoreId"],
      },
      DraftRecipientUsers: {
        keyFields: ["draftFirestoreId", "userFirestoreId"],
      },
      Drafts: {
        keyFields: ["firestoreId"],
      },
      MessageMentionedChannels: {
        keyFields: ["messageFirestoreId", "channelFirestoreId"],
      },
      MessageMentionedUsers: {
        keyFields: ["messageFirestoreId", "userFirestoreId"],
      },
      MessageRecipientChannels: {
        keyFields: ["messageFirestoreId", "channelFirestoreId"],
      },
      MessageRecipientUsers: {
        keyFields: ["messageFirestoreId", "userFirestoreId"],
      },
      Messages: {
        keyFields: ["firestoreId"],
      },
      NotificationNewPosts: {
        keyFields: ["userFirestoreId", "threadFirestoreId"],
      },
      OrganizationMembers: {
        keyFields: ["userFirestoreId", "organizationFirestoreId"],
      },
      Organizations: {
        keyFields: ["firestoreId"],
      },
      ThreadChannelPermissions: {
        keyFields: ["threadFirestoreId", "channelFirestoreId"],
      },
      ThreadReadStatuses: {
        keyFields: ["threadFirestoreId", "userFirestoreId"],
      },
      ThreadSubscriptions: {
        keyFields: ["threadFirestoreId", "userFirestoreId"],
      },
      ThreadUserParticipations: {
        keyFields: ["threadFirestoreId", "userFirestoreId"],
      },
      ThreadUserPermissions: {
        keyFields: ["threadFirestoreId", "userFirestoreId"],
      },
      Threads: {
        keyFields: ["firestoreId"],
      },
      Users: {
        keyFields: ["firestoreId"],
      },
      Query: {
        fields: {
          searchMessages: {
            // Cache separate results based on
            keyArgs: ["args", "where", "orderBy"],

            // Merge the incoming list items with
            // the existing list items.
            merge: mergeFn,
          },
          messages: {
            keyArgs: ["where", "orderBy"],
            merge: mergeFn,
          },
          searchThreads: {
            keyArgs: ["args", "where", "orderBy"],
            merge: mergeFn,
          },
          threads: {
            keyArgs: ["where", "orderBy"],
            merge: mergeFn,
          },
          notificationNewPosts: {
            keyArgs: ["where", "orderBy"],
            // We want to be able to render optimistic updates in the UI. This
            // means that, if we manually update the cache, we want queries
            // for notificationNewPosts to immediately update as well. But
            // apollo doesn't natively understand the Hasura query syntax.
            // In order to get our optimistic rendering, we need to manually
            // instruct Apollo how to handle filtering cache results for this
            // query. But Hasura's query syntax is complex, and we don't want
            // to attempt fully recreating it here. Instead, we'll just support
            // what's needed for our optimistic rendering usecases (e.g. inbox
            // commands such as mark done, set reminder, star, etc).
            read: ((
              // Note, for some reason providing a default value for cached of
              // `cached: Query_Root["notificationNewPosts"] = []` causes
              // apollo to silently break, returning no results for queries to
              // this field. My best guess is that Apollo uses a response of
              // undefined as a trigger to fetch more data or something.
              // Regardless, apparently we can't use a default value in `read`
              // functions.
              cached: Query_Root["notificationNewPosts"] | undefined,
              options: FieldFunctionOptions<Types.Query_RootNotificationNewPostsArgs>,
            ) => {
              console.debug("Query notificationNewPosts: cached", cached);
              console.debug("Query notificationNewPosts: options", options);

              if (cached === undefined) return cached;

              const isDone = options.args?.where?.done?._eq;

              if (typeof isDone === "boolean") {
                cached = cached.filter((ref) => {
                  return options.readField("done", ref) === isDone;
                });
              }

              return cached;
            }) as FieldReadFunction,
            merge(existing, incoming, options) {
              console.debug("Query merge", existing, incoming, options);

              if (options.storage.cacheModify && existing) {
                delete options.storage.cacheModify;
                console.debug("cacheModify = true", existing);
                return [...existing];
                // The notificationNewPosts Hasura subscription is updating even when
                // nothing has changed. We guard for that here and return the existing data
                // to prevent unnecessary rerenders.
              } else if (isEqual(existing, incoming)) {
                return existing;
              }

              return incoming;
            },
          },
        },
      },
      Subscription: {
        fields: {
          notificationNewPosts: {
            read(cached, options) {
              console.debug(
                "Subscription notificationNewPosts: cached",
                cached,
              );
              console.debug(
                "Subscription notificationNewPosts: options",
                options,
              );

              return cached;
            },
            merge(existing, incoming, options) {
              console.debug("Subscription merge", existing, incoming, options);
              return incoming;
            },
          },
        },
      },
    },
  }),
  link: from([contextLink, authLink, splitLink]),
});

/**
 * Rather than simply use the apolloclient `subscribe` method, we need to use
 * the watchQuery method with the `subscribeToMore` option. This is because the
 * `subscribe` method (unlike watchQuery) ignores cache updates and won't
 * reemit when the underlying data in the cache is manually updated.
 */
export function observeLiveQuery<TVariables = OperationVariables, TData = any>(
  options: Omit<WatchQueryOptions<TVariables, TData>, "subscribeToMore">,
): Observable<ApolloQueryResult<TData>> {
  console.debug("observeLiveQuery", options);

  const query = apollo.watchQuery(options);

  const queryObservable = rxjsFrom(query) as Observable<
    ApolloQueryResult<TData>
  >;

  if (options?.fetchPolicy === "cache-only") {
    // When the fetchPolicy is "cache-only", the query should just return what's
    // in the cache. But Apollo has many bugs, and if we've manually updated the
    // cache to add a new result to the query, and then later we call that query with
    // "cache-only", Apollo client returns "undefined" (instead of the expected result).
    // On a hunch, I tried calling refetch() here, and it works. I don't know why.
    // I'll note that, in this scenerio, the Apollo devtools indicate the proper return
    // value for the query, even though watchQuery is returning undefined. This is
    // what gives me more confidence it's a bug and not user error.
    //
    // Note, originally I was calling refretch in a `useEffect()`. This implementation
    // doesn't use react hooks, but it's possible that we'll need to call refetch after
    // the query has been subscribed to. Here we're calling it _before_ anyone has subscribed
    // to the observable.
    // -- John 5/9/23
    query.refetch();

    return queryObservable;
  }

  const subscriptionDocument = buildSubscriptionFromQuery(options.query);

  let unsubscribe: () => void;

  return defer(() => {
    unsubscribe = query.subscribeToMore({
      document: subscriptionDocument,
      variables: options?.variables,
      onError(error) {
        console.error("Subscription error", error);
      },
      updateQuery(prev, { subscriptionData: { data: next } }) {
        console.debug("Subscription updateQuery", prev, next);
        if (!next) return prev;
        return next;
      },
    });

    return queryObservable;
  }).pipe(
    finalize(() => unsubscribe?.()),
    share({ resetOnRefCountZero: true }),
  );
}

// /**
//  * Rather than simply use the apolloclient `useSubscription` hook, we need to use
//  * the useQuery hook with the `subscribeToMore` option. This is because the
//  * `useSubscription` hook (unlike useQuery) ignores cache updates and won't
//  * reemit when the underlying data in the cache is manually updated.
//  */
// export function useSubscription<TData = any, TVariables = OperationVariables>(
//   query: DocumentNode | TypedDocumentNode<TData, TVariables>,
//   options?: Pick<
//     QueryHookOptions<TData, TVariables>,
//     "variables" | "fetchPolicy"
//   >,
// ) {
//   const queryResult = useQuery(query, options);

//   useEffect(() => {
//     if (options?.fetchPolicy === "cache-only") {
//       // When the fetchPolicy is "cache-only", the query should just return what's
//       // in the cache. But Apollo has many bugs, and if we've manually updated the
//       // cache to add a new result to the query, and then later we call that query with
//       // "cache-only", Apollo client silently crashes and returns "undefined". On
//       // a hunch, I tried calling refetch() here, and it works. I don't know why.
//       // I'll note that, in this scenerio, the Apollo devtools indicate the proper return
//       // value for the query, even though useQuery is returning undefined. This is
//       // what gives me more confidence it's a bug and not user error.
//       queryResult.refetch();
//       return;
//     }

//     const subscription = buildSubscriptionFromQuery(query);

//     const unsubscribe = queryResult.subscribeToMore({
//       document: subscription,
//       variables: options?.variables,
//       onError(error) {
//         console.error("Subscription error", error);
//       },
//       updateQuery(prev, { subscriptionData }) {
//         if (!subscriptionData.data) return prev;

//         return subscriptionData.data;
//       },
//     });

//     return unsubscribe;
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [query, options?.variables, options?.fetchPolicy]);

//   return queryResult;
// }

function buildSubscriptionFromQuery(query: DocumentNode) {
  const subscription = cloneDeep(query);

  if (
    subscription.definitions[0]?.kind !== "OperationDefinition" ||
    subscription.definitions[0]?.operation !== "query"
  ) {
    throw new Error(
      "The first operation in the query provided to useSubscription must be a query",
    );
  }

  (subscription.definitions[0] as Writable<OperationDefinitionNode>).operation =
    OperationTypeNode.SUBSCRIPTION;

  return subscription;
}
