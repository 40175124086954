import { ComponentType, useEffect, useMemo, useState } from "react";
import {
  useLastScheduledDeliveryDatetime,
  useNextScheduledDeliveryDatetime,
} from "~/services/inbox.service";
import { MdOutlineSchedule } from "react-icons/md";
import { Tooltip } from "~/components/Tooltip";
import dayjs from "dayjs";
import {
  AvailableLessonBadge,
  AVAILABLE_LESSON_BOX_SHADOW_PULSE,
  useIsTourInProgress,
  useLesson,
} from "~/services/lesson-service";
import {
  getClassForScheduledDeliveryTourStep,
  tour,
} from "../../services/lesson-service/lessons/scheduled-delivery-walkthrough";
import { cx } from "@emotion/css";
import { UnreachableCaseError } from "@libs/utils/errors";
import { convertDateTimeToRelativeString_DayAtTime } from "~/utils/time-formatting";
import { deliverMessagesNowCommand } from "~/utils/common-commands";

export const NextScheduledDeliveryHeader: ComponentType<{}> = () => {
  const lesson = useLesson(tour.lessonName);
  const isLessonInProgress = useIsTourInProgress(tour);
  const [deliverNowDialogEnabled, setDeliverNowDialogEnabled] = useState(false);

  useEffect(() => {
    const sub = tour.event$.subscribe((event) => {
      switch (event) {
        case "deliver-now-dialog-enabled": {
          setDeliverNowDialogEnabled(true);
          break;
        }
        case "deliver-now-dialog-disabled": {
          setDeliverNowDialogEnabled(false);
          break;
        }
        default: {
          throw new UnreachableCaseError(event);
        }
      }
    });

    return () => sub.unsubscribe();
  }, []);

  const {
    nextScheduledDeliveryTimeString,
    nextScheduledDeliveryInHours,
    lastScheduledDeliveryTimeString,
  } = useScheduledDeliveryTimes();

  const treatLessonAsCompleted =
    isLessonInProgress ||
    lesson === undefined ||
    (lesson?.version === tour.lessonVersion && lesson?.completed) ||
    deliverNowDialogEnabled;

  return (
    <div className="relative">
      <Tooltip
        side="bottom"
        content={
          !treatLessonAsCompleted ? (
            "Click to Learn about Scheduled Delivery"
          ) : (
            <>
              <span className="mb-2">Click to deliver messages now.</span>
              <span>
                Next scheduled delivery is {nextScheduledDeliveryTimeString}.
                Last delivery was {lastScheduledDeliveryTimeString}.
              </span>
            </>
          )
        }
      >
        <button
          type="button"
          className={cx(
            `flex items-center mr-2 rounded px-2 py-1`,
            getClassForScheduledDeliveryTourStep(20),
            treatLessonAsCompleted
              ? "text-slate-9 hover:bg-slate-5 hover:text-black active:bg-slate-5"
              : "text-violet-9 bg-violet-3 hover:bg-violet-5 active:bg-violet-7 font-medium " +
                  AVAILABLE_LESSON_BOX_SHADOW_PULSE,
          )}
          onClick={() => {
            if (treatLessonAsCompleted) {
              deliverMessagesNowCommand.trigger();
            } else {
              tour.start();
            }
          }}
        >
          <MdOutlineSchedule className="mr-1" />{" "}
          <span>{nextScheduledDeliveryInHours}</span>
        </button>
      </Tooltip>

      {!treatLessonAsCompleted && (
        <AvailableLessonBadge
          type="button"
          tooltip="Click to Learn about Scheduled Delivery"
          className="absolute -top-1 right-0"
          onClick={() => tour.start()}
        />
      )}
    </div>
  );
};

// This is an alternative version of the scheduled delivery header which
// we decided not to use. I'm keeping it as a comment for the time being
// since we may decide to use it in the future.
// -- John 1/4/23
//
// export const NextScheduledDeliveryHeader: ComponentType<{}> = () => {
//   const { nextScheduledDeliveryTimeString, lastScheduledDeliveryTimeString } =
//     useScheduledDeliveryTimes();
//
//   return (
//     <div className="flex items-center text-slate-9 text-sm">
//       <Tooltip
//         side="bottom"
//         content={`Last delivery ${lastScheduledDeliveryTimeString}`}
//       >
//         <span className="hover:cursor-help mr-2">
//           <MdOutlineSchedule />
//         </span>
//       </Tooltip>
//
//       <span>Next delivery {nextScheduledDeliveryTimeString} (</span>
//
//       <button
//         type="button"
//         className="hover:underline"
//         onClick={() => {
//           DeliverMessagesNowDialogState.toggle(true);
//         }}
//       >
//         deliver now
//       </button>
//
//       <span>)</span>
//     </div>
//   );
// };

function useScheduledDeliveryTimes() {
  const lastScheduledDeliveryDatetime = useLastScheduledDeliveryDatetime();

  const lastScheduledDeliveryTimeString = useMemo(() => {
    if (!lastScheduledDeliveryDatetime) return;

    return convertDateTimeToRelativeString_DayAtTime(
      lastScheduledDeliveryDatetime,
    );
  }, [lastScheduledDeliveryDatetime]);

  const nextScheduledDeliveryDatetime = useNextScheduledDeliveryDatetime();

  const nextScheduledDeliveryTimeString = useMemo(() => {
    if (!nextScheduledDeliveryDatetime) return;

    return convertDateTimeToRelativeString_DayAtTime(
      nextScheduledDeliveryDatetime,
    );
  }, [nextScheduledDeliveryDatetime]);

  const nextScheduledDeliveryInHours = useMemo(() => {
    if (!nextScheduledDeliveryDatetime) return;

    return convertDateTimeToConcise_DayAtTime(nextScheduledDeliveryDatetime);
  }, [nextScheduledDeliveryDatetime]);

  return {
    nextScheduledDeliveryDatetime,
    nextScheduledDeliveryTimeString,
    nextScheduledDeliveryInHours,
    lastScheduledDeliveryDatetime,
    lastScheduledDeliveryTimeString,
  };
}

/**
 * Formats datetime like "at 10am" if same day else formats
 * like "Wed at 10am".
 */
function convertDateTimeToConcise_DayAtTime(datetime: dayjs.Dayjs) {
  if (!datetime) return;

  const now = dayjs();
  const timeFormat = datetime.get("minutes") === 0 ? "ha" : "h:mma";

  if (now.isSame(datetime, "day")) {
    return datetime.format(`[at] ${timeFormat}`);
  }

  return datetime.format(`ddd [at] ${timeFormat}`);
}
