import { FcGoogle } from "react-icons/fc";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  signOut,
  User,
  createUserWithEmailAndPassword,
  signInWithCustomToken,
  linkWithPopup,
} from "firebase/auth";
import {
  useLocation,
  Navigate,
  NavigateFunction,
  useNavigate,
  Location,
} from "react-router-dom";
import { auth, functions } from "../../firebase";
import { getDoc } from "firebase/firestore";
import { docRef } from "~/firestore.service";
import { CURRENT_USER$ } from "~/services/user.service";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { ComponentType } from "react";
import { useObservable } from "~/utils/useObservable";
import { setIsLoading } from "~/services/loading.service";
import { Helmet } from "react-helmet-async";
import {
  NewUserDialog,
  NewUserDialogState,
} from "~/dialogs/user-new/NewUserDialog";
import { LoadingText } from "~/components/LoadingText";
import { PendingRequestBar } from "~/components/PendingRequestBar";
import { attemptToGetRedirectLocation } from "~/utils/navigation-helpers";
import { withIsOnlineGuard } from "~/route-guards/withIsOnlineGuard";
import { withAPIVersionGuard } from "~/route-guards/withApiVersionGuard";
import { httpsCallable } from "firebase/functions";

export const LoginView: ComponentType<{}> = withIsOnlineGuard(
  withAPIVersionGuard(() => {
    const currentUser = useObservable(() => CURRENT_USER$, {
      initialValue: "loading" as const,
    });

    const navigate = useNavigate();
    const location = useLocation();
    const redirectTo = attemptToGetRedirectLocation(location);

    if (currentUser === "loading") {
      return (
        <PendingRequestBar>
          <LoadingText />
        </PendingRequestBar>
      );
    }

    if (currentUser) {
      return (
        <Navigate
          to={
            !redirectTo || redirectTo.pathname.startsWith("/login")
              ? "/inbox"
              : redirectTo
          }
          replace
        />
      );
    }

    return (
      <div className="h-dynamic-screen w-screen flex flex-col justify-center items-center space-y-10">
        <Helmet>
          <title>Login | Comms</title>
        </Helmet>

        <NewUserDialog />

        <h1 className="text-4xl font-bold">Login to Comms</h1>

        <button
          className="flex rounded-md px-8 py-4 bg-tealDark-6 text-white items-center space-x-3"
          onClick={() => signinWithGoogle(navigate, redirectTo)}
        >
          <FcGoogle /> <span>Login with Google</span>
        </button>
      </div>
    );
  }),
);

const signinWithGoogle = onlyCallFnOnceWhilePreviousCallIsPending(
  setIsLoading(async (navigate: NavigateFunction, redirectTo?: Location) => {
    // Set language to the default browser preference
    auth.useDeviceLanguage();

    let user: User | null;

    if (import.meta.env.VITE_IMPERSONATE_USER_CODE) {
      // Only used for debugging purposes with permission from Sam
      user = await impersonateUser();

      // if (user) {
      //   await linkExistingUserToGoogleAccount(user);
      // } else {
      //   alert(
      //     `Something went wrong. Failed to signin and, hence, failed to link user account.`,
      //   );
      // }
    } else if (
      import.meta.env.VITE_FIREBASE_EMULATORS === "true" &&
      !navigator.onLine
    ) {
      // We don't use the `signInWithPopup` method in the emulator
      // since it tries to fetch the `gapi` API asyncronously and will
      // error when offline.
      const email = prompt(
        "Choose an email address",
        "olive.grass@example.com",
      );

      if (!email) return;

      const password = "password";

      user = await signInWithEmailAndPassword(auth, email, password)
        .catch((e) => {
          if (e.code === "auth/user-not-found") {
            return createUserWithEmailAndPassword(auth, email, password);
          }

          throw e;
        })
        .then((c) => c.user)
        .catch((e) => {
          console.warn(JSON.stringify(e));
          console.warn(e);
          return null;
        });
    } else {
      user = await signInWithPopup(auth, new GoogleAuthProvider())
        .then((c) => c.user)
        .catch((e) => {
          console.warn(e);
          return null;
        });
    }

    if (!user) return;

    const userSnap = await getDoc(docRef("users", user.uid));

    if (userSnap.exists()) {
      // See the comments on the IUserDoc#retired prop for more info
      if (userSnap.data().retired) {
        await signOut(auth);
        return;
      }

      navigate(redirectTo || "/inbox", { replace: true });
      return;
    }

    NewUserDialogState.toggle(true, {
      name: user.displayName,
      email: user.email,
      phoneNumber: user.phoneNumber,
    });
  }),
);

// Only used for debugging purposes with permission from Sam
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function impersonateUser() {
  const { data } = await httpsCallable<{ code: string }, { token: string }>(
    functions,
    "impersonateuser",
  )({ code: import.meta.env.VITE_IMPERSONATE_USER_CODE });

  const credential = await signInWithCustomToken(auth, data.token);

  console.warn("credential", credential);

  return credential.user;
}

/**
 * This function initiates a "Sign in with Google" popup and then
 * links the account which the user signs into with the provided
 * user account.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function linkExistingUserToGoogleAccount(existingUser: User) {
  await linkWithPopup(existingUser, new GoogleAuthProvider());
}
