import { css, cx } from "@emotion/css";
import type { Timestamp } from "@firebase/firestore-types";
import { UnreachableCaseError } from "@libs/utils/errors";
import { isEqual } from "@libs/utils/isEqual";
import { blueA } from "@radix-ui/colors";
import dayjs from "dayjs";
import { ComponentType, CSSProperties, memo, ReactNode } from "react";
import { BsFillStarFill, BsLockFill } from "react-icons/bs";
import { distinctUntilChanged, map } from "rxjs";
import { WINDOW_SIZE$ } from "~/services/window.service";
import { useObservable } from "~/utils/useObservable";
import { Tooltip } from "../Tooltip";

// We need to use CSS classes combined with an `<li>`
// element (rather than a react component) because the
// `<Slot>` component doesn't appear to support providing
// a React component as a child (not even a forwardRef).
export const entryCSSClasses = cx(
  `
    flex gap-4 items-center h-12 
    px-4 sm-w:px-8 md-w:px-12
    hover:cursor-pointer border-l-2 
    border-white outline-none focus:bg-slate-4 
    focus:border-black group
  `,
  css`
    &.is-checked {
      background-color: ${blueA.blueA7};
      border-color: ${blueA.blueA7};
    }

    &.is-checked:focus {
      background-color: ${blueA.blueA7};
      border-color: black;
    }
  `,
);

export const Recipients: ComponentType<{
  style?: CSSProperties;
  className?: string;
  nonTruncatedPrefix?: ReactNode;
  nonTruncatedSuffix?: ReactNode;
}> = (props) => {
  return (
    <div
      className={cx("EntryRecipients flex items-center pr-4", props.className)}
      style={{ width: 168, ...props.style }}
    >
      {props.nonTruncatedPrefix}
      <span className="truncate">{props.children}</span>
      {props.nonTruncatedSuffix}
    </div>
  );
};

export const Summary: ComponentType<{
  subject?: string | null;
  reply?: boolean;
  details?: string | null;
}> = (props) => {
  // These styles were built after inspecting the styles that Gmail uses
  // to display inbox entries. The combination is necessary to support
  // first truncating the body and then truncating the subject if
  // necessary.
  return (
    <div className="flex items-center flex-[3_3_0%] min-w-0">
      {props.subject && (
        <div className="EntrySummary-subject inline-flex shrink whitespace-nowrap overflow-hidden">
          <span className="truncate mr-4">{props.subject}</span>
        </div>
      )}

      <span className="EntrySummary-details text-slateA-9 truncate flex-1">
        {props.details}
      </span>
    </div>
  );
};

export const PrivateEntryIcon: ComponentType<{}> = () => {
  return (
    <span className="inline-flex shrink-0 ml-2">
      <small>
        <BsLockFill />
      </small>
    </span>
  );
};

export const StarredEntryIcon: ComponentType<{}> = () => {
  return (
    <span className="inline-flex shrink-0 ml-2">
      <small>
        <BsFillStarFill className="text-yellow-10 stroke-yellow-11 stroke-[0.5]" />
      </small>
    </span>
  );
};

export const EntryTimestamp: ComponentType<{
  datetime: Timestamp;
  /** default is "sm" */
  size?: "sm" | "md" | "lg";
  alwaysShowTime?: boolean;
}> = (props) => {
  return (
    <div className="flex items-center text-sm ml-4">
      <span className="text-slateA-9 uppercase">
        <DisplayDate
          date={props.datetime}
          size={props.size}
          alwaysShowTime={props.alwaysShowTime}
        />
      </span>
    </div>
  );
};

export const DisplayDate: ComponentType<{
  date: Timestamp;
  /** default is "sm" */
  size?: "sm" | "md" | "lg";
  alwaysShowTime?: boolean;
  className?: string;
}> = memo((props) => {
  const date = dayjs(props.date.toDate());
  const now = dayjs();

  if (date.diff(now, "years") > 50) {
    return (
      <Tooltip
        side="left"
        content="This date will never arrive"
        delayDuration={500}
      >
        <span className={props.className}>never</span>
      </Tooltip>
    );
  }

  const formatStr = datetimeFormatStr(date, props.size, props.alwaysShowTime);

  return (
    <Tooltip side="left" content={date.format("LLLL")} delayDuration={500}>
      <time dateTime={date.toISOString()} className={props.className}>
        {date.format(formatStr)}
      </time>
    </Tooltip>
  );
}, isEqual);

/**
 * @returns a formatting string suitable for passing to `Dayjs#format()`
 */
function datetimeFormatStr(
  date: dayjs.Dayjs,
  size: "sm" | "md" | "lg" = "sm",
  alwaysShowTime = false,
) {
  let formatStr: string;
  const now = dayjs();

  switch (size) {
    case "lg": {
      if (date.isSame(now, "date")) {
        return "[today at] h:mm A";
      }

      formatStr = date.isSame(now, "year")
        ? "dddd, MMMM D"
        : "dddd, MMMM D, YYYY";

      break;
    }
    case "md": {
      if (date.isSame(now, "date")) {
        return "h:mm A";
      }

      formatStr = date.isSame(now, "year") ? "ddd, MMM D" : "ddd, M/D/YY";

      break;
    }
    case "sm": {
      if (date.isSame(now, "date")) {
        return "h:mm A";
      }

      formatStr = date.isSame(now, "year") ? "MMM D" : "M/D/YY";

      break;
    }
    default: {
      throw new UnreachableCaseError(size);
    }
  }

  if (alwaysShowTime) {
    formatStr += ", h:mm A";
  }

  return formatStr;
}

export function useShowChannelLabels() {
  return useObservable(
    () => {
      return WINDOW_SIZE$.pipe(
        map(({ width }) => width >= 800),
        distinctUntilChanged(),
      );
    },
    {
      synchronous: true,
    },
  );
}
