import { memo } from "react";
import { isEqual } from "@libs/utils/isEqual";
import {
  entryCSSClasses,
  Summary,
  Recipients,
  NotificationTimestamp,
} from "~/components/content-list";
import { List } from "~/components/list";
import { BsLockFill } from "react-icons/bs";
import {
  ChannelLabels,
  LabelChip,
  useThreadChannelNames,
} from "~/components/ChannelLabels";
import { useInboxNotification } from "~/services/inbox.service";
import { IPostDoc, IThreadDoc } from "@libs/firestore-models";
import { getPostSenderName } from "@libs/firestore-models/utils";

// interface IThreadSearchResultMessageSender {
//   firestoreId: string;
//   name: string;
// }

// export interface IThreadSearchResultEntry {
//   id: string;
//   firestoreId: string;
//   visibility: string;
//   subject: string;

//   // may be undefined if the thread syncs to postgres before
//   // the associated message
//   firstMessage?: {
//     firestoreId: string;
//     // We've had occasion to delete a few user docs in the past.
//     // It's possible that a post's sender no longer exists.
//     sender?: IThreadSearchResultMessageSender;
//   };

//   // may be undefined if the thread syncs to postgres before
//   // the associated message
//   lastMessage?: {
//     sentAt: string;
//     scheduledToBeSentAt: string;
//     bodyText: string;
//   };

//   distinctSenders: IThreadSearchResultMessageSender[];

//   // messages: Array<{
//   //   firestoreId: string;
//   //   bodyText: string;
//   //   bodyHtml: string;
//   //   sentAt: string;
//   //   scheduledToBeSentAt: string;

//   //   // We've had occasion to delete a few user docs in the past.
//   //   // It's possible that a post's sender no longer exists.
//   //   sender?: IThreadSearchResultMessageSender;
//   // }>;

//   inboxNotifications: Array<{
//     hasReminder: boolean;
//     remindAt: string | null;
//     doneLastModifiedBy: IAbstractNotificationDoc["doneLastModifiedBy"];
//   }>;
// }

// export const ThreadSearchResultEntry = memo<{
//   thread: IThreadSearchResultEntry;
//   relativeOrder: number;
// }>((props) => {
//   const knownRecipientChannels = useThreadChannelNames(props.thread.id);

//   const isPrivateThread = props.thread.visibility === "PRIVATE";

//   const notification = props.thread.inboxNotifications[0];

//   const senderIds = new Map<string, string>();

//   if (props.thread.firstMessage?.sender?.firestoreId) {
//     senderIds.set(
//       props.thread.firstMessage.sender.firestoreId,
//       props.thread.firstMessage.sender.name,
//     );
//   }

//   props.thread.distinctSenders
//     .slice()
//     .reverse()
//     .forEach((s) => senderIds.set(s.firestoreId, s.name));

//   const senders = Array.from(senderIds.values())
//     .map((name) => name.split(" ").at(0))
//     .join(", ");

//   return (
//     <>
//       <List.Entry<IThreadSearchResultEntry>
//         id={props.thread.firestoreId}
//         data={props.thread}
//         relativeOrder={props.relativeOrder}
//       >
//         <div className={entryCSSClasses}>
//           <Recipients
//             nonTruncatedSuffix={
//               isPrivateThread && (
//                 <span className="inline-flex ml-2">
//                   <small>
//                     <BsLockFill />
//                   </small>
//                 </span>
//               )
//             }
//           >
//             {senders}
//           </Recipients>

//           <Summary
//             subject={props.thread.subject}
//             details={
//               isPrivateThread
//                 ? "private message"
//                 : props.thread.lastMessage?.bodyText
//             }
//           />

//           {knownRecipientChannels && (
//             <ChannelLabels channels={knownRecipientChannels} />
//           )}

//           {props.thread.lastMessage && (
//             <NotificationTimestamp
//               notification={{
//                 doneLastModifiedBy:
//                   notification?.doneLastModifiedBy || "system",
//                 sentAt: Timestamp.fromDate(
//                   new Date(props.thread.lastMessage.sentAt + "Z"),
//                 ),
//                 triagedUntil:
//                   (notification?.remindAt &&
//                     Timestamp.fromDate(
//                       new Date(notification?.remindAt + "Z"),
//                     )) ||
//                   null,
//               }}
//             />
//           )}
//         </div>
//       </List.Entry>

//       {/* {props.thread.messages.map((message, index) => (
//         <Message
//           key={message.firestoreId}
//           message={message}
//           relativeOrder={index}
//           isPrivateThread={props.thread.visibility === "PRIVATE"}
//         />
//       ))} */}
//     </>
//   );
// }, isEqual);

export const SearchResultEntry = memo<{
  thread: IThreadDoc & { __local: { fromPost: IPostDoc } };
  relativeOrder: number;
}>((props) => {
  const { thread } = props;
  const notification = useInboxNotification(thread.id);
  const knownRecipientChannels = useThreadChannelNames(thread.id);
  const isPrivateThread = thread.visibility === "private";
  const senderName = getPostSenderName(thread.__local.fromPost) || "unknown";

  return (
    <List.Entry<IThreadDoc>
      id={thread.id}
      data={thread}
      relativeOrder={props.relativeOrder}
    >
      <div className={entryCSSClasses}>
        <Recipients
          nonTruncatedSuffix={
            isPrivateThread && (
              <span className="inline-flex ml-2">
                <small>
                  <BsLockFill />
                </small>
              </span>
            )
          }
        >
          {senderName}
        </Recipients>

        <Summary
          subject={thread.subject}
          details={
            isPrivateThread
              ? "private message"
              : thread.__local.fromPost.bodyText
          }
        />

        {knownRecipientChannels && (
          <ChannelLabels channels={knownRecipientChannels} />
        )}

        {thread.type.startsWith("EMAIL") && (
          <LabelChip tooltip="This is an email thread">#Email</LabelChip>
        )}

        <NotificationTimestamp
          notification={{
            doneLastModifiedBy: notification?.doneLastModifiedBy || "system",
            sentAt: thread.__local.fromPost.sentAt,
            triagedUntil: notification?.triagedUntil || null,
          }}
        />
      </div>
    </List.Entry>
  );
}, isEqual);
