import { UnreachableCaseError } from "@libs/utils/errors";
import { useLocation } from "react-router-dom";
import { firstValueFrom, map, Observable, of } from "rxjs";
import { useObservable } from "~/utils/useObservable";
import { IDraftWithThreadData } from "./draft.service";
import {
  INotificationWithLocalAndDraftData,
  observeInboxSectionNotificationsAndDrafts,
} from "./inbox.service";
import { getLocationState, ILocation } from "./navigate.service";
import { IObserveThread } from "./post.service";

/* -------------------------------------------------------------------------------------------------
 * thread-prev-next.service
 * -------------------------------------------------------------------------------------------------
 *
 * This service is responsible for managing what the previous/next buttons on the ThreadView do.
 */

/** State saved to `router.location.state`. */

export interface IBaseThreadListNavigationLocationState {
  origin: string;
}

export interface IThreadListNavigationLocationStateInboxView
  extends IBaseThreadListNavigationLocationState {
  origin: "InboxView";
  inboxSectionId: string;
}

export type IThreadListNotificationLocationState =
  IThreadListNavigationLocationStateInboxView;

export interface IThreadListNavigationArgs {
  readonly previousUrl: string | null;
  readonly nextUrl: string | null;
  readonly state: {
    readonly ["ThreadListNavigationLocationState"]: IBaseThreadListNavigationLocationState;
  };
}

export function getThreadListNavigationLocationState<
  T extends IBaseThreadListNavigationLocationState = IThreadListNotificationLocationState,
>(location?: ILocation) {
  return getLocationState<T>("ThreadListNavigationLocationState", location);
}

export function useThreadViewPrevNextUrl(
  entry: IDraftWithThreadData | IObserveThread,
) {
  const location = useLocation();

  return useObservable(() => observeThreadViewPrevNextUrl(entry, location), {
    deps: [location, entry],
  });
}

export function getThreadViewPrevNextUrl(
  entry: IDraftWithThreadData | IObserveThread,
  location: ILocation,
): Promise<IThreadListNavigationArgs | null> {
  return firstValueFrom(observeThreadViewPrevNextUrl(entry, location));
}

export function observeThreadViewPrevNextUrl(
  entry: IDraftWithThreadData | IObserveThread,
  location: ILocation,
): Observable<IThreadListNavigationArgs | null> {
  const state = getThreadListNavigationLocationState(location);

  if (!state?.origin) return of(null);

  switch (state.origin) {
    case "InboxView": {
      return observeThreadViewInboxPrevNextUrl({
        entry,
        historyState: state,
      });
    }
    default: {
      throw new UnreachableCaseError(state.origin);
    }
  }
}

function observeThreadViewInboxPrevNextUrl(args: {
  entry: IDraftWithThreadData | IObserveThread;
  historyState: IThreadListNavigationLocationStateInboxView;
}): Observable<IThreadListNavigationArgs> {
  return observeInboxSectionNotificationsAndDrafts(
    args.historyState.inboxSectionId,
  ).pipe(
    map(([drafts, notificationGroups]) => [
      ...drafts,
      ...notificationGroups.flatMap((group) => group.notificationDocs),
    ]),
    map((list) => {
      const currentIndex = list.findIndex((entry) => {
        switch (entry.__docType) {
          case "INotificationDoc": {
            return entry.id === args.entry.id;
          }
          case "IUnsafeDraftDoc": {
            if (entry.isFirstPostInThread) {
              return entry.id === args.entry.id;
            }

            // In this case (e.g. because we have the blocking
            // inbox enabled), the `entry` passed to
            // `observeThreadViewInboxPrevNextUrl` will be for a thread doc but
            // the entry in the `list` variable will be for a draft doc.
            return entry.threadId === args.entry.id;
          }
          default: {
            throw new UnreachableCaseError(entry as never);
          }
        }
      });

      if (currentIndex < 0) {
        return {
          previousUrl: null,
          nextUrl: null,
          state: {
            ["ThreadListNavigationLocationState"]: args.historyState,
          },
        };
      }

      const previousEntry = list[currentIndex - 1];
      const nextEntry = list[currentIndex + 1];

      return {
        previousUrl: previousEntry ? convertEntryToURL(previousEntry) : null,
        nextUrl: nextEntry ? convertEntryToURL(nextEntry) : null,
        state: {
          ["ThreadListNavigationLocationState"]: args.historyState,
        },
      };
    }),
  );
}

function convertEntryToURL(
  entry: IDraftWithThreadData | INotificationWithLocalAndDraftData,
) {
  switch (entry.__docType) {
    case "IUnsafeDraftDoc": {
      if (entry.isFirstPostInThread) {
        return `${location.pathname}?compose=${entry.id}`;
      }

      return `/threads/${entry.threadId}`;
    }
    case "INotificationDoc": {
      switch (entry.type) {
        case "new-post": {
          return `/threads/${entry.id}`;
        }
        default: {
          throw new UnreachableCaseError(entry);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(entry);
    }
  }
}
