import { IPostDoc, IThreadDoc, ThreadVisibility } from "@libs/firestore-models";
import {
  IEditorMention,
  IPostEditorControl,
} from "~/form-components/post-editor";
import {
  IEmailRecipientOption,
  IRecipientOption,
} from "~/form-components/ThreadRecipients";
import {
  ContainerControls,
  createFormControl,
  createFormGroup,
} from "solid-forms-react";
import { useEffect } from "react";
import { isWindowFocused, WINDOW_FOCUSED$ } from "~/services/focus.service";
import {
  combineLatest,
  filter,
  map,
  NEVER,
  pairwise,
  skip,
  Subject,
  switchMap,
} from "rxjs";
import { observable } from "~/form-components/utils";
import {
  deleteDraft,
  getDraftDataStorageKey,
  IDraft,
  observeDraft,
} from "~/services/draft.service";
import { sessionStorageService } from "~/services/session-storage.service";
import { PendingUpdates } from "~/services/loading.service";
import { debounce, differenceBy } from "lodash-es";
import { ALL_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$ } from "~/services/organization.service";
import { USER_CHANNELS$ } from "~/services/channels.service";
import { isNonNullable } from "@libs/utils/predicates";
import { UnreachableCaseError } from "@libs/utils/errors";

export interface IComposeMessageFormValue {
  postId: IDraft["id"];
  type: IDraft["type"];
  branchedFrom: null | {
    threadId: IThreadDoc["id"];
    postId: IPostDoc["id"];
    postSentAt: IPostDoc["sentAt"];
    postScheduledToBeSentAt: IPostDoc["scheduledToBeSentAt"];
  };
  recipients: {
    to: IRecipientOption[];
    cc: IRecipientOption[];
    bcc: IEmailRecipientOption[];
  };
  visibility: ThreadVisibility | null;
  subject: NonNullable<IDraft["newThread"]>["subject"];
  body: {
    content: IDraft["bodyHTML"];
    userMentions: IEditorMention[];
    channelMentions: IEditorMention[];
  };
}

export const SEND_MESSAGE_COMMAND_ID = "SEND_MESSAGE";
export const CLOSE_DRAFT_COMMAND_ID = "CLOSE_DRAFT";

export type IComposeMessageForm = ReturnType<typeof createComposeMessageForm>;

export function createComposeMessageForm(
  initialFormValues: IComposeMessageFormValue,
  options: { recipientsRequired?: boolean } = {},
) {
  const branchedFrom = !initialFormValues.branchedFrom
    ? createFormControl(null)
    : createFormGroup({
        threadId: createFormControl(initialFormValues.branchedFrom.threadId),
        postId: createFormControl(initialFormValues.branchedFrom.postId),
        postSentAt: createFormControl(
          initialFormValues.branchedFrom.postSentAt,
        ),
        postScheduledToBeSentAt: createFormControl(
          initialFormValues.branchedFrom.postScheduledToBeSentAt,
        ),
      });

  return createFormGroup(
    {
      postId: createFormControl<string>(initialFormValues.postId),
      type: createFormControl(initialFormValues.type),
      branchedFrom,
      recipients: createFormGroup(
        {
          to: createFormControl(initialFormValues.recipients.to, {
            data: {
              focus: new Subject<void>(),
            },
          }),
          cc: createFormControl(initialFormValues.recipients.cc, {
            data: {
              focus: new Subject<void>(),
            },
          }),
          bcc: createFormControl(initialFormValues.recipients.bcc, {
            data: {
              focus: new Subject<void>(),
            },
          }),
        },
        {
          required: !!options.recipientsRequired,
        },
      ),
      visibility: createFormControl(initialFormValues.visibility, {
        required: true,
      }),
      subject: createFormControl(initialFormValues.subject, {
        required: true,
        data: {
          focus: new Subject<void>(),
        },
      }),
      body: createFormGroup<
        ContainerControls<IPostEditorControl["controls"]["body"]>
      >({
        content: createFormControl(initialFormValues.body.content, {
          required: true,
        }),
        userMentions: createFormControl<IEditorMention[]>(
          initialFormValues.body.userMentions,
        ),
        channelMentions: createFormControl<IEditorMention[]>(
          initialFormValues.body.channelMentions,
        ),
        // Currently in the NewThreadForm component, we only care about
        // changes to the mentionsCount. For this reason, it's fine for us
        // to initialize the control with an incorrect value. In the future
        // we might need to refactor this. See the JSDoc comment on the
        // IPostEditorControl["controls"]["body"]["possiblyIncorrectMentionsCount"]
        // type for more info.
        possiblyIncorrectMentionsCount: createFormControl(0),
      }),
    },
    {
      validators: !options.recipientsRequired
        ? undefined
        : (v: IComposeMessageFormValue) => {
            switch (v.type) {
              case "COMMS": {
                return v.recipients.to.length > 0 || v.recipients.cc.length > 0
                  ? null
                  : { required: "recipients" };
              }
              case "EMAIL": {
                const isNonChannelRecipient = (r: IRecipientOption) =>
                  r.type === "user" || r.type === "email";

                return v.recipients.to.some(isNonChannelRecipient) ||
                  v.recipients.cc.some(isNonChannelRecipient)
                  ? null
                  : { required: "recipients" };
              }
              default: {
                throw new UnreachableCaseError(v.type);
              }
            }
          },
    },
  );
}

export function useAutosaveDraft(args: {
  control: IComposeMessageForm;
  saveDraft: (values: IComposeMessageFormValue) => void;
  cancelSaveDraft: (postId: string) => void;
}) {
  const { control, saveDraft, cancelSaveDraft } = args;

  // Periodically save drafts if the window is focused
  useEffect(() => {
    const sub = WINDOW_FOCUSED$.pipe(
      switchMap((isFocused) =>
        !isFocused ? NEVER : observable(() => control.rawValue).pipe(skip(1)),
      ),
    ).subscribe((value) => {
      saveDraft(value);
    });

    return () => sub.unsubscribe();
  }, [saveDraft, control]);

  // When we navigate away from this draft,
  // delete the draft if it's empty
  useEffect(() => {
    let draft: IDraft | null = null;

    // If the user has the same draft editor open in two different browsers
    // (e.g. Chrome and Safari), and the draft is blank in both browsers,
    // the other browser will not update with content as the
    // user types. This is intended, as the draft editor ignores updates
    // to the draft doc while open and, since the user is editing in a
    // different browser, there is no way to sync changes via our session
    // store service. Because of this, if the user sends the post then it
    // will cause the draft editor in the other browser to
    // close, causing this useEffect onUnmount callback to run, potentially
    // resulting in the draft being deleted and unsent. SOOooooo, here we
    // subscribe directly to the Firestore draft document. Fortunately,
    // this subscription updates faster than React so, when the unmount
    // callback is run, it has an up-to-date draft doc and can check to
    // see if we're closing the editor because the draft has been sent.
    // If we are, then we don't delete the draft
    const sub = observable(() => control.rawValue.postId)
      .pipe(switchMap((postId) => observeDraft(postId)))
      .subscribe((d) => {
        draft = d;
      });

    return () => {
      sub.unsubscribe();

      if (!isWindowFocused()) return;
      if (draft?.scheduledToBeSent) return;

      const content = draft?.bodyHTML || control.rawValue.body.content;

      const isEmptyDraft =
        (!content.trim() || content === "<p></p>") &&
        control.rawValue.recipients.to.length === 0 &&
        control.rawValue.recipients.cc.length === 0 &&
        !control.rawValue.subject.trim();

      if (isEmptyDraft) {
        console.debug("Editor close: deleting draft because it's empty...");
        cancelSaveDraft(control.rawValue.postId);
        deleteDraft(control.rawValue);
        sessionStorageService.deleteItem(
          getDraftDataStorageKey(control.rawValue.postId),
        );
      }
    };
  }, [control, cancelSaveDraft]);
}

export function getSaveDraftFns(
  debouncedSaveFn: (
    this: { pendingDebouncePostId: string | null },
    values: IComposeMessageFormValue,
  ) => IComposeMessageFormValue,
) {
  const context = {
    // Used to track if we currently have a pending call to our debounced
    // save function and, if so, what the postId associated with that
    // call is.
    pendingDebouncePostId: null as string | null,
  };

  function saveDraft(args: IComposeMessageFormValue) {
    // if the user edits one draft and then quickly moves to another,
    // we want to save the previous draft immediately before attempting
    // to save the new one
    if (
      context.pendingDebouncePostId &&
      context.pendingDebouncePostId !== args.postId
    ) {
      saveReplyDraftDebouncedFn.flush();
    }

    context.pendingDebouncePostId = args.postId;
    PendingUpdates.add(args.postId);
    return saveReplyDraftDebouncedFn(args);
  }

  function cancelSaveDraft(postId: string) {
    context.pendingDebouncePostId = null;
    saveReplyDraftDebouncedFn.cancel();
    PendingUpdates.remove(postId);
  }

  // We're using lodash for debounce instead of rxjs to ensure that
  // the function is called even if the component is destroyed.
  // If we were using rxjs, the observable would be unsubscribed from
  // when the component unmounted and the save would never happen.
  // (admittedly, we could work around this, but this approach seemed
  // easier)
  const saveReplyDraftDebouncedFn = debounce(
    debouncedSaveFn.bind(context),
    1500,
    {
      maxWait: 5000,
      trailing: true,
    },
  );

  return [saveDraft, cancelSaveDraft] as const;
}

export function usePromptToRemoveRecipientOnMentionRemoval(
  control: IComposeMessageForm,
) {
  useEffect(() => {
    const removedMentions$ = observable(() => [
      ...control.rawValue.body.userMentions,
      ...control.rawValue.body.channelMentions,
    ]).pipe(
      pairwise(),
      // Mentions will never be mutated, only added/removed.
      // While mentions are generally added/removed one at a time, they can be
      // changed in bulk if the user deletes a paragraph.
      map(([previous, next]) => {
        const added = differenceBy(next, previous, "id");
        const removed = differenceBy(previous, next, "id");

        return added.length > 0
          ? { type: "ADDED" as const, value: added }
          : { type: "REMOVED" as const, value: removed };
      }),
      filter(
        (change): change is { type: "REMOVED"; value: IEditorMention[] } =>
          change.type === "REMOVED",
      ),
      map((change) => change.value),
    );

    const removedUsersAndChannels$ = combineLatest([
      ALL_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$,
      USER_CHANNELS$,
      removedMentions$,
    ]).pipe(
      map(([users, channels, removedMentions]) =>
        removedMentions
          .map(
            (m) =>
              users.find((user) => user.id === m.id) ||
              channels.find((channel) => channel.id === m.id),
          )
          .filter(isNonNullable),
      ),
    );

    const sub = removedUsersAndChannels$.subscribe(
      (removedUsersAndChannels) => {
        for (const removed of removedUsersAndChannels) {
          const recipient = control.rawValue.recipients.to.find(
            (r) => r.value === removed.id,
          );

          if (!recipient) continue;
          if (recipient.dontPromptToRemoveOnMentionDeletion) continue;

          const response = confirm(
            `Do you want remove ${
              removed.__docType === "IChannelDoc"
                ? `"${removed.name}" channel`
                : `"${removed.user.name}"`
            } as a recipient?`,
          );

          if (response) {
            control.patchValue({
              recipients: {
                to: control.rawValue.recipients.to.filter(
                  (r) => r.value !== removed.id,
                ),
              },
            });
          }
        }
      },
    );

    return () => sub.unsubscribe();
  }, [control]);
}
