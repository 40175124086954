import { memo } from "react";
import { isEqual } from "@libs/utils/isEqual";
import { entryCSSClasses, EntryTimestamp } from "~/components/content-list";
import { List } from "~/components/list";
import { IChannelDocWithCurrentUserData } from "~/services/channels.service";
import {
  IChannelGroupDoc,
  IChannelSubscriptionDoc,
} from "@libs/firestore-models";
import {
  numberComparer,
  stringComparer,
  timestampComparer,
} from "@libs/utils/comparers";
import { combineLatest, distinctUntilChanged, map, of } from "rxjs";
import { USER_CHANNELS$ } from "~/services/channels.service";
import { useObservable } from "~/utils/useObservable";
import { USER_CHANNEL_SUBSCRIPTIONS$ } from "~/services/subscription.service";
import { Tooltip } from "~/components/Tooltip";
import { BsCheckCircleFill, BsFillPinFill, BsLockFill } from "react-icons/bs";

export interface IChannelEntry {
  /** `IChannelDoc["id"] + ":" + IChannelGroupDoc["id"]` */
  id: string;
  channel: IChannelDocWithCurrentUserData;
  channelGroup: IChannelGroupDoc;
  subscription: IChannelSubscriptionDoc | null;
}

export type TOrderChannelsBy = "subscriber-count" | "recently-active" | "name";

export const ChannelEntry = memo<{
  entry: IChannelEntry;
  orderBy: TOrderChannelsBy;
  relativeOrder: number;
}>((props) => {
  const isSubscribed =
    props.entry.subscription?.preference === "all" ||
    props.entry.subscription?.preference === "all-new";

  return (
    <List.Entry
      id={props.entry.id}
      data={props.entry}
      relativeOrder={props.relativeOrder}
    >
      <div className={entryCSSClasses}>
        <div className="w-full flex items-center">
          <div className="flex items-center pr-6">
            <span className="text-slate-9 mr-2">
              {props.entry.channelGroup.name} {">"}
            </span>

            <span>{props.entry.channel.name}</span>

            {props.entry.channel.classification === "private" && (
              <Tooltip
                side="bottom"
                content="This is a private invite-only channel"
              >
                <span className="ml-2 hover:cursor-help">
                  <small>
                    <BsLockFill />
                  </small>
                </span>
              </Tooltip>
            )}

            {isSubscribed && (
              <Tooltip
                side="bottom"
                content="You are subscribed to this channel"
              >
                <span className="ml-2 text-slate-9 hover:cursor-help">
                  <BsCheckCircleFill size={14} />
                </span>
              </Tooltip>
            )}

            {props.entry.subscription?.isPinned && (
              <Tooltip
                side="bottom"
                content="You have pinned this channel to the sidebar"
              >
                <span className="ml-2 text-slate-9 hover:cursor-help">
                  <BsFillPinFill size={14} />
                </span>
              </Tooltip>
            )}
          </div>

          <div className="flex-1" />

          {props.orderBy === "subscriber-count" ? (
            <div className="text-slate-9">
              {props.entry.channel.subscriberCount} subscriber
              {props.entry.channel.subscriberCount === 1 ? "" : "s"}
            </div>
          ) : props.orderBy === "recently-active" ? (
            props.entry.channel.lastPostSentAt ? (
              <EntryTimestamp datetime={props.entry.channel.lastPostSentAt} />
            ) : (
              <div className="text-slate-9">n/a</div>
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </List.Entry>
  );
}, isEqual);

export function useChannelEntries(args: {
  organizationId?: string;
  orderBy: TOrderChannelsBy;
}): IChannelEntry[] | undefined {
  return useObservable(
    () => {
      if (!args.organizationId) return of([]);

      return combineLatest([USER_CHANNELS$, USER_CHANNEL_SUBSCRIPTIONS$]).pipe(
        map(([channels, subscriptions]) => {
          const nameComparer = (
            a: { channel: { name: string }; id: string },
            b: { channel: { name: string }; id: string },
          ) =>
            stringComparer(a.channel.name, b.channel.name) ||
            stringComparer(a.id, b.id);

          const comparer =
            args.orderBy === "subscriber-count"
              ? (a: IChannelEntry, b: IChannelEntry) =>
                  // we're sorting in DESC order
                  numberComparer(
                    b.channel.subscriberCount,
                    a.channel.subscriberCount,
                  ) || nameComparer(a, b)
              : args.orderBy === "recently-active"
              ? (a: IChannelEntry, b: IChannelEntry) => {
                  if (a.channel.lastPostSentAt && b.channel.lastPostSentAt) {
                    return (
                      // we're sorting in DESC order
                      timestampComparer(
                        b.channel.lastPostSentAt,
                        a.channel.lastPostSentAt,
                      ) ||
                      // we're sorting in DESC order
                      timestampComparer(
                        // Always non-null if lastPostSentAt is non-null
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        b.channel.lastPostScheduledToBeSentAt!,
                        // Always non-null if lastPostSentAt is non-null
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        a.channel.lastPostScheduledToBeSentAt!,
                      ) ||
                      nameComparer(a, b)
                    );
                  } else if (a.channel.lastPostSentAt) {
                    return -1;
                  } else if (b.channel.lastPostSentAt) {
                    return 1;
                  } else {
                    return nameComparer(a, b);
                  }
                }
              : nameComparer;

          return channels
            .filter((c) => !c.isOrganizationSharedChannel)
            .flatMap((c) => {
              return c.__local.knownChannelGroups
                .filter(
                  (channelGroup) =>
                    channelGroup.organizationId === args.organizationId,
                )
                .map((channelGroup) => {
                  const subscription =
                    subscriptions.find((s) => s.id === c.id) || null;

                  return {
                    id: `${c.id}:${channelGroup.id}`,
                    channel: c,
                    channelGroup,
                    subscription,
                  };
                });
            })
            .sort(comparer);
        }),
        distinctUntilChanged(isEqual),
      );
    },
    {
      deps: [args.organizationId, args.orderBy],
    },
  );
}
