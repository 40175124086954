import Emoji from "@tiptap-pro/extension-emoji";
import { ReactRenderer } from "@tiptap/react";
import tippy, { Instance, GetReferenceClientRect } from "tippy.js";
import { EmojiList, ISuggestionEntryProps } from "./EmojiList";
import { SuggestionEntryComponent } from "../utils";

export default Emoji.configure({
  suggestion: {
    items: ({ editor, query }) => {
      const emojis: ISuggestionEntryProps[] = editor.storage.emoji.emojis;

      return emojis
        .filter(({ shortcodes, tags }) => {
          const matchesSomeShortcode = shortcodes.some((shortcode) =>
            shortcode.startsWith(query.toLowerCase()),
          );

          return (
            matchesSomeShortcode ||
            tags.some((tag) => tag.startsWith(query.toLowerCase()))
          );
        })
        .slice(0, 5);
    },

    render: () => {
      let component: SuggestionEntryComponent<ISuggestionEntryProps>;
      let popup: Instance[] = [];

      return {
        onStart: (props) => {
          component = new ReactRenderer(EmojiList, {
            props,
            editor: props.editor,
          });

          popup = tippy("body", {
            getReferenceClientRect: props.clientRect as GetReferenceClientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
          });
        },

        onUpdate(props) {
          component.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup[0]?.setProps({
            getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          });
        },

        onKeyDown(props) {
          if (props.event.key === "Escape") {
            popup[0]?.hide();
            props.event.stopPropagation();
            return true;
          }

          return component.ref?.onKeyDown(props) || false;
        },

        onExit() {
          popup[0]?.destroy();
          component.destroy();
        },
      };
    },
  },
});
