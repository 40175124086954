import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  firstValueFrom,
  interval,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  scan,
  withLatestFrom,
} from "rxjs";
import { collectionChanges } from "rxfire/firestore";
import { collectionData, docData } from "~/utils/rxFireWrappers";
import { collectionRef, docRef } from "~/firestore.service";
import {
  query,
  where,
  updateDoc,
  serverTimestamp,
  orderBy,
  setDoc,
  UpdateData,
  Timestamp,
  deleteDoc,
  limit,
  deleteField,
  getDoc,
} from "firebase/firestore";
import {
  ASSERT_CURRENT_USER_ID$,
  catchNoCurrentUserError,
  getAndAssertCurrentUser,
} from "./user.service";
import { useObservable } from "~/utils/useObservable";
import { groupBy, memoize, pick } from "lodash-es";
import { isEqual } from "@libs/utils/isEqual";
import { withPendingUpdate } from "./loading.service";
import { SetNonNullable } from "@libs/utils/type-helpers";
import {
  IChannelDoc,
  IInboxSectionDoc,
  IInboxSubsectionDoc,
  IMainSettingsDoc,
  INewPostNotificationDoc,
  INotificationDoc,
  IPostDoc,
  ITagDoc,
  IThreadDoc,
  IThreadReadStatusDoc,
  IUserDoc,
  WithLocalData,
  WithServerTimestamp,
} from "@libs/firestore-models";
import { offlineAwareFirestoreCRUD } from "./network-connection.service";
import { getThread, getThreadReadStatus } from "./post.service";
import { toast } from "./toast-service";
import { validateNewNotification } from "~/utils/decoders";
import { clearUndo } from "./undo.service";
import dayjs from "dayjs";
import { isNonNullable } from "@libs/utils/predicates";
import { startWith } from "~/utils/rxjs-operators";
import { CURRENT_USER_MAIN_SETTINGS$ } from "./settings.service";
import { RefObject, useMemo } from "react";
import { getPagedQuery, useListPaging } from "~/utils/useListPaging";
import { UnreachableCaseError } from "@libs/utils/errors";
import { ParsedToken } from "@libs/utils/searchQueryParser";
import { graphql } from "~/gql";
import { buildGraphQLQueryVariables } from "./search-service";
import {
  findMatchingSubsection,
  ISearchQueryMatcherService,
} from "@libs/utils/searchQueryMatcher";
import { IDraftWithThreadData, observeDrafts } from "./draft.service";
import produce from "immer";
import { cacheReplayForTime } from "@libs/utils/rxjs-operators";
import { getTypedCallableFn } from "~/firebase";
import { navigateService } from "./navigate.service";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import {
  mutateAndAddOneToSortedArray,
  mutateAndRemoveOneFromArray,
} from "@libs/utils/array-utils";

export const inboxSectionDelete = getTypedCallableFn("inboxsectiondelete", {
  timeout: 300_000,
});

export const inboxSectionMerge = getTypedCallableFn("inboxsectionmerge", {
  timeout: 300_000,
});

export type INotificationNewPostWithLocalAndDraftData = WithLocalData<
  INewPostNotificationDoc,
  "INotificationDoc",
  {
    hasDraft?: boolean;
    draftId?: string;
  }
>;

export type INotificationWithLocalAndDraftData =
  INotificationNewPostWithLocalAndDraftData;

/** Reevaluate the scheduled delivery window every 10 seconds */
const REEVALUATE_SCHEDULED_DELIVERY_WINDOW_MS = 1000 * 20;

/* -------------------------------------------------------------------------------------------------
 * observeInboxNotification
 * -----------------------------------------------------------------------------------------------*/

export function observeInboxNotification<
  T extends INotificationDoc = INotificationDoc,
>(notificationId: string) {
  return ASSERT_CURRENT_USER_ID$.pipe(
    switchMap((userId) =>
      docData(docRef<T>("users", userId, "inbox", notificationId)).pipe(
        map((n) => n || null),
      ),
    ),
    catchNoCurrentUserError(() => null),
    distinctUntilChanged(isEqual),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
}

/* -------------------------------------------------------------------------------------------------
 * useInboxNotification
 * -----------------------------------------------------------------------------------------------*/

export function useInboxNotification(notificationId?: string) {
  return useObservable(
    () => {
      if (!notificationId) return of(null);
      return observeInboxNotification(notificationId);
    },
    {
      deps: [notificationId],
    },
  );
}

/* -------------------------------------------------------------------------------------------------
 * getInboxNotification
 * -----------------------------------------------------------------------------------------------*/

export function getInboxNotification(notificationId: string) {
  return firstValueFrom(observeInboxNotification(notificationId));
}

/* -------------------------------------------------------------------------------------------------
 * ALL_INBOX_NOTIFICATIONS$
 * -----------------------------------------------------------------------------------------------*/

export const ALL_INBOX_NOTIFICATIONS$ = ASSERT_CURRENT_USER_ID$.pipe(
  switchMap((userId) =>
    combineLatest([
      mainSettingsWithLastDeliveryDatetime$,
      collectionData(
        query(
          collectionRef("users", userId, "inbox"),
          where("done", "==", false),
          orderBy("sentAt", "asc"),
          orderBy("scheduledToBeSentAt", "asc"),
        ),
      ),
    ]),
  ),
  map(applyFocusSettingsToInboxNotifications),
  catchNoCurrentUserError(() => []),
  distinctUntilChanged(isEqual),
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const ALL_EMAIL_INBOX_NOTIFICATIONS$ = ASSERT_CURRENT_USER_ID$.pipe(
  switchMap((userId) =>
    combineLatest([
      mainSettingsWithLastDeliveryDatetime$,
      collectionData(
        query(
          collectionRef("users", userId, "inbox"),
          where("type", "==", "new-post"),
          where("postType", "==", "EMAIL"),
          where("done", "==", false),
          orderBy("sentAt", "asc"),
          orderBy("scheduledToBeSentAt", "asc"),
        ),
      ),
    ]),
  ),
  map(applyFocusSettingsToInboxNotifications),
  // switchMap(combineNotificationsWithRelatedPosts),
  catchNoCurrentUserError(() => []),
  distinctUntilChanged(isEqual),
  // We use `shareReply()` without a refCount so that a subscription
  // is maintained to the inbox notifications even after unsubscribe.
  // We do this because we want the user to always have the latest
  // triaged notifications if they accidently go offline.
  shareReplay({ bufferSize: 1, refCount: true }),
);

const mainSettingsWithLastDeliveryDatetime$ = CURRENT_USER_MAIN_SETTINGS$.pipe(
  switchMap((setting) => {
    if (!setting?.enableScheduledDelivery) {
      return of({ setting, lastDeliveryDatetime: null });
    }

    // If we have enableScheduledDelivery turned on, then
    // we need to periodically rerun our
    // applyFocusSettingsToInboxNotifications() mapper function
    // to re-filter notifications with the current time.
    return interval(REEVALUATE_SCHEDULED_DELIVERY_WINDOW_MS).pipe(
      startWith(() => null),
      map(() => {
        const lastDeliveryDatetime = getLastScheduledDeliveryDatetime(setting);

        return {
          setting,
          lastDeliveryDatetime,
        };
      }),
    );
  }),
  distinctUntilChanged(isEqual),
);

function applyFocusSettingsToInboxNotifications([settingData, notifications]: [
  {
    setting: IMainSettingsDoc | null;
    lastDeliveryDatetime: dayjs.Dayjs | null;
  },
  INotificationDoc[],
]) {
  const { setting, lastDeliveryDatetime } = settingData;
  let filteredNotifications: INotificationDoc[];

  if (!setting?.enableFocusMode) {
    filteredNotifications = notifications.slice();
  } else if (setting.focusModeExceptions === undefined) {
    // We require the user to choose 0 or more exceptions the
    // first time they enableFocusMode. If this property is undefined
    // it indicates some kind of a bug so we should just ignore the
    // setting.
    filteredNotifications = notifications.slice();
  } else if (setting.focusModeExceptions.length === 0) {
    return [];
  } else {
    filteredNotifications = notifications.filter((n) =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setting.focusModeExceptions!.some((exception) => {
        if (exception === 300) {
          return n.priority >= exception;
        }

        return n.priority === exception;
      }),
    );
  }

  if (setting?.enableScheduledDelivery) {
    if (!lastDeliveryDatetime) {
      // We require the user to choose 1 or more scheduledDays and
      // scheduledTimes the first time they enableScheduledDelivery.
      // If either of these properties are length 0 it indicates
      // some kind of a bug so we should just ignore the setting.
      return filteredNotifications;
    }

    return filteredNotifications.filter((notification) => {
      if (notification.priority <= 100) return true;
      if (notification.doneLastModifiedBy !== "system") return true;
      if (!notification.oldestSentAtValueNotMarkedDone) return true;

      return !lastDeliveryDatetime.isBefore(
        notification.oldestSentAtValueNotMarkedDone.toDate(),
      );
    });
  }

  return filteredNotifications;
}

type DayAsNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6;

/**
 * Accepts a day of the week string and returns that day of the
 * week converted to a number following the JS Date convention
 * (i.e. Sunday is 0 and Monday is 1).
 *
 * @param day e.g. "Tuesday"
 * @returns e.g. 2
 */
export function mapDayToNumber(day: string): DayAsNumber | undefined {
  switch (day) {
    case "Sunday":
      return 0;
    case "Monday":
      return 1;
    case "Tuesday":
      return 2;
    case "Wednesday":
      return 3;
    case "Thursday":
      return 4;
    case "Friday":
      return 5;
    case "Saturday":
      return 6;
  }
}

export function getLastScheduledDeliveryDatetime(
  settingsDoc: Pick<
    IMainSettingsDoc,
    "scheduledDays" | "scheduledTimes" | "mostRecentDeliverNow"
  >,
) {
  const now = dayjs();

  const { scheduledDays = [], scheduledTimes = [] } = settingsDoc;

  if (scheduledDays.length === 0 || scheduledTimes.length === 0) {
    return null;
  }

  const sortedDates = scheduledDays
    .map(mapDayToNumber)
    .filter(isNonNullable)
    .map((day) => {
      const date = now.set("day", day);
      return date.isAfter(now, "date") ? date.subtract(1, "week") : date;
    })
    .sort((a, b) => b.valueOf() - a.valueOf());

  const sortedTimes = scheduledTimes.slice().sort().reverse();

  const mostRecentDeliverNowTime =
    settingsDoc.mostRecentDeliverNow &&
    dayjs(settingsDoc.mostRecentDeliverNow.toDate());

  for (const date of sortedDates) {
    for (const time of sortedTimes) {
      const [h, m] = time.split(":").map((t) => Number.parseInt(t, 10));

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const datetime = date.set("hour", h!).set("minute", m!).startOf("minute");

      if (datetime.isBefore(now) || datetime.isSame(now)) {
        if (mostRecentDeliverNowTime?.isAfter(datetime)) {
          // This works because the mostRecentDeliverNowTime will always be
          // before `now`.
          return mostRecentDeliverNowTime;
        }

        return datetime;
      }
    }
  }

  return null;
}

export function observeInboxSectionNotifications(sectionId: string) {
  return combineLatest([
    CURRENT_USER_MAIN_SETTINGS$.pipe(
      map((s) => s.inboxLayout),
      distinctUntilChanged(),
    ),
    observeInboxSection(sectionId),
  ]).pipe(
    switchMap(([inboxLayout, sectionDoc]) => {
      if (!sectionDoc) return of([]);
      else if (inboxLayout === "blocking-inbox") {
        return observeBlockingInboxSectionNotifications(sectionDoc).pipe(
          map((value) => value?.notificationDocs || []),
        );
      } else {
        return observeConsolidatedInboxSectionNotifications(sectionDoc);
      }
    }),
  );
}

export function useInboxSectionNotifications(sectionId?: string | null) {
  return useObservable(
    () => {
      if (!sectionId) return of([]);
      return observeInboxSectionNotifications(sectionId);
    },
    {
      deps: [sectionId],
    },
  );
}

function observeConsolidatedInboxSectionNotifications(
  sectionDoc: TInboxSection,
) {
  if (sectionDoc.subsectionDocs.length === 0) return of([]);

  return combineLatest(
    sectionDoc.subsectionDocs.map((subsectionDoc) =>
      observeInboxSubsectionNotifications(subsectionDoc.tagId),
    ),
  ).pipe(map((groupedNotifications) => groupedNotifications.flat()));
}

function observeConsolidatedInboxSectionNotificationsAndDrafts(
  sectionDoc: TInboxSection,
) {
  return combineLatest([
    observeDrafts(),
    ...sectionDoc.subsectionDocs.map((subsectionDoc) =>
      observeInboxSubsectionNotifications(subsectionDoc.tagId).pipe(
        map((notificationDocs) => ({
          subsectionDoc,
          notificationDocs,
        })),
      ),
    ),
  ]).pipe(
    map(([drafts, ...notificationGroups]) => {
      const newDrafts: IDraftWithThreadData[] = [];

      const notificationsWithDraftData = produce(
        notificationGroups,
        (state) => {
          draftsLabel: for (const draft of drafts) {
            for (const notificationGroup of state) {
              for (const notification of notificationGroup.notificationDocs) {
                if (notification.id !== draft.threadId) continue;

                notification.__local.hasDraft = true;
                notification.__local.draftId = draft.id;

                continue draftsLabel;
              }
            }

            newDrafts.push(draft);
          }
        },
      );

      return [newDrafts, notificationsWithDraftData] as [
        IDraftWithThreadData[],
        Array<{
          subsectionDoc: IInboxSubsectionDoc;
          notificationDocs: INotificationNewPostWithLocalAndDraftData[];
        }>,
      ];
    }),
  );
}

function observeBlockingInboxSectionNotifications(sectionDoc: TInboxSection) {
  if (sectionDoc.subsectionDocs.length === 0) return of(null);

  return combineLatest(
    sectionDoc.subsectionDocs.map((subsectionDoc) =>
      observeInboxSubsectionNotifications(subsectionDoc.tagId).pipe(
        map((notificationDocs) => ({
          sectionDoc,
          subsectionDoc,
          notificationDocs,
        })),
      ),
    ),
  ).pipe(
    map((groupedNotifications) => {
      return (
        groupedNotifications.find(
          (group) => group.notificationDocs.length > 0,
        ) || null
      );
    }),
  );
}

export function useBlockingInboxSectionNotifications(
  sectionId?: string | null,
) {
  return useObservable(
    () => {
      if (!sectionId) return of(null);
      return observeInboxSection(sectionId).pipe(
        switchMap((sectionDoc) => {
          if (!sectionDoc) return of(null);
          return observeBlockingInboxSectionNotifications(sectionDoc);
        }),
      );
    },
    {
      deps: [sectionId],
    },
  );
}

function observeBlockingInboxSectionNotificationsAndDrafts(
  sectionDoc: TInboxSection,
): Observable<
  [
    IDraftWithThreadData[],
    {
      subsectionDoc: IInboxSubsectionDoc;
      notificationDocs: INotificationNewPostWithLocalAndDraftData[];
    }[],
  ]
> {
  return combineLatest([
    observeDrafts(),
    ...sectionDoc.subsectionDocs.map((subsectionDoc) =>
      observeInboxSubsectionNotifications(subsectionDoc.tagId).pipe(
        map((notificationDocs) => ({
          subsectionDoc,
          notificationDocs,
        })),
      ),
    ),
  ]).pipe(
    map(([drafts, ...notificationGroups]) => {
      return [
        drafts,
        notificationGroups.find((group) => group.notificationDocs.length > 0) ||
          null,
      ] as const;
    }),
    map(([drafts, notificationGroup]) => {
      if (!notificationGroup) {
        return [drafts, []];
      }

      const newDrafts: IDraftWithThreadData[] = [];

      const notificationsWithDraftData = produce(notificationGroup, (state) => {
        draftsLabel: for (const draft of drafts) {
          for (const notification of state.notificationDocs) {
            if (notification.id !== draft.threadId) continue;

            notification.__local.hasDraft = true;
            notification.__local.draftId = draft.id;

            continue draftsLabel;
          }

          newDrafts.push(draft);
        }
      });

      return [newDrafts, [notificationsWithDraftData]];
    }),
  );
}

export function observeInboxSubsectionNotifications(subsectionTagId: string) {
  const settings$ = mainSettingsWithLastDeliveryDatetime$.pipe(
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  return settings$.pipe(
    switchMap(() => {
      // When the scheduled delivery settings update, we
      // reevaluate all of the notifications.
      return ASSERT_CURRENT_USER_ID$.pipe(
        switchMap((currentUserId) =>
          collectionChanges(
            query(
              collectionRef("users", currentUserId, "inbox"),
              where("done", "==", false),
              where("tagIds", "array-contains", subsectionTagId),
              orderBy("sentAt", "asc"),
              orderBy("scheduledToBeSentAt", "asc"),
            ),
          ),
        ),
        // If the result set is empty, collectionChanges doesn't emit anything
        startWith(() => []),
        withLatestFrom(settings$),
        map(([changes, settings]) => {
          return changes
            .filter((change) =>
              doesNotificationPassFocusSettings({
                ...settings,
                notification: change.doc.data(),
              }),
            )
            .map((change) => ({
              ...change,
              doc: mapNotificationToNotificationWithLocalAndDraftData(
                change.doc.data(),
              ),
            }));
        }),
        scan((_store, changes) => {
          // `scan()` expects the object identity to change if the object has changed
          const store = _store.slice();

          // Because we filter out changes using the inbox focus settings, we can't
          // trust the position indexes associated with changes coming from Firestore.
          for (const change of changes) {
            switch (change.type) {
              case "added": {
                mutateAndAddOneToSortedArray(
                  store,
                  change.doc,
                  (doc) =>
                    doc.sentAt.valueOf() + doc.scheduledToBeSentAt.valueOf(),
                );

                break;
              }
              case "modified": {
                mutateAndRemoveOneFromArray(
                  store,
                  (doc) => doc.id === change.doc.id,
                );

                mutateAndAddOneToSortedArray(
                  store,
                  change.doc,
                  (doc) =>
                    doc.sentAt.valueOf() + doc.scheduledToBeSentAt.valueOf(),
                );

                break;
              }
              case "removed": {
                mutateAndRemoveOneFromArray(
                  store,
                  (doc) => doc.id === change.doc.id,
                );

                break;
              }
              default: {
                throw new UnreachableCaseError(change.type);
              }
            }
          }

          return store;
        }, [] as INotificationNewPostWithLocalAndDraftData[]),
      );
    }),
    catchNoCurrentUserError(() => []),
  );
}

export function useInboxSubsectionNotifications(
  subsectionTagId?: string | null,
) {
  return useObservable(
    () => {
      if (!subsectionTagId) return of([]);
      return observeInboxSubsectionNotifications(subsectionTagId);
    },
    {
      deps: [subsectionTagId],
    },
  );
}

function mapNotificationToNotificationWithLocalAndDraftData(
  notification: INotificationDoc,
): INotificationWithLocalAndDraftData {
  return {
    ...notification,
    __docType: "INotificationDoc",
    __local: {},
  };
}

export function observeAreAnyInboxSectionNotifications(
  sectionId: string,
): Observable<boolean> {
  return combineLatest([
    observeInboxSection(sectionId),
    ASSERT_CURRENT_USER_ID$,
  ]).pipe(
    switchMap(([section, currentUserId]) => {
      if (!section) return of([]);

      return collectionData(
        query(
          collectionRef("users", currentUserId, "inbox"),
          where("done", "==", false),
          where("tagIds", "array-contains", section.tagId),
          limit(1),
        ),
      );
    }),
    map((notificationDocs) => notificationDocs.length > 0),
    distinctUntilChanged(),
  );
}

export function useAreThereAnyInboxSectionNotifications(
  sectionId?: string | null,
) {
  return useObservable(
    () => {
      if (!sectionId) return of(false);
      return observeAreAnyInboxSectionNotifications(sectionId);
    },
    {
      deps: [sectionId],
    },
  );
}

export type TInboxNotificationsAndDrafts = [
  IDraftWithThreadData[],
  {
    subsectionDoc: IInboxSubsectionDoc;
    notificationDocs: INotificationNewPostWithLocalAndDraftData[];
  }[],
];

const cache = new Map<string, Observable<TInboxNotificationsAndDrafts>>();

export function observeInboxSectionNotificationsAndDrafts(
  sectionId: string,
): Observable<TInboxNotificationsAndDrafts> {
  const cachedQuery = cache.get(sectionId);

  if (cachedQuery) {
    return cachedQuery;
  }

  const obs = combineLatest([
    CURRENT_USER_MAIN_SETTINGS$.pipe(
      map((s) => s.inboxLayout),
      distinctUntilChanged(),
    ),
    observeInboxSection(sectionId),
  ])
    .pipe(
      switchMap(([inboxLayout, sectionDoc]) => {
        if (!sectionDoc) return of([[], []] as TInboxNotificationsAndDrafts);
        else if (inboxLayout === "blocking-inbox") {
          return observeBlockingInboxSectionNotificationsAndDrafts(sectionDoc);
        } else {
          return observeConsolidatedInboxSectionNotificationsAndDrafts(
            sectionDoc,
          );
        }
      }),
    )
    .pipe(
      cacheReplayForTime({
        timeMs: 5000,
        onInit() {
          cache.set(sectionId, obs);
        },
        onCleanup() {
          cache.delete(sectionId);
        },
      }),
    );

  return obs;
}

export function useInboxSectionNotificationsAndDrafts(
  sectionId?: string | null,
) {
  return useObservable(
    () => {
      if (!sectionId) return of([[], []] as TInboxNotificationsAndDrafts);
      return observeInboxSectionNotificationsAndDrafts(sectionId);
    },
    {
      deps: [sectionId],
    },
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function convertPostgresTimeStringToTimestamp(timestring: string): Timestamp;
function convertPostgresTimeStringToTimestamp(
  timestring: string | null,
): Timestamp | null;
function convertPostgresTimeStringToTimestamp(timestring: string | null) {
  if (!timestring) return null;

  // The timestamps coming from postgres are in UTC format but they
  // don't include the "Z". In case one does include the "Z", we remove
  // it to normalize the result.
  if (timestring.endsWith("Z")) {
    timestring = timestring.slice(0, -1);
  }

  const [datetime, fractionalSeconds] = timestring.split(".");

  const fromDate = Timestamp.fromDate(new Date(Date.parse(`${datetime}Z`)));

  if (!fractionalSeconds) {
    return fromDate;
  } else if (fractionalSeconds.length <= 9) {
    return new Timestamp(
      fromDate.seconds,
      parseInt(fractionalSeconds.padEnd(9, "0")),
    );
  } else {
    throw new UnreachableCaseError(
      fractionalSeconds as never,
      `convertStringToTimestamp received an unexpected fractionalSeconds value: ${timestring}`,
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function applyFocusSettingsToNotifications<T extends INotificationDoc>([
  settingData,
  notifications,
]: [
  {
    setting: IMainSettingsDoc | null;
    lastDeliveryDatetime: dayjs.Dayjs | null;
  },
  T[],
]) {
  const { setting, lastDeliveryDatetime } = settingData;

  let filteredNotifications: T[];

  if (!setting?.enableFocusMode) {
    filteredNotifications = notifications.slice();
  } else if (setting.focusModeExceptions === undefined) {
    // We require the user to choose 0 or more exceptions the
    // first time they enableFocusMode. If this property is undefined
    // it indicates some kind of a bug so we should just ignore the
    // setting.
    filteredNotifications = notifications.slice();
  } else if (setting.focusModeExceptions.length === 0) {
    return [];
  } else {
    filteredNotifications = notifications.filter((n) =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setting.focusModeExceptions!.some((exception) => {
        if (exception === 300) {
          return n.priority >= exception;
        }

        return n.priority === exception;
      }),
    );
  }

  if (
    setting?.enableScheduledDelivery &&
    // We require the user to choose 1 or more scheduledDays and
    // scheduledTimes the first time they enableScheduledDelivery.
    // If either of these properties are length 0 it indicates
    // some kind of a bug so we should just ignore the setting.
    lastDeliveryDatetime
  ) {
    filteredNotifications = filteredNotifications.filter((notification) => {
      if (notification.priority <= 100) return true;
      if (notification.doneLastModifiedBy !== "system") return true;
      if (!notification.oldestSentAtValueNotMarkedDone) return true;

      return !lastDeliveryDatetime.isBefore(
        notification.oldestSentAtValueNotMarkedDone.toDate(),
      );
    });
  }

  return filteredNotifications;
}

function doesNotificationPassFocusSettings<T extends INotificationDoc>(args: {
  setting: IMainSettingsDoc | null;
  lastDeliveryDatetime: dayjs.Dayjs | null;
  notification: T;
}): boolean {
  const { setting, lastDeliveryDatetime, notification } = args;

  if (
    setting?.enableFocusMode &&
    // We require the user to choose 0 or more exceptions the
    // first time they enableFocusMode. If this property is undefined
    // it indicates some kind of a bug so we should just ignore the
    // setting.
    setting.focusModeExceptions !== undefined
  ) {
    if (setting.focusModeExceptions.length === 0) {
      return false;
    }

    const isCoveredByExcemption = setting.focusModeExceptions.some(
      (exception) => {
        if (exception === 300) {
          return notification.priority >= exception;
        }

        return notification.priority === exception;
      },
    );

    if (!isCoveredByExcemption) {
      return false;
    }
  }

  if (
    setting?.enableScheduledDelivery &&
    // We require the user to choose 1 or more scheduledDays and
    // scheduledTimes the first time they enableScheduledDelivery.
    // If either of these properties are length 0 it indicates
    // some kind of a bug so we should just ignore the setting.
    lastDeliveryDatetime &&
    // We allow priority 100 messages to bypass scheduled delivery
    notification.priority > 100 &&
    // If the user has created the notification and added it to their
    // inbox, we should bypass scheduled delivery and show the
    // notification
    notification.doneLastModifiedBy === "system" &&
    notification.oldestSentAtValueNotMarkedDone
  ) {
    const isAfterDeliveryWindow = lastDeliveryDatetime.isBefore(
      notification.oldestSentAtValueNotMarkedDone.toDate(),
    );

    if (isAfterDeliveryWindow) return false;
  }

  return true;
}

export function useLastScheduledDeliveryDatetime() {
  return useObservable(
    () =>
      CURRENT_USER_MAIN_SETTINGS$.pipe(
        map((settings) =>
          pick(
            settings,
            "enableScheduledDelivery",
            "scheduledDays",
            "scheduledTimes",
            "mostRecentDeliverNow",
          ),
        ),
        distinctUntilChanged(isEqual),
        switchMap((settings) => {
          if (!settings.enableScheduledDelivery) {
            return of(null);
          }

          return interval(REEVALUATE_SCHEDULED_DELIVERY_WINDOW_MS).pipe(
            startWith(() => null),
            map(() => getLastScheduledDeliveryDatetime(settings)),
          );
        }),
        distinctUntilChanged(isEqual),
      ),
    { initialValue: null },
  );
}

export function getNextScheduledDeliveryDatetime(
  settingsDoc: Pick<
    IMainSettingsDoc,
    "scheduledDays" | "scheduledTimes" | "mostRecentDeliverNow"
  >,
) {
  const now = dayjs();

  const { scheduledDays = [], scheduledTimes = [] } = settingsDoc;

  if (scheduledDays.length === 0 || scheduledTimes.length === 0) {
    return null;
  }

  const sortedDates = scheduledDays
    .map(mapDayToNumber)
    .filter(isNonNullable)
    .map((day) => {
      const date = now.set("day", day);
      return date.isBefore(now, "date") ? date.add(1, "week") : date;
    })
    .sort((a, b) => a.valueOf() - b.valueOf());

  const sortedTimes = scheduledTimes.slice().sort();

  for (const date of sortedDates) {
    for (const time of sortedTimes) {
      const [h, m] = time.split(":").map((t) => Number.parseInt(t, 10));

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const datetime = date.set("hour", h!).set("minute", m!);

      if (datetime.isAfter(now)) {
        return datetime;
      }
    }
  }

  return null;
}

export function useNextScheduledDeliveryDatetime() {
  return useObservable(
    () =>
      CURRENT_USER_MAIN_SETTINGS$.pipe(
        map((settings) =>
          pick(
            settings,
            "enableScheduledDelivery",
            "scheduledDays",
            "scheduledTimes",
            "mostRecentDeliverNow",
          ),
        ),
        distinctUntilChanged(isEqual),
        switchMap((settings) => {
          if (!settings.enableScheduledDelivery) {
            return of(null);
          }

          return interval(REEVALUATE_SCHEDULED_DELIVERY_WINDOW_MS).pipe(
            startWith(() => null),
            map(() => getNextScheduledDeliveryDatetime(settings)),
          );
        }),
        distinctUntilChanged(isEqual),
      ),
    { initialValue: null },
  );
}

/**
 * @param date e.g. 2022/10/19 @ 8:31 am
 * @returns e.g. "08:31"
 */
export function convertDateToTimeString(date: dayjs.Dayjs) {
  let hours = date.get("hours").toString(10);
  hours = hours.length === 1 ? "0" + hours : hours;

  let minutes = date.get("minutes").toString(10);
  minutes = minutes.length === 1 ? "0" + minutes : minutes;

  return `${hours}:${minutes}`;
}

export type ITriagedNotificationDoc = SetNonNullable<
  INotificationDoc,
  "triagedUntil"
> & {
  triaged: true;
};

export const ALL_TRIAGED_NOTIFICATIONS$ = ASSERT_CURRENT_USER_ID$.pipe(
  switchMap((userId) =>
    collectionData(
      query(
        collectionRef<ITriagedNotificationDoc>("users", userId, "inbox"),
        where("triaged", "==", true),
        orderBy("triagedUntil", "asc"),
        orderBy("sentAt", "asc"),
        orderBy("scheduledToBeSentAt", "asc"),
      ),
    ),
  ),
  // switchMap(combineNotificationsWithRelatedPosts),
  catchNoCurrentUserError(() => []),
  distinctUntilChanged(isEqual),
  // We use `shareReply()` without a refCount so that a subscription
  // is maintained to the triaged notifications even after unsubscribe.
  // We do this because we want the user to always have the latest
  // triaged notifications if they accidently go offline.
  shareReplay(1),
);

export type INotificationDocWithLocalData = WithLocalData<
  INotificationDoc,
  "INotificationDoc",
  {}
>;

/**
 * Returns an observable of inbox notifications grouped by
 * priority level.
 *
 * Priority levels
 * - 100 - `@@@interrupt`
 * - 200 - `@@request-response`
 * - 300 - `@mention` OR participating in the thread
 * - 500 - subscriptions you aren't participating in
 */
export function observeGroupedInboxNotifications() {
  return ALL_INBOX_NOTIFICATIONS$.pipe(
    map((notificationDocs) => {
      return notificationDocs.map((doc) => {
        const localDoc: INotificationDocWithLocalData = {
          ...doc,
          __docType: "INotificationDoc",
          __local: {},
        };

        return localDoc;
      });
    }),
    map((notificationDocs) => {
      const groups = groupBy(notificationDocs, (doc) => {
        if (300 <= doc.priority && 400 >= doc.priority) return 300;
        return doc.priority;
      });

      return groups;
    }),
    distinctUntilChanged(isEqual),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
}

export function useGroupedInboxNotifications() {
  return useObservable(() => observeGroupedInboxNotifications());
}

export function observeHighestPriorityInboxNotifications() {
  return observeGroupedInboxNotifications().pipe(
    switchMap((groupedNotificationDocs) => {
      if ("100" in groupedNotificationDocs) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return of(groupedNotificationDocs["100"]!);
      }

      if ("200" in groupedNotificationDocs) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return of(groupedNotificationDocs["200"]!);
      }

      // Currently we're combining everything below 200 into one group.
      // In the future I expect this will be customizable.
      // See https://github.com/levelshealth/comms/issues/427
      // // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      // if ("300" in groups) return groups["300"]!;

      return ALL_INBOX_NOTIFICATIONS$.pipe(
        map((notificationDocs) => {
          return notificationDocs.map((doc) => {
            const localDoc: INotificationDocWithLocalData = {
              ...doc,
              __docType: "INotificationDoc",
              __local: {},
            };

            return localDoc;
          });
        }),
      );
    }),
    distinctUntilChanged(isEqual),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
}

export function useInboxNotifications() {
  return useObservable(() => observeHighestPriorityInboxNotifications());
}

export function observeDoneNotifications(
  page: number | BehaviorSubject<number>,
) {
  const { docs$, ...other } = getPagedQuery(
    (limitInboxCountTo) =>
      ASSERT_CURRENT_USER_ID$.pipe(
        switchMap((userId) =>
          collectionData(
            query(
              collectionRef("users", userId, "inbox"),
              where("done", "==", true),
              orderBy("doneAt", "desc"),
              orderBy("scheduledToBeSentAt", "desc"),
              limit(limitInboxCountTo),
            ),
          ),
        ),
      ),
    page,
  );

  const notifications$ = docs$.pipe(
    catchNoCurrentUserError(() => []),
    distinctUntilChanged(isEqual),
    shareReplay(1),
  );

  return { notifications$, ...other };
}

export function useDoneNotifications(options: {
  /**
   * Loads an initial chunk of notifications and then loads
   * more when the user scrolls to the bottom of the
   * element associated with this scrollboxRef.
   */
  pagingScrollboxRef: RefObject<HTMLElement>;
}) {
  const { pagingScrollboxRef } = options;

  const { notifications$, getNextPage, loading$ } = useMemo(() => {
    return observeDoneNotifications(1);
  }, []);

  useListPaging({ getNextPage, pagingScrollboxRef, loading$ });

  return useObservable(() => notifications$ || of([]), {
    deps: [notifications$],
  });
}

export function useTriagedNotifications() {
  return useObservable(() => ALL_TRIAGED_NOTIFICATIONS$);
}

export function observeStarredNotifications(
  page: number | BehaviorSubject<number>,
) {
  const { docs$, ...other } = getPagedQuery(
    (limitInboxCountTo) =>
      ASSERT_CURRENT_USER_ID$.pipe(
        switchMap((userId) =>
          collectionData(
            query(
              collectionRef("users", userId, "inbox"),
              where("isStarred", "==", true),
              orderBy("sentAt", "desc"),
              orderBy("scheduledToBeSentAt", "desc"),
              limit(limitInboxCountTo),
            ),
          ),
        ),
      ),
    page,
  );

  const notifications$ = docs$.pipe(
    catchNoCurrentUserError(() => []),
    distinctUntilChanged(isEqual),
    shareReplay(1),
  );

  return { notifications$, ...other };
}

export function useStarredNotifications(options: {
  /**
   * Loads an initial chunk of notifications and then loads
   * more when the user scrolls to the bottom of the
   * element associated with this scrollboxRef.
   */
  pagingScrollboxRef: RefObject<HTMLElement>;
}) {
  const { pagingScrollboxRef } = options;

  const { notifications$, getNextPage, loading$ } = useMemo(() => {
    return observeStarredNotifications(1);
  }, []);

  useListPaging({ getNextPage, pagingScrollboxRef, loading$ });

  return useObservable(() => notifications$ || of([]), {
    deps: [notifications$],
  });
}

export type TInboxSection = IInboxSectionDoc & {
  subsectionDocs: IInboxSubsectionDoc[];
};

export function observeInboxSection(
  sectionId: string,
): Observable<TInboxSection | null> {
  return ASSERT_CURRENT_USER_ID$.pipe(
    switchMap((userId) =>
      combineLatest([
        docData(docRef("users", userId, "inboxSections", sectionId)).pipe(
          map((n) => n || null),
        ),
        collectionData(
          query(
            collectionRef(
              "users",
              userId,
              "inboxSections",
              sectionId,
              "inboxSubsections",
            ),
            orderBy("order", "asc"),
            orderBy("name", "asc"),
            orderBy("id", "asc"),
          ),
        ),
      ]),
    ),
    map(([sectionDoc, subsectionDocs]) => {
      if (!sectionDoc) return null;
      return {
        ...sectionDoc,
        subsectionDocs,
      };
    }),
    catchNoCurrentUserError(() => null),
    distinctUntilChanged(isEqual),
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useInboxSection(sectionId?: string) {
  return useObservable(
    () => {
      if (!sectionId) return of(null);
      return observeInboxSection(sectionId);
    },
    { deps: [sectionId] },
  );
}

/**
 * Doesn't include the default inbox section
 */
export function observeInboxSections() {
  return ASSERT_CURRENT_USER_ID$.pipe(
    switchMap((userId) =>
      collectionData(
        query(
          collectionRef("users", userId, "inboxSections"),
          orderBy("order", "asc"),
          orderBy("name", "asc"),
          orderBy("id", "asc"),
        ),
      ).pipe(
        switchMap((sectionDocs) => {
          sectionDocs = sectionDocs.filter((doc) => doc.id !== "DEFAULT");

          if (sectionDocs.length === 0) return of([]);

          return combineLatest(
            sectionDocs.map((sectionDoc) =>
              collectionData(
                query(
                  collectionRef(
                    "users",
                    userId,
                    "inboxSections",
                    sectionDoc.id,
                    "inboxSubsections",
                  ),
                  orderBy("order", "asc"),
                  orderBy("name", "asc"),
                  orderBy("id", "asc"),
                ),
              ).pipe(
                map((subsectionDocs) => {
                  return {
                    ...sectionDoc,
                    subsectionDocs,
                  } satisfies TInboxSection;
                }),
              ),
            ),
          );
        }),
      ),
    ),
    catchNoCurrentUserError(() => []),
    distinctUntilChanged(isEqual),
  );
}

export function getInboxSections() {
  return firstValueFrom(observeInboxSections());
}

export function useInboxSections() {
  return useObservable(observeInboxSections);
}

export const deleteInboxSection = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(async (inboxSectionId: string) => {
    const confirmed = confirm(
      "Are you sure you want to delete this inbox section?",
    );

    if (!confirmed || !inboxSectionId) {
      return;
    }

    navigateService("/inbox");

    await inboxSectionDelete({ inboxSectionId });
  }),
);

export interface ITriageThreadArgs {
  threadId: string;
  done?: boolean;
  triagedUntil?: Date | null;
  isStarred?: boolean;
  /** Surpresses toast notifications when true */
  noToast?: boolean;
  /**
   * When true, will change the toast notification to indicate
   * that the user just undid an action.
   */
  isUndo?: boolean;
}

/**
 * Triages a thread by updating the associated inbox notification.
 * If no inbox notification currently exists for this thread, one will
 * be created using the last post in the thread. When marking the
 * thread as "done", we will also record that the user has read up
 * to the current last post in the thread.
 */
export const triageThread = memoize(
  withPendingUpdate(async (args: ITriageThreadArgs) => {
    if (
      args.done === undefined &&
      args.triagedUntil === undefined &&
      args.isStarred === undefined
    ) {
      console.warn("Provided an empty update to updateThreadNotification()");
      return;
    }

    try {
      const currentUser = getAndAssertCurrentUser();

      const notification = await getInboxNotification(args.threadId);

      let notificationUpdatePromise: Promise<unknown>;
      let readStatusPromise: Promise<IThreadReadStatusDoc | null> | undefined;
      let readStatusUpdatePromise: Promise<unknown> | undefined;

      if (!notification) {
        const result = await createNewPostNotification(args);

        if (!result) return;

        notificationUpdatePromise = result.notificationUpdatePromise;
        readStatusPromise = result.readStatusPromise;
        readStatusUpdatePromise = result.readStatusUpdatePromise;
      } else {
        const notificationUpdate: UpdateData<
          Pick<
            INotificationDoc,
            | "updatedAt"
            | "serverUpdatedAt"
            | "done"
            | "doneAt"
            | "doneLastModifiedBy"
            | "oldestSentAtValueNotMarkedDone"
            | "triaged"
            | "triagedUntil"
            | "isStarred"
            | "starredAt"
          >
        > = {
          updatedAt: serverTimestamp(),
          serverUpdatedAt: notification.serverUpdatedAt || null,
        };

        if (typeof args.done !== "undefined") {
          notificationUpdate.done = args.done;
          notificationUpdate.doneAt = args.done ? serverTimestamp() : null;
          notificationUpdate.doneLastModifiedBy = "user";
          notificationUpdate.oldestSentAtValueNotMarkedDone = args.done
            ? null
            : notification.oldestSentAtValueNotMarkedDone ||
              notification.sentAt;
        }

        if (typeof args.triagedUntil !== "undefined") {
          notificationUpdate.triaged = !!args.triagedUntil;
          notificationUpdate.triagedUntil = args.triagedUntil;
        }

        if (typeof args.isStarred !== "undefined") {
          notificationUpdate.isStarred = args.isStarred;
          notificationUpdate.starredAt = args.isStarred
            ? serverTimestamp()
            : null;
        }

        if (
          notificationUpdate.done &&
          // setting a reminder shouldn't mark a thread as "read".
          !notificationUpdate.triaged
        ) {
          readStatusPromise = getThreadReadStatus(notification.id);

          readStatusUpdatePromise = (async () => {
            if (notification?.type !== "new-post") return;

            const [thread] = await Promise.all([
              getThread(notification.id as string),
              // we need a copy of the read status before it's updated
              // so that we can allow the user to "undo" the changes if
              // desired
              readStatusPromise,
            ]);

            const lastPost = thread?.lastPost;

            if (!lastPost) {
              console.debug(
                `Could not find posts associated with notificationId.
                Maybe you provided the wrong type of notification?`,
              );

              return;
            }

            return setDoc(
              docRef("threads", args.threadId, "readStatus", currentUser.id),
              {
                id: currentUser.id,
                threadId: args.threadId,
                seenToSentAt: lastPost.sentAt,
                seenToScheduledToBeSentAt: lastPost.scheduledToBeSentAt,
                readToSentAt: lastPost.sentAt,
                readToScheduledToBeSentAt: lastPost.scheduledToBeSentAt,
                updatedAt: serverTimestamp(),
              },
            );
          })();
        }

        notificationUpdatePromise = updateDoc(
          docRef("users", currentUser.id, "inbox", notification.id),
          notificationUpdate,
        );
      }

      if (!args.noToast) {
        // Here prevent the user from undoing anything prior. We're specifically
        // concerned with undoing the sending of a post that notification might
        // be associated with.
        clearUndo();

        const undoAction = async () => {
          triageThread.cache.delete(JSON.stringify(args));

          if (notification) {
            await updateDoc(
              docRef("users", currentUser.id, "inbox", notification.id),
              pick(
                notification,
                "updatedAt",
                "serverUpdatedAt",
                "done",
                "doneAt",
                "doneLastModifiedBy",
                "oldestSentAtValueNotMarkedDone",
                "triaged",
                "triagedUntil",
                "isStarred",
                "starredAt",
              ),
            );
          } else {
            await deleteDoc(
              docRef("users", currentUser.id, "inbox", args.threadId),
            );

            toast("vanilla", {
              subject: "Undone",
              durationMs: 1000,
            });
          }

          if (args.done) {
            const readStatusDoc = await readStatusPromise;

            const readStatusRef = docRef(
              "threads",
              args.threadId,
              "readStatus",
              currentUser.id,
            );

            if (readStatusDoc) {
              await setDoc(readStatusRef, {
                ...readStatusDoc,
                updatedAt: serverTimestamp(),
              });
            } else {
              await setDoc(
                readStatusRef,
                {
                  id: currentUser.id,
                  threadId: args.threadId,
                  readToSentAt: deleteField(),
                  readToScheduledToBeSentAt: deleteField(),
                  updatedAt: serverTimestamp(),
                },
                {
                  merge: true,
                },
              );
            }
          }
        };

        if (args.isUndo) {
          toast("vanilla", {
            subject: "Undone",
            durationMs: 1000,
          });
        } else if (typeof args.triagedUntil !== "undefined") {
          toast("undo", {
            subject: args.triagedUntil ? "Reminder set." : "Reminder removed.",
            onAction: undoAction,
            durationMs: 5000,
          });
        } else if (typeof args.isStarred !== "undefined") {
          toast("undo", {
            subject: args.isStarred ? "Starred thread." : "Unstarred thread.",
            onAction: undoAction,
            durationMs: 5000,
          });
        } else {
          toast("undo", {
            subject: args.done
              ? "Thread marked done."
              : "Thread marked not done.",
            onAction: undoAction,
            durationMs: 5000,
          });
        }
      }

      await offlineAwareFirestoreCRUD(
        Promise.all([notificationUpdatePromise, readStatusUpdatePromise]),
      );
    } finally {
      triageThread.cache.delete(JSON.stringify(args));
    }
  }),
  (args) => JSON.stringify(args),
);

async function createNewPostNotification(args: ITriageThreadArgs) {
  const currentUser = getAndAssertCurrentUser();

  const [thread, inboxSections] = await Promise.all([
    getThread(args.threadId),
    getInboxSections(),
  ]);

  if (thread?.type === "EMAIL_SECRET") {
    console.error(
      "Attempted to triage secret thread. Instead triage the canonical thread " +
        "that this secret thread is associated with.",
      thread,
    );

    throw new Error(`Attempted to triage secret thread`);
  }

  const lastPost = thread?.lastPost;

  if (!lastPost) {
    console.debug(`
      Could not find posts associated with notificationId. 
      Maybe you provided the wrong type of notification?
    `);

    return;
  }

  const isDone = args.done ?? true;
  const docStore = new Map<string, Promise<unknown>>();

  const results = await Promise.all(
    inboxSections.map((inboxSection) => {
      return findMatchingSubsection({
        currentUserId: currentUser.id,
        postId: lastPost.id,
        threadId: lastPost.threadId,
        service: new SearchQueryMatcherService({ docStore }),
        inboxSectionDoc: inboxSection,
        subsectionDocs: inboxSection.subsectionDocs,
      });
    }),
  );

  const tagIds = results
    .filter(isNonNullable)
    .flatMap((r) => [r.section.tagId, r.subsection.tagId]);

  const notificationDraft: WithServerTimestamp<INewPostNotificationDoc> = {
    id: lastPost.threadId,
    type: "new-post",
    postId: lastPost.id,
    postType: lastPost.type,
    from: !lastPost.creatorId
      ? {}
      : {
          [lastPost.creatorId]: {
            type: "user",
            // If the creatorId is known then the name is non-null
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            name: lastPost.creatorName!,
            // If the creatorId is known then the name is non-null
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            email: lastPost.creatorEmail!,
            photoURL: lastPost.creatorPhotoURL,
          },
        },
    fromIds: lastPost.creatorId ? [lastPost.creatorId] : [],
    subject: lastPost.subject,
    summary: lastPost.bodyText.slice(0, 250),
    // If a user navigates to a thread which they don't have an
    // inbox notification for and sets a reminder time for it, I don't
    // think the user would expect that thread to also show up in their
    // inbox. Thus, we mark the generated notification as "done" in this
    // scenerio.
    done: isDone,
    doneAt: isDone ? serverTimestamp() : null,
    doneLastModifiedBy: "user",
    oldestSentAtValueNotMarkedDone: isDone ? null : lastPost.sentAt,
    reason: "user-created",
    priority: 200,
    sentAt: lastPost.sentAt,
    scheduledToBeSentAt: lastPost.scheduledToBeSentAt,
    triaged: !!args.triagedUntil,
    triagedUntil: args.triagedUntil
      ? Timestamp.fromDate(args.triagedUntil)
      : null,
    isStarred: !!args.isStarred,
    starredAt: args.isStarred ? serverTimestamp() : null,
    isFirstPostInThread: lastPost.isFirstPostInThread,
    threadVisibility: thread.visibility,
    tagIds,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    serverUpdatedAt: null,
  };

  let readStatusPromise: Promise<IThreadReadStatusDoc | null> | undefined;
  let readStatusUpdatePromise: Promise<unknown> | undefined;

  if (
    notificationDraft.done &&
    // setting a reminder shouldn't mark a thread as "read"
    !notificationDraft.triaged
  ) {
    readStatusPromise = Promise.resolve(null);
    readStatusUpdatePromise = setDoc(
      docRef("threads", args.threadId, "readStatus", currentUser.id),
      {
        id: currentUser.id,
        threadId: args.threadId,
        seenToSentAt: lastPost.sentAt,
        seenToScheduledToBeSentAt: lastPost.scheduledToBeSentAt,
        readToSentAt: lastPost.sentAt,
        readToScheduledToBeSentAt: lastPost.scheduledToBeSentAt,
        updatedAt: serverTimestamp(),
      },
    );
  }

  const notificationUpdatePromise = setDoc(
    docRef("users", currentUser.id, "inbox", args.threadId),
    validateNewNotification(notificationDraft),
  );

  return {
    notificationDraft,
    notificationUpdatePromise,
    readStatusPromise,
    readStatusUpdatePromise,
  };
}

/* -----------------------------------------------------------------------------------------------*/

export function getInboxSectionQuery(
  parsedInboxSectionFilterQuery?: ParsedToken[] | null,
) {
  const variables = buildGraphQLQueryVariables({
    searchTokens: parsedInboxSectionFilterQuery || [],
    offset: 0,
    allowTopLevelFullTextQuery: false,
  });

  return {
    query: INBOX_SECTION_QUERY,
    variables: {
      where: variables.inboxNotificationsWhere,
    },
  };
}

const INBOX_SECTION_QUERY = graphql(`
  query InboxSectionQuery($where: NotificationNewPostsBoolExp) {
    notificationNewPosts(
      where: $where
      orderBy: [{ sentAt: ASC }, { scheduledToBeSentAt: ASC }]
    ) {
      userFirestoreId
      threadFirestoreId
      messageFirestoreId
      senderUserFirestoreId
      priority
      sentAt
      scheduledToBeSentAt
      hasReminder
      remindAt
      done
      doneAt
      doneLastModifiedBy
      oldestSentAtValueNotMarkedDone
      isStarred
      starredAt
      isFirstMessageInThread
      threadVisibility
      messageType
      createdAt
      updatedAt
      sender {
        firestoreId
        name
        photoUrl
      }
      message {
        firestoreId
        subject
        bodyText
        typeSpecificJson
      }
    }
  }
`);

/* -----------------------------------------------------------------------------------------------*/

export class SearchQueryMatcherService implements ISearchQueryMatcherService {
  static getUserKey(id: string) {
    return `users/${id}`;
  }

  static getPostKey(id: string) {
    return `posts/${id}`;
  }

  static getThreadKey(id: string) {
    return `threads/${id}`;
  }

  static getNewPostNotificationKey(userId: string, threadId: string) {
    return `users/${userId}/inbox/${threadId}`;
  }

  static getThreadReadStatusKey(userId: string, threadId: string) {
    return `threads/${threadId}/readStatus/${userId}`;
  }

  static getChannelKey(id: string) {
    return `channels/${id}`;
  }

  static getTagKey(id: string) {
    return `tags/${id}`;
  }

  private docStore: Map<string, Promise<unknown>>;

  constructor(args: { docStore: Map<string, Promise<unknown>> }) {
    this.docStore = args.docStore;
  }

  getUserDoc(id: string) {
    const key = SearchQueryMatcherService.getUserKey(id);

    if (this.docStore.has(key)) {
      return this.docStore.get(key) as Promise<IUserDoc | null>;
    }

    const query = getDoc(docRef("users", id)).then((s) => s.data() || null);
    this.docStore.set(key, query);
    return query;
  }

  getPostDoc(id: string) {
    const key = SearchQueryMatcherService.getPostKey(id);

    if (this.docStore.has(key)) {
      return this.docStore.get(key) as Promise<IPostDoc | null>;
    }

    const query = getDoc(docRef("posts", id)).then((s) => s.data() || null);
    this.docStore.set(key, query);
    return query;
  }

  getThreadDoc(id: string) {
    const key = SearchQueryMatcherService.getThreadKey(id);

    if (this.docStore.has(key)) {
      return this.docStore.get(key) as Promise<IThreadDoc | null>;
    }

    const query = getDoc(docRef("threads", id)).then((s) => s.data() || null);
    this.docStore.set(key, query);
    return query;
  }

  getNewPostNotificationDoc(userId: string, threadId: string) {
    const key = SearchQueryMatcherService.getNewPostNotificationKey(
      userId,
      threadId,
    );

    if (this.docStore.has(key)) {
      return this.docStore.get(key) as Promise<INotificationDoc | null>;
    }

    const query = getDoc(docRef("users", userId, "inbox", threadId)).then(
      (s) => s.data() || null,
    );

    this.docStore.set(key, query);

    return query;
  }

  getThreadReadStatusDoc(userId: string, threadId: string) {
    const key = SearchQueryMatcherService.getThreadReadStatusKey(
      userId,
      threadId,
    );

    if (this.docStore.has(key)) {
      return this.docStore.get(key) as Promise<IThreadReadStatusDoc | null>;
    }

    const query = getDoc(
      docRef("threads", threadId, "readStatus", userId),
    ).then((s) => s.data() || null);

    this.docStore.set(key, query);

    return query;
  }

  getChannelDoc(id: string) {
    const key = SearchQueryMatcherService.getChannelKey(id);

    if (this.docStore.has(key)) {
      return this.docStore.get(key) as Promise<IChannelDoc | null>;
    }

    const query = getDoc(docRef("channels", id)).then((s) => s.data() || null);
    this.docStore.set(key, query);
    return query;
  }

  getTagDoc(id: string) {
    const key = SearchQueryMatcherService.getTagKey(id);

    if (this.docStore.has(key)) {
      return this.docStore.get(key) as Promise<ITagDoc | null>;
    }

    const query = getDoc(docRef("tags", id)).then((s) => s.data() || null);
    this.docStore.set(key, query);
    return query;
  }

  convertStringToTimestamp(dateString: string): Timestamp | null {
    const dateMatch = dateString.match(/(\d{4})\/(\d{1,2})\/(\d{1,2})/);

    if (!dateMatch) return null;

    const date = new Date(dateMatch[0]);

    if (isNaN(date.valueOf())) return null;

    return Timestamp.fromDate(date);
  }
}

/* -----------------------------------------------------------------------------------------------*/
