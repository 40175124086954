import { IThreadDoc } from "@libs/firestore-models";
import { ComponentType, forwardRef, memo, useMemo } from "react";
import { isEqual } from "@libs/utils/isEqual";
import { IListOnEntryActionEvent, List } from "~/components/list";
import { cx } from "@emotion/css";
import {
  EntryTimestamp,
  navigateToEntry,
  PrivateEntryIcon,
  Recipients,
  StarredEntryIcon,
  Summary,
} from "~/components/content-list";
import { htmlToText } from "@libs/utils/htmlToText";
import {
  IDraftWithThreadData,
  useDraftsBranchedFromPost,
} from "~/services/draft.service";
import { RiGitBranchLine } from "react-icons/ri";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";
import { useThreadsBranchedFromPost } from "~/services/post.service";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { isModKeyActive } from "~/services/command.service";
import { useInboxNotification } from "~/services/inbox.service";
import type { Timestamp } from "@firebase/firestore-types";
import { openLinkInNewTabOrWindow } from "~/utils/navigation-helpers";
import { getPostSenderName } from "@libs/firestore-models/utils";

/* -------------------------------------------------------------------------------------------------
 * ThreadPostEntryBranches
 * -----------------------------------------------------------------------------------------------*/

export const ThreadPostEntryBranches: ComponentType<{
  postId: string;
  isFromBranchId?: IThreadDoc["id"];
}> = memo((props) => {
  const branchedDrafts = useDraftsBranchedFromPost(props.postId);

  const branchedThreads = useThreadsBranchedFromPost(props.postId);

  return (
    <>
      {!props.isFromBranchId &&
        branchedThreads?.map((thread, index) => {
          if (thread.id === props.isFromBranchId) return null;
          return (
            <BranchedThread
              key={thread.id}
              thread={thread}
              relativeOrder={index}
            />
          );
        })}

      {!props.isFromBranchId &&
        branchedDrafts?.map((draft, index) => (
          <BranchedDraft
            key={draft.id}
            draftDoc={draft}
            relativeOrder={index}
          />
        ))}
    </>
  );
}, isEqual);

const collapsedPostCSS = cx(
  "Post flex p-4 sm-w:px-8 sm-w:py-4 my-1",
  "border-l-[3px] border-transparent bg-transparent",
  "focus:outline-none focus:border-black focus:bg-white focus:shadow-lg",
  "hover:cursor-pointer hover:outline-none hover:bg-white hover:shadow-lg",
);

/* -----------------------------------------------------------------------------------------------*/

const BranchedDraft: ComponentType<{
  draftDoc: IDraftWithThreadData;
  relativeOrder: number;
}> = (props) => {
  const isPrivateThread = props.draftDoc.newThread?.visibility === "private";

  const bodyText = useMemo(() => {
    return htmlToText(props.draftDoc.bodyHTML);
  }, [props.draftDoc.bodyHTML]);

  return (
    <List.Entry
      id={props.draftDoc.id}
      data={props.draftDoc}
      relativeOrder={props.relativeOrder}
      onEntryAction={onBranchedDraftEntrySelectNavigateToDraft}
    >
      <BranchedThreadEntryBase
        senderName={props.draftDoc.scheduledToBeSent ? "Me" : "Draft"}
        subject={props.draftDoc.newThread?.subject || ""}
        details={bodyText}
        sentAt={props.draftDoc.createdAt}
        isPrivate={isPrivateThread}
        recipientsCSS={
          props.draftDoc.scheduledToBeSent ? "text-black" : "text-green-9"
        }
      />
    </List.Entry>
  );
};

function onBranchedDraftEntrySelectNavigateToDraft({
  entry,
  event,
}: IListOnEntryActionEvent<IDraftWithThreadData>) {
  const to = `/threads/${entry.threadId}`;

  if (isModKeyActive(event)) {
    if (entry.scheduledToBeSent) {
      openLinkInNewTabOrWindow(to);
    } else {
      openComposeNewThreadDialog(entry.id, {
        inNewWindow: true,
      });
    }

    return;
  }

  if (entry.scheduledToBeSent) {
    navigateToEntry(entry.id, to);
  } else {
    openComposeNewThreadDialog(entry.id);
  }
}

/* -----------------------------------------------------------------------------------------------*/

const BranchedThread: ComponentType<{
  thread: IThreadDoc;
  relativeOrder: number;
}> = memo((props) => {
  const { currentUser } = useAuthGuardContext();
  const lastPost = props.thread.lastPost;
  const notification = useInboxNotification(props.thread.id);
  const isPrivateThread = props.thread.visibility === "private";

  if (!lastPost) return null;

  const senderName = getPostSenderName(lastPost, currentUser.id) || "unknown";

  return (
    <List.Entry
      id={props.thread.id}
      data={props.thread}
      relativeOrder={props.relativeOrder}
      onEntryAction={onThreadSelectNavigateToThread}
    >
      <BranchedThreadEntryBase
        senderName={senderName}
        isPrivate={isPrivateThread}
        isStarred={notification?.isStarred}
        subject={lastPost.subject}
        details={lastPost.bodyText}
        sentAt={lastPost.sentAt}
      />
    </List.Entry>
  );
}, isEqual);

function onThreadSelectNavigateToThread({
  entry,
  event,
}: IListOnEntryActionEvent<IThreadDoc>) {
  const to = `/threads/${entry.id}`;

  if (isModKeyActive(event)) {
    openLinkInNewTabOrWindow(to);
  } else {
    navigateToEntry(entry.id, to);
  }
}

/* -----------------------------------------------------------------------------------------------*/

const BranchedThreadEntryBase = forwardRef<
  HTMLDivElement,
  {
    senderName: string;
    subject: string;
    details: string;
    sentAt: Timestamp;
    isStarred?: boolean;
    isPrivate?: boolean;
    recipientsCSS?: string;
  }
>((props, ref) => {
  const {
    senderName,
    subject,
    details,
    sentAt,
    isStarred,
    isPrivate,
    recipientsCSS,
    ...otherProps
  } = props;

  return (
    <div
      ref={ref}
      className={collapsedPostCSS}
      // Other props is needed by the <Slot> component which `<List.Entry>` uses
      {...otherProps}
    >
      <Recipients
        nonTruncatedPrefix={
          <RiGitBranchLine size="1.5rem" className="mr-2 shrink-0" />
        }
        nonTruncatedSuffix={
          <>
            {isPrivate && <PrivateEntryIcon />}
            {isStarred && <StarredEntryIcon />}
          </>
        }
        className={cx("font-bold", recipientsCSS)}
      >
        {senderName}
      </Recipients>

      <Summary
        subject={subject}
        details={isPrivate ? "private message" : details}
      />

      <EntryTimestamp datetime={sentAt} />
    </div>
  );
});

/* -----------------------------------------------------------------------------------------------*/
