import { registerSW } from "virtual:pwa-register";
import { toast, ToastAction } from "./toast-service";
import { createKeybindingsHandler } from "@libs/tinykeys";
import { filter, firstValueFrom } from "rxjs";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { PLATFORM_MODIFIER_KEY } from "./command.service";
import { PendingUpdates } from "./loading.service";
import { isMaintenanceOccurring } from "./version.service";
import { CURRENT_USER_MAIN_SETTINGS$ } from "./settings.service";

const SwEvents = {
  UPDATING: "updating...",
} as const;

const serviceWorkerUpdatedBroadcastChannel = new BroadcastChannel(
  "Service Worker Updated",
);

serviceWorkerUpdatedBroadcastChannel.onmessage = function (e) {
  if (e.data !== SwEvents.UPDATING) return;
  setTimeout(() => window.location.reload(), 2000);
};

if (import.meta.env.MODE !== "test") {
  /** The update EventListener function if one is attached to the `window` */
  let updateCommandHandler: EventListener | undefined;

  /** callback to remove the current toast notification */
  let removeToastFn: (() => void) | undefined;

  function cleanupUpdateCommandHandler() {
    if (!updateCommandHandler) return;
    window.removeEventListener("keydown", updateCommandHandler);
    updateCommandHandler = undefined;
  }

  const updateSW = registerSW({
    onRegistered(reg) {
      if (!reg) return;

      setInterval(() => {
        console.debug("Checking for sw update");
        reg.update();
      }, 300_000 /* 5m */);
    },
    onNeedRefresh() {
      console.log("Service Worker update available");

      // I believe `onNeedRefresh()` is called each time a new update is
      // available.
      // If the user has the app open, receives an update, doesn't respond
      // to it, and then receives another update, we will have a handler
      // left over from the previous time this function was called which
      // we clean up here.
      cleanupUpdateCommandHandler();

      updateCommandHandler = createKeybindingsHandler({
        // Previously, I chose `$mod+r` as the shortcut (since updating
        // results in the app reloading and Command+R makes clear that
        // the app is going to reload). Unfortunately, Safari doesn't
        // support intercepting `Command+R`.
        "$mod+u": async (e) => {
          e.preventDefault();
          onUpdate();
        },
      });

      window.addEventListener("keydown", updateCommandHandler);

      const onUpdate = onlyCallFnOnceWhilePreviousCallIsPending(async () => {
        console.debug("Updating service worker...");

        // remove the "update available" toast
        removeToastFn?.();

        if (PendingUpdates.value()) {
          removeToastFn = toast("vanilla", {
            subject: "Waiting for background updates to complete...",
            description:
              "If this takes too long, it may mean you manually need to reload the page.",
            durationMs: Infinity,
          });

          // wait for pending updates to complete
          await firstValueFrom(PendingUpdates.value$.pipe(filter((v) => !v)));
        }

        serviceWorkerUpdatedBroadcastChannel.postMessage(SwEvents.UPDATING);

        try {
          await updateSW(true);
        } catch (e) {
          console.error(e);
          cleanupUpdateCommandHandler();
          removeToastFn?.();
          toast("vanilla", {
            subject: "Oops, something went wrong with the update.",
            description: "Try reloading the page and trying again.",
            durationMs: Infinity,
          });
          return;
        }

        // There are some scenerios where the page won't reload
        // when the service worker updates (e.g. the user
        // enters $mod+u but then is warned that they have pending
        // updates and decides to cancel the page reload after the service
        // worker has updated).
        // In these cases, we want to remove this event listener so
        // the user can use $mod+r normally again.
        cleanupUpdateCommandHandler();
        removeToastFn?.();
      });

      removeToastFn = toast("vanilla", {
        subject: "Update available.",
        durationMs: Infinity,
        Action: () => (
          <ToastAction name="Reload">
            <kbd className="mx-1">{PLATFORM_MODIFIER_KEY.symbol({})}</kbd>+
            <kbd className="mx-1">U</kbd>
          </ToastAction>
        ),
        onAction: onUpdate,
      });

      if (isMaintenanceOccurring()) {
        // if maintenance is occurring on Comms and
        // an update is available, we should automatically
        // apply the update
        onUpdate();
      }
    },
    onOfflineReady() {
      console.log("Offline Ready");

      // remove any previous toasts that might exist
      removeToastFn?.();

      toast("vanilla", {
        subject: "Offline ready.",
      });

      cleanupUpdateCommandHandler();
    },
  });

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Subscribe to data necessary for making Offline Mode work adequetly  *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  CURRENT_USER_MAIN_SETTINGS$.subscribe();

  // Disabling inbox and reminder caching because it was significantly
  // impacting performance.
  // In order to allow the user to navigate to all the threads
  // associated with their inbox notifications while offline,
  // we maintain a subscription to the thread data associated with
  // their inbox notifications and triaged notifications.
  // observeInboxSectionNotificationsAndDrafts("DEFAULT")
  //   .pipe(
  //     startWith(() => []),
  //     map(([drafts, notifications]) => [
  //       ...drafts,
  //       ...notifications.flatMap((n) => n.notificationDocs),
  //     ]),
  //     pairwise(),
  //   )
  //   .subscribe(subscribeToNotifications);

  // ALL_TRIAGED_NOTIFICATIONS$.pipe(
  //   startWith(() => []),
  //   pairwise(),
  // ).subscribe(subscribeToNotifications);

  // function subscribeToNotifications([prev, next]: [
  //   INotificationPartial[],
  //   INotificationPartial[],
  // ]) {
  //   const { added, removed } = arrayChange(next, prev, (a, b) => a.id === b.id);

  //   for (const notification of added) {
  //     let sub = notificationSubs.get(notification.id);

  //     if (sub) sub.unsubscribe(); // This shouldn't happen but in case it does

  //     outer: switch (notification.__docType) {
  //       case "INotificationDoc": {
  //         switch (notification.type) {
  //           case "new-post": {
  //             // This is the data requested by ThreadView
  //             const context = threadContextObservables(notification.id);
  //             sub = context.isLoading$.subscribe();
  //             break outer;
  //           }
  //           default: {
  //             logUnknownNotificationType(notification.type);
  //             continue;
  //           }
  //         }
  //       }
  //       case "IUnsafeDraftDoc": {
  //         // This is the data requested by ThreadView
  //         const context = threadContextObservables(notification.threadId);
  //         sub = context.isLoading$.subscribe();
  //         break;
  //       }
  //     }

  //     notificationSubs.set(notification.id, sub);
  //   }

  //   for (const notification of removed) {
  //     notificationSubs.get(notification.id)?.unsubscribe();
  //     notificationSubs.delete(notification.id);
  //   }
  // }

  // const notificationSubs = new Map<string, Subscription>();

  // type INotificationPartial =
  //   | Pick<IDraftWithThreadData, "id" | "threadId" | "__docType">
  //   | Pick<
  //       INotificationNewPostWithLocalAndDraftData,
  //       "id" | "__docType" | "type"
  //     >;

  // function logUnknownNotificationType(type: never) {
  //   console.warn("Unknown notification type", type);
  // }
}
