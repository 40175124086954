import { ComponentType } from "react";
import {
  DialogState,
  DialogTitle,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import {
  createFormControl,
  createFormGroup,
  IFormControl,
  useControl,
} from "solid-forms-react";
import {
  handleSubmit,
  onSubmitFn,
  useControlState,
} from "~/form-components/utils";
import { toast } from "~/services/toast-service";
import { useRegisterCommands } from "~/services/command.service";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { css, cx } from "@emotion/css";
import * as DialogLayout from "~/dialogs/DialogLayout";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import {
  getAndAssertCurrentUser,
  updateCurrentUser,
} from "~/services/user.service";

export type IEditUserProfileData = {} | undefined;

export type IEditUserProfileReturnData = { success: boolean } | void;

export const EditUserProfileState = new DialogState<
  IEditUserProfileData,
  IEditUserProfileReturnData
>();

export const EditUserProfileDialog = withModalDialog({
  dialogState: EditUserProfileState,
  Component() {
    const control = useControl(controlFactory);

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              EditUserProfileState.toggle(false);
            },
          },
          {
            label: "Submit form",
            hotkeys: ["$mod+Enter"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => handleSubmit(control, submit),
          },
        ];
      },
    });

    return (
      <>
        <DialogTitle>
          <h2>Edit user profile</h2>
        </DialogTitle>

        <form
          onSubmit={onSubmitFn(control, submit)}
          className={DIALOG_CONTENT_WRAPPER_CSS}
        >
          <div className="p-4">
            <PhoneNumber control={control.controls.phoneNumber} />
          </div>

          <DialogLayout.DialogFooter>
            <div className="flex-1" />

            <DialogLayout.DialogSubmitButton
              onClick={() => handleSubmit(control, submit)}
            />
          </DialogLayout.DialogFooter>
        </form>

        <DialogLayout.SubmitDialogHint />
      </>
    );
  },
});

/* -----------------------------------------------------------------------------------------------*/

type TForm = ReturnType<typeof controlFactory>;

function controlFactory() {
  const currentUser = getAndAssertCurrentUser();

  return createFormGroup({
    phoneNumber: createFormControl(currentUser.phoneNumber || "", {
      validators: (rawValue) =>
        !rawValue.trim() || isPossiblePhoneNumber(rawValue)
          ? null
          : { invalidPhoneNumber: true },
    }),
  });
}

/* -----------------------------------------------------------------------------------------------*/

const PhoneNumber: ComponentType<{
  control: IFormControl<string>;
}> = (props) => {
  const value = useControlState(() => props.control.value, [props.control]);
  const isInvalid = useControlState(
    () => !props.control.isValid && props.control.isTouched,
    [props.control],
  );

  return (
    <div className="flex flex-col">
      <label htmlFor="phone-number" className="mb-2">
        Your phone number?
      </label>

      <PhoneInput
        id="phone-number"
        international
        placeholder="Phone number"
        value={value}
        onChange={(value) => props.control.setValue(value || "")}
        onBlur={() => props.control.markTouched(true)}
        defaultCountry="US"
        autoFocus
        className={cx(phoneInputCSS, isInvalid && "is-invalid")}
      />
    </div>
  );
};

const phoneInputCSS = css`
  width: 100%;

  & input {
    outline: none;
    border-bottom: 1px solid black;
  }

  &.is-invalid {
    color: red;
    border: 1px solid red;
  }
`;

/* -----------------------------------------------------------------------------------------------*/

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(async (values: TForm["rawValue"]) => {
    console.log("submitting...", values);

    EditUserProfileState.toggle(false, { success: true });

    toast("vanilla", {
      subject: "Updating profile...",
    });

    updateCurrentUser({
      phoneNumber: values.phoneNumber.trim() || null,
    });

    console.log("submitted successfully!");

    toast("vanilla", {
      subject: "Saved.",
    });
  }),
);

/* -----------------------------------------------------------------------------------------------*/
