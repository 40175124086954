import { ComponentType, Ref } from "react";
import {
  DialogState,
  DialogTitle,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { TextInput } from "~/form-components/TextInput";
import {
  IOption,
  TAutocompleteSelectRef,
  useAutocompleteMenuPositioning,
} from "~/form-components/AutocompleteSelect";
import { OrganizationSelect } from "~/form-components/OrganizationSelect";
import {
  sendOrganizationInvite,
  useOrganizations,
} from "~/services/organization.service";
import {
  createFormControl,
  createFormGroup,
  IFormControl,
  useControl,
} from "solid-forms-react";
import {
  handleSubmit,
  onSubmitFn,
  useControlState,
} from "~/form-components/utils";
import { toast } from "~/services/toast-service";
import { isAppOnline } from "~/services/network-connection.service";
import { useRegisterCommands } from "~/services/command.service";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { SubmitDialogHint } from "../DialogLayout";

export const OrganizationInviteDialogState = new DialogState();

interface IFormValue {
  organization: IOption<string> | null;
  email: string;
}

export const OrganizationInviteDialog = withModalDialog({
  dialogState: OrganizationInviteDialogState,
  useOnDialogContainerRendered: () => {
    const organizations = useOrganizations();

    useRegisterCommands({
      commands: () => {
        if (organizations.length === 0) return [];

        return [
          {
            label: "Invite user to organization",
            altLabels: [
              "Send organization invite",
              "Send organization invitation",
            ],
            keywords: [
              "Edit organization members",
              "Update organization members",
            ],
            callback: () => {
              if (!isAppOnline()) {
                toast("vanilla", {
                  subject: "Not supported in offline mode",
                  description: "Can't send organization invites when offline.",
                });

                return;
              }

              OrganizationInviteDialogState.toggle(true);
            },
          },
        ];
      },
      deps: [organizations.length],
    });
  },
  Component: () => {
    const control = useControl(() =>
      createFormGroup({
        organization: createFormControl<IOption<string> | null>(null, {
          validators: (value: IOption<string>) =>
            value ? null : { required: true },
        }),
        email: createFormControl("", {
          required: true,
        }),
      }),
    );

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              OrganizationInviteDialogState.toggle(false);
            },
          },
          {
            label: "Submit form",
            hotkeys: ["$mod+Enter"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              console.log("attempting submit");
              handleSubmit(control, submit);
            },
          },
        ];
      },
    });

    const [
      organizationAutocompleteRef,
      organizationAutocompletePortalEl,
      organizationAutocompletePortalJSX,
    ] = useAutocompleteMenuPositioning<IOption<string>, false>();

    return (
      <>
        <DialogTitle>
          <h2>Send organization invitation</h2>
        </DialogTitle>

        <form
          onSubmit={onSubmitFn(control, submit)}
          className={DIALOG_CONTENT_WRAPPER_CSS}
        >
          <p className="m-4">
            <em>
              <strong>Note:</strong> at the moment only Miz, Sam, and Chin are
              able to invite new users to Comms.
            </em>
          </p>

          <Organization
            autocompleteRef={organizationAutocompleteRef}
            control={control.controls.organization}
            autocompleteMenuEl={organizationAutocompletePortalEl}
          />

          <Email control={control.controls.email} />
        </form>

        {organizationAutocompletePortalJSX}

        <SubmitDialogHint />
      </>
    );
  },
});

const submit = withPendingRequestBar(async (values: IFormValue) => {
  console.log("submitting...", values);

  OrganizationInviteDialogState.toggle(false);

  toast("vanilla", {
    subject: "Sending invite...",
    description: "This may take a minute or two.",
  });

  const result = await sendOrganizationInvite({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    organizationId: values.organization!.value,
    role: "admin",
    email: values.email,
  });

  if (!result.data.success) {
    console.log("submission failed", result);
    toast("vanilla", {
      subject: "Invite failed :(",
    });
    return;
  }

  console.log("submitted successfully!");

  toast("vanilla", {
    subject: `Invitation to ${values.email} sent.`,
  });
});

const Organization: ComponentType<{
  autocompleteRef: Ref<TAutocompleteSelectRef<IOption<string>, false>>;
  control: IFormControl<IOption<string> | null>;
  autocompleteMenuEl?: HTMLDivElement | null;
}> = (props) => {
  const value = useControlState(() => props.control.value, [props.control]);

  const isInvalid = useControlState(
    () => !props.control.isValid,
    [props.control],
  );

  const isTouched = useControlState(
    () => props.control.isTouched,
    [props.control],
  );

  return (
    <div className="flex px-4">
      <OrganizationSelect
        autocompleteRef={props.autocompleteRef}
        label="For"
        value={value}
        multiple={false}
        autoFocus
        error={isInvalid ? "Required." : undefined}
        touched={isTouched}
        autocompleteMenuEl={props.autocompleteMenuEl}
        onBlur={() => props.control.markTouched(true)}
        onChange={(newValue) =>
          props.control.setValue(newValue as IOption<string>)
        }
      />
    </div>
  );
};

const Email: ComponentType<{
  control: IFormControl<string>;
}> = (props) => {
  return (
    <div className="flex px-4">
      <div className="flex flex-1 py-2 border-b border-mauve-5">
        <TextInput
          name="email"
          type="email"
          placeholder="Email address"
          control={props.control}
        />
      </div>
    </div>
  );
};
