import * as Dialog from "@radix-ui/react-dialog";
import { ComponentType, useEffect, useState } from "react";
import { usePortalContext } from "~/services/portal.service";
import { css, cx } from "@emotion/css";
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  Subject,
  switchMap,
  of,
} from "rxjs";
import { NAVIGATION_EVENTS } from "~/services/navigate.service";
import { isEqual } from "@libs/utils/isEqual";
import { updatableBehaviorSubject } from "@libs/utils/updatableBehaviorSubject";
import uid from "@libs/utils/uid";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import {
  INewCommandContextOptions,
  withNewCommandContext,
} from "~/services/command.service";

const openDialogStack$ = updatableBehaviorSubject<
  Array<{
    id: string;
    previouslyFocusedEl: (Element & { focus?(): void }) | null;
  }>
>([]);

export const isAnyDialogOpen$ = openDialogStack$.pipe(
  map((stack) => stack.length > 0),
  shareReplay(1),
);

export interface IDialogOptions<
  P extends Record<string, unknown>,
  Data = unknown,
  ReturnData = unknown,
> {
  dialogState: DialogState<Data, ReturnData>;
  Component: ComponentType<P & { data: Data }>;
  commandContextOptions?: Omit<INewCommandContextOptions<P>, "Component">;
  containerCSS?: string;
  overlayCSS?: string;
  onBackdropClick?: () => void;
  /**
   * Optional async function to load component Data.
   * If data is provided by the dialogState, that data
   * will be passed as an argument to loadData.
   */
  loadData?: (data?: Data) => Promise<Data>;
  modal?: boolean;
  dontCloseOnNavigation?: boolean;
  /**
   * A react hook that should be called when the dialog container is rendered
   * The dialog container is rendered even if the dialog itself is closed.
   * This is useful for registering hotkeys or kbar commands which should
   * be available even when the dialog is closed. However, this hook will
   * only be called when the dialog is included in the current component
   * tree.
   */
  useOnDialogContainerRendered?: (props: P) => void;
}

export function withModalDialog<
  Props extends Record<string, unknown>,
  Data = unknown,
  ReturnData = unknown,
>(
  options: IDialogOptions<Props, Data, ReturnData>,
): ComponentType<Omit<Props, "data">> {
  const {
    Component: _component,
    onBackdropClick = () => options.dialogState.toggle(false),
  } = options;

  const Component = withNewCommandContext({
    updateStrategy: "replace",
    Component: _component,
    ...options.commandContextOptions,
  });

  return (_props) => {
    // type hack
    const props = _props as Props;

    const { container } = usePortalContext();

    const [dialogData, setDialogData] = useState<Data | null | undefined>(
      undefined,
    );

    const isOpen = dialogData !== undefined;

    useEffect(() => {
      const subscription = options.dialogState.isOpen$
        .pipe(
          distinctUntilChanged((a, b) => isEqual(a.isOpen, b.isOpen)),
          switchMap(({ isOpen, data }) => {
            // We need to respond to close events synchronously else
            // it can cause errors in the command service.
            if (!isOpen || !options.loadData) {
              return of({ isOpen, data });
            }

            return withPendingRequestBar(
              options.loadData(data).then((data) => ({
                isOpen,
                data,
              })),
            );
          }),
        )
        .subscribe(({ isOpen, data }) => {
          if (isOpen) {
            setDialogData(data ?? null);
          } else {
            setDialogData(undefined);
          }
        });

      return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
      if (options.dontCloseOnNavigation) return;

      const sub = NAVIGATION_EVENTS.pipe(
        filter(() => options.dialogState.isOpen()),
      ).subscribe(() => {
        options.dialogState.toggle(false);
      });

      return () => sub.unsubscribe();
    }, []);

    options.useOnDialogContainerRendered?.(props);

    return (
      <Dialog.Root open={isOpen} modal={options.modal}>
        <Dialog.Portal container={container}>
          <Dialog.Overlay
            className={options.overlayCSS || DIALOG_OVERLAY_CSS}
            onClick={onBackdropClick}
          />

          <Dialog.Content
            className={options.containerCSS || DIALOG_CONTAINER_CSS}
          >
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            {isOpen && <Component {...props} data={dialogData!} />}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  };
}

export const DIALOG_OVERLAY_CSS =
  "fixed inset-0 bg-blackA-9 width-screen height-screen z-[100]";

const classnamesToCenterModal =
  "fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2";

export const DIALOG_CONTAINER_CSS = cx(
  classnamesToCenterModal,
  "flex flex-col rounded focus:outline-none z-[100]",
  css`
    width: 90vw;
    max-width: 600px;
    max-height: 85vh;
  `,
);

export const DialogTitle: ComponentType<{}> = (props) => {
  return (
    <div className="flex items-center bg-mauveDark-6 text-white px-6 h-11 shrink-0">
      {props.children}
    </div>
  );
};

export const DIALOG_CONTENT_WRAPPER_CSS = cx(
  // Note, we're not including overflow-hidden here because
  // the autocomplete dropdown wants the ability to overflow
  // the modal dialog container.
  "flex flex-col flex-1 bg-white overflow-y-auto",
  css`
    max-height: calc(85vh - 44px);
  `,
);

export class DialogState<Data = unknown, ReturnData = unknown> {
  protected _isOpen$ = new BehaviorSubject<{ isOpen: boolean; data?: Data }>({
    isOpen: false,
  });

  readonly id = uid();

  /**
   * Returns the current open state of the dialog.
   */
  isOpen() {
    return this._isOpen$.getValue().isOpen;
  }

  /**
   * Observable of the dialog's current open state.
   * Immediately returns current state upon subscription.
   */
  readonly isOpen$ = this._isOpen$.asObservable();

  protected _beforeOpen$ = new Subject<Data | undefined>();
  readonly beforeOpen$ = this._beforeOpen$.asObservable();

  protected _afterOpen$ = new Subject<Data | undefined>();
  readonly afterOpen$ = this._afterOpen$.asObservable();

  protected _beforeClose$ = new Subject<ReturnData | undefined>();
  readonly beforeClose$ = this._beforeClose$.asObservable();

  protected _afterClose$ = new Subject<ReturnData | undefined>();
  readonly afterClose$ = this._afterClose$.asObservable();

  /**
   * If called without arguments, toggles the current dialog open state.
   * Otherwise, sets the state to the provided value.
   */
  toggle(isOpen?: true, data?: Data): void;
  toggle(isOpen?: false, data?: ReturnData): void;
  toggle(isOpen?: boolean, data?: Data | ReturnData): void;
  toggle(isOpen?: boolean, data?: Data | ReturnData) {
    const originalValue = this.isOpen();
    const newValue = isOpen ?? !this.isOpen();

    if (newValue) {
      this._beforeOpen$.next(data as Data);
      this.afterBeforeOpenCallback(originalValue);
    } else {
      this._beforeClose$.next(data as ReturnData);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._isOpen$.next({ isOpen: newValue, data: data as any });

    if (newValue) {
      this._afterOpen$.next(data as Data);
    } else {
      this.beforeAfterCloseCallback(originalValue);
      this._afterClose$.next(data as ReturnData);
    }
  }

  protected afterBeforeOpenCallback(originalValue: boolean) {
    if (originalValue) return;

    openDialogStack$.update((value) => {
      return [
        ...value,
        {
          id: this.id,
          previouslyFocusedEl: document.activeElement,
        },
      ];
    });
  }

  protected beforeAfterCloseCallback(originalValue: boolean) {
    if (!originalValue) return;

    // since we're closing the dialog, we need to handle focusing a
    // new element while taking into consideration any other dialogs
    // that are open above or below this one

    const openDialogStack = openDialogStack$.getValue();

    const dialogEntryIndex = openDialogStack.findIndex(
      (entry) => entry.id === this.id,
    );

    const dialogEntry = openDialogStack[dialogEntryIndex];

    if (dialogEntry) {
      const isThereAnOpenDialogAboveThisOne =
        dialogEntryIndex < openDialogStack.length - 1;

      if (isThereAnOpenDialogAboveThisOne) {
        const dialogEntryOnTopOfThisOne =
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          openDialogStack[dialogEntryIndex + 1]!;

        // we mutate the dialog stack entry on top of this one so that, when closed,
        // it focuses the element that was originally focused when this dialog was
        // opened
        dialogEntryOnTopOfThisOne.previouslyFocusedEl =
          dialogEntry.previouslyFocusedEl;
      } else {
        const previouslyFocusedEl = dialogEntry.previouslyFocusedEl;

        if (
          typeof previouslyFocusedEl?.focus === "function" &&
          document.body.contains(previouslyFocusedEl)
        ) {
          previouslyFocusedEl.focus?.();
        } else {
          document.body.focus();
        }
      }

      openDialogStack$.update((value) => {
        return value.filter((v) => v.id !== this.id);
      });
    } else {
      console.error(
        "Could not find dialog stack entry for closed dialog",
        this.id,
      );
    }
  }
}

export function assertDialogDataProvided<T>(
  data?: T,
): asserts data is NonNullable<T> {
  if (!data) {
    throw new Error("Missing required dialog data");
  }
}
