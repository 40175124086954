import { ComponentType, useRef } from "react";
import { ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import {
  ContentList,
  EmptyListMessage,
  onPostSelectNavigateToThread,
  PostEntry,
} from "~/components/content-list";
import { useTopScrollShadow } from "~/utils/useScrollShadow";
import { PendingRequestBar } from "~/components/PendingRequestBar";
import { ICommandArgs, useRegisterCommands } from "~/services/command.service";
import { showNotImplementedToastMsg } from "~/services/toast-service";
import { useSharedMessagesThreads } from "~/services/post.service";
import {
  ESCAPE_TO_INBOX_COMMAND,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";
import { IPostDoc } from "@libs/firestore-models";

export const SharedMessagesView: ComponentType<{}> = () => {
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);

  const threads = useSharedMessagesThreads({
    pagingScrollboxRef: scrollboxRef,
  });

  useRegisterSharedMessagesCommands();

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <>
      <Helmet>
        <title>Shared Messages | Comms</title>
      </Helmet>

      <MainLayout.Header ref={headerRef} className="items-center">
        <h1 className="text-3xl mr-4">Shared Messages</h1>
      </MainLayout.Header>

      <ListScrollbox
        isBodyElement
        offsetHeaderEl={headerRef}
        onlyOffsetHeaderElIfSticky
      >
        {!threads ? (
          <PendingRequestBar>
            <EmptyListMessage text="Nothing yet." />
          </PendingRequestBar>
        ) : threads.length === 0 ? (
          <EmptyListMessage text="Nothing yet." />
        ) : (
          <ContentList<IPostDoc>
            onEntryAction={onPostSelectNavigateToThread}
            className="mb-20"
            autoFocus
          >
            {threads.map((thread, index) => (
              <PostEntry
                key={thread.id}
                post={thread.lastPost}
                thread={thread}
                relativeOrder={index}
              />
            ))}
          </ContentList>
        )}
      </ListScrollbox>
    </>
  );
};

function useRegisterSharedMessagesCommands() {
  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        ESCAPE_TO_INBOX_COMMAND,
        markDoneCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't mark threads Done from the 
              Shared Messages page.
            `);
          },
        }),
        markNotDoneCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't mark threads not Done from the 
              Shared Messages page.
            `);
          },
        }),
        setThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't edit reminders from the 
              Shared Messages page.
            `);
          },
        }),
        removeThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't edit reminders from the 
              Shared Messages page.
            `);
          },
        }),
        starThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't star threads from the
              Shared Messages page.
            `);
          },
        }),
        unstarThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't unstar threads from the
              Shared Messages page.
            `);
          },
        }),
      ];

      return commands;
    },
  });
}
