/**
 * Gets the currently selected DOM elements and returns a copy
 * of them inside a new DIV element. `null` is returned if
 * nothing is currently selected
 *
 * @returns HTMLDIVElement
 */
export function getSelectionAsElement() {
  const selection = getSelection();

  if (!selection?.rangeCount) return null;

  const container = document.createElement("div");

  for (let i = 0; i < selection.rangeCount; ++i) {
    container.appendChild(selection.getRangeAt(i).cloneContents());
  }

  return container;
}
