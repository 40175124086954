import { css, cx } from "@emotion/css";
import { ComponentType, MouseEventHandler } from "react";
import { List, useListContext } from "~/components/list";
import { normalizeCommand } from "~/services/command.service";
import { useObservable } from "~/utils/useObservable";
import { KBarState } from "./KBarState";
import { FaSearch } from "react-icons/fa";
import { ShortcutHint, entryCSS } from "./CommandEntry";
import { navigateService } from "~/services/navigate.service";
import { distinctUntilChanged, map } from "rxjs";

export const SEARCH_COMMAND = normalizeCommand(
  {
    label: "Quick search",
    // we're manually controlling the display of this command
    showInKBar: false,
    hotkeys: ["$mod+/"],
    triggerHotkeysWhenInputFocused: true,
    callback: () => {
      const searchQuery = KBarState.query$.getValue();
      KBarState.toggle(false);
      navigateService(`/search?q=${searchQuery}`);
    },
  },
  1,
);

export const SearchEntry: ComponentType<{
  mode: "hotkey" | "search";
  onClick: MouseEventHandler<HTMLDivElement>;
}> = (props) => {
  const listContext = useListContext();

  const query = useObservable(() => KBarState.query$, {
    synchronous: true,
  });

  const isFocused = useObservable(
    () =>
      // the kbar list is in "active-descendent" mode so we
      // subscribe to the focusableEntryId instead of the
      // focusedEntryId.
      listContext.focusableOrActiveEntryId$.pipe(
        map((id) => id === SEARCH_COMMAND.id),
        distinctUntilChanged(),
      ),
    {
      synchronous: true,
    },
  );

  if (query.length === 0) return null;

  return (
    <>
      <div
        className={cx(
          "text-slate-9 px-8 border-l-2 border-white mt-2 mb-1",
          "font-medium",
        )}
      >
        <small className="text-sm">Search</small>
      </div>

      <List.Entry id={SEARCH_COMMAND.id} data={SEARCH_COMMAND}>
        <div
          id={`kbar-command-${SEARCH_COMMAND.id}`}
          className={entryCSS(isFocused)}
          onClick={props.onClick}
        >
          <div className="flex flex-1 items-center">
            <FaSearch className={iconCSS} />

            <div className="inline text-slate-9">
              Search for "<span className="text-black">{query}</span>"
            </div>
          </div>

          {SEARCH_COMMAND.hotkeys.length > 0 && (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <ShortcutHint mode={props.mode} hint={SEARCH_COMMAND.hotkeys[0]!} />
          )}
        </div>
      </List.Entry>
    </>
  );
};

const iconCSS = cx(
  "mr-3 text-slate-9 pointer-events-none outline-none",
  "w-[22px]",
  css`
    font-size: 1.1rem;

    .hotkey-mode & {
      display: none;
    }
  `,
);
