import { isEqual } from "@libs/utils/isEqual";
import {
  ComponentType,
  ForwardedRef,
  forwardRef,
  memo,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { focusLastListEntry, IListRef } from "~/components/list";
import { IRichTextEditorRef } from "~/form-components/post-editor";
import {
  deleteDraft,
  buildPostDocFromDraftReplyFormValues,
  updateDraftReply,
  getDraftDataStorageKey,
  unsendDraft,
  createDraftReply,
  IPostDocFromDraft,
} from "~/services/draft.service";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { htmlToText } from "@libs/utils/htmlToText";
import { PendingUpdates } from "~/services/loading.service";
import { sessionStorageService } from "~/services/session-storage.service";
import { handleSubmit } from "~/form-components/utils";
import { useComposedRefs } from "~/utils/useComposedRefs";
import { toast } from "~/services/toast-service";
import {
  getCurrentRouterLocation,
  navigateBackOrToInbox,
  navigateBackToMostRecentRouteMatching,
  navigateService,
} from "~/services/navigate.service";
import { useLocation } from "react-router-dom";
import { getAndAssertCurrentUser } from "~/services/user.service";
import { docRef, waitForCacheToContainDoc } from "~/firestore.service";
import {
  callCommandById,
  useRegisterCommands,
  withNewCommandContext,
} from "~/services/command.service";
import { triageThread } from "~/services/inbox.service";
import dayjs from "dayjs";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { IObserveThread } from "~/services/post.service";
import { getSelectionAsHTMLString } from "./getSelectedPostHTMLString";
import { wait } from "@libs/utils/wait";
import { deleteDraftCommand } from "~/utils/common-commands";
import {
  convertCurrentDraftToNewBranchedThreadDraft,
  IThreadRecipients,
  observeThreadRecipients,
  ReplyDraftHeader,
} from "./ReplyDraftHeader";
import {
  ComposePostReplyBase,
  ComposeReplyHint,
  DeleteDraftButton,
  SendDraftButton,
} from "~/components/ComposeReplyBase";
import {
  getSaveDraftFns,
  IComposeMessageForm,
  IComposeMessageFormValue,
  SEND_MESSAGE_COMMAND_ID,
  useAutosaveDraft,
  usePromptToRemoveRecipientOnMentionRemoval,
} from "~/components/ComposeMessageContext";
import {
  createBranchedReplyCommand,
  focusReply,
  getLastPostEntry,
  replyToThreadCommand,
  TThreadTimelineEntry,
} from "./utils";
import { OutlineButton } from "~/components/OutlineButtons";
import { Tooltip } from "~/components/Tooltip";
import {
  combineLatest,
  distinctUntilChanged,
  from,
  map,
  merge,
  NEVER,
  Observable,
  switchMap,
} from "rxjs";
import { observeFocusWithin } from "~/utils/dom-helpers";
import { KBarState } from "~/dialogs/kbar";
import { startWith } from "@libs/utils/rxjs-operators";
import { observeThreadViewPrevNextUrl } from "~/services/thread-prev-next.service";
import { getCurrentUserMainSettings } from "~/services/settings.service";
import {
  observeUsersWhoAreSubscribedToThread,
  useUsersWhoAreSubscribedToThread,
} from "~/services/subscription.service";
import { useUsersWhoCanViewThread } from "~/services/channels.service";
import { ThreadRecipientInfoDialogState } from "~/dialogs/thread-recipient-info/ThreadRecipientInfoDialog";
import { ThreadRecipientInfoDialog } from "~/dialogs/thread-recipient-info/ThreadRecipientInfoDialog";
import { IThreadDoc } from "@libs/firestore-models";
import { Editor } from "@tiptap/core";

/* -------------------------------------------------------------------------------------------------
 * ComposePostReply
 * -----------------------------------------------------------------------------------------------*/

export interface IComposePostReplyProps {
  thread: IObserveThread;
  control: IComposeMessageForm;
  listRef: RefObject<IListRef<TThreadTimelineEntry>>;
  threadRecipients$: Observable<IThreadRecipients>;
  onClose: (post?: IPostDocFromDraft) => void;
  focusOnInit?: boolean;
}

export const ComposePostReply = memo(
  withNewCommandContext({
    forwardRef: true,
    Component: forwardRef(
      (
        props: IComposePostReplyProps,
        ref: ForwardedRef<IRichTextEditorRef>,
      ) => {
        const { control } = props;
        const formRef = useRef<HTMLFormElement>(null);
        const editorRef = useRef<IRichTextEditorRef>(null);
        const wrapperRef = useRef<HTMLDivElement>(null);

        const composedEditorRefs = useComposedRefs(ref, editorRef);

        useAutosaveDraft({
          control,
          saveDraft: saveReplyDraft,
          cancelSaveDraft: cancelSaveReplyDraft,
        });

        useEditorMentionWeightAdjustments(props.thread, editorRef);

        useRegisterComposeReplyCommands({
          thread: props.thread,
          control,
          onClose: props.onClose,
          editorRef,
          formRef,
          listRef: props.listRef,
          wrapperRef,
        });

        usePromptToRemoveRecipientOnMentionRemoval(control);

        return (
          <>
            <ComposePostReplyBase
              ref={composedEditorRefs}
              control={control}
              header={
                <ReplyDraftHeader
                  control={control}
                  thread={props.thread}
                  listRef={props.listRef}
                  threadRecipients$={props.threadRecipients$}
                />
              }
              draftActions={<DraftActions />}
              listRef={props.listRef}
              formRef={formRef}
              focusOnInit={props.focusOnInit}
              className="mt-4"
              onClose={props.onClose}
            />

            <ReplyRecipientInfo thread={props.thread} />

            <ComposeReplyHint />
          </>
        );
      },
    ),
  }),
  isEqual,
);

const DraftActions: ComponentType<{}> = () => {
  return (
    <>
      <SendDraftButton />

      <Tooltip side="bottom" content="Branch Reply (Shift+R)">
        <OutlineButton
          tabIndex={-1}
          onClick={(e) => {
            e.preventDefault();
            callCommandById(createBranchedReplyCommand.id);
          }}
        >
          <small>Branch Reply</small>
        </OutlineButton>
      </Tooltip>

      <div className="flex-1" />

      <DeleteDraftButton />
    </>
  );
};

/* -------------------------------------------------------------------------------------------------
 * useRegisterComposeReplyCommands
 * -----------------------------------------------------------------------------------------------*/

function useRegisterComposeReplyCommands(args: {
  thread: IComposePostReplyProps["thread"];
  listRef: IComposePostReplyProps["listRef"];
  editorRef: RefObject<IRichTextEditorRef>;
  wrapperRef: RefObject<HTMLDivElement>;
  formRef: RefObject<HTMLFormElement>;
  control: IComposeMessageForm;
  onClose: IComposePostReplyProps["onClose"];
}) {
  const location = useLocation();

  useRegisterCommands({
    commands: () => {
      return [
        replyToThreadCommand({
          label: `Focus reply`,
          keywords: ["Reply"],
          callback: () => {
            const selectedHTML = getSelectionAsHTMLString();

            const editor = args.editorRef.current?.editor;

            if (!selectedHTML || !editor) {
              args.editorRef.current?.focus("end", { scrollIntoView: true });
              return;
            }

            const prefix = editor.isEmpty ? "" : "<p></p>";
            const replyText = `${prefix}<blockquote>${selectedHTML}</blockquote><p></p><p></p>`;

            editor
              .chain()
              .focus("end")
              .insertContent(replyText)
              .focus("end", { scrollIntoView: true })
              .run();
          },
        }),
        deleteDraftCommand({
          triggerHotkeysWhenInputFocused: true,
          callback: () => {
            cancelSaveReplyDraft(args.control.rawValue.postId);

            deleteDraft(args.control.rawValue);

            sessionStorageService.setItem(
              getDraftDataStorageKey(args.control.rawValue.postId),
              {
                deleted: true,
              },
            );

            args.onClose();

            const draftLocation = getCurrentRouterLocation();
            const value = args.control.rawValue;

            toast("undo", {
              subject: "Draft deleted.",
              onAction: async () => {
                await newPostReply({
                  thread: args.thread,
                  postId: value.postId,
                  bodyHTML: value.body.content,
                  recipients: value.recipients,
                  userMentions: value.body.userMentions,
                  channelMentions: value.body.channelMentions,
                });

                focusReply(value.postId, draftLocation);
              },
            });
          },
        }),
        {
          id: SEND_MESSAGE_COMMAND_ID,
          label: "Send reply",
          keywords: ["Submit reply"],
          hotkeys: ["$mod+Enter"],
          triggerHotkeysWhenInputFocused: true,
          callback: async (e) => {
            e?.preventDefault();
            e?.stopPropagation();
            handleSubmit(args.control, (values) =>
              submit({
                values,
                thread: args.thread,
                listRef: args.listRef,
                onClose: args.onClose,
              }),
            );
          },
        },
        {
          label: "Send reply immediately",
          keywords: ["Submit reply immediately"],
          callback: async (e) => {
            e?.preventDefault();
            e?.stopPropagation();
            handleSubmit(args.control, (values) =>
              submit({
                values,
                thread: args.thread,
                listRef: args.listRef,
                onClose: args.onClose,
                sendImmediately: true,
              }),
            );
          },
        },
        {
          label: "Blur/cancel reply",
          hotkeys: ["Escape"],
          triggerHotkeysWhenInputFocused: true,
          showInKBar: false,
          callback: () => {
            // While we could simply use a `div` and `innerText` here,
            // the draft service already depends on `htmlToText()` and
            // reusing it provides more consistent HTML to text conversion
            const textContent = htmlToText(args.control.rawValue.body.content);

            const wasComposePostReplyFocused =
              !!args.wrapperRef.current?.contains(document.activeElement);

            focusLastListEntry(args.listRef);

            if (textContent.trim().length !== 0) {
              if (!wasComposePostReplyFocused) {
                navigateBackToMostRecentRouteMatching(
                  (loc) =>
                    !loc.pathname.startsWith("/threads/") &&
                    !loc.pathname.startsWith("/emails/"),
                );
              }

              return;
            }

            cancelSaveReplyDraft(args.control.rawValue.postId);
            deleteDraft(args.control.rawValue);
            sessionStorageService.setItem(
              getDraftDataStorageKey(args.control.rawValue.postId),
              { deleted: true },
            );
            args.onClose();
          },
        },
      ];
    },
    deps: [args.thread, args.control, args.onClose],
  });

  useRegisterCommands({
    commands() {
      return observeThreadViewPrevNextUrl(args.thread, location).pipe(
        map((prevNextState) => [
          {
            label: "Send reply & mark Done",
            keywords: ["Submit reply & mark Done"],
            hotkeys: ["$mod+Shift+Enter"],
            triggerHotkeysWhenInputFocused: true,
            callback: async (e) => {
              e?.preventDefault();
              e?.stopPropagation();

              if (args.control.status !== "VALID") return;

              const postId = args.control.rawValue.postId;
              const draftLocation = getCurrentRouterLocation();

              const submitPromise = handleSubmit(args.control, (values) =>
                submit({
                  values,
                  thread: args.thread,
                  listRef: args.listRef,
                  onClose: args.onClose,
                  noToast: true,
                }),
              );

              const settingsDoc = await getCurrentUserMainSettings();

              const shouldAlsoNavigateBack =
                settingsDoc.enableNavBackOnThreadDone ||
                !prevNextState?.nextUrl;

              let triageDonePromise: Promise<void>;

              if (shouldAlsoNavigateBack) {
                navigateBackOrToInbox();

                // This is a hack. When we mark a message as done and
                // navigate back to the inbox (if we're navigating back
                // to the inbox), we want to focus the next entry in the
                // list. Currently, in order for this to work, when we
                // navigate back we need to be able to refocus the this
                // entry in the list. If this entry has already been marked
                // "Done" and is no longer in the list, then we won't be
                // able to refocus it. But, if it's focused when we mark it
                // done, then we'll automatically focus the next entry in
                // the list. By waiting 100ms after navigating back before
                // marking the message as Done, 95%+ of the time (in testing)
                // we're able to refocus the appropriate message and then
                // transition focus to the next message.
                //
                // A better approach would be a more robust way
                // of tracking, between pages, the inbox entry which
                // should be focused.
                await wait(100);

                triageDonePromise = triageThread({
                  threadId: args.thread.id,
                  done: true,
                  noToast: true,
                }).catch((e) =>
                  console.error("failed to mark thread as done", e),
                );
              } else {
                // Whenever we make a change to the Firestore in-memory cache,
                // anecdotally the cache needs to do some local processing before it
                // allows fetching any more data. This tends to take 1-5 seconds.
                // During this time, if you attempt to load something, you'll see a
                // loading spinner. By navigating to the next thread first, and then
                // waiting a few ms for that thread to load before marking this thread
                // as done, we mitigate the user impact of this behavior.
                navigateService(prevNextState.nextUrl, {
                  state: prevNextState.state,
                });

                await wait(100);

                triageDonePromise = triageThread({
                  threadId: args.thread.id,
                  done: true,
                  noToast: true,
                }).catch((e) =>
                  console.error("failed to mark thread as done", e),
                );
              }

              toast("undo", {
                subject: "Message sent & marked Done!",
                onAction: withPendingRequestBar(async () => {
                  const markNotDonePromise =
                    // To avoid a race condition, we make sure we've finished
                    // marking this thread as Done before undoing marking
                    // it as Done.
                    triageDonePromise
                      .then(() =>
                        triageThread({
                          threadId: args.thread.id,
                          done: false,
                          noToast: true,
                        }),
                      )
                      .catch((e) =>
                        console.error("failed to mark thread as not done", e),
                      );

                  const success =
                    // To avoid a race condition, we make sure we've finished
                    // submitting this thread as Done before unsubmitting it.
                    await submitPromise.then(() => unsendDraft({ postId }));

                  if (!success) {
                    navigateService(draftLocation);
                  } else {
                    focusReply(postId, draftLocation);
                  }

                  await markNotDonePromise;
                }),
              });
            },
          },
        ]),
      );
    },
    deps: [args.thread, location],
  });

  // Observe the draft focus status and update commands
  // accordingly.
  useRegisterCommands({
    commands() {
      const formEl = args.formRef.current;

      if (!formEl) return [];

      // Note that we're emitting open events beforeOpen and afterClose
      const isKbarOpen$ = merge(
        KBarState.beforeOpen$.pipe(map(() => true)),
        KBarState.afterClose$.pipe(map(() => false)),
      ).pipe(
        startWith(() => KBarState.isOpen()),
        distinctUntilChanged(),
      );

      // When the kbar is opened, focus will shift to the kbar.
      // This means that if you're composing a regular reply and then open
      // up the kbar to turn that into a branched reply, our
      // `observeFocusWithin(formEl)` would re-emit and the update
      // we make here to `createBranchedReplyCommand()` would be removed.
      // We don't want that to happen, so we observe the kbar opening and
      // prevent updates to this command due to the kbar opening.
      const isDraftFocused$ = isKbarOpen$.pipe(
        switchMap((isKbarOpen) =>
          isKbarOpen ? NEVER : observeFocusWithin(formEl),
        ),
      );

      return isDraftFocused$.pipe(
        map((isDraftFocused) => {
          if (!isDraftFocused) return [];

          return [
            // This command will override the same command being set in
            // the ThreadPosts component.
            createBranchedReplyCommand({
              label: "Branch reply",
              callback: onlyCallFnOnceWhilePreviousCallIsPending(async () => {
                const lastPostEntry = getLastPostEntry(
                  args.listRef.current?.entries,
                );

                if (!lastPostEntry) return;

                await convertCurrentDraftToNewBranchedThreadDraft({
                  recipients: args.control.rawValue.recipients,
                  lastPost: lastPostEntry,
                  control: args.control,
                });
              }),
            }),
          ];
        }),
      );
    },
    deps: [args.control, args.formRef, args.listRef],
  });
}

/* -----------------------------------------------------------------------------------------------*/

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  async (args: {
    values: IComposeMessageFormValue;
    thread: IComposePostReplyProps["thread"];
    listRef: IComposePostReplyProps["listRef"];
    onClose: IComposePostReplyProps["onClose"];
    sendImmediately?: boolean;
    noToast?: boolean;
  }) => {
    const {
      values,
      thread,
      onClose,
      listRef,
      sendImmediately: sendImmediatelyOption,
      noToast,
    } = args;

    console.log("submitting...", values);

    cancelSaveReplyDraft(values.postId);

    const settings = await getCurrentUserMainSettings();

    const sendImmediately =
      sendImmediatelyOption || settings.secondsForUndoingSentMessage === 0;

    const undoDurationSeconds = settings.secondsForUndoingSentMessage + 10;

    // We want the new post form to close immediately without
    // waiting for this promise to resolve.
    // See `createNewDraftReply` jsdoc.
    updateDraftReply({
      type: values.type,
      postId: values.postId,
      bodyHTML: values.body.content,
      recipients: values.recipients,
      userMentions: values.body.userMentions,
      channelMentions: values.body.channelMentions,
      scheduledToBeSentAt: sendImmediately
        ? new Date()
        : dayjs().add(undoDurationSeconds, "seconds").toDate(),
    })
      .then(() => console.log("submitted successfully!"))
      .catch(console.error);

    const post = await buildPostDocFromDraftReplyFormValues({
      thread,
      postId: values.postId,
      bodyHTML: values.body.content,
      recipients: values.recipients,
      userMentions: values.body.userMentions,
      channelMentions: values.body.channelMentions,
    });

    if (post) {
      const key = getDraftDataStorageKey(post.id);

      sessionStorageService.setItem(key, {
        sent: true,
        post,
      });

      sessionStorageService.deleteItem(key);
    }

    const draftLocation = getCurrentRouterLocation();

    if (!noToast) {
      if (sendImmediately) {
        toast("vanilla", {
          subject: "Message sent!",
        });
      } else {
        toast("undo", {
          subject: "Message sent!",
          durationMs: undoDurationSeconds * 1000,
          onAction: async () => {
            const success = await unsendDraft({ postId: values.postId });

            if (!success) return;

            focusReply(values.postId, draftLocation);
          },
        });
      }
    }

    onClose(post ?? undefined);

    setTimeout(() => focusLastListEntry(listRef), 0);
  },
);

/* -------------------------------------------------------------------------------------------------
 * saveReplyDraft, cancelSaveReplyDraft
 * -----------------------------------------------------------------------------------------------*/

const [saveReplyDraft, cancelSaveReplyDraft] = getSaveDraftFns(function (
  values,
) {
  this.pendingDebouncePostId = null;

  updateDraftReply({
    type: values.type,
    postId: values.postId,
    bodyHTML: values.body.content,
    recipients: values.recipients,
    userMentions: values.body.userMentions,
    channelMentions: values.body.channelMentions,
  }).catch((e) => console.error("updateDraftReply", e));

  PendingUpdates.remove(values.postId);

  return values;
});

/* -------------------------------------------------------------------------------------------------
 * newPostReply
 * -----------------------------------------------------------------------------------------------*/

export async function newPostReply(
  args: Parameters<typeof createDraftReply>[0],
) {
  const currentUser = getAndAssertCurrentUser();

  // We race the result in case of an error. If no error,
  // `waitForCacheToContainDoc()` will generally finish first because of
  // optimistic updates.
  await waitForCacheToContainDoc(
    docRef("users", currentUser.id, "unsafeDrafts", args.postId),
    createDraftReply(args),
  );
}

/* -------------------------------------------------------------------------------------------------
 * ReplyRecipientInfo
 * -----------------------------------------------------------------------------------------------*/

export const ReplyRecipientInfo: ComponentType<{ thread: IThreadDoc }> = (
  props,
) => {
  const subscriberData = useUsersWhoAreSubscribedToThread({
    thread: props.thread,
    onlyCountTheseSubscriptionPreferences,
    dontIncludeCurrentUser: true,
  });

  const willReceiveNotificationCount = subscriberData
    ? subscriberData.knownSubscribers.length +
      subscriberData.unknownSubscribersCount
    : null;

  const canViewData = useUsersWhoCanViewThread(props.thread);

  const usersWhoWillBeAbleToViewCount = canViewData
    ? canViewData.permittedUsers.length + canViewData.unknownPermittedUsersCount
    : null;

  const channelsWhichWillBeAbleToViewMsg =
    !canViewData?.unknownPermittedChannelsCount
      ? ""
      : canViewData.unknownPermittedChannelsCount === 1
      ? "(and 1 channel you don't have access to) "
      : `(and ${canViewData.unknownPermittedChannelsCount} channels you don't have access to) `;

  return (
    <div className="my-8 mx-10 prose text-slate-9">
      <button
        type="button"
        tabIndex={-1}
        className="hover:underline text-left"
        onClick={() => {
          ThreadRecipientInfoDialogState.toggle(true, {
            threadId: props.thread.id,
          });
        }}
      >
        <p>
          <strong>Visibility:</strong>{" "}
          {willReceiveNotificationCount === null ||
          usersWhoWillBeAbleToViewCount === null ? (
            "loading..."
          ) : (
            <>
              {willReceiveNotificationCount}{" "}
              {willReceiveNotificationCount === 1 ? "person" : "people"} will be
              notified of this reply and {usersWhoWillBeAbleToViewCount} other{" "}
              {usersWhoWillBeAbleToViewCount === 1 ? "person" : "people"}{" "}
              {channelsWhichWillBeAbleToViewMsg}will be able to view it. Click
              to learn more.
            </>
          )}
        </p>
      </button>

      <ThreadRecipientInfoDialog threadId={props.thread.id} />
    </div>
  );
};

const onlyCountTheseSubscriptionPreferences = ["all"] as const;

/* -----------------------------------------------------------------------------------------------*/

/**
 * This hook observes the users participating in the current thread and
 * weights them more heavily when determining the autocomplete `@mention`
 * suggestion order.
 */
function useEditorMentionWeightAdjustments(
  thread: IThreadDoc,
  editorRef: RefObject<IRichTextEditorRef | null>,
) {
  useEffect(() => {
    if (!editorRef.current) {
      console.log("no editor ref");
      return;
    }

    const editorObs = from(
      new Promise<Editor>((res, rej) => {
        if (!editorRef.current) {
          rej("no editor ref");
        } else {
          editorRef.current.onCreate.push(res);
        }
      }),
    );

    const sub = combineLatest([
      editorObs,
      observeUsersWhoAreSubscribedToThread({
        thread,
        onlyCountTheseSubscriptionPreferences,
        dontIncludeCurrentUser: true,
      }),
    ]).subscribe(([editor, subscriptionData]) => {
      if (!editor.storage.mention) {
        editor.storage.mention = {};
      }

      editor.storage.mention.mentionWeightAdjustments = {};

      for (const subscriber of subscriptionData.knownSubscribers) {
        editor.storage.mention.mentionWeightAdjustments[subscriber.id] = 0.05;
      }
    });

    return () => sub.unsubscribe();
  }, [thread, editorRef]);
}

/* -------------------------------------------------------------------------------------------------
 * usePreloadThreadRecipients$
 * -----------------------------------------------------------------------------------------------*/

/**
 * Preloads data used by the ComposeReply component to speed up
 * UI
 */
export function usePreloadThreadRecipients$(threadId: string) {
  const [threadRecipients$, setRecipientOptions$] =
    useState<Observable<IThreadRecipients>>(NEVER);

  useEffect(() => {
    const threadRecipients$ = observeThreadRecipients(threadId);
    const sub = threadRecipients$.subscribe();
    setRecipientOptions$(threadRecipients$);

    return () => sub.unsubscribe();
  }, [threadId]);

  return threadRecipients$;
}
