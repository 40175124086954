import { memo } from "react";
import { isEqual } from "@libs/utils/isEqual";
import { entryCSSClasses } from "~/components/content-list";
import { List } from "~/components/list";
import {
  IAcceptedOrganizationMemberDoc,
  IInvitedOrganizationMemberDoc,
} from "~/services/organization.service";

export const MemberEntry = memo<{
  member: IAcceptedOrganizationMemberDoc | IInvitedOrganizationMemberDoc;
  relativeOrder: number;
}>((props) => {
  return (
    <List.Entry<IAcceptedOrganizationMemberDoc | IInvitedOrganizationMemberDoc>
      id={props.member.id}
      data={props.member}
      relativeOrder={props.relativeOrder}
    >
      <div className={entryCSSClasses}>
        <div className="w-full flex items-center">
          {props.member.accepted ? (
            <>
              <span className="flex-1">{props.member.user.name}</span>
              <span className="flex-[4] text-slate-9">
                {props.member.user.email}
              </span>
            </>
          ) : (
            <span className="flex-1">{props.member.lowercaseEmail}</span>
          )}
        </div>
      </div>
    </List.Entry>
  );
}, isEqual);
