import { UnreachableCaseError } from "@libs/utils/errors";
import { isNonNullable } from "@libs/utils/predicates";
import { SetNonNullable } from "@libs/utils/type-helpers";
import uid from "@libs/utils/uid";
import { ComponentType, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";
import { IComposeMessageFormValue } from "~/components/ComposeMessageContext";
import { docRef, waitForCacheToContainDoc } from "~/firestore.service";
import { IEditorMention } from "~/form-components/post-editor";
import {
  convertDraftRecipientToRecipientOption,
  IEmailRecipientOption,
} from "~/form-components/ThreadRecipients";
import {
  createNewDraft,
  IDraft,
  TDraftRecipient,
  useDraft,
} from "~/services/draft.service";
import { getAndAssertCurrentUser } from "~/services/user.service";
import {
  closeComposeNewThreadDialog,
  openComposeNewThreadDialog,
} from "../page-dialog-state";
import { ComposeBranchedThread } from "./branched-thread/ComposeBranchedThread";
import { ComposeNewThread } from "./new-thread/ComposeNewThread";

/* -------------------------------------------------------------------------------------------------
 * ComposeMessageView
 * -----------------------------------------------------------------------------------------------*/

export const ComposeMessageView: ComponentType<{}> = () => {
  const [searchParams] = useSearchParams();
  const draftId = searchParams?.get("compose");

  const isCreatingNewDraft = draftId === "new" || draftId === "new-email";

  const draft = useDraft((!isCreatingNewDraft && draftId) || undefined);

  const dontCloseComposeMessageView = draft !== null || isCreatingNewDraft;

  // Close dialog if draft doesn't exist
  useEffect(() => {
    if (dontCloseComposeMessageView) return;
    closeComposeNewThreadDialog();
    // we only want this useEffect to rerun on draft initialization
  }, [dontCloseComposeMessageView]);

  const initialFormValues = useInitialFormValues({
    type: isCreatingNewDraft ? draftId : "existing",
    draft,
  });

  if (!initialFormValues) {
    return (
      <>
        <Helmet>
          <title>Loading... | Comms</title>
        </Helmet>
      </>
    );
  } else if (initialFormValues.branchedFrom) {
    return (
      <>
        <Helmet>
          <title>New Branched Message | Comms</title>
        </Helmet>

        <ComposeBranchedThread
          initialFormValues={
            initialFormValues as SetNonNullable<
              IComposeMessageFormValue,
              "branchedFrom"
            >
          }
        />
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>New Message | Comms</title>
        </Helmet>

        <ComposeNewThread initialFormValues={initialFormValues} />
      </>
    );
  }
};

/* -----------------------------------------------------------------------------------------------*/

function useInitialFormValues(args: {
  type: "new" | "new-email" | "existing";
  draft?: IDraft | null;
}) {
  const [initialFormValues, setInitialFormValues] = useState<
    IComposeMessageFormValue | undefined
  >();

  /**
   * Creates a new post draft if an existing draft wasn't provided
   * before setting the form values.
   * Otherwise directly sets the initial form values to the existing draft.
   */
  useEffect(() => {
    if (args.type === "new") {
      createAndOpenNewDraft("COMMS").catch(console.error);
    } else if (args.type === "new-email") {
      createAndOpenNewDraft("EMAIL").catch(console.error);
    } else if (!args.draft) {
      return;
    } else if (args.draft.newThread) {
      // We clear the form to ensure we rebuild the form's FormControls
      // setInitialFormValues(undefined);
      getInitialFormValues(args.draft as SetNonNullable<IDraft, "newThread">)
        .then(setInitialFormValues)
        .catch(console.error);
    } else {
      throw new Error(`Expected a draft for a new thread`);
    }
    // Since this is an initialization fn, we only want to run it
    // once per draftId
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args.type, args.draft?.id]);

  return initialFormValues;
}

/* -----------------------------------------------------------------------------------------------*/

async function createAndOpenNewDraft(type: "COMMS" | "EMAIL") {
  const currentUser = getAndAssertCurrentUser();
  const draftId = uid();
  const newDraftPromise = createNewDraft({ type, postId: draftId });

  await waitForCacheToContainDoc(
    docRef("users", currentUser.id, "unsafeDrafts", draftId),
    newDraftPromise,
  );

  openComposeNewThreadDialog(draftId);
}

/* -----------------------------------------------------------------------------------------------*/

async function getInitialFormValues(
  draft: SetNonNullable<IDraft, "newThread">,
) {
  const mapFn = (r: TDraftRecipient) =>
    convertDraftRecipientToRecipientOption(
      r,
      draft.newThread.visibility === "private",
    );

  const toQuery = Promise.all(draft.to.map(mapFn));
  const ccQuery = Promise.all(draft.cc.map(mapFn));
  const bccQuery = Promise.all(draft.bcc.map(mapFn)) as Promise<
    Array<IEmailRecipientOption | null>
  >;

  const [to, cc, bcc] = await Promise.all([toQuery, ccQuery, bccQuery]);

  return {
    type: draft.type,
    postId: draft.id,
    branchedFrom: !draft.branchedFrom
      ? null
      : {
          threadId: draft.branchedFrom.threadId,
          postId: draft.branchedFrom.postId,
          postSentAt: draft.branchedFrom.postSentAt,
          postScheduledToBeSentAt: draft.branchedFrom.postScheduledToBeSentAt,
        },
    visibility: draft.newThread.visibility,
    recipients: {
      to: to.filter(isNonNullable),
      cc: cc.filter(isNonNullable),
      bcc: bcc.filter(isNonNullable),
    },
    subject: draft.newThread.subject,
    body: {
      content: draft.bodyHTML,
      channelMentions: getMentionsFromDraft("channel", draft),
      userMentions: getMentionsFromDraft("user", draft),
    },
  } satisfies IComposeMessageFormValue;
}

/* -----------------------------------------------------------------------------------------------*/

function getMentionsFromDraft(
  type: IEditorMention["type"],
  draft: IDraft,
): IEditorMention[] {
  let draftProp: keyof IDraft;

  switch (type) {
    case "user": {
      draftProp = "mentionedUsers";
      break;
    }
    case "channel": {
      draftProp = "mentionedChannels";
      break;
    }
    default: {
      throw new UnreachableCaseError(type);
    }
  }

  return Object.entries(draft[draftProp]).map(([id, { priority }]) => ({
    id,
    type,
    priority,
  }));
}
