import { ComponentType, memo, useMemo } from "react";
import { List } from "../list";
import { isEqual } from "@libs/utils/isEqual";
import { navigateToEntry } from "./ContentList";
import { IDraft, IDraftWithThreadData } from "~/services/draft.service";
import {
  entryCSSClasses,
  EntryTimestamp,
  PrivateEntryIcon,
  Recipients,
  Summary,
  useShowChannelLabels,
} from "./layout";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { ChannelLabels, LabelChip } from "../ChannelLabels";
import { INavigateServiceOptions } from "~/services/navigate.service";

/* -------------------------------------------------------------------------------------------------
 * onDraftReplySelectNavigateToThread
 * -----------------------------------------------------------------------------------------------*/

export function onDraftReplySelectNavigateToThread(
  draft: IDraft,
  options?: INavigateServiceOptions,
) {
  navigateToEntry(draft.id, `/threads/${draft.threadId}`, options);
}

/* -------------------------------------------------------------------------------------------------
 * DraftEntry
 * -----------------------------------------------------------------------------------------------*/

export const DraftEntry: ComponentType<{
  draft: IDraftWithThreadData;
  relativeOrder: number;
}> = memo(({ draft, relativeOrder }) => {
  const showChannelLabels = useShowChannelLabels();

  const knownRecipientChannels = useKnownRecipientChannels(draft);

  const recipientNames = useDraftRecipientNames({
    draft,
    knownRecipientChannels,
  });

  const isPrivateThread = draft.__local.fromThread.visibility === "private";

  return (
    <List.Entry<IDraft>
      id={draft.id}
      data={draft}
      relativeOrder={relativeOrder}
    >
      <div className={entryCSSClasses}>
        <Recipients
          nonTruncatedSuffix={isPrivateThread && <PrivateEntryIcon />}
        >
          <span className="text-green-9">Draft</span>
          {recipientNames && ` to ${recipientNames}`}
        </Recipients>

        <Summary
          subject={draft.__local.fromThread.subject}
          reply={!draft.isFirstPostInThread}
          details={isPrivateThread ? "private message" : draft.__local.bodyText}
        />

        {showChannelLabels && knownRecipientChannels && (
          <ChannelLabels channels={knownRecipientChannels} />
        )}

        {draft.type === "EMAIL" && (
          <LabelChip tooltip="This is an email thread">#Email</LabelChip>
        )}

        <EntryTimestamp datetime={draft.createdAt} />
      </div>
    </List.Entry>
  );
}, isEqual);

/* -----------------------------------------------------------------------------------------------*/

function useKnownRecipientChannels(draft: IDraftWithThreadData) {
  return useMemo(() => {
    return draft.__local.fromThread.knownRecipientChannels
      .filter((c) => !c.isOrganizationSharedChannel)
      .map((c) => ({ id: c.id, name: c.name }));
  }, [draft.__local.fromThread.knownRecipientChannels]);
}

/* -----------------------------------------------------------------------------------------------*/

function useDraftRecipientNames(args: {
  draft: IDraftWithThreadData;
  knownRecipientChannels: ReturnType<typeof useKnownRecipientChannels>;
}) {
  const { currentUser } = useAuthGuardContext();
  const { draft, knownRecipientChannels } = args;

  return useMemo(() => {
    if (
      knownRecipientChannels.length === 0 &&
      draft.__local.fromThread.recipientUsers.length === 1 &&
      draft.__local.fromThread.recipientUsers[0]?.id === currentUser.id
    ) {
      return [`@me`];
    }

    const channelNames = knownRecipientChannels.map((c) => `#${c.name}`);

    const userNames = draft.__local.fromThread.recipientUsers
      .filter((r) => r.id !== currentUser.id)
      .map((r) => `@${r.name}`);

    return [...userNames, ...channelNames].join(", ");
  }, [
    currentUser,
    knownRecipientChannels,
    draft.__local.fromThread.recipientUsers,
  ]);
}

/* -----------------------------------------------------------------------------------------------*/
