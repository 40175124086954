/**
 * Overview of the various email fields. This interface isn't actually used
 * in Comms, but rather exists for documentation and reference purposes.
 * https://www.rfc-editor.org/rfc/rfc5322
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface EmailMessage {
  /**
   * MUST contain exactly 1 Date header.
   *
   * The origination date specifies the date and time at which the creator
   * of the message indicated that the message was complete and ready to
   * enter the mail delivery system.  For instance, this might be the time
   * that a user pushes the "send" or "submit" button in an application
   * program.  In any case, it is specifically not intended to convey the
   * time that the message is actually transported, but rather the time at
   * which the human or other creator of the message has put the message
   * into its final form, ready for transport.  (For example, a portable
   * computer user who is not connected to a network might queue a message
   * for delivery.  The origination date is intended to contain the date
   * and time that the user queued the message, not the time when the user
   * connected to the network to send the message.)
   */
  Date: Date;
  /**
   * MUST contain exactly 1 From header.
   *
   * The from field consists of the field name "From" and a comma-
   * separated list of one or more mailbox specifications.  If the from
   * field contains more than one mailbox specification in the mailbox-
   * list, then the sender field, containing the field name "Sender" and a
   * single mailbox specification, MUST appear in the message.
   *
   * The "From:" field specifies the author(s) of the message,
   * that is, the mailbox(es) of the person(s) or system(s) responsible
   * for the writing of the message.
   */
  From: EmailAddress[];

  /**
   * MAY contain 1 Sender header. Containing 1 email address or
   * email address group. The stated use case for an email address group
   * as the sender is if you wish to just provide a label as the sender
   * without indicating an address.
   *
   * The "Sender:" field specifies the mailbox of the agent responsible for
   * the actual transmission of the message.  For example, if a secretary
   * were to send a message for another person, the mailbox of the secretary
   * would appear in the "Sender:" field and the mailbox of the actual author
   * would appear in the "From:" field. If the originator of the message can
   * be indicated by a single mailbox and the author and transmitter are
   * identical, the "Sender:" field SHOULD NOT be used.  Otherwise, both
   * fields SHOULD appear.
   *
   * - Note: The transmitter information is always present.  The absence
   *   of the "Sender:" field is sometimes mistakenly taken to mean that
   *   the agent responsible for transmission of the message has not been
   *   specified.  This absence merely means that the transmitter is
   *   identical to the author and is therefore not redundantly placed
   *   into the "Sender:" field.
   */
  Sender?: EmailAddress;

  /**
   * MAY contain 1 Reply-To header. Containing 1 or more email addresses.
   *
   * When the "Reply-To:" field is present, it indicates the address(es)
   * to which the author of the message suggests that replies be sent.
   * In the absence of the "Reply-To:" field, replies SHOULD by default
   * be sent to the mailbox(es) specified in the "From:" field unless
   * otherwise specified by the person composing the reply.
   */
  "Reply-To"?: EmailAddress;

  /**
   * REGARDING REPLIES
   *
   * When a message is a reply to another message, the mailboxes of the
   * authors of the original message (the mailboxes in the "From:" field)
   * or mailboxes specified in the "Reply-To:" field (if it exists) MAY
   * appear in the "To:" field of the reply since these would normally be
   * the primary recipients of the reply.  If a reply is sent to a message
   * that has destination fields, it is often desirable to send a copy of
   * the reply to all of the recipients of the message, in addition to the
   * author.  When such a reply is formed, addresses in the "To:" and
   * "Cc:" fields of the original message MAY appear in the "Cc:" field of
   * the reply, since these are normally secondary recipients of the
   * reply.  If a "Bcc:" field is present in the original message,
   * addresses in that field MAY appear in the "Bcc:" field of the reply,
   * but they SHOULD NOT appear in the "To:" or "Cc:" fields.
   */

  /**
   * MAY contain 1 To header. Containing 1 or more email addresses.
   *
   * The "To:" field contains the address(es) of the primary recipient(s)
   * of the message.
   */
  To?: EmailAddress[];

  /**
   * MAY contain 1 Cc header. Containing 1 or more email addresses.
   *
   * The "Cc:" field (where the "Cc" means "Carbon Copy" in the sense of
   * making a copy on a typewriter using carbon paper) contains the
   * addresses of others who are to receive the message, though the
   * content of the message may not be directed at them.
   */
  Cc?: EmailAddress[];

  /**
   * MAY contain 1 Bcc header. Containing 1 or more email addresses.
   *
   * The "Bcc:" field (where the "Bcc" means "Blind Carbon Copy") contains
   * addresses of recipients of the message whose addresses are not to be
   * revealed to other recipients of the message.  There are three ways in
   * which the "Bcc:" field is used.  In the first case, when a message
   * containing a "Bcc:" field is prepared to be sent, the "Bcc:" line is
   * removed even though all of the recipients (including those specified
   * in the "Bcc:" field) are sent a copy of the message.  In the second
   * case, recipients specified in the "To:" and "Cc:" lines each are sent
   * a copy of the message with the "Bcc:" line removed as above, but the
   * recipients on the "Bcc:" line get a separate copy of the message
   * containing a "Bcc:" line.  (When there are multiple recipient
   * addresses in the "Bcc:" field, some implementations actually send a
   * separate copy of the message to each recipient with a "Bcc:"
   * containing only the address of that particular recipient.)  Finally,
   * since a "Bcc:" field may contain no addresses, a "Bcc:" field can be
   * sent without any addresses indicating to the recipients that blind
   * copies were sent to someone.  Which method to use with "Bcc:" fields
   * is implementation dependent, but refer to the "Security
   * Considerations" section of this document for a discussion of each.
   *
   * Security notes:
   * Many implementations use the "Bcc:" (blind carbon copy) field,
   * described in section 3.6.3, to facilitate sending messages to
   * recipients without revealing the addresses of one or more of the
   * addressees to the other recipients.  Mishandling this use of "Bcc:"
   * may disclose confidential information that could eventually lead to
   * security problems through knowledge of even the existence of a
   * particular mail address.  For example, if using the first method
   * described in section 3.6.3, where the "Bcc:" line is removed from the
   * message, blind recipients have no explicit indication that they have
   * been sent a blind copy, except insofar as their address does not
   * appear in the header section of a message.  Because of this, one of
   * the blind addressees could potentially send a reply to all of the
   * shown recipients and accidentally reveal that the message went to the
   * blind recipient.  When the second method from section 3.6.3 is used,
   * the blind recipient's address appears in the "Bcc:" field of a
   * separate copy of the message.  If the "Bcc:" field sent contains all
   * of the blind addressees, all of the "Bcc:" recipients will be seen by
   * each "Bcc:" recipient.  Even if a separate message is sent to each
   * "Bcc:" recipient with only the individual's address, implementations
   * still need to be careful to process replies to the message as per
   * section 3.6.3 so as not to accidentally reveal the blind recipient to
   * other recipients.
   */
  Bcc?: EmailAddress[];

  /**
   * Every message SHOULD have a "Message-ID:" field, though it is
   * technically optional.
   *
   * The "Message-ID:" field contains a single unique message identifier.
   *
   * The message identifier (msg-id) syntax is a limited version of the
   * addr-spec construct enclosed in the angle bracket characters, "<" and
   * ">".  Unlike addr-spec, this syntax only permits the dot-atom-text
   * form on the left-hand side of the "@" and does not have internal CFWS
   * anywhere in the message identifier.
   *
   * - Note: As with addr-spec, a liberal syntax is given for the right-
   *   hand side of the "@" in a msg-id.  However, later in this section,
   *   the use of a domain for the right-hand side of the "@" is
   *   RECOMMENDED.  Again, the syntax of domain constructs is specified
   *   by and used in other protocols (e.g., [RFC1034], [RFC1035],
   *   [RFC1123], [RFC5321]).  It is therefore incumbent upon
   *   implementations to conform to the syntax of addresses for the
   *   context in which they are used.
   *
   * The "Message-ID:" field provides a unique message identifier that
   * refers to a particular version of a particular message.  The
   * uniqueness of the message identifier is guaranteed by the host that
   * generates it (see below).  This message identifier is intended to be
   * machine readable and not necessarily meaningful to humans.  A message
   * identifier pertains to exactly one version of a particular message;
   * subsequent revisions to the message each receive new message
   * identifiers.
   *
   * - Note: There are many instances when messages are "changed", but
   *   those changes do not constitute a new instantiation of that
   *   message, and therefore the message would not get a new message
   *   identifier.  For example, when messages are introduced into the
   *   transport system, they are often prepended with additional header
   *   fields such as trace fields (described in section 3.6.7) and
   *   resent fields (described in section 3.6.6).  The addition of such
   *   header fields does not change the identity of the message and
   *   therefore the original "Message-ID:" field is retained.  In all
   *   cases, it is the meaning that the sender of the message wishes to
   *   convey (i.e., whether this is the same message or a different
   *   message) that determines whether or not the "Message-ID:" field
   *   changes, not any particular syntactic difference that appears (or
   *   does not appear) in the message.
   *
   * The message identifier (msg-id) itself MUST be a globally unique
   * identifier for a message.  The generator of the message identifier
   * MUST guarantee that the msg-id is unique.  There are several
   * algorithms that can be used to accomplish this.  Since the msg-id has
   * a similar syntax to addr-spec (identical except that quoted strings,
   * comments, and folding white space are not allowed), a good method is
   * to put the domain name (or a domain literal IP address) of the host
   * on which the message identifier was created on the right-hand side of
   * the "@" (since domain names and IP addresses are normally unique),
   * and put a combination of the current absolute date and time along
   * with some other currently unique (perhaps sequential) identifier
   * available on the system (for example, a process id number) on the
   * left-hand side.  Though other algorithms will work, it is RECOMMENDED
   * that the right-hand side contain some domain identifier (either of
   * the host itself or otherwise) such that the generator of the message
   * identifier can guarantee the uniqueness of the left-hand side within
   * the scope of that domain.
   *
   * Semantically, the angle bracket characters are not part of the
   * msg-id; the msg-id is what is contained between the two angle bracket
   * characters.
   */
  "Message-Id"?: string;

  /**
   * MAY have 1 In-Reply-To header. Contains 1 or more Message-Id strings.
   *
   * The "In-Reply-To:" and "References:" fields are used when creating a
   * reply to a message.  They hold the message identifier of the original
   * message and the message identifiers of other messages (for example,
   * in the case of a reply to a message that was itself a reply).  The
   * "In-Reply-To:" field may be used to identify the message (or
   * messages) to which the new message is a reply, while the
   * "References:" field may be used to identify a "thread" of
   * conversation.
   *
   * - The "In-Reply-To:" field will contain the contents of the
   *   "Message-ID:" field of the message to which this one is a reply (the
   *   "parent message").  If there is more than one parent message, then
   *   the "In-Reply-To:" field will contain the contents of all of the
   *   parents' "Message-ID:" fields.  If there is no "Message-ID:" field in
   *   any of the parent messages, then the new message will have no "In-
   *   Reply-To:" field.
   */
  "In-Reply-To"?: string[];

  /**
   * MAY have 1 References header. Contains 1 or more Message-Id strings.
   *
   * The "In-Reply-To:" and "References:" fields are used when creating a
   * reply to a message.  They hold the message identifier of the original
   * message and the message identifiers of other messages (for example,
   * in the case of a reply to a message that was itself a reply).  The
   * "In-Reply-To:" field may be used to identify the message (or
   * messages) to which the new message is a reply, while the
   * "References:" field may be used to identify a "thread" of
   * conversation.
   *
   * The "References:" field will contain the contents of the parent's
   * "References:" field (if any) followed by the contents of the parent's
   * "Message-ID:" field (if any).  If the parent message does not contain
   * a "References:" field but does have an "In-Reply-To:" field
   * containing a single message identifier, then the "References:" field
   * will contain the contents of the parent's "In-Reply-To:" field
   * followed by the contents of the parent's "Message-ID:" field (if
   * any).  If the parent has none of the "References:", "In-Reply-To:",
   * or "Message-ID:" fields, then the new message will have no
   * "References:" field.
   *
   * - Note: Some implementations parse the "References:" field to
   *   display the "thread of the discussion".  These implementations
   *   assume that each new message is a reply to a single parent and
   *   hence that they can walk backwards through the "References:" field
   *   to find the parent of each message listed there.  Therefore,
   *   trying to form a "References:" field for a reply that has multiple
   *   parents is discouraged; how to do so is not defined in this
   *   document.
   */
  References?: string[];

  /**
   * MAY have 1 Subject header. Contains unstructured text.
   *
   * Contains a short string identifying the topic of the message.  When
   * used in a reply, the field body MAY start with the string "Re: "
   * (an abbreviation of the Latin "in re", meaning "in the matter of")
   * followed by the contents of the "Subject:" field body of the
   * original message.  If this is done, only one instance of the literal
   * string "Re: " ought to be used since use of other strings or more
   * than one instance can lead to undesirable consequences.
   */
  Subject?: string;

  /**
   * MAY have 1 Comments header. Contains unstructured text.
   *
   * "Comments:" field contains any additional comments on the text of the
   * body of the message.
   */
  Comments?: string;

  /**
   * MAY have 1 Keywords header. Contains comma-separated words and phrases.
   *
   * The "Keywords:" field contains a comma-separated list of important words
   * and phrases that might be useful for the recipient.
   */
  Keywords?: string[];

  /**
   * RESENT FIELDS
   *
   * Resent fields SHOULD be added to any message that is reintroduced by
   * a user into the transport system.  A separate set of resent fields
   * SHOULD be added each time this is done.  All of the resent fields
   * corresponding to a particular resending of the message SHOULD be
   * grouped together.  Each new set of resent fields is prepended to the
   * message; that is, the most recent set of resent fields appears
   * earlier in the message.  No other fields in the message are changed
   * when resent fields are added.
   *
   * - Callout from John: *the most recent set of resent fields appears
   *   earlier in the message*
   *
   * Each of the resent fields corresponds to a particular field elsewhere
   * in the syntax.  For instance, the "Resent-Date:" field corresponds to
   * the "Date:" field and the "Resent-To:" field corresponds to the "To:"
   * field.  In each case, the syntax for the field body is identical to
   * the syntax given previously for the corresponding field.
   *
   * Resent fields are used to identify a message as having been
   * reintroduced into the transport system by a user.  The purpose of
   * using resent fields is to have the message appear to the final
   * recipient as if it were sent directly by the original sender, with
   * all of the original fields remaining the same.  Each set of resent
   * fields correspond to a particular resending event.  That is, if a
   * message is resent multiple times, each set of resent fields gives
   * identifying information for each individual time.  Resent fields are
   * strictly informational.  They MUST NOT be used in the normal
   * processing of replies or other such automatic actions on messages.
   *
   * - Note: Reintroducing a message into the transport system and using
   *   resent fields is a different operation from "forwarding".
   *   "Forwarding" has two meanings: One sense of forwarding is that a
   *   mail reading program can be told by a user to forward a copy of a
   *   message to another person, making the forwarded message the body
   *   of the new message.  A forwarded message in this sense does not
   *   appear to have come from the original sender, but is an entirely
   *   new message from the forwarder of the message.  Forwarding may
   *   also mean that a mail transport program gets a message and
   *   forwards it on to a different destination for final delivery.
   *   Resent header fields are not intended for use with either type of
   *   forwarding.
   *
   * The resent originator fields indicate the mailbox of the person(s) or
   * system(s) that resent the message.  As with the regular originator
   * fields, there are two forms: a simple "Resent-From:" form, which
   * contains the mailbox of the individual doing the resending, and the
   * more complex form, when one individual (identified in the "Resent-
   * Sender:" field) resends a message on behalf of one or more others
   * (identified in the "Resent-From:" field).
   *
   * - Note: When replying to a resent message, replies behave just as
   *   they would with any other message, using the original "From:",
   *   "Reply-To:", "Message-ID:", and other fields.  The resent fields
   *   are only informational and MUST NOT be used in the normal
   *   processing of replies.
   */

  /**
   * When resent fields are used, the "Resent-From:" and "Resent-Date:"
   * fields MUST be sent.
   *
   * The "Resent-Date:" indicates the date and time at which the resent
   * message is dispatched by the resender of the message.  Like the
   * "Date:" field, it is not the date and time that the message was
   * actually transported.
   */
  "Resent-Date"?: Date;

  /**
   * When resent fields are used, the "Resent-From:" and "Resent-Date:"
   * fields MUST be sent.
   *
   *
   */
  "Resent-From"?: EmailAddress[];

  /**
   * "Resent-Sender:" SHOULD NOT be used if "Resent-Sender:" would be
   * identical to "Resent-From:".
   */
  "Resent-Sender"?: EmailAddress;

  /**
   * The "Resent-To:", "Resent-Cc:", and "Resent-Bcc:" fields function
   * identically to the "To:", "Cc:", and "Bcc:" fields, respectively,
   * except that they indicate the recipients of the resent message, not
   * the recipients of the original message.
   */
  "Resent-To"?: EmailAddress[];

  /**
   * The "Resent-To:", "Resent-Cc:", and "Resent-Bcc:" fields function
   * identically to the "To:", "Cc:", and "Bcc:" fields, respectively,
   * except that they indicate the recipients of the resent message, not
   * the recipients of the original message.
   */
  "Resent-Cc"?: EmailAddress[];

  /**
   * The "Resent-To:", "Resent-Cc:", and "Resent-Bcc:" fields function
   * identically to the "To:", "Cc:", and "Bcc:" fields, respectively,
   * except that they indicate the recipients of the resent message, not
   * the recipients of the original message.
   */
  "Resent-Bcc"?: EmailAddress[];

  /**
   * The "Resent-Message-ID:" field provides a unique identifier for the
   * resent message.
   */
  "Resent-Message-ID"?: string;

  /**
   * OPTIONAL FIELDS
   *
   * Fields may appear in messages that are otherwise unspecified in this
   * document.  They MUST conform to the syntax of an optional-field.
   * This is a field name, made up of the printable US-ASCII characters
   * except SP and colon, followed by a colon, followed by any text that
   * conforms to the unstructured syntax.
   *
   * The field names of any optional field MUST NOT be identical to any
   * field name specified elsewhere in this document.
   *
   * For the purposes of this specification, any optional field is
   * uninterpreted.
   */
}

/**
 * Normally, a mailbox is composed of two parts: (1) an optional display
 * name that indicates the name of the recipient (which can be a person
 * or a system) that could be displayed to the user of a mail
 * application, and (2) an addr-spec address enclosed in angle brackets
 * ("<" and ">").  There is an alternate simple form of a mailbox where
 * the addr-spec address appears alone, without the recipient's name or
 * the angle brackets.  The Internet addr-spec address is described in
 * section 3.4.1.
 *
 * When it is desirable to treat several mailboxes as a single unit
 * (i.e., in a distribution list), the group construct can be used.  The
 * group construct allows the sender to indicate a named group of
 * recipients.  This is done by giving a display name for the group,
 * followed by a colon, followed by a comma-separated list of any number
 * of mailboxes (including zero and one), and ending with a semicolon.
 * Because the list of mailboxes can be empty, using the group construct
 * is also a simple way to communicate to recipients that the message
 * was sent to one or more named sets of recipients, without actually
 * providing the individual mailbox address for any of those recipients.
 *
 * An addr-spec is a specific Internet identifier that contains a
 * locally interpreted string followed by the at-sign character ("@",
 * ASCII value 64) followed by an Internet domain.  The locally
 * interpreted string is either a quoted-string or a dot-atom.  If the
 * string can be represented as a dot-atom (that is, it contains no
 * characters other than atext characters or "." surrounded by atext
 * characters), then the dot-atom form SHOULD be used and the quoted-
 * string form SHOULD NOT be used.  Comments and folding white space
 * SHOULD NOT be used around the "@" in the addr-spec.
 *
 * General format: local-part "@" domain
 *
 * Use cases for group addresses as shown in RFC 6854
 *
 * First, consider an email message that is sent by an automated nightly
 * monitor program, to which replies should not be sent.  Such messages
 * commonly include a valid, replyable address that will discard any
 * replies that are sent to it, but recipients who do reply might be
 * unaware that their replies will be discarded.  If the message is
 * instead presented as follows, the recipients' email clients will not
 * allow them to reply in the first place:
 *
 *    From: Nightly Monitor Robot:;
 *
 * Second, consider an email message that is meant to be "from" the two
 * managing partners of a business, Ben and Carol, and that is sent by
 * their assistant, Dave.  This message could always have been presented
 * this way:
 *
 *    From: ben@example.com,carol@example.com
 *    Sender: dave@example.com
 *
 * This change allows it to be represented this way:
 *
 *    From: Managing Partners:ben@example.com,carol@example.com;
 *    Sender: dave@example.com
 */

export type EmailAddress = Mailbox | MailboxGroup;

export interface Mailbox {
  /** Optional label for the email address */
  label?: string;
  /** The email address. */
  address: string;
  /** only exists for typescript ergonomics */
  addresses?: undefined;
}

export interface MailboxGroup {
  /** Required label of the mailbox group */
  label: string;
  /** only exists for typescript ergonomics */
  address?: undefined;
  /** An array of grouped addresses. May be empty. */
  addresses: Mailbox[];
}

export function stringifyEmailAddress(email: EmailAddress): string {
  if (email.addresses) {
    return `${email.label}: ${email.addresses
      .map(stringifyEmailAddress)
      .join(", ")};`;
  } else {
    return email.label ? `"${email.label}" <${email.address}>` : email.address;
  }
}
