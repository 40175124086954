import { useSearchParams } from "react-router-dom";
import { from, filter, firstValueFrom } from "rxjs";
import { getAndAssertCurrentUser } from "~/services/user.service";
import { useEffect, useState } from "react";
import {
  ALL_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$,
  IAcceptedOrganizationMemberDoc,
} from "~/services/organization.service";
import { USER_CHANNELS$ } from "~/services/channels.service";
import { UnreachableCaseError } from "@libs/utils/errors";
import { IChannelDoc } from "@libs/firestore-models";
import { memoize } from "lodash-es";

/* -------------------------------------------------------------------------------------------------
 * useGetSearchQueryFromURL
 * -----------------------------------------------------------------------------------------------*/

export function useGetSearchQueryFromURL() {
  const [searchParams] = useSearchParams();
  const searchQueryParam = searchParams.get("q") || "";
  const [queryTextAndHTML, setQueryTextAndHTML] = useState<{
    queryAsPlainText: string | null;
    queryAsHTML: string | null;
  }>({
    queryAsPlainText: null,
    queryAsHTML: null,
  });

  // We want to set the input's current value to the
  // queryText on initial page load. Important to run this
  // effect before we focus and select the input.
  useEffect(() => {
    const sub = from(
      mapPlainTextSearchQueryToHTMLQuery(searchQueryParam),
    ).subscribe(setQueryTextAndHTML);

    return () => sub.unsubscribe();
  }, [searchQueryParam]);

  return queryTextAndHTML;
}

/* -----------------------------------------------------------------------------------------------*/

export const mapPlainTextSearchQueryToHTMLQuery = memoize(
  async (searchQueryParam: string) => {
    if (!searchQueryParam) {
      return { queryAsHTML: "", queryAsPlainText: "" };
    }

    const [members, channels] = await Promise.all([
      firstValueFrom(ALL_ACCEPTED_MEMBERS_OF_USERS_ORGANIZATIONS$),
      firstValueFrom(
        USER_CHANNELS$.pipe(filter((channels) => channels.length > 0)),
      ),
    ]);

    const queryAsHTML = mapDocIdsInQuery({
      searchQueryParam,
      channels,
      members,
      map({ subject, priority, subjectId, label }) {
        const priorityNumber =
          priority?.length === 3 ? 100 : priority?.length === 2 ? 200 : 300;

        const html =
          `<span data-type="mention" class="search-filter" data-id="` +
          subjectId +
          `" data-label="${label}" data-subject="` +
          subject +
          `" data-priority="` +
          priorityNumber +
          `" contenteditable="false">${priority}${label}</span>`;

        return html;
      },
    })
      .map((text) => `<p>${text}</p>`)
      .join("");

    const queryAsPlainText = mapDocIdsInQuery({
      searchQueryParam,
      channels,
      members,
      map({ priority, label }) {
        return `${priority}${label}`;
      },
    }).join("");

    mapPlainTextSearchQueryToHTMLQuery.cache.delete(searchQueryParam);

    return { queryAsHTML, queryAsPlainText };
  },
);

/* -----------------------------------------------------------------------------------------------*/

function mapDocIdsInQuery(args: {
  searchQueryParam: string;
  members: IAcceptedOrganizationMemberDoc[];
  channels: IChannelDoc[];
  map: (args: {
    subject: string | undefined;
    priority: string | undefined;
    subjectId: string | undefined;
    label: string | undefined;
  }) => string;
}) {
  const currentUser = getAndAssertCurrentUser();
  const { searchQueryParam, members, channels, map: mapDocIDsFn } = args;

  return searchQueryParam.split("\n").map((text) => {
    let match: RegExpMatchArray | null;

    // match `<#`, then match any character until `::`,
    // then match `@` or `@@` or `@@@` (or `#` or `##` or `###`),
    // then match `::`, then match any character until `>`.
    const regex = /<#[^:]*::(?:@@?@?|##?#?)::[^>]*>/;

    while ((match = text.match(regex))) {
      const [subject, priority, subjectId] = match[0].slice(2, -1).split("::");

      let label: string | undefined;

      if (subject === "user") {
        const member = members.find((m) => m.id === subjectId);

        label =
          currentUser.id === member?.id ? "me" : member?.user.name || "unknown";
      } else if (subject === "channel") {
        const channel = channels.find((m) => m.id === subjectId);

        label = channel?.name || "unknown";
      } else {
        throw new UnreachableCaseError(subject as never);
      }

      text = text.replace(
        `<#${subject}::${priority}::${subjectId}>`,
        mapDocIDsFn({
          subject,
          priority:
            subject === "user"
              ? priority
              : priority?.split("").fill("#").join(""),
          subjectId,
          label,
        }),
      );
    }

    return text;
  });
}

/* -----------------------------------------------------------------------------------------------*/
