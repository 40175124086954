import { ComponentType, useRef } from "react";
import { triageThread } from "~/services/inbox.service";
import { IListRef, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import {
  ContentList,
  EmptyListMessage,
  useKBarAwareFocusedEntry,
  NotificationEntry,
  onNotificationSelectNavigateToPost,
  useRegisterNotificationEntryCommands,
} from "~/components/content-list";
import { useTopScrollShadow } from "~/utils/useScrollShadow";
import { PendingRequestBar } from "~/components/PendingRequestBar";
import { ICommandArgs, useRegisterCommands } from "~/services/command.service";
import { RemindMeDialogState } from "~/dialogs/remind-me";
import {
  ESCAPE_TO_INBOX_COMMAND,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import { useStarredNotifications } from "~/services/inbox.service";
import * as MainLayout from "~/page-layouts/main-layout";
import { INotificationDoc } from "@libs/firestore-models";

export const StarredView: ComponentType<{}> = () => {
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);

  const notifications = useStarredNotifications({
    pagingScrollboxRef: scrollboxRef,
  });

  const listRef = useRef<IListRef<INotificationDoc>>(null);

  useRegisterNotificationEntryCommands({
    priority: { delta: 1 },
    listRef,
    deps: [notifications?.length],
  });

  const [focusedNotification, setFocusedNotification] =
    useKBarAwareFocusedEntry<INotificationDoc>();

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [ESCAPE_TO_INBOX_COMMAND];

      if (focusedNotification) {
        commands.push(
          markDoneCommand({
            callback: () => {
              triageThread({
                threadId: focusedNotification.id,
                done: true,
              });
            },
          }),
          markNotDoneCommand({
            callback: () => {
              triageThread({
                threadId: focusedNotification.id,
                done: false,
              });
            },
          }),
          setThreadReminderCommand({
            callback: () => {
              RemindMeDialogState.toggle(true, {
                id: focusedNotification.id,
                triagedUntil:
                  focusedNotification.triagedUntil?.toDate() || null,
                isStarred: focusedNotification.isStarred,
              });
            },
          }),
        );

        if (focusedNotification.triaged) {
          commands.push(
            removeThreadReminderCommand({
              callback: () => {
                triageThread({
                  threadId: focusedNotification.id,
                  triagedUntil: null,
                });
              },
            }),
          );
        }

        if (focusedNotification.isStarred) {
          commands.push(
            unstarThreadCommand({
              callback: () => {
                triageThread({
                  threadId: focusedNotification.id,
                  isStarred: false,
                });
              },
            }),
          );
        } else {
          commands.push(
            starThreadCommand({
              callback: () => {
                triageThread({
                  threadId: focusedNotification.id,
                  isStarred: true,
                });
              },
            }),
          );
        }
      }

      return commands;
    },
    deps: [focusedNotification],
  });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <>
      <Helmet>
        <title>Starred | Comms</title>
      </Helmet>

      <MainLayout.Header ref={headerRef}>
        <h1 className="text-3xl">Starred</h1>
      </MainLayout.Header>

      <ListScrollbox
        isBodyElement
        offsetHeaderEl={headerRef}
        onlyOffsetHeaderElIfSticky
      >
        {!notifications ? (
          <PendingRequestBar>
            <EmptyListMessage text="Nothing yet." />
          </PendingRequestBar>
        ) : notifications.length === 0 ? (
          <EmptyListMessage text="Nothing yet." />
        ) : (
          <ContentList<INotificationDoc>
            ref={listRef}
            onEntryFocused={setFocusedNotification}
            onEntryAction={onNotificationSelectNavigateToPost}
            className="mb-20"
            autoFocus
          >
            {notifications.map((notification, index) => (
              <NotificationEntry
                key={notification.id}
                notification={notification}
                relativeOrder={index}
              />
            ))}
          </ContentList>
        )}
      </ListScrollbox>
    </>
  );
};
