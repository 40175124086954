import {
  IAbstractNotificationDoc,
  INewPostNotificationDoc,
  INotificationDoc,
} from "@libs/firestore-models";
import { useMemo, memo, ComponentType, useState, RefObject } from "react";
import {
  IListOnEntryActionEvent,
  IListRef,
  List,
  useListContext,
} from "../list";
import { isEqual } from "@libs/utils/isEqual";
import { navigateToEntry } from "./ContentList";
import {
  Recipients,
  Summary,
  DisplayDate,
  entryCSSClasses,
  useShowChannelLabels,
  PrivateEntryIcon,
  StarredEntryIcon,
} from "./layout";
import { map } from "rxjs";
import { UnreachableCaseError } from "@libs/utils/errors";
import {
  ChannelLabels,
  LabelChip,
  useThreadChannelNames,
} from "../ChannelLabels";
import {
  ICommandArgs,
  isModKeyActive,
  useRegisterCommands,
} from "~/services/command.service";
import { openLinkInNewTabOrWindow } from "~/utils/navigation-helpers";
import { cx } from "@emotion/css";
import { INavigateServiceOptions } from "~/services/navigate.service";
import { Avatar } from "../Avatar";
import { GrCheckbox, GrCheckboxSelected } from "react-icons/gr";
import {
  markDoneCommand,
  markNotDoneCommand,
  removeThreadReminderCommand,
  setThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import { triageThread } from "~/services/inbox.service";
import { showNotImplementedToastMsg, toast } from "~/services/toast-service";

export function onNotificationSelectNavigateToPost(
  {
    entry,
    event,
  }: IListOnEntryActionEvent<Pick<INotificationDoc, "type" | "id">>,
  options?: INavigateServiceOptions,
) {
  let to: string;

  switch (entry.type) {
    case "new-post": {
      to = `/threads/${entry.id}`;
      break;
    }
    default: {
      throw new UnreachableCaseError(entry.type);
    }
  }

  if (isModKeyActive(event)) {
    openLinkInNewTabOrWindow(to);
  } else {
    navigateToEntry(entry.id, to, options);
  }
}

export const NotificationEntry: ComponentType<{
  notification: IAbstractNotificationDoc;
  hasDraft?: boolean;
  relativeOrder: number;
}> = memo(({ notification, hasDraft, relativeOrder }) => {
  const from = useMemo(() => {
    return Object.values(notification.from)
      .map((from) => from.name)
      .join(", ");
  }, [notification.from]);

  const fromPhotoURL = useMemo(() => {
    return Object.values(notification.from).at(0)?.photoURL;
  }, [notification.from]);

  const knownRecipientChannels = useThreadChannelNames(
    notification.type === "new-post" ? notification.id : undefined,
  );

  const showChannelLabels = useShowChannelLabels();

  const [isChecked, setIsChecked] = useState(false);

  const listContext = useListContext();

  const isPrivateThread =
    isNewPostNotification(notification) &&
    notification.threadVisibility === "private";

  const subject =
    isNewPostNotification(notification) &&
    !notification.isFirstPostInThread &&
    notification.postType === "COMMS"
      ? `Re: ${notification.subject}`
      : notification.subject;

  return (
    <List.Entry<IAbstractNotificationDoc>
      key={notification.id}
      id={notification.id}
      data={notification}
      relativeOrder={relativeOrder}
      onEntrySelectionChange={(event) => {
        setIsChecked(event.isSelected);
      }}
    >
      <div className={cx(entryCSSClasses, isChecked && "is-checked")}>
        {isChecked ? (
          <GrCheckboxSelected
            size={30}
            className="p-1"
            onClick={(e) => {
              e.stopPropagation();
              listContext.deselect(notification.id);
            }}
          />
        ) : (
          <>
            <Avatar
              label={from}
              photoURL={fromPhotoURL}
              width="30px"
              className="group-hover:hidden"
            />

            <GrCheckbox
              size={30}
              className="hidden group-hover:block p-1"
              onClick={(e) => {
                e.stopPropagation();
                listContext.select(notification.id);
              }}
            />
          </>
        )}

        <Recipients
          nonTruncatedSuffix={
            <>
              {hasDraft && (
                <span className="text-green-9 ml-2 shrink-0">(+ draft)</span>
              )}

              {isPrivateThread && <PrivateEntryIcon />}
              {notification.isStarred && <StarredEntryIcon />}
            </>
          }
        >
          {from}
        </Recipients>

        <Summary
          subject={subject}
          details={isPrivateThread ? "private message" : notification.summary}
        />

        {showChannelLabels && knownRecipientChannels && (
          <ChannelLabels channels={knownRecipientChannels} />
        )}

        {notification?.type === "new-post" &&
          (notification as INewPostNotificationDoc).postType === "EMAIL" && (
            <LabelChip tooltip="This is an email thread">#Email</LabelChip>
          )}

        <NotificationTimestamp notification={notification} />
      </div>
    </List.Entry>
  );
}, isEqual);

function isNewPostNotification(
  notification: IAbstractNotificationDoc,
): notification is INewPostNotificationDoc {
  return notification.type === "new-post";
}

export const NotificationTimestamp: ComponentType<{
  notification: Pick<
    IAbstractNotificationDoc,
    "doneLastModifiedBy" | "triagedUntil" | "sentAt"
  >;
}> = (props) => {
  const wrapperCSS = "flex items-center text-sm";

  if (props.notification.triagedUntil) {
    return (
      <div className={wrapperCSS}>
        <span className="text-plumA-8">
          Remind me:{" "}
          <span className="uppercase">
            <DisplayDate date={props.notification.triagedUntil} />
          </span>
        </span>
      </div>
    );
  }

  const isInInboxDueToReminder =
    props.notification.doneLastModifiedBy === "reminder";

  return (
    <div className={wrapperCSS}>
      <span
        className={cx(
          "uppercase",
          isInInboxDueToReminder ? "text-plumA-8" : "text-slateA-9",
        )}
      >
        <DisplayDate date={props.notification.sentAt} />
      </span>
    </div>
  );
};

export function useRegisterNotificationEntryCommands<
  T extends Pick<INotificationDoc, "id" | "type">,
>(args: {
  priority?: number | { delta: number };
  listRef: RefObject<IListRef<T>>;
  /**
   * An optional function that filters the list entries to return only
   * INotificationDocs.
   */
  filterFn?: (entry: T) => boolean;
  deps?: unknown[];
}): void;
export function useRegisterNotificationEntryCommands<A>(args: {
  priority?: number | { delta: number };
  listRef: RefObject<IListRef<A>>;
  /**
   * An optional function that filters the list entries to return only
   * INotificationDocs.
   */
  // @ts-expect-error - typescript requires a type predicate to extend the input type for no apparent reason
  filterFn?: (entry: A) => entry is Pick<INotificationDoc, "id" | "type">;
  deps?: unknown[];
}): void;
export function useRegisterNotificationEntryCommands<
  T extends Pick<INotificationDoc, "id" | "type">,
>(args: {
  priority?: number | { delta: number };
  listRef: RefObject<IListRef<T>>;
  /**
   * An optional function that filters the list entries to return only
   * INotificationDocs.
   */
  filterFn?: (entry: T) => boolean;
  deps?: unknown[];
}): void {
  const { listRef } = args;

  useRegisterCommands({
    id: "notification-entry-commands",
    priority: args.priority,
    commands() {
      const list = listRef.current;

      if (!list) return [];

      return list.selectedEntryIds$.pipe(
        map((ids) => {
          const commands: ICommandArgs[] = [];

          if (ids.size === 0) return commands;

          const selectedEntries = list.entries.filter((entry) =>
            ids.has(entry.id),
          );

          const selectedNotificationEntries = args.filterFn
            ? selectedEntries.map((entry) => entry.data).filter(args.filterFn)
            : selectedEntries.map((entry) => entry.data);

          commands.push(
            {
              label: "Unselect notifications",
              altLabels: ["Clear selection"],
              hotkeys: ["Escape"],
              callback() {
                list.deselectAll();
              },
            },
            markDoneCommand({
              label: "mark all done",
              callback() {
                markMultipleNotificationsDone({
                  done: true,
                  notifications: selectedNotificationEntries,
                });
              },
            }),
            markNotDoneCommand({
              label: "mark all not done",
              callback() {
                markMultipleNotificationsDone({
                  done: false,
                  notifications: selectedNotificationEntries,
                });
              },
            }),
            setThreadReminderCommand({
              callback: () => {
                showNotImplementedToastMsg(
                  `You can't yet set a reminder on multiple notifications at once.`,
                );
              },
            }),
            removeThreadReminderCommand({
              callback: () => {
                showNotImplementedToastMsg(
                  `You can't yet remove a reminder on multiple notifications at once.`,
                );
              },
            }),
            starThreadCommand({
              callback: () => {
                showNotImplementedToastMsg(
                  `You can't yet star multiple notifications at once.`,
                );
              },
            }),
            unstarThreadCommand({
              callback: () => {
                showNotImplementedToastMsg(
                  `You can't yet unstar multiple notifications at once.`,
                );
              },
            }),
          );

          return commands;
        }),
      );
    },
    deps: [listRef, ...(args.deps || [])],
  });
}

async function markMultipleNotificationsDone({
  notifications,
  done,
}: {
  notifications: Array<Pick<INotificationDoc, "id" | "type">>;
  done: boolean;
}) {
  toast("undo", {
    subject: `Marking ${notifications.length} ${
      notifications.length === 1 ? "notification" : "notifications"
    } ${done ? "done" : "not done"}`,
    onAction() {
      showNotImplementedToastMsg(`
        Unfortunately, you can't yet undo bulk actions.
      `);
    },
    durationMs: 5000,
  });

  return Promise.allSettled(
    notifications.map((notification) => {
      switch (notification.type) {
        case "new-post": {
          return triageThread({
            threadId: notification.id,
            done,
            noToast: true,
          });
        }
        default: {
          throw new UnreachableCaseError(notification.type);
        }
      }
    }),
  );
}
