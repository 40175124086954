import uid from "@libs/utils/uid";
import {
  deleteObject,
  getDownloadURL,
  uploadBytesResumable,
  UploadTask,
  UploadTaskSnapshot,
  StorageError,
} from "firebase/storage";
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  finalize,
  from,
  lastValueFrom,
  map,
  merge,
  NEVER,
  Observable,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throttleTime,
} from "rxjs";
import { docRef, storageRef } from "~/firestore.service";
import {
  CURRENT_USER$,
  getAndAssertCurrentUser,
} from "~/services/user.service";
import Compressor from "compressorjs";
import Dexie, { liveQuery } from "dexie";
import { withPendingUpdate } from "./loading.service";
import { getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { observeDraft } from "./draft.service";
import { APP_ONLINE$, isAppOnline } from "./network-connection.service";
import { IS_LEADER$ } from "./tab-leader.service";
import { isEqual } from "@libs/utils/isEqual";
import { wait } from "@libs/utils/wait";
import { resolvablePromise } from "@libs/utils/resolvablePromise";

/**
 * Some image upload scenerios we handle
 *
 * 1. When the user deletes the image, cleanup the local cache as
 *    well as Firebase storage.
 * 2. When the image finishes uploading, the upload service should
 *    update the draft body to insert the new image src. If the user
 *    is actively editing the draft, then our custom tiptop image
 *    extension will also do this, but if they aren’t editing the
 *    draft then this is necessary in order to support “optimistic
 *    sending”.
 * 3. If the user uploads an image, then loses internet connection
 *    and deletes the image, we need to cleanup Firebase storage.
 * 4. If the user starts uploading an image but then closes the tab
 *    responsible for the upload
 * 5. If the user attempts to upload an image but receives an
 *    unrecoverable error (e.g. the image is corrupted or something),
 *    we should warn the user and then delete the image from the
 *    draft and cleanup the local data.
 * 6. When the user sends a post, make sure to cleanup all unused
 *    data in Firebase storage and also clean up the local cache.
 */

interface IFileUpload {
  id: string;
  postId: string;
  file: File | Blob;
  fileType: string;
  fileName: string;
  fileWidth: number;
  fileHeight: number;
  url: string | null;
  uploadProgress: number;
  downloadProgress: number;
  updatedAt: number;
  creatorId: string;
  error?: string;
}

interface IFileUploadWithPreviewURL extends IFileUpload {
  previewUrl: string;
}

export class ImageCache extends Dexie {
  postImages!: Dexie.Table<IFileUpload, string>;

  constructor(
    /** This database is namespaced to the current user's id */
    userId: string,
  ) {
    super(`ImageCache-${userId}`);
    this.version(1).stores({
      postImages: "id, postId, updatedAt",
    });
  }
}

let db: ImageCache;

export function initializeDexie(userId: string) {
  db = new ImageCache(userId);
  return db;
}

export function initializeFileUploadService() {
  const dexieInitialized$ = CURRENT_USER$.pipe(
    map((user) => user?.id),
    distinctUntilChanged(),
    map((userId) => {
      if (userId) {
        return initializeDexie(userId);
      } else if (db) {
        db.close();
      }

      return null;
    }),
    shareReplay(1),
  );

  // Process image uploads if the client is the leader and is also online
  combineLatest([dexieInitialized$, IS_LEADER$, APP_ONLINE$])
    .pipe(
      switchMap(([db, isLeader, onLine]) => {
        if (!db || !isLeader || !onLine) return NEVER;

        return from(liveQuery(() => db.postImages.toArray()));
      }),
    )
    .subscribe((imageDocs) => {
      for (const imageDoc of imageDocs) {
        if (imageDoc.error) continue;
        if (activeUploads.has(imageDoc.id)) continue;
        if (activeDownloads.has(imageDoc.id)) continue;

        if (imageDoc.uploadProgress === 100) {
          getImgDownloadURLAndUpdatePostBodyHTML(imageDoc).catch((e) => {
            console.error("Error downloading image", e);
          });
        } else {
          uploadImageToFirebaseStorage(imageDoc).catch((err) =>
            console.error("Error uploading image", err),
          );
        }
      }
    });

  // Monitor the drafts associated with cached images and delete
  // the cached image when the draft is deleted
  dexieInitialized$
    .pipe(
      switchMap((db) => {
        if (!db) return NEVER;

        return from(liveQuery(() => db.postImages.toArray())).pipe(
          map((images) =>
            images.map((image) => ({ id: image.id, postId: image.postId })),
          ),
          distinctUntilChanged(isEqual),
          switchMap((images) =>
            merge(
              ...images.map((image) =>
                observeDraft(image.postId).pipe(
                  tap((draft) => {
                    if (draft) return;

                    removeCachedImage(image.id).catch(console.error);
                  }),
                ),
              ),
            ),
          ),
        );
      }),
    )
    .subscribe();
}

if (import.meta.env.MODE !== "test") {
  initializeFileUploadService();
}

const KB = 1024;
const MB = KB * 1024;

export const onImageDrop = withPendingUpdate(
  async (
    file: File,
    postId: string,
  ): Promise<
    { imageId: string; width: number; height: number } | undefined
  > => {
    const imageId = uid();

    const imageFile = await resizeImage(file);

    if (!imageFile) return;

    const imageDimensions = await preloadAndGetImageDimensions(imageFile);

    const currentUser = getAndAssertCurrentUser();

    const doc: IFileUpload = {
      id: imageId,
      postId,
      creatorId: currentUser.id,
      file: imageFile,
      fileType: imageFile.type,
      fileName: file.name,
      fileWidth: imageDimensions.width,
      fileHeight: imageDimensions.height,
      url: null,
      uploadProgress: 0,
      downloadProgress: 0,
      updatedAt: Date.now(),
    };

    await putCachedImage(doc);

    return { imageId, ...imageDimensions };
  },
);

/**
 * Map object where the keys are `IFileUpload` IDs and
 * the values are active Firestore `UploadTasks`.
 */
const activeUploads = new Map<string, UploadTask>();

function uploadImageToFirebaseStorage(fileUploadDoc: IFileUpload) {
  const promise = resolvablePromise();

  const ref = getImageRef(fileUploadDoc.id);

  const task = uploadBytesResumable(ref, fileUploadDoc.file, {
    contentType: fileUploadDoc.file.type,
    customMetadata: {
      creatorId: fileUploadDoc.creatorId,
      postId: fileUploadDoc.postId,
    },
  });

  activeUploads.set(fileUploadDoc.id, task);

  const uploadEvents$ = new Subject<UploadTaskSnapshot>();

  task.on("state_changed", {
    next: uploadEvents$.next.bind(uploadEvents$),
    error: uploadEvents$.error.bind(uploadEvents$),
    complete: uploadEvents$.complete.bind(uploadEvents$),
  });

  // Mark app as pending during upload
  withPendingUpdate(lastValueFrom(uploadEvents$));

  // Cancel the upload if the device goes offline. This service
  // will automatically restart the upload when the client
  // reconnects.
  APP_ONLINE$.pipe(
    takeUntil(uploadEvents$),
    filter((onLine) => !onLine),
  ).subscribe(() => {
    task.cancel();
  });

  uploadEvents$
    // Dexie appears to debounce updates so if we don't throttle
    // the upload events then they won't be processed incrementally.
    .pipe(throttleTime(350, undefined, { trailing: true }))
    .subscribe({
      next: (snap) => {
        const uploadProgress = Math.round(
          (snap.bytesTransferred / snap.totalBytes) * 100,
        );

        // We'll handle `uploadProgress === 100` in the complete() callback
        // See notes there for details.
        if (uploadProgress === 100) return;

        updateCachedImage(fileUploadDoc.id, { uploadProgress });

        console.debug("uploadProgress", uploadProgress, snap);
      },
      error: async (error: StorageError) => {
        console.warn("upload error", error);

        try {
          if (error.code === "storage/canceled") return;

          await updateCachedImage(fileUploadDoc.id, {
            error: error instanceof Error ? error.message : error,
          });
        } finally {
          activeUploads.delete(fileUploadDoc.id);

          promise.reject(error);
        }
      },
      complete: () => {
        console.debug("upload complete");
        // We mark the upload as complete (i.e. progress 100) and
        // delete this UploadTask at the same time because sometimes
        // indexedDB seems to update synchronously. If we updated the
        // cached image doc in indexedDB in the next handler (i.e. before
        // we deleted the UploadTask), then our subscribe handler (above)
        // which respondes to `uploadProgress === 100` wouldn't operate
        // properly.
        activeUploads.delete(fileUploadDoc.id);
        updateCachedImage(fileUploadDoc.id, { uploadProgress: 100 });
        promise.resolve();
      },
    });

  return promise as Promise<void>;
}

/**
 * Set of `IFileUpload` IDs which are being downloaded.
 */
const activeDownloads = new Set<string>();

const getImgDownloadURLAndUpdatePostBodyHTML = withPendingUpdate(
  async (fileUploadDoc: IFileUpload) => {
    try {
      activeDownloads.add(fileUploadDoc.id);

      const ref = getImageRef(fileUploadDoc.id);

      const url = await getDownloadURL(ref);

      // We want to preload the new img src so that there isn't a flicker
      // as we transition from the preview src to the new src.
      //
      // Note, I had wanted to use
      // something like a fetch or xhr request to preload the image so that
      // we can monitor download progress, but unfortunately the fetch/xhr
      // response cache in the browser isn't shared with the img.src cache
      await preloadAndGetImageDimensions(url);

      // Now that we've gotten our download URL and preloaded it, we want to
      // update the draft doc with the new img src. For drafts
      // that are being actively edited, the post editor component will also
      // update this img in the actively edited draft.

      const [draftSnap] = await Promise.all([
        getDoc(
          docRef(
            "users",
            fileUploadDoc.creatorId,
            "unsafeDrafts",
            fileUploadDoc.postId,
          ),
        ),
        updateCachedImage(fileUploadDoc.id, { downloadProgress: 100 }),
      ]);

      const doc = draftSnap.data({ serverTimestamps: "estimate" });

      if (!doc) return deleteImage(fileUploadDoc.id);

      const draftDocument = parseHTMLString(doc.bodyHTML);

      const img = draftDocument.querySelector<HTMLImageElement>(
        `[data-imageid="${fileUploadDoc.id}"]`,
      );

      if (!img) return deleteImage(fileUploadDoc.id);

      img.src = url;

      const bodyHTML = serializeHTML(draftDocument);

      await Promise.all([
        // We need to update the cached image with the correct URL
        // in case the user is actively editing this draft. The draft
        // editor ignores updates to the draft doc being edited so
        // it won't see that we updated the draft doc's body with the
        // correct img src. But the draft editor will respond to updates
        // to the file upload doc and will update the draft body to
        // include the correct img src.
        updateCachedImage(fileUploadDoc.id, { url }),
        updateDoc(draftSnap.ref, {
          bodyHTML,
          updatedAt: serverTimestamp(),
        }),
      ]);

      // Here we wait 500ms in case the user is actively editing this
      // draft. This gives enough time for the updated file upload doc
      // to be synced to the editor and then the editor will update the
      // img src with the fileUploadDoc's URL. After having done this,
      // we can then safely call `removeCachedImage()`.
      await wait(500);
      await removeCachedImage(fileUploadDoc.id);

      console.debug("upload added to draft");
    } finally {
      activeDownloads.delete(fileUploadDoc.id);
    }
  },
);

export function putCachedImage(doc: IFileUpload) {
  console.debug("putCachedImage", doc);
  return db.postImages.put(doc);
}

export function updateCachedImage(
  imageId: string,
  change: Partial<IFileUpload>,
) {
  console.debug("updateCachedImage", imageId, change);
  return db.postImages.update(imageId, { ...change, updatedAt: Date.now() });
}

export const cancelImageUpload = withPendingUpdate(async (imageId: string) => {
  console.debug("cancelImageUpload", imageId);

  const task = activeUploads.get(imageId);

  if ((task && task.cancel()) || !isAppOnline()) {
    return removeCachedImage(imageId);
  }
});

export const deleteImage = withPendingUpdate(async (imageId: string) => {
  console.debug("deleteImage", imageId);

  const task = activeUploads.get(imageId);

  if ((task && task.cancel()) || !isAppOnline()) {
    return removeCachedImage(imageId);
  }

  return Promise.allSettled([
    removeCachedImage(imageId),
    deleteObject(getImageRef(imageId)),
  ]);
});

export const removeCachedImage = withPendingUpdate((imageId: string) => {
  console.debug("removeCachedImage", imageId);
  return db.postImages.delete(imageId);
});

export function observeCachedImage(
  imageId: string,
): Observable<IFileUploadWithPreviewURL | null> {
  let fileUrl: string | undefined;

  return from(liveQuery(() => db.postImages.get(imageId))).pipe(
    map((doc) => {
      if (!doc) return null;
      if (fileUrl) URL.revokeObjectURL(fileUrl);
      fileUrl = URL.createObjectURL(doc.file);
      return { ...doc, previewUrl: fileUrl };
    }),
    finalize(() => {
      if (fileUrl) URL.revokeObjectURL(fileUrl);
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
}

async function preloadAndGetImageDimensions(src: string | Blob) {
  const imgContainer = document.createElement("div");
  imgContainer.style.position = "absolute";
  imgContainer.style.zIndex = "-1000";
  imgContainer.style.opacity = "0";
  imgContainer.style.width = "9999px";
  imgContainer.style.height = "9999px";

  document.body.appendChild(imgContainer);

  const img = new Image();

  if (src instanceof Blob) {
    img.src = URL.createObjectURL(src);
  } else {
    img.src = src;
  }

  img.style.position = "absolute";

  const loaded = new Promise<Event>((res, rej) => {
    img.onload = res;
    img.onerror = rej;
  });

  imgContainer.appendChild(img);

  await loaded;

  const result = {
    width: img.width,
    height: img.height,
  };

  if (src instanceof Blob) {
    URL.revokeObjectURL(img.src);
  }

  document.body.removeChild(imgContainer);

  return result;
}

function getImageRef(imageId: string) {
  const currentUser = getAndAssertCurrentUser();
  return storageRef("images", currentUser.id, imageId);
}

async function resizeImage(imageFile: File) {
  if (!imageFile.type.startsWith("image/")) return;

  if (imageFile.type === "image/gif") {
    if (imageFile.size > 25 * MB) {
      alert("GIFs must less than 25mb");
      return;
    }

    return imageFile;
  }

  return compressImage(imageFile, {
    maxWidth: 1600,
    maxHeight: 1600,
    quality: 0.8,
    convertSize: 4 * MB,
    convertTypes: ["image/png", "image/webp"],
  });
}

async function compressImage(
  imageFile: File,
  options: Compressor.Options = {
    maxWidth: 650,
    quality: 0.5,
    convertSize: 500 * KB,
    convertTypes: ["image/png", "image/webp"],
  },
) {
  return new Promise<File | Blob>((res, rej) => {
    new Compressor(imageFile, {
      ...options,
      success: res,
      error: rej,
    });
  });
}

function parseHTMLString(html: string) {
  return new DOMParser().parseFromString(html, "text/html");
}

function serializeHTML(node: Node) {
  return new XMLSerializer().serializeToString(node);
}
