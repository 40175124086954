import { Plugin, PluginKey, Command, TextSelection } from "@tiptap/pm/state";
import { Extension } from "@tiptap/react";
import { ComponentType, useEffect, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
import { fuzzyMatchEntries } from "../../tiptap/suggestion-utils";
import tippy, { Instance } from "tippy.js";
import {
  BaseEntry,
  BaseSuggestionsDropdown,
  ISuggestionEntryProps,
  WithIndexAndGroup,
} from "./dropdown";
import { useOnUnmount } from "~/utils/useOnUnmount";
import { EditorView } from "@tiptap/pm/view";
import commandScore from "command-score";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    searchSuggestions: {
      /** Toggle the search suggestions dropdown */
      toggleSearchSuggestions: () => ReturnType;
      /** Open the search suggestions dropdown */
      showSearchSuggestions: () => ReturnType;
      /** Close the search suggestions dropdown */
      closeSearchSuggestions: () => ReturnType;
    };
  }
}

export const SearchFilterSuggestions = Extension.create({
  addProseMirrorPlugins() {
    return [plugin];
  },
  addCommands() {
    return {
      toggleSearchSuggestions() {
        return ({ state: editorState, commands }) => {
          const state = pluginKey.getState(editorState);

          if (state?.isOpen) {
            return commands.closeSearchSuggestions();
          } else {
            return commands.showSearchSuggestions();
          }
        };
      },
      showSearchSuggestions() {
        return ({ state, dispatch }) =>
          commands.open(state, dispatch, undefined, { changedBy: "hotkey" });
      },
      closeSearchSuggestions() {
        return ({ state, dispatch }) => commands.close(state, dispatch);
      },
    };
  },
});

interface IState {
  isOpen: boolean;
  isOpenChangedBy?: "hotkey";
  context?: {
    word: string;
    from: number;
    to: number;
  };
}

interface IActionMeta {
  type: "open" | "close";
  isOpenChangedBy?: IState["isOpenChangedBy"];
}

const pluginKey = new PluginKey<IState>("search-suggestions");

const plugin = new Plugin<IState>({
  key: pluginKey,
  state: {
    init() {
      return { isOpen: false };
    },
    apply(tr, old) {
      const isContentSelected = tr.selection.$anchor !== tr.selection.$head;

      if (isContentSelected) {
        return { isOpen: false };
      }

      const metadata = tr.getMeta(pluginKey) as IActionMeta | undefined;

      if (metadata?.type === "close") {
        return {
          ...old,
          isOpen: false,
          isOpenChangedBy: metadata.isOpenChangedBy,
        };
      } else if (
        tr.docChanged ||
        metadata?.type === "open" ||
        metadata?.isOpenChangedBy
      ) {
        const openedBy = metadata?.isOpenChangedBy;
        const pos = tr.selection.$head;

        // get text for the current paragraph. @mentions will be considered
        // empty leafText that takes up 1 position so we provide the option
        // to count them as an single " " else our relativePos index will be
        // off.
        const text = pos.doc.textBetween(pos.start(), pos.end(), " ", " ");
        // // get the current cursor index within the paragraph
        const relativePos = pos.pos - pos.start() - 1;

        const result = getWordAtIndex(text, relativePos);

        if (!result) {
          return {
            isOpen: metadata?.type === "open",
            isOpenChangedBy: openedBy,
            context: {
              word: "",
              from: pos.pos,
              to: pos.pos,
            },
          };
        }

        return {
          isOpen: true,
          isOpenChangedBy: openedBy,
          context: {
            word: result.word,
            from: result.wordStartIndex + pos.start(),
            to: result.wordEndIndex + pos.start(),
          },
        };
      } else if (!old.isOpen || !old.context) {
        return old;
      } else {
        const pos = tr.selection.$head.pos;

        if (pos < old.context.from || pos > old.context.to) {
          return { ...old, isOpen: false, isOpenChangedBy: undefined };
        }

        return old;
      }
    },
  },
  props: {
    handleDOMEvents: {
      blur(view, event) {
        const didUserClickOnSuggestion = suggestionDropdownElement.contains(
          event.relatedTarget as HTMLElement,
        );

        if (didUserClickOnSuggestion) return;

        commands.close(view.state, view.dispatch);
      },
    },
  },
  view() {
    return {
      update(view) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const state = pluginKey.getState(view.state)!;

        const pos = view.state.selection.$head.pos;

        const rect = view.coordsAtPos(pos);

        const onSelect = ({
          suggestion,
          hint,
          receivesNestedQuery,
        }: {
          suggestion: string;
          hint: string;
          receivesNestedQuery?: boolean;
        }) => {
          if (!state.isOpen || !state.context) return;

          const { tr } = view.state;

          let newText: string;

          if (receivesNestedQuery) {
            newText = `${suggestion}( ${hint} )`;

            tr.insertText(newText, state.context.from, state.context.to);
          } else {
            newText = `${suggestion}: ${hint}`;

            tr.insertText(newText, state.context.from, state.context.to);
          }

          const isStartingPosition =
            state.context.from === 1 && state.context.to === 1;

          const from = isStartingPosition
            ? 1 + suggestion.length + 2
            : tr.mapping.map(state.context.from) + suggestion.length + 2;

          let to: number;

          if (isStartingPosition) {
            if (receivesNestedQuery) {
              to = tr.mapping.map(1) - 2;
            } else {
              to = tr.mapping.map(1);
            }
          } else if (receivesNestedQuery) {
            to = tr.mapping.map(state.context.to) - 2;
          } else {
            to = tr.mapping.map(state.context.to);
          }

          tr.setSelection(TextSelection.create(tr.doc, from, to));

          view.dispatch(tr);
        };

        const onClose = () => commands.close(view.state, view.dispatch);

        renderPopup({ state, rect, onSelect, onClose });
      },
    };
  },
});

const commands = {
  open(
    state,
    dispatch,
    _?: EditorView | undefined,
    options?: { changedBy: IActionMeta["isOpenChangedBy"] },
  ) {
    if (dispatch) {
      const meta: IActionMeta = {
        type: "open",
        isOpenChangedBy: options?.changedBy,
      };

      dispatch(state.tr.setMeta(pluginKey, meta));
    }

    return true;
  },

  close(
    state,
    dispatch,
    _?: EditorView | undefined,
    options?: { changedBy: IActionMeta["isOpenChangedBy"] },
  ) {
    if (dispatch) {
      const meta: IActionMeta = {
        type: "close",
        isOpenChangedBy: options?.changedBy,
      };

      dispatch(state.tr.setMeta(pluginKey, meta));
    }

    return true;
  },
} as const satisfies { [key: string]: Command };

const suggestionDropdownElement = document.createElement("div");

function renderPopup(props: IAutocompleteProps) {
  ReactDOM.render(<AutocompletePopup {...props} />, suggestionDropdownElement);
}

interface IAutocompleteProps {
  state: IState;
  rect: { left: number; bottom: number };
  onSelect: (args: {
    suggestion: string;
    hint: string;
    receivesNestedQuery?: boolean;
  }) => void;
  onClose: () => void;
}

function AutocompletePopup(props: IAutocompleteProps) {
  if (!props.state.isOpen || !props.state.context) {
    return null;
  }

  return <AutocompleteInner {...props} />;
}

function AutocompleteInner(props: IAutocompleteProps) {
  const word = props.state.context?.word || "";

  const suggestions = useMemo(() => {
    if (word.length === 0) {
      return filterSuggestions.map((o, index) => ({
        ...o,
        id: o.name,
        index,
        group: "Filters",
      }));
    }

    return fuzzyMatchEntries({
      entries: filterSuggestions,
      query: word,
      entryScore: (o, query) => commandScore(o.name, query),
    }).map((o, index) => ({
      ...o,
      id: o.name,
      index,
      group: "Filters",
    }));
  }, [word]);

  usePopup({
    show: suggestions.length > 0 || props.state.isOpenChangedBy === "hotkey",
    position: {
      left: props.rect.left,
      top: props.rect.bottom,
    },
    onClose: props.onClose,
  });

  return (
    <BaseSuggestionsDropdown
      items={suggestions}
      suggestionEntryComponentMap={{ Filters: FilterEntry }}
      onSelect={(item) => {
        props.onSelect({
          suggestion: item.name,
          hint: item.hint,
          receivesNestedQuery: item.receivesNestedQuery,
        });
      }}
      ifEmptyShowMsg={props.state.isOpenChangedBy === "hotkey"}
      onClose={() => {
        props.onClose();
      }}
    />
  );
}

export type TFilterSuggestion = WithIndexAndGroup<
  { id: string; name: string; description: string },
  "Filters"
>;

const FilterEntry: ComponentType<ISuggestionEntryProps<TFilterSuggestion>> = (
  props,
) => {
  return (
    <BaseEntry
      item={props.entry}
      isSelected={props.selectedIndex === props.entry.index}
      onClick={() => {
        props.selectItem(props.entry.index);
      }}
    >
      <div className="flex flex-col">
        <span className="inline-flex items-center shrink whitespace-nowrap overflow-hidden px-3">
          <span className="truncate">{props.entry.name}</span>
        </span>

        <span className="inline-flex items-center shrink whitespace-nowrap overflow-hidden px-3">
          <span className="truncate text-slate-9">
            {props.entry.description}
          </span>
        </span>
      </div>
    </BaseEntry>
  );
};

function usePopup(args: {
  show: boolean;
  position: { left: number; top: number };
  onClose: () => void;
}) {
  const { show, position, onClose } = args;

  const onCloseRef = useRef(onClose);
  const popoverRef = useRef<Instance>();

  useEffect(() => {
    popoverRef.current = tippy("body", {
      appendTo: () => document.body,
      content: suggestionDropdownElement,
      showOnCreate: false,
      interactive: true,
      trigger: "manual",
      placement: "bottom-start",
      zIndex: 150,
      onHide() {
        onCloseRef.current();
      },
    }).at(0);
  }, []);

  useEffect(() => {
    if (show) {
      popoverRef.current?.show();
    } else {
      popoverRef.current?.hide();
    }
  }, [show]);

  useEffect(() => {
    popoverRef.current?.setProps({
      getReferenceClientRect() {
        return new DOMRect(position.left, position.top, 0, 0);
      },
    });
  }, [position.left, position.top]);

  useOnUnmount(() => popoverRef.current?.destroy());
}

const filterSuggestions = [
  {
    name: "from",
    description: "message sender",
    hint: "replace with @mention",
  },
  {
    name: "to",
    description: "message recipient",
    hint: "replace with @mention",
  },
  {
    name: "channel",
    description: "channel that the message is in",
    hint: "replace with channel @mention",
  },
  {
    name: "mentions",
    description: "people or channels mentioned in the message",
    hint: "replace with user or channel @mention",
  },
  {
    name: "priority",
    description: "the thread's priority in your inbox",
    hint: "replace with subscriber, participating, @@@, @@, or @",
  },
  {
    name: "is",
    description: "is done, is private, is shared, is a branch",
    hint: `replace with "done", "private", "shared", or "branch"`,
  },
  {
    name: "after",
    description: "sent after date",
    hint: `replace with YYYY/M/D`,
  },
  {
    name: "before",
    description: "sent before date",
    hint: `replace with YYYY/M/D`,
  },
  {
    name: "has",
    description: "has reminder",
    hint: `replace with "reminder"`,
  },
  {
    name: "remind-after",
    description: "has reminder for after date",
    hint: `replace with YYYY/M/D`,
  },
  {
    name: "remind-before",
    description: "has reminder for before date",
    hint: `replace with YYYY/M/D`,
  },
  {
    name: "viewer",
    description: "person has permission to view",
    hint: `replace with @mention`,
  },
  {
    name: "participating",
    description: "person is participating in thread",
    hint: `replace with @mention`,
  },
  {
    name: "body",
    description: "message body content includes",
    hint: `replace with "quoted text"`,
  },
  {
    name: "subject",
    description: "message subject includes",
    hint: `replace with "quoted text"`,
  },
  {
    name: "or",
    description: "OR nested query",
    receivesNestedQuery: true,
    hint: "replace with nested query",
  },
  {
    name: "and",
    description: "AND nested query",
    receivesNestedQuery: true,
    hint: "replace with nested query",
  },
  {
    name: "not",
    description: "NOT nested query",
    receivesNestedQuery: true,
    hint: "replace with nested query",
  },
];

function getWordAtIndex(text: string, index: number) {
  const char = text[index];

  if (!char || char === " ") return false;

  const before = text.slice(0, index);
  const after = text.slice(index);

  const startingSpaceIndex = before.lastIndexOf(" ");
  const endingSpaceIndex = after.indexOf(" ");

  const wordStartIndex = startingSpaceIndex < 0 ? 0 : startingSpaceIndex + 1;
  const wordEndIndex =
    endingSpaceIndex < 0 ? text.length : endingSpaceIndex + index;

  return {
    word: text.slice(wordStartIndex, wordEndIndex),
    wordStartIndex,
    wordEndIndex,
  };
}
