import { css, cx } from "@emotion/css";
import { forwardRef, ComponentProps } from "react";
import { IFormControl } from "solid-forms-react";
import * as ReactSwitch from "@radix-ui/react-switch";
import { blackA, blueA, greenA } from "@radix-ui/colors";
import { useControlState } from "~/form-components/utils";

export const SwitchInput = forwardRef<
  HTMLButtonElement,
  {
    id?: string;
    control: IFormControl<boolean>;
    className?: string;
    onCheckedChange?: (value: boolean) => void;
  }
>((props, ref) => {
  const value = useControlState(() => props.control.value, [props.control]);
  const isDisabled = useControlState(
    () => props.control.isDisabled,
    [props.control],
  );

  return (
    <SwitchPrimitive
      ref={ref}
      id={props.id}
      checked={value}
      onCheckedChange={(e) => {
        props.control.setValue(e);
        props.onCheckedChange?.(e);
      }}
      onBlur={() => props.control.markTouched(true)}
      disabled={isDisabled}
      className={props.className}
    />
  );
});

export const SwitchPrimitive = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof ReactSwitch.Switch>
>((props, ref) => {
  return (
    <ReactSwitch.Switch
      ref={ref}
      {...props}
      className={cx("SwitchControl", switchCSS, props.className)}
    >
      <ReactSwitch.SwitchThumb className={cx("SwitchThumb", switchThumbCSS)} />
    </ReactSwitch.Switch>
  );
});

const switchCSS = css`
  width: 42px;
  height: 25px;
  background-color: ${blackA.blackA4};
  border: 2px solid ${blackA.blackA7};
  border-radius: 9999px;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:focus {
    box-shadow: 0 0 0 2px ${blueA.blueA9};
  }

  &[data-state="checked"] {
    background-color: ${greenA.greenA8};
  }
`;

const switchThumbCSS = css`
  display: block;
  width: 21px;
  height: 21px;
  background-color: ${blackA.blackA9};
  border-radius: 9999px;
  box-shadow: 0 2px 2px ${blackA.blackA7};
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;

  &[data-state="checked"] {
    background-color: white;
    transform: translateX(19px);
  }
`;
