import {
  callCommandById,
  isModKeyActive,
  PLATFORM_MODIFIER_KEY,
  useRegisterCommands,
  withNewCommandContext,
} from "~/services/command.service";
import { ComponentType, RefObject, useEffect, useRef, useState } from "react";
import {
  IListOnEntryActionEvent,
  IListRef,
  ListScrollbox,
} from "~/components/list";
import { Helmet } from "react-helmet-async";
import {
  ContentList,
  EmptyListMessage,
  navigateToEntry,
} from "~/components/content-list";
import { useTopScrollShadow } from "~/utils/useScrollShadow";
import { SearchResultEntry } from "./ThreadSearchResultEntry";
import { navigateBackOrToInbox } from "~/services/navigate.service";
import { useIsAppOnline } from "~/services/network-connection.service";
import { showNotImplementedToastMsg } from "~/services/toast-service";
import {
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import { InView } from "react-intersection-observer";
import { Tooltip } from "~/components/Tooltip";
import * as MainLayout from "~/page-layouts/main-layout";
import { openLinkInNewTabOrWindow } from "~/utils/navigation-helpers";
import { ISearchEditorRef } from "~/form-components/search-editor/SearchEditorBase";
import {
  ISearchEditorProps,
  SearchEditor,
} from "~/form-components/search-editor/SearchEditor";
import { ISearchDropdownRef, SearchDropdown } from "./SearchDropdown";
import { SearchSidebar } from "./SearchSidebar";
import { useForceSidebarIntoMode } from "~/page-layouts/sidebar-layout";
import { OutlineButton } from "~/components/OutlineButtons";
import {
  IndicateIfNoMoreSearchResults,
  performSearch,
  useAndInitializeSearch,
} from "./useSearch";
import { IObserveThread } from "~/services/post.service";

/* -------------------------------------------------------------------------------------------------
 * SearchView
 * -----------------------------------------------------------------------------------------------*/

export const SearchView = withNewCommandContext({
  priority: { delta: 2 },
  Component: () => {
    const resultsListRef = useRef<IListRef<IObserveThread>>(null);
    const resultsScrollboxRef = useRef<HTMLElement>(document.body);
    const headerRef = useRef<HTMLElement>(null);
    const searchDropdownRef = useRef<ISearchDropdownRef>(null);
    const editorRef = useRef<ISearchEditorRef>(null);

    const isAppOnline = useIsAppOnline();

    const {
      searchControl,
      searchResults,
      isSearchPending,
      queryAsPlainText,
      endOfSearch$,
      onBottomOfPageInView,
    } = useAndInitializeSearch({ editorRef });

    useForceSidebarIntoMode("over");

    useFocusSearchInputIfNoResults({
      editorRef,
      searchDropdownRef,
      queryAsPlainText,
      areSearchResults: searchResults.length > 0,
      isSearchPending,
    });

    useRegisterSearchViewCommands({
      editorRef,
      searchControl,
      searchDropdownRef,
    });

    useTopScrollShadow({
      scrollboxRef: resultsScrollboxRef,
      targetRef: headerRef,
    });

    return (
      <>
        <Helmet>
          <title>"{queryAsPlainText || ""}" | Search | Comms</title>
        </Helmet>

        <div className="MainPanel md-w:mr-[280px]">
          <MainLayout.Header ref={headerRef} className="MainHeader pt-4">
            <h1 className="text-2xl mr-3 mt-2 font-medium">Search</h1>

            <SearchDropdown
              ref={searchDropdownRef}
              searchQueryHTMLControl={searchControl.controls.queryHTML}
              isSearchPending={isSearchPending}
            >
              <SearchEditor editorRef={editorRef} control={searchControl} />
            </SearchDropdown>

            <div className="absolute right-[3.5rem] top-[25px] opacity-50 hover:opacity-100">
              <Tooltip
                side="bottom"
                content={
                  <span className="flex items-center">
                    <PLATFORM_MODIFIER_KEY.symbol className="mr-1" /> + Enter
                  </span>
                }
              >
                <OutlineButton
                  tabIndex={-1}
                  onClick={() => {
                    callCommandById("Submit");
                  }}
                >
                  Submit
                </OutlineButton>
              </Tooltip>
            </div>
          </MainLayout.Header>

          <ListScrollbox
            isBodyElement
            offsetHeaderEl={headerRef}
            onlyOffsetHeaderElIfSticky
          >
            {!isAppOnline ? (
              <EmptyListMessage text="Currently offline. Go online to search." />
            ) : !searchResults || isSearchPending ? (
              <LoadingMessage />
            ) : !queryAsPlainText ? (
              <div />
            ) : searchResults.length === 0 ? (
              <EmptyListMessage text={`No results for "${queryAsPlainText}"`} />
            ) : (
              <ContentList
                ref={resultsListRef}
                onEntryAction={onSearchEntrySelect}
                onArrowUpOverflow={(e) => {
                  e.preventDefault();
                  editorRef.current?.focus("all");
                }}
                className="mb-20"
                autoFocus
              >
                {searchResults.map((thread, index) => (
                  <SearchResultEntry
                    key={thread.id}
                    thread={thread}
                    relativeOrder={index}
                  />
                ))}

                {!isSearchPending && (
                  <InView
                    as="div"
                    onChange={(inView) => {
                      if (!inView) return;
                      onBottomOfPageInView();
                    }}
                  />
                )}

                <IndicateIfNoMoreSearchResults endOfSearch$={endOfSearch$} />
              </ContentList>
            )}
          </ListScrollbox>
        </div>

        <SearchSidebar />
      </>
    );
  },
});

/* -----------------------------------------------------------------------------------------------*/

function useFocusSearchInputIfNoResults(props: {
  editorRef: RefObject<ISearchEditorRef>;
  searchDropdownRef: RefObject<ISearchDropdownRef>;
  isSearchPending: boolean;
  queryAsPlainText: string | null;
  areSearchResults: boolean;
}) {
  useEffect(() => {
    if (props.isSearchPending) return;
    if (props.areSearchResults) return;
    props.editorRef.current?.focus("all");
    // Focusing the editor will cause the search dropdown to appear
    // so it's important that we hide it after focusing it. We do this
    // in a setTimeout since it appears that focusing the editor doesn't
    // happen syncronously.
    const id = setTimeout(() => props.searchDropdownRef.current?.hide(), 50);
    return () => clearTimeout(id);
  }, [
    props.editorRef,
    props.searchDropdownRef,
    // We wish to refocus the input every time the query changes
    props.queryAsPlainText,
    props.areSearchResults,
    props.isSearchPending,
  ]);
}

/* -----------------------------------------------------------------------------------------------*/

const LoadingMessage: ComponentType = () => {
  const [message, setMessage] = useState("Loading...");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMessage((curr) => {
        if (curr === "Loading...") {
          return "Loading";
        } else {
          return curr + ".";
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return <EmptyListMessage text={message} />;
};

/* -----------------------------------------------------------------------------------------------*/

function onSearchEntrySelect({
  entry,
  event,
}: IListOnEntryActionEvent<IObserveThread>) {
  const url = `/threads/${entry.id}`;

  if (isModKeyActive(event)) {
    openLinkInNewTabOrWindow(url);
  } else {
    navigateToEntry(entry.id, url);
  }
}

/* -------------------------------------------------------------------------------------------------
 * useRegisterSearchViewCommands
 * -----------------------------------------------------------------------------------------------*/

function useRegisterSearchViewCommands(args: {
  editorRef: RefObject<ISearchEditorRef>;
  searchControl: ISearchEditorProps["control"];
  searchDropdownRef: RefObject<ISearchDropdownRef>;
}) {
  const { editorRef, searchControl } = args;

  useRegisterCommands({
    commands: () => {
      return [
        {
          label: "Submit",
          hotkeys: ["$mod+Enter"],
          triggerHotkeysWhenInputFocused: true,
          callback() {
            if (!searchControl.isValid) return;

            performSearch(searchControl.rawValue.queryText);
          },
        },
        {
          label: "Go to Search",
          hotkeys: ["/"],
          callback: () => {
            console.log("focus search");
            editorRef.current?.focus("all");
          },
        },
        {
          label: "Clear query or Back",
          showInKBar: false,
          triggerHotkeysWhenInputFocused: true,
          hotkeys: ["Escape"],
          callback: () => {
            if (!editorRef.current?.editor?.isFocused) {
              editorRef.current?.focus("all");
            } else if (!editorRef.current?.editor.isEmpty) {
              editorRef.current.editor.commands.clearContent(true);
            } else if (args.searchDropdownRef.current?.visible) {
              args.searchDropdownRef.current.hide();
            } else {
              navigateBackOrToInbox();
            }
          },
        },
        markDoneCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't mark threads Done from the
              Search page.
            `);
          },
        }),
        markNotDoneCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't mark threads not Done from the
              Search page.
            `);
          },
        }),
        setThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't edit reminders from the
              Search page.
            `);
          },
        }),
        removeThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't edit reminders from the
              Search page.
            `);
          },
        }),
        starThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't star threads from the
              Search page.
            `);
          },
        }),
        unstarThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't unstar threads from the
              Search page.
            `);
          },
        }),
      ];
    },
    deps: [editorRef, searchControl],
  });
}
