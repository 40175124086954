import { useLocation, Navigate } from "react-router-dom";
import { ComponentType } from "react";
import { Helmet } from "react-helmet-async";
import { attemptToGetRedirectLocation } from "~/utils/navigation-helpers";
import { useIsAppOnline } from "~/services/network-connection.service";

export const OfflineMessageView: ComponentType<{}> = () => {
  const online = useIsAppOnline();
  const location = useLocation();
  const redirectTo = attemptToGetRedirectLocation(location);

  if (online) {
    return (
      <Navigate
        to={
          !redirectTo || redirectTo.pathname.startsWith("/offline")
            ? "/inbox"
            : redirectTo
        }
        replace
      />
    );
  }

  return (
    <div className="w-screen h-dynamic-screen flex flex-col justify-center items-center space-y-10">
      <Helmet>
        <title>Offline | Comms</title>
      </Helmet>

      <h1 className="text-4xl text-center font-bold">
        You appear to be offline.
      </h1>

      <div className="flex px-8 py-4 items-center justify-center text-center max-w-[600px] min-w-0">
        <p>
          Comms doesn't currently support offline mode. Previously Comms did
          support offline mode, but we needed to remove offline support because
          it was negatively affecting application performance. We plan on adding
          offline support again in the future.
        </p>
      </div>
    </div>
  );
};
