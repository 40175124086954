import { ComponentType, useRef } from "react";
import { ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { ContentList, EmptyListMessage } from "~/components/content-list";
import { useTopScrollShadow } from "~/utils/useScrollShadow";
import { PendingRequestBar } from "~/components/PendingRequestBar";
import { useParams } from "react-router-dom";
import { NotFound } from "~/components/NotFound";
import {
  useInvitedOrganizationMembers,
  useOrganization,
  useOrganizationMembers,
} from "~/services/organization.service";
import { MemberEntry } from "./MemberEntry";
import { showNotImplementedToastMsg } from "~/services/toast-service";
import { ICommandArgs, useRegisterCommands } from "~/services/command.service";
import { ESCAPE_TO_BACK_COMMAND } from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";

export const MembersView: ComponentType<{}> = () => {
  const params = useParams();
  const organization = useOrganization(params.organizationId);
  const organizationMembers = useOrganizationMembers(params.organizationId);
  const invitedOrganizationMembers = useInvitedOrganizationMembers(
    params.organizationId,
  );

  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);

  useRegisterCommands({
    commands() {
      const commands: ICommandArgs[] = [ESCAPE_TO_BACK_COMMAND];
      return commands;
    },
  });

  // const findFn = useCallback(
  //   (
  //     entry: NonNullable<typeof organizationMembers>[number],
  //     focusedEntry: NonNullable<typeof organizationMembers>[number],
  //   ) => entry.id === focusedEntry.id,
  //   [],
  // );

  // const [focusedMember, setFocusedMember] = useKBarAwareFocusedEntry<
  //   NonNullable<typeof organizationMembers>[number]
  // >(organizationMembers, findFn);

  // I'm disabling this command since it's not clear how we should handle
  // an attempt to remove a user account who is "owned" by the organization
  // they are being removed from. Should we just delete the user account?
  // Archive it? Will need to decide later
  //
  // useRegisterCommands({
  //   commands: () => {
  //     if (!focusedMember || !organization) return [];
  //     if (focusedMember.id === currentUser.id) return [];

  //     const label = focusedMember.user?.name
  //       ? `Remove "${focusedMember.user.name}" from organization`
  //       : "Cancel organization invite";

  //     return [
  //       {
  //         label,
  //         keywords: ["Remove organization member", "Delete member"],
  //         callback: onlyCallFnOnceWhilePreviousCallIsPending(async () => {
  //           if (focusedMember.accepted && focusedMember.user) {
  //             const areTheySure = confirm(`
  //               Are you sure you want to remove "${focusedMember.user.name}"
  //               from the "${organization.name}" organization?
  //             `);

  //             if (!areTheySure) return;
  //           }

  //           const removeLoading = pendingRequestBarContext.markLoading();

  //           return removeOrganizationMember({
  //             organizationId: organization.id,
  //             memberId: focusedMember.id,
  //           })
  //             .then(() =>
  //               console.debug(`Successfully removed organization member`),
  //             )
  //             .catch((e) =>
  //               console.error(`Error removing organization member`, e),
  //             )
  //             .finally(removeLoading);
  //         }),
  //       },
  //     ];
  //   },
  //   deps: [organization, focusedMember, currentUser, pendingRequestBarContext],
  // });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
    deps: [!!organization],
  });

  if (organization === undefined) {
    return <div>Loading...</div>;
  }

  if (organization === null) {
    return <NotFound title="Organization Not Found" />;
  }

  return (
    <>
      <Helmet>
        <title>{organization.name} members | Comms</title>
      </Helmet>

      <MainLayout.Header ref={headerRef}>
        <h1 className="text-3xl">{organization.name} members</h1>
      </MainLayout.Header>

      <ListScrollbox
        isBodyElement
        offsetHeaderEl={headerRef}
        onlyOffsetHeaderElIfSticky
      >
        {!organizationMembers ? (
          <PendingRequestBar />
        ) : organizationMembers.length === 0 ? (
          <EmptyListMessage text="None." />
        ) : (
          <ContentList
            className="mb-20"
            onEntryAction={() => {
              showNotImplementedToastMsg(`
                Unfortunately, you can't currently view user profiles. 
                Annoying, I know. I want this feature too...
              `);
            }}
            autoFocus
          >
            {organizationMembers.map((member, index) => (
              <MemberEntry
                key={member.id}
                member={member}
                relativeOrder={index}
              />
            ))}

            {invitedOrganizationMembers &&
              invitedOrganizationMembers.length > 0 && (
                <>
                  <div className="mx-12 mt-8 mb-2 uppercase text-slate-8 font-medium text-sm">
                    Pending Invitations
                  </div>

                  {invitedOrganizationMembers.map((member, index) => (
                    <MemberEntry
                      key={member.id}
                      member={member}
                      relativeOrder={index}
                    />
                  ))}
                </>
              )}
          </ContentList>
        )}
      </ListScrollbox>
    </>
  );
};
