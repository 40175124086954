import type { IThreadListNavigationLocationStateInboxView } from "~/services/thread-prev-next.service";

/* -------------------------------------------------------------------------------------------------
 * thread-prev-next-service-utils
 * -------------------------------------------------------------------------------------------------
 *
 * This module contains helpers for working with the thread-prev-next service.
 */

export function buildThreadViewInboxPrevNextState(inboxSectionId: string) {
  return {
    ThreadListNavigationLocationState: {
      origin: "InboxView",
      inboxSectionId,
    } satisfies IThreadListNavigationLocationStateInboxView,
  };
}
