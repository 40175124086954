import { memo } from "react";
import { isEqual } from "@libs/utils/isEqual";
import { entryCSSClasses } from "~/components/content-list";
import { List } from "~/components/list";
import { ISubscriptionDoc } from "@libs/firestore-models";

interface ISubscriberEntry {
  preference: ISubscriptionDoc["preference"];
  name: string;
  email: string;
  photoURL: string | null;
  phoneNumber: string | null;
  id: string;
}

export const SubscriberEntry = memo<{
  member: ISubscriberEntry;
  relativeOrder: number;
}>((props) => {
  return (
    <List.Entry<ISubscriberEntry>
      id={props.member.id}
      data={props.member}
      relativeOrder={props.relativeOrder}
    >
      <div className={entryCSSClasses}>
        <div className="w-full flex items-center">
          <span className="flex-1">{props.member.name}</span>
          <span className="flex-[4] text-slate-9">{props.member.email}</span>
        </div>
      </div>
    </List.Entry>
  );
}, isEqual);
