import { ComponentType } from "react";
import {
  DialogState,
  DialogTitle,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { TextInput } from "~/form-components/TextInput";
import { handleSubmit, onSubmitFn } from "~/form-components/utils";
import {
  createFormControl,
  createFormGroup,
  IFormControl,
  useControl,
} from "solid-forms-react";
import { toast } from "~/services/toast-service";
import { isAppOnline } from "~/services/network-connection.service";
import { useRegisterCommands } from "~/services/command.service";
import { IChannelGroupDoc } from "@libs/firestore-models";
import {
  createChannelGroup,
  updateChannelGroup,
} from "~/services/organization.service";
import { getAndAssertCurrentUser } from "~/services/user.service";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { SubmitDialogHint } from "../DialogLayout";

export type IEditChannelGroupDialogData =
  | {
      channelGroup: IChannelGroupDoc;
    }
  | undefined;

export type IEditChannelGroupDialogReturnData = { success: boolean } | void;

export const EditChannelGroupDialogState = new DialogState<
  IEditChannelGroupDialogData,
  IEditChannelGroupDialogReturnData
>();

interface IFormValue {
  id: string | null;
  name: string;
}

export const EditChannelGroupDialog = withModalDialog({
  dialogState: EditChannelGroupDialogState,
  useOnDialogContainerRendered: () => {
    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "New channel group",
            altLabels: ["Create channel group"],
            callback: () => {
              if (!isAppOnline()) {
                toast("vanilla", {
                  subject: "Not supported in offline mode",
                  description: "Can't create channel groups when offline.",
                });

                return;
              }

              EditChannelGroupDialogState.toggle(true);
            },
          },
        ];
      },
    });
  },
  Component: (props) => {
    const control = useControl(() =>
      createFormGroup({
        id: createFormControl(props.data?.channelGroup.id || null),
        name: createFormControl(props.data?.channelGroup.name || "", {
          required: true,
        }),
      }),
    );

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              EditChannelGroupDialogState.toggle(false);
            },
          },
          {
            label: "Submit form",
            hotkeys: ["$mod+Enter"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              console.log("attempting submit");
              handleSubmit(control, submit);
            },
          },
        ];
      },
    });

    return (
      <>
        <DialogTitle>
          <h2>
            {props.data?.channelGroup.name
              ? `Update "${props.data.channelGroup.name}" channel group`
              : `Create channel group`}
          </h2>
        </DialogTitle>

        <form
          onSubmit={onSubmitFn(control, submit)}
          className={DIALOG_CONTENT_WRAPPER_CSS}
        >
          <Name control={control.controls.name} />
        </form>

        <SubmitDialogHint />
      </>
    );
  },
});

const submit = withPendingRequestBar(async (values: IFormValue) => {
  console.log("submitting...", values);

  const currentUser = getAndAssertCurrentUser();

  if (!currentUser.organizationId) {
    alert(`
        Please email john.carroll.p@gmail.com and let him know that
        you ran into this error. You shouldn't have.
        Error code: ChannelGroupInviteDialog no organizationId
      `);

    return;
  }

  EditChannelGroupDialogState.toggle(false, { success: true });

  if (values.id) {
    toast("vanilla", {
      subject: "Updating channel group...",
    });

    const result = await updateChannelGroup({
      organizationId: currentUser.organizationId,
      id: values.id,
      name: values.name,
    });

    if (!result.data.success) {
      console.log("submission failed", result);
      toast("vanilla", {
        subject: "Channel group update failed :(",
      });
      return;
    }

    console.log("submitted successfully!");

    toast("vanilla", {
      subject: "Channel group updated.",
    });
  } else {
    toast("vanilla", {
      subject: "Creating channel group...",
    });

    const result = await createChannelGroup({
      organizationId: currentUser.organizationId,
      name: values.name,
    });

    if (!result.data.success) {
      console.log("submission failed", result);
      toast("vanilla", {
        subject: "Channel group creation failed :(",
      });
      return;
    }

    console.log("submitted successfully!");

    toast("vanilla", {
      subject: "Channel group created.",
    });
  }
});

const Name: ComponentType<{
  control: IFormControl<string>;
}> = (props) => {
  return (
    <div className="flex px-4">
      <div className="flex flex-1 py-2 border-b border-mauve-5">
        <TextInput name="name" control={props.control} />
      </div>
    </div>
  );
};
