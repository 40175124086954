import { css, cx } from "@emotion/css";
import { ComponentType } from "react";
import { IoMdHelpCircle } from "react-icons/io";
import { distinctUntilChanged, map } from "rxjs";
import { Tooltip } from "~/components/Tooltip";
import { HelpDialogState } from "~/dialogs/help/HelpDialog";
import { WINDOW_SIZE$ } from "~/services/window.service";
import { useObservable } from "~/utils/useObservable";

const newCSS = css`
  transform: scale(1.5);
`;

export const HelpIcon: ComponentType<{}> = () => {
  const show = useObservable(
    () => {
      return WINDOW_SIZE$.pipe(
        map(({ width }) => width >= 768),
        distinctUntilChanged(),
      );
    },
    { synchronous: true },
  );

  if (!show) return null;

  return (
    <Tooltip
      side="left"
      content={
        <span>
          <kbd>Shift</kbd> + <kbd>/</kbd>
        </span>
      }
    >
      <button
        type="button"
        tabIndex={-1} // Doesn't need to be tabbable because of hotkeys
        className={cx(
          "fixed right-[1rem] top-[1rem] text-slate-9 rounded-full z-[99]",
          "outline-none hover:pointer hover:bg-slate-5 hover:text-black",
        )}
        onClick={() => {
          HelpDialogState.toggle(true);
        }}
      >
        <IoMdHelpCircle className={newCSS} />
      </button>
    </Tooltip>
  );
};
