import { router } from "./Routes";
import { PortalContext } from "~/services/portal.service";
import useConstant from "use-constant";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ToastViewport } from "./services/toast-service";
import "~/services/service-worker.service";
import { TooltipProvider } from "./components/Tooltip";
import { PendingRequestBarProvider } from "./components/PendingRequestBar";
import { HintViewport } from "./services/hint-service";
import { lazy, Suspense } from "react";
import { ApolloProvider } from "@apollo/client";
import { apollo } from "./services/apollo.service";
import { OfflineBanner } from "./components/OfflineBanner";
import { RouterProvider } from "react-router-dom";
import { LoadingModal } from "./dialogs/LoadingModal";
import { DarkModeProvider } from "./components/DarkMode";

const UnsupportedBrowserWarningBanner = lazy(() =>
  import("~/services/detect-browser.service").then((m) => ({
    default: m.UnsupportedBrowserWarningBanner,
  })),
);

export default function App() {
  const portalContainer = useConstant(() => document.body);

  return (
    <HelmetProvider>
      <DarkModeProvider>
        <ApolloProvider client={apollo}>
          <PortalContext.Provider value={{ container: portalContainer }}>
            <TooltipProvider>
              <PendingRequestBarProvider>
                <Helmet>
                  {/* 
                This acts as the default page title for the app. It is expected that
                this is overwritten by child components. It may be shown while a page
                is loading. 
              */}
                  <title>Comms</title>
                </Helmet>

                <Suspense fallback={null}>
                  <UnsupportedBrowserWarningBanner />
                </Suspense>

                <LoadingModal />
                <RouterProvider router={router} />
                <ToastViewport />
                <HintViewport />
                <OfflineBanner />
              </PendingRequestBarProvider>
            </TooltipProvider>
          </PortalContext.Provider>
        </ApolloProvider>
      </DarkModeProvider>
    </HelmetProvider>
  );
}
