import { resolvablePromise } from "@libs/utils/resolvablePromise";
import { distinctUntilChanged, fromEvent, map, merge } from "rxjs";
import { startWith } from "./rxjs-operators";

export function getMaxScrollTop(el: HTMLElement) {
  if (el === document.body) {
    const html = document.documentElement;

    const maxScrollTop =
      Math.max(
        el.scrollHeight,
        el.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
      ) - html.clientHeight;

    return maxScrollTop;
  }

  return el.scrollHeight - el.clientHeight;
}

/** If passed the body element, returns document.documentElement.scrollTop instead */
export function getScrollTop(el: HTMLElement) {
  return el === document.body
    ? document.documentElement.scrollTop
    : el.scrollTop;
}

/** If passed the body element, sets document.documentElement.scrollTop instead */
export function setScrollTop(
  el: HTMLElement,
  value: number | ((oldValue: number) => number),
) {
  const normalizedEl = el === document.body ? document.documentElement : el;

  const newValue =
    typeof value === "function" ? value(normalizedEl.scrollTop) : value;

  normalizedEl.scrollTop = newValue;
}

/** If passed the body element, scrolls document.documentElement instead */
export function scrollElementTo(
  el: HTMLElement,
  options?: ScrollToOptions | undefined,
) {
  if (el === document.body) {
    document.documentElement.scrollTo(options);
  } else {
    el.scrollTo(options);
  }
}

export function observeFocusWithin(...elements: HTMLElement[]) {
  return merge(
    ...elements.map((el) =>
      fromEvent<FocusEvent>(el, "focusin").pipe(map(() => true)),
    ),
    ...elements.map((el) =>
      fromEvent<FocusEvent>(el, "focusout").pipe(
        map((e) =>
          elements.some((el) => el.contains(e.relatedTarget as Node | null)),
        ),
      ),
    ),
  ).pipe(
    startWith(() => elements.some((el) => el.matches(":focus-within"))),
    distinctUntilChanged(),
  );
}

/**
 * Copy a value to the clipboard. If the clipboard API isn't
 * supported, this will throw an error.
 * @returns promise which resolves when the copy is complete
 */
export function writeToClipboard<T extends BlobPart>(args: {
  /**
   * These are the only types supported by Firefox.
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/write
   */
  type: "text/plain" | "text/html" | "image/png";
  value: T;
}) {
  if (!("clipboard" in navigator)) {
    throw new Error("This browser doesn't support the clipboard API");
  }

  const blob = new Blob([args.value], { type: args.type });
  const data = [new ClipboardItem({ [args.type]: blob })];

  return navigator.clipboard.write(data);
}

export function isElementFocused(el: HTMLElement) {
  return document.activeElement === el;
}

export function isFocusWithinElement(el: HTMLElement) {
  return el.contains(document.activeElement);
}

/**
 * Loads a javascript script from the provided source and
 * appends it to the document body in a `script` el.
 *
 * @returns a promise which resolves when the script has
 *   finished loading.
 */
export function loadScript(src: string): Promise<unknown> {
  const scriptEl = document.createElement("script");
  scriptEl.type = "text/javascript";
  scriptEl.src = src;
  scriptEl.async = true;
  scriptEl.defer = true;
  const promise = resolvablePromise();
  scriptEl.onload = promise.resolve;
  scriptEl.onerror = promise.reject;
  document.body.appendChild(scriptEl);
  return promise;
}
