import { ComponentType, Ref } from "react";
import {
  DialogState,
  DialogTitle,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { TextInput } from "~/form-components/TextInput";
import {
  createChannel,
  updateChannel,
  IChannelDocWithCurrentUserData,
} from "~/services/channels.service";
import {
  IOption,
  TAutocompleteSelectRef,
  useAutocompleteMenuPositioning,
} from "~/form-components/AutocompleteSelect";
import { navigateService } from "~/services/navigate.service";
import {
  IChannelGroupOption,
  ChannelGroupsSelect,
} from "~/form-components/ChannelGroupsSelect";
import {
  createFormControl,
  createFormGroup,
  IFormControl,
  useControl,
} from "solid-forms-react";
import { TextareaInput } from "~/form-components/Textarea";
import {
  handleSubmit,
  onSubmitFn,
  useControlState,
} from "~/form-components/utils";
import { toast } from "~/services/toast-service";
import { isAppOnline } from "~/services/network-connection.service";
import { useRegisterCommands } from "~/services/command.service";
import { getAndAssertCurrentUser } from "~/services/user.service";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { SubmitDialogHint } from "../DialogLayout";
import { IChannelDoc } from "@libs/firestore-models";
import { CheckboxInput } from "~/form-components/CheckboxInput";
import { Tooltip } from "~/components/Tooltip";
import { cx } from "@emotion/css";

export type IEditChannelDialogData =
  | {
      channel: IChannelDocWithCurrentUserData;
    }
  | undefined;

export type IEditChannelDialogReturnData = { success: boolean } | void;

export const EditChannelDialogState = new DialogState<
  IEditChannelDialogData,
  IEditChannelDialogReturnData
>();

interface IFormValue {
  id: string | null;
  channelGroups: IOption<string>[];
  name: string;
  description: string;
  classification: IChannelDoc["classification"];
}

export const EditChannelDialog = withModalDialog({
  dialogState: EditChannelDialogState,
  useOnDialogContainerRendered: () => {
    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "New channel",
            altLabels: ["Create channel"],
            callback: () => {
              if (!isAppOnline()) {
                toast("vanilla", {
                  subject: "Not supported in offline mode",
                  description: "Can't create channels when offline.",
                });

                return;
              }

              EditChannelDialogState.toggle(true);
            },
          },
        ];
      },
    });
  },
  Component: (props) => {
    const control = useControl(() => {
      return createFormGroup({
        id: createFormControl(props.data?.channel.id || null),
        channelGroups: createFormControl<IChannelGroupOption[]>(
          !props.data
            ? []
            : props.data.channel.__local.knownChannelGroups.map(
                (channelGroup) => ({
                  label: channelGroup.name,
                  value: channelGroup.id,
                  organizationName: channelGroup.organizationName,
                }),
              ),
          {
            validators: required,
            required: true,
          },
        ),
        name: createFormControl(props.data?.channel.name || "", {
          required: true,
        }),
        description: createFormControl(props.data?.channel.description || ""),
        classification: createFormControl(
          props.data?.channel.classification || "public",
          {
            disabled: !!props.data,
          },
        ),
      });
    });

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              EditChannelDialogState.toggle(false);
            },
          },
          {
            label: "Submit form",
            hotkeys: ["$mod+Enter"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              console.log("attempting submit");
              handleSubmit(control, submit);
            },
          },
        ];
      },
    });

    const [
      channelGroupsAutocompleteRef,
      channelGroupsAutocompletePortalEl,
      channelGroupsAutocompletePortalJSX,
    ] = useAutocompleteMenuPositioning<IChannelGroupOption, true>();

    return (
      <>
        <DialogTitle>
          <h2>
            {props.data?.channel.name
              ? `Update "${props.data.channel.name}" channel`
              : `Create channel`}
          </h2>
        </DialogTitle>

        <form
          onSubmit={onSubmitFn(control, submit)}
          className={DIALOG_CONTENT_WRAPPER_CSS}
        >
          <ChannelGroups
            autocompleteRef={channelGroupsAutocompleteRef}
            control={control.controls.channelGroups}
            autocompleteMenuEl={channelGroupsAutocompletePortalEl}
          />

          <div className="flex px-4">
            <div className="flex flex-1 py-2 border-b border-mauve-5">
              <TextInput control={control.controls.name} name="name" />

              <IsPrivateInput control={control.controls.classification} />
            </div>
          </div>

          <Description control={control.controls.description} />
        </form>

        {channelGroupsAutocompletePortalJSX}

        <SubmitDialogHint />
      </>
    );
  },
});

const IsPrivateInput: ComponentType<{
  control: IFormControl<"private" | "public">;
}> = (props) => {
  const isDisabled = useControlState(
    () => props.control.isDisabled,
    [props.control],
  );

  return (
    <Tooltip
      side="bottom"
      content={
        isDisabled
          ? "Cannot edit after creation."
          : "Private channels are only visible to invited members."
      }
    >
      <div className="flex items-center">
        <label
          htmlFor="channel-classification-input"
          className={cx(
            "mx-2",
            isDisabled && "text-slate-9 cursor-not-allowed",
          )}
        >
          Private channel?
        </label>

        <CheckboxInput
          id="channel-classification-input"
          control={props.control}
          checkedValue="private"
          uncheckedValue="public"
        />
      </div>
    </Tooltip>
  );
};

function required(value: IOption<string>[]) {
  return value.length > 0 ? null : { required: "Required." };
}

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(async (values: IFormValue) => {
    console.log("submitting...", values);

    EditChannelDialogState.toggle(false, { success: true });

    if (values.id) {
      toast("vanilla", {
        subject: "Updating channel...",
      });

      const result = await updateChannel({
        id: values.id,
        name: values.name,
        description: values.description || null,
        channelGroups: values.channelGroups.map((option) => ({
          id: option.value,
          role: "admin",
        })),
      });

      if (!result.data.success) {
        console.log("submission failed", result);
        toast("vanilla", {
          subject: "Channel failed to update :(",
        });
        return;
      }

      console.log("submitted successfully!");

      toast("vanilla", {
        subject: "Channel updated.",
      });
    } else {
      toast("vanilla", {
        subject: "Creating channel...",
      });

      const currentUser = getAndAssertCurrentUser();

      const organizationId = currentUser.organizationId;

      if (!organizationId) {
        alert(`
          Please email john.carroll.p@gmail.com and let him know that
          you ran into this error. You shouldn't have.
          Error code: EditChannelDialog no organizationId
        `);

        return;
      }

      const result = await createChannel({
        organizationId,
        name: values.name,
        description: values.description || null,
        photoURL: null,
        channelGroups: values.channelGroups.map((option) => ({
          id: option.value,
          role: "admin",
        })),
        classification: values.classification,
      });

      if (!result.data.success) {
        console.log("submission failed", result);
        toast("vanilla", {
          subject: "Channel creation failed :(",
        });
        return;
      }

      console.log("submitted successfully!");

      toast("vanilla", {
        subject: "Channel created.",
      });

      navigateService(`/channels/${result.data.data.channelId}`);
    }
  }),
);

// TODO: upgrade the RecipientsInput to import from `react-select/async`
//       instead of from `react-select` so that we can lazily load the
//       options based on what the user has typed. This will require
//       fulltext searching.

const ChannelGroups: ComponentType<{
  autocompleteRef?: Ref<TAutocompleteSelectRef<IChannelGroupOption, true>>;
  control: IFormControl<IChannelGroupOption[]>;
  autocompleteMenuEl?: HTMLDivElement | null;
}> = (props) => {
  const value = useControlState(() => props.control.value, [props.control]);

  const error = useControlState(
    () => props.control.errors?.required as string | undefined,
    [props.control],
  );

  const touched = useControlState(
    () => props.control.isTouched,
    [props.control],
  );

  return (
    <div className="flex px-4">
      <ChannelGroupsSelect
        autocompleteRef={props.autocompleteRef}
        label="In"
        value={value}
        multiple
        autoFocus
        error={error}
        touched={touched}
        autocompleteMenuEl={props.autocompleteMenuEl}
        onBlur={() => props.control.markTouched(true)}
        onChange={(newValue) =>
          props.control.setValue(newValue as IChannelGroupOption[])
        }
      />
    </div>
  );
};

const Description: ComponentType<{
  control: IFormControl<string>;
}> = (props) => {
  return (
    <div className="flex flex-1 overflow-y-auto p-4" tabIndex={-1}>
      <TextareaInput control={props.control} name="description" />
    </div>
  );
};
