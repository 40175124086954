import {
  ICommsPostDoc,
  ICommsThreadDoc,
  IEmailPostDoc,
  IEmailThreadDoc,
  IPostDoc,
  ISecretEmailPostDoc,
  ISecretEmailThreadDoc,
  ISubscriptionDoc,
  IThreadDoc,
  WithDateOrServerTimestamp,
  WithServerTimestamp,
} from "./index";
import { UnreachableCaseError } from "@libs/utils/errors";
import { pick, uniqBy } from "lodash-es";
import { Except } from "type-fest";
import { EmailAddress } from "@libs/utils/email-rfc";

export const DEFAULT_SUBSCRIPTION_PREFERENCE = "involved";

/**
 * Accepts a subscription preference and returns a number
 * representing how much that subscription preference
 * should be prioritized relative to the other subscription
 * preference options. This value is only useful in
 * relation to the other subscription preference priorities.
 */
export function getSubscriptionPreferencePriority(
  preference: ISubscriptionDoc["preference"],
) {
  switch (preference) {
    case "all": {
      return 1;
    }
    case "all-new": {
      return 2;
    }
    case "involved": {
      return 3;
    }
    default: {
      throw new UnreachableCaseError(preference);
    }
  }
}

/**
 * Accepts a subscription preference priority (as returned
 * from `getSubscriptionPreferencePriority`()) and returns
 * the associated subscription preference.
 *
 * @param priority return value from `getSubscriptionPreferencePriority()`
 */
export function getSubscriptionPreferenceFromPriority(
  priority: ReturnType<typeof getSubscriptionPreferencePriority>,
) {
  switch (priority) {
    case 1: {
      return "all";
    }
    case 2: {
      return "all-new";
    }
    case 3: {
      return "involved";
    }
    default: {
      throw new UnreachableCaseError(priority);
    }
  }
}

export type ICommsPostTypeSpecificJson = null;
export type IEmailPostTypeSpecificJson = Except<
  IEmailPostDoc,
  keyof ICommsPostDoc
>;
export type ISecretEmailPostTypeSpecificJson = Except<
  ISecretEmailPostDoc,
  keyof ICommsPostDoc
>;

export type IPostTypeSpecificJson =
  | ICommsPostTypeSpecificJson
  | IEmailPostTypeSpecificJson
  | ISecretEmailPostTypeSpecificJson;

export function getPostTypeSpecificJson(doc: IPostDoc) {
  switch (doc.type) {
    case "COMMS": {
      return null;
    }
    case "EMAIL": {
      return JSON.stringify(
        pick(
          doc,
          getTypesafeKeys<IEmailPostTypeSpecificJson>({
            emailMessageId: true,
            emailReferences: true,
            cc: true,
            from: true,
            sender: true,
            to: true,
          }),
        ),
      );
    }
    case "EMAIL_SECRET": {
      return JSON.stringify(
        pick(
          doc,
          getTypesafeKeys<ISecretEmailPostTypeSpecificJson>({
            emailMessageId: true,
            emailReferences: true,
            forUserId: true,
            forPostId: true,
            forThreadId: true,
            cc: true,
            from: true,
            sender: true,
            to: true,
            bcc: true,
          }),
        ),
      );
    }
    default: {
      throw new UnreachableCaseError(doc);
    }
  }
}

/** Typesafe helper to an array containing specific values. */
function getTypesafeKeys<T>(value: { [Key in keyof T]: true }) {
  return Object.keys(value);
}

export type ICommsThreadTypeSpecificJson = null;
export type IEmailThreadTypeSpecificJson = Except<
  IEmailThreadDoc,
  keyof ICommsThreadDoc
>;
export type ISecretEmailThreadTypeSpecificJson = Except<
  ISecretEmailThreadDoc,
  keyof ICommsThreadDoc
>;
export type IThreadTypeSpecificJson =
  | ICommsThreadTypeSpecificJson
  | IEmailThreadTypeSpecificJson
  | ISecretEmailThreadTypeSpecificJson;

export function getThreadTypeSpecificJson(doc: IThreadDoc) {
  switch (doc.type) {
    case "COMMS": {
      return null;
    }
    case "EMAIL": {
      type Checker<
        T extends { [Key in keyof IEmailThreadTypeSpecificJson]: true },
      > = T;

      // We're just doing a manual type check so that typescript throws an
      // error if we need to update this case in the future.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      type Test = Checker<{}>;

      return null;
    }
    case "EMAIL_SECRET": {
      return JSON.stringify(
        pick(
          doc,
          getTypesafeKeys<ISecretEmailThreadTypeSpecificJson>({
            forUserId: true,
            forThreadId: true,
          }),
        ),
      );
    }
    default: {
      throw new UnreachableCaseError(doc);
    }
  }
}

/**
 * If a currentUserId is provided and if the post was created by the current
 * user, then "Me" is returned.
 */
export function getPostSenderName(post: IPostDoc, currentUserId?: string) {
  if (currentUserId && post.creatorId === currentUserId) {
    return "Me";
  }

  switch (post.type) {
    case "COMMS": {
      return post.creatorName;
    }
    case "EMAIL":
    case "EMAIL_SECRET": {
      return (
        post.creatorName || post.sender?.label || post.sender?.address || null
      );
    }
    default: {
      throw new UnreachableCaseError(post);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

export type IEmailPostDocFromSecretEmailPost = IEmailPostDoc & {
  __local: { fromSecretPost: ISecretEmailPostDoc };
};

export function mapSecretEmailPostToEmailPost(
  secretPost: ISecretEmailPostDoc,
): IEmailPostDocFromSecretEmailPost;
export function mapSecretEmailPostToEmailPost(
  secretPost: WithServerTimestamp<ISecretEmailPostDoc>,
): WithServerTimestamp<IEmailPostDocFromSecretEmailPost>;
export function mapSecretEmailPostToEmailPost(
  secretPost: WithDateOrServerTimestamp<ISecretEmailPostDoc>,
): WithDateOrServerTimestamp<IEmailPostDocFromSecretEmailPost>;
export function mapSecretEmailPostToEmailPost(
  secretPost:
    | ISecretEmailPostDoc
    | WithDateOrServerTimestamp<ISecretEmailPostDoc>,
):
  | IEmailPostDocFromSecretEmailPost
  | WithDateOrServerTimestamp<IEmailPostDocFromSecretEmailPost> {
  return {
    ...secretPost,
    type: "EMAIL",
    id: secretPost.forPostId,
    threadId: secretPost.forThreadId,
    // Since we're mapping this to a regular email post, we should
    // maintain the fact that the current user doesn't have access
    // to the email post.
    recipientChannelIds: [],
    recipientUserIds: [],
    recipientUsers: {},
    __local: {
      fromSecretPost: secretPost,
    },
  };
}

/* -----------------------------------------------------------------------------------------------*/

export type IEmailThreadDocFromSecretEmailThread = IEmailThreadDoc & {
  __local: { fromSecretThread: ISecretEmailThreadDoc };
};

export function mapSecretEmailThreadToEmailThread(
  secretThread: ISecretEmailThreadDoc,
): IEmailThreadDocFromSecretEmailThread;
export function mapSecretEmailThreadToEmailThread(
  secretThread: WithServerTimestamp<ISecretEmailThreadDoc>,
): WithServerTimestamp<IEmailThreadDocFromSecretEmailThread>;
export function mapSecretEmailThreadToEmailThread(
  secretThread: WithDateOrServerTimestamp<ISecretEmailThreadDoc>,
): WithDateOrServerTimestamp<IEmailThreadDocFromSecretEmailThread>;
export function mapSecretEmailThreadToEmailThread(
  secretThread:
    | ISecretEmailThreadDoc
    | WithDateOrServerTimestamp<ISecretEmailThreadDoc>,
):
  | IEmailThreadDocFromSecretEmailThread
  | WithDateOrServerTimestamp<IEmailThreadDocFromSecretEmailThread> {
  return {
    ...secretThread,
    type: "EMAIL" as const,
    id: secretThread.forThreadId,
    firstPost: mapSecretEmailPostToEmailPost(secretThread.firstPost),
    lastPost: mapSecretEmailPostToEmailPost(secretThread.lastPost),
    // Since we're mapping this to a regular email thread, we should
    // maintain the fact that the current user doesn't have access
    // to the email thread.
    permittedChannelIds: [],
    permittedUserIds: [],
    userPermissions: {},
    participatingUserIds: [],
    participatingUsers: {},
    __local: {
      fromSecretThread: secretThread,
    },
  };
}

/* -----------------------------------------------------------------------------------------------*/

export function getEmailReplyRecipientsFromLastPost(
  lastPost: IEmailPostDoc,
  currentUserEmail: string,
) {
  const currentUserLowercaseEmail = currentUserEmail.toLowerCase();

  const wasLastPostSentByCurrentUser =
    lastPost.sender?.address?.toLowerCase() === currentUserLowercaseEmail &&
    lastPost.from.length === 1;

  let to: EmailAddress[];
  let cc: EmailAddress[];

  if (wasLastPostSentByCurrentUser) {
    to = lastPost.to.slice();
    cc = lastPost.cc.slice();
  } else {
    to = lastPost.from.slice();

    if (lastPost.sender) {
      to.push(lastPost.sender);
    }

    cc = [...lastPost.to, ...lastPost.cc];
  }

  to = uniqBy(to, "address").filter(
    (r) => r.address?.toLowerCase() !== currentUserLowercaseEmail,
  );

  cc = uniqBy(cc, "address").filter(
    (r) => r.address?.toLowerCase() !== currentUserLowercaseEmail,
  );

  return { to, cc };
}

/* -----------------------------------------------------------------------------------------------*/
