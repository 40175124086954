import {
  DialogState,
  DIALOG_OVERLAY_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { css, cx } from "@emotion/css";
import { ComponentType } from "react";
import {
  PLATFORM_MODIFIER_KEY,
  useRegisterCommands,
} from "~/services/command.service";
import { openHelpCommand } from "~/utils/common-commands";

export const HelpDialogState = new DialogState();

const overlayCSS = cx(
  DIALOG_OVERLAY_CSS,
  css`
    backdrop-filter: blur(8px);
  `,
);

export const HelpDialog = withModalDialog({
  dialogState: HelpDialogState,
  overlayCSS,
  useOnDialogContainerRendered: () => {
    useRegisterCommands({
      commands: () => {
        return [
          openHelpCommand({
            callback: () => {
              HelpDialogState.toggle(true);
            },
          }),
        ];
      },
    });
  },
  Component: () => {
    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              HelpDialogState.toggle(false);
            },
          },
        ];
      },
    });

    return (
      <div
        className={cx(
          "text-white bg-blackA-10 rounded-lg p-6",
          "overflow-y-auto",
        )}
      >
        <h2 className="text-3xl mb-4">Help</h2>

        <ul className="list-disc mb-4">
          <li className="ml-8">
            <a
              href="https://www.notion.so/levelshealth/Comms-By-Levels-2c18b69d056a4b4da10c5b4725632b22"
              rel="noreferrer"
              target="_blank"
              className="underline"
            >
              Notion onboarding document
            </a>
          </li>

          <li className="ml-8">
            <a
              href="https://github.com/orgs/levelshealth/projects/2"
              rel="noreferrer"
              target="_blank"
              className="underline"
            >
              Comms Roadmap (highlights only)
            </a>
          </li>
        </ul>

        <h3 className="text-xl mb-4 mt-8">General Commands</h3>

        <table className="border border-white border-collapse w-full">
          <thead>
            <tr>
              <th className={cx(thCSS, "w-3/8")}>Shortcut</th>
              <th className={cx(thCSS, "w-5/8")}>Command</th>
            </tr>
          </thead>
          <tbody>
            <Row shortcut={"Escape"} command="Close dialog" />
            <Row shortcut={"←"} command="Open sidebar" />
            <Row shortcut={"G then I"} command="Go to Inbox" />
            <Row shortcut={"G then R"} command="Go to Starred" />
            <Row shortcut={"G then D"} command="Go to Drafts" />
            <Row shortcut={"G then T"} command="Go to Sent" />
            <Row shortcut={"G then E"} command="Go to Done" />
            <Row shortcut={"G then H"} command="Go to Reminders" />
            <Row shortcut={"/"} command="Go to Search" />
            <Row shortcut={"G then C"} command="Go to channel..." />
            <Row shortcut={"C"} command="Compose new post" />
            <Row shortcut={"R"} command="Reply to thread" />
            <Row
              shortcut={"E"}
              command="Mark done (from Inbox view and from Thread view)"
            />
            <Row
              shortcut={"Shift + E"}
              command="Mark not done (from Done view and from Thread view)"
            />
            <Row
              shortcut={"H"}
              command="Set reminder (Inbox, Done, Reminder, and Thread views)"
            />
            <Row
              shortcut={"Shift + H"}
              command="Remove reminder (Inbox, Done, Reminder, and Thread views)"
            />
            <Row
              shortcut={`${PLATFORM_MODIFIER_KEY.shortName} + Shift + ,`}
              command="Delete draft"
            />
            <Row
              shortcut={"S"}
              command="Subscribe/unsubscribe from thread or channel"
            />
            <Row
              shortcut={"Enter"}
              command="Expand/collapse post (Thread view only)"
            />
            <Row
              shortcut={`${PLATFORM_MODIFIER_KEY.shortName} + K`}
              command="Command Bar"
            />
            <Row
              shortcut={`${PLATFORM_MODIFIER_KEY.shortName} + Enter`}
              command="Submit form"
            />
            <Row shortcut={"?"} command="Open help menu" />
          </tbody>
        </table>

        <h3 className="text-xl mb-4 mt-12">Editor Shortcuts</h3>

        <p className="mb-2">
          The Comms editor is a "What You See is What You Get" editor that has
          builtin shortcuts that mimic some common markdown syntax. Unlike most
          text editors on the web, Comms does not provide any kind of toolbar.
        </p>

        <ul className="list-disc mb-4">
          <li className="ml-8">
            <a
              href="https://www.loom.com/share/27118dee919541cf8a40adeea6c16411"
              rel="noreferrer"
              target="_blank"
              className="underline"
            >
              Comms message editor walkthrough
            </a>
          </li>
        </ul>

        <table className="border border-white border-collapse w-full">
          <thead>
            <tr>
              <th className={cx(thCSS, "w-3/8")}>Shortcut</th>
              <th className={cx(thCSS, "w-5/8")}>Effect</th>
            </tr>
          </thead>
          <tbody>
            <Row
              shortcut={"# then Space"}
              command="Format as Huge header (only available from start of line)"
            />
            <Row
              shortcut={"## then Space"}
              command="Format as Large header (only available from start of line)"
            />
            <Row
              shortcut={"### then Space"}
              command="Format as header (only available from start of line)"
            />
            <Row
              shortcut={`**some text** OR ${PLATFORM_MODIFIER_KEY.shortName} + B`}
              command="Format as bold"
            />
            <Row
              shortcut={`*some text* OR ${PLATFORM_MODIFIER_KEY.shortName} + I`}
              command="Format as italic"
            />
            <Row shortcut={"`some text`"} command="Format as inline code" />
            <Row shortcut={"~~some text~~"} command="Format as strikethrough" />
            <Row
              shortcut={"> then Space"}
              command="Format as blockquote (only available from start of line)"
            />
            <Row
              shortcut={"``` then Space"}
              command="Format as codeblock (only available from start of line)"
            />
            <Row
              shortcut={"- then Space"}
              command="Format as bulletted list (only available from start of line)"
            />
            <Row
              shortcut={"Select Text then Paste Hyperlink"}
              command="Make selected text a hyperlink"
            />
            <Row
              shortcut={`${PLATFORM_MODIFIER_KEY.shortName} + Enter`}
              command="Send message"
            />
          </tbody>
        </table>

        <p className="mt-4">
          <em>
            <strong>Got feedback?</strong> Post to the{" "}
            <a href="/channels/cNWflsm4PB1ZUzPTsyCpQy" className="underline">
              #Comms channel
            </a>{" "}
            or send a message directly to John Carroll. You can also send an
            email to john.carroll.p@gmail.com or post to the{" "}
            <a
              href="https://www.notion.so/levelshealth/Comms-By-Levels-2c18b69d056a4b4da10c5b4725632b22"
              rel="noreferrer"
              target="_blank"
              className="underline"
            >
              Notion onboarding document
            </a>
            .
          </em>
        </p>
      </div>
    );
  },
});

const thCSS = "border border-whiteA-9 bg-whiteA-5 px-4 py-2 text-left";
const tdCSS = "border border-whiteA-9 px-4 py-2";

const Row: ComponentType<{ shortcut: string; command: string }> = (props) => {
  return (
    <tr>
      <td className={tdCSS}>{props.shortcut}</td>
      <td className={tdCSS}>{props.command}</td>
    </tr>
  );
};
