import {
  callCommandById,
  ICommandArgs,
  PLATFORM_MODIFIER_KEY,
  PLATFORM_ALT_KEY,
  useRegisterCommands,
} from "~/services/command.service";
import { deleteDraftCommand } from "~/utils/common-commands";
import {
  createNewDraft,
  deleteDraft,
  IDraft,
  mapRecipientOptionToDraftRecipient,
  updateNewDraft,
} from "~/services/draft.service";
import { toast } from "~/services/toast-service";
import {
  closeComposeNewThreadDialog,
  openComposeNewThreadDialog,
} from "../page-dialog-state";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { handleSubmit, observable } from "~/form-components/utils";
import { combineLatest, map, withLatestFrom } from "rxjs";
import {
  CLOSE_DRAFT_COMMAND_ID,
  getSaveDraftFns,
  IComposeMessageForm,
  IComposeMessageFormValue,
  SEND_MESSAGE_COMMAND_ID,
  useAutosaveDraft as _useAutosaveDraft,
} from "~/components/ComposeMessageContext";
import { PendingUpdates } from "~/services/loading.service";
import { ComponentType, forwardRef, useEffect } from "react";
import { IFormControl } from "solid-forms-react";
import { TextInput } from "~/form-components/TextInput";
import { cx } from "@emotion/css";
import { getAndAssertCurrentUser } from "~/services/user.service";
import { useOrganizations } from "~/services/organization.service";
import {
  IChannelRecipientOption,
  IRecipientOption,
} from "~/form-components/ThreadRecipients";
import { docRef, waitForCacheToContainDoc } from "~/firestore.service";
import {
  DeleteDraftButton,
  SendDraftButton,
} from "~/components/ComposeReplyBase";
import { OutlineButton } from "~/components/OutlineButtons";
import { ThreadVisibility } from "@libs/firestore-models";
import {
  throwUnreachableCaseError,
  UnreachableCaseError,
} from "@libs/utils/errors";
import {
  CURRENT_USER_MAIN_SETTINGS$,
  useCurrentUserMainSettings,
} from "~/services/settings.service";
import { Tooltip } from "~/components/Tooltip";

export const Subject = forwardRef<
  HTMLInputElement,
  {
    control: IFormControl<string>;
  }
>((props, ref) => {
  return (
    <div
      className={cx(
        // the transparent border exists so that this input has the
        // same height as the recipients input. The recipient chips
        // have a non-transparent border.
        "flex flex-1 py-1 border-y border-transparent outline-none",
        "font-bold placeholder:font-medium",
      )}
    >
      <TextInput ref={ref} name="subject" control={props.control} />
    </div>
  );
});

export function useRegisterSharedComposeNewMessageCommands(args: {
  control: IComposeMessageForm;
  submit: (
    values: IComposeMessageFormValue,
    options?: { sendImmediately?: boolean },
  ) => Promise<void>;
}) {
  const { control, submit } = args;

  useRegisterCommands({
    commands: () => {
      return [
        deleteDraftCommand({
          triggerHotkeysWhenInputFocused: true,
          callback: () => {
            cancelSaveNewDraft(control.rawValue.postId);
            deleteDraft(control.rawValue);
            closeComposeNewThreadDialog();

            toast("undo", {
              subject: "Draft deleted.",
              onAction: async () => {
                const value = control.rawValue;
                const currentUser = getAndAssertCurrentUser();

                const newDraftPromise = createNewDraft({
                  type: value.type,
                  postId: value.postId,
                  visibility: value.visibility,
                  to: value.recipients.to.map((r) =>
                    mapRecipientOptionToDraftRecipient(r, value.type),
                  ),
                  cc: value.recipients.cc.map((r) =>
                    mapRecipientOptionToDraftRecipient(r, value.type),
                  ),
                  bcc: value.recipients.bcc.map((r) =>
                    mapRecipientOptionToDraftRecipient(r, value.type),
                  ),
                  subject: value.subject,
                  bodyHTML: value.body.content,
                  channelMentions: value.body.channelMentions,
                  userMentions: value.body.userMentions,
                });

                await waitForCacheToContainDoc(
                  docRef("users", currentUser.id, "unsafeDrafts", value.postId),
                  newDraftPromise,
                );

                openComposeNewThreadDialog(value.postId);
              },
            });
          },
        }),
        {
          id: CLOSE_DRAFT_COMMAND_ID,
          label: "Close draft",
          hotkeys: ["Escape"],
          triggerHotkeysWhenInputFocused: true,
          callback: () => {
            cancelSaveNewDraft(control.rawValue.postId);

            const values = control.rawValue;

            const content = values.body.content;

            const isEmptyDraft =
              (!content.trim() || content === "<p></p>") &&
              values.recipients.to.length === 0 &&
              values.recipients.cc.length === 0 &&
              !values.subject.trim();

            // If this draft is empty, then the useSaveDraft
            // will be deleting it so we don't need to
            // worry about saving anything here.
            if (isEmptyDraft) {
              closeComposeNewThreadDialog();
              return;
            }

            updateNewDraft({
              type: values.type,
              postId: values.postId,
              branchedFrom: null,
              visibility: values.visibility,
              to: values.recipients.to.map((r) =>
                mapRecipientOptionToDraftRecipient(r, values.type),
              ),
              cc: values.recipients.cc.map((r) =>
                mapRecipientOptionToDraftRecipient(r, values.type),
              ),
              bcc: values.recipients.bcc.map((r) =>
                mapRecipientOptionToDraftRecipient(r, values.type),
              ),
              subject: values.subject || "",
              bodyHTML: values.body.content || "",
              channelMentions: values.body.channelMentions || [],
              userMentions: values.body.userMentions || [],
            })
              .then(() => console.debug("WIP draft saved successfully"))
              .catch((e) => console.error("updateNewDraft", e));

            closeComposeNewThreadDialog();
          },
        },
        {
          id: SEND_MESSAGE_COMMAND_ID,
          label: "Send message",
          hotkeys: [
            "$mod+Enter",
            // $mod+Shift+Enter is the hotkey for "send and mark done". While
            // "marking done" doesn't apply to a new message, someone might
            // want to use the same hotkey to send a new draft because of
            // muscle memory
            "$mod+Shift+Enter",
          ],
          triggerHotkeysWhenInputFocused: true,
          callback: onlyCallFnOnceWhilePreviousCallIsPending(
            async (e?: KeyboardEvent) => {
              e?.preventDefault();
              e?.stopPropagation();

              if (control.status === "VALID") {
                cancelSaveNewDraft(control.rawValue.postId);
              }

              await handleSubmit(control, submit);
            },
          ),
        },
        {
          label: "Send message immediately",
          callback: onlyCallFnOnceWhilePreviousCallIsPending(
            async (e?: KeyboardEvent) => {
              e?.preventDefault();
              e?.stopPropagation();

              if (control.status === "VALID") {
                cancelSaveNewDraft(control.rawValue.postId);
              }

              await handleSubmit(control, (values) =>
                submit(values, { sendImmediately: true }),
              );
            },
          ),
        },
      ];
    },
    deps: [cancelSaveNewDraft, deleteDraft],
  });

  useRegisterCommands({
    commands: () => {
      return observable(() => control.rawValue.visibility).pipe(
        map((visibility) => {
          const props =
            visibility === "private"
              ? {
                  label: "Mark shared",
                  keywords: ["Mark public", "Mark private"],
                }
              : {
                  label: "Mark private",
                  keywords: ["Mark public", "Mark shared"],
                };

          return [
            {
              ...props,
              id: TOGGLE_VISIBILITY_COMMAND_ID,
              hotkeys: [
                "$mod+Alt+p",
                // Note that, depending on the browser, the emitted KeyboardEvent#key
                // value may be "p" or "P" (chrome on windows seems to do "p" whereas
                // Firefox does "P"). For this reason, we also add a second shortcut
                // using "KeyP". The first shortcut will be shown in the kbar
                "$mod+Alt+KeyP",
              ],
              triggerHotkeysWhenInputFocused: true,
              callback: () => makePrivateCommandCallback(control),
            },
          ];
        }),
      );
    },
    deps: [control],
  });

  useRegisterCommands({
    commands: () => {
      return combineLatest([
        CURRENT_USER_MAIN_SETTINGS$,
        observable(() => control.rawValue.type),
      ]).pipe(
        map(([settings, draftType]) => {
          const isEmailEnabled = settings?.linkedGmailEmailAccount === true;

          if (!isEmailEnabled) {
            return [];

            // TODO:
            // When email is out of beta and open to anyone, show users
            // a helpful message if they haven't linked their email.
            //
            // return [
            //   {
            //     id: TOGGLE_DRAFT_TYPE_COMMAND_ID,
            //     label: "Convert to email message",
            //     keywords: ["Make email", "Make comms message"],
            //     callback: () => {
            //       toast("vanilla", {
            //         subject: "You must link your email account to send emails",
            //       });
            //     },
            //   },
            // ];
          }

          const props = (
            draftType === "COMMS" || draftType === null
              ? {
                  label: "Convert to email message",
                  keywords: ["Make email", "Make comms message"],
                }
              : draftType === "EMAIL"
              ? {
                  label: "Convert to comms message",
                  keywords: ["Make email", "Make comms message"],
                }
              : throwUnreachableCaseError(draftType)
          ) satisfies Partial<ICommandArgs>;

          return [
            {
              ...props,
              id: TOGGLE_DRAFT_TYPE_COMMAND_ID,
              callback: () => toggleDraftTypeCommandCallback(control),
            },
          ];
        }),
      );
    },
    deps: [control],
  });
}

const TOGGLE_VISIBILITY_COMMAND_ID = "TOGGLE_VISIBILITY";
const TOGGLE_DRAFT_TYPE_COMMAND_ID = "TOGGLE_DRAFT_TYPE";

export function makePrivateCommandCallback(control: IComposeMessageForm) {
  const newVisibility =
    control.rawValue.visibility === "private" ? "shared" : "private";

  const filterFn = (r: IRecipientOption) =>
    r.type === "user" || r.type === "email" || r.classification === "private";

  const to =
    newVisibility === "private"
      ? control.rawValue.recipients.to.filter(filterFn)
      : control.rawValue.recipients.to;

  const cc =
    newVisibility === "private"
      ? control.rawValue.recipients.cc.filter(filterFn)
      : control.rawValue.recipients.cc;

  const bcc =
    newVisibility === "private"
      ? control.rawValue.recipients.bcc.filter(filterFn)
      : control.rawValue.recipients.bcc;

  control.patchValue({
    visibility: newVisibility,
    recipients: {
      to,
      cc,
      bcc,
    },
  });
}

export function toggleDraftTypeCommandCallback(control: IComposeMessageForm) {
  const newDraftType =
    control.rawValue.type === "COMMS"
      ? "EMAIL"
      : control.rawValue.type === "EMAIL"
      ? "COMMS"
      : throwUnreachableCaseError(control.rawValue.type);

  const filterFn =
    newDraftType === "COMMS"
      ? (r: IRecipientOption) => r.type === "user" || r.type === "channel"
      : newDraftType === "EMAIL"
      ? (r: IRecipientOption) =>
          r.type === "user" || r.type === "email" || r.type === "channel"
      : throwUnreachableCaseError(newDraftType);

  const to = control.rawValue.recipients.to.filter(filterFn);
  const cc = control.rawValue.recipients.cc.filter(filterFn);
  const bcc = control.rawValue.recipients.bcc.filter(filterFn);

  control.patchValue({
    type: newDraftType,
    recipients: {
      to,
      cc,
      bcc,
    },
  });
}

export function useAutosaveDraft(control: IComposeMessageForm) {
  _useAutosaveDraft({
    control,
    saveDraft: saveNewDraft,
    cancelSaveDraft: cancelSaveNewDraft,
  });
}

const [saveNewDraft, cancelSaveNewDraft] = getSaveDraftFns(function (values) {
  this.pendingDebouncePostId = null;

  updateNewDraft({
    type: values.type,
    postId: values.postId,
    branchedFrom: values.branchedFrom,
    visibility: values.visibility,
    to: values.recipients.to.map((r) =>
      mapRecipientOptionToDraftRecipient(r, values.type),
    ),
    cc: values.recipients.cc.map((r) =>
      mapRecipientOptionToDraftRecipient(r, values.type),
    ),
    bcc: values.recipients.bcc.map((r) =>
      mapRecipientOptionToDraftRecipient(r, values.type),
    ),
    subject: values.subject || "",
    bodyHTML: values.body.content || "",
    channelMentions: values.body.channelMentions || [],
    userMentions: values.body.userMentions || [],
  })
    .then(() => console.debug("WIP draft saved successfully"))
    .catch((e) => console.error("updateNewDraft", e));

  PendingUpdates.remove(values.postId);

  return values;
});

export { cancelSaveNewDraft };

function cloneJSON<T>(value: T): T {
  return JSON.parse(JSON.stringify(value));
}

export function useUpdateVisibilityOnRecipientChanges(
  control: IComposeMessageForm,
) {
  const userOrganizations = useOrganizations();

  useEffect(() => {
    const sub = observable(() => control.rawValue.recipients)
      .pipe(withLatestFrom(observable(() => control.rawValue.visibility)))
      .subscribe(([recipients, visibility]) => {
        console.debug(
          "useUpdateVisibilityOnRecipientChanges",
          cloneJSON({
            visibility,
            recipients,
          }),
        );

        const currentUser = getAndAssertCurrentUser();

        const organization = userOrganizations.find(
          (org) => org.id === currentUser.organizationId,
        );

        // TODO:
        // Change this when we support multiple organizations
        // and users who aren't part of an organization
        if (!organization) return;

        const allRecipients = [
          ...recipients.to,
          ...recipients.cc,
          ...recipients.bcc,
        ];

        const sharedChannelCount = allRecipients.reduce((prev, curr) => {
          const isSharedChannel =
            curr.type === "channel" &&
            curr.value === organization.sharedChannelId;

          return isSharedChannel ? prev + 1 : prev;
        }, 0);

        if (
          allRecipients.length === 0 ||
          sharedChannelCount === allRecipients.length
        ) {
          if (visibility === null) return;
          // We use setTimeout to ensure that any other subscriptions to
          // this control's value changes have a chance to respond to the
          // current change before we emit a new change.
          setTimeout(() => control.patchValue({ visibility: null }));
          return;
        }

        if (visibility) return;

        const isPrivate = allRecipients.some(
          (r) => r.type === "channel" && r.classification === "private",
        );

        if (isPrivate) {
          // We use setTimeout to ensure that any other subscriptions to
          // this control's value changes have a chance to respond to the
          // current change before we emit a new change.
          setTimeout(() => control.patchValue({ visibility: "private" }));
        } else {
          // We use setTimeout to ensure that any other subscriptions to
          // this control's value changes have a chance to respond to the
          // current change before we emit a new change.
          setTimeout(() => control.patchValue({ visibility: "shared" }));
        }
      });

    return () => sub.unsubscribe();
  }, [control, userOrganizations]);
}

export function useUpdateRecipientsOnVisibilityChanges(
  control: IComposeMessageForm,
) {
  useEffect(() => {
    const sub = observable(() => control.rawValue.visibility)
      .pipe(withLatestFrom(observable(() => control.rawValue.recipients)))
      .subscribe(([visibility, recipients]) => {
        console.debug(
          "useUpdateRecipientsOnVisibilityChanges",
          cloneJSON({
            visibility,
            recipients,
          }),
        );

        switch (visibility) {
          case "private": {
            const filterFn = (r: IRecipientOption) =>
              r.type === "user" ||
              r.type === "email" ||
              (r.type === "channel" && r.classification === "private");

            // We use setTimeout to ensure that any other subscriptions to
            // this control's value changes have a chance to respond to the
            // current change before we emit a new change.
            setTimeout(() =>
              control.patchValue({
                recipients: {
                  to: recipients.to.filter(filterFn),
                  cc: recipients.cc.filter(filterFn),
                  bcc: recipients.bcc.filter(filterFn),
                },
              }),
            );

            return;
          }
          case "shared": {
            const filterFn = (r: IRecipientOption) =>
              r.type === "user" ||
              r.type === "email" ||
              (r.type === "channel" && r.classification === "public");

            // We use setTimeout to ensure that any other subscriptions to
            // this control's value changes have a chance to respond to the
            // current change before we emit a new change.
            setTimeout(() =>
              control.patchValue({
                recipients: {
                  to: recipients.to.filter(filterFn),
                  cc: recipients.cc.filter(filterFn),
                  bcc: recipients.bcc.filter(filterFn),
                },
              }),
            );

            return;
          }
          case null: {
            return;
          }
          default: {
            throw new UnreachableCaseError(visibility);
          }
        }
      });

    return () => sub.unsubscribe();
  }, [control]);
}

/**
 * This effect handles adding the shared channel when the recipients
 * change as well as updating the shared channel option on
 * walkthrough completion.
 *
 * Note, the AutocompleteSelect component accepts a custom Option
 * component which we use to display and style the shared channel option
 * when a walkthrough is available. Annoyingly, this custom Option
 * component must be "pure" and does not support the usage of react context
 * within it. Because of this, we need to provide all state within the
 * option object itself. So when the `walkthroughNotCompleted` value
 * changes, we need to update the shared channel option if it is present
 * in the recipients array.
 */
export function useAddSharedChannelsOnRecipientChanges(args: {
  control: IComposeMessageForm;
  walkthroughNotCompleted: boolean | undefined;
}) {
  const { control, walkthroughNotCompleted } = args;
  const userOrganizations = useOrganizations();

  useEffect(() => {
    const sub = observable(() => control.rawValue.recipients)
      .pipe(withLatestFrom(observable(() => control.rawValue.visibility)))
      .subscribe(([recipients, visibility]) => {
        console.debug(
          "useAddSharedChannelsOnRecipientChanges",
          cloneJSON({
            visibility,
            recipients,
          }),
        );

        if (visibility === "private") return;

        const allRecipients = [
          ...recipients.to,
          ...recipients.cc,
          ...recipients.bcc,
        ];

        if (allRecipients.length === 0) return;

        const currentUser = getAndAssertCurrentUser();

        const organization = userOrganizations.find(
          (org) => org.id === currentUser.organizationId,
        );

        // TODO:
        // Change this when we support multiple organizations
        // and users who aren't part of an organization
        if (!organization) return;

        const sharedChannelCount = allRecipients.reduce((prev, curr) => {
          const isSharedChannel =
            curr.type === "channel" &&
            curr.value === organization.sharedChannelId;

          return isSharedChannel ? prev + 1 : prev;
        }, 0);

        if (sharedChannelCount === allRecipients.length) {
          // Clear shared channels if they are the only recipients

          // We use setTimeout to ensure that any other subscriptions to
          // this control's value changes have a chance to respond to the
          // current change before we emit a new change.
          setTimeout(() =>
            control.patchValue({
              recipients: {
                to: [],
                cc: [],
                bcc: [],
              },
            }),
          );
        } else if (sharedChannelCount > 0) {
          // Make sure any shared channels present have the correct
          // "showWalkthrough" value.

          const areSharedChannelsAlreadyUpdated = allRecipients.every(
            (r) =>
              r.type !== "channel" ||
              !r.sharedChannel ||
              r.sharedChannel.showWalkthrough === !!walkthroughNotCompleted,
          );

          if (areSharedChannelsAlreadyUpdated) return;

          const mapFn = <T extends IRecipientOption>(r: T): T => {
            if (
              r.type === "user" ||
              r.type === "email" ||
              (r.type === "channel" && !r.sharedChannel)
            ) {
              return r;
            }

            return {
              ...r,
              sharedChannel: {
                // we guarded against null in the if statement, above
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ...r.sharedChannel!,
                showWalkthrough: !!walkthroughNotCompleted,
              },
            } satisfies IChannelRecipientOption as T;
          };

          // We use setTimeout to ensure that any other subscriptions to
          // this control's value changes have a chance to respond to the
          // current change before we emit a new change.
          setTimeout(() =>
            control.patchValue({
              recipients: {
                to: recipients.to.map(mapFn),
                cc: recipients.cc.map(mapFn),
                bcc: recipients.bcc.map(mapFn),
              },
            }),
          );
        } else {
          // If the thread is shared and if there are no shared channels as recipients
          // then we need to add the shared channel to the recipients.

          // We use setTimeout to ensure that any other subscriptions to
          // this control's value changes have a chance to respond to the
          // current change before we emit a new change.
          setTimeout(() =>
            control.patchValue({
              recipients: {
                to: [
                  {
                    type: "channel",
                    label: `${organization.nameShort} Shared`,
                    value: organization.sharedChannelId,
                    channelGroupNames: [],
                    classification: "public",
                    isFixed: true,
                    sharedChannel: {
                      organizationName: organization.name,
                      showWalkthrough: !!walkthroughNotCompleted,
                    },
                  },
                  ...recipients.to,
                ],
              },
            }),
          );
        }
      });

    return () => sub.unsubscribe();
  }, [control, userOrganizations, walkthroughNotCompleted]);
}

/**
 * Automatically converts a draft to an email draft
 * if an email recipient is added.
 */
export function useUpdateDraftTypeOnRecipientChanges(
  control: IComposeMessageForm,
) {
  useEffect(() => {
    const sub = observable(() => control.rawValue.recipients)
      .pipe(withLatestFrom(observable(() => control.rawValue.type)))
      .subscribe(([recipients, draftType]) => {
        console.debug(
          "useUpdateDraftTypeOnRecipientChanges",
          cloneJSON({
            draftType,
            recipients,
          }),
        );

        if (draftType === "EMAIL") return;

        const allRecipients = [
          ...recipients.to,
          ...recipients.cc,
          ...recipients.bcc,
        ];

        const containsAnEmailRecipient = allRecipients.some(
          (r) => r.type === "email",
        );

        if (containsAnEmailRecipient) {
          // We use setTimeout to ensure that any other subscriptions to
          // this control's value changes have a chance to respond to the
          // current change before we emit a new change.
          setTimeout(() => control.patchValue({ type: "EMAIL" }));
          return;
        }
      });

    return () => sub.unsubscribe();
  }, [control]);
}

export function useUpdateRecipientsOnDraftTypeChanges(
  control: IComposeMessageForm,
) {
  useEffect(() => {
    const sub = observable(() => control.rawValue.type)
      .pipe(withLatestFrom(observable(() => control.rawValue.recipients)))
      .subscribe(([draftType, recipients]) => {
        console.debug(
          "useUpdateRecipientsOnDraftTypeChanges",
          cloneJSON({
            draftType,
            recipients,
          }),
        );

        const filterFn =
          draftType === "EMAIL"
            ? (r: IRecipientOption) =>
                r.type === "user" || r.type === "email" || r.type === "channel"
            : draftType === "COMMS"
            ? (r: IRecipientOption) => r.type === "user" || r.type === "channel"
            : throwUnreachableCaseError(draftType);

        setTimeout(() =>
          control.patchValue({
            recipients: {
              to: recipients.to.filter(filterFn),
              cc: recipients.cc.filter(filterFn),
              bcc: recipients.bcc.filter(filterFn),
            },
          }),
        );
      });

    return () => sub.unsubscribe();
  }, [control]);
}

export const DraftActions: ComponentType<{
  visibility: ThreadVisibility | null;
  draftType: IDraft["type"];
}> = (props) => {
  const settings = useCurrentUserMainSettings();

  const isEmailEnabled = settings?.linkedGmailEmailAccount === true;

  const toggleVisibilityLabel =
    props.visibility === "shared" || props.visibility === null
      ? "Mark private"
      : props.visibility === "private"
      ? "Mark shared"
      : throwUnreachableCaseError(props.visibility);

  const toggleDraftTypeLabel =
    props.draftType === "COMMS" || props.draftType === null
      ? "Convert to email"
      : props.draftType === "EMAIL"
      ? "Convert to Comms message"
      : throwUnreachableCaseError(props.draftType);

  return (
    <>
      <SendDraftButton />

      <Tooltip
        side="bottom"
        content={
          <>
            {toggleVisibilityLabel}{" "}
            <span className="flex items-center">
              (<PLATFORM_MODIFIER_KEY.symbol className="mr-1" />
              +
              <PLATFORM_ALT_KEY.symbol className="mx-1" /> + Enter)
            </span>
          </>
        }
      >
        <OutlineButton
          tabIndex={-1}
          onClick={(e) => {
            e.preventDefault();
            callCommandById(TOGGLE_VISIBILITY_COMMAND_ID);
          }}
        >
          <small>{toggleVisibilityLabel}</small>
        </OutlineButton>
      </Tooltip>

      {isEmailEnabled && (
        <Tooltip side="bottom" content={toggleDraftTypeLabel}>
          <OutlineButton
            tabIndex={-1}
            onClick={(e) => {
              e.preventDefault();
              callCommandById(TOGGLE_DRAFT_TYPE_COMMAND_ID);
            }}
          >
            <small>{toggleDraftTypeLabel}</small>
          </OutlineButton>
        </Tooltip>
      )}

      <div className="flex-1" />

      <DeleteDraftButton />
    </>
  );
};
