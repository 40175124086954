import { ComponentType, memo, RefObject, useEffect, useRef } from "react";
import {
  IChannelDocWithCurrentUserData,
  useChannel,
} from "~/services/channels.service";
import { useNavigate, useParams } from "react-router-dom";
import { ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { NotFound } from "~/components/NotFound";
import {
  updateChannelSubscription,
  useChannelSubscribersCount,
  useCurrentUserChannelSubscription,
} from "~/services/subscription.service";
import {
  ContentList,
  EmptyListMessage,
  onPostSelectNavigateToThread,
  PostEntry,
  useKBarAwareFocusedEntry,
} from "~/components/content-list";
import { useTopScrollShadow } from "~/utils/useScrollShadow";
import { cx } from "@emotion/css";
import {
  PendingRequestBar,
  withPendingRequestBar,
} from "~/components/PendingRequestBar";
import {
  ICommandArgs,
  useRegisterCommands,
  withNewCommandContext,
} from "~/services/command.service";
import { KBarState } from "~/dialogs/kbar";
import { EditChannelDialogState } from "~/dialogs/channel-edit/EditChannelDialog";
import { isAppOnline } from "~/services/network-connection.service";
import { toast, showNotImplementedToastMsg } from "~/services/toast-service";
import { Tooltip } from "~/components/Tooltip";
import { IoMdEye } from "react-icons/io";
import {
  IChannelDoc,
  IChannelSubscriptionDoc,
  IPostDoc,
  ISubscriptionDoc,
} from "@libs/firestore-models";
import {
  observeInboxNotification,
  triageThread,
} from "~/services/inbox.service";
import { RemindMeDialogState } from "~/dialogs/remind-me";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { firstValueFrom } from "rxjs";
import { useChannelThreads } from "~/services/post.service";
import {
  channelSubscriptionCommands,
  composeMessageCommand,
  ESCAPE_TO_INBOX_COMMAND,
  markDoneCommand,
  markNotDoneCommand,
  pinChannelCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unpinChannelCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import { BsFillPinFill, BsLockFill, BsPin } from "react-icons/bs";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";
import { UnreachableCaseError } from "@libs/utils/errors";
import { DEFAULT_SUBSCRIPTION_PREFERENCE } from "@libs/firestore-models/utils";
import * as MainLayout from "~/page-layouts/main-layout";
import { OutlineDropdownButton } from "~/components/OutlineButtons";
import { createNewDraft } from "~/services/draft.service";
import uid from "@libs/utils/uid";
import { wait } from "@libs/utils/wait";

/* -------------------------------------------------------------------------------------------------
 * ChannelView
 * -----------------------------------------------------------------------------------------------*/

export const ChannelView = withNewCommandContext(() => {
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);
  const params = useParams();
  const navigate = useNavigate();
  const channel = useChannel(params.channelId);
  const threads = useChannelThreads({
    channelId: params.channelId,
    pagingScrollboxRef: scrollboxRef,
  });

  const subscription = useCurrentUserChannelSubscription(params.channelId);

  const subscribersCount = useChannelSubscribersCount(params.channelId);

  const [focusedPost, setFocusedPost] = useKBarAwareFocusedEntry<IPostDoc>();

  useRegisterChannelViewCommands({
    focusedPost,
    channel,
    subscription,
  });

  useApplyScrollShadowToHeader({
    channel,
    scrollboxRef,
    headerRef,
  });

  useRedirectIfOrganizationSharedChannel(channel);

  if (channel === undefined) {
    return <div>Loading...</div>;
  }

  if (channel === null) {
    return <NotFound title="Channel Not Found" />;
  }

  return (
    <>
      <Helmet>
        <title>{channel.name} | Channel | Comms</title>
      </Helmet>

      <MainLayout.Header
        ref={headerRef}
        theme={channel.classification === "private" ? "dark" : "light"}
        className="flex-col"
      >
        <div
          className={cx("flex items-center", {
            ["mb-1"]: !!channel.description,
          })}
        >
          <h1 className="text-3xl text-slate-8 truncate">
            <span
              className={
                channel.classification === "private"
                  ? "text-white"
                  : "text-black"
              }
            >
              # {channel.name}
            </span>

            {channel.classification === "private" && (
              <Tooltip
                side="bottom"
                content="This channel is private and only visible to invited members"
              >
                <span className="text-2xl inline-flex ml-2 hover:cursor-help mt-1">
                  <small>
                    <BsLockFill />
                  </small>
                </span>
              </Tooltip>
            )}

            <ChannelGroupDetails channel={channel} />
          </h1>

          <div className="flex-1" />
        </div>

        {channel.description && (
          <p className="text-xl">{channel.description}</p>
        )}

        <MainLayout.HeaderMenu>
          <li className="flex items-center">
            {subscription?.isPinned ? (
              <Tooltip
                side="bottom"
                content="Click to unpin channel from sidebar."
              >
                <div
                  className={`text-slate-9 rounded-full h-7 w-7 flex justify-center
                        items-center hover:cursor-pointer hover:bg-slate-5`}
                  onClick={() =>
                    updateChannelSubscription({
                      channelId: channel.id,
                      isPinned: false,
                    })
                  }
                >
                  <BsFillPinFill />
                </div>
              </Tooltip>
            ) : (
              <Tooltip side="bottom" content="Click to pin channel to sidebar.">
                <div
                  className={`text-slate-9 rounded-full h-7 w-7 flex justify-center
                        items-center hover:cursor-pointer hover:bg-slate-5`}
                  onClick={() =>
                    updateChannelSubscription({
                      channelId: channel.id,
                      isPinned: true,
                    })
                  }
                >
                  <BsPin />
                </div>
              </Tooltip>
            )}
          </li>

          <li>
            <SubscriptionLevel
              classification={channel.classification}
              subscription={subscription}
            />
          </li>

          <li>
            <OutlineDropdownButton
              theme={channel.classification === "private" ? "dark" : "light"}
              onClick={(e) => {
                e.preventDefault();
                navigate("subscribers");
              }}
            >
              <small>
                {subscribersCount !== undefined && (
                  <span
                    className={cx(
                      "mr-2 font-medium",
                      channel.classification === "private"
                        ? "text-whiteA-11"
                        : "text-slate-11",
                    )}
                  >
                    {subscribersCount}
                  </span>
                )}
                Subscriber{subscribersCount === 1 ? "" : "s"}
              </small>
            </OutlineDropdownButton>
          </li>
        </MainLayout.HeaderMenu>
      </MainLayout.Header>

      <ListScrollbox
        isBodyElement
        offsetHeaderEl={headerRef}
        onlyOffsetHeaderElIfSticky
      >
        {!threads ? (
          <PendingRequestBar>
            <EmptyListMessage text="Nothing yet." />
          </PendingRequestBar>
        ) : threads.length === 0 ? (
          <EmptyListMessage text="Nothing yet." />
        ) : (
          <ContentList<IPostDoc>
            onEntryFocused={setFocusedPost}
            onEntryAction={onPostSelectNavigateToThread}
            className="mb-20"
            autoFocus
          >
            {threads.map((thread, index) => (
              <PostEntry
                key={thread.id}
                post={thread.lastPost}
                thread={thread}
                relativeOrder={index}
              />
            ))}
          </ContentList>
        )}
      </ListScrollbox>
    </>
  );
});

/* -----------------------------------------------------------------------------------------------*/

const ChannelGroupDetails: ComponentType<{
  channel: IChannelDocWithCurrentUserData;
}> = memo(({ channel }) => {
  return (
    <span
      title={channel.__local.knownChannelGroups.map((c) => c.name).join(", ")}
    >
      {channel.__local.knownChannelGroups.map((channelGroup, index) => {
        return (
          <span key={channelGroup.id} className="ml-2">
            {channelGroup.name}
            {index !== channel.channelGroupIds.length - 1 && ","}
          </span>
        );
      })}
    </span>
  );
});

/* -----------------------------------------------------------------------------------------------*/

/**
 * If the user attempts to navigate to the "shared channel"
 * of an organization, redirect them to the Shared messages
 * page
 */
function useRedirectIfOrganizationSharedChannel(
  channel: IChannelDocWithCurrentUserData | null | undefined,
) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!channel?.isOrganizationSharedChannel) return;
    navigate("/shared-messages", { replace: true });
  }, [channel, navigate]);
}

/* -----------------------------------------------------------------------------------------------*/

function useApplyScrollShadowToHeader(args: {
  channel: IChannelDocWithCurrentUserData | null | undefined;
  scrollboxRef: RefObject<HTMLElement>;
  headerRef: RefObject<HTMLElement>;
}) {
  const { channel, scrollboxRef, headerRef } = args;

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
    deps: [!!channel],
  });
}

/* -------------------------------------------------------------------------------------------------
 * SubscriptionLevel
 * -----------------------------------------------------------------------------------------------*/

const SubscriptionLevel: ComponentType<{
  classification: IChannelDoc["classification"];
  subscription?: IChannelSubscriptionDoc | null;
}> = memo((props) => {
  const { label, hintText } = getSubscriptionText(
    props.subscription && props.subscription.preference,
  );

  return (
    <Tooltip
      side="bottom"
      content={
        <>
          <p className="text-center">{hintText}</p>
          <p className="mt-1">
            <em>
              (press <kbd>S</kbd> to update subscription )
            </em>
          </p>
        </>
      }
    >
      <span>
        <OutlineDropdownButton
          theme={props.classification === "private" ? "dark" : "light"}
          onClick={(e) => {
            if (props.subscription === undefined) return;
            e.preventDefault();
            KBarState.toggle(true, {
              path: ["Update subscription"],
              mode: "hotkey",
            });
          }}
        >
          <IoMdEye className="mr-1 text-slate-11" /> <small>{label}</small>
        </OutlineDropdownButton>
      </span>
    </Tooltip>
  );
});

/* -----------------------------------------------------------------------------------------------*/

function getSubscriptionText(
  preference: ISubscriptionDoc["preference"] | null | undefined,
) {
  const normalizedPreference =
    preference === null ? DEFAULT_SUBSCRIPTION_PREFERENCE : preference;

  switch (normalizedPreference) {
    case "all": {
      return {
        label: "Subscribed All",
        hintText: `You will receive all notifications for this channel.`,
      };
    }
    case "all-new": {
      return {
        label: "Subscribed",
        hintText: `
          You will receive a notification for every new
          thread created in this channel.
        `,
      };
    }
    case "involved": {
      return {
        label: "Unsubscribed",
        hintText: `
          You will only receive notifications for 
          threads you are participating or @mentioned in.
        `,
      };
      break;
    }
    case undefined: {
      return {
        label: "",
        hintText: `loading`,
      };
    }
    default: {
      throw new UnreachableCaseError(normalizedPreference);
    }
  }
}

/* -------------------------------------------------------------------------------------------------
 * useRegisterChannelViewCommands
 * -----------------------------------------------------------------------------------------------*/

function useRegisterChannelViewCommands(args: {
  focusedPost: IPostDoc | null;
  channel: IChannelDocWithCurrentUserData | null | undefined;
  subscription: IChannelSubscriptionDoc | null | undefined;
}) {
  const { focusedPost, channel, subscription } = args;

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [ESCAPE_TO_INBOX_COMMAND];

      if (focusedPost) {
        commands.push(
          markDoneCommand({
            callback: () => {
              triageThread({
                threadId: focusedPost.threadId,
                done: true,
              });
            },
          }),
          markNotDoneCommand({
            callback: () => {
              triageThread({
                threadId: focusedPost.threadId,
                done: false,
              });
            },
          }),
          setThreadReminderCommand({
            callback: onlyCallFnOnceWhilePreviousCallIsPending(
              withPendingRequestBar(async () => {
                const notification = await firstValueFrom(
                  observeInboxNotification(focusedPost.threadId),
                );

                RemindMeDialogState.toggle(true, {
                  id: focusedPost.threadId,
                  triagedUntil: notification?.triagedUntil?.toDate() || null,
                  isStarred: notification?.isStarred ?? false,
                });
              }),
            ),
          }),
          removeThreadReminderCommand({
            callback: () => {
              triageThread({
                threadId: focusedPost.threadId,
                triagedUntil: null,
              });
            },
          }),
          starThreadCommand({
            callback: () => {
              triageThread({
                threadId: focusedPost.threadId,
                isStarred: true,
              });
            },
          }),
          unstarThreadCommand({
            callback: () => {
              triageThread({
                threadId: focusedPost.threadId,
                isStarred: false,
              });
            },
          }),
        );
      }

      commands.push(
        ...channelSubscriptionCommands({
          channel,
          channelSubscription: subscription,
        }),
      );

      if (channel) {
        commands.push(
          {
            label: "Update channel",
            altLabels: ["Edit channel", "Move channel"],
            callback: () => {
              if (!isAppOnline()) {
                toast("vanilla", {
                  subject: "Not supported in offline mode",
                  description: "Can't update channels when offline.",
                });

                return;
              }

              if (channel.isOrganizationSharedChannel) {
                toast("vanilla", {
                  subject: `Cannot edit`,
                  description: `The ${channel.name} is special and cannot be edited.`,
                });

                return;
              }

              EditChannelDialogState.toggle(true, {
                channel,
              });
            },
          },
          {
            label: "Delete channel (n/a)",
            callback: () => showNotImplementedToastMsg(),
          },
          pinChannelCommand({
            callback: () => {
              updateChannelSubscription({
                channelId: channel.id,
                isPinned: true,
              });
            },
          }),
          unpinChannelCommand({
            callback: () => {
              updateChannelSubscription({
                channelId: channel.id,
                isPinned: false,
              });
            },
          }),
        );

        // When the user composes while on a channel other than the shared channel,
        // the default compose command in SidebarLayout will be overridden to include
        // the channel as a recipient.
        if (!channel.isOrganizationSharedChannel) {
          commands.push(
            composeMessageCommand({
              callback: onlyCallFnOnceWhilePreviousCallIsPending(async () => {
                const draftId = uid();

                // We're intentionally not awaiting this response since it results in
                // this command taking too long in the UI
                createNewDraft({
                  type: "COMMS",
                  postId: draftId,
                  ...getNewThreadDataWithChannelAsDefaultRecipient(channel),
                });

                await wait(50);

                openComposeNewThreadDialog(draftId);
              }),
            }),
          );
        }
      }

      return commands;
    },
    deps: [channel, focusedPost, subscription],
  });
}

/* -----------------------------------------------------------------------------------------------*/

function getNewThreadDataWithChannelAsDefaultRecipient(
  currentChannel: IChannelDocWithCurrentUserData,
) {
  return {
    visibility:
      currentChannel.classification === "private"
        ? ("private" as const)
        : ("shared" as const),
    to: [
      {
        type: "channelId" as const,
        value: currentChannel.id,
      },
    ],
  };
}

/* -----------------------------------------------------------------------------------------------*/
