import { FieldValue, Timestamp } from "firebase/firestore";
import {
  buildSubscriptionD,
  buildNotificationD,
  buildMainSettingsD,
} from "@libs/firestore-models/decoders";
import {
  IMainSettingsDoc,
  INewPostNotificationDoc,
  ISubscriptionDoc,
  WithServerTimestamp,
} from "@libs/firestore-models";
import * as d from "ts-decoders/decoders";
import { assert } from "ts-decoders";

export const TimestampOrFieldValueD = d.anyOfD([
  d.instanceOfD(Timestamp),
  d.instanceOfD(
    FieldValue as typeof FieldValue & { new (...args: unknown[]): FieldValue },
  ),
]);

export const SubscriptionCreateD = buildSubscriptionD<
  WithServerTimestamp<ISubscriptionDoc>
>(
  {},
  {
    timestampD: TimestampOrFieldValueD,
  },
);

export const validateNewSubscription = assert(SubscriptionCreateD);

export const NewNotificationCreateD = buildNotificationD<
  WithServerTimestamp<INewPostNotificationDoc>
>(
  {},
  {
    timestampD: TimestampOrFieldValueD,
  },
);

export const validateNewNotification = assert(NewNotificationCreateD);

export const MainSettingsD = buildMainSettingsD<
  WithServerTimestamp<IMainSettingsDoc>
>(
  {},
  {
    timestampD: TimestampOrFieldValueD,
  },
);

export const validateMainSettings = assert(MainSettingsD);
