import {
  ComponentType,
  ReactElement,
  Suspense,
  useEffect,
  useMemo,
} from "react";
import { filter } from "rxjs";
import { LoadingText } from "~/components/LoadingText";
import { useSidebarLayoutContext } from "~/page-layouts/sidebar-layout";
import { NAVIGATION_EVENTS, ILocation } from "~/services/navigate.service";
import { useObservable } from "~/utils/useObservable";
import { IPageDialogData, PAGE_DIALOG_STATE$ } from "./page-dialog-state";

export const PageDialogRenderer: ComponentType<{ children: ReactElement }> = (
  props,
) => {
  const state = useObservable(() => PAGE_DIALOG_STATE$, {
    synchronous: true,
  });

  useEffect(() => {
    const sub = NAVIGATION_EVENTS.pipe(
      filter((e) => !isPageDialogURL(e.location)),
    ).subscribe(() => {
      PAGE_DIALOG_STATE$.next(null);
    });

    return () => sub.unsubscribe();
  }, []);

  const component = useMemo(
    () => state && getComponent(state.type, state.data),
    [state],
  );

  if (!component) return props.children;

  return <PageDialogComponent>{component}</PageDialogComponent>;
};

// We're running into "Failed to fetch dynamically imported modules" errors
// occationally after deployment. This happens because our current deployment
// strategy (which is defined by a Firebase github action) erases all files
// related to the previous deployment when uploading a new one. The proper
// fix here is probably to use a service worker. We ran into problems with our
// service worker script that we don't want to deal with right now though,
// so instead I'm going to stop lazy loading moduels.
//
// const ComposeMessageView = lazy(() =>
//   import("~/page-dialogs/compose-message/ComposeMessageView").then((m) => ({
//     default: m.ComposeMessageView,
//   })),
// );
import { ComposeMessageView } from "./compose-message";

function getComponent<T extends IPageDialogData>(
  type: T["type"],
  // A refactoring made this property unnecessary, but it seems
  // possible we'll need it again in the future.
  // -- John 2/6/23
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data?: T["data"],
) {
  switch (type) {
    case "ComposeMessage": {
      return <ComposeMessageView />;
    }
  }

  return null;
}

function isPageDialogURL(loc: ILocation) {
  return !!new URLSearchParams(loc.search).get("compose");
}

const PageDialogComponent: ComponentType<{}> = (props) => {
  const sidebarLayoutContext = useSidebarLayoutContext();

  useEffect(() => {
    sidebarLayoutContext.focusEvent$.next("Outlet");
    sidebarLayoutContext.sidebarForceMode$.next("over");
    return () => sidebarLayoutContext.sidebarForceMode$.next(null);
  }, [sidebarLayoutContext]);

  return <Suspense fallback={<LoadingText />}>{props.children}</Suspense>;
};
