import { logEvent } from "firebase/analytics";
import { FirebaseError } from "firebase/app";
import {
  DocumentData,
  DocumentReference,
  Query,
  QuerySnapshot,
  SnapshotListenOptions,
} from "firebase/firestore";
import {
  // eslint-disable-next-line no-restricted-imports
  docData as _docData,
  // eslint-disable-next-line no-restricted-imports
  collectionData as _collectionData,
  // eslint-disable-next-line no-restricted-imports
  fromRef as _fromRef,
} from "rxfire/firestore";
import {
  catchError,
  finalize,
  interval,
  map,
  Observable,
  ObservableInput,
  of,
  pipe,
  race,
  switchMap,
  take,
  tap,
} from "rxjs";
import { analytics } from "~/firebase";

export function docData<T = DocumentData>(
  ref: DocumentReference<T>,
): Observable<T | null> {
  const cleanup = periodicallyLogPerformance("document", {
    path: ref.path,
  });

  return retryOnceIfNotResolvedInTime(`document ${ref.path}`, () =>
    _docData(ref),
  ).pipe(
    catchFirebaseError(() => null),
    finalize(() => cleanup(null)),
    tap((document) => cleanup(document)),
    map((doc) => doc || null),
  );
}

export function collectionData<T = DocumentData>(
  query: Query<T>,
): Observable<T[]> {
  const cleanup = periodicallyLogPerformance(query.type, {
    type: query.type,
  });

  return retryOnceIfNotResolvedInTime(query.type, () =>
    _collectionData(query),
  ).pipe(
    catchFirebaseError(() => []),
    finalize(() => cleanup(null)),
    tap((document) => cleanup(document)),
  );
}

export function fromRef<T = DocumentData>(
  query: Query<T>,
  options?: SnapshotListenOptions,
): Observable<QuerySnapshot<T> | null> {
  const cleanup = periodicallyLogPerformance(query.type, {
    type: query.type,
  });

  return retryOnceIfNotResolvedInTime(query.type, () =>
    _fromRef(query, options),
  ).pipe(
    catchFirebaseError(() => null),
    finalize(() => cleanup(null)),
    tap((document) => cleanup(document)),
  );
}

/**
 * rxjs operator to catch a FirebaseError and return a fallbackValue
 * instead. Note that, surprisingly, Firestore subscriptions might
 * throw Firebase errors instead of Firestore errors for invalid
 * permissions.
 */
function catchFirebaseError<I, T>(fallbackValue: (err: FirebaseError) => T) {
  return pipe(
    catchError<I, ObservableInput<T>>((err) => {
      if (err instanceof FirebaseError) {
        console.debug(err);
        return of(fallbackValue(err));
      }

      throw err;
    }),
  );
}

function retryOnceIfNotResolvedInTime<T>(
  label: string,
  sourceFn: () => Observable<T>,
  count = 1,
): Observable<T> {
  const fail = Symbol("timeout");
  const timeoutMs = count ** 2 * 5_000;

  if (timeoutMs > 1000 * 60 * 20) {
    console.error("Firestore query timed out.", { label });
    throw new Error("Firestore query timed out.");
  }

  return race([
    sourceFn(),
    interval(timeoutMs).pipe(
      take(1),
      map(() => fail),
    ),
  ]).pipe(
    switchMap((value) => {
      if (value !== fail) return of(value as T);

      console.warn(
        `Retrying Firestore query that hasn't resolved in ${Math.round(
          timeoutMs / 1000,
        )}s.`,
        { label },
      );

      return retryOnceIfNotResolvedInTime(label, sourceFn, count + 1);
    }),
  );
}

function periodicallyLogPerformance<T extends object>(type: string, data: T) {
  const start = Date.now();
  let count = 0;

  let timeoutId = setInterval(() => {
    count++;

    if (count > 5) {
      clearInterval(timeoutId);
      timeoutId = undefined;

      console.error(`Firestore failed to load ${type} within 30 seconds.`, {
        elapsedSeconds: (Date.now() - start) / 1000,
        ...data,
      });

      logEvent(analytics, "failed_firestore_load", {
        ...data,
      });
    } else {
      const msg =
        count === 1
          ? "is taking a long time"
          : `is *still* taking a long time [${count}]`;

      console.warn(`Firestore ${msg} to load a ${type}.`, {
        elapsedSeconds: (Date.now() - start) / 1000,
        ...data,
      });
    }
  }, 5_000) as unknown as number | undefined;

  const cleanup = (result: unknown) => {
    clearTimeout(timeoutId);
    timeoutId = undefined;

    if (!result) return;

    if (count > 0) {
      logEvent(analytics, "slow_firestore_load", {
        elapsedSeconds: (Date.now() - start) / 1000,
        ...data,
        result,
        count,
      });
    }

    if (count > 2) {
      console.error(`Firestore took a really long time to load this ${type}.`, {
        elapsedSeconds: (Date.now() - start) / 1000,
        ...data,
        result,
      });
    }
  };

  return cleanup;
}
