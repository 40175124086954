import { ComponentType, Ref, useCallback } from "react";
import {
  DialogState,
  DialogTitle,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import {
  TAutocompleteSelectRef,
  useAutocompleteMenuPositioning,
} from "~/form-components/AutocompleteSelect";
import {
  createFormControl,
  createFormGroup,
  IFormControl,
  useControl,
} from "solid-forms-react";
import {
  handleSubmit,
  onSubmitFn,
  useControlState,
} from "~/form-components/utils";
import { toast } from "~/services/toast-service";
import {
  PLATFORM_MODIFIER_KEY,
  useRegisterCommands,
} from "~/services/command.service";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { SubmitDialogHint } from "../DialogLayout";
import { ChannelSelect, IChannelOption } from "~/form-components/ChannelSelect";
import {
  IChannelDocWithCurrentUserData,
  sendChannelInvite,
} from "~/services/channels.service";
import {
  ICommsThreadRecipientOption,
  IRecipientOption,
  IUserRecipientOption,
  ThreadRecipients,
} from "~/form-components/ThreadRecipients";
import { IChannelSubscriptionDoc } from "@libs/firestore-models";
import { CheckboxInput } from "~/form-components/CheckboxInput";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { observeOrganizationMembers } from "~/services/organization.service";
import { firstValueFrom } from "rxjs";
import { Tooltip } from "~/components/Tooltip";

export type IChannelInviteDialogData =
  | {
      channel?: IChannelDocWithCurrentUserData;
    }
  | undefined;

export type IChannelInviteDialogReturnData = { success: boolean } | void;

export const ChannelInviteDialogState = new DialogState<
  IChannelInviteDialogData,
  IChannelInviteDialogReturnData
>();

interface IFormValue {
  channel: IChannelOption | null;
  recipients: ICommsThreadRecipientOption[];
  preference: IChannelSubscriptionDoc["preference"] | "";
  notifyInvitee: boolean;
}

export const ChannelInviteDialog = withModalDialog({
  dialogState: ChannelInviteDialogState,
  Component: (props) => {
    const { currentUser } = useAuthGuardContext();

    const control = useControl(() => {
      const channel = props.data?.channel;

      const initChannelOption: IChannelOption | null = !channel
        ? null
        : {
            label: channel.id,
            value: channel.id,
            channelGroupNames: channel.__local.knownChannelGroups.map(
              (g) => g.name,
            ),
            classification: channel.classification,
          };

      return createFormGroup({
        channel: createFormControl<IChannelOption | null>(initChannelOption, {
          validators: (value: IChannelOption) =>
            value ? null : { required: true },
        }),
        recipients: createFormControl<ICommsThreadRecipientOption[]>([], {
          validators: (value: ICommsThreadRecipientOption[]) =>
            value.length > 0 ? null : { required: true },
          required: true,
        }),
        preference: createFormControl<IChannelSubscriptionDoc["preference"]>(
          "all-new",
          {
            validators: (value: IChannelSubscriptionDoc["preference"] | null) =>
              value ? null : { required: true },
            required: true,
          },
        ),
        notifyInvitee: createFormControl(true),
      });
    });

    const addAllOrgMembersAsRecipients = useCallback(async () => {
      if (!currentUser.organizationId) return;

      const recipients = new Map(
        control.rawValue.recipients.map((r) => [r.value, r]),
      );

      const members = await firstValueFrom(
        observeOrganizationMembers(currentUser.organizationId),
      );

      members.forEach((m) => {
        if (m.id === currentUser.id) return;

        const option: IUserRecipientOption = {
          type: "user",
          value: m.id,
          label: m.user.name,
          email: m.user.email,
        };

        recipients.set(m.id, option);
      });

      control.patchValue({
        recipients: Array.from(recipients.values()),
      });
    }, [control, currentUser.id, currentUser.organizationId]);

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              ChannelInviteDialogState.toggle(false);
            },
          },
          {
            label: "Submit form",
            hotkeys: ["$mod+Enter"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => handleSubmit(control, submit),
          },
        ];
      },
    });

    const [
      channelAutocompleteRef,
      channelAutocompletePortalEl,
      channelAutocompletePortalJSX,
    ] = useAutocompleteMenuPositioning<IChannelOption, false>();

    const [
      recipientsAutocompleteRef,
      recipientsAutocompletePortalEl,
      recipientsAutocompletePortalJSX,
    ] = useAutocompleteMenuPositioning<IRecipientOption, true>();

    return (
      <>
        <DialogTitle>
          <h2>
            Subscribe people to{" "}
            {props.data?.channel ? `#${props.data.channel.name}` : "channel"}
          </h2>
        </DialogTitle>

        <form
          onSubmit={onSubmitFn(control, submit)}
          className={DIALOG_CONTENT_WRAPPER_CSS}
        >
          {!props.data?.channel && (
            <Channel
              autocompleteRef={channelAutocompleteRef}
              control={control.controls.channel}
              autocompleteMenuEl={channelAutocompletePortalEl}
            />
          )}

          {/* <SubscriptionPreference control={control.controls.preference} /> */}

          <div className="px-4 py-2">
            <ThreadRecipients
              ref={recipientsAutocompleteRef}
              name="recipients"
              control={control.controls.recipients}
              threadType="COMMS"
              isThreadPrivate={null}
              autocompleteMenuPortalEl={recipientsAutocompletePortalEl}
              onlyRecipientsOfType="user"
            />
          </div>

          <div className="mx-4 py-4 border-t border-mauve-5">
            {currentUser.organizationId && (
              <button
                type="button"
                className={`mr-4 my-2 rounded bg-slate-5 px-2 border 
                  border-slate-9 text-sm hover:border-black hover:bg-slate-7`}
                onClick={addAllOrgMembersAsRecipients}
              >
                {/*
                  TODO:
                  Update this code in the future when we support more organizations
                */}
                Add Everyone in Levels
              </button>
            )}

            <NotifyPeople control={control.controls.notifyInvitee} />
          </div>

          <div className="flex p-4 border-t border-mauve-5">
            <div className="flex-1" />

            <Tooltip
              side="bottom"
              content={`${PLATFORM_MODIFIER_KEY.name} + Enter`}
            >
              <button
                type="button"
                className={`rounded bg-slate-5 border px-2
                  border-slate-9 text-sm hover:border-black hover:bg-slate-7`}
                onClick={() => handleSubmit(control, submit)}
              >
                Submit
              </button>
            </Tooltip>
          </div>
        </form>

        {channelAutocompletePortalJSX}
        {recipientsAutocompletePortalJSX}

        <SubmitDialogHint />
      </>
    );
  },
});

const submit = withPendingRequestBar(async (values: IFormValue) => {
  console.log("submitting...", values);

  ChannelInviteDialogState.toggle(false);

  toast("vanilla", {
    subject: "Subscribing users...",
    description: "This may take a minute or two.",
  });

  const results = await Promise.allSettled(
    values.recipients.map((r) =>
      sendChannelInvite({
        // form validation prevents `submit()` from being called if this is null
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        channelId: values.channel!.value,
        invitedUserId: r.value,
        // form validation prevents `submit()` from being called if this is falsy
        preference: values.preference as IChannelSubscriptionDoc["preference"],
        notifyInvitee: values.notifyInvitee,
      }),
    ),
  );

  const isAnyFailures = results.some((r) => r.status === "rejected");

  if (isAnyFailures) {
    console.log("submission failed", results);
    toast("vanilla", {
      subject: "Some users failed to be subscribed :(",
    });
    return;
  }

  console.log("submitted successfully!");

  if (values.notifyInvitee) {
    toast("vanilla", {
      subject: `Subscriptions updated and notifications sent.`,
    });
  } else {
    toast("vanilla", {
      subject: "Subscriptions updated.",
    });
  }
});

const Channel: ComponentType<{
  autocompleteRef: Ref<TAutocompleteSelectRef<IChannelOption, false>>;
  control: IFormControl<IChannelOption | null>;
  autocompleteMenuEl?: HTMLDivElement | null;
}> = (props) => {
  const value = useControlState(() => props.control.value, [props.control]);

  const isInvalid = useControlState(
    () => !props.control.isValid,
    [props.control],
  );

  const isTouched = useControlState(
    () => props.control.isTouched,
    [props.control],
  );

  return (
    <div className="flex px-4">
      <ChannelSelect
        autocompleteRef={props.autocompleteRef}
        label="For"
        value={value}
        multiple={false}
        autoFocus
        error={isInvalid ? "Required." : undefined}
        touched={isTouched}
        autocompleteMenuEl={props.autocompleteMenuEl}
        onBlur={() => props.control.markTouched(true)}
        onChange={(newValue) =>
          props.control.setValue(newValue as IChannelOption)
        }
      />
    </div>
  );
};

// const SubscriptionPreference: ComponentType<{
//   control: IFormControl<IChannelSubscriptionDoc["preference"] | "">;
// }> = (props) => {
//   return (
//     <div className="flex px-4">
//       <div className="flex flex-1 items-center py-2 border-b border-mauve-5">
//         <label
//           htmlFor="subscription-preference-input"
//           className="mr-2 text-slateDark-7"
//         >
//           Subscription preference
//         </label>

//         <SelectInput
//           id="subscription-preference-input"
//           control={props.control}
//           name="preference"
//           options={subscriptionPreferenceOptions}
//         />
//       </div>
//     </div>
//   );
// };
//
// // Here we're taking pains to ensure that a type error is thrown
// // if `IChannelSubscriptionDoc["preference"]` is updated in the future
// const subscriptionPreferenceOptions = Object.entries(
//   checkValueMatchesType<Record<IChannelSubscriptionDoc["preference"], string>>({
//     "all-new": "New threads, @mentions, and replies",
//     all: "All notifications",
//     involved: "@mentions and replies",
//   }),
// ).map(([k, v]) => ({ label: v, value: k }));

const NotifyPeople: ComponentType<{
  control: IFormControl<boolean>;
}> = (props) => {
  return (
    <div className="flex flex-1 items-center py-2">
      <label htmlFor="notify-people-input" className="mr-2 text-slateDark-7">
        Notify people?
      </label>

      <CheckboxInput
        id="notify-people-input"
        control={props.control}
        checkedValue={true}
        uncheckedValue={false}
      />
    </div>
  );
};
