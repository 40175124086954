import { ComponentType, PropsWithChildren, Ref, useMemo } from "react";
import { css, cx } from "@emotion/css";
import { red } from "@radix-ui/colors";
import {
  AutocompleteSelect,
  getFuzzyFilteringFn,
  IOption,
  TAutocompleteSelectRef,
} from "~/form-components/AutocompleteSelect";
import { MultiValue, OptionProps, SingleValue } from "react-select";
import { useChannelGroups } from "~/services/organization.service";
import { IOrganizationDoc } from "@libs/firestore-models";

export interface IChannelGroupOption extends IOption<string> {
  organizationName: IOrganizationDoc["name"];
}

/**
 * Component for selecting a channel group with autocomplete.
 */
export function ChannelGroupsSelect<M extends boolean>(
  props: PropsWithChildren<{
    autocompleteRef?: Ref<TAutocompleteSelectRef<IChannelGroupOption, M>>;
    value?: M extends true
      ? MultiValue<IChannelGroupOption>
      : SingleValue<IChannelGroupOption>;
    touched?: boolean;
    error?: string;
    onChange?: M extends true
      ? (newValue: MultiValue<IChannelGroupOption>) => void
      : (newValue: SingleValue<IChannelGroupOption>) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    autoFocus?: boolean;
    autocompleteMenuEl?: HTMLDivElement | null;
    multiple?: M;
    label?: string;
  }>,
) {
  const channelGroups = useChannelGroups();

  const options: IChannelGroupOption[] = useMemo(() => {
    return channelGroups.map((channelGroup) => {
      return {
        label: channelGroup.name,
        value: channelGroup.id,
        organizationName: channelGroup.organizationName,
      };
    });
  }, [channelGroups]);

  return (
    <div
      className={cx("flex flex-1 py-2 border-b border-mauve-5", {
        [showLabelOnFocusCSS]: props.multiple
          ? (props.value as MultiValue<IOption>)?.length === 0
          : !props.value,
      })}
    >
      {props.label && (
        <label
          className={cx(
            "my-1 mr-2",
            props.touched && props.error ? "text-red-9" : "text-slateDark-11",
          )}
        >
          {props.label}
        </label>
      )}

      <AutocompleteSelect
        ref={props.autocompleteRef}
        name="channelGroups"
        value={props.value}
        onBlur={props.onBlur}
        onChange={
          props.onChange as (
            newValue: MultiValue<IOption> | SingleValue<IOption>,
          ) => void
        }
        loadOptions={getFuzzyFilteringFn(options)}
        placeholder={
          props.touched && props.error
            ? "Channel group required..."
            : "Channel groups..."
        }
        autoFocus={props.autoFocus}
        multiple={props.multiple}
        classNames={
          (props.touched &&
            props.error &&
            `input-invalid ${channelGroupsInputCSS}`) ||
          ""
        }
        menuPortalTarget={props.autocompleteMenuEl}
        menuPlacement="bottom"
        components={components}
      />
    </div>
  );
}

const showLabelOnFocusCSS = css`
  &:not(:focus-within) label {
    display: none;
  }
`;

const channelGroupsInputCSS = css`
  &.input-invalid .react-select-placeholder {
    color: ${red.red9};
  }
`;

const Option: ComponentType<OptionProps<IChannelGroupOption, boolean>> = (
  props,
) => {
  const option = props.data as IChannelGroupOption;

  return (
    <div
      className={cx(
        "py-2 px-4 hover:cursor-pointer",
        !option.isDisabled && "hover:bg-blue-5",
        props.isFocused ? "bg-blue-5" : "bg-transparent",
      )}
      onClick={() => props.selectOption(option)}
    >
      {option.label}
      <span className="text-slateA-8 ml-4">{option.organizationName}</span>
    </div>
  );
};

const components = {
  Option,
};
