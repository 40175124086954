/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment SearchThreadFields on Threads {\n    firestoreId\n    visibility\n    subject\n\n    firstMessage {\n      firestoreId\n      sender {\n        firestoreId\n        name\n      }\n    }\n\n    lastMessage {\n      firestoreId\n      sentAt\n      scheduledToBeSentAt\n      bodyText\n    }\n\n    distinctSenders(args: { sort_order: \"DESC\" }, limit: 4) {\n      firestoreId\n      name\n    }\n\n    inboxNotifications {\n      userFirestoreId\n      threadFirestoreId\n      hasReminder\n      remindAt\n      doneLastModifiedBy\n    }\n  }\n": types.SearchThreadFieldsFragmentDoc,
    "\n  query FullTextSearchQuery(\n    $query: String!\n    $messagesWhere: MessagesBoolExp\n    $offset: Int!\n    $limit: Int!\n  ) {\n    messages: searchMessages(\n      args: { search: $query }\n      where: $messagesWhere\n      orderBy: [{ sentAt: DESC }, { scheduledToBeSentAt: DESC }]\n      offset: $offset\n      limit: $limit\n    ) {\n      firestoreId\n      threadFirestoreId\n      type\n      typeSpecificJson\n    }\n  }\n": types.FullTextSearchQueryDocument,
    "\n  query NonFullTextSearchQuery(\n    $messagesWhere: MessagesBoolExp!\n    $offset: Int!\n    $limit: Int!\n  ) {\n    messages(\n      where: $messagesWhere\n      orderBy: [{ sentAt: DESC }, { scheduledToBeSentAt: DESC }]\n      offset: $offset\n      limit: $limit\n    ) {\n      firestoreId\n      threadFirestoreId\n      type\n      typeSpecificJson\n    }\n  }\n": types.NonFullTextSearchQueryDocument,
    "\n  query InboxSectionQuery($where: NotificationNewPostsBoolExp) {\n    notificationNewPosts(\n      where: $where\n      orderBy: [{ sentAt: ASC }, { scheduledToBeSentAt: ASC }]\n    ) {\n      userFirestoreId\n      threadFirestoreId\n      messageFirestoreId\n      senderUserFirestoreId\n      priority\n      sentAt\n      scheduledToBeSentAt\n      hasReminder\n      remindAt\n      done\n      doneAt\n      doneLastModifiedBy\n      oldestSentAtValueNotMarkedDone\n      isStarred\n      starredAt\n      isFirstMessageInThread\n      threadVisibility\n      messageType\n      createdAt\n      updatedAt\n      sender {\n        firestoreId\n        name\n        photoUrl\n      }\n      message {\n        firestoreId\n        subject\n        bodyText\n        typeSpecificJson\n      }\n    }\n  }\n": types.InboxSectionQueryDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SearchThreadFields on Threads {\n    firestoreId\n    visibility\n    subject\n\n    firstMessage {\n      firestoreId\n      sender {\n        firestoreId\n        name\n      }\n    }\n\n    lastMessage {\n      firestoreId\n      sentAt\n      scheduledToBeSentAt\n      bodyText\n    }\n\n    distinctSenders(args: { sort_order: \"DESC\" }, limit: 4) {\n      firestoreId\n      name\n    }\n\n    inboxNotifications {\n      userFirestoreId\n      threadFirestoreId\n      hasReminder\n      remindAt\n      doneLastModifiedBy\n    }\n  }\n"): (typeof documents)["\n  fragment SearchThreadFields on Threads {\n    firestoreId\n    visibility\n    subject\n\n    firstMessage {\n      firestoreId\n      sender {\n        firestoreId\n        name\n      }\n    }\n\n    lastMessage {\n      firestoreId\n      sentAt\n      scheduledToBeSentAt\n      bodyText\n    }\n\n    distinctSenders(args: { sort_order: \"DESC\" }, limit: 4) {\n      firestoreId\n      name\n    }\n\n    inboxNotifications {\n      userFirestoreId\n      threadFirestoreId\n      hasReminder\n      remindAt\n      doneLastModifiedBy\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FullTextSearchQuery(\n    $query: String!\n    $messagesWhere: MessagesBoolExp\n    $offset: Int!\n    $limit: Int!\n  ) {\n    messages: searchMessages(\n      args: { search: $query }\n      where: $messagesWhere\n      orderBy: [{ sentAt: DESC }, { scheduledToBeSentAt: DESC }]\n      offset: $offset\n      limit: $limit\n    ) {\n      firestoreId\n      threadFirestoreId\n      type\n      typeSpecificJson\n    }\n  }\n"): (typeof documents)["\n  query FullTextSearchQuery(\n    $query: String!\n    $messagesWhere: MessagesBoolExp\n    $offset: Int!\n    $limit: Int!\n  ) {\n    messages: searchMessages(\n      args: { search: $query }\n      where: $messagesWhere\n      orderBy: [{ sentAt: DESC }, { scheduledToBeSentAt: DESC }]\n      offset: $offset\n      limit: $limit\n    ) {\n      firestoreId\n      threadFirestoreId\n      type\n      typeSpecificJson\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query NonFullTextSearchQuery(\n    $messagesWhere: MessagesBoolExp!\n    $offset: Int!\n    $limit: Int!\n  ) {\n    messages(\n      where: $messagesWhere\n      orderBy: [{ sentAt: DESC }, { scheduledToBeSentAt: DESC }]\n      offset: $offset\n      limit: $limit\n    ) {\n      firestoreId\n      threadFirestoreId\n      type\n      typeSpecificJson\n    }\n  }\n"): (typeof documents)["\n  query NonFullTextSearchQuery(\n    $messagesWhere: MessagesBoolExp!\n    $offset: Int!\n    $limit: Int!\n  ) {\n    messages(\n      where: $messagesWhere\n      orderBy: [{ sentAt: DESC }, { scheduledToBeSentAt: DESC }]\n      offset: $offset\n      limit: $limit\n    ) {\n      firestoreId\n      threadFirestoreId\n      type\n      typeSpecificJson\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query InboxSectionQuery($where: NotificationNewPostsBoolExp) {\n    notificationNewPosts(\n      where: $where\n      orderBy: [{ sentAt: ASC }, { scheduledToBeSentAt: ASC }]\n    ) {\n      userFirestoreId\n      threadFirestoreId\n      messageFirestoreId\n      senderUserFirestoreId\n      priority\n      sentAt\n      scheduledToBeSentAt\n      hasReminder\n      remindAt\n      done\n      doneAt\n      doneLastModifiedBy\n      oldestSentAtValueNotMarkedDone\n      isStarred\n      starredAt\n      isFirstMessageInThread\n      threadVisibility\n      messageType\n      createdAt\n      updatedAt\n      sender {\n        firestoreId\n        name\n        photoUrl\n      }\n      message {\n        firestoreId\n        subject\n        bodyText\n        typeSpecificJson\n      }\n    }\n  }\n"): (typeof documents)["\n  query InboxSectionQuery($where: NotificationNewPostsBoolExp) {\n    notificationNewPosts(\n      where: $where\n      orderBy: [{ sentAt: ASC }, { scheduledToBeSentAt: ASC }]\n    ) {\n      userFirestoreId\n      threadFirestoreId\n      messageFirestoreId\n      senderUserFirestoreId\n      priority\n      sentAt\n      scheduledToBeSentAt\n      hasReminder\n      remindAt\n      done\n      doneAt\n      doneLastModifiedBy\n      oldestSentAtValueNotMarkedDone\n      isStarred\n      starredAt\n      isFirstMessageInThread\n      threadVisibility\n      messageType\n      createdAt\n      updatedAt\n      sender {\n        firestoreId\n        name\n        photoUrl\n      }\n      message {\n        firestoreId\n        subject\n        bodyText\n        typeSpecificJson\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;