import {
  INotificationWithLocalAndDraftData,
  TInboxSection,
  triageThread,
  useInboxSectionNotificationsAndDrafts,
} from "~/services/inbox.service";
import { Helmet } from "react-helmet-async";
import { deleteDraft } from "~/services/draft.service";
import { ICommandArgs, useRegisterCommands } from "~/services/command.service";
import { toast } from "~/services/toast-service";
import { RemindMeDialogState } from "~/dialogs/remind-me";
import {
  deleteDraftCommand,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import { ComponentType, forwardRef, Fragment, useRef } from "react";
import { IListRef, ListScrollbox } from "~/components/list";
import {
  ContentList,
  DraftEntry,
  EmptyListMessage,
  NotificationEntry,
  useKBarAwareFocusedEntry$,
  useRegisterNotificationEntryCommands,
} from "~/components/content-list";
import { IInboxEntry, onInboxEntrySelect, useInboxZeroConfetti } from "./utils";
import { useTopScrollShadow } from "~/utils/useScrollShadow";
import { useCurrentUserMainSettings } from "~/services/settings.service";
import {
  useInboxSection,
  useInboxSections,
  useAreThereAnyInboxSectionNotifications,
} from "~/services/inbox.service";
import { MdSettings } from "react-icons/md";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "~/components/Tooltip";
import { Link, useParams } from "react-router-dom";
import { cx, css } from "@emotion/css";
import { NextScheduledDeliveryHeader } from "./NextScheduledDeliveryHeader";
import { getClassForScheduledDeliveryTourStep } from "~/services/lesson-service/lessons/scheduled-delivery-walkthrough";
import * as MainLayout from "~/page-layouts/main-layout";
import { NotificationCountBadge } from "~/components/NotificationCountBadge";
import { buildThreadViewInboxPrevNextState } from "./thread-prev-next-service-utils";
import { withNewCommandContext } from "~/services/command.service";
import { IInboxSubsectionDoc, IMainSettingsDoc } from "@libs/firestore-models";
import { BlockingInboxProgressBar } from "./BlockingInboxProgressBar";
import Confetti from "react-confetti";
import { EmptyList } from "./EmptyList";
import { navigateService } from "~/services/navigate.service";
import { getAndAssertCurrentUser } from "~/services/user.service";
import { deleteDoc } from "firebase/firestore";
import { docRef } from "~/firestore.service";
import { EditInboxSectionsDialogState } from "~/dialogs/edit-inbox-sections/EditInboxSectionsDialog";

/* -------------------------------------------------------------------------------------------------
 * InboxView
 * -----------------------------------------------------------------------------------------------*/

export const InboxView: ComponentType<{}> = withNewCommandContext(() => {
  const settingsDoc = useCurrentUserMainSettings();
  const inboxLayout = settingsDoc?.inboxLayout;
  const params = useParams<{ inboxSectionId: string }>();
  const inboxSectionId = params.inboxSectionId || "DEFAULT";
  const inboxSectionDoc = useInboxSection(inboxSectionId);

  const { setFocusedEntry, useFocusedEntry } =
    useKBarAwareFocusedEntry$<IInboxEntry>();

  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  const areThereAnyInboxNotifications =
    useAreThereAnyInboxSectionNotifications(inboxSectionId);

  const isLoading =
    areThereAnyInboxNotifications === undefined ||
    inboxSectionDoc === undefined;

  const noInboxNotifications = !areThereAnyInboxNotifications;

  const { showConfetti, windowWidth, windowHeight } = useInboxZeroConfetti(
    isLoading ? null : noInboxNotifications,
  );

  return (
    <>
      <RegisterInboxViewCommands
        inboxSectionDoc={inboxSectionDoc}
        useFocusedNotification={useFocusedEntry}
      />

      <Helmet>
        <title>Inbox | Comms</title>
      </Helmet>

      <MainLayout.Header
        ref={headerRef}
        className={cx("sm-max-w:pr-0 flex-col")}
      >
        <div className="flex items-center">
          <InboxHeader />

          <div className="flex-1" />

          {settingsDoc?.enableScheduledDelivery && (
            <NextScheduledDeliveryHeader />
          )}

          <Tooltip side="bottom" content="Settings">
            <span className="sm-max-w:hidden">
              <IconButton
                aria-label="settings"
                href="/settings"
                LinkComponent={CustomLink}
                className={getClassForScheduledDeliveryTourStep(40)}
              >
                <MdSettings />
              </IconButton>
            </span>
          </Tooltip>
        </div>

        {inboxLayout === "blocking-inbox" &&
          inboxSectionDoc &&
          inboxSectionDoc.subsectionDocs.length > 1 && (
            <div className="mt-2">
              <BlockingInboxProgressBar inboxSection={inboxSectionDoc} />
            </div>
          )}
      </MainLayout.Header>

      <ListScrollbox
        isBodyElement
        offsetHeaderEl={headerRef}
        onlyOffsetHeaderElIfSticky
      >
        {isLoading ? (
          <EmptyListMessage text="Loading..." />
        ) : inboxSectionDoc === null ? (
          <EmptyListMessage text="Inbox section not found." />
        ) : (
          <InboxEntries
            inboxLayout={inboxLayout}
            inboxSectionDoc={inboxSectionDoc}
            setFocusedNotification={setFocusedEntry}
          />
        )}
      </ListScrollbox>

      {!isLoading && !!inboxSectionDoc && noInboxNotifications && (
        <>
          {showConfetti && !settingsDoc?.enableFocusMode && (
            <div className="pointer-events-none w-screen h-screen absolute top-0 left-0 z-[2000]">
              <Confetti
                width={windowWidth}
                height={windowHeight}
                recycle={false}
                tweenDuration={5000}
                gravity={0.1}
              />
            </div>
          )}

          <EmptyList settings={settingsDoc} entriesAreLoading={isLoading} />
        </>
      )}
    </>
  );
});

/* -----------------------------------------------------------------------------------------------*/

function getDraftIdFromInboxNotificationOrDraft(doc: IInboxEntry) {
  if (doc.__docType === "IUnsafeDraftDoc") {
    return doc.id;
  } else if (doc.__local.draftId) {
    return doc.__local.draftId;
  }
}

/* -----------------------------------------------------------------------------------------------*/

const CustomLink = forwardRef<HTMLAnchorElement, { href: string }>(
  ({ href, ...props }, ref) => {
    return <Link {...props} ref={ref} to={href} />;
  },
);

/* -----------------------------------------------------------------------------------------------*/

const InboxEntries: ComponentType<{
  inboxLayout: IMainSettingsDoc["inboxLayout"];
  inboxSectionDoc: TInboxSection;
  setFocusedNotification: (entry: IInboxEntry | null) => void;
}> = withNewCommandContext((props) => {
  const listRef = useRef<IListRef<IInboxEntry>>(null);

  useRegisterNotificationEntryCommands({
    listRef,
    filterFn: (entry): entry is INotificationWithLocalAndDraftData =>
      entry.__docType === "INotificationDoc",
  });

  const showSectionHeaders =
    props.inboxLayout === "consolidated-inbox" &&
    props.inboxSectionDoc.subsectionDocs.length > 1;

  const results = useInboxSectionNotificationsAndDrafts(
    props.inboxSectionDoc.id,
  );

  const [drafts, groupedNotifications] = results || [];

  return (
    <>
      <ContentList<IInboxEntry>
        ref={listRef}
        onEntryFocused={props.setFocusedNotification}
        onEntryAction={(event) =>
          onInboxEntrySelect(
            { ...event },
            {
              state: buildThreadViewInboxPrevNextState(
                props.inboxSectionDoc.id,
              ),
            },
          )
        }
        className="mb-20"
        autoFocus
      >
        {drafts?.map((draft, index) => (
          <DraftEntry key={draft.id} draft={draft} relativeOrder={index} />
        ))}

        {groupedNotifications?.map((group) => {
          return (
            <InboxSubsection
              key={group.subsectionDoc.id}
              sectionId={props.inboxSectionDoc.id}
              subsection={group.subsectionDoc}
              notifications={group.notificationDocs}
              showHeader={showSectionHeaders}
            />
          );
        })}
      </ContentList>
    </>
  );
});

const InboxSubsection: ComponentType<{
  sectionId: string;
  subsection: IInboxSubsectionDoc;
  notifications: INotificationWithLocalAndDraftData[];
  showHeader?: boolean;
}> = (props) => {
  const notifications = props.notifications;
  const noNotifications = !notifications || notifications.length === 0;

  if (noNotifications) return null;

  return (
    <>
      {props.showHeader && (
        <SubsectionHeader
          sectionId={props.sectionId}
          subsection={props.subsection}
          notificationCount={notifications?.length || 0}
        />
      )}

      {notifications?.map((notification, index) => (
        <NotificationEntry
          key={notification.id}
          notification={notification}
          hasDraft={notification.__local.hasDraft}
          relativeOrder={index}
        />
      ))}
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const SubsectionHeader: ComponentType<{
  sectionId: string;
  subsection: IInboxSubsectionDoc;
  notificationCount: number;
}> = (props) => {
  return (
    <div
      className={`
        mx-4 sm-w:mx-8 md-w:mx-12
        mt-8 mb-2 border-l-2 border-white text-black 
        font-medium flex items-center
      `}
    >
      <h2 className="inline-flex shrink-0 items-center text-2xl">
        {props.notificationCount > 0 && (
          <NotificationCountBadge
            count={props.notificationCount}
            className="bg-slate-4 border-blackA-7 text-black"
          />
        )}

        {props.subsection.name}
      </h2>

      {props.subsection.description && (
        <>
          <span className="mx-2 text-slate-9">-</span>

          <p className="italic text-slate-9 font-normal">
            {props.subsection.description}
          </p>
        </>
      )}

      {props.sectionId !== "DEFAULT" && (
        <>
          <div className="flex-1" />

          <Link to={`/inbox/${props.sectionId}/edit`} className="underline">
            Edit
          </Link>
        </>
      )}
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const InboxHeader: ComponentType<{}> = () => {
  const inboxSections = useInboxSections();
  const params = useParams();

  return (
    <div className="flex">
      <Link
        to="/inbox"
        className={cx("text-3xl", params.inboxSectionId && "opacity-40")}
      >
        Inbox
      </Link>

      {inboxSections?.map((section) => {
        return (
          <Fragment key={section.id}>
            <span className="text-3xl mx-4 text-slate-7">&bull;</span>

            <Link
              to={`/inbox/${section.id}`}
              className={cx(
                "text-3xl",
                params.inboxSectionId !== section.id && "opacity-40",
              )}
            >
              {section.name}
            </Link>
          </Fragment>
        );
      })}

      <Tooltip side="bottom" content="Edit inbox sections">
        <span
          className={cx(
            "inbox-section-button",
            "mx-4 text-3xl flex items-center cursor-pointer",
            addInboxSectionButtonCss,
          )}
          onClick={() => {
            EditInboxSectionsDialogState.toggle(true);
          }}
        >
          edit
        </span>
      </Tooltip>
    </div>
  );
};

const addInboxSectionButtonCss = css`
  opacity: 0%;

  header:hover & {
    opacity: 20%;
  }

  &.inbox-section-button:hover {
    opacity: 100%;
  }
`;

/* -------------------------------------------------------------------------------------------------
 * useRegisterInboxViewCommands
 * -----------------------------------------------------------------------------------------------*/

const RegisterInboxViewCommands: ComponentType<{
  inboxSectionDoc: TInboxSection | null | undefined;
  useFocusedNotification: () => IInboxEntry | null;
}> = ({ inboxSectionDoc, useFocusedNotification }) => {
  const focusedNotification = useFocusedNotification();

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        {
          label: "Add inbox section",
          callback() {
            navigateService("/inbox/new");
          },
        },
      ];

      if (inboxSectionDoc && inboxSectionDoc.id !== "DEFAULT") {
        commands.push(
          {
            label: "Edit inbox section",
            callback() {
              navigateService(`/inbox/${inboxSectionDoc.id}/edit`);
            },
          },
          {
            label: "Delete inbox section",
            callback() {
              const confirmed = confirm(
                "Are you sure you want to delete this inbox section?",
              );

              if (!confirmed || !inboxSectionDoc) {
                return;
              }

              navigateService("/inbox");

              const currentUser = getAndAssertCurrentUser();

              deleteDoc(
                docRef(
                  "users",
                  currentUser.id,
                  "inboxSections",
                  inboxSectionDoc.id,
                ),
              );
            },
          },
        );
      }

      if (!focusedNotification) {
        return [
          ...commands,
          markDoneCommand({
            callback: () => {
              toast("vanilla", {
                subject: "Oops, no message is focused.",
                description: `
                You first need to focus a message by hovering your mouse  
                over it or by using the arrow keys on your keyboard.
              `,
              });
            },
          }),
        ];
      }

      if (focusedNotification.__docType === "IUnsafeDraftDoc") {
        return [
          ...commands,
          deleteDraftCommand({
            callback: () => {
              deleteDraft({ postId: focusedNotification.id });
            },
          }),
        ];
      }

      commands.push(
        markDoneCommand({
          callback: () => {
            triageThread({
              threadId: focusedNotification.id,
              done: true,
            });
          },
        }),
        markNotDoneCommand({
          showInKBar: false,
          callback: () => {
            toast("vanilla", {
              subject: "Post already marked not done.",
              description: `Hint: use "E" to mark as done.`,
            });
          },
        }),
        setThreadReminderCommand({
          callback: () => {
            const triagedUntil =
              (focusedNotification.__docType === "INotificationDoc" &&
                focusedNotification.triagedUntil?.toDate()) ||
              null;

            const isStarred =
              (focusedNotification.__docType === "INotificationDoc" &&
                focusedNotification.isStarred) ??
              false;

            RemindMeDialogState.toggle(true, {
              id: focusedNotification.id,
              triagedUntil,
              isStarred,
            });
          },
        }),
      );

      if (focusedNotification.__docType === "INotificationDoc") {
        if (focusedNotification.triaged) {
          commands.push(
            removeThreadReminderCommand({
              callback: () => {
                triageThread({
                  threadId: focusedNotification.id,
                  triagedUntil: null,
                });
              },
            }),
          );
        }

        if (focusedNotification.isStarred) {
          commands.push(
            unstarThreadCommand({
              callback: () => {
                triageThread({
                  threadId: focusedNotification.id,
                  isStarred: false,
                });
              },
            }),
          );
        } else {
          commands.push(
            starThreadCommand({
              callback: () => {
                triageThread({
                  threadId: focusedNotification.id,
                  isStarred: true,
                });
              },
            }),
          );
        }
      }

      const draftId =
        getDraftIdFromInboxNotificationOrDraft(focusedNotification);

      if (draftId) {
        commands.push(
          deleteDraftCommand({
            callback: () => {
              deleteDraft({ postId: draftId });
            },
          }),
        );
      }

      return commands;
    },
    deps: [focusedNotification, inboxSectionDoc],
  });

  return null;
};

/* -----------------------------------------------------------------------------------------------*/
