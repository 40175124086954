import { UnreachableCaseError } from "@libs/utils/errors";
import { useEffect, useState } from "react";
import { usePrevious } from "react-use";
import useWindowSize from "react-use/lib/useWindowSize";
import { Merge } from "type-fest";
import { onNotificationSelectNavigateToPost } from "~/components/content-list";
import { IListOnEntryActionEvent } from "~/components/list";
import { IDraftWithThreadData } from "~/services/draft.service";
import {
  INotificationDocWithLocalData,
  INotificationWithLocalAndDraftData,
} from "~/services/inbox.service";
import { INavigateServiceOptions } from "~/services/navigate.service";
import { onDraftsEntrySelect } from "../drafts/utils";

export type INotificationDocWithLocalDataAndDraftData =
  INotificationDocWithLocalData & {
    __local: Merge<
      INotificationDocWithLocalData["__local"],
      {
        hasDraft?: boolean;
        draftId?: string;
      }
    >;
  };

export type IInboxEntry =
  | IDraftWithThreadData
  | INotificationWithLocalAndDraftData;

export async function onInboxEntrySelect(
  event: IListOnEntryActionEvent<IInboxEntry>,
  options?: INavigateServiceOptions,
) {
  switch (event.entry.__docType) {
    case "INotificationDoc": {
      onNotificationSelectNavigateToPost(
        event as IListOnEntryActionEvent<INotificationWithLocalAndDraftData>,
        options,
      );
      return;
    }
    case "IUnsafeDraftDoc": {
      onDraftsEntrySelect(
        event as IListOnEntryActionEvent<IDraftWithThreadData>,
        options,
      );
      return;
    }
    default: {
      throw new UnreachableCaseError(event.entry);
    }
  }
}

export function useInboxZeroConfetti(noNotifications?: boolean | null) {
  const prevNoNotifications = usePrevious(noNotifications);

  const { width, height } = useWindowSize();

  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (
      typeof noNotifications !== "boolean" ||
      typeof prevNoNotifications !== "boolean"
    ) {
      return;
    } else if (!noNotifications || prevNoNotifications) {
      return;
    }

    setShowConfetti(true);
    setTimeout(() => setShowConfetti(false), 4000);
  }, [noNotifications, prevNoNotifications]);

  return { showConfetti, windowWidth: width, windowHeight: height };
}
