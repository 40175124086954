import { UnreachableCaseError } from "@libs/utils/errors";
import { merge } from "lodash-es";
import {
  FilterValue,
  LogicalOperator,
  ParsedToken,
  TextToken,
} from "@libs/utils/searchQueryParser";
import { getAndAssertCurrentUser } from "~/services/user.service";
import { Types } from "~/gql";
import { SetRequired, SetNonNullable } from "type-fest";

/* -------------------------------------------------------------------------------------------------
 * buildGraphQLQueryVariables
 * -----------------------------------------------------------------------------------------------*/

// Set required makes the properly non optional (i.e. removes the "?") but
// it doesn't remove the explicit ` | undefined` type. So we use `SetNonNullable`
// to remove the ` | undefined | null` type. I.e. both of these are needed.
type ThreadsWhere = SetRequired<
  SetNonNullable<Types.ThreadsBoolExp, "_and">,
  "_and"
>;
// See ThreadsWhere commend
type MessagesWhere = SetRequired<
  SetNonNullable<Types.MessagesBoolExp, "_and">,
  "_and"
>;
// See ThreadsWhere commend
type NotificationNewPostsWhere = SetRequired<
  SetNonNullable<Types.NotificationNewPostsBoolExp, "_and">,
  "_and"
>;

interface IGraphQLQueryVariables_Where {
  /**
   * If the GraphQL query is returning threads, use these
   * "where" clause filter variables.
   */
  threadsWhere: ThreadsWhere;
  /**
   * If the GraphQL query is returning messages, use these
   * "where" clause filter variables.
   */
  messagesWhere: MessagesWhere;
  /**
   * If the GraphQL query is returning new post notifications
   * which are not done (i.e. they are in the inbox), use these "where"
   * clause filter variables. These variables are specifically
   * taylored to filter inbox notifications. They also always filter on
   * `done: false`. See the after/before filter implementations for an
   * example of why this is specifically taylored for `done: false`.
   */
  inboxNotificationsWhere: NotificationNewPostsWhere;
}

export interface IGraphQLQueryVariables extends IGraphQLQueryVariables_Where {
  query?: string;
  offset: number;
  limit?: number;
}

export function buildGraphQLQueryVariables(args: {
  searchTokens: ParsedToken[];
  offset: number;
  allowTopLevelFullTextQuery?: boolean;
  limit?: number;
}) {
  const newTokens = [...args.searchTokens] as [ParsedToken, ...ParsedToken[]];

  const variables: IGraphQLQueryVariables = {
    offset: args.offset,
    threadsWhere: { _and: [] },
    messagesWhere: { _and: [] },
    inboxNotificationsWhere: {
      done: { _eq: false },
      _and: [],
    },
  };

  if (args.limit) {
    variables.limit = args.limit;
  }

  if (newTokens.length === 0) {
    return variables;
  }

  // If the search query contains a plain text search phrase, the first
  // ParsedToken in the response will be of type "text". See the
  // searchQueryParser.ts module for more information.
  if (newTokens[0].type === "text" && args.allowTopLevelFullTextQuery) {
    const token = newTokens.shift() as TextToken;
    variables.query = token.value;
  }

  for (const token of newTokens) {
    mergeGraphQLQueryInput(token, variables);
  }

  return variables;
}

/* -----------------------------------------------------------------------------------------------*/

function mergeGraphQLQueryInput(
  token: ParsedToken,
  variables: IGraphQLQueryVariables_Where,
): void {
  switch (token.type) {
    case "text": {
      return textFilter(token, variables);
    }
    case "from:": {
      return token.value.forEach((value) => fromFilter(value, variables));
    }
    case "to:": {
      return token.value.forEach((value) => toFilter(value, variables));
    }
    case "is:": {
      return token.value.forEach((value) => isFilter(value, variables));
    }
    case "after:": {
      return token.value.forEach((value) => afterFilter(value, variables));
    }
    case "before:": {
      return token.value.forEach((value) => beforeFilter(value, variables));
    }
    case "viewer:": {
      return token.value.forEach((value) => viewerFilter(value, variables));
    }
    case "participating:": {
      return token.value.forEach((value) =>
        participatingFilter(value, variables),
      );
    }
    case "channel:": {
      return token.value.forEach((value) => channelFilter(value, variables));
    }
    case "tag:": {
      return token.value.forEach((value) => tagFilter(value, variables));
    }
    case "subject:": {
      return token.value.forEach((value) => subjectFilter(value, variables));
    }
    case "body:": {
      return token.value.forEach((value) => bodyFilter(value, variables));
    }
    case "has:": {
      return token.value.forEach((value) => hasFilter(value, variables));
    }
    case "remind-after:": {
      return token.value.forEach((value) =>
        remindAfterFilter(value, variables),
      );
    }
    case "remind-before:": {
      return token.value.forEach((value) =>
        remindBeforeFilter(value, variables),
      );
    }
    case "mentions:": {
      return token.value.forEach((value) => mentionsFilter(value, variables));
    }
    case "priority:": {
      return token.value.forEach((value) => priorityFilter(value, variables));
    }
    case "and()": {
      return andOperator(token as LogicalOperator<"and()">, variables);
    }
    case "or()": {
      return orOperator(token as LogicalOperator<"or()">, variables);
    }
    case "not()": {
      return notOperator(token as LogicalOperator<"not()">, variables);
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

function textFilter(token: TextToken, variables: IGraphQLQueryVariables_Where) {
  ensureThreadsAndNotificationsHaveMessagesKey_and(variables);

  const text = `%${escapePostgresPatternMatching(token.value)}%`;

  const graphql = {
    _or: [{ bodyText: { _ilike: text } }, { subject: { _ilike: text } }],
  };

  variables.threadsWhere.messages._and.push(graphql);
  variables.messagesWhere._and.push(graphql);
  variables.inboxNotificationsWhere.threadMessages._and.push(graphql);
}

/* -----------------------------------------------------------------------------------------------*/

function fromFilter(
  token: FilterValue<"from">,
  variables: IGraphQLQueryVariables_Where,
) {
  if (token.type === "text") {
    ensureThreadsAndNotificationsHaveMessagesKey_and(variables);

    if (token.value === "me") {
      const graphql = {
        senderUserFirestoreId: { _eq: getAndAssertCurrentUser().id },
      };

      variables.threadsWhere.messages._and.push(graphql);
      variables.messagesWhere._and.push(graphql);
      variables.inboxNotificationsWhere.threadMessages._and.push(graphql);
    } else {
      const graphql = {
        sender: {
          name: { _ilike: normalizeFilterString(token.value) },
        },
      };

      variables.threadsWhere.messages._and.push(graphql);
      variables.messagesWhere._and.push(graphql);
      variables.inboxNotificationsWhere.threadMessages._and.push(graphql);
    }
  } else if (token.type === "DocId") {
    const [subject, , subjectId] = (token.value as string).split("::") as [
      string,
      string,
      string,
    ];

    if (subject !== "user") {
      console.warn(`Attempted to filter on "from:" channel, ignoring.`);
      return;
    }

    ensureThreadsAndNotificationsHaveMessagesKey_and(variables);

    const graphql = {
      senderUserFirestoreId: { _eq: subjectId },
    };

    variables.threadsWhere.messages._and.push(graphql);
    variables.messagesWhere._and.push(graphql);
    variables.inboxNotificationsWhere.threadMessages._and.push(graphql);
  } else {
    throw new UnreachableCaseError(token);
  }
}

/* -----------------------------------------------------------------------------------------------*/

function toFilter(
  token: FilterValue<"to">,
  variables: IGraphQLQueryVariables_Where,
) {
  if (token.type === "text") {
    ensureThreadsAndNotificationsHaveMessagesKey_and(variables);

    if (token.value === "me") {
      const graphql = {
        userRecipients: {
          userFirestoreId: { _eq: getAndAssertCurrentUser().id },
        },
      };

      variables.threadsWhere.messages._and.push(graphql);
      variables.messagesWhere._and.push(graphql);
      variables.inboxNotificationsWhere.threadMessages._and.push(graphql);
    } else {
      const graphql = {
        userRecipients: {
          user: {
            name: { _ilike: normalizeFilterString(token.value) },
          },
        },
      };

      variables.threadsWhere.messages._and.push(graphql);
      variables.messagesWhere._and.push(graphql);
      variables.inboxNotificationsWhere.threadMessages._and.push(graphql);
    }
  } else if (token.type === "DocId") {
    ensureThreadsAndNotificationsHaveMessagesKey_and(variables);

    const [subject, priority, subjectId] = (token.value as string).split(
      "::",
    ) as [string, string, string];

    switch (subject) {
      case "user": {
        switch (priority.length) {
          case 1: {
            const graphql = {
              userRecipients: {
                userFirestoreId: { _eq: subjectId },
              },
            };

            variables.threadsWhere.messages._and.push(graphql);
            variables.messagesWhere._and.push(graphql);
            variables.inboxNotificationsWhere.threadMessages._and.push(graphql);
            return;
          }
          case 2:
          case 3: {
            const graphql = {
              userMentions: {
                userFirestoreId: { _eq: subjectId },
                mentionType: {
                  _eq:
                    priority.length === 2
                      ? ("REQUEST_RESPONSE" as const)
                      : ("INTERRUPT" as const),
                },
              },
            };

            variables.threadsWhere.messages._and.push(graphql);
            variables.messagesWhere._and.push(graphql);
            variables.inboxNotificationsWhere.threadMessages._and.push(graphql);

            return;
          }
          default: {
            throw new UnreachableCaseError(priority.length as never);
          }
        }
      }
      case "channel": {
        switch (priority.length) {
          case 1: {
            const graphql = {
              channelRecipients: {
                channelFirestoreId: { _eq: subjectId },
              },
            };

            variables.threadsWhere.messages._and.push(graphql);
            variables.messagesWhere._and.push(graphql);
            variables.inboxNotificationsWhere.threadMessages._and.push(graphql);

            return;
          }
          case 2:
          case 3: {
            const graphql = {
              channelMentions: {
                channelFirestoreId: { _eq: subjectId },
                mentionType: {
                  _eq:
                    priority.length === 2
                      ? ("REQUEST_RESPONSE" as const)
                      : ("INTERRUPT" as const),
                },
              },
            };

            variables.threadsWhere.messages._and.push(graphql);
            variables.messagesWhere._and.push(graphql);
            variables.inboxNotificationsWhere.threadMessages._and.push(graphql);

            return;
          }
          default: {
            throw new UnreachableCaseError(priority.length as never);
          }
        }
      }
      default: {
        throw new UnreachableCaseError(subject as never);
      }
    }
  } else {
    throw new UnreachableCaseError(token);
  }
}

/* -----------------------------------------------------------------------------------------------*/

function isFilter(
  token: FilterValue<"is">,
  variables: IGraphQLQueryVariables_Where,
) {
  if (token.type === "text") {
    switch (token.value) {
      case "done": {
        ensureQueryHasPath(variables.threadsWhere, {
          inboxNotifications: { _and: emptyArray() },
        });

        ensureQueryHasPath(variables.messagesWhere, {
          inboxNotifications: { _and: emptyArray() },
        });

        const graphql = {
          done: { _eq: true },
        };

        variables.threadsWhere.inboxNotifications._and.push(graphql);
        variables.messagesWhere.inboxNotifications._and.push(graphql);
        variables.inboxNotificationsWhere._and.push(graphql);
        return;
      }
      case "branch": {
        ensureQueryHasPath(variables.messagesWhere, {
          thread: { _and: emptyArray() },
        });

        ensureQueryHasPath(variables.inboxNotificationsWhere, {
          thread: { _and: emptyArray() },
        });

        const graphql = {
          branchedFromThreadFirestoreId: { _isNull: false },
        };

        variables.threadsWhere._and.push(graphql);
        variables.messagesWhere.thread._and.push(graphql);
        variables.inboxNotificationsWhere.thread._and.push(graphql);
        return;
      }
      case "private": {
        ensureQueryHasPath(variables.messagesWhere, {
          thread: { _and: emptyArray() },
        });

        ensureQueryHasPath(variables.inboxNotificationsWhere, {
          thread: { _and: emptyArray() },
        });

        const graphql = {
          visibility: { _eq: "PRIVATE" as const },
        };

        variables.threadsWhere._and.push(graphql);
        variables.messagesWhere.thread._and.push(graphql);
        variables.inboxNotificationsWhere.thread._and.push(graphql);
        return;
      }
      case "shared": {
        ensureQueryHasPath(variables.messagesWhere, {
          thread: { _and: emptyArray() },
        });

        ensureQueryHasPath(variables.inboxNotificationsWhere, {
          thread: { _and: emptyArray() },
        });

        const graphql = {
          visibility: { _eq: "SHARED" as const },
        };

        variables.threadsWhere._and.push(graphql);
        variables.messagesWhere.thread._and.push(graphql);
        variables.inboxNotificationsWhere.thread._and.push(graphql);
        return;
      }
      case "seen": {
        ensureQueryHasPath(variables.messagesWhere, {
          thread: { _and: emptyArray() },
        });

        ensureQueryHasPath(variables.inboxNotificationsWhere, {
          thread: { _and: emptyArray() },
        });

        const graphql = {
          _or: [
            {
              threadReadStatuses: {
                userFirestoreId: { _eq: getAndAssertCurrentUser().id },
              },
            },
            { type: { _eq: "EMAIL_SECRET" as const } },
          ],
        };

        variables.threadsWhere._and.push(graphql);
        variables.messagesWhere.thread._and.push(graphql);
        variables.inboxNotificationsWhere._and.push(graphql);
        return;
      }
      default: {
        console.warn(`Unknown option passed to "is:". Ignoring.`, token);
        return;
      }
    }
  } else if (token.type === "DocId") {
    console.warn(`Document ID passed to "is:", ignoring.`);
    return;
  } else {
    throw new UnreachableCaseError(token);
  }
}

/* -----------------------------------------------------------------------------------------------*/

function afterFilter(
  token: FilterValue<"after">,
  variables: IGraphQLQueryVariables_Where,
) {
  if (token.type === "text") {
    const dateString = normalizeStringDate(token.value);

    if (!dateString) {
      console.warn(`Invalid value provided to "after:" filter`);
      return;
    }

    ensureThreadsAndNotificationsHaveMessagesKey_and(variables);

    const graphql = {
      sentAt: {
        // Note that we're using "greater than or equal" whereas for the
        // beforeFilter we use "less than". This is arguably a little strange,
        // but at the moment I'm of the opinion that if someone said, "give
        // me posts sent after 3pm" they would want probably want posts
        // sent at 3pm. Whereas if they said, "give me posts before 3pm"
        // they probably wouldn't want posts sent at 3pm. In the future we
        // may find that this was the incorrect decision. -- John
        _gte: dateString,
      },
    };

    variables.threadsWhere.messages._and.push(graphql);
    variables.messagesWhere._and.push(graphql);
    // Note that this filter operates differently on notificationsWhere for after
    // vs before. The before filter filters on oldestSentAtValueNotMarkedDone.
    variables.inboxNotificationsWhere._and.push(graphql);
  } else if (token.type === "DocId") {
    console.warn(`Document ID passed to "after:", ignoring.`);
    return;
  } else {
    throw new UnreachableCaseError(token);
  }
}

/* -----------------------------------------------------------------------------------------------*/

function beforeFilter(
  token: FilterValue<"before">,
  variables: IGraphQLQueryVariables_Where,
) {
  if (token.type === "text") {
    const dateString = normalizeStringDate(token.value);

    if (!dateString) {
      console.warn(`Invalid value provided to "before:" filter`);
      return;
    }

    ensureThreadsAndNotificationsHaveMessagesKey_and(variables);

    const graphql = {
      sentAt: {
        // Note that we're using "less than" whereas for the afterFilter
        // we use "greater than or equal". This is arguably a little strange,
        // but at the moment I'm of the opinion that if someone said, "give
        // me posts sent after 3pm" they would want probably want posts
        // sent at 3pm. Whereas if they said, "give me posts before 3pm"
        // they probably wouldn't want posts sent at 3pm. In the future we
        // may find that this was the incorrect decision. -- John
        _lt: dateString,
      },
    };

    variables.threadsWhere.messages._and.push(graphql);
    variables.messagesWhere._and.push(graphql);
    variables.inboxNotificationsWhere._and.push({
      oldestSentAtValueNotMarkedDone: {
        _lt: dateString,
      },
    });
  } else if (token.type === "DocId") {
    console.warn(`Document ID passed to "before:", ignoring.`);
    return;
  } else {
    throw new UnreachableCaseError(token);
  }
}

/* -----------------------------------------------------------------------------------------------*/

function viewerFilter(
  token: FilterValue<"viewer">,
  variables: IGraphQLQueryVariables_Where,
) {
  if (token.type === "text") {
    ensureQueryHasPath(variables.inboxNotificationsWhere, {
      thread: { _and: emptyArray() },
    });

    const asNumber = Number.parseInt(token.value, 10);

    if (Number.isInteger(asNumber)) {
      const graphql = {
        permittedUsersAggregate: {
          count: {
            predicate: {
              _eq: asNumber,
            },
          },
        },
      };

      variables.threadsWhere._and.push(graphql);
      variables.messagesWhere._and.push(graphql);
      variables.inboxNotificationsWhere._and.push({
        threadPermittedUsersAggregate: {
          count: {
            predicate: {
              _eq: asNumber,
            },
          },
        },
      });
      return;
    }

    if (token.value === "me") {
      const graphql = {
        permittedUsers: {
          userFirestoreId: { _eq: getAndAssertCurrentUser().id },
        },
      };

      variables.threadsWhere._and.push(graphql);
      variables.messagesWhere._and.push(graphql);
      variables.inboxNotificationsWhere._and.push({
        threadPermittedUsers: {
          userFirestoreId: { _eq: getAndAssertCurrentUser().id },
        },
      });
    } else {
      const graphql = {
        permittedUsers: {
          user: {
            name: { _ilike: normalizeFilterString(token.value) },
          },
        },
      };

      variables.threadsWhere._and.push(graphql);
      variables.messagesWhere._and.push(graphql);
      variables.inboxNotificationsWhere._and.push({
        threadPermittedUsers: {
          user: {
            name: { _ilike: normalizeFilterString(token.value) },
          },
        },
      });
    }
  } else if (token.type === "DocId") {
    const [subject, , subjectId] = (token.value as string).split("::") as [
      string,
      string,
      string,
    ];

    ensureQueryHasPath(variables.inboxNotificationsWhere, {
      thread: { _and: emptyArray() },
    });

    switch (subject) {
      case "user": {
        const graphql = {
          permittedUsers: {
            userFirestoreId: { _eq: subjectId },
          },
        };

        variables.threadsWhere._and.push(graphql);
        variables.messagesWhere._and.push(graphql);
        variables.inboxNotificationsWhere._and.push({
          threadPermittedUsers: {
            userFirestoreId: { _eq: getAndAssertCurrentUser().id },
          },
        });
        return;
      }
      case "channel": {
        console.warn(
          `User provided a specified a channel for "viewer:". Ignoring.`,
        );
        return;
      }
      default: {
        throw new UnreachableCaseError(subject as never);
      }
    }
  } else {
    throw new UnreachableCaseError(token);
  }
}

/* -----------------------------------------------------------------------------------------------*/

function participatingFilter(
  token: FilterValue<"participating">,
  variables: IGraphQLQueryVariables_Where,
) {
  if (token.type === "text") {
    const asNumber = Number.parseInt(token.value, 10);

    if (Number.isInteger(asNumber)) {
      const graphql = {
        userParticipationsAggregate: {
          count: {
            predicate: {
              _eq: asNumber,
            },
          },
        },
      };

      variables.threadsWhere._and.push(graphql);
      variables.messagesWhere._and.push(graphql);
      variables.inboxNotificationsWhere._and.push({
        threadUserParticipationsAggregate: {
          count: {
            predicate: {
              _eq: asNumber,
            },
          },
        },
      });
      return;
    }

    if (token.value === "me") {
      const graphql = {
        userParticipations: {
          userFirestoreId: { _eq: getAndAssertCurrentUser().id },
        },
      };

      variables.threadsWhere._and.push(graphql);
      variables.messagesWhere._and.push(graphql);
      variables.inboxNotificationsWhere._and.push({
        threadUserParticipations: {
          userFirestoreId: { _eq: getAndAssertCurrentUser().id },
        },
      });
    } else {
      const graphql = {
        userParticipations: {
          user: {
            name: { _ilike: normalizeFilterString(token.value) },
          },
        },
      };

      variables.threadsWhere._and.push(graphql);
      variables.messagesWhere._and.push(graphql);
      variables.inboxNotificationsWhere._and.push({
        threadUserParticipations: {
          user: {
            name: { _ilike: normalizeFilterString(token.value) },
          },
        },
      });
    }
  } else if (token.type === "DocId") {
    const [subject, , subjectId] = (token.value as string).split("::") as [
      string,
      string,
      string,
    ];

    switch (subject) {
      case "user": {
        const graphql = {
          userParticipations: {
            userFirestoreId: { _eq: subjectId },
          },
        };

        variables.threadsWhere._and.push(graphql);
        variables.messagesWhere._and.push(graphql);
        variables.inboxNotificationsWhere._and.push({
          threadUserParticipations: {
            userFirestoreId: { _eq: subjectId },
          },
        });
        return;
      }
      case "channel": {
        console.warn(
          `"channel" DocId provided for "participating:". Ignoring.`,
        );
        return;
      }
      default: {
        throw new UnreachableCaseError(subject as never);
      }
    }
  } else {
    throw new UnreachableCaseError(token);
  }
}

/* -----------------------------------------------------------------------------------------------*/

function channelFilter(
  token: FilterValue<"channel">,
  variables: IGraphQLQueryVariables_Where,
) {
  if (token.type === "text") {
    const asNumber = Number.parseInt(token.value, 10);

    if (Number.isInteger(asNumber)) {
      const graphql = {
        channelPermissionsAggregate: {
          count: {
            predicate: {
              _eq: asNumber,
            },
          },
        },
      };

      variables.threadsWhere._and.push(graphql);
      variables.messagesWhere._and.push(graphql);
      variables.inboxNotificationsWhere._and.push({
        threadChannelPermissionsAggregate: {
          count: {
            predicate: {
              _eq: asNumber,
            },
          },
        },
      });
      return;
    }

    const graphql = {
      channelPermissions: {
        channel: {
          name: { _ilike: normalizeFilterString(token.value) },
        },
      },
    };

    variables.threadsWhere._and.push(graphql);
    variables.messagesWhere._and.push(graphql);
    variables.inboxNotificationsWhere._and.push({
      threadChannelPermissions: {
        channel: {
          name: { _ilike: normalizeFilterString(token.value) },
        },
      },
    });
    return;
  } else if (token.type === "DocId") {
    const [subject, , subjectId] = (token.value as string).split("::") as [
      string,
      string,
      string,
    ];

    switch (subject) {
      case "user": {
        console.warn(`"user" DocId provided for "channels:". Ignoring.`);
        return;
      }
      case "channel": {
        const graphql = {
          channelPermissions: {
            channelFirestoreId: { _eq: subjectId },
          },
        };

        variables.threadsWhere._and.push(graphql);
        variables.messagesWhere._and.push(graphql);
        variables.inboxNotificationsWhere._and.push({
          threadChannelPermissions: {
            channelFirestoreId: { _eq: subjectId },
          },
        });
        return;
      }
      default: {
        throw new UnreachableCaseError(subject as never);
      }
    }
  } else {
    throw new UnreachableCaseError(token);
  }
}

/* -----------------------------------------------------------------------------------------------*/

function tagFilter(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  token: FilterValue<"tag">,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  variables: IGraphQLQueryVariables_Where,
) {
  console.warn(`tagFilter not currently supported. Ignoring.`);
}

/* -----------------------------------------------------------------------------------------------*/

function subjectFilter(
  token: FilterValue<"subject">,
  variables: IGraphQLQueryVariables_Where,
) {
  if (token.type === "text") {
    ensureQueryHasPath(variables.inboxNotificationsWhere, {
      thread: { _and: emptyArray() },
    });

    const graphql = {
      subject: {
        _ilike: normalizeFilterString(token.value),
      },
    };

    variables.threadsWhere._and.push(graphql);
    variables.messagesWhere._and.push(graphql);
    variables.inboxNotificationsWhere.thread._and.push(graphql);
  } else if (token.type === "DocId") {
    console.warn(`provided a docId to "subject:". Ignoring.`);
    return;
  } else {
    throw new UnreachableCaseError(token);
  }
}

/* -----------------------------------------------------------------------------------------------*/

function bodyFilter(
  token: FilterValue<"body">,
  variables: IGraphQLQueryVariables_Where,
) {
  if (token.type === "text") {
    ensureThreadsAndNotificationsHaveMessagesKey_and(variables);

    const graphql = {
      bodyText: {
        _ilike: normalizeFilterString(token.value),
      },
    };

    variables.threadsWhere.messages._and.push(graphql);
    variables.messagesWhere._and.push(graphql);
    variables.inboxNotificationsWhere.threadMessages._and.push(graphql);
  } else if (token.type === "DocId") {
    console.warn(`provided a docId to "body:". Ignoring.`);
    return;
  } else {
    throw new UnreachableCaseError(token);
  }
}

/* -----------------------------------------------------------------------------------------------*/

function hasFilter(
  token: FilterValue<"has">,
  variables: IGraphQLQueryVariables_Where,
) {
  if (token.type === "text") {
    switch (token.value) {
      case "reminder": {
        const ensuredPath = {
          inboxNotifications: { _and: emptyArray() },
        };

        ensureQueryHasPath(variables.threadsWhere, ensuredPath);
        ensureQueryHasPath(variables.messagesWhere, ensuredPath);

        const graphql = { hasReminder: { _eq: true } };
        variables.threadsWhere.inboxNotifications._and.push(graphql);
        variables.messagesWhere.inboxNotifications._and.push(graphql);
        variables.inboxNotificationsWhere._and.push(graphql);
        return;
      }
      case "notification": {
        const graphql = {
          inboxNotificationsAggregate: { count: { predicate: { _eq: 1 } } },
        };

        variables.threadsWhere._and.push(graphql);
        variables.messagesWhere._and.push(graphql);
        return;
      }
      default: {
        console.warn(
          `provided unknown value "${token.value}" to "has:". Ignoring.`,
        );
        return;
      }
    }
  } else if (token.type === "DocId") {
    console.warn(`provided a docId to "has:". Ignoring.`);
    return;
  } else {
    throw new UnreachableCaseError(token);
  }
}

/* -----------------------------------------------------------------------------------------------*/

function remindAfterFilter(
  token: FilterValue<"remindAfter">,
  variables: IGraphQLQueryVariables_Where,
) {
  if (token.type === "text") {
    const dateString = normalizeStringDate(token.value);

    if (!dateString) {
      console.warn(`Invalid value provided to "remind-after:" filter`);
      return;
    }

    const ensuredPath = {
      inboxNotifications: { _and: emptyArray() },
    };

    ensureQueryHasPath(variables.threadsWhere, ensuredPath);
    ensureQueryHasPath(variables.messagesWhere, ensuredPath);

    const graphql = {
      // Note that we're using "greater than or equal" whereas for the
      // remindBeforeFilter we use "less than". This is arguably a little strange,
      // but at the moment I'm of the opinion that if someone said, "give
      // me posts sent after 3pm" they would probably want posts
      // sent at 3pm included. Whereas if they said, "give me posts before 3pm"
      // they probably wouldn't want posts sent at 3pm included. In the future we
      // may find that this was the incorrect decision. -- John
      remindAt: { _gte: dateString },
    };

    variables.threadsWhere.inboxNotifications._and.push(graphql);
    variables.messagesWhere.inboxNotifications._and.push(graphql);
    variables.inboxNotificationsWhere._and.push(graphql);
  } else if (token.type === "DocId") {
    console.warn(`provided a docId to "remind-after:". Ignoring.`);
    return;
  } else {
    throw new UnreachableCaseError(token);
  }
}

/* -----------------------------------------------------------------------------------------------*/

function remindBeforeFilter(
  token: FilterValue<"remindBefore">,
  variables: IGraphQLQueryVariables_Where,
) {
  if (token.type === "text") {
    const dateString = normalizeStringDate(token.value);

    console.log("date", dateString);

    if (!dateString) {
      console.warn(`Invalid value provided to "remind-after:" filter`);
      return;
    }

    const ensuredPath = {
      inboxNotifications: { _and: emptyArray() },
    };

    ensureQueryHasPath(variables.threadsWhere, ensuredPath);
    ensureQueryHasPath(variables.messagesWhere, ensuredPath);

    const graphql = {
      // Note that we're using "less than" whereas for the
      // remindAfterFilter we use "greater than or equal". This is arguably a little strange,
      // but at the moment I'm of the opinion that if someone said, "give
      // me posts sent after 3pm" they would probably want posts
      // sent at 3pm included. Whereas if they said, "give me posts before 3pm"
      // they probably wouldn't want posts sent at 3pm included. In the future we
      // may find that this was the incorrect decision. -- John
      remindAt: { _lt: dateString },
    };

    variables.threadsWhere.inboxNotifications._and.push(graphql);
    variables.messagesWhere.inboxNotifications._and.push(graphql);
    variables.inboxNotificationsWhere._and.push(graphql);
  } else if (token.type === "DocId") {
    console.warn(`provided a docId to "remind-after:". Ignoring.`);
    return;
  } else {
    throw new UnreachableCaseError(token);
  }
}

/* -----------------------------------------------------------------------------------------------*/

function mentionsFilter(
  token: FilterValue<"mentions">,
  variables: IGraphQLQueryVariables_Where,
) {
  if (token.type === "text") {
    ensureThreadsAndNotificationsHaveMessagesKey_and(variables);

    if (token.value === "me") {
      const graphql = {
        userMentions: {
          userFirestoreId: { _eq: getAndAssertCurrentUser().id },
        },
      };

      variables.threadsWhere.messages._and.push(graphql);
      variables.messagesWhere._and.push(graphql);
      variables.inboxNotificationsWhere.threadMessages._and.push(graphql);
    } else {
      const graphql = {
        userMentions: {
          user: {
            name: { _ilike: normalizeFilterString(token.value) },
          },
        },
      };

      variables.threadsWhere.messages._and.push(graphql);
      variables.messagesWhere._and.push(graphql);
      variables.inboxNotificationsWhere.threadMessages._and.push(graphql);
    }
  } else if (token.type === "DocId") {
    const [subject, , subjectId] = (token.value as string).split("::") as [
      string,
      string,
      string,
    ];

    ensureThreadsAndNotificationsHaveMessagesKey_and(variables);

    switch (subject) {
      case "user": {
        const graphql = {
          userMentions: {
            userFirestoreId: { _eq: subjectId },
          },
        };

        variables.threadsWhere.messages._and.push(graphql);
        variables.messagesWhere._and.push(graphql);
        variables.inboxNotificationsWhere.threadMessages._and.push(graphql);
        return;
      }
      case "channel": {
        const graphql = {
          channelMentions: {
            channelFirestoreId: { _eq: subjectId },
          },
        };

        variables.threadsWhere.messages._and.push(graphql);
        variables.messagesWhere._and.push(graphql);
        variables.inboxNotificationsWhere.threadMessages._and.push(graphql);
        return;
      }
      default: {
        throw new UnreachableCaseError(subject as never);
      }
    }
  } else {
    throw new UnreachableCaseError(token);
  }
}

/* -----------------------------------------------------------------------------------------------*/

function priorityFilter(
  token: FilterValue<"priority">,
  variables: IGraphQLQueryVariables_Where,
) {
  if (token.type === "text") {
    const ensuredPath = {
      inboxNotifications: { _and: emptyArray() },
    };

    const addPriorityFilter = (priority: number) => {
      ensureQueryHasPath(variables.threadsWhere, ensuredPath);
      ensureQueryHasPath(variables.messagesWhere, ensuredPath);

      const graphql = {
        priority: { _eq: priority },
      };

      variables.threadsWhere.inboxNotifications._and.push(graphql);
      variables.messagesWhere.inboxNotifications._and.push(graphql);
      variables.inboxNotificationsWhere._and.push(graphql);
    };

    if (token.value.includes("@@@") || token.value === "100") {
      addPriorityFilter(100);
    } else if (token.value.includes("@@") || token.value === "200") {
      addPriorityFilter(200);
    } else if (token.value.includes("@") || token.value === "300") {
      addPriorityFilter(300);
    } else if (token.value === "participating" || token.value === "400") {
      addPriorityFilter(400);
    } else if (token.value === "subscriber" || token.value === "500") {
      addPriorityFilter(500);
    } else {
      throw new UnreachableCaseError(token.value as never);
    }
  } else {
    throw new UnreachableCaseError(token.value as never);
  }
}

/* -----------------------------------------------------------------------------------------------*/

function andOperator(
  { value: tokens }: LogicalOperator<"and()">,
  variables: IGraphQLQueryVariables_Where,
) {
  const subVariables: IGraphQLQueryVariables_Where = {
    threadsWhere: { _and: [] },
    messagesWhere: { _and: [] },
    inboxNotificationsWhere: { _and: [] },
  };

  for (const token of tokens) {
    mergeGraphQLQueryInput(token, subVariables);
  }

  variables.threadsWhere._and.push(subVariables.threadsWhere);
  variables.messagesWhere._and.push(subVariables.messagesWhere);
  variables.inboxNotificationsWhere._and.push(
    subVariables.inboxNotificationsWhere,
  );
}

/* -----------------------------------------------------------------------------------------------*/

function orOperator(
  { value: tokens }: LogicalOperator<"or()">,
  variables: IGraphQLQueryVariables_Where,
) {
  const subVariables: IGraphQLQueryVariables_Where = {
    threadsWhere: { _and: [] },
    messagesWhere: { _and: [] },
    inboxNotificationsWhere: { _and: [] },
  };

  for (const token of tokens) {
    mergeGraphQLQueryInput(token, subVariables);
  }

  const ensuredPath = { _or: emptyArray() };
  ensureQueryHasPath(variables.threadsWhere, ensuredPath);
  ensureQueryHasPath(variables.messagesWhere, ensuredPath);
  ensureQueryHasPath(variables.inboxNotificationsWhere, ensuredPath);

  variables.threadsWhere._or.push(subVariables.threadsWhere);
  variables.messagesWhere._or.push(subVariables.messagesWhere);
  variables.inboxNotificationsWhere._or.push(
    subVariables.inboxNotificationsWhere,
  );
}

/* -----------------------------------------------------------------------------------------------*/

function notOperator(
  { value: tokens }: LogicalOperator<"not()">,
  variables: IGraphQLQueryVariables_Where,
) {
  const subVariables: IGraphQLQueryVariables_Where = {
    threadsWhere: { _and: [] },
    messagesWhere: { _and: [] },
    inboxNotificationsWhere: { _and: [] },
  };

  for (const token of tokens) {
    mergeGraphQLQueryInput(token, subVariables);
  }

  const ensuredPath = { _not: { _or: emptyArray() } };
  ensureQueryHasPath(variables.threadsWhere, ensuredPath);
  ensureQueryHasPath(variables.messagesWhere, ensuredPath);
  ensureQueryHasPath(variables.inboxNotificationsWhere, ensuredPath);

  variables.threadsWhere._not._or.push(subVariables.threadsWhere);
  variables.messagesWhere._not._or.push(subVariables.messagesWhere);
  variables.inboxNotificationsWhere._not._or.push(
    subVariables.inboxNotificationsWhere,
  );
}

/* -----------------------------------------------------------------------------------------------*/

function ensureThreadsAndNotificationsHaveMessagesKey_and(
  variables: IGraphQLQueryVariables_Where,
): asserts variables is IGraphQLQueryVariables_Where & {
  threadsWhere: ThreadsWhere & {
    messages: MessagesWhere;
  };
  inboxNotificationsWhere: NotificationNewPostsWhere & {
    threadMessages: MessagesWhere;
  };
} {
  merge(variables.threadsWhere, { messages: { _and: [] } });

  merge(variables.inboxNotificationsWhere, {
    threadMessages: { _and: [] },
  });
}

function ensureQueryHasPath<T>(
  query: ThreadsWhere,
  path: T,
): asserts query is ThreadsWhere & T {
  merge(query, path);
}

/**
 * This is used for ease of getting a new `[]` as `unknown[]`.
 * Easier than `[] as unknown[]`.
 */
function emptyArray() {
  return [] as unknown[];
}

function normalizeFilterString(value: string) {
  if (value.startsWith(`"`) && value.endsWith(`"`)) {
    return `%${escapePostgresPatternMatching(value.slice(1, -1))}%`;
  }

  return `%${escapePostgresPatternMatching(value)}%`;
}

function escapePostgresPatternMatching(text: string | undefined) {
  // Need to escape "%" and "_" since they are special characters
  // in postgres pattern matching search:
  // https://www.postgresql.org/docs/14/functions-matching.html
  return text?.replaceAll("%", "\\%").replaceAll("_", "\\_");
}

function normalizeStringDate(dateString: string) {
  const dateMatch = dateString.match(/(\d{4})\/(\d{1,2})\/(\d{1,2})/);

  if (!dateMatch) return null;

  const date = new Date(dateMatch[0]);

  if (isNaN(date.valueOf())) return null;

  // Here we remove the "Z" suffix from the date string because our
  // timestamps in postgres don't have it (though they are UTC times).
  return date.toISOString().slice(0, -1);
}

/* -----------------------------------------------------------------------------------------------*/
