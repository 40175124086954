import {
  ComponentType,
  memo,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { isEqual } from "@libs/utils/isEqual";
import { EditThreadPostEntry } from "./EditThreadPostEntry";
import {
  IViewThreadPostEntryProps,
  ViewThreadPostEntry,
} from "./ViewThreadPostEntry";
import { ThreadPostEntryBranches } from "./ThreadPostEntryBranches";
import { IComposeMessageFormValue } from "~/components/ComposeMessageContext";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { firstValueFrom, Observable } from "rxjs";
import {
  IObserveThread,
  observePost,
  observeThread,
} from "~/services/post.service";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { IListRef, List } from "~/components/list";
import { TThreadTimelineEntry } from "../utils";
import { useThreadPostEntryContext } from "./context";
import { IPostDoc } from "@libs/firestore-models";
import { IRichTextEditorRef } from "~/form-components/post-editor";
import { IThreadRecipients } from "../ReplyDraftHeader";

/* -------------------------------------------------------------------------------------------------
 * ThreadPostEntry
 * -----------------------------------------------------------------------------------------------*/

export interface IThreadPostEntryProps extends IViewThreadPostEntryProps {
  thread: IObserveThread;
  listRef: RefObject<IListRef<TThreadTimelineEntry>>;
  relativeOrder: number;
  threadRecipients$: Observable<IThreadRecipients>;
}

export const ThreadPostEntry: ComponentType<IThreadPostEntryProps> = memo(
  (props) => {
    const { isEditingPostId, setIsEditingPostId } = useThreadPostEntryContext();
    const [initialEditPostFormValues, setInitialEditPostFormValues] =
      useState<IComposeMessageFormValue | null>(null);

    const listEntryDivRef = useRef<HTMLDivElement>(null);
    const editorRef = useRef<IRichTextEditorRef>(null);

    useEffect(() => {
      if (!initialEditPostFormValues) return;
      editorRef.current?.focus("start");
    }, [initialEditPostFormValues]);

    useEffect(() => {
      if (isEditingPostId !== props.post.id) return;

      editThreadPost({
        postId: props.post.id,
        threadId: props.thread.id,
        setInitialEditPostFormValues,
        setIsEditingPostId,
      });
    }, [props.post.id, props.thread.id, setIsEditingPostId, isEditingPostId]);

    return (
      <>
        <List.Entry<IPostDoc>
          id={props.post.id}
          data={props.post}
          relativeOrder={props.relativeOrder}
          onFocusIn={(e) => {
            if (!initialEditPostFormValues) return;
            if (e.target.classList.contains("ProseMirror")) return;
            // The related target is the target which previous had focus
            if (listEntryDivRef.current?.contains(e.relatedTarget)) return;
            e.preventDefault();
            // We can't call editor.focus() while react is rendering. React
            // suggested we move this call to a microtask.
            queueMicrotask(() => editorRef.current?.focus("start"));
          }}
        >
          {initialEditPostFormValues ? (
            <EditThreadPostEntry
              ref={listEntryDivRef}
              post={props.post}
              thread={props.thread}
              listRef={props.listRef}
              threadRecipients$={props.threadRecipients$}
              initialFormValues={initialEditPostFormValues}
              editorRef={editorRef}
              onDone={() => {
                setInitialEditPostFormValues(null);
                setIsEditingPostId(null);
              }}
            />
          ) : (
            <ViewThreadPostEntry
              ref={listEntryDivRef}
              post={props.post}
              listRef={props.listRef}
              canUndoSend={props.canUndoSend}
              canEdit={props.canEdit}
              cannotEditReason={props.cannotEditReason}
              collapsePostEvents={props.collapsePostEvents}
              isFromSecretThread={!!props.thread.__local.fromSecretThread}
              isFirstPost={props.isFirstPost}
              isLastPost={props.isLastPost}
              isFromBranchId={props.isFromBranchId}
              onPostInView={props.onPostInView}
            />
          )}
        </List.Entry>

        <ThreadPostEntryBranches
          postId={props.post.id}
          isFromBranchId={props.isFromBranchId}
        />
      </>
    );
  },
  isEqual,
);

const editThreadPost = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(
    async (args: {
      postId: string;
      threadId: string;
      setInitialEditPostFormValues: (value: IComposeMessageFormValue) => void;
      setIsEditingPostId: (value: string | null) => void;
    }): Promise<void> => {
      const [post, thread] = await Promise.all([
        firstValueFrom(observePost(args.postId)),
        firstValueFrom(observeThread(args.threadId)),
      ]);

      if (!post) {
        console.warn(`Could not find post associated with id "${args.postId}"`);
        return;
      } else if (!thread) {
        console.warn(
          `Could not find thread associated with id "${args.threadId}"`,
        );

        return;
      }

      args.setInitialEditPostFormValues({
        type: thread.type,
        postId: args.postId,
        visibility: thread.visibility,
        subject: "",
        branchedFrom: null,
        recipients: {
          to: [],
          cc: [],
          bcc: [],
        },
        body: {
          content: post.bodyHTML,
          userMentions: Object.entries(post.mentionedUsers).map(
            ([userId, data]) => ({
              type: "user",
              id: userId,
              priority: data.priority,
            }),
          ),
          channelMentions: Object.entries(post.mentionedChannels).map(
            ([channelId, data]) => ({
              type: "channel",
              id: channelId,
              priority: data.priority,
            }),
          ),
        },
      });

      args.setIsEditingPostId(args.postId);
    },
  ),
);

/* -----------------------------------------------------------------------------------------------*/
