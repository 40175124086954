import {
  ICommsPostDoc,
  IEmailPostDoc,
  IPostDoc,
  ISecretEmailPostDoc,
} from "@libs/firestore-models";
import React, {
  ComponentType,
  createContext,
  memo,
  ReactNode,
  useMemo,
  useState,
} from "react";
import { isEqual } from "@libs/utils/isEqual";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { cx, css } from "@emotion/css";
import { DisplayDate, EntryTimestamp } from "~/components/content-list/layout";
import { Link } from "react-router-dom";
import { distinctUntilChanged, map, of } from "rxjs";
import { useConvertHTMLToReact } from "@libs/utils/parseHTMLProse";
import { Tooltip } from "~/components/Tooltip";
import {
  getEmbeddableVideoLink,
  shouldURLBeUnfurled,
} from "./utils/videoEmbeds";
import { FaPlayCircle } from "react-icons/fa";
import { useObservable } from "~/utils/useObservable";
import {
  CHANNELS_USER_IS_SUBSCRIBED_TO$,
  observeChannel,
} from "~/services/channels.service";
import { createUseContextHook } from "~/utils/createUseContextHook";
import { BsLockFill } from "react-icons/bs";
import { UnreachableCaseError } from "@libs/utils/errors";
import root from "react-shadow";
import {
  getPostSenderName,
  IEmailPostDocFromSecretEmailPost,
} from "@libs/firestore-models/utils";
import { parseEmailAddress } from "@libs/utils/parseEmailAddress";
import { LabelChip } from "../ChannelLabels";
import { isAddressIncludedInEmailAddresses } from "@libs/utils/predicates";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ShadowDiv = root.div!;

export const ExpandedPost: ComponentType<{
  post: IPostDoc;
  postActions?: ReactNode;
  onHeaderClick?: () => void;
}> = memo((props) => {
  const { post } = props;

  const componentsToUseForHTML = useMemo(() => {
    switch (post.type) {
      case "COMMS": {
        return {
          a: Hyperlink,
          span: Span,
        };
      }
      case "EMAIL":
      case "EMAIL_SECRET": {
        return {
          a: Hyperlink,
          div: DivTag,
          style: StyleTag,
        };
      }
      default: {
        throw new UnreachableCaseError(post);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post.type]);

  const bodyNode = useConvertHTMLToReact(post.bodyHTML, componentsToUseForHTML);

  switch (post.type) {
    case "COMMS": {
      return <CommsPost {...props} post={post} bodyNode={bodyNode} />;
    }
    case "EMAIL":
    case "EMAIL_SECRET": {
      return <EmailPost {...props} post={post} bodyNode={bodyNode} />;
    }
    default: {
      throw new UnreachableCaseError(post);
    }
  }
}, isEqual);

/* -----------------------------------------------------------------------------------------------*/

const CommsPost: ComponentType<{
  post: ICommsPostDoc;
  bodyNode: ReactNode;
  postActions?: ReactNode;
  onHeaderClick?: () => void;
}> = (props) => {
  const { post } = props;
  const { currentUser } = useAuthGuardContext();
  const senderName =
    currentUser.id === post.creatorId ? "Me" : getPostSenderName(post);

  return (
    <>
      <div
        className="PostHeader flex p-4 sm-w:px-8 sm-w:py-4 hover:cursor-pointer"
        onClick={props.onHeaderClick}
      >
        <div className="PostSender flex items-baseline flex-1">
          <strong>{senderName}</strong>

          {post.lastEditedAt && (
            <Tooltip
              side="bottom"
              content={
                <span className="flex flex-wrap">
                  This post last edited{" "}
                  <DisplayDate
                    date={post.lastEditedAt}
                    size="sm"
                    className="ml-1"
                  />
                </span>
              }
            >
              <div className="text-sm text-slate-8 ml-4">(edited)</div>
            </Tooltip>
          )}
        </div>

        <EntryTimestamp datetime={post.sentAt} size="md" alwaysShowTime />
      </div>

      <div
        className={cx(
          "PostBody prose p-4 sm-w:px-8 sm-w:py-4",
          currentUserMentionCSS(currentUser.id),
        )}
      >
        <ExpandedPostContext.Provider value={props}>
          {props.bodyNode}
        </ExpandedPostContext.Provider>
      </div>

      {props.postActions && (
        <div className="flex items-center p-4 sm-w:px-8 min-h-[60px]">
          {props.postActions}
        </div>
      )}
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const EmailPost: ComponentType<{
  post: IEmailPostDoc | IEmailPostDocFromSecretEmailPost | ISecretEmailPostDoc;
  bodyNode: ReactNode;
  postActions?: ReactNode;
}> = (props) => {
  const { currentUser } = useAuthGuardContext();
  const senderEmail = parseEmailAddress(props.post.sender);
  const senderName =
    currentUser.lowercaseEmail === senderEmail?.emailAddress?.toLowerCase()
      ? "Me"
      : senderEmail?.label
      ? senderEmail.label
      : "unknown";

  const sender = (
    <Tooltip side="bottom" content={senderEmail?.rawValue || ""}>
      <span>{senderName}</span>
    </Tooltip>
  );

  const recipients = Object.values(props.post.recipientUsers).map(
    ({ name, email }, index) => {
      return (
        <span key={index}>
          {index !== 0 && ", "}

          <Tooltip side="bottom" content={`${name} <${email}>`}>
            <span>{name}</span>
          </Tooltip>
        </span>
      );
    },
  );

  const secretPost =
    (props.post.type === "EMAIL" &&
      "__local" in props.post &&
      props.post.__local.fromSecretPost) ||
    (props.post.type === "EMAIL_SECRET" && props.post) ||
    null;

  const wasBCCed =
    secretPost &&
    isAddressIncludedInEmailAddresses(
      currentUser.lowercaseEmail,
      secretPost.bcc,
      true,
    );

  return (
    <>
      <div className="PostHeader flex p-4 sm-w:px-8 sm-w:py-4 hover:cursor-pointer">
        <div className="PostSender flex-1">
          <strong>
            {sender} {recipients.length > 1 && <> to {recipients}</>}
          </strong>

          {secretPost && (
            <span className="ml-2">
              {wasBCCed ? (
                <LabelChip
                  tooltip={`
                    You were BCCed on this message. Other recipients cannot see that you 
                    were BCCed and do not know that you can see this message. Note that
                    you may not see replies to this thread unless you are a recipient of
                    those replies.
                  `}
                >
                  BCC
                </LabelChip>
              ) : (
                <LabelChip
                  tooltip={`
                    You weren't specifically BCCed, but, like a BCC, your email address was 
                    not listed as a recipient of this email yet you received a copy anyway. 
                    This can happen for a number of reasons but most often it's because you 
                    are part of a listserv which is a recipient of this message. Just know 
                    that other recipients of this message don't necessarily know that you 
                    received a copy. Also note that you may not see replies to this thread 
                    unless you are a recipient of those replies.
                  `}
                >
                  BCC-ish
                </LabelChip>
              )}
            </span>
          )}
        </div>

        <EntryTimestamp datetime={props.post.sentAt} size="md" alwaysShowTime />
      </div>

      <ExpandedPostContext.Provider value={props}>
        <ShadowDiv className="PostBody p-4 sm-w:px-8 sm-w:py-4 overflow-auto">
          <style type="text/css">{`
            div { word-break: break-word; }
          `}</style>
          {props.bodyNode}
        </ShadowDiv>
      </ExpandedPostContext.Provider>

      {props.postActions && (
        <div className="flex items-center p-4 sm-w:px-8 min-h-[60px]">
          {props.postActions}
        </div>
      )}
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ExpandedPostContext = createContext<{
  post: IPostDoc;
} | null>(null);

const useExpandedPostContext = createUseContextHook(
  ExpandedPostContext,
  "ExpandedPostContext",
);

const Span: ComponentType<
  Omit<JSX.IntrinsicElements["span"], "ref"> & {
    "data-type"?: string;
    "data-id"?: string;
    "data-subject"?: string;
  }
> = memo((props) => {
  const { currentUser } = useAuthGuardContext();
  const { post } = useExpandedPostContext();

  const isChannelMention = useMemo(
    () =>
      props["data-type"] === "mention" && props["data-subject"] === "channel",
    // react-hooks lint rule is incorrectly erroring here
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props["data-type"], props["data-subject"]],
  );

  const [isPrivateChannel, tooltipText] = useObservable(
    () => {
      if (!isChannelMention || !props["data-id"]) {
        return of([false, ""]);
      }

      return observeChannel(props["data-id"]).pipe(
        map((channel) => {
          const isPrivateChannel = channel?.classification === "private";

          const channelGroupNames = channel?.__local.knownChannelGroups
            .map((group) => group.name)
            .join(", ");

          const tooltipText =
            (channel?.name &&
              channelGroupNames &&
              `${channel?.name} (in ${channelGroupNames})`) ||
            "";

          return [isPrivateChannel, tooltipText] as const;
        }),
      );
    },
    {
      initialValue: [false, ""],
      deps: [isChannelMention, props["data-id"]],
    },
  );

  const shouldHighlightChannelMention = useObservable(
    () => {
      if (!isChannelMention) return of(false);

      return CHANNELS_USER_IS_SUBSCRIBED_TO$.pipe(
        map((channelsUserIsSubscribedTo) => {
          return channelsUserIsSubscribedTo?.some(
            (c) => c.id === props["data-id"],
          );
        }),
        distinctUntilChanged(),
      );
    },
    {
      deps: [isChannelMention, props["data-id"]],
    },
  );

  if (isChannelMention) {
    return (
      <Tooltip side="bottom" content={tooltipText}>
        <span
          className={cx(
            "inline-flex items-center",
            post.creatorId !== currentUser.id &&
              shouldHighlightChannelMention &&
              highlightChannelMentionCSS,
          )}
          {...props}
        >
          {props.children}{" "}
          {isPrivateChannel && <BsLockFill className="inline ml-1 scale-75" />}
        </span>
      </Tooltip>
    );
  }

  return <span {...props} />;
}, isEqual);

const highlightChannelMentionCSS = css`
  &[data-priority="300"] {
    background-color: var(--mint4) !important;
  }

  &[data-priority="200"] {
    // this color of green is taken from levelshealth.com
    background-color: #54ecca !important;
  }

  &[data-priority="100"] {
    background-color: var(--red11) !important;
    color: white !important;
  }

  blockquote &[data-type="mention"][data-subject="channel"] {
    color: var(--slate9) !important;
    background-color: var(--slate2) !important;
  }
`;

/**
 * This component renders a hyperlink from HTML text. The original motivation
 * for creating it was to render internal links using react-router's Link
 * component. It also handles setting a link's `target` prop.
 */
const Hyperlink: ComponentType<Omit<JSX.IntrinsicElements["a"], "ref">> = memo(
  ({ href, target, children, ...otherProps }) => {
    const { post } = useExpandedPostContext();

    const isExternalLink = useMemo(() => {
      if (!href) return true;

      const commsHost = new URL(window.location.href).host;

      try {
        const linkHost = new URL(href, window.location.href).host;
        return linkHost !== commsHost;
      } catch (e) {
        console.warn("Hyperlink: invalid href passed to <a> tag", href, e);
        return true;
      }
    }, [href]);

    const targetProp = target || (isExternalLink ? "_blank" : "_self");

    const shouldUnfurl =
      post.type === "COMMS" && shouldURLBeUnfurled(href, children);

    const embeddableLink = shouldUnfurl && getEmbeddableVideoLink(href);

    if (embeddableLink) {
      return (
        <>
          <VideoEmbed embedUrl={embeddableLink} />
          <a {...otherProps} href={href} target={targetProp}>
            {children}
          </a>
        </>
      );
    }

    if (!href || isExternalLink) {
      return (
        <a {...otherProps} href={href} target={targetProp}>
          {children}
        </a>
      );
    }

    // React router's Link component expects the "to" value to
    // not include the URL origin.
    const to = href.replace(new URL(window.location.href).origin, "");

    return (
      <Link {...otherProps} to={to} target={targetProp}>
        {children}
      </Link>
    );
  },
  isEqual,
);

/**
 * Renders a video embed within an iframe. A loading state is shown until the
 * iframe has loaded.
 *
 * Note: This component will show the following warning in the console, which
 * we're ignoring for now as it will take some further attention to fix:
 * `Warning: validateDOMNesting(...): <div> cannot appear as a descendant of <p>.`
 */
const VideoEmbed: ComponentType<{ embedUrl: string }> = memo(({ embedUrl }) => {
  const [showIFrameLoading, setShowIFrameLoading] = useState(true);

  return (
    <div className={videoEmbedCSS}>
      <iframe
        className={cx(
          videoEmbedIFrameCSS,
          "transition-opacity duration-300",
          showIFrameLoading ? "opacity-0" : "opacity-100",
        )}
        frameBorder="0"
        allowFullScreen
        src={embedUrl}
        onLoad={() => {
          setShowIFrameLoading(false);
        }}
      />
      <div
        className={cx(
          videoEmbedIFrameCSS,
          "bg-slate-3 transition-opacity duration-300 pointer-events-none",
          showIFrameLoading ? "opacity-100" : "opacity-0",
        )}
      >
        <div className="w-full h-full flex items-center justify-center">
          <FaPlayCircle
            className="text-slate-7 self-center animate-pulse"
            size={64}
          />
        </div>
      </div>
    </div>
  );
}, isEqual);

const videoEmbedCSS = css`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 10px;
  height: 0;
  overflow: hidden;
  border-radius: 0.25rem;
`;

const videoEmbedIFrameCSS = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
`;

function currentUserMentionCSS(currentUserId: string) {
  return css`
    & {
      [data-type="mention"][data-id="${currentUserId}"] {
        &[data-priority="300"] {
          background-color: var(--mint4);
        }

        &[data-priority="200"] {
          // this color of green is taken from levelshealth.com
          background-color: #54ecca;
        }

        &[data-priority="100"] {
          background-color: var(--red11);
          color: white;
        }
      }

      blockquote [data-type="mention"][data-id="${currentUserId}"] {
        &[data-priority="300"],
        &[data-priority="200"],
        &[data-priority="100"] {
          color: var(--slate9);
          background-color: var(--slate2);
        }
      }
    }
  `;
}

/* -----------------------------------------------------------------------------------------------*/

/**
 * This component renders a `<div>` tag. If the element has the "gmail_quote" class,
 * then it wraps that `<div>` in a `<details> element.
 */
const DivTag: ComponentType<Omit<JSX.IntrinsicElements["div"], "ref">> = memo(
  ({ className, ...otherProps }) => {
    const isAlreadyQuoted = useQuotedTextContext();
    const isQuotedText = className?.includes("gmail_quote");

    if (!isAlreadyQuoted && isQuotedText) {
      return (
        <QuotedTextContext.Provider value={true}>
          <details>
            <summary style={{ cursor: "pointer" }}>Show more</summary>

            <div className={className} {...otherProps} />
          </details>
        </QuotedTextContext.Provider>
      );
    }

    return <div className={className} {...otherProps} />;
  },
  isEqual,
);

const QuotedTextContext = createContext(false);

const useQuotedTextContext = createUseContextHook(
  QuotedTextContext,
  "QuotedTextContext",
);

/* -----------------------------------------------------------------------------------------------*/

/**
 * This component renders a `<style>` tag but strips "!important" from the styles.
 * The reason this component was added was because Gmail was styling emails based on
 * users system light/dark theme and forcing those styles with "!important". Since
 * Comms doesn't currently support a dark theme, it could make text very difficult to
 * read if the email was rendered expecting a dark background. The user of !important
 * prevented us from overriding the behavior. This could inadvertently break the
 * styling of other emails. If we add a dark theme to Comms, we should reevaluate
 * the need for this component.
 */
// TODO: reevaluate after Comms supports a dark theme
const StyleTag: ComponentType<Omit<JSX.IntrinsicElements["style"], "ref">> =
  memo(({ children, ...otherProps }) => {
    const mappedChildren = React.Children.map(children, (child) => {
      if (typeof child === "string") {
        return child.replaceAll("!important", "");
      }

      return child;
    });

    return <style {...otherProps}>{mappedChildren}</style>;
  }, isEqual);

/* -----------------------------------------------------------------------------------------------*/
