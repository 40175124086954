import { css, cx } from "@emotion/css";
import {
  useState,
  useEffect,
  ComponentType,
  useMemo,
  Fragment,
  useRef,
} from "react";
import { groupBy } from "lodash-es";
import { UnreachableCaseError } from "@libs/utils/errors";
import { useRegisterCommands } from "~/services/command.service";

export type WithIndexAndGroup<T, Group extends string> = T & {
  index: number;
  group: Group;
};

export interface IBaseSuggestion {
  group: string;
  id: string | number;
  index: number;
}

export interface ISuggestionEntryProps<T extends IBaseSuggestion> {
  entry: T;
  selectedIndex: number;
  selectItem: (index: number) => void;
}

export type IBaseSuggestionDropdownProps<T extends IBaseSuggestion> = {
  items: T[];
  suggestionEntryComponentMap: Record<
    string,
    // We're using `any` because the T suggestion entry type is
    // probably a union of different types of entries but each
    // of these suggestionEntry components can't handle being
    // passed a union type.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ComponentType<ISuggestionEntryProps<any>>
  >;
  ifEmptyShowMsg?: boolean;
  onSelect: (item: T) => void;
  onClose: () => void;
};

export function BaseSuggestionsDropdown<T extends IBaseSuggestion>(
  props: IBaseSuggestionDropdownProps<T>,
) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const suggestionEntriesByGroup = useMemo(() => {
    const groupOrder = Object.keys(props.suggestionEntryComponentMap);

    return Object.entries(groupBy(props.items, (item) => item.group)).sort(
      ([aGroupId], [bGroupId]) =>
        groupOrder.indexOf(aGroupId) - groupOrder.indexOf(bGroupId),
    );
  }, [props.items, props.suggestionEntryComponentMap]);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (!item) return;

    props.onSelect(item);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useRegisterCommands({
    commands() {
      if (props.items.length === 0) {
        return [];
      }

      return [
        {
          label: "Down",
          hotkeys: ["ArrowDown"],
          triggerHotkeysWhenInputFocused: true,
          callback: () => {
            setSelectedIndex((s) =>
              clampValue(s + 1, [0, props.items.length - 1]),
            );
          },
        },
        {
          label: "Up",
          hotkeys: ["ArrowUp"],
          triggerHotkeysWhenInputFocused: true,
          callback: () => {
            setSelectedIndex((s) =>
              clampValue(s - 1, [0, props.items.length - 1]),
            );
          },
        },
        {
          label: "Select",
          hotkeys: ["Enter"],
          triggerHotkeysWhenInputFocused: true,
          callback: (e) => {
            e?.preventDefault();
            e?.stopPropagation();
            selectItem(selectedIndex);
          },
        },
        {
          label: "Close",
          hotkeys: ["Escape"],
          triggerHotkeysWhenInputFocused: true,
          callback: (e) => {
            e?.preventDefault();
            e?.stopPropagation();
            props.onClose();
          },
        },
      ];
    },
    deps: [props.items, selectItem, selectedIndex, props.onClose],
  });

  if (suggestionEntriesByGroup.length === 0 && props.ifEmptyShowMsg) {
    return noResultsMsg;
  }

  return (
    <div
      className={cx(
        "bg-white rounded overflow-hidden",
        `w-[330px] pb-3 max-h-[300px] overflow-y-auto`,
        suggestionDropdownStyles,
      )}
    >
      {suggestionEntriesByGroup.map(([groupId, groupEntries], index) => {
        const divider = index > 0 && <hr className="text-slate-5 mt-3 mb-1" />;
        const EntryComponent = props.suggestionEntryComponentMap[groupId];

        if (!EntryComponent) {
          throw new UnreachableCaseError(
            EntryComponent as never,
            "Unexpected suggestion group",
          );
        }

        return (
          <Fragment key={groupId}>
            {divider}

            <SectionWrapper>
              <SectionHeader label={groupId} />

              {groupEntries.map((entry) => {
                return (
                  <EntryComponent
                    key={entry.id}
                    entry={entry}
                    selectedIndex={selectedIndex}
                    selectItem={selectItem}
                  />
                );
              })}
            </SectionWrapper>
          </Fragment>
        );
      })}
    </div>
  );
}

function clampValue(n: number, minMax: [number, number]) {
  return Math.max(Math.min(n, minMax[1]), minMax[0]);
}

const suggestionDropdownStyles = css`
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
`;

const SectionWrapper: ComponentType<{}> = (props) => {
  return <div className="flex flex-col">{props.children}</div>;
};

const SectionHeader: ComponentType<{
  label: string;
}> = (props) => {
  return (
    <h4 className="text-xs uppercase text-slate-9 font-medium mx-4 my-2">
      {props.label}
    </h4>
  );
};

export const BaseEntry: ComponentType<{
  item: { id: string };
  isSelected: boolean;
  isDisabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}> = (props) => {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!props.isSelected) return;
    ref.current?.scrollIntoView({ block: "nearest" });
  }, [props.isSelected]);

  return (
    <button
      ref={ref}
      type="button"
      className={cx(
        "flex items-center text-left mx-1 pr-3 py-1 rounded",
        "relative text-sm",
        props.isSelected ? "bg-slate-5" : "bg-transparent",
        props.isDisabled && "text-slate-9",
      )}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

const noResultsMsg = (
  <div
    className={cx(
      "bg-white rounded overflow-hidden",
      `w-[330px] pb-3 max-h-[300px] overflow-y-auto`,
      suggestionDropdownStyles,
    )}
  >
    <SectionWrapper>
      <SectionHeader label="Filters" />

      <div
        className={cx(
          "flex items-center text-left mx-4 py-1 rounded",
          "relative text-sm bg-transparent",
        )}
      >
        No results
      </div>
    </SectionWrapper>
  </div>
);
