/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  Ref,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { css, cx } from "@emotion/css";
import { slate } from "@radix-ui/colors";
// https://www.radix-ui.com/docs/primitives/components/select
import * as RSelect from "@radix-ui/react-select";
import type { IOption } from "./AutocompleteSelect";
import { createUseContextHook } from "~/utils/createUseContextHook";
import useConstant from "use-constant";
import { IFormControl } from "solid-forms-react";
import { FaChevronDown } from "react-icons/fa";
import uid from "@libs/utils/uid";
import { useControlState } from "~/form-components/utils";

interface ISelectContext<O extends IOption> {
  /**
   * Like the native `<select>` element, the `@radix-ui/react-select`
   * component only accepts `string` values. We use react context with
   * an option store to support any arbitrary value.
   */
  optionStore: Map<string, O>;
}

const SelectContext = createContext<ISelectContext<IOption> | null>(null);

const useSelectContext = createUseContextHook(SelectContext, "SelectContext");

export type ISelectProps<O extends IOption> = PropsWithChildren<{
  control: IFormControl<O>;
  onFocusInput?: () => void;
}>;

/**
 * To ensure that options are always rendered with the same ID,
 * we use a WeakMap to associate options with IDs
 */
const optionIdMap = new WeakMap<object, string>();

function _Select(
  props: ISelectProps<IOption>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const contentRef = useRef<HTMLDivElement>(null);

  const context = useConstant<ISelectContext<IOption>>(() => ({
    optionStore: new Map(),
  }));

  const selectedOptionId = useControlState(() => {
    if (!props.control.value) return;
    if (!optionIdMap.has(props.control.value)) {
      optionIdMap.set(props.control.value, uid());
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return optionIdMap.get(props.control.value)!;
  }, [props.control]);

  const selectedOption = useControlState(
    () => props.control.value,
    [props.control],
  );

  const onValueChange = useMemo(() => {
    return (optionId: string) => {
      const option = context.optionStore.get(optionId);

      if (!option) {
        console.error(`Unknown select optionId "${optionId}"`);
        return;
      }

      props.control.setValue(option);
    };
  }, [props.control, context.optionStore]);

  const isDisabled = useControlState(
    () => props.control.isDisabled,
    [props.control],
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <RSelect.Root
      open={isOpen}
      value={selectedOptionId}
      onValueChange={onValueChange}
    >
      <RSelect.Trigger
        ref={ref}
        className={cx(triggerCSS, isOpen && "is-open")}
        onFocus={() => {
          setIsOpen(true);
        }}
        onClick={() => {
          setIsOpen(true);
        }}
        tabIndex={0}
        disabled={isDisabled}
      >
        <RSelect.Value aria-label={selectedOption.label}>
          {/* 
            The "Value" component displays the value of the select when
            the dropdown is closed.
          */}

          <div>{selectedOption.label}</div>
        </RSelect.Value>

        <RSelect.Icon className="mt-1">
          <FaChevronDown style={{ fontSize: "85%" }} />
        </RSelect.Icon>
      </RSelect.Trigger>

      {/* 
        // Note, the documentation for the radix Select component indicates
        // that the Content should be rendered inside of a Portal element.
        // However, currently the select component doesn't support being
        // rendered inside of a modal dialog (the modal dialog prevents
        // the content from receiving focus). Because of this, we
        // are not rendering the content inside of a portal and are instead
        // rendering it inside the modal dialog which allows the Content to
        // receive focus.
        */}
      <RSelect.Content
        ref={contentRef}
        className={contentCSS}
        onEscapeKeyDown={(e) => {
          e.stopPropagation();
          setIsOpen(false);
          setTimeout(() => props.onFocusInput?.(), 10);
        }}
        onCloseAutoFocus={(e) => {
          e.preventDefault();
          setIsOpen(false);
          setTimeout(() => props.onFocusInput?.(), 10);
        }}
        onKeyDown={(e) => {
          if (e.key !== "Enter") return;
          e.stopPropagation();
          setIsOpen(false);
          setTimeout(() => props.onFocusInput?.(), 10);
        }}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(false);
          setTimeout(() => props.onFocusInput?.(), 10);
        }}
      >
        {/* <RSelect.ScrollUpButton /> */}
        <SelectContext.Provider value={context}>
          <RSelect.Viewport>{props.children}</RSelect.Viewport>
        </SelectContext.Provider>
        {/* <RSelect.ScrollDownButton /> */}
      </RSelect.Content>
    </RSelect.Root>
  );
}

function SelectOption<O extends IOption>(
  props: PropsWithChildren<{ option: O }>,
) {
  const optionId = useMemo(() => {
    if (!optionIdMap.has(props.option)) {
      optionIdMap.set(props.option, uid());
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return optionIdMap.get(props.option)!;
  }, [props.option]);

  const context = useSelectContext() as ISelectContext<O>;

  // Update context.optionStore with the new option
  useEffect(() => {
    context.optionStore.set(optionId, props.option);

    return () => {
      context.optionStore.delete(optionId);
    };
  }, [optionId, context.optionStore, props.option]);

  return (
    <RSelect.Item
      value={optionId}
      disabled={props.option.isDisabled}
      className={entryCSS}
    >
      {/* 
        Note, if providing children to the SelectEntry component,
        you **_must_** use the `ItemText` component to render the
        option text.
      */}

      {props.children || (
        <RSelect.ItemText>{props.option.label}</RSelect.ItemText>
      )}
    </RSelect.Item>
  );
}

const triggerCSS = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 85%;
  padding: 0 1rem;
  border-radius: 0.25rem;

  &:focus,
  &.is-open {
    outline: none;
    background-color: ${slate.slate5};
  }

  & + div {
    left: unset !important;
    bottom: unset !important;
    top: 2.5rem;
    right: 2.5rem;
  }
`;

const contentCSS = css`
  overflow: hidden;
  color: white;
  padding: 3px;
  background-color: ${slate.slate11};
  border-radius: 0.25rem;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  z-index: 1;
`;

const entryCSS = css`
  border: 1px solid transparent;
  border-radius: 3px;
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  outline: none;

  &:hover {
    cursor: pointer;
  }

  &[data-disabled] {
    pointer-events: none;
  }

  &[data-highlighted] {
    border-color: white;
  },
`;

/**
 * START EXPORTS
 */

const Select = forwardRef(_Select) as unknown as (<O extends IOption>(
  props: ISelectProps<O> & {
    ref?: Ref<HTMLButtonElement>;
  },
) => ReactElement) & {
  /**
   * Note, if providing children to the Option component,
   * you **_must_** use the `OptionText` component to render the
   * option text.
   */
  Option: typeof SelectOption;
  OptionText: typeof RSelect.ItemText;
};

Select.Option = SelectOption;
Select.OptionText = RSelect.ItemText;

export { Select, type IOption };

/**
 * END EXPORTS
 */
