import { IPostDoc } from "@libs/firestore-models";
import { isEqual } from "@libs/utils/isEqual";
import { useEffect, useState } from "react";
import {
  deleteDraft,
  createNewDraft,
  mapRecipientOptionToDraftRecipient,
} from "~/services/draft.service";
import { pick, unionBy } from "lodash-es";
import {
  createFormControl,
  createFormGroup,
  IFormControl,
  IFormGroup,
  useControl,
} from "solid-forms-react";
import { observable, useControlState } from "~/form-components/utils";
import {
  ASSERT_CURRENT_USER$,
  catchNoCurrentUserError,
  getAndAssertCurrentUser,
} from "~/services/user.service";
import { docRef, waitForCacheToContainDoc } from "~/firestore.service";
import {
  combineLatest,
  delay,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  from,
  map,
  Observable,
  pairwise,
  shareReplay,
  skip,
  switchMap,
  take,
} from "rxjs";
import { isNonNullable } from "@libs/utils/predicates";
import {
  IThreadDocWithPermittedChannels,
  observeThread,
} from "~/services/post.service";
import { observeUsersWhoAreSubscribedToThread } from "~/services/subscription.service";
import { USER_ORG_SHARED_CHANNELS$ } from "~/services/channels.service";
import {
  IChannelRecipientOption,
  IEmailRecipientOption,
  IRecipientOption,
  IUserRecipientOption,
  ThreadRecipients,
} from "~/form-components/ThreadRecipients";
import { startWith } from "@libs/utils/rxjs-operators";
import { oneLine } from "common-tags";
import uid from "@libs/utils/uid";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import {
  IComposeMessageForm,
  IComposeMessageFormValue,
} from "~/components/ComposeMessageContext";
import {
  useAddNewChannelMentionsAsRecipients,
  useAddNewUserMentionsAsRecipients,
} from "~/components/ComposeReplyBase";
import { getLastPostEntry } from "./utils";
import { updateSearchParams } from "~/services/navigate.service";
import { IComposePostReplyProps } from "./ComposeReply";
import { cx } from "@emotion/css";
import { UnreachableCaseError } from "@libs/utils/errors";
import { EmailAddress } from "@libs/utils/email-rfc";
import { getEmailReplyRecipientsFromLastPost } from "@libs/firestore-models/utils";

/* -------------------------------------------------------------------------------------------------
 * ReplyDraftHeader
 * -----------------------------------------------------------------------------------------------*/

type THeaderRecipientsControl = IFormGroup<{
  to: IFormControl<IRecipientOption[]>;
  cc: IFormControl<IRecipientOption[]>;
  bcc: IFormControl<IEmailRecipientOption[]>;
}>;

export type IThreadRecipients = {
  to: IRecipientOption[];
  cc: IRecipientOption[];
  bcc: IEmailRecipientOption[];
};

export function ReplyDraftHeader(props: {
  listRef: IComposePostReplyProps["listRef"];
  thread: IThreadDocWithPermittedChannels;
  control: IComposeMessageForm;
  threadRecipients$: Observable<IThreadRecipients>;
  isEditingExistingPost?: boolean;
}) {
  if (props.thread.type === "EMAIL_SECRET") {
    throw new Error("ReplyDraftHeader doesn't support EMAIL_SECRET");
  }

  const headerRecipientsControl = useControl(() =>
    createFormGroup({
      to: createFormControl<IRecipientOption[]>([]),
      cc: createFormControl<IRecipientOption[]>([]),
      bcc: createFormControl<IEmailRecipientOption[]>([]),
    }),
  );

  const isSyncInitialized =
    useSyncThreadRecipientsToHeaderControlAndPushHeaderUpdatesToDraftControl({
      threadId: props.thread.id,
      listRef: props.listRef,
      draftControl: props.control,
      headerRecipientsControl,
      isEditingExistingPost: !!props.isEditingExistingPost,
      threadRecipients$: props.threadRecipients$,
    });

  usePushDraftControlRecipientUpdatesToHeaderControl({
    threadId: props.thread.id,
    draftControl: props.control,
    headerRecipientsControl,
    threadRecipients$: props.threadRecipients$,
    isSyncInitialized,
  });

  useAddNewChannelMentionsAsRecipients(
    props.control.controls.body.controls.channelMentions,
    headerRecipientsControl.controls.to,
    isSyncInitialized,
  );

  useAddNewUserMentionsAsRecipients(
    props.control.controls.body.controls.userMentions,
    headerRecipientsControl.controls.to,
    isSyncInitialized,
  );

  const isToInvalid = useControlState(
    () => !props.control.controls.recipients.controls.to.isValid,
    [props.control],
  );

  const isToTouched = useControlState(
    () => props.control.controls.recipients.controls.to.isTouched,
    [props.control],
  );

  return (
    <div className="flex flex-col mx-4 py-4 sm-w:mx-8 pb-0">
      <div className="PostSender flex pb-4">
        <strong>
          <span className="text-green-9">
            {props.isEditingExistingPost ? "Edit post" : "Draft"}
          </span>
        </strong>
      </div>

      <div className="flex items-baseline pb-2 border-b border-slate-6">
        <label
          htmlFor="to"
          className={cx(
            "mr-2 font-medium",
            isToTouched && isToInvalid && "text-red-9",
          )}
        >
          To
        </label>

        <ThreadRecipients
          name="to"
          control={headerRecipientsControl.controls.to}
          threadType={props.thread.type}
          isThreadPrivate={props.thread.visibility === "private"}
          wrapperClassName="flex-1"
        />
      </div>

      {props.thread.type === "EMAIL" && (
        <div className="flex items-baseline py-2 border-b border-slate-6">
          <label
            htmlFor="cc"
            className={cx(
              "mr-2 font-medium",
              isToTouched && isToInvalid && "text-red-9",
            )}
          >
            Cc
          </label>

          <ThreadRecipients
            name="cc"
            control={headerRecipientsControl.controls.cc}
            threadType={props.thread.type}
            isThreadPrivate={props.thread.visibility === "private"}
            wrapperClassName="flex-1"
          />
        </div>
      )}
    </div>
  );
}

/* -------------------------------------------------------------------------------------------------
 * useSyncThreadRecipientsToHeaderControlAndPushHeaderUpdatesToDraftControl
 * -----------------------------------------------------------------------------------------------*/

function useSyncThreadRecipientsToHeaderControlAndPushHeaderUpdatesToDraftControl(args: {
  threadId: string;
  listRef: IComposePostReplyProps["listRef"];
  isEditingExistingPost: boolean;
  draftControl: IComposeMessageForm;
  headerRecipientsControl: THeaderRecipientsControl;
  threadRecipients$: Observable<IThreadRecipients>;
}) {
  const {
    threadId,
    listRef,
    draftControl,
    isEditingExistingPost,
    headerRecipientsControl,
    threadRecipients$,
  } = args;

  // We want to track is this hook has been initialized or not because the
  // process of initialization can cause a race condition to overwrite any
  // others values which are being written to the headerRecipientsControl
  // at the same time (e.g. initialization can overwrite changes made by
  // "useAddNewChannelMentionsAsRecipients" and "useAddNewUserMentionsAsRecipients"
  // hooks if those hooks make changes during initialization).
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initializeHeaderRecipientsControlPromise = (async () => {
      const threadRecipients = await firstValueFrom(threadRecipients$);

      headerRecipientsControl.setValue({
        to: unionBy(
          threadRecipients.to,
          draftControl.rawValue.recipients.to,
          (recipient) => recipient.value,
        ),
        cc: unionBy(
          threadRecipients.cc,
          draftControl.rawValue.recipients.cc,
          (recipient) => recipient.value,
        ),
        bcc: unionBy(
          threadRecipients.bcc,
          draftControl.rawValue.recipients.bcc,
          (recipient) => recipient.value,
        ),
      });
    })();

    const obs = from(initializeHeaderRecipientsControlPromise).pipe(
      switchMap(() =>
        combineLatest([
          threadRecipients$,
          observable(() => draftControl.rawValue.visibility),
          observable(() => headerRecipientsControl.rawValue).pipe(
            // we start with the current value because pairwise() needs two
            // values to be emitted for it to start emitting values itself
            startWith(() => headerRecipientsControl.rawValue),
            pairwise(),
          ),
        ]),
      ),
    );

    const sub = obs.subscribe(
      ([
        currentThreadRecipients,
        visibility,
        // Note that if currentThreadRecipients emits multiple times in a row,
        // then previousHeaderRecipients and currentHeaderRecipients will
        // be the same for each of those emissions (i.e. previousHeaderRecipients
        // won't be equal to the value of header recipients the last time this
        // function was run).
        [previousHeaderRecipients, currentHeaderRecipients],
      ]) => {
        const shouldWeBranch = shouldWeCreateNewBranchedThread({
          currentThreadRecipients,
          previousHeaderRecipients,
          currentHeaderRecipients,
          isEditingExistingPost,
        });

        if (shouldWeBranch) {
          const lastPostEntry = getLastPostEntry(listRef.current?.entries);

          if (lastPostEntry) {
            convertCurrentDraftToNewBranchedThreadDraft({
              recipients: currentHeaderRecipients,
              lastPost: lastPostEntry,
              control: draftControl,
            });

            return;
          }
        }

        const newDraftRecipients = {
          to: [] as IRecipientOption[],
          cc: [] as IRecipientOption[],
          bcc: [] as IEmailRecipientOption[],
        };

        const isPrivate = visibility === "private";

        currentHeaderRecipients.to.forEach((option) => {
          if (
            isPrivate &&
            option.type === "channel" &&
            option.classification === "public"
          ) {
            return;
          } else if (
            !isPrivate &&
            option.type === "channel" &&
            option.classification === "private"
          ) {
            return;
          }

          const isRecipientAThreadRecipient = currentThreadRecipients.to.some(
            (r) => r.value === option.value,
          );

          if (isRecipientAThreadRecipient) return;

          newDraftRecipients.to.push({ ...option, emphasize: true });
        });

        currentHeaderRecipients.cc.forEach((option) => {
          const isRecipientAThreadRecipient = currentThreadRecipients.cc.some(
            (r) => r.value === option.value,
          );

          if (isRecipientAThreadRecipient) return;

          newDraftRecipients.cc.push({ ...option, emphasize: true });
        });

        currentHeaderRecipients.bcc.forEach((option) => {
          const isRecipientAThreadRecipient = currentThreadRecipients.bcc.some(
            (r) => r.value === option.value,
          );

          if (isRecipientAThreadRecipient) return;

          newDraftRecipients.bcc.push({ ...option, emphasize: true });
        });

        draftControl.patchValue({
          recipients: newDraftRecipients,
        });

        headerRecipientsControl.setValue({
          to: [...currentThreadRecipients.to, ...newDraftRecipients.to],
          cc: [...currentThreadRecipients.cc, ...newDraftRecipients.cc],
          bcc: [...currentThreadRecipients.bcc, ...newDraftRecipients.bcc],
        });
      },
    );

    sub.add(
      obs
        .pipe(
          take(1),
          // I'm not sure why, but setting this value synchronously causes
          //  a bug where adding a new user mention to
          // a message doesn't update the reply draft header. In this scenerio,
          // `observable(() => props.control.controls.body.controls.userMentions)`
          // doesn't emit changes.
          delay(0),
        )
        .subscribe(() => setIsInitialized(true)),
    );

    return () => sub.unsubscribe();
  }, [
    threadId,
    draftControl,
    listRef,
    isEditingExistingPost,
    headerRecipientsControl,
    threadRecipients$,
  ]);

  return isInitialized;
}

/* -------------------------------------------------------------------------------------------------
 * observeThreadRecipients
 * -----------------------------------------------------------------------------------------------*/

export function observeThreadRecipients(
  threadId: string,
): Observable<IThreadRecipients> {
  const thread$ = observeThread(threadId).pipe(
    filter(isNonNullable),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  const threadProps$ = thread$.pipe(
    map((thread) =>
      pick(
        thread,
        "id",
        "type",
        "lastPost",
        "permittedChannelIds",
        "participatingUserIds",
      ),
    ),
    distinctUntilChanged(isEqual),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  const recipientOptions$ = combineLatest([
    thread$.pipe(
      map((t) => pick(t, "visibility", "type")),
      distinctUntilChanged(),
    ),
    from(import("~/form-components/ThreadRecipients")),
  ]).pipe(
    switchMap(([{ visibility, type }, { observeRecipientOptions }]) =>
      observeRecipientOptions({
        threadType: type,
        isThreadPrivate: visibility === "private",
      }),
    ),
  );

  const sharedChannelRecipientOptions$ = USER_ORG_SHARED_CHANNELS$.pipe(
    map((sharedChannels) =>
      sharedChannels.map<IChannelRecipientOption>((c) => ({
        type: "channel",
        value: c.id,
        label: c.name,
        classification: c.classification,
        channelGroupNames: c.__local.knownChannelGroups.map((g) => g.name),
        sharedChannel: {
          organizationName:
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            c.__local.knownChannelGroups[0]!.organizationName,
          showWalkthrough: false,
        },
        isFixed: true,
      })),
    ),
  );

  const groupedSubscriptions$ = threadProps$.pipe(
    switchMap((thread) =>
      observeUsersWhoAreSubscribedToThread({
        thread,
        onlyCountTheseSubscriptionPreferences: ["all"],
        dontIncludeCurrentUser: true,
      }),
    ),
  );

  const currentUserLowercaseEmail$ = ASSERT_CURRENT_USER$.pipe(
    map((u) => u.lowercaseEmail),
    distinctUntilChanged(),
  );

  return combineLatest([
    currentUserLowercaseEmail$,
    threadProps$,
    recipientOptions$,
    groupedSubscriptions$,
    sharedChannelRecipientOptions$,
  ]).pipe(
    map(
      ([
        currentUserLowercaseEmail,
        thread,
        recipientOptions,
        groupedSubscriptions,
        sharedChannelRecipientOptions,
      ]) => {
        switch (thread.lastPost.type) {
          case "COMMS": {
            const channelRecipientOptions = thread.permittedChannelIds
              .map((id) => {
                return (
                  recipientOptions.find((o) => o.value === id) ||
                  sharedChannelRecipientOptions.find((o) => o.value === id)
                );
              })
              .filter(isNonNullable);

            const userRecipientOptions = groupedSubscriptions.knownSubscribers
              .map(({ id }) =>
                recipientOptions.find((option) => option.value === id),
              )
              .filter(isNonNullable);

            return {
              to: [...channelRecipientOptions, ...userRecipientOptions],
              cc: [],
              bcc: [],
            };
          }
          case "EMAIL": {
            const channelRecipientOptions = thread.permittedChannelIds
              .map((id) => {
                return (
                  recipientOptions.find((o) => o.value === id) ||
                  sharedChannelRecipientOptions.find((o) => o.value === id)
                );
              })
              .filter(isNonNullable);

            const mapEmailToRecipientOption = (
              email: EmailAddress,
            ): IEmailRecipientOption => {
              if (email.addresses) {
                throw new Error("observeThreadRecipients unexpected email");
              }

              const option = recipientOptions.find(
                (option): option is IUserRecipientOption =>
                  option.type === "user" && option.email === email.address,
              );

              if (option) {
                return {
                  type: "email",
                  label: option.label,
                  email: option.email,
                  value: option.email,
                };
              }

              return {
                type: "email",
                label: email.label || email.address,
                email: email.address,
                value: email.address,
              };
            };

            const to: IRecipientOption[] = channelRecipientOptions;
            const cc: IRecipientOption[] = [];

            if (thread.lastPost) {
              const r = getEmailReplyRecipientsFromLastPost(
                thread.lastPost,
                currentUserLowercaseEmail,
              );

              to.push(...r.to.map(mapEmailToRecipientOption));
              cc.push(...r.cc.map(mapEmailToRecipientOption));
            }

            return {
              to,
              cc,
              bcc: [],
            };
          }
          default: {
            throw new UnreachableCaseError(thread.lastPost);
          }
        }
      },
    ),
    catchNoCurrentUserError(
      (): IThreadRecipients => ({
        to: [],
        cc: [],
        bcc: [],
      }),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );
}

/* -----------------------------------------------------------------------------------------------*/

function shouldWeCreateNewBranchedThread(args: {
  currentThreadRecipients: IThreadRecipients;
  previousHeaderRecipients: IThreadRecipients;
  currentHeaderRecipients: IThreadRecipients;
  isEditingExistingPost: boolean;
}) {
  const allPreviousRecipients = [
    ...args.previousHeaderRecipients.to,
    ...args.previousHeaderRecipients.cc,
    ...args.previousHeaderRecipients.bcc,
  ];

  const allCurrentRecipients = [
    ...args.currentHeaderRecipients.to,
    ...args.currentHeaderRecipients.cc,
    ...args.currentHeaderRecipients.bcc,
  ];

  const wasRecipientRemoved =
    allPreviousRecipients.length > allCurrentRecipients.length;

  if (!wasRecipientRemoved) return false;

  const removedRecipient = allPreviousRecipients.find(
    (prev) => !allCurrentRecipients.some((curr) => curr.value === prev.value),
  );

  const allThreadRecipients = [
    ...args.currentThreadRecipients.to,
    ...args.currentThreadRecipients.cc,
    ...args.currentThreadRecipients.bcc,
  ];

  const attemptedToRemoveThreadRecipient =
    removedRecipient &&
    allThreadRecipients.some((r) => r.value === removedRecipient.value);

  if (!attemptedToRemoveThreadRecipient) return false;

  if (args.isEditingExistingPost) {
    alert(oneLine`
      You can't remove thread participants when editing a sent post
      (though you can add new participants when editing a post).
    `);

    return false;
  }

  const shouldBranchThread = confirm(oneLine`
    You are attempting to remove a current participant in this thread.
    All responses in a thread must go to all participants, but you can
    create a new thread branching off of this one with different 
    participants. Would you like to do this now?
  `);

  return shouldBranchThread;
}

/* -------------------------------------------------------------------------------------------------
 * convertCurrentDraftToNewBranchedThreadDraft
 * -----------------------------------------------------------------------------------------------*/

export async function convertCurrentDraftToNewBranchedThreadDraft(args: {
  recipients: IComposeMessageFormValue["recipients"];
  lastPost: IPostDoc;
  control: IComposeMessageForm;
}) {
  const { recipients, lastPost, control } = args;

  if (lastPost.type === "EMAIL_SECRET") {
    throw new Error(
      "convertCurrentDraftToNewBranchedThreadDraft: not supported",
    );
  }

  const currentUser = getAndAssertCurrentUser();
  const newDraftId = uid();
  const draftForm = control.rawValue;

  const createNewBranchedDraftPromise = createNewDraft({
    type: lastPost.type,
    postId: newDraftId,
    to: recipients.to.map((r) =>
      mapRecipientOptionToDraftRecipient(r, lastPost.type),
    ),
    cc: recipients.cc.map((r) =>
      mapRecipientOptionToDraftRecipient(r, lastPost.type),
    ),
    bcc: recipients.bcc.map((r) =>
      mapRecipientOptionToDraftRecipient(r, lastPost.type),
    ),
    subject: `Branch: ${lastPost.subject}`,
    bodyHTML: draftForm.body.content,
    channelMentions: draftForm.body.channelMentions,
    userMentions: draftForm.body.userMentions,
    visibility: draftForm.visibility,
    branchedFrom: {
      threadId: lastPost.threadId,
      postId: lastPost.id,
      postSentAt: lastPost.sentAt,
      postScheduledToBeSentAt: lastPost.scheduledToBeSentAt,
    },
  });

  await withPendingRequestBar(
    waitForCacheToContainDoc(
      docRef("users", currentUser.id, "unsafeDrafts", newDraftId),
      createNewBranchedDraftPromise,
    ),
  );

  // When the compose new thread dialog is closed we want to refocus the
  // correct post.
  updateSearchParams((searchParams) => searchParams.set("post", lastPost.id), {
    replace: true,
  });

  openComposeNewThreadDialog(newDraftId);

  deleteDraft({ postId: draftForm.postId });
}

/* -------------------------------------------------------------------------------------------------
 * usePushDraftControlRecipientUpdatesToHeaderControl
 * -----------------------------------------------------------------------------------------------*/

function usePushDraftControlRecipientUpdatesToHeaderControl(args: {
  threadId: string;
  draftControl: IComposeMessageForm;
  headerRecipientsControl: THeaderRecipientsControl;
  threadRecipients$: Observable<IThreadRecipients>;
  isSyncInitialized: boolean;
}) {
  const {
    threadId,
    draftControl,
    headerRecipientsControl,
    threadRecipients$,
    isSyncInitialized,
  } = args;

  useEffect(() => {
    if (!isSyncInitialized) return;

    const sub = combineLatest([
      threadRecipients$,
      observable(() => draftControl.rawValue.recipients),
    ])
      .pipe(
        // We initialize the headerRecipientsControl in the
        // useSyncThreadRecipientsToHeaderControlAndPushHeaderUpdatesToDraftControl
        // so doing so again here would be redundant.
        skip(1),
      )
      .subscribe(([threadRecipients, additionalDraftRecipients]) => {
        headerRecipientsControl.setValue({
          to: unionBy(
            threadRecipients.to,
            additionalDraftRecipients.to,
            (recipient) => recipient.value,
          ),
          cc: unionBy(
            threadRecipients.cc,
            additionalDraftRecipients.cc,
            (recipient) => recipient.value,
          ),
          bcc: unionBy(
            threadRecipients.bcc,
            additionalDraftRecipients.bcc,
            (recipient) => recipient.value,
          ),
        });
      });

    return () => sub.unsubscribe();
  }, [
    threadId,
    draftControl,
    headerRecipientsControl,
    threadRecipients$,
    isSyncInitialized,
  ]);
}
