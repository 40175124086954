import { analytics, auth, getTypedCallableFn } from "~/firebase";
import { user } from "rxfire/auth";
import {
  switchMap,
  of,
  shareReplay,
  Observable,
  distinctUntilChanged,
  map,
  pipe,
} from "rxjs";
import { docData } from "~/utils/rxFireWrappers";
import { docRef } from "~/firestore.service";
import { useObservable } from "~/utils/useObservable";
import { IUserDoc } from "@libs/firestore-models";
import { forceOfflineMode } from "./network-connection.service";
import { isEqual } from "@libs/utils/isEqual";
import { setUserId } from "firebase/analytics";
import {
  assertPredicate,
  catchFailedPredicateError,
} from "@libs/utils/rxjs-operators";
import { isNonNullable } from "@libs/utils/predicates";

// export const login = getTypedCallableFn("login");

export const FIREBASE_USER$ = user(auth).pipe(shareReplay(1));

export const CURRENT_USER$ = FIREBASE_USER$.pipe(
  switchMap(async (user) => {
    return (await user?.getIdTokenResult())?.claims.hasura ? user : null;
  }),
  switchMap((user) =>
    !user
      ? of(null)
      : docData(docRef("users", user.uid)).pipe(
          map((userDoc) => (userDoc?.retired ? null : userDoc)),
        ),
  ),
  distinctUntilChanged(isEqual),
  shareReplay(1),
);

let currentUser: IUserDoc | null = null;

// we maintain one subscription to the current user state
// for speedier retrieval
CURRENT_USER$.subscribe((user) => {
  currentUser = user;

  if (!user) {
    // The setUserId function is typed incorrectly. It should accept string or null.
    // Passing `null` is the intended way to "unset" the userId prop in Google
    // analytics.
    setUserId(analytics, null as unknown as string);
    // If the user logs out we disable offline mode if it
    // was enabled.
    forceOfflineMode(false);
  } else {
    setUserId(analytics, user.id);
  }
});

export const ASSERT_CURRENT_USER$ = CURRENT_USER$.pipe(
  assertPredicate("ASSERT_CURRENT_USER", isNonNullable),
);

export const ASSERT_CURRENT_USER_ID$ = ASSERT_CURRENT_USER$.pipe(
  map((user) => user.id),
  distinctUntilChanged(),
);

/**
 * The `catchNoCurrentUserError` is intended to be used in
 * conjunction with the `ASSERT_CURRENT_USER$` or
 * `ASSERT_CURRENT_USER_ID$` observables.
 * Together, they allow us to subscribe to the current user
 * and pretend like it will always be non-null in observables
 * that should only be called while the user is logged in.
 *
 * See https://github.com/ReactiveX/rxjs/discussions/6992#discussioncomment-2936961
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function catchNoCurrentUserError<I extends any[]>(
  defaultValue: () => never[],
): (source: Observable<I>) => Observable<I>;
export function catchNoCurrentUserError<I, T>(
  defaultValue: () => T,
): (source: Observable<I>) => Observable<I | T>;
export function catchNoCurrentUserError<I, T>(
  defaultValue: () => T,
): (source: Observable<I>) => Observable<I | T> {
  return pipe(catchFailedPredicateError("ASSERT_CURRENT_USER", defaultValue));
}

export function useCurrentUser() {
  return useObservable(() => CURRENT_USER$, {
    initialValue: null,
  });
}

export const createUser = getTypedCallableFn("usercreate");
export const updateCurrentUser = getTypedCallableFn("userupdate");

export function signout() {
  return auth.signOut();
}

export function getCurrentUser() {
  return currentUser;
}

export function getAndAssertCurrentUser() {
  if (!currentUser) {
    throw new Error("Expected current user to be signed in but they are not.");
  }

  return currentUser;
}
