import {
  INewPostNotificationDoc,
  IPostDoc,
  IThreadDoc,
} from "@libs/firestore-models";
import { ComponentType, memo } from "react";

import { IListOnEntryActionEvent, List } from "../list";
import { isEqual } from "@libs/utils/isEqual";
import { navigateToEntry } from "./ContentList";

import {
  entryCSSClasses,
  EntryTimestamp,
  PrivateEntryIcon,
  Recipients,
  StarredEntryIcon,
  Summary,
  useShowChannelLabels,
} from "./layout";
import { observeInboxNotification } from "~/services/inbox.service";
import { NotificationTimestamp } from "./NotificationEntry";
import { useChannels } from "~/services/channels.service";
import {
  castToNonNullablePredicate,
  isNonNullable,
} from "@libs/utils/predicates";
import { observeThread } from "~/services/post.service";
import { useObservable } from "~/utils/useObservable";
import { distinctUntilChanged, map, of } from "rxjs";
import { pick } from "lodash-es";
import { ChannelLabels, LabelChip } from "../ChannelLabels";
import { isModKeyActive } from "~/services/command.service";
import { openLinkInNewTabOrWindow } from "~/utils/navigation-helpers";
import { INavigateServiceOptions } from "~/services/navigate.service";
import { Avatar } from "../Avatar";
import { getPostSenderName } from "@libs/firestore-models/utils";
import { parseEmailAddress } from "@libs/utils/parseEmailAddress";
import { UnreachableCaseError } from "@libs/utils/errors";

export function onPostSelectNavigateToPost(
  { entry, event }: IListOnEntryActionEvent<IPostDoc>,
  options?: INavigateServiceOptions,
) {
  const url = `/threads/${entry.threadId}?post=${entry.id}`;

  if (isModKeyActive(event)) {
    openLinkInNewTabOrWindow(url);
  } else {
    navigateToEntry(entry.id, url, options);
  }
}

export function onPostSelectNavigateToThread({
  entry,
  event,
}: IListOnEntryActionEvent<IPostDoc>) {
  const url = `/threads/${entry.threadId}`;

  if (isModKeyActive(event)) {
    openLinkInNewTabOrWindow(url);
  } else {
    navigateToEntry(entry.id, url);
  }
}

export const PostEntry: ComponentType<{
  post: IPostDoc;
  thread?: IThreadDoc;
  showRecipientNames?: boolean;
  relativeOrder: number;
}> = memo(({ post, thread, showRecipientNames = false, relativeOrder }) => {
  const knownRecipientChannels = useChannels({
    mapResults: (channels) => {
      const knownRecipientChannels = post.recipientChannelIds
        .map((channelId) => channels.find((c) => c.id === channelId))
        .filter(
          castToNonNullablePredicate(
            (c) => !!c && !c.isOrganizationSharedChannel,
          ),
        );

      // We only grab the properties we need to avoid unnecessary rerenders
      return knownRecipientChannels.map((c) => ({ id: c.id, name: c.name }));
    },
    deps: [post.recipientChannelIds],
  });

  const showChannelLabels = useShowChannelLabels();

  const recipientNames = (channels: Array<{ id: string; name: string }>) => {
    const userNames = Object.values(post.recipientUsers).map(
      (r) => `@${r.name}`,
    );

    const channelNames = channels.map((c) => `#${c.name}`);

    switch (post.type) {
      case "COMMS": {
        return [...userNames, ...channelNames].join(", ");
      }
      case "EMAIL":
      case "EMAIL_SECRET": {
        const toEmails = post.to
          .map((e) => parseEmailAddress(e).label)
          .filter(isNonNullable);
        const ccEmails = post.to
          .map((e) => parseEmailAddress(e).label)
          .filter(isNonNullable);

        return [...userNames, ...toEmails, ...ccEmails, ...channelNames].join(
          ", ",
        );
      }
      default: {
        throw new UnreachableCaseError(post);
      }
    }
  };

  const notification = useObservable(
    () => {
      return observeInboxNotification<INewPostNotificationDoc>(
        post.threadId,
      ).pipe(
        map(
          (n) =>
            n &&
            pick(
              n,
              "type",
              "postType",
              "triagedUntil",
              "sentAt",
              "isStarred",
              "doneLastModifiedBy",
            ),
        ),
        distinctUntilChanged(isEqual),
      );
    },
    { deps: [post.threadId] },
  );

  const isPrivateThread = useObservable(
    () => {
      if (thread) {
        return of(thread.visibility === "private");
      }

      return observeThread(post.threadId).pipe(
        map((thread) => thread?.visibility === "private"),
        distinctUntilChanged(),
      );
    },
    { deps: [post.threadId, thread?.visibility] },
  );

  if (isPrivateThread === undefined) {
    return null;
  }

  const senderName = getPostSenderName(post) || "unknown";

  return (
    <List.Entry<IPostDoc>
      id={post.id}
      data={post}
      relativeOrder={relativeOrder}
    >
      <div className={entryCSSClasses}>
        <Avatar
          label={senderName}
          photoURL={post.creatorPhotoURL}
          width="30px"
        />

        <Recipients
          nonTruncatedSuffix={
            <>
              {isPrivateThread && <PrivateEntryIcon />}
              {notification?.isStarred && <StarredEntryIcon />}
            </>
          }
        >
          {showRecipientNames
            ? recipientNames(knownRecipientChannels)
            : senderName}
        </Recipients>

        <Summary
          subject={post.subject}
          reply={!post.isFirstPostInThread}
          details={isPrivateThread ? "private message" : post.bodyText}
        />

        {showChannelLabels && (
          <ChannelLabels channels={knownRecipientChannels} />
        )}

        {post.type === "EMAIL" && (
          <LabelChip tooltip="This is an email thread">#Email</LabelChip>
        )}

        {notification?.triagedUntil ? (
          <NotificationTimestamp notification={notification} />
        ) : (
          <EntryTimestamp datetime={post.sentAt} />
        )}
      </div>
    </List.Entry>
  );
}, isEqual);
