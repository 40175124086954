import { isEqual } from "@libs/utils/isEqual";
import { startWith } from "@libs/utils/rxjs-operators";
import {
  ComponentType,
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  BehaviorSubject,
  combineLatest,
  delay,
  distinctUntilChanged,
  filter,
  map,
  merge,
  take,
} from "rxjs";
import useConstant from "use-constant";
import { KBarState } from "~/dialogs/kbar";
import { useSidebarLayoutContext } from "~/page-layouts/sidebar-layout";
import { useRegisterCommands } from "~/services/command.service";
import {
  navigateService,
  getCurrentRouterLocation,
  INavigateServiceOptions,
  getLocationState,
} from "~/services/navigate.service";
import { setScrollTop } from "~/utils/dom-helpers";
import { useComposedRefs } from "~/utils/useComposedRefs";
import { useLocationState } from "~/utils/useLocationState";
import { useObservable } from "~/utils/useObservable";
import { IListProps, IListRef, List, useListScrollboxContext } from "../list";

export interface IContactListProps<T extends { id: string }> {
  initiallyFocusEntryId?: string;
  /**
   * Called when a user clicks on a list entry or when they focus
   * a list entry and press the "Enter" key.
   */
  onEntryAction?: IListProps<T>["onEntryAction"];
  onEntryFocused?: (entry: T | null) => void;
  /**
   * Default is to scroll the scrollbox element up if possible.
   * Pass `null` to turn on overflow up wrapping.
   */
  onArrowUpOverflow?: (e: KeyboardEvent) => void;
  /**
   * Default is to scroll the scrollbox element down if possible.
   * Pass `null` to turn on overflow down wrapping.
   */
  onArrowDownOverflow?: (e: KeyboardEvent) => void;
  /** default true */
  focusOnMouseOver?: boolean;
  className?: string;
  children?: ReactNode;
  autoFocus?: boolean;
}

export const EmptyListMessage: ComponentType<{ text?: string }> = (props) => {
  return (
    <div className="w-full pt-6 px-10 flex flex-col justify-center items-center">
      {props.text && (
        <span className="text-2xl text-slate-9">{props.text}</span>
      )}

      {props.children}
    </div>
  );
};

function _ContentList<T extends { id: string }>(
  props: IContactListProps<T>,
  forwardedRef?: ForwardedRef<IListRef<T>>,
) {
  const sidebarLayoutContext = useSidebarLayoutContext();
  const scrollboxContext = useListScrollboxContext();

  const listRef = useRef<IListRef<T>>(null);
  const listElRef = useRef<HTMLDivElement>(null);
  const storedInitiallyFocusEntryId = useLocationState<string>("ContentList");

  const initiallyFocusEntryId =
    storedInitiallyFocusEntryId ?? props.initiallyFocusEntryId;

  const [focusEntryOnMouseOver, setFocusEntryOnMouseOver] = useState(false);

  const composedRefs = useComposedRefs(forwardedRef, listRef);

  /** Scroll list container up if possible */
  const defaultOnArrowUpOverflow = useCallback(
    (e) => {
      if (!scrollboxContext.scrollboxRef.current) return;

      e.preventDefault();

      setScrollTop(
        scrollboxContext.scrollboxRef.current,
        (oldValue) => oldValue - 100,
      );
    },
    [scrollboxContext.scrollboxRef],
  );

  /** Scroll list container down if possible */
  const defaultOnArrowDownOverflow = useCallback(
    (e) => {
      if (!scrollboxContext.scrollboxRef.current) return;

      e.preventDefault();

      setScrollTop(
        scrollboxContext.scrollboxRef.current,
        (oldValue) => oldValue + 100,
      );
    },
    [scrollboxContext.scrollboxRef],
  );

  // If an ArrowUp or ArrowDown event is uncaught and
  // reaches the window in the DOM, this indicates that there
  // wasn't a list entry focused when the arrow key was pressed.
  // In this case, we should focus the ContentList.
  useRegisterCommands({
    commands: () => {
      return [
        {
          label: "Focus content list",
          hotkeys: ["ArrowUp", "ArrowDown"],
          showInKBar: false,
          callback: () => {
            listRef.current?.focus();
          },
        },
      ];
    },
  });

  // Focus the ContentList on the "Outlet" SidebarLayoutContext focus event
  useEffect(() => {
    const sub = sidebarLayoutContext.focusEvent$
      .pipe(filter((e) => e === "Outlet"))
      .subscribe(() => {
        listRef.current?.focus();
      });

    return () => sub.unsubscribe();
  }, [sidebarLayoutContext.focusEvent$]);

  // Here we disable focusing entry on mouseover until 50ms after the
  // first entry loads. We do this so that entries loading beneath the mouse
  // don't trigger entry focus events. In the future, after we have route
  // link preloading, we may be able to simplify this implementation.
  useEffect(() => {
    const focusOnMouseOver = props.focusOnMouseOver ?? true;

    if (!focusOnMouseOver) {
      setFocusEntryOnMouseOver(false);
      return;
    }

    if (!listRef.current) return;

    const sub = listRef.current.entries$
      .pipe(
        take(1),
        delay(50),
        map(() => true),
        startWith(() => false),
      )
      .subscribe(setFocusEntryOnMouseOver);

    return () => sub.unsubscribe();
  }, [props.focusOnMouseOver]);

  return (
    <List<T>
      ref={composedRefs}
      onEntryFocusIn={(args) => {
        props.onEntryFocused?.(args.entry);
      }}
      onEntryAction={props.onEntryAction}
      onArrowUpOverflow={
        props.onArrowUpOverflow === undefined
          ? defaultOnArrowUpOverflow
          : props.onArrowUpOverflow
      }
      onArrowDownOverflow={
        props.onArrowDownOverflow === undefined
          ? defaultOnArrowDownOverflow
          : props.onArrowDownOverflow
      }
      initiallyFocusableOrActiveEntryId={initiallyFocusEntryId}
      autoFocus={props.autoFocus ?? !!initiallyFocusEntryId}
      focusEntryOnMouseOver={focusEntryOnMouseOver ?? false}
    >
      <div
        ref={listElRef}
        onBlur={(e) => {
          if (
            e.relatedTarget instanceof HTMLLIElement &&
            listElRef.current?.contains(e.relatedTarget)
          ) {
            // We want to ignore the blur event if we're switching focus from
            // one list entry to another.
            return;
          }

          props.onEntryFocused?.(null);
        }}
        className={props.className}
      >
        {props.children}
      </div>
    </List>
  );
}

export const ContentList = forwardRef(_ContentList) as <
  T extends { id: string },
>(
  props: IContactListProps<T> & { ref?: Ref<IListRef<T>> },
) => ReactElement;

/**
 * This hook is intended for usage with the ContentList component.
 * It allows tracking the currently focused post in the ContentList
 * except opening the KBar doesn't clear the focused post (
 * normally opening the KBar receives focus so the ContentList's
 * onEntryFocused callback triggers with `null`). This simplifies
 * adding KBar commands that are dependent on the currently
 * focused entry when the KBar is opened.
 */
export function useKBarAwareFocusedEntry<T extends { id: string }>() {
  const [currentlyFocusedEntry, setCurrentlyFocusedEntry] = useState<T | null>(
    null,
  );

  const [entryFocusedWhenKBarOpened, setEntryFocusedWhenKBarOpened] =
    useState<T | null>(null);

  useEffect(() => {
    const sub = KBarState.beforeOpen$.subscribe(() => {
      setEntryFocusedWhenKBarOpened(currentlyFocusedEntry);
    });

    const sub2 = KBarState.afterClose$.subscribe(() => {
      setEntryFocusedWhenKBarOpened(null);
    });

    sub.add(sub2);

    return () => sub.unsubscribe();
  }, [currentlyFocusedEntry]);

  const focusedEntry = entryFocusedWhenKBarOpened || currentlyFocusedEntry;

  return [focusedEntry, setCurrentlyFocusedEntry] as const;
}

export function createKBarAwareFocusedEntryObservable<
  T extends { id: string },
>() {
  const focusedEntryStore$ = new BehaviorSubject<T | null>(null);

  const setFocusedEntry = (entry: T | null) => focusedEntryStore$.next(entry);

  // We want to subscribe to the KBar open state but we want to react *before*
  // and *after* the KBar actually opens. We react before so that we can remember
  // what the focusedEntry was before opening. We react after so that we've already
  // refocused the correct entry after closing.
  const isKBarOpen$ = merge(
    KBarState.beforeOpen$.pipe(map(() => true)),
    KBarState.afterClose$.pipe(map(() => false)),
  ).pipe(startWith(() => KBarState.isOpen()));

  const focusedEntry$ = combineLatest([focusedEntryStore$, isKBarOpen$]).pipe(
    filter(([, isKBarOpen]) => !isKBarOpen),
    map(([focusedEntry]) => focusedEntry),
    distinctUntilChanged(isEqual),
  );

  return { focusedEntry$, setFocusedEntry };
}

// This is a more performant refactor of the original "useKBarAwareFocusedEntry"
// hook above. This version makes it easy for the context invoking this hook
// to not re-render on focusedEntry changes.
export function useKBarAwareFocusedEntry$<T extends { id: string }>() {
  return useConstant(() => {
    const result = createKBarAwareFocusedEntryObservable<T>();

    return {
      ...result,
      useFocusedEntry: () =>
        useObservable(() => result.focusedEntry$, {
          synchronous: true,
          deps: [result.focusedEntry$],
        }),
    };
  });
}

/** The "to" argument must be a full route path. Relative routing is not supported. */
export async function navigateToEntry(
  entryId: string,
  to: string,
  options?: INavigateServiceOptions,
) {
  await updateNavigationHistoryToAllowRefocusingContentListEntryOnBack(entryId);
  await navigateService(to, options);
}

/**
 * Updates the current navigation history state to save the
 * provided entryId so that the ContentList component will
 * refocus it if we navigate "back" to this history entry.
 */
export function updateNavigationHistoryToAllowRefocusingContentListEntryOnBack(
  entryId: string,
) {
  const location = getCurrentRouterLocation();

  return navigateService(location, {
    replace: true,
    state: {
      ...getLocationState(),
      ContentList: entryId,
    },
  });
}
