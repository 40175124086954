import { useLocation, Navigate } from "react-router-dom";
import { ComponentType } from "react";
import { Helmet } from "react-helmet-async";
import { useServerAPIVersion } from "~/services/version.service";
import { LoadingText } from "~/components/LoadingText";
import { PendingRequestBar } from "~/components/PendingRequestBar";
import { attemptToGetRedirectLocation } from "~/utils/navigation-helpers";
import { withIsOnlineGuard } from "~/route-guards/withIsOnlineGuard";

export const MaintenanceView: ComponentType<{}> = withIsOnlineGuard(() => {
  const apiVersion = useServerAPIVersion();
  const location = useLocation();
  const redirectTo = attemptToGetRedirectLocation(location);

  if (apiVersion === "loading") {
    return (
      <PendingRequestBar>
        <LoadingText />
      </PendingRequestBar>
    );
  }

  if (apiVersion.matches) {
    return (
      <Navigate
        to={
          !redirectTo || redirectTo.pathname.startsWith("/maintenance")
            ? "/inbox"
            : redirectTo
        }
        replace
      />
    );
  }

  return (
    <div className="w-screen h-dynamic-screen flex flex-col justify-center items-center space-y-10">
      <Helmet>
        <title>Maintenance | Comms</title>
      </Helmet>

      <h1 className="text-4xl text-center font-bold">Down for Maintenance</h1>

      <div className="flex px-8 py-4 items-center">
        <p>
          {apiVersion.server?.message || "Comms is currently being updated."}
        </p>
      </div>
    </div>
  );
});
