import { IPostDoc } from "@libs/firestore-models";
import { getPostSenderName } from "@libs/firestore-models/utils";
import { isEqual } from "@libs/utils/isEqual";
import { ComponentType, memo } from "react";
import { EntryTimestamp, Recipients, Summary } from "~/components/content-list";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { LabelChip } from "../ChannelLabels";

export const CollapsedPost: ComponentType<{
  post: IPostDoc;
}> = memo((props) => {
  const { post } = props;
  const { currentUser } = useAuthGuardContext();
  const senderName = getPostSenderName(post, currentUser.id);
  const priorityLabel = getMessageMentionLabel(
    post.mentionedUsers[currentUser.id]?.priority,
  );

  return (
    <>
      <Recipients className="font-bold">{senderName}</Recipients>

      <Summary details={post.bodyText} />

      {priorityLabel && (
        <div className="mx-2">
          <LabelChip tooltip={`You were ${priorityLabel}ed`}>
            {priorityLabel}
          </LabelChip>
        </div>
      )}

      <EntryTimestamp datetime={post.sentAt} />
    </>
  );
}, isEqual);

function getMessageMentionLabel(priority?: number) {
  if (!priority) return;

  return priority === 100
    ? "@@@mention"
    : priority === 200
    ? "@@mention"
    : priority === 300
    ? "@mention"
    : undefined;
}
