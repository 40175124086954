import {
  distinctUntilChanged,
  from,
  map,
  Observable,
  of,
  switchMap,
  tap,
  combineLatest,
  filter,
  merge,
  interval,
  take,
  BehaviorSubject,
  Subject,
} from "rxjs";
import {
  ASSERT_CURRENT_USER_ID$,
  catchNoCurrentUserError,
} from "~/services/user.service";
import { useObservable } from "~/utils/useObservable";
import { isAppOnline } from "~/services/network-connection.service";
import { ApolloQueryResult, TypedDocumentNode } from "@apollo/client";
import { apollo } from "~/services/apollo.service";
import { startWith } from "@libs/utils/rxjs-operators";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { wait } from "@libs/utils/wait";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { ParsedToken } from "@libs/utils/searchQueryParser";
import { isEqual } from "@libs/utils/isEqual";
import { buildGraphQLQueryVariables } from "./buildGraphQLQueryVariables";
import useConstant from "use-constant";
import { pick } from "lodash-es";

/* -----------------------------------------------------------------------------------------------*/

export function observeSearchResults<T, R>(args: {
  /**
   * Our graphql schema has different root entry points for a fulltext
   * search query vs a non-fulltext search query.
   *
   * Depending on what is being searched, we may or may not support a
   * fulltext search query. If we do, provide a graphql query document
   * that should be used if the user tries a full text search. If we
   * don't, leave this undefined. If a user provides plain text and
   * a fulltext query isn't supported, then we will approximate the
   * fulltext query by performing an ilike search on the subject and
   * bodyText fields and looking for a match in either.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gqlQueryWithFullTextSearch?: TypedDocumentNode<T, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gqlQueryWithoutFullTextSearch: TypedDocumentNode<T, any>;
  queryLimit?: number;
  mapApolloQueryResultData: (data: T) => { data: Promise<R[]>; size: number };
}) {
  const {
    gqlQueryWithFullTextSearch,
    gqlQueryWithoutFullTextSearch,
    queryLimit,
    mapApolloQueryResultData,
  } = args;

  const parsedQueryText$ = new BehaviorSubject<ParsedToken[]>([]);
  const isSearchPending$ = new BehaviorSubject(false);
  const endOfSearch$ = new BehaviorSubject(false);
  const bottomOfPageInView$ = new Subject<void>();

  const searchResults$: Observable<R[]> = ASSERT_CURRENT_USER_ID$.pipe(
    switchMap(() =>
      // Here we're using merge() so that whenever parsedQuery$ emits,
      // we immediately clear our current results (i.e. reemit
      // `[]` from the first argument passed to merge()). Then we want
      // to process our new query and fetch those results.
      merge(
        parsedQueryText$.pipe(map(() => [])),
        parsedQueryText$.pipe(
          switchMap((searchTokens) => {
            if (searchTokens.length === 0 || !isAppOnline()) {
              isSearchPending$.next(false);
              endOfSearch$.next(true);
              return of([]);
            }

            const queryStart = new Date();
            let page = 0;

            isSearchPending$.next(true);
            endOfSearch$.next(false);

            const variables = buildGraphQLQueryVariables({
              searchTokens,
              offset: page,
              allowTopLevelFullTextQuery: !!gqlQueryWithFullTextSearch,
              limit: queryLimit,
            });

            console.debug("search variables", variables);

            const searchQuery = apollo.watchQuery({
              query:
                variables.query && gqlQueryWithFullTextSearch
                  ? gqlQueryWithFullTextSearch
                  : gqlQueryWithoutFullTextSearch,
              variables: pick(variables, [
                "query",
                "offset",
                "limit",
                "messagesWhere",
              ]),
              fetchPolicy: "network-only",
            });

            const searchQuery$ = from(searchQuery) as Observable<
              ApolloQueryResult<T>
            >;

            if (!queryLimit) {
              return searchQuery$.pipe(
                tap(({ data }) => {
                  console.debug("Search started at", queryStart.toISOString());
                  console.debug(
                    "GraphQL query returned at",
                    new Date().toISOString(),
                  );
                  console.debug("GraphQL results", data);
                }),
                switchMap(({ data }) => mapApolloQueryResultData(data).data),
                tap(() => {
                  isSearchPending$.next(false);
                  console.debug("Search started at", queryStart.toISOString());
                  console.debug(
                    "Search completed at",
                    new Date().toISOString(),
                  );
                }),
                tap((posts) => console.debug("search results", posts)),
              );
            }

            const fetchMoreResults = onlyCallFnOnceWhilePreviousCallIsPending(
              withPendingRequestBar(async () => {
                page++;

                console.debug("fetchMoreResults offset", page * queryLimit);

                const { data } = await searchQuery.fetchMore({
                  variables: { offset: page * queryLimit },
                });

                const results = mapApolloQueryResultData(data);

                if (results.size !== queryLimit) {
                  endOfSearch$.next(true);
                }

                await wait(200);

                return results.data;
              }),
            );

            return combineLatest([
              searchQuery$,
              // We don't use the results of this observable, just the side effects
              // of `fetchMoreResults()`.
              bottomOfPageInView$.pipe(
                map(() => endOfSearch$.getValue()),
                filter((endOfSearch) => !endOfSearch),
                switchMap(async () => fetchMoreResults()),
                // `combineLatest` waits for all observables to emit once before it emits
                // anything, so we start with `null`. Since we throw away the results
                // anyway, the value we start with doesn't actually matter.
                startWith(() => null),
              ),
            ]).pipe(
              tap(([{ data }]) => {
                console.debug("Search started at", queryStart.toISOString());
                console.debug(
                  "GraphQL query returned at",
                  new Date().toISOString(),
                );
                console.debug("GraphQL results", data);
              }),
              switchMap(async ([{ data }]) => {
                const results = mapApolloQueryResultData(data);

                // If the user performs a search, loads a bunch of pages of that search,
                // navigates away, and then comes back and performs the same search again,
                // ApolloClient will load everything from the cache, even though we have
                // the network-only fetch policy (at the moment, I'm not sure if this means
                // its ignoring our fetch policy or if something else is going on). Because
                // of this, we can start out on a page other than 0. Here we check for this
                // and jump the page forward if appropriate.
                //
                // We subtract one since the first page is 0.
                const actualPage = Math.floor(results.size / queryLimit) - 1;

                if (page < actualPage) {
                  page = actualPage;
                }

                return results.data;
              }),
              tap((posts) => {
                isSearchPending$.next(false);
                console.debug("Search started at", queryStart.toISOString());
                console.debug("Search ended at", new Date().toISOString());
                console.debug("search results", posts);
              }),
            );
          }),
        ),
      ),
    ),
    catchNoCurrentUserError(() => []),
    distinctUntilChanged(isEqual),
  );

  return {
    isSearchPending$: isSearchPending$.asObservable(),
    endOfSearch$: endOfSearch$.asObservable(),
    searchResults$,
    parsedQueryText$,
    bottomOfPageInView$,
  };
}

/* -----------------------------------------------------------------------------------------------*/

export function getParsedToken_IsSeen(): ParsedToken {
  return {
    type: "is:",
    value: [{ type: "text", value: "seen" }],
  };
}

/* -----------------------------------------------------------------------------------------------*/

/**
 * Note, the `useSearch` args will be used inside of a `useConstant` hook
 * meaning that the args will ignore changes.
 */
export function useSearch<T, R>(args: {
  /**
   * Our graphql schema has different root entry points for a fulltext
   * search query vs a non-fulltext search query.
   *
   * Depending on what is being searched, we may or may not support a
   * fulltext search query. If we do, provide a graphql query document
   * that should be used if the user tries a full text search. If we
   * don't, leave this undefined. If a user provides plain text and
   * a fulltext query isn't supported, then we will approximate the
   * fulltext query by performing an ilike search on the subject and
   * bodyText fields and looking for a match in either.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gqlQueryWithFullTextSearch?: TypedDocumentNode<T, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gqlQueryWithoutFullTextSearch: TypedDocumentNode<T, any>;
  queryLimit: number;
  mapApolloQueryResultData: (data: T) => { data: Promise<R[]>; size: number };
}) {
  const {
    searchResults$,
    isSearchPending$,
    endOfSearch$,
    parsedQueryText$,
    bottomOfPageInView$,
  } = useConstant(() => observeSearchResults(args));

  const searchResults = useObservable(() => searchResults$, {
    synchronous: true,
  });

  const isSearchPending = useIsSearchPending(isSearchPending$);

  return {
    searchResults,
    isSearchPending,
    endOfSearch$,
    setSearchQuery(parsedQueryText: ParsedToken[]) {
      if (isEqual(parsedQueryText, parsedQueryText$.getValue())) {
        return;
      }

      parsedQueryText$.next(parsedQueryText);
    },
    onBottomOfPageInView() {
      bottomOfPageInView$.next();
    },
  };
}

/* -----------------------------------------------------------------------------------------------*/

function useIsSearchPending(isSearchPending$: Observable<boolean>) {
  return useObservable(
    () =>
      // When we mark search as pending, we want to emit that change
      // immediately. When we mark search as complete, we want to
      // delay slightly to ensure that the search results emit *before*
      // we mark search as done.
      isSearchPending$.pipe(
        switchMap((isPending) => {
          if (isPending) return of(true);

          return interval(50).pipe(
            take(1),
            map(() => false),
          );
        }),
        distinctUntilChanged(),
      ),
    { initialValue: true, deps: [isSearchPending$] },
  );
}

/* -----------------------------------------------------------------------------------------------*/
