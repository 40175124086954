import { createPath } from "react-router-dom";
import { SetOptional } from "type-fest";
import { IListRef } from "~/components/list";
import { IBranchedAtTimelineEntryLocalDoc } from "~/components/QuoteBranchedThreadPostsBase";
import { ICommandArgs } from "~/services/command.service";
import { IDraftWithThreadData } from "~/services/draft.service";
import {
  ILocation,
  navigateBackToMostRecentRouteMatching,
  navigateService,
} from "~/services/navigate.service";
import { IObservePost } from "~/services/post.service";
import { getCommandFactory } from "~/utils/common-commands";

export type TThreadTimelineEntry =
  | IObservePost
  | IDraftWithThreadData
  | IBranchedAtTimelineEntryLocalDoc;

export function getLastPostEntry<T extends { __docType: unknown }>(
  entries?: IListRef<T>["entries"],
): (T & { __docType: "IPostDoc" }) | null {
  if (!entries) return null;

  let index = entries.length - 1;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const entry = entries[index]?.data;

    if (!entry) return null;
    if (entry.__docType === "IPostDoc") {
      return entry as T & { __docType: "IPostDoc" };
    }

    index--;
  }
}

/**
 * Given the currently focused timeline entry, returns the
 * nearest entry which is a post document. If the currently
 * focused timeline entry is a post document, returns it.
 * Prefers post documents which are before the currently
 * focused timeline entry, but will pick the next post
 * document after the current entry if there are none before
 * the current entry.
 */
export function getNearestPostEntry(args: {
  focusedEntry: TThreadTimelineEntry | null;
  listRef: IListRef<TThreadTimelineEntry>;
}) {
  const { focusedEntry, listRef } = args;

  if (!focusedEntry) return null;
  if (!listRef) return null;
  if (focusedEntry.__docType === "IPostDoc") {
    return focusedEntry;
  }

  const focusedIndex = listRef.entries.findIndex(
    (e) => e.id === focusedEntry.id,
  );

  let index = focusedIndex - 1;

  // Search for a post entry before the currently focused entry
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const entry = listRef.entries[index];
    if (entry?.data.__docType === "IPostDoc") return entry.data;
    index--;
    if (index < 0) break;
  }

  index = focusedIndex + 1;

  // Search for a post entry after the currently focused entry
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const entry = listRef.entries[index];
    if (entry?.data.__docType === "IPostDoc") return entry.data;
    index++;
    if (index >= listRef.entries.length) break;
  }

  return null;
}

/* -----------------------------------------------------------------------------------------------*/

export function focusReply(postId: string, draftLocation: ILocation) {
  if (createPath(window.location) !== createPath(draftLocation)) {
    navigateService(draftLocation, {
      state: { undoSentDraft: true },
    });
  }

  // While chrome will consistently initialize the editor with
  // `setTimeout(fn, 0)`, Safari takes a (comparatively long and)
  // variable amount of time to initialize
  // the editor. Because the varience is high, we're
  // using setInterval to wait until the editor has initialized.
  // In testing, Safari _generally_ inializes the editor in 1ms
  // but 2ms is not unusual and sometimes it takes more than 10ms.
  let index = 0;
  const interval = setInterval(() => {
    index += 2;

    if (index > 500) clearInterval(interval);

    const tiptapEl = document.querySelector<HTMLDivElement>(
      `.RichTextEditor.Post-${postId} > *`,
    );

    if (!tiptapEl) return;

    tiptapEl.focus();
    clearInterval(interval);
  }, 2);
}

/* -------------------------------------------------------------------------------------------------
 * closeThreadView
 * -----------------------------------------------------------------------------------------------*/

export function closeThreadView() {
  return navigateBackToMostRecentRouteMatching(
    (loc) =>
      !loc.pathname.startsWith("/threads/") &&
      !loc.pathname.startsWith("/emails/"),
  );
}

/* -------------------------------------------------------------------------------------------------
 * Commands
 * -----------------------------------------------------------------------------------------------*/

export const replyToThreadCommand = getCommandFactory(
  "REPLY_TO_THREAD",
  (options: SetOptional<ICommandArgs, "hotkeys">) => ({
    hotkeys: ["r"],
    ...options,
  }),
);

export const createBranchedReplyCommand = getCommandFactory(
  "CREATE_BRANCHED_REPLY",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys">) => ({
    label: "Branched reply",
    hotkeys: ["Shift+R", "Shift+r"],
    ...options,
  }),
);

export const updateThreadChannelsCommand = getCommandFactory(
  "UPDATE_THREAD_CHANNELS",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys">): ICommandArgs => ({
    label: "Update thread channels",
    altLabels: [
      "Move to channel...",
      "Add channel to thread...",
      {
        render: "Edit thread channels",
        keywords: ["Edit thread recipients"],
      },
    ],
    keywords: ["Update thread recipients", "Update channels", "Add channels"],
    ...options,
  }),
);

export const copyLinkToFocusedPostCommand = getCommandFactory(
  "COPY_LINK_TO_POST",
  (
    options: SetOptional<ICommandArgs, "label" | "altLabels">,
  ): ICommandArgs => ({
    label: "Copy direct link to focused post",
    altLabels: ["Copy direct link to selected post"],
    ...options,
  }),
);
