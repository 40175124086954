import React, { ComponentType } from "react";
import { ICommandArgs, useRegisterCommands } from "~/services/command.service";
import { Tooltip } from "~/components/Tooltip";
import { css, cx } from "@emotion/css";
import {
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  subscribeToThreadCommand,
  unsubscribeFromThreadCommand,
} from "~/utils/common-commands";
import {
  MdDone,
  MdEdit,
  MdRemoveDone,
  MdRssFeed,
  MdSchedule,
} from "react-icons/md";
import { CgMailReply } from "react-icons/cg";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useThreadContext } from "./context";
import {
  createBranchedReplyCommand,
  replyToThreadCommand,
  updateThreadChannelsCommand,
} from "./utils";
import { useSubscriptionText } from "./ThreadHeader";
import { useThreadViewPrevNextUrl } from "~/services/thread-prev-next.service";
import { navigateService } from "~/services/navigate.service";
import { toast } from "~/services/toast-service";
import { UnreachableCaseError } from "@libs/utils/errors";
import { SwitchCase } from "~/components/SwitchCase";

/* -------------------------------------------------------------------------------------------------
 * ActionToolbar
 * -----------------------------------------------------------------------------------------------*/

export const ActionToolbar: ComponentType<{}> = () => {
  const context = useThreadContext();
  const thread = context.useThread();
  const prevNextState = useThreadViewPrevNextUrl(thread);

  useRegisterCommands({
    commands() {
      const commands: ICommandArgs[] = [];

      if (prevNextState?.previousUrl) {
        commands.push({
          label: "Previous thread",
          hotkeys: ["k"],
          callback() {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            navigateService(prevNextState.previousUrl!, {
              state: prevNextState.state,
            });
          },
        });
      } else if (prevNextState) {
        commands.push({
          label: "Previous thread",
          hotkeys: ["k"],
          callback() {
            toast("vanilla", {
              subject: "This is the first thread in the list.",
            });
          },
        });
      }

      if (prevNextState?.nextUrl) {
        commands.push({
          label: "Next thread",
          hotkeys: ["j"],
          callback() {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            navigateService(prevNextState.nextUrl!, {
              state: prevNextState.state,
            });
          },
        });
      } else if (prevNextState) {
        commands.push({
          label: "Next thread",
          hotkeys: ["j"],
          callback() {
            toast("vanilla", {
              subject: "This is the last thread in the list.",
            });
          },
        });
      }

      return commands;
    },
    deps: [prevNextState],
  });

  return (
    <div
      className={cx(
        // shared styles
        "fixed z-[30] bg-white",
        // narrow screen styles
        "sm-max-w:bottom-0 sm-max-w:left-0",
        "sm-max-w:w-screen sm-max-w:border-t sm-max-w:border-slate-7",
        "sm-max-w:overflow-x-auto",
        // wider screen styles
        "sm-w:top-[5rem] sm-w:overflow-y-auto",
        actionToolbarWrapperCSS,
        "sm-w:ml-6 sm-w:mt-4 sm-w:rounded-lg",
        "sm-w:shadow-lg",
      )}
    >
      <div
        className={cx(
          // shared styles
          "flex w-fit py-2",
          // narrow screen styles
          "sm-max-w:px-2 sm-max-w:py-1",
          "sm-max-w:justify-center",
          // wider screen styles
          "sm-w:flex-col sm-w:p-[1px]",
        )}
      >
        <SwitchCase deps={[thread.type, !!thread.__local.fromSecretThread]}>
          {() => {
            switch (thread.type) {
              case "COMMS": {
                return <ThreadActions />;
              }
              case "EMAIL": {
                if (thread.__local.fromSecretThread) {
                  return <SecretEmailThreadActions />;
                }

                return <ThreadActions />;
              }
              default: {
                throw new UnreachableCaseError(thread);
              }
            }
          }}
        </SwitchCase>
      </div>
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ThreadActions: ComponentType<{}> = () => {
  const context = useThreadContext();
  const thread = context.useThread();
  const notification = context.useNotification();
  const draft = context.useDraftForThread();
  const prevNextState = useThreadViewPrevNextUrl(thread);
  const { badgeText } = useSubscriptionText();

  return (
    <>
      {badgeText === "Everything" ? (
        <Action
          label="Unsubscribe"
          shortcut="(S)"
          onClick={(e) => {
            e.preventDefault();
            unsubscribeFromThreadCommand.trigger();
          }}
        >
          <MdRssFeed
            className={cx(
              "sm-max-w:text-green-8 sm-max-w:group-hover:text-green-10",
              actionBtnIconCSS,
            )}
          />
        </Action>
      ) : badgeText === "Mentions" ? (
        <Action
          label="Subscribe"
          shortcut="(S)"
          onClick={(e) => {
            e.preventDefault();
            subscribeToThreadCommand.trigger();
          }}
        >
          <MdRssFeed className={actionBtnIconCSS} />
        </Action>
      ) : (
        <Action label="Loading..." onClick={() => {}}>
          <MdRssFeed className={actionBtnIconCSS} />
        </Action>
      )}

      {notification?.done ? (
        <Action
          label="Mark not done"
          shortcut="(Shift+E)"
          onClick={(e) => {
            e.preventDefault();
            markNotDoneCommand.trigger();
          }}
        >
          <MdRemoveDone className={actionBtnIconCSS} />
        </Action>
      ) : (
        <Action
          label="Mark done"
          shortcut="(E)"
          onClick={(e) => {
            e.preventDefault();
            markDoneCommand.trigger();
          }}
        >
          <MdDone className={actionBtnIconCSS} />
        </Action>
      )}

      <Action
        label="Set reminder"
        shortcut="(H)"
        onClick={(e) => {
          e.preventDefault();
          setThreadReminderCommand.trigger();
        }}
      >
        <MdSchedule
          className={cx(
            notification?.triaged
              ? "sm-max-w:text-plum-8 sm-max-w:group-hover:text-plum-10"
              : actionBtnIconCSS,
          )}
        />
      </Action>

      <Action
        label="Edit channels"
        onClick={(e) => {
          e.preventDefault();
          updateThreadChannelsCommand.trigger();
        }}
      >
        <MdEdit className={actionBtnIconCSS} />
      </Action>

      <Action
        label="Reply"
        shortcut="(R)"
        onClick={(e) => {
          e.preventDefault();
          replyToThreadCommand.trigger();
        }}
      >
        <CgMailReply
          className={cx(
            draft
              ? "sm-max-w:text-green-8 sm-max-w:group-hover:text-green-10"
              : actionBtnIconCSS,
          )}
        />
      </Action>

      {prevNextState?.previousUrl && (
        <Action
          label="Previous"
          shortcut="(K)"
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            navigateService(prevNextState.previousUrl!, {
              state: prevNextState?.state,
            });
          }}
        >
          <FiChevronUp className={actionBtnIconCSS} />
        </Action>
      )}

      {prevNextState?.nextUrl && (
        <Action
          label="Next"
          shortcut="(J)"
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            navigateService(prevNextState.nextUrl!, {
              state: prevNextState?.state,
            });
          }}
        >
          <FiChevronDown className={actionBtnIconCSS} />
        </Action>
      )}
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const SecretEmailThreadActions: ComponentType<{}> = () => {
  const context = useThreadContext();
  const thread = context.useThread();
  const notification = context.useNotification();
  const prevNextState = useThreadViewPrevNextUrl(thread);

  return (
    <>
      {notification?.done ? (
        <Action
          label="Mark not done"
          shortcut="(Shift+E)"
          onClick={(e) => {
            e.preventDefault();
            markNotDoneCommand.trigger();
          }}
        >
          <MdRemoveDone className={actionBtnIconCSS} />
        </Action>
      ) : (
        <Action
          label="Mark done"
          shortcut="(E)"
          onClick={(e) => {
            e.preventDefault();
            markDoneCommand.trigger();
          }}
        >
          <MdDone className={actionBtnIconCSS} />
        </Action>
      )}

      <Action
        label="Set reminder"
        shortcut="(H)"
        onClick={(e) => {
          e.preventDefault();
          setThreadReminderCommand.trigger();
        }}
      >
        <MdSchedule
          className={cx(
            notification?.triaged
              ? "sm-max-w:text-plum-8 sm-max-w:group-hover:text-plum-10"
              : actionBtnIconCSS,
          )}
        />
      </Action>

      <Action
        label="Reply"
        shortcut="(R)"
        onClick={(e) => {
          e.preventDefault();
          createBranchedReplyCommand.trigger();
        }}
      >
        <CgMailReply className={actionBtnIconCSS} />
      </Action>

      {prevNextState?.previousUrl && (
        <Action
          label="Previous"
          shortcut="(K)"
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            navigateService(prevNextState.previousUrl!, {
              state: prevNextState?.state,
            });
          }}
        >
          <FiChevronUp className={actionBtnIconCSS} />
        </Action>
      )}

      {prevNextState?.nextUrl && (
        <Action
          label="Next"
          shortcut="(J)"
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            navigateService(prevNextState.nextUrl!, {
              state: prevNextState?.state,
            });
          }}
        >
          <FiChevronDown className={actionBtnIconCSS} />
        </Action>
      )}
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const Action: ComponentType<{
  label: string;
  shortcut?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}> = (props) => {
  const tooltip = `${props.label} ${props.shortcut || ""}`.trim();

  return (
    <Tooltip side="right" content={tooltip}>
      <button
        type="button"
        tabIndex={-1}
        className={cx(
          "flex items-center py-1 px-3 h-[40px] rounded-lg",
          "hover:bg-slate-3 group",
          // narrow screen styles
          "sm-max-w:mx-3",
          // wider screen styles
          "sm-w:my-1",
        )}
        onClick={props.onClick}
      >
        <span
          className={cx(
            "inline-flex items-center justify-center w-5 h-5 text-xl lg-w:mr-2",
          )}
        >
          {props.children}
        </span>
        <span className="shrink-0 lg-max-w:hidden">{props.label}</span>
      </button>
    </Tooltip>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const actionToolbarWrapperCSS = css`
  @media (min-width: 580px) {
    max-height: calc(100dvh - 8rem);
  }

  @media print {
    display: none !important;
  }
`;

const actionBtnIconCSS = "text-slate-10 group-hover:text-black";

/* -----------------------------------------------------------------------------------------------*/
