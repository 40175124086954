import { isEqual } from "@libs/utils/isEqual";
import { RefObject, useEffect } from "react";
import {
  BehaviorSubject,
  combineLatest,
  concat,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  Observable,
  shareReplay,
  skip,
  switchMap,
  take,
  takeUntil,
  throttleTime,
} from "rxjs";
import { pendingRequestBarService } from "~/components/PendingRequestBar";
import { getMaxScrollTop, getScrollTop } from "./dom-helpers";
import { startWith } from "./rxjs-operators";

/**
 * Constant intended for use in the `limit()` argument when
 * fetching documents from Firestore.
 */
export const ENTRIES_PER_PAGE_LIMIT = 100;

/**
 * This hook aids with implementing paging functionality
 * in other hooks which are returning firestore data. This
 * hook receives a ref for the dom element where scrolling will
 * take place as well as a callback function which will be
 * called when the user scrolls to the bottom of the scrolling
 * element.
 */
export function useListPaging(options: {
  getNextPage?: () => void;
  loading$?: Observable<boolean>;
  /**
   * Loads an initial chunk of threads and then loads
   * more when the user scrolls to the bottom of the
   * element associated with this scrollboxRef.
   */
  pagingScrollboxRef: RefObject<HTMLElement>;
}) {
  const { getNextPage, pagingScrollboxRef, loading$ } = options;

  useEffect(() => {
    if (!loading$) return;

    let pending: (() => void) | undefined;

    const sub = loading$.pipe(distinctUntilChanged()).subscribe((isLoading) => {
      if (isLoading) {
        pending = pendingRequestBarService.markLoading();
      } else {
        pending?.();
      }
    });

    return () => {
      sub.unsubscribe();
      pending?.();
    };
  }, [loading$]);

  useEffect(() => {
    const scrollboxEl = pagingScrollboxRef.current;

    if (!scrollboxEl) return;
    if (!getNextPage) return;

    const sub = fromEvent(
      scrollboxEl === document.body ? window : scrollboxEl,
      "scroll",
    )
      .pipe(
        startWith(() => null),
        throttleTime(100, undefined, { leading: false, trailing: true }),
        filter(() => {
          const targetPxFromBottom = 400;

          const maxScrollHeight = getMaxScrollTop(scrollboxEl);

          const targetHeight = maxScrollHeight - targetPxFromBottom;

          const isTargetHeightReached =
            getScrollTop(scrollboxEl) > targetHeight;

          return isTargetHeightReached;
        }),
      )
      .subscribe(getNextPage);

    return () => sub.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNextPage]);
}

/**
 * Helper for adding paging functionality to a Firestore query.
 *
 * @param queryFactory a factory function to construct the Firestore query
 * @param page the current page for the query
 */
export function getPagedQuery<T>(
  queryFactory: (limitThreadCountTo: number) => Observable<T[]>,
  page: number | BehaviorSubject<number>,
) {
  const page$ = page instanceof Observable ? page : new BehaviorSubject(page);
  const end$ = new BehaviorSubject(false);
  const loading$ = new BehaviorSubject(false);

  let lastQuery = page$.pipe(
    switchMap((targetPage) =>
      queryFactory(targetPage * ENTRIES_PER_PAGE_LIMIT),
    ),
  );

  const docs$ = page$.pipe(
    filter(() => !end$.getValue()),
    switchMap((currentPage) => {
      loading$.next(true);
      const limitThreadCountTo = currentPage * ENTRIES_PER_PAGE_LIMIT;
      const currentQuery = queryFactory(limitThreadCountTo);

      return combineLatest([
        // Initially I just tried `lastQuery.pipe(takeUntil(currentQuery))`
        // but I found that it wouldn't always emit a value (which
        // prevented combineLatest from emitting a value). By using concat
        // we ensure that we emit at least one value from `lastQuery`.
        concat(
          lastQuery.pipe(take(1)),
          lastQuery.pipe(skip(1), takeUntil(currentQuery)),
        ),
        currentQuery.pipe(startWith(() => undefined)),
      ]).pipe(
        map(([last, current]) => {
          if (!current) return last;
          if (loading$.getValue()) {
            setTimeout(() => loading$.next(false));
          }

          lastQuery = currentQuery;
          const noMoreResults = current.length < limitThreadCountTo;
          end$.next(noMoreResults);

          return current;
        }),
        distinctUntilChanged(isEqual),
        shareReplay(1),
      );
    }),
  );

  const getNextPage = () => {
    if (end$.getValue() || loading$.getValue()) return;
    page$.next(page$.getValue() + 1);
  };

  return { docs$, page$, end$, loading$, getNextPage };
}
