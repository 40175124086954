import { ComponentType, useEffect, useRef, useState } from "react";
import {
  DIALOG_CONTAINER_CSS,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { css, cx } from "@emotion/css";
import { signout } from "~/services/user.service";
import {
  ACTIVE_COMMANDS$,
  COMMAND_EVENTS$,
  ICommand,
  useRegisterCommands,
} from "~/services/command.service";
import { combineLatest, map, throttleTime } from "rxjs";
import { IListRef, List, ListScrollbox } from "~/components/list";
import commandScore from "command-score";
import { CommandEntry } from "./CommandEntry";
import {
  FilterCommandsInput,
  KBarHeader,
  type IKBarHeaderRef,
} from "./KBarHeader";
import { IKBarDialogData, KBarState, resetKBarState } from "./KBarState";
import { useObservable } from "~/utils/useObservable";
import { isEqual } from "@libs/utils/isEqual";
import { SearchEntry, SEARCH_COMMAND } from "./SearchEntry";
import { navigateService } from "~/services/navigate.service";
import { hint, ShortcutHint } from "~/services/hint-service";

export const KBarDialog = withModalDialog<{}, IKBarDialogData, undefined>({
  dialogState: KBarState,
  containerCSS: cx(
    DIALOG_CONTAINER_CSS,
    css`
      max-height: 472px;
    `,
  ),
  useOnDialogContainerRendered: () => {
    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Toggle Command Bar",
            keywords: [
              "open command bar",
              "close command bar",
              "open kbar",
              "close kbar",
            ],
            hotkeys: ["$mod+k"],
            triggerHotkeysWhenInputFocused: true,
            showInKBar: false,
            callback: () => {
              KBarState.toggle();
            },
          },
          {
            label: "Sign out",
            altLabels: ["Log out"],
            callback: () => {
              navigateService("/inbox");
              signout();
            },
          },
        ];
      },
    });
  },
  // The CommandBar dialog is unusual in that it it's a dialog which doesn't
  // replace the current hotkey context when opened, but rather merges it's
  // context in with the existing context (which is how we grab all the
  // currently active commands and display them). Because of this, we need
  // to ensure that the kbar's commands take precidence over any existing
  // ones with similar names.
  //
  // Note, another approach would be to grab a snapshot of the active commands
  // right before opening the command bar and use those. For the time
  // being though, this current approach seems simpler.
  commandContextOptions: {
    updateStrategy: "merge",
  },
  Component: () => {
    const listRef = useRef<IListRef<ICommand>>(null);
    const headerRef = useRef<IKBarHeaderRef>(null);
    const scrollboxRef = useRef<HTMLDivElement>(null);

    const [commands, setCommands] = useState<Array<ICommand>>([]);

    const mode = useObservable(() => KBarState.mode$, {
      synchronous: true,
    });

    const currentPath = useObservable(() => KBarState.path$, {
      synchronous: true,
    });

    const [enableEntryFocusOnMouseover, setEnableEntryFocusOnMouseover] =
      useState(true);

    // Switch focus between search-input and not-search-input
    // when the mode changes.
    useEffect(() => {
      headerRef.current?.focusInput(mode === "search");
    }, [mode]);

    // Reset KBarState on dialog unmount
    useEffect(() => resetKBarState, []);

    useRegisterCommands({
      priority: 99999,
      commands: () => {
        return [
          SEARCH_COMMAND,
          {
            label: "Escape",
            path: ["global"],
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            showInKBar: false,
            callback: () => {
              if (
                KBarState.mode$.getValue() === "search" &&
                KBarState.query$.getValue().length > 0
              ) {
                KBarState.query$.update(() => "");
                return;
              }

              KBarState.toggle(false);
            },
          },
          {
            label: "Back",
            path: ["global"],
            hotkeys: ["Backspace"],
            triggerHotkeysWhenInputFocused: true,
            showInKBar: false,
            callback: () => {
              if (KBarState.path$.getValue().length === 0) return false;
              if (
                KBarState.mode$.getValue() === "search" &&
                KBarState.query$.getValue().length > 0
              ) {
                return false;
              }

              KBarState.query$.next("");

              if (KBarState.path$.getValue().length === 1) {
                KBarState.mode$.next("search");
              }

              KBarState.query$.update(() => "");
              KBarState.path$.update((path) => path.slice(0, -1));
            },
          },
          {
            label: "Select",
            path: ["global"],
            hotkeys: ["Enter"],
            triggerHotkeysWhenInputFocused: true,
            showInKBar: false,
            callback: () => {
              const command = listRef.current?.focusableOrActiveEntry()?.data;
              if (!command) return;
              callCommand(command);
            },
          },
        ];
      },
    });

    // Update the kbar commands in response to change in the
    // command service state and in response to user searches.
    useEffect(() => {
      const sub = combineLatest([
        KBarState.query$.pipe(
          throttleTime(100, undefined, { leading: true, trailing: true }),
        ),
        ACTIVE_COMMANDS$.pipe(
          map((commandMap) => {
            return Array.from(commandMap.values()).filter(
              (command) => command.showInKBar,
            );
          }),
        ),
      ]).subscribe(([query, commands]) => {
        const currentPath = KBarState.path$.getValue();

        const getScore = (command: ICommand) => {
          let text = command.keywords.join(" ");

          if (typeof command.label === "string") {
            text = command.label.concat(" ", text);
          }

          return commandScore(text, query);
        };

        const results = commands
          .filter((command) => {
            if (isEqual(command.path, ["global"] as const)) return true;

            if (query.length === 0) {
              return (
                command.path.length === currentPath.length &&
                currentPath.every((segment, i) => command.path[i] === segment)
              );
            }

            return currentPath.every(
              (segment, i) => command.path[i] === segment,
            );
          })
          .flatMap((command) => {
            if (command.altLabels.length > 0) {
              const allLabels: ICommand["altLabels"] = [
                command.keywords
                  ? { render: command.label, keywords: command.keywords }
                  : (command.label as string),
                ...command.altLabels,
              ];

              const expandedCommands = allLabels
                .map((label) => {
                  const labelText =
                    typeof label === "string"
                      ? label
                      : typeof label.render === "string"
                      ? label.render
                      : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        label.keywords[0]!;

                  const id = command.id
                    ? command.id + ":" + labelText
                    : labelText;

                  const keywords =
                    typeof label === "object" ? label.keywords : [];

                  return {
                    ...command,
                    __local: {
                      originalCommand: command,
                    },
                    id,
                    label: labelText,
                    keywords,
                  } as ICommand;
                })
                .filter((command) => getScore(command) > 0);

              if (expandedCommands.length === 0) return [];

              if (query.length > 0) {
                expandedCommands.sort((a, b) => getScore(b) - getScore(a));
              }

              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              return [expandedCommands[0]!];
            }

            if (getScore(command) <= 0) return [];

            return [command];
          })
          // Place highest scores first
          .sort((a, b) => {
            const aText =
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              typeof a.label === "string" ? a.label : a.keywords[0]!;

            const bText =
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              typeof b.label === "string" ? b.label : b.keywords[0]!;

            return commandScore(bText, query) - commandScore(aText, query);
          });

        // If the mouse is overing over the list as entries move around
        // beneith it, mouseover events will be triggered which will cause
        // the entry beneith the mouse to be focused as someone types. We
        // want the top entry to be focused while someone types, so we
        // disable mouseover events while they type.
        setEnableEntryFocusOnMouseover(false);
        setCommands(results);

        // After performing a search, we want to focus the first command
        // in the list.
        setTimeout(() => {
          const firstEntry = listRef.current?.entries[0];
          listRef.current?.focus(firstEntry?.id);
          setEnableEntryFocusOnMouseover(true);
        }, 20);
      });

      return () => sub.unsubscribe();
    }, []);

    return (
      <List
        ref={listRef}
        mode="active-descendent"
        focusEntryOnMouseOver={enableEntryFocusOnMouseover}
      >
        <div className={cx(dialogCSS, mode === "hotkey" && "hotkey-mode")}>
          <KBarHeader
            ref={headerRef}
            scrollboxRef={scrollboxRef}
            currentPath={currentPath}
            mode={mode}
          >
            <KbarFilterInput />
          </KBarHeader>

          <ListScrollbox>
            <div
              ref={scrollboxRef}
              role="listbox"
              className="flex flex-col overflow-y-auto bg-white"
            >
              {commands.map((command, index) => {
                return (
                  <CommandEntry
                    key={command.id}
                    index={index}
                    currentPath={currentPath}
                    command={command}
                    mode={mode}
                    onClick={() => {
                      callCommand(command);

                      if (command.hotkeys[0]) {
                        hint("quiet", {
                          content: <ShortcutHint hint={command.hotkeys[0]} />,
                        });
                      }
                    }}
                  />
                );
              })}

              <SearchEntry
                mode={mode}
                onClick={() => {
                  callCommand(SEARCH_COMMAND);

                  hint("quiet", {
                    // This command has at least one hotkey
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    content: <ShortcutHint hint={SEARCH_COMMAND.hotkeys[0]!} />,
                  });
                }}
              />
            </div>
          </ListScrollbox>
        </div>
      </List>
    );
  },
});

const dialogCSS = cx(
  DIALOG_CONTENT_WRAPPER_CSS,
  "overflow-hidden rounded",
  css`
    background-color: transparent;
    box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.2),
      0px 15px 50px 10px rgba(0, 0, 0, 0.3);
  `,
);

const KbarFilterInput: ComponentType<{}> = () => {
  const value = useObservable(() => KBarState.query$, {
    synchronous: true,
  });

  return (
    <FilterCommandsInput
      value={value}
      onChange={(e) => KBarState.query$.next(e.target.value)}
      onFocus={() => KBarState.mode$.next("search")}
    />
  );
};

function callCommand(command: ICommand) {
  COMMAND_EVENTS$.next({ type: "kbar", command });
  command.callback();
}
