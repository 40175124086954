import { onDraftReplySelectNavigateToThread } from "~/components/content-list";
import { IListOnEntryActionEvent } from "~/components/list";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";
import { isModKeyActive } from "~/services/command.service";
import { IDraftWithThreadData } from "~/services/draft.service";
import { INavigateServiceOptions } from "~/services/navigate.service";
import { openLinkInNewTabOrWindow } from "~/utils/navigation-helpers";

export type IDraftsEntry = IDraftWithThreadData;

export async function onDraftsEntrySelect(
  { entry, event }: IListOnEntryActionEvent<IDraftsEntry>,
  options?: INavigateServiceOptions,
) {
  if (isModKeyActive(event)) {
    if (entry.isFirstPostInThread) {
      const url = new URL(location.href);
      url.searchParams.set("compose", entry.id);
      openLinkInNewTabOrWindow(url);
      return;
    }

    openLinkInNewTabOrWindow(`/threads/${entry.threadId}`);
    return;
  }

  if (entry.isFirstPostInThread) {
    openComposeNewThreadDialog(entry.id);
    return;
  }

  onDraftReplySelectNavigateToThread(entry, options);
}
