import { IChannelDocWithCurrentUserData } from "~/services/channels.service";
import { IAcceptedOrganizationMemberDoc } from "~/services/organization.service";
import { ReactRenderer } from "@tiptap/react";
import type { SuggestionOptions, SuggestionProps } from "@tiptap/suggestion";
import tippy, { Instance, GetReferenceClientRect } from "tippy.js";
import { ComponentType } from "react";
import { IMentionFrequencyRecord } from "@libs/firestore-models";

export function fuzzyMatchEntries<T>(args: {
  entries: readonly T[];
  query: string;
  entryScore: (o: T, query: string) => number;
}) {
  const { entries, query, entryScore } = args;

  return entries
    .map((c) => {
      return {
        option: c,
        score: entryScore(c, query),
      };
    })
    .sort((a, b) => b.score - a.score)
    .slice(0, 9)
    .filter((r) => r.score > 0)
    .map((r) => r.option);
}

export function fuzzyMatchMembers(args: {
  members: IAcceptedOrganizationMemberDoc[];
  query: string;
  entryScore: (o: IAcceptedOrganizationMemberDoc, query: string) => number;
}) {
  return fuzzyMatchEntries({
    entries: args.members,
    query: args.query,
    entryScore: args.entryScore,
  });
}

export function fuzzyMatchChannels(args: {
  channels: IChannelDocWithCurrentUserData[];
  query: string;
  entryScore: (o: IChannelDocWithCurrentUserData, query: string) => number;
}) {
  return fuzzyMatchEntries({
    entries: args.channels,
    query: args.query,
    entryScore: args.entryScore,
  });
}

export function applyAdditionalMentionSuggestionWeights(args: {
  score: number;
  id: string;
  frequencyDictionary: Record<string, IMentionFrequencyRecord>;
  mentionWeightAdjustments?: Record<string, number>;
}) {
  const frequency = getFrequencyForId(args.id, args.frequencyDictionary);
  const frequencyScore = (frequency / (frequency + 1)) * 0.1;
  args.score = args.score + frequencyScore;

  if (args.mentionWeightAdjustments) {
    args.score = args.score + (args.mentionWeightAdjustments[args.id] || 0);
  }

  return args.score;
}

function getFrequencyForId(
  id: string,
  frequencyDictionary: Record<string, IMentionFrequencyRecord>,
) {
  const record = frequencyDictionary[id];

  if (!record) return 0;

  return (
    record.week1Count +
    record.week2Count +
    record.week3Count +
    record.week4Count +
    record.week5Count
  );
}

/**
 * This is the type returned by `new ReactRenderer(MySuggestionEntryComponent)`
 */
export type SuggestionEntryComponent<T> = ReactRenderer<
  {
    onKeyDown(o: { event: KeyboardEvent }): boolean | undefined;
  },
  SuggestionProps<T> &
    React.RefAttributes<{
      onKeyDown(o: { event: KeyboardEvent }): boolean | undefined;
    }>
>;

/**
 * Returns true if there is an opened mention list suggestion box.
 * Useful for determining how the RichTextEditor should handle
 * key presses such as "Escape"
 */
export function isSuggestionDialogOpened() {
  return openedSuggestionCount > 0;
}

export function onSuggestionDialogOpen() {
  openedSuggestionCount++;
}

export function onSuggestionDialogClose() {
  openedSuggestionCount--;
}

let openedSuggestionCount = 0;

export function getDropdownRenderFn<ISuggestionEntry>(
  dropdownComponent: ComponentType<SuggestionProps<ISuggestionEntry>>,
  options: {
    additionalProps?: Record<string, unknown>;
  } = {},
): SuggestionOptions<ISuggestionEntry>["render"] {
  return () => {
    let component: SuggestionEntryComponent<{}>;
    let popup: Instance[] = [];

    return {
      onStart: (props) => {
        component = new ReactRenderer(dropdownComponent, {
          props: { ...props, ...options.additionalProps },
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
          zIndex: 150,
        });
      },

      onUpdate(props) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0]?.setProps({
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === "Escape") {
          component.destroy();
          props.event.stopPropagation();
          return true;
        }

        return component.ref?.onKeyDown(props) || false;
      },

      onExit() {
        popup[0]?.destroy();
        component.destroy();
      },
    };
  };
}
