import { ILessonDoc } from "@libs/firestore-models";
import {
  deleteDoc,
  getDocs,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { docData } from "~/utils/rxFireWrappers";
import { map, switchMap } from "rxjs";
import { collectionRef, docRef } from "~/firestore.service";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { useObservable } from "~/utils/useObservable";
import { withPendingUpdate } from "../loading.service";
import { offlineAwareFirestoreCRUD } from "../network-connection.service";
import { toast } from "../toast-service";
import {
  ASSERT_CURRENT_USER_ID$,
  catchNoCurrentUserError,
  getAndAssertCurrentUser,
} from "../user.service";

export function useLesson(name: string): ILessonDoc | null | undefined {
  return useObservable(
    () => {
      return ASSERT_CURRENT_USER_ID$.pipe(
        switchMap((userId) =>
          docData(docRef("users", userId, "lessons", name)),
        ),
        map((lessonDoc) => lessonDoc || null),
        catchNoCurrentUserError(() => null),
      );
    },
    {
      deps: [name],
    },
  );
}

export const markLessonForCurrentUserComplete = withPendingUpdate(
  async (name: string, version: number) => {
    const currentUser = getAndAssertCurrentUser();

    await offlineAwareFirestoreCRUD(
      setDoc(docRef("users", currentUser.id, "lessons", name), {
        id: name,
        version,
        completed: true,
        updatedAt: serverTimestamp(),
        createdAt: serverTimestamp(),
      }),
    );
  },
);

export const markLessonForCurrentUserIncomplete = withPendingUpdate(
  async (name: string) => {
    const currentUser = getAndAssertCurrentUser();

    await offlineAwareFirestoreCRUD(
      deleteDoc(docRef("users", currentUser.id, "lessons", name)),
    );
  },
);

export const markAllLessonsForCurrentUserIncomplete =
  onlyCallFnOnceWhilePreviousCallIsPending(
    withPendingUpdate(async () => {
      const currentUser = getAndAssertCurrentUser();

      const lessonSnaps = await getDocs(
        collectionRef("users", currentUser.id, "lessons"),
      );

      const pendingUpdate = Promise.all(
        lessonSnaps.docs.map((snap) => deleteDoc(snap.ref)),
      );

      await offlineAwareFirestoreCRUD(pendingUpdate);

      toast("vanilla", {
        subject: "All guided tours marked incomplete.",
      });
    }),
  );
