import { cx } from "@emotion/css";
import { IChannelDoc, ISecretEmailThreadDoc } from "@libs/firestore-models";
import { mapSecretEmailThreadToEmailThread } from "@libs/firestore-models/utils";
import { isEqual } from "@libs/utils/isEqual";
import { isNonNullable } from "@libs/utils/predicates";
import { limit, query, where } from "firebase/firestore";
import { pick } from "lodash-es";
import { ComponentType, ReactElement } from "react";
import { Link, useParams } from "react-router-dom";
import { collectionData, docData } from "~/utils/rxFireWrappers";
import { combineLatest, distinctUntilChanged, map, of, switchMap } from "rxjs";
import { collectionRef, docRef } from "~/firestore.service";
import { ASSERT_CURRENT_USER_ID$ } from "~/services/user.service";
import { useObservable } from "~/utils/useObservable";
import { Tooltip } from "./Tooltip";

export const LabelChip: ComponentType<{
  href?: string;
  tooltip?: string | ReactElement;
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  colorClassName?: string;
}> = (props) => {
  if (props.href) {
    return (
      <Tooltip side="bottom" content={props.tooltip}>
        <Link
          to={props.href}
          className={cx(
            LabelSizeClasses,
            props.colorClassName ||
              LabelColorClasses + " hover:border-black hover:text-black",
          )}
          onClick={props.onClick}
        >
          {props.children}
        </Link>
      </Tooltip>
    );
  } else if (props.onClick) {
    return (
      <Tooltip side="bottom" content={props.tooltip}>
        <button
          type="button"
          className={cx(
            LabelSizeClasses,
            props.colorClassName ||
              LabelColorClasses + " hover:border-black hover:text-black",
          )}
          onClick={props.onClick}
        >
          {props.children}
        </button>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip side="bottom" content={props.tooltip}>
        <span
          className={cx(
            LabelSizeClasses,
            props.colorClassName || LabelColorClasses,
            props.tooltip && "hover:cursor-help",
          )}
        >
          {props.children}
        </span>
      </Tooltip>
    );
  }
};

const LabelSizeClasses = `
  border rounded text-xs truncate max-w-[7rem] pt-[3px] 
  px-[6px] pb-[2px] font-medium
`;

const LabelColorClasses = `
  bg-transparent border-slateA-9 text-slateA-9 
`;

const LabelClasses = LabelSizeClasses + LabelColorClasses;

interface IChannelLabelsProps {
  channels: Array<Pick<IChannelDoc, "id" | "name">>;
}

export const ChannelLabels: ComponentType<IChannelLabelsProps> = (props) => {
  const params = useParams();

  // If we're on a page associated with a specific channel then we
  // don't need to include that channel when rendering the labels
  // (i.e. the inclusion of that channel is implied).
  const channels = params.channelId
    ? props.channels.filter((c) => c.id !== params.channelId)
    : props.channels;

  return (
    <>
      {channels.slice(0, 2).map(({ id, name }) => {
        return (
          <LabelChip
            key={id}
            tooltip={name}
            href={`/channels/${id}`}
            onClick={(e) => e.stopPropagation()}
          >
            #{name}
          </LabelChip>
        );
      })}

      {renderOverflow(channels)}
    </>
  );
};

/**
 * @summary Skips the first two array items and returns the other items.
 */
const getChannelNames = (channels: IChannelLabelsProps["channels"]) =>
  channels.slice(2).map((c) => `#${c.name}`);

/**
 * @summary renders any additional channels to avoid cluttering the UI.
 */
const renderOverflow = (channels: IChannelLabelsProps["channels"]) => {
  const overflow = channels.length - 2;

  if (overflow <= 0) {
    return null;
  }

  return (
    <Tooltip side="bottom" content={getChannelNames(channels).join(", ")}>
      <div className={LabelClasses}>{`+${overflow}`}</div>
    </Tooltip>
  );
};

/**
 *
 * @returns `undefined` while loading else returns channel name objects.
 */
export function useThreadChannelNames(threadId?: string) {
  return useObservable(
    () => {
      if (!threadId) return of([]);

      // Note we're intentionally ignoring optimistic updates to this thread's
      // channels. Rendering the optimistic updates in a large inbox was
      // causing memory spikes as drafts where changed.
      return docData(docRef("threads", threadId)).pipe(
        switchMap((thread) => {
          if (thread) return of(thread);

          return ASSERT_CURRENT_USER_ID$.pipe(
            switchMap((currentUserId) =>
              collectionData(
                query(
                  collectionRef<ISecretEmailThreadDoc>("threads"),
                  where("type", "==", "EMAIL_SECRET"),
                  where("forThreadId", "==", threadId),
                  where("forUserId", "==", currentUserId),
                  limit(1),
                ),
              ),
            ),
            map(
              (threads) =>
                (threads[0] && mapSecretEmailThreadToEmailThread(threads[0])) ||
                null,
            ),
          );
        }),
        switchMap((thread) => {
          if (!thread || thread.permittedChannelIds.length === 0) return of([]);

          return combineLatest(
            thread.permittedChannelIds.map((channelId) =>
              docData(docRef("channels", channelId)).pipe(
                map((channel) =>
                  channel && !channel.isOrganizationSharedChannel
                    ? pick(channel, "id", "name")
                    : null,
                ),
                distinctUntilChanged(isEqual),
              ),
            ),
          );
        }),
        map((channels) => channels.filter(isNonNullable)),
      );
    },
    { deps: [threadId] },
  );
}
