import { cx } from "@emotion/css";
import { ISubscriptionDoc, IThreadDoc } from "@libs/firestore-models";
import { ComponentType, Fragment, useEffect, useRef, useState } from "react";
import { useUsersWhoCanViewThread } from "~/services/channels.service";
import { IAcceptedOrganizationMemberDoc } from "~/services/organization.service";
import { useUsersWhoAreSubscribedToThread } from "~/services/subscription.service";

/**
 * Used to render the "Can view" section of the
 * ThreadView sidepanel as well as the "Will be able to view" section
 * of the NewThreadForm.
 */
export const UsersWhoCanViewThreadSection: ComponentType<{
  thread?: Pick<IThreadDoc, "permittedChannelIds" | "permittedUserIds"> | null;
}> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const canViewData = useUsersWhoCanViewThread(props.thread);
  const [expandCanView, setExpandCanView] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (!ref.current) return;
    setIsTruncated(ref.current.offsetHeight < ref.current.scrollHeight);
  }, [canViewData]);

  return (
    <div className="m-4">
      <p className="text-slate-9">{props.children}</p>

      <div ref={ref} className={cx("mt-2", !expandCanView && "line-clamp-4")}>
        {canViewData === undefined && "Loading..."}

        {canViewData?.permittedUsers.join(", ")}

        {!canViewData?.unknownPermittedUsersCount ? null : (
          <span className="ml-1">
            + {canViewData.unknownPermittedUsersCount} unknown user
            {canViewData.unknownPermittedUsersCount !== 1 && "s"}
          </span>
        )}

        {!canViewData?.unknownPermittedChannelsCount ? null : (
          <span className="ml-1">
            + {canViewData.unknownPermittedChannelsCount} unknown channel
            {canViewData.unknownPermittedChannelsCount !== 1 && "s"}
          </span>
        )}
      </div>

      {isTruncated && (
        <button
          type="button"
          className="text-blue-9 hover:underline"
          onClick={() => setExpandCanView((s) => !s)}
        >
          Show {expandCanView ? "less" : "more"}
        </button>
      )}
    </div>
  );
};

/**
 * Used to render the "Will receive replies" section of the
 * ThreadView sidepanel as well as the "Will be notified" section
 * of the NewThreadForm.
 */
export const UsersWhoWillReceiveThreadNotificationsSection: ComponentType<{
  thread:
    | Pick<IThreadDoc, "id" | "permittedChannelIds" | "participatingUserIds">
    | null
    | undefined;
  onlyCountTheseSubscriptionPreferences: readonly ISubscriptionDoc["preference"][];
  dontIncludeCurrentUser?: boolean;
}> = (props) => {
  const ref = useRef<HTMLDivElement>(null);

  const subscriberData = useUsersWhoAreSubscribedToThread(props);

  const [isTruncated, setIsTruncated] = useState(false);
  const [expandCanView, setExpandCanView] = useState(false);

  useEffect(() => {
    if (!ref.current) return;
    setIsTruncated(ref.current.offsetHeight < ref.current.scrollHeight);
  }, [subscriberData]);

  return (
    <div className="m-4">
      <p className="text-slate-9">{props.children}</p>

      <div ref={ref} className={cx("mt-2", !expandCanView && "line-clamp-4")}>
        {!subscriberData ? (
          "Loading..."
        ) : (
          <Subscribers
            subscribers={subscriberData.knownSubscribers}
            unknownSubscribersCount={subscriberData.unknownSubscribersCount}
          />
        )}
      </div>

      {isTruncated && (
        <button
          type="button"
          className="text-blue-9 hover:underline"
          onClick={() => setExpandCanView((s) => !s)}
        >
          Show {expandCanView ? "less" : "more"}
        </button>
      )}
    </div>
  );
};

const Subscribers: ComponentType<{
  subscribers: Array<IAcceptedOrganizationMemberDoc["user"] & { id: string }>;
  unknownSubscribersCount: number;
}> = (props) => {
  if (props.subscribers.length === 0 && props.unknownSubscribersCount === 0) {
    return <em>no one</em>;
  }

  return (
    <>
      {props.subscribers.map((subscriber, index) => {
        return (
          <Fragment key={subscriber.id}>
            {index !== 0 && <span>,</span>} {subscriber.name}
          </Fragment>
        );
      })}

      {props.unknownSubscribersCount > 0 && (
        <span className="ml-1">
          and {props.unknownSubscribersCount} other user
          {props.unknownSubscribersCount !== 1 && "s"}
        </span>
      )}
    </>
  );
};
