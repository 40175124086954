export function resolvablePromise<T>() {
  let resolve: (value?: T | PromiseLike<T>) => void;
  let reject: (reason?: unknown) => void;

  const promise = new Promise<T>((res, rej) => {
    resolve = res as typeof resolve;
    reject = rej;
  }) as Promise<T> & {
    resolve: typeof resolve;
    reject: typeof reject;
  };

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  promise.resolve = resolve!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  promise.reject = reject!;

  return promise;
}
