import { createContext } from "react";
import { createUseContextHook } from "~/utils/createUseContextHook";

export interface IThreadPostEntryContext {
  isEditingPostId: string | null;
  setIsEditingPostId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const ThreadPostEntryContext =
  createContext<IThreadPostEntryContext | null>(null);

export const useThreadPostEntryContext = createUseContextHook(
  ThreadPostEntryContext,
  "ThreadPostEntryContext",
);
