import {
  DialogState,
  DialogTitle,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import {
  createFormControl,
  createFormGroup,
  useControl,
} from "solid-forms-react";
import { handleSubmit, useControlState } from "~/form-components/utils";
import { toast } from "~/services/toast-service";
import { isAppOnline } from "~/services/network-connection.service";
import {
  IRecipientOption,
  IChannelRecipientOption,
  ThreadRecipients,
} from "~/form-components/ThreadRecipients";
import {
  IChannelDoc,
  IThreadDoc,
  ThreadVisibility,
} from "@libs/firestore-models";
import { updateThread } from "~/services/post.service";
import {
  PLATFORM_MODIFIER_KEY,
  useRegisterCommands,
} from "~/services/command.service";
import {
  pendingRequestBarService,
  withPendingRequestBar,
} from "~/components/PendingRequestBar";
import { SubmitDialogHint } from "../DialogLayout";
import { useAutocompleteMenuPositioning } from "~/form-components/AutocompleteSelect";
import { FirebaseError } from "firebase/app";
import { Tooltip } from "~/components/Tooltip";

export interface IEditThreadDialogData {
  threadId: string;
  isThreadPrivate: boolean;
  knownPermittedChannels: IChannelDoc[];
}

export const EditThreadDialogState = new DialogState<IEditThreadDialogData>();

interface IFormValue {
  threadId: string;
  channelRecipients: IChannelRecipientOption[];
  visibility: ThreadVisibility;
}

export function openEditThreadDialog(
  threadId: IThreadDoc["id"],
  knownPermittedChannels: IChannelDoc[],
  visibility: IThreadDoc["visibility"],
) {
  if (!isAppOnline()) {
    toast("vanilla", {
      subject: "Not supported in offline mode",
      description: "Can't update thread channels while offline.",
    });

    return;
  }

  EditThreadDialogState.toggle(true, {
    threadId,
    isThreadPrivate: visibility === "private",
    knownPermittedChannels,
  });
}

export const EditThreadDialog = withModalDialog({
  dialogState: EditThreadDialogState,
  Component: (props) => {
    const control = useControl(() =>
      createFormGroup({
        threadId: createFormControl(props.data.threadId),
        visibility: createFormControl<ThreadVisibility>(
          props.data.isThreadPrivate ? "private" : "shared",
        ),
        channelRecipients: createFormControl<IChannelRecipientOption[]>(
          props.data.knownPermittedChannels
            .map((channel) => ({
              type: "channel" as const,
              value: channel.id,
              label: channel.name,
              classification: channel.classification,
              // Once an option has been selected, IRecipientOption#channelGroupNames
              // isn't used anymore so this is ok.
              channelGroupNames: [],
              isFixed: channel.isOrganizationSharedChannel,
            }))
            .sort((a, b) => {
              if (a.isFixed) return -1;
              if (b.isFixed) return 1;
              return 0;
            }),
        ),
      }),
    );

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              EditThreadDialogState.toggle(false);
            },
          },
          {
            label: "Mark thread as private",
            hotkeys: [
              "$mod+Alt+p",
              // Note that, depending on the browser, the emitted KeyboardEvent#key
              // value may be "p" or "P" (chrome on windows seems to do "p" whereas
              // Firefox does "P"). For this reason, we also add a second shortcut
              // using "KeyP". The first shortcut will be shown in the kbar
              "$mod+Alt+KeyP",
            ],
            triggerHotkeysWhenInputFocused: true,
            callback: onlyCallFnOnceWhilePreviousCallIsPending(async () => {
              const newVisibility =
                control.rawValue.visibility === "private"
                  ? "shared"
                  : "private";

              EditThreadDialogState.toggle(false);

              toast("vanilla", {
                subject: `Marking thread ${newVisibility}...`,
              });

              const loadingComplete = pendingRequestBarService.markLoading();

              try {
                await updateThread({
                  threadId: control.rawValue.threadId,
                  visibility: newVisibility,
                });
              } finally {
                loadingComplete();
              }

              toast("vanilla", {
                subject: `Thread is now ${newVisibility}`,
              });
            }),
          },
          {
            label: "Submit form",
            hotkeys: ["$mod+Enter"],
            triggerHotkeysWhenInputFocused: true,
            callback: onlyCallFnOnceWhilePreviousCallIsPending(async () => {
              console.log("attempting submit");
              handleSubmit(control, submit);
            }),
          },
        ];
      },
    });

    const visibility = useControlState(
      () => control.rawValue.visibility,
      [control],
    );

    const [
      recipientsAutocompleteRef,
      recipientsAutocompletePortalEl,
      recipientsAutocompletePortalJSX,
    ] = useAutocompleteMenuPositioning<IRecipientOption, true>();

    return (
      <>
        <DialogTitle>
          <h2>Update thread channels</h2>
        </DialogTitle>

        <div className={DIALOG_CONTENT_WRAPPER_CSS}>
          <div className="px-4 py-2">
            <ThreadRecipients
              ref={recipientsAutocompleteRef}
              name="recipients"
              control={control.controls.channelRecipients}
              threadType="COMMS"
              isThreadPrivate={visibility === "private"}
              onlyRecipientsOfType="channel"
              autocompleteMenuPortalEl={recipientsAutocompletePortalEl}
              autoFocus
            />
          </div>

          <div className="flex px-4 py-2 border-t border-mauve-5">
            <div className="flex-1" />

            <Tooltip
              side="bottom"
              content={`${PLATFORM_MODIFIER_KEY.name} + Enter`}
            >
              <button
                type="button"
                className={`rounded bg-slate-5 border px-2
                  border-slate-9 text-sm hover:border-black hover:bg-slate-7`}
                onClick={() => handleSubmit(control, submit)}
              >
                Submit
              </button>
            </Tooltip>
          </div>
        </div>

        {recipientsAutocompletePortalJSX}

        <SubmitDialogHint />
      </>
    );
  },
});

const submit = withPendingRequestBar(async (values: IFormValue) => {
  console.log("submitting...", values);

  EditThreadDialogState.toggle(false);

  toast("vanilla", {
    subject: "Updating thread...",
  });

  let result: Awaited<ReturnType<typeof updateThread>>;

  try {
    result = await updateThread({
      threadId: values.threadId,
      visibility: values.visibility,
      permittedChannelIds: values.channelRecipients.map((c) => c.value),
    });
  } catch (e) {
    if (e instanceof FirebaseError) {
      if (e.message.match("Thread with id .* does not exist")) {
        toast("vanilla", {
          subject: `
            Failed to update thread because it hasn't finished 
            being sent.
          `,
          description: "Try waiting 30 seconds and trying again.",
        });

        return;
      }
    }

    console.error("Error updating thread", e);

    toast("vanilla", {
      subject: "Failed to update thread for an unknown reason.",
      description: "Try again?",
    });

    return;
  }

  if (!result.data.success) {
    console.log("submission failed", result);

    toast("vanilla", {
      subject: "Failed to update thread for an unknown reason.",
      description: "Try again?",
    });

    return;
  }

  console.log("submitted successfully!");

  toast("vanilla", {
    subject: "Thread channels updated.",
  });
});
