import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  firstValueFrom,
  fromEvent,
  interval,
  map,
  merge,
  of,
  race,
  shareReplay,
  switchMap,
  take,
} from "rxjs";
import { startWith } from "~/utils/rxjs-operators";
import { wait } from "@libs/utils/wait";
import { disableNetwork, enableNetwork } from "firebase/firestore";
import { db } from "~/firebase";
import { useObservable } from "~/utils/useObservable";

const FORCE_OFFLINE_MODE$ = new BehaviorSubject(false);

/**
 * An observable indicating if the user's device is online.
 * In general, you should use the `APP_ONLINE$` observable
 * instead of this one. Even if the user's device is online,
 * they might still have chosen to enter "offline mode".
 * `APP_ONLINE$` will tell you if the app is functioning in
 * offline mode or not.
 */
// Navigator#onLine is not a reliable method for checking to see
// if the client has a connection. If navigator#onLine is `false`,
// then the client definitely doesn't have internet. But if it's
// `true`, the client may or may not have internet.
// For more info, see https://stackoverflow.com/a/73159461/5490505
export let DEVICE_ONLINE$ = merge(
  fromEvent(window, "online").pipe(map(() => true)),
  fromEvent(window, "offline").pipe(map(() => false)),
).pipe(
  startWith(() => navigator.onLine),
  distinctUntilChanged(),
  switchMap((onLine) =>
    !onLine
      ? of(false)
      : interval(10_000).pipe(
          startWith(() => null),
          switchMap(() =>
            race(
              interval(9900).pipe(
                take(1),
                map(() => false),
              ),
              fetch("/connection.txt", {
                method: "HEAD",
                cache: "no-store",
              })
                .then((r) => r.ok)
                .catch(() => false),
            ),
          ),
        ),
  ),
  startWith(() => navigator.onLine),
  distinctUntilChanged(),
  shareReplay(1),
);

if (import.meta.env.VITE_FIREBASE_EMULATORS === "true") {
  const override = new BehaviorSubject(
    import.meta.env.VITE_FIREBASE_EMULATORS_PRETEND_ONLINE === "true",
  );

  DEVICE_ONLINE$ = combineLatest([override, DEVICE_ONLINE$]).pipe(
    map(([override, onLine]) => override || onLine),
    distinctUntilChanged(),
    shareReplay(1),
  );

  // Manual toggle in dev mode for pretending if the device is online
  // or offline.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (globalThis as any).COMMS_DEV = {
    pretendDeviceIsOnlne(value: boolean) {
      override.next(value);
    },
  };
}

/**
 * An observable indicating if the app is functioning
 * in offline mode or not. Emits `true` while it is
 * in "online mode".
 */
export const APP_ONLINE$ = combineLatest([
  DEVICE_ONLINE$,
  FORCE_OFFLINE_MODE$,
]).pipe(
  map(([isDeviceOnline, forceOfflineMode]) =>
    forceOfflineMode ? false : isDeviceOnline,
  ),
  distinctUntilChanged(),
  shareReplay(1),
);

let isOnline = navigator.onLine;

if (import.meta.env.MODE !== "test") {
  DEVICE_ONLINE$.subscribe((onLine) => {
    console.debug("DEVICE ONLINE", onLine);
    isOnline = onLine;
  });

  APP_ONLINE$.subscribe((onLine) => {
    console.debug("APP ONLINE", onLine);

    if (onLine) {
      enableNetwork(db);
    } else {
      disableNetwork(db);
    }
  });
}

export function forceOfflineMode(value: boolean) {
  FORCE_OFFLINE_MODE$.next(value);
}

/**
 * Returns `true` if the user's device appears to be online.
 * In general, `isAppOnline()` should be preferred since,
 * even if the user's device is online, the app may still
 * be functioning in offline mode.
 */
export function isDeviceOnline() {
  return isOnline;
}

export function useIsDeviceOnline() {
  return useObservable(() => DEVICE_ONLINE$, {
    synchronous: true,
  });
}

/**
 * Returns true if the app is functioning in online mode.
 */
export function isAppOnline() {
  return FORCE_OFFLINE_MODE$.getValue() ? false : isOnline;
}

export function useIsAppOnline() {
  return useObservable(() => APP_ONLINE$, {
    synchronous: true,
  });
}

/**
 * Promises from Firestore CRUD operations never resolve until the
 * update has been committed to the server. When offline, the promise
 * never resolves.
 *
 * If you pass a firestore CRUD promise to this helper then you can await the
 * result in an offline-friendly way.
 *
 * **Example:**
 *
 * ```ts
 * const promise = setDoc(ref, doc);
 * await offlineAwareFirestoreCRUD(promise);
 * ```
 */
export function offlineAwareFirestoreCRUD(
  promise: Promise<unknown>,
): Promise<unknown>;
/**
 * Promises from Firestore CRUD operations never resolve until the
 * update has been committed to the server. When offline, the promise
 * never resolves.
 *
 * If you pass a callback it will receive the current onLine value and
 * expects a promise to be returned. If the onLine value changes before
 * the promise is resolved the callback will rerun and the previous
 * value will be ignored.
 *
 * **Example:**
 *
 * ```ts
 * const promise = setDoc(ref, doc);
 * return offlineAwareFirestoreCRUD(async (onLine) => {
 *   if (onLine) return promise;
 *
 *   return Promise.race([
 *     wait(2000).then(() => false),
 *     firstValueFrom(
 *       observeDraft(args.postId).pipe(
 *         filter((doc) => doc?.scheduledToBeSent === false),
 *       ),
 *     ).then(() => true),
 *   ]);
 * });
 * ```
 */
export function offlineAwareFirestoreCRUD<T>(
  promise: (onLine: boolean) => Promise<T>,
): Promise<T>;
export function offlineAwareFirestoreCRUD<T>(
  promise: Promise<T> | ((onLine: boolean) => Promise<T>),
) {
  const handler =
    promise instanceof Function
      ? promise
      : (onLine: boolean) => {
          if (onLine) {
            return promise;
          } else {
            return wait(500);
          }
        };

  return firstValueFrom(APP_ONLINE$.pipe(switchMap(handler)));
}
