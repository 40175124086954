import { ComponentType, useEffect, useMemo, useState } from "react";
import {
  DialogState,
  DialogTitle,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { createFormControl, useControl } from "solid-forms-react";
import { useControlState } from "~/form-components/utils";
import { toast } from "~/services/toast-service";
import { useRegisterCommands } from "~/services/command.service";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import {
  combineLatest,
  firstValueFrom,
  interval,
  map,
  of,
  scan,
  switchMap,
  takeWhile,
} from "rxjs";
import { useNextScheduledDeliveryDatetime } from "~/services/inbox.service";
import dayjs from "dayjs";
import {
  CURRENT_USER_MAIN_SETTINGS$,
  mergeMainSettings,
  TNormalizedMainSettingsDoc,
} from "~/services/settings.service";
import { TextInput } from "~/form-components/TextInput";
import { WINDOW_FOCUSED$, WINDOW_VISIBLE$ } from "~/services/focus.service";
import { cx } from "@emotion/css";
import { serverTimestamp } from "firebase/firestore";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { deliverMessagesNowCommand } from "~/utils/common-commands";

export type IDeliverMessagesNowDialogData = {
  settings: TNormalizedMainSettingsDoc;
};

export type IDeliverMessagesNowDialogReturnData = { success: boolean } | void;

export const DeliverMessagesNowDialogState = new DialogState<
  IDeliverMessagesNowDialogData,
  IDeliverMessagesNowDialogReturnData
>();

export const DeliverMessagesNowDialog = withModalDialog({
  dialogState: DeliverMessagesNowDialogState,
  useOnDialogContainerRendered() {
    useRegisterCommands({
      commands: () => {
        return CURRENT_USER_MAIN_SETTINGS$.pipe(
          map((settings) => {
            if (settings.secondsToWaitToDisableScheduledDelivery) {
              return [
                deliverMessagesNowCommand({
                  callback: () => {
                    DeliverMessagesNowDialogState.toggle(true);
                  },
                }),
              ];
            } else {
              return [
                deliverMessagesNowCommand({
                  callback: submit,
                }),
              ];
            }
          }),
        );
      },
    });
  },
  async loadData() {
    return {
      settings: await firstValueFrom(CURRENT_USER_MAIN_SETTINGS$),
    };
  },
  Component({ data }) {
    if (!data) {
      throw new Error("DeliverMessagesNowDialog: expected data to be provided");
    }

    const [currentStep, setCurrentStep] = useState(1);
    const [submitAttempt, setSubmitAttempt] = useState(false);

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              DeliverMessagesNowDialogState.toggle(false);
            },
          },
        ];
      },
    });

    return (
      <>
        <DialogTitle>
          <h2>Deliver Messages Now</h2>
        </DialogTitle>

        <form
          // we intentionally don't allow submitting this form
          // with the keyboard
          onSubmit={(e) => {
            e.preventDefault();
            setSubmitAttempt(true);
          }}
          className={DIALOG_CONTENT_WRAPPER_CSS}
        >
          {currentStep === 2 ? (
            <SecondStep
              initialTimerValue={
                data.settings.secondsToWaitToDisableScheduledDelivery < 30
                  ? data.settings.secondsToWaitToDisableScheduledDelivery
                  : 30
              }
            />
          ) : (
            <FirstStep
              submitAttempt={submitAttempt}
              onClick={() => setCurrentStep(2)}
            />
          )}
        </form>
      </>
    );
  },
});

const FirstStep: ComponentType<{
  submitAttempt: boolean;
  onClick: () => void;
}> = (props) => {
  const control = useControl(() => {
    return createFormControl("", {
      validators: (value) =>
        value.toLowerCase() ===
        "i would like to break my focus and get my messages now"
          ? null
          : { invalid: true },
    });
  });

  useEffect(() => {
    if (!props.submitAttempt) return;
    control.markTouched(true);
  }, [props.submitAttempt, control]);

  const confirmed = useControlState(
    () =>
      control.rawValue.toLowerCase() ===
      "i would like to break my focus and get my messages now",
    [control],
  );

  const nextScheduledDeliveryDatetime = useNextScheduledDeliveryDatetime();

  const nextScheduledDelivery = useMemo(() => {
    if (!nextScheduledDeliveryDatetime) return;

    return convertDateTimeToRelativeTimeString(nextScheduledDeliveryDatetime);
  }, [nextScheduledDeliveryDatetime]);

  return (
    <>
      <div className="p-4">
        <p>
          Are you sure you wish to bypass scheduled delivery and have your
          messages delivered immediately? Your next scheduled delivery is{" "}
          {nextScheduledDelivery}.
        </p>

        <div className="h-4" />

        <p>
          Type "I would like to break my focus and get my messages now" then
          press continue.
        </p>

        <TextInput
          name="Type here"
          control={control}
          className="mt-4 border border-slate-9 w-full rounded px-2 py-1"
        />

        {props.submitAttempt && (
          <p className="mt-4 font-bold">
            You must use your mouse to click "Continue".
          </p>
        )}
      </div>

      <div className="p-4 border-t flex items-center">
        <button
          type="button"
          className={`rounded bg-slate-5 px-2 border 
            border-slate-9 text-sm hover:border-black hover:bg-slate-7`}
          onClick={() => DeliverMessagesNowDialogState.toggle(false)}
        >
          Cancel
        </button>

        <div className="flex-1" />

        <button
          type="button"
          className={`rounded bg-slate-5 px-2 border 
            border-slate-9 text-sm hover:border-black hover:bg-slate-7`}
          disabled={!confirmed}
          onClick={props.onClick}
        >
          Continue
        </button>
      </div>
    </>
  );
};

const SecondStep: ComponentType<{ initialTimerValue: number }> = (props) => {
  const [countdown, setCountdown] = useState<number | "cancelled">(
    props.initialTimerValue,
  );

  useEffect(() => {
    const sub = combineLatest([WINDOW_VISIBLE$, WINDOW_FOCUSED$])
      .pipe(
        switchMap(([visible, focused]) =>
          !visible || !focused
            ? of("cancelled" as const)
            : interval(1000).pipe(
                scan(
                  (acc) => (acc <= 0 ? 0 : acc - 1),
                  props.initialTimerValue,
                ),
                takeWhile((value) => value !== 0, true),
              ),
        ),
      )
      .subscribe(setCountdown);

    return () => sub.unsubscribe();
  }, [props.initialTimerValue]);

  return (
    <>
      <div className="p-4">
        <p>
          Please wait for the countdown to finish then press "Deliver now",
          below. You must keep this window active or else the timer will reset.
        </p>

        <div className="h-4" />

        <button
          type="button"
          disabled={countdown !== 0}
          className={cx(
            "text-3xl font-bold text-slate-11 bg-slate-3 border",
            "px-4 py-1 rounded disabled:text-slate-8 active:bg-slate-5",
          )}
          onClick={() => submit()}
        >
          {countdown === "cancelled"
            ? "Cancelled"
            : countdown === 0
            ? `Deliver now`
            : `Deliver now in ${countdown}s`}
        </button>
      </div>

      <div className="p-4 border-t flex items-center">
        <button
          type="button"
          className={`rounded bg-slate-5 px-2 border 
            border-slate-9 text-sm hover:border-black hover:bg-slate-7`}
          onClick={() => DeliverMessagesNowDialogState.toggle(false)}
        >
          Cancel
        </button>
      </div>
    </>
  );
};

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(async () => {
    console.log("submitting...");

    DeliverMessagesNowDialogState.toggle(false);

    toast("vanilla", {
      subject: "Messages delivered",
    });

    await mergeMainSettings({
      mostRecentDeliverNow: serverTimestamp(),
    });
  }),
);

function convertDateTimeToRelativeTimeString(datetime: dayjs.Dayjs) {
  if (!datetime) return;

  const now = dayjs();

  const differenceInDays =
    datetime.startOf("day").diff(now.startOf("day"), "date") /
    MILLISECONDS_IN_DAY;

  let time = rtf.format(differenceInDays, "day");
  time += " at ";
  time +=
    datetime.get("minutes") === 0
      ? datetime.format("ha")
      : datetime.format("h:mma");

  return time;
}

const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

const rtf = new Intl.RelativeTimeFormat("en", {
  localeMatcher: "best fit",
  numeric: "auto",
  style: "short",
});
