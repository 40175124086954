import { ComponentType, memo, useState, useEffect } from "react";
import {
  DialogState,
  DialogTitle,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { useRegisterCommands } from "~/services/command.service";
import { SubmitDialogHint } from "../DialogLayout";
import { IInboxSectionDoc } from "@libs/firestore-models";
import { deleteInboxSection, getInboxSections } from "~/services/inbox.service";
import { OutlineButton } from "~/components/OutlineButtons";
import { entryCSSClasses } from "~/components/content-list";
import { isEqual } from "@libs/utils/isEqual";
import { css, cx } from "@emotion/css";
import { navigateService } from "~/services/navigate.service";
import { getAndAssertCurrentUser } from "~/services/user.service";
import { docRef } from "~/firestore.service";
import { serverTimestamp, updateDoc } from "@firebase/firestore";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { RxDragHandleHorizontal } from "react-icons/rx";
import { debounceTime, Subject } from "rxjs";
import useConstant from "use-constant";

export type IEditInboxSectionsDialogData = {
  inboxSections: IInboxSectionDoc[];
};

export type IEditInboxSectionsDialogReturnData = { success: boolean } | void;

export const EditInboxSectionsDialogState = new DialogState<
  IEditInboxSectionsDialogData,
  IEditInboxSectionsDialogReturnData
>();

export const EditInboxSectionsDialog = withModalDialog({
  dialogState: EditInboxSectionsDialogState,
  useOnDialogContainerRendered() {
    useRegisterCommands({
      commands() {
        return [
          {
            label: "Edit inbox sections",
            callback() {
              EditInboxSectionsDialogState.toggle(true);
            },
          },
        ];
      },
    });
  },
  async loadData() {
    return {
      inboxSections: await getInboxSections(),
    };
  },
  Component: ({ data }) => {
    if (!data) {
      throw new Error("No data provided to EditInboxSectionsDialog");
    }

    const [orderedSections, setOrderedSections] = useState(data.inboxSections);

    usePersistSectionOrderChanges(orderedSections);

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              EditInboxSectionsDialogState.toggle(false);
            },
          },
        ];
      },
    });

    const hasInboxSections = orderedSections.length > 0;

    return (
      <>
        <DialogTitle>
          <h2>Edit inbox sections</h2>
        </DialogTitle>

        <div className={DIALOG_CONTENT_WRAPPER_CSS}>
          <div className="m-4 prose">
            <p>
              <em>
                Learn about inbox sections by watching this{" "}
                <a
                  href="https://www.loom.com/share/344b5015d14f433c9d60398cb4976a20"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instructional Loom Video
                </a>
              </em>
            </p>
          </div>

          {!hasInboxSections ? (
            <div className="m-4">No inbox sections</div>
          ) : (
            <div className="m-4">
              <hr className="mb-2" />

              <DragDropContext
                onDragStart={(start) => {
                  addClassToDraggedEntry(start.draggableId);
                }}
                onDragEnd={(result) => {
                  removeClassToDraggedEntry(result.draggableId);

                  if (!result.destination) {
                    return;
                  }

                  if (result.destination.index === result.source.index) {
                    return;
                  }

                  const newOrderedSections = reorder(
                    orderedSections,
                    result.source.index,
                    result.destination.index,
                  );

                  setOrderedSections(newOrderedSections);
                }}
              >
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {orderedSections.map((section, index) => (
                        <InboxSubsectionEntry
                          key={section.id}
                          section={section}
                          index={index}
                        />
                      ))}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <hr className="mt-2" />
            </div>
          )}

          <div className="m-4">
            <OutlineButton
              onClick={() => {
                EditInboxSectionsDialogState.toggle(false);
                navigateService("/inbox/new");
              }}
            >
              Add inbox section
            </OutlineButton>
          </div>
        </div>

        <SubmitDialogHint />
      </>
    );
  },
});

function addClassToDraggedEntry(draggableId: string) {
  const draggedEl = document.querySelector(
    `[data-rbd-draggable-id="${draggableId}"]`,
  );

  if (!draggedEl) return;

  draggedEl.classList.add("being-dragged");
}

function removeClassToDraggedEntry(draggableId: string) {
  const draggedEl = document.querySelector(
    `[data-rbd-draggable-id="${draggableId}"]`,
  );

  if (!draggedEl) return;

  draggedEl.classList.remove("being-dragged");
}

const reorder = (
  subsections: IInboxSectionDoc[],
  startIndex: number,
  endIndex: number,
) => {
  const newList = Array.from(subsections);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const removed = newList.splice(startIndex, 1)[0]!;
  newList.splice(endIndex, 0, removed);

  return newList;
};

const InboxSubsectionEntry: ComponentType<{
  section: IInboxSectionDoc;
  index: number;
}> = memo((props) => {
  return (
    <Draggable draggableId={props.section.id} index={props.index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={reactBeautifulDnDCssHack}
        >
          <div className={inboxSubsectionEntryCss}>
            <div {...provided.dragHandleProps}>
              <RxDragHandleHorizontal size={30} className="text-slate-8" />
            </div>

            <div className="font-medium">{props.section.name}</div>

            <div className="flex-1" />

            <OutlineButton
              onClick={() => {
                EditInboxSectionsDialogState.toggle(false);
                navigateService(`/inbox/${props.section.id}/edit`);
              }}
            >
              Edit
            </OutlineButton>

            <OutlineButton
              onClick={() => {
                EditInboxSectionsDialogState.toggle(false);
                deleteInboxSection(props.section.id);
              }}
            >
              Delete
            </OutlineButton>
          </div>
        </div>
      )}
    </Draggable>
  );
}, isEqual);

const inboxSubsectionEntryCss = cx(
  entryCSSClasses,
  "border",
  css`
    padding-left: 0;
    padding-right: 0;
  `,
);

/**
 * React Beautiful DnD has a bug where the positioning CSS won't work for the element
 * being dragged if the element is inside a container with `position: fixed`. Applying
 * this CSS fixes the issue.
 *
 * See https://github.com/atlassian/react-beautiful-dnd/issues/1881#issuecomment-1464944428
 */
const reactBeautifulDnDCssHack = css`
  &.being-dragged {
    left: auto !important;
    top: auto !important;
  }
`;

function usePersistSectionOrderChanges(sections: IInboxSectionDoc[]) {
  const orderedSectionChanges$ = useConstant(
    () => new Subject<IInboxSectionDoc[]>(),
  );

  useEffect(() => {
    orderedSectionChanges$.next(sections);
  }, [orderedSectionChanges$, sections]);

  // We use an observable to react to changes so that we can easily debounce the
  // changes while also ensuring that we eventually persist the changes, even
  // if the component is unmounted.
  useEffect(() => {
    orderedSectionChanges$
      .pipe(
        // Note, we don't need to skip the first emission (i.e. the one that happens on mount)
        // when subscribe to this observable because the first emission occurs before we've
        // subscribed to this observable.
        debounceTime(1000),
      )
      .subscribe((sections) => {
        const currentUser = getAndAssertCurrentUser();

        Promise.all(
          sections.map((section, index) => {
            return updateDoc(
              docRef("users", currentUser.id, "inboxSections", section.id),
              {
                order: index,
                updatedAt: serverTimestamp(),
              },
            );
          }),
        );
      });
  }, [orderedSectionChanges$]);
}
