import { IThreadDoc } from "@libs/firestore-models";
import { ComponentType, RefObject } from "react";
import { ThreadPostEntry } from "./thread-post-entry/ThreadPostEntry";
import { Observable } from "rxjs";
import type { Timestamp } from "@firebase/firestore-types";
import { useThreadContext } from "./context";
import { QuoteBranchedThreadPostsBase } from "~/components/QuoteBranchedThreadPostsBase";
import { IListRef } from "~/components/list";
import { TThreadTimelineEntry } from "./utils";
import { IThreadRecipients } from "./ReplyDraftHeader";

/* -------------------------------------------------------------------------------------------------
 * QuoteBranchedThreadPosts
 * -----------------------------------------------------------------------------------------------*/

export const QuoteBranchedThreadPosts: ComponentType<{
  listRef: RefObject<IListRef<TThreadTimelineEntry>>;
  branchCreatedAt: Timestamp;
  branchedFrom: NonNullable<IThreadDoc["branchedFrom"]>;
  collapsePostEvents: Observable<string>;
  loadMorePostsButtonFocusEvents: Observable<void>;
  threadRecipients$: Observable<IThreadRecipients>;
}> = (props) => {
  const context = useThreadContext();
  const thread = context.useThread();
  const branchedThreadPosts = context.useBranchedThreadPosts();

  if (!branchedThreadPosts) {
    console.error(
      "QuoteBranchedThreadPosts: expected branchedThreadPosts to be non-null",
    );
    return null;
  }

  return (
    <QuoteBranchedThreadPostsBase
      firstBranchPosts={branchedThreadPosts}
      {...props}
      renderPostEntry={({ post, index }) => {
        return (
          <ThreadPostEntry
            key={post.id}
            post={post}
            thread={thread}
            canUndoSend={false}
            canEdit={false}
            cannotEditReason=""
            isFromSecretThread={!!thread.__local.fromSecretThread}
            threadRecipients$={props.threadRecipients$}
            listRef={props.listRef}
            relativeOrder={index}
            isFirstPost={index === 0}
            isLastPost={false}
            collapsePostEvents={props.collapsePostEvents}
            isFromBranchId={thread.id}
          />
        );
      }}
    />
  );
};
