import { ComponentType, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { withAuthGuard } from "~/route-guards/withAuthGuard";
import { EditChannelGroupDialog } from "~/dialogs/channel-group-edit/EditChannelGroupDialog";
import { USER_CHANNELS$ } from "~/services/channels.service";
import { OrganizationInviteDialog } from "~/dialogs/organization-invite/OrganizationInviteDialog";
import { Sidebar } from "./Sidebar";
import {
  ISidebarLayoutContext,
  SidebarLayoutContext,
  TSidebarLayoutMode,
} from "./context";
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  Subject,
} from "rxjs";
import { PendingUpdatesSpinner } from "./PendingUpdatesSpinner";
import useConstant from "use-constant";
import { HelpDialog } from "~/dialogs/help/HelpDialog";
import { PageDialogRenderer } from "~/page-dialogs/PageDialogRenderer";
import { showNotImplementedToastMsg } from "~/services/toast-service/toast.state";
import { KBarDialog, KBarState } from "~/dialogs/kbar";
import {
  ICommandArgs,
  useRegisterCommands,
  withNewCommandContext,
} from "~/services/command.service";
import { navigateService } from "~/services/navigate.service";
import { RemindMeDialog } from "~/dialogs/remind-me";
import {
  APP_ONLINE$,
  DEVICE_ONLINE$,
} from "~/services/network-connection.service";
import { toast } from "~/services/toast-service";
import { EditChannelDialog } from "~/dialogs/channel-edit/EditChannelDialog";
import { USER_CHANNEL_GROUPS$ } from "~/services/organization.service";
import { withAPIVersionGuard } from "~/route-guards/withApiVersionGuard";
import { BsLockFill } from "react-icons/bs";
import {
  ChannelInviteDialog,
  ChannelInviteDialogState,
} from "~/dialogs/channel-invite/ChannelInviteDialog";
import {
  composeEmailCommand,
  composeMessageCommand,
} from "~/utils/common-commands";
import { DeliverMessagesNowDialog } from "~/dialogs/deliver-messages-now/DeliverMessagesNowDialog";
import { useObservable } from "~/utils/useObservable";
import { HelpIcon } from "./HelpIcon";
import {
  OpenComposeMessageService,
  openComposeNewThreadDialog,
} from "~/page-dialogs/page-dialog-state";
import { ToggleScheduledDeliveryDialog } from "~/dialogs/toggle-scheduled-delivery/ToggleScheduledDeliveryDialog";
import { EditInboxSectionsDialog } from "~/dialogs/edit-inbox-sections/EditInboxSectionsDialog";
import { DarkMode } from "~/components/DarkMode";
import { CURRENT_USER_MAIN_SETTINGS$ } from "~/services/settings.service";

const sidebarMode$ = new BehaviorSubject<TSidebarLayoutMode>("over");
const sidebarForceMode$ = new BehaviorSubject<TSidebarLayoutMode | null>(null);

export const SIDEBAR_LAYOUT_CONTEXT: ISidebarLayoutContext = {
  focusEvent$: new Subject(),
  sidebarOpen$: new BehaviorSubject<boolean>(false),
  sidebarMode$,
  sidebarForceMode$,
  normalizedSidebarMode$: combineLatest([sidebarMode$, sidebarForceMode$]).pipe(
    map(([sidebarMode, sidebarForceMode]) => sidebarForceMode || sidebarMode),
    distinctUntilChanged(),
  ),
};

export const SidebarLayout: ComponentType<{}> = withAPIVersionGuard(
  withAuthGuard(
    withNewCommandContext(() => {
      useEffect(() => {
        // We maintain a subscription to user channel groups and channels
        // to speed up loading on pages that make use of the sidebar layout.
        const sub = USER_CHANNEL_GROUPS$.subscribe();
        sub.add(USER_CHANNELS$.subscribe());
        return () => sub.unsubscribe();
      }, []);

      const context = useConstant(() => SIDEBAR_LAYOUT_CONTEXT);

      useRegisterCommands({
        commands: () => {
          const commands: ICommandArgs[] = [
            {
              label: "Open sidebar",
              altLabels: ["focus sidebar"],
              hotkeys: ["ArrowLeft"],
              callback: () => {
                context.focusEvent$.next("Sidebar");
              },
            },
            composeMessageCommand({
              callback: () => {
                openComposeNewThreadDialog("new");
              },
            }),
            {
              label: "Update your profile (n/a)",
              callback: () => showNotImplementedToastMsg(),
            },
            {
              label: "Delete channel group (n/a)",
              callback: () => showNotImplementedToastMsg(),
            },
            {
              label: "Edit organization (n/a)",
              keywords: ["Update organization"],
              callback: () => showNotImplementedToastMsg(),
            },
            {
              label: "Delete organization (n/a)",
              callback: () => showNotImplementedToastMsg(),
            },
            {
              label: "View channel subscribers...",
              closeKBarOnSelect: false,
              callback: () => {
                KBarState.toggle(true, {
                  path: ["Channel subscribers"],
                  mode: "search",
                });
              },
            },
          ];

          return commands;
        },
      });

      useRegisterCommands({
        commands() {
          return CURRENT_USER_MAIN_SETTINGS$.pipe(
            map((settings) => {
              if (
                import.meta.env.VITE_FIREBASE_EMULATORS !== "true" &&
                settings.linkedGmailEmailAccount !== true
              ) {
                return [];
              }

              return [
                composeEmailCommand({
                  callback: () => {
                    openComposeNewThreadDialog("new-email");
                  },
                }),
              ];
            }),
          );
        },
      });

      // Add "Go to XXX channel" commands
      useRegisterCommands({
        commands: () => {
          return USER_CHANNELS$.pipe(
            map((channels) => {
              const commands: ICommandArgs[] = channels.flatMap((channel) => {
                const channelGroupNames =
                  channel.__local.knownChannelGroups.map((doc) => doc.name);

                return [
                  {
                    id: `Go to ${channel.id}`,
                    label: (
                      <>
                        <span className="text-slateA-10">Go to </span>
                        <span>#{channel.name}</span>
                        {channel.classification === "private" && (
                          <span className="inline-flex ml-1 hover:cursor-help mt-1">
                            <small>
                              <BsLockFill />
                            </small>
                          </span>
                        )}
                        <span className="text-slateA-8 ml-4">
                          {channelGroupNames.join(", ")}
                        </span>
                      </>
                    ),
                    keywords: [
                      `Go to #${channel.name}`,
                      ...channelGroupNames.map(
                        (name) => `#${channel.name} ${name}`,
                      ),
                    ],
                    path: ["Channels"],
                    callback: () => {
                      navigateService(`/channels/${channel.id}`);
                    },
                  },
                  {
                    id: `View ${channel.name} subscribers`,
                    label: (
                      <>
                        <span>View #{channel.name}</span>
                        {channel.classification === "private" && (
                          <span className="inline-flex ml-1 hover:cursor-help mt-1">
                            <small>
                              <BsLockFill />
                            </small>
                          </span>
                        )}
                        <span className="ml-1">subscribers</span>
                        <span className="text-slateA-8 ml-4">
                          {channelGroupNames.join(", ")}
                        </span>
                      </>
                    ),
                    keywords: [`View #${channel.name} subscribers`],
                    path: ["Channel subscribers"],
                    callback: () => {
                      navigateService(`/channels/${channel.id}/subscribers`);
                    },
                  },
                ] satisfies ICommandArgs[];
              });

              commands.push({
                label: "Subscribe users to channel",
                altLabels: [
                  "Invite users to channel",
                  `Add users to channel`,
                  `Subscribe users to channel`,
                  `Send channel invites`,
                  `Send channel invitations`,
                  `Invite to channel`,
                ],
                callback: () => {
                  ChannelInviteDialogState.toggle(true);
                },
              });

              return commands;
            }),
          );
        },
      });

      // Add enable/disable offline mode commands
      useRegisterCommands({
        commands: () => {
          return combineLatest([APP_ONLINE$, DEVICE_ONLINE$]).pipe(
            map(([appOnLine]) => {
              const commands: ICommandArgs[] = [];

              if (appOnLine) {
                commands.push({
                  label: "Enable offline mode",
                  altLabels: ["Go offline", "Disable online mode"],
                  keywords: ["Disable offline mode"],
                  callback: () => {
                    toast("vanilla", {
                      subject: "Offline support is temporarily disabled :(",
                    });

                    // toast("vanilla", {
                    //   subject: "Offline mode enabled",
                    // });

                    // forceOfflineMode(true);
                  },
                });
              }
              // } else if (deviceOnLine) {
              //   commands.push({
              //     label: "Disable offline mode",
              //     altLabels: ["Go online", "Enable online mode"],
              //     keywords: ["Enable offline mode"],
              //     callback: () => {
              //       toast("vanilla", {
              //         subject: "Offline mode disabled",
              //       });

              //       forceOfflineMode(false);
              //     },
              //   });
              // } else {
              //   commands.push({
              //     label: "Disable offline mode",
              //     altLabels: ["Go online", "Enable online mode"],
              //     keywords: ["Enable offline mode"],
              //     callback: () => {
              //       toast("vanilla", {
              //         subject: "Device offline",
              //         description: `
              //           Your device appears to not be connected to the internet.
              //           We've disabled offline mode, but you still don't have
              //           internet.
              //         `,
              //         duration: 15_000,
              //       });

              //       forceOfflineMode(false);
              //     },
              //   });
              // }

              return commands;
            }),
          );
        },
      });

      const mode = useObservable(() => context.normalizedSidebarMode$, {
        synchronous: true,
        deps: [context.normalizedSidebarMode$],
      });

      return (
        <SidebarLayoutContext.Provider value={context}>
          <HelpIcon />

          <Sidebar mode={mode} />

          <div className={mode === "push" ? "ml-[256px]" : ""}>
            <PageDialogRenderer>
              <Outlet />
            </PageDialogRenderer>
          </div>

          <KBarDialog />
          {/* 
              *tl;dr;*
              - Order these dialog components in the order you
                would like their kbar commands to appear.

              Many of these Dialog components also register kbar
              commands. At the moment, we don't use many weights
              when ordering kbar commands. If two commands match
              the current search term equally well, they'll 
              appear in the order they were registered. As such,
              the order of these dialogs can affects the order
              commands are displayed in via the kbar. E.g. the
              EditChannelGroupDialog and the EditChannelDialog both
              register commands beginning with "create channel".
              We choose to render EditChannelDialog first so that
              its command is displayed first in this edge case.
              In the future, we should have a better system for
              ordering commands and this order here won't matter.
            */}
          <HelpDialog />
          <RemindMeDialog />
          <OrganizationInviteDialog />
          <ChannelInviteDialog />
          <EditChannelDialog />
          <EditChannelGroupDialog />
          <DeliverMessagesNowDialog />
          <ToggleScheduledDeliveryDialog />
          <EditInboxSectionsDialog />
          <PendingUpdatesSpinner />
          <OpenComposeMessageService />
          <DarkMode />
        </SidebarLayoutContext.Provider>
      );
    }),
  ),
);
