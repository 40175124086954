import {
  IChannelDoc,
  IInboxSectionDoc,
  IInboxSubsectionDoc,
  INotificationDoc,
  IPostDoc,
  ITagDoc,
  IThreadDoc,
  IThreadReadStatusDoc,
  IUserDoc,
} from "@libs/firestore-models";
import {
  throwUnreachableCaseError,
  UnreachableCaseError,
} from "@libs/utils/errors";
import type {
  FilterValue,
  LogicalOperator,
  ParsedToken,
  TextToken,
  Token,
} from "@libs/utils/searchQueryParser";
import { EMAIL_ADDRESS_REGEXP } from "@libs/utils/parseEmailAddress";
import { timestampComparer } from "@libs/utils/comparers";
import type { Timestamp } from "@firebase/firestore-types";
import { uniq } from "lodash-es";

export interface ISearchQueryMatcherProps {
  currentUserId: string;
  postId: string;
  threadId: string;
  parsedQuery: ParsedToken[];
  allowTopLevelFullTextQuery?: boolean;
  service: ISearchQueryMatcherService;
}

export interface ISearchQueryMatcherService {
  getUserDoc(id: string): Promise<IUserDoc | null>;
  getPostDoc(id: string): Promise<IPostDoc | null>;
  getThreadDoc(id: string): Promise<IThreadDoc | null>;
  getTagDoc(id: string): Promise<ITagDoc | null>;
  getNewPostNotificationDoc(
    userId: string,
    notificationId: string,
  ): Promise<INotificationDoc | null>;
  getThreadReadStatusDoc(
    userId: string,
    threadId: string,
  ): Promise<IThreadReadStatusDoc | null>;
  getChannelDoc(channelId: string): Promise<IChannelDoc | null>;
  convertStringToTimestamp(dateString: string): Timestamp | null;
}

interface IFilterProps<T> extends ISearchQueryMatcherProps {
  token: T;
}

export async function findMatchingSubsection(args: {
  currentUserId: string;
  postId: string;
  threadId: string;
  inboxSectionDoc: IInboxSectionDoc;
  subsectionDocs: IInboxSubsectionDoc[];
  service: ISearchQueryMatcherService;
}) {
  for (const subsection of args.subsectionDocs) {
    const matchesSubsection = await searchQueryMatcher({
      ...args,
      parsedQuery: subsection.parsedQuery,
      allowTopLevelFullTextQuery: true,
    });

    if (!matchesSubsection) continue;

    return {
      section: args.inboxSectionDoc,
      subsection,
    };
  }

  return null;
}

// Equal to the same similarity threadshold that our postgres
// instance users
const FUZZY_MATCH_SIMILARITY_THRESHOLD = 0.6;

export async function searchQueryMatcher(
  props: ISearchQueryMatcherProps,
): Promise<boolean> {
  if (props.parsedQuery.length === 0) {
    return false;
  }

  const parsedQuery = bundleOrOperators(props.parsedQuery) as [
    ParsedToken,
    ...ParsedToken[],
  ];

  // Unfortunately, the "trigram-similarity" npm package attempts to mimic
  // "similarity" matching in postgres rather than "word_similarity" matching
  // (which is what Comms users). Additionally, while it produces a pretty
  // similar similarity score, it isn't an exact match. As such, we're temporarily
  // disabling fuzzy matching. In the client, if someone attempts to add
  // a fuzzy text filter we show them an alert and then prevent them.
  //
  // // If the search query contains a plain text search phrase, the first
  // // ParsedToken in the response will be of type "text". See the
  // // searchQueryParser.ts module for more information.
  // if (parsedQuery[0].type === "text" && props.allowTopLevelFullTextQuery) {
  //   const token = parsedQuery.shift() as TextToken;
  //   const postText = props.postDoc.subject + " " + props.postDoc.bodyText;
  //   const similarity = trigramSimilarity(postText, token.value);
  //   if (similarity < FUZZY_MATCH_SIMILARITY_THRESHOLD) return false;
  // }

  return areAllResultsTrue(
    parsedQuery.map((token) => matchQueryToken(token, props)),
  );
}

/* -----------------------------------------------------------------------------------------------*/

async function matchQueryToken(
  token: ParsedToken,
  props: ISearchQueryMatcherProps,
): Promise<boolean> {
  const getFilterProps = <T>(token: T) => ({ ...props, token });

  switch (token.type) {
    case "text": {
      return textFilter(getFilterProps(token));
    }
    case "from:": {
      return areAllResultsTrue(
        token.value.map((value) => fromFilter(getFilterProps(value))),
      );
    }
    case "to:": {
      return areAllResultsTrue(
        token.value.map((value) => toFilter(getFilterProps(value))),
      );
    }
    case "is:": {
      return areAllResultsTrue(
        token.value.map((value) => isFilter(getFilterProps(value))),
      );
    }
    case "after:": {
      return areAllResultsTrue(
        token.value.map((value) => afterFilter(getFilterProps(value))),
      );
    }
    case "before:": {
      return areAllResultsTrue(
        token.value.map((value) => beforeFilter(getFilterProps(value))),
      );
    }
    case "viewer:": {
      return areAllResultsTrue(
        token.value.map((value) => viewerFilter(getFilterProps(value))),
      );
    }
    case "participating:": {
      return areAllResultsTrue(
        token.value.map((value) => participatingFilter(getFilterProps(value))),
      );
    }
    case "channel:": {
      return areAllResultsTrue(
        token.value.map((value) => channelFilter(getFilterProps(value))),
      );
    }
    case "tag:": {
      return areAllResultsTrue(
        token.value.map((value) => tagFilter(getFilterProps(value))),
      );
    }
    case "subject:": {
      return areAllResultsTrue(
        token.value.map((value) => subjectFilter(getFilterProps(value))),
      );
    }
    case "body:": {
      return areAllResultsTrue(
        token.value.map((value) => bodyFilter(getFilterProps(value))),
      );
    }
    case "has:": {
      return areAllResultsTrue(
        token.value.map((value) => hasFilter(getFilterProps(value))),
      );
    }
    case "remind-after:": {
      return areAllResultsTrue(
        token.value.map((value) => remindAfterFilter(getFilterProps(value))),
      );
    }
    case "remind-before:": {
      return areAllResultsTrue(
        token.value.map((value) => remindBeforeFilter(getFilterProps(value))),
      );
    }
    case "mentions:": {
      return areAllResultsTrue(
        token.value.map((value) => mentionsFilter(getFilterProps(value))),
      );
    }
    case "priority:": {
      return areAllResultsTrue(
        token.value.map(async (value) => priorityFilter(getFilterProps(value))),
      );
    }
    case "and()": {
      return andOperator({
        ...props,
        token: token as LogicalOperator<"and()">,
      });
    }
    case "or()": {
      return orOperator({
        ...props,
        token: token as LogicalOperator<"or()">,
      });
    }
    case "not()": {
      return notOperator({
        ...props,
        token: token as LogicalOperator<"not()">,
      });
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function textFilter(props: IFilterProps<TextToken>) {
  const lowercaseInput = props.token.value.toLowerCase();
  const postDoc = await props.service.getPostDoc(props.postId);
  if (!postDoc) return false;

  return (
    postDoc.subject.toLowerCase().includes(lowercaseInput) ||
    postDoc.bodyText.toLowerCase().includes(lowercaseInput)
  );
}

/* -----------------------------------------------------------------------------------------------*/

async function fromFilter(props: IFilterProps<FilterValue<"from">>) {
  const { token, currentUserId } = props;

  const postDoc = await props.service.getPostDoc(props.postId);
  if (!postDoc) return false;

  switch (token.type) {
    case "text": {
      if (token.value === "me") {
        return postDoc.creatorId === currentUserId;
      } else if (isEmail(token.value)) {
        return isPostSentByEmail(postDoc, token.value);
      } else {
        return isPostSentByName(postDoc, token.value);
      }
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          return isPostSentByUser(subjectId, props);
        }
        case "channel":
        case "tag": {
          // TODO
          // if someone indicates "from" a channel or tag, they probably mean that
          // they want messages which they received an inbox notification for
          // because the message was sent to that channel or tag (and, presumably,
          // the current user had a subscription to the channel/tag at the time).
          // This is something we should try to support.
          console.warn(`Attempted to filter on "from:" ${subject}, ignoring.`);
          return false;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

function isPostSentByEmail(postDoc: IPostDoc, input: string) {
  const lowercaseInput = input.toLowerCase();

  switch (postDoc.type) {
    case "COMMS": {
      return postDoc.creatorEmail.toLowerCase() === lowercaseInput;
    }
    case "EMAIL":
    case "EMAIL_SECRET": {
      return postDoc.from.some((e) =>
        typeof e.address === "string"
          ? e.address.toLowerCase() === lowercaseInput
          : e.addresses
          ? e.addresses.some((g) => g.address.toLowerCase() === lowercaseInput)
          : throwUnreachableCaseError(e),
      );
    }
    default: {
      throw new UnreachableCaseError(postDoc);
    }
  }
}

function isPostSentByName(postDoc: IPostDoc, input: string) {
  const lowercaseInput = input.toLowerCase();

  switch (postDoc.type) {
    case "COMMS": {
      return postDoc.creatorName.toLowerCase().includes(lowercaseInput);
    }
    case "EMAIL":
    case "EMAIL_SECRET": {
      return postDoc.from.some(
        (e) =>
          e.label?.toLowerCase().includes(lowercaseInput) ||
          (typeof e.address === "string"
            ? e.address.toLowerCase().includes(lowercaseInput)
            : e.addresses
            ? e.addresses.some((g) =>
                g.address.toLowerCase().includes(lowercaseInput),
              )
            : throwUnreachableCaseError(e)),
      );
    }
    default: {
      throw new UnreachableCaseError(postDoc);
    }
  }
}

async function isPostSentByUser(userId: string, props: IFilterProps<unknown>) {
  const postDoc = await props.service.getPostDoc(props.postId);

  if (!postDoc) return false;

  switch (postDoc.type) {
    case "COMMS": {
      return postDoc.creatorId === userId;
    }
    case "EMAIL":
    case "EMAIL_SECRET": {
      if (postDoc.creatorId === userId) return true;

      const userDoc = await props.service.getUserDoc(userId);

      if (!userDoc) return false;

      return isPostSentByEmail(postDoc, userDoc.lowercaseEmail);
    }
    default: {
      throw new UnreachableCaseError(postDoc);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function toFilter(props: IFilterProps<FilterValue<"to">>) {
  const { token, currentUserId } = props;

  const postDoc = await props.service.getPostDoc(props.postId);
  if (!postDoc) return false;

  switch (token.type) {
    case "text": {
      if (token.value === "me") {
        return postDoc.recipientUserIds.some((id) => id === currentUserId);
      } else if (isEmail(token.value)) {
        return isPostSentToEmail(postDoc, token.value);
      } else {
        return isPostSentToName(postDoc, token.value);
      }
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          return isPostSentToUser(subjectId, props);
        }
        case "channel": {
          return isPostSentToChannel(subjectId, props);
        }
        case "tag": {
          console.warn(`Attempted to filter on "to:" tag, ignoring.`);
          return false;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

function isPostSentToEmail(postDoc: IPostDoc, input: string) {
  const lowercaseInput = input.toLowerCase();

  switch (postDoc.type) {
    case "COMMS": {
      return Object.values(postDoc.recipientUsers).some(
        (userData) => userData.email.toLowerCase() === lowercaseInput,
      );
    }
    case "EMAIL":
    case "EMAIL_SECRET": {
      return postDoc.to.some((e) =>
        typeof e.address === "string"
          ? e.address.toLowerCase() === lowercaseInput
          : e.addresses
          ? e.addresses.some((g) => g.address.toLowerCase() === lowercaseInput)
          : throwUnreachableCaseError(e),
      );
    }
    default: {
      throw new UnreachableCaseError(postDoc);
    }
  }
}

function isPostSentToName(postDoc: IPostDoc, input: string) {
  const lowercaseInput = input.toLowerCase();

  switch (postDoc.type) {
    case "COMMS": {
      return Object.values(postDoc.recipientUsers).some(
        (userData) => userData.name.toLowerCase() === lowercaseInput,
      );
    }
    case "EMAIL":
    case "EMAIL_SECRET": {
      return postDoc.to.some(
        (e) =>
          e.label?.toLowerCase().includes(lowercaseInput) ||
          (typeof e.address === "string"
            ? e.address.toLowerCase().includes(lowercaseInput)
            : e.addresses
            ? e.addresses.some((g) =>
                g.address.toLowerCase().includes(lowercaseInput),
              )
            : throwUnreachableCaseError(e)),
      );
    }
    default: {
      throw new UnreachableCaseError(postDoc);
    }
  }
}

async function isPostSentToUser(userId: string, props: IFilterProps<unknown>) {
  const postDoc = await props.service.getPostDoc(props.postId);
  if (!postDoc) return false;

  switch (postDoc.type) {
    case "COMMS": {
      return postDoc.recipientUserIds.some((id) => id === userId);
    }
    case "EMAIL":
    case "EMAIL_SECRET": {
      if (postDoc.recipientUserIds.some((id) => id === userId)) {
        return true;
      }

      const userDoc = await props.service.getUserDoc(userId);

      if (!userDoc) return false;

      return isPostSentToEmail(postDoc, userDoc.lowercaseEmail);
    }
    default: {
      throw new UnreachableCaseError(postDoc);
    }
  }
}

async function isPostSentToChannel(
  channelId: string,
  props: IFilterProps<unknown>,
) {
  const postDoc = await props.service.getPostDoc(props.postId);
  if (!postDoc) return false;

  switch (postDoc.type) {
    case "COMMS":
    case "EMAIL":
    case "EMAIL_SECRET": {
      return postDoc.recipientChannelIds.some((id) => id === channelId);
    }
    default: {
      throw new UnreachableCaseError(postDoc);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function isFilter(props: IFilterProps<FilterValue<"is">>) {
  const { token } = props;

  if (token.type === "DocId") {
    console.warn(`Document ID passed to "is:".`);
    return false;
  } else if (token.type !== "text") {
    throw new UnreachableCaseError(token);
  }

  switch (token.value) {
    case "done": {
      const notificationDoc = await props.service.getNewPostNotificationDoc(
        props.currentUserId,
        props.threadId,
      );
      return !!notificationDoc?.done;
    }
    case "branch": {
      const threadDoc = await props.service.getThreadDoc(props.threadId);
      return !!threadDoc?.isBranch;
    }
    case "private": {
      const threadDoc = await props.service.getThreadDoc(props.threadId);
      return threadDoc?.visibility === "private";
    }
    case "shared": {
      const threadDoc = await props.service.getThreadDoc(props.threadId);
      return threadDoc?.visibility === "shared";
    }
    case "seen": {
      const postDoc = await props.service.getPostDoc(props.postId);
      if (!postDoc) return false;

      return (
        postDoc.type === "EMAIL_SECRET" ||
        !!(await props.service.getThreadReadStatusDoc(
          props.currentUserId,
          props.threadId,
        ))
      );
    }
    case "email": {
      const postDoc = await props.service.getPostDoc(props.postId);
      if (!postDoc) return false;
      return postDoc.type === "EMAIL" || postDoc.type === "EMAIL_SECRET";
    }
    default: {
      console.warn(`Unknown option passed to "is:".`, token);
      return false;
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function afterFilter(props: IFilterProps<FilterValue<"after">>) {
  const { token } = props;

  if (token.type === "DocId") {
    console.warn(`Document ID passed to "after:".`);
    return false;
  } else if (token.type !== "text") {
    throw new UnreachableCaseError(token);
  }

  const timestamp = props.service.convertStringToTimestamp(token.value);

  if (!timestamp) {
    console.warn(`Invalid value provided to "after:" filter`);
    return false;
  }

  const postDoc = await props.service.getPostDoc(props.postId);
  if (!postDoc) return false;

  return timestampComparer(postDoc.sentAt, timestamp) !== -1;
}

/* -----------------------------------------------------------------------------------------------*/

async function beforeFilter(props: IFilterProps<FilterValue<"before">>) {
  const { token } = props;

  if (token.type === "DocId") {
    console.warn(`Document ID passed to "before:".`);
    return false;
  } else if (token.type !== "text") {
    throw new UnreachableCaseError(token);
  }

  const timestamp = props.service.convertStringToTimestamp(token.value);

  if (!timestamp) {
    console.warn(`Invalid value provided to "after:" filter`);
    return false;
  }

  const postDoc = await props.service.getPostDoc(props.postId);
  if (!postDoc) return false;

  return timestampComparer(postDoc.sentAt, timestamp) === -1;
}

/* -----------------------------------------------------------------------------------------------*/

async function viewerFilter(props: IFilterProps<FilterValue<"viewer">>) {
  const { token } = props;

  switch (token.type) {
    case "text": {
      const threadDoc = await props.service.getThreadDoc(props.threadId);
      if (!threadDoc) return false;
      const asNumber = Number.parseInt(token.value, 10);

      if (Number.isInteger(asNumber)) {
        return threadDoc.permittedUserIds.length === asNumber;
      } else if (token.value === "me") {
        return threadDoc.permittedUserIds.some(
          (id) => id === props.currentUserId,
        );
      } else if (isEmail(token.value)) {
        const lowercaseEmail = token.value.toLowerCase();

        return Object.values(threadDoc.userPermissions).some(
          (userData) => userData.email.toLowerCase() === lowercaseEmail,
        );
      } else {
        const lowercaseInput = token.value.toLowerCase();

        return Object.values(threadDoc.userPermissions).some((userData) =>
          userData.name.toLowerCase().includes(lowercaseInput),
        );
      }
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          const threadDoc = await props.service.getThreadDoc(props.threadId);
          if (!threadDoc) return false;
          return threadDoc.permittedUserIds.some((id) => id === subjectId);
        }
        case "channel":
        case "tag": {
          console.warn(`User provided a specified a ${subject} for "viewer:".`);
          return false;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function participatingFilter(
  props: IFilterProps<FilterValue<"participating">>,
) {
  const { token } = props;

  switch (token.type) {
    case "text": {
      const threadDoc = await props.service.getThreadDoc(props.threadId);
      if (!threadDoc) return false;
      const asNumber = Number.parseInt(token.value, 10);

      if (Number.isInteger(asNumber)) {
        return threadDoc.participatingUserIds.length === asNumber;
      } else if (token.value === "me") {
        return threadDoc.participatingUserIds.some(
          (id) => id === props.currentUserId,
        );
      } else if (isEmail(token.value)) {
        const lowercaseEmail = token.value.toLowerCase();

        return Object.values(threadDoc.participatingUsers).some(
          (userData) => userData.email.toLowerCase() === lowercaseEmail,
        );
      } else {
        const lowercaseInput = token.value.toLowerCase();

        return Object.values(threadDoc.participatingUsers).some((userData) =>
          userData.name.toLowerCase().includes(lowercaseInput),
        );
      }
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          const threadDoc = await props.service.getThreadDoc(props.threadId);
          if (!threadDoc) return false;
          return threadDoc.participatingUserIds.some((id) => id === subjectId);
        }
        case "channel":
        case "tag": {
          console.warn(`User provided a ${subject} for "participating:".`);
          return false;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function channelFilter(props: IFilterProps<FilterValue<"channel">>) {
  const { token } = props;

  switch (token.type) {
    case "text": {
      const threadDoc = await props.service.getThreadDoc(props.threadId);
      if (!threadDoc) return false;
      const asNumber = Number.parseInt(token.value, 10);

      if (Number.isInteger(asNumber)) {
        return threadDoc.permittedChannelIds.length === asNumber;
      } else {
        const lowercaseInput = token.value.toLowerCase();

        const channelDocs = await Promise.all(
          threadDoc.permittedChannelIds.map((id) =>
            props.service.getChannelDoc(id),
          ),
        );

        return channelDocs.some((channelDoc) =>
          channelDoc?.name.toLowerCase().includes(lowercaseInput),
        );
      }
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          console.warn(`User provided a user for "channel:".`);
          return false;
        }
        case "channel": {
          const threadDoc = await props.service.getThreadDoc(props.threadId);
          if (!threadDoc) return false;
          return threadDoc.permittedChannelIds.some((id) => id === subjectId);
        }
        case "tag": {
          console.warn(`User provided a tag for "channel:".`);
          return false;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function tagFilter(props: IFilterProps<FilterValue<"tag">>) {
  const { token } = props;

  switch (token.type) {
    case "text": {
      const [threadDoc, notificationDoc] = await Promise.all([
        props.service.getThreadDoc(props.threadId),
        props.service.getNewPostNotificationDoc(
          props.currentUserId,
          props.threadId,
        ),
      ]);

      if (!threadDoc && !notificationDoc) return false;

      const tagIds = uniq([
        ...(threadDoc?.tagIds || []),
        ...(notificationDoc?.tagIds || []),
      ]);

      const tagDocs = await Promise.all(
        tagIds.map((id) => props.service.getTagDoc(id)),
      );

      const lowercaseInput = token.value.toLowerCase();

      return tagDocs.some((tagDoc) =>
        tagDoc?.name.toLowerCase().includes(lowercaseInput),
      );
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          console.warn(`User specified a user for "tag:".`);
          return false;
        }
        case "channel": {
          console.warn(`User specified a channel for "tag:".`);
          return false;
        }
        case "tag": {
          const [threadDoc, notificationDoc] = await Promise.all([
            props.service.getThreadDoc(props.threadId),
            props.service.getNewPostNotificationDoc(
              props.currentUserId,
              props.threadId,
            ),
          ]);

          if (!threadDoc && !notificationDoc) return false;

          const tagIds = uniq([
            ...(threadDoc?.tagIds || []),
            ...(notificationDoc?.tagIds || []),
          ]);

          const tagDocs = await Promise.all(
            tagIds.map((id) => props.service.getTagDoc(id)),
          );

          return tagDocs.some((tagDoc) => tagDoc?.id === subjectId);
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function subjectFilter(props: IFilterProps<FilterValue<"subject">>) {
  const { token } = props;

  switch (token.type) {
    case "text": {
      const threadDoc = await props.service.getThreadDoc(props.threadId);
      if (!threadDoc) return false;
      return threadDoc.subject
        .toLowerCase()
        .includes(token.value.toLowerCase());
    }
    case "DocId": {
      console.warn(`provided a docId to "subject:".`);
      return false;
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function bodyFilter(props: IFilterProps<FilterValue<"body">>) {
  const { token } = props;

  const postDoc = await props.service.getPostDoc(props.postId);
  if (!postDoc) return false;

  switch (token.type) {
    case "text": {
      return postDoc.bodyText.toLowerCase().includes(token.value.toLowerCase());
    }
    case "DocId": {
      console.warn(`provided a docId to "body:".`);
      return false;
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function hasFilter(props: IFilterProps<FilterValue<"has">>) {
  const { token } = props;

  if (token.type === "DocId") {
    console.warn(`Document ID passed to "has:".`);
    return false;
  } else if (token.type !== "text") {
    throw new UnreachableCaseError(token);
  }

  const notificationDoc = await props.service.getNewPostNotificationDoc(
    props.currentUserId,
    props.threadId,
  );

  if (!notificationDoc) return false;

  switch (token.value) {
    case "reminder": {
      return notificationDoc.triaged;
    }
    case "notification": {
      return !!notificationDoc;
    }
    default: {
      console.warn(`provided unknown value "${token.value}" to "has:".`);
      return false;
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function remindAfterFilter(
  props: IFilterProps<FilterValue<"remindAfter">>,
) {
  const { token } = props;

  if (token.type === "DocId") {
    console.warn(`Document ID passed to "remind-after:".`);
    return false;
  } else if (token.type !== "text") {
    throw new UnreachableCaseError(token);
  }

  const notificationDoc = await props.service.getNewPostNotificationDoc(
    props.currentUserId,
    props.threadId,
  );

  if (!notificationDoc?.triagedUntil) return false;

  const timestamp = props.service.convertStringToTimestamp(token.value);

  if (!timestamp) {
    console.warn(`Invalid value provided to "remind-after:" filter`);
    return false;
  }

  return timestampComparer(notificationDoc.triagedUntil, timestamp) !== -1;
}

/* -----------------------------------------------------------------------------------------------*/

async function remindBeforeFilter(
  props: IFilterProps<FilterValue<"remindBefore">>,
) {
  const { token } = props;

  if (token.type === "DocId") {
    console.warn(`Document ID passed to "remind-before:".`);
    return false;
  } else if (token.type !== "text") {
    throw new UnreachableCaseError(token);
  }

  const notificationDoc = await props.service.getNewPostNotificationDoc(
    props.currentUserId,
    props.threadId,
  );

  if (!notificationDoc?.triagedUntil) return false;

  const timestamp = props.service.convertStringToTimestamp(token.value);

  if (!timestamp) {
    console.warn(`Invalid value provided to "remind-before:" filter`);
    return false;
  }

  return timestampComparer(notificationDoc.triagedUntil, timestamp) === -1;
}

/* -----------------------------------------------------------------------------------------------*/

async function mentionsFilter(props: IFilterProps<FilterValue<"mentions">>) {
  const { token } = props;

  const postDoc = await props.service.getPostDoc(props.postId);
  if (!postDoc) return false;

  switch (token.type) {
    case "text": {
      const mentionedUserIds = Object.keys(postDoc.mentionedUsers);

      if (token.value === "me") {
        return mentionedUserIds.some(
          (userId) => userId === props.currentUserId,
        );
      } else if (isEmail(token.value)) {
        const userDocs = await Promise.all(
          mentionedUserIds.map((userId) => props.service.getUserDoc(userId)),
        );

        const lowercaseInput = token.value.toLowerCase();

        return userDocs.some(
          (userDoc) => userDoc?.lowercaseEmail === lowercaseInput,
        );
      } else {
        const userDocs = await Promise.all(
          mentionedUserIds.map((userId) => props.service.getUserDoc(userId)),
        );

        const lowercaseInput = token.value.toLowerCase();

        return userDocs.some((userDoc) =>
          userDoc?.name.toLowerCase().includes(lowercaseInput),
        );
      }
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          return Object.keys(postDoc.mentionedUsers).some(
            (userId) => userId === subjectId,
          );
        }
        case "channel": {
          return Object.keys(postDoc.mentionedChannels).some(
            (channelId) => channelId === subjectId,
          );
        }
        case "tag": {
          console.warn(`User provided a tag for "mentions:".`);
          return false;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function priorityFilter(props: IFilterProps<FilterValue<"priority">>) {
  const { token } = props;

  if (token.type !== "text") {
    throw new UnreachableCaseError(token.value as never);
  }

  const notificationDoc = await props.service.getNewPostNotificationDoc(
    props.currentUserId,
    props.threadId,
  );

  if (!notificationDoc) return false;

  const hasPriority = (priority: number) =>
    notificationDoc.priority === priority;

  if (token.value.includes("@@@") || token.value === "100") {
    return hasPriority(100);
  } else if (token.value.includes("@@") || token.value === "200") {
    return hasPriority(200);
  } else if (token.value.includes("@") || token.value === "300") {
    return hasPriority(300);
  } else if (token.value === "participating" || token.value === "400") {
    return hasPriority(400);
  } else if (token.value === "subscriber" || token.value === "500") {
    return hasPriority(500);
  } else {
    throw new UnreachableCaseError(token.value as never);
  }
}

/* -----------------------------------------------------------------------------------------------*/

function andOperator(props: IFilterProps<LogicalOperator<"and()">>) {
  return areAllResultsTrue(
    bundleOrOperators(props.token.value).map((token) =>
      matchQueryToken(token, props),
    ),
  );
}

/* -----------------------------------------------------------------------------------------------*/

function orOperator(props: IFilterProps<LogicalOperator<"or()">>) {
  return isAnyResultTrue(
    bundleOrOperators(props.token.value).map((token) =>
      matchQueryToken(token, props),
    ),
  );
}

/* -----------------------------------------------------------------------------------------------*/

async function notOperator(props: IFilterProps<LogicalOperator<"not()">>) {
  const result = await areAllResultsTrue(
    bundleOrOperators(props.token.value).map((token) =>
      matchQueryToken(token, props),
    ),
  );

  return !result;
}

/* -----------------------------------------------------------------------------------------------*/

function isEmail(input: string) {
  return EMAIL_ADDRESS_REGEXP.test(input);
}

function areAllResultsTrue(results: Promise<boolean>[] | boolean[]) {
  return Promise.all(results).then((result) => result.every((value) => value));
}

function isAnyResultTrue(results: Promise<boolean>[] | boolean[]) {
  return Promise.all(results).then((result) => result.some((value) => value));
}

function parseDocId(token: Token<"DocId", string>) {
  const [subject, mentionLevel, subjectId] = token.value.split("::") as [
    "user" | "channel" | "tag",
    string,
    string,
  ];

  return { subject, mentionLevel, subjectId };
}

/**
 * In search, "or()" operators are compared to each each other and one
 * of them must be `true`. In Comms, we bundle all the "or()"
 * clauses together to make a single "or()" operator which compares all
 * the clauses looking for one which is true.
 */
function bundleOrOperators(tokens: ParsedToken[]) {
  const { orOperator, otherTokens } = tokens.reduce(
    (store, curr) => {
      if (curr.type === "or()") {
        store.orOperator.value.push({
          ...curr,
          type: "and()",
        });
      } else {
        store.otherTokens.push(curr);
      }

      return store;
    },
    {
      otherTokens: [] as ParsedToken[],
      orOperator: {
        type: "or()",
        value: [],
      } as LogicalOperator<"or()">,
    },
  );

  if (orOperator.value.length === 0) return otherTokens;

  // The orOperator should not be placed first. If a fulltext search query
  // is present we expect it to be the first token.
  return [...otherTokens, orOperator];
}
