import { ComponentType, useRef } from "react";
import { ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useSentPosts } from "~/services/post.service";
import {
  ContentList,
  EmptyListMessage,
  onPostSelectNavigateToPost,
  PostEntry,
} from "~/components/content-list";
import { useTopScrollShadow } from "~/utils/useScrollShadow";
import { PendingRequestBar } from "~/components/PendingRequestBar";
import { showNotImplementedToastMsg } from "~/services/toast-service";
import { ICommandArgs, useRegisterCommands } from "~/services/command.service";
import {
  ESCAPE_TO_INBOX_COMMAND,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";

export const SentView: ComponentType<{}> = () => {
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);
  const posts = useSentPosts({
    pagingScrollboxRef: scrollboxRef,
  });

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        ESCAPE_TO_INBOX_COMMAND,
        markDoneCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't mark threads Done from the 
              Sent page.
            `);
          },
        }),
        markNotDoneCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't mark threads not Done from the 
              Sent page.
            `);
          },
        }),
        setThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't edit reminders from the 
              Sent page.
            `);
          },
        }),
        removeThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't edit reminders from the 
              Sent page.
            `);
          },
        }),
        starThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't star threads from the
              Sent page.
            `);
          },
        }),
        unstarThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't unstar threads from the
              Sent page.
            `);
          },
        }),
      ];

      return commands;
    },
  });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <>
      <Helmet>
        <title>Sent | Comms</title>
      </Helmet>

      <MainLayout.Header ref={headerRef}>
        <h1 className="text-3xl">Sent</h1>
      </MainLayout.Header>

      <ListScrollbox
        isBodyElement
        offsetHeaderEl={headerRef}
        onlyOffsetHeaderElIfSticky
      >
        {!posts ? (
          <PendingRequestBar>
            <EmptyListMessage text="Nothing yet." />
          </PendingRequestBar>
        ) : posts.length === 0 ? (
          <EmptyListMessage text="Nothing yet." />
        ) : (
          <ContentList
            onEntryAction={onPostSelectNavigateToPost}
            className="mb-20"
            autoFocus
          >
            {posts.map((post, index) => (
              <PostEntry
                key={post.id}
                post={post}
                relativeOrder={index}
                showRecipientNames
              />
            ))}
          </ContentList>
        )}
      </ListScrollbox>
    </>
  );
};
