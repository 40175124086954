import { FirebaseOptions, initializeApp } from "firebase/app";
import {
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
  connectFirestoreEmulator,
} from "firebase/firestore";
import {
  getFunctions,
  httpsCallable,
  HttpsCallableOptions,
  connectFunctionsEmulator,
} from "firebase/functions";
import { ICallableFnsTypeMap } from "@libs/firebase-functions-types";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";

export const firebaseConfig: FirebaseOptions = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

export const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
});

export const functions = getFunctions(app);

export const auth = getAuth(app);

export const storage = getStorage(app);

export const analytics = getAnalytics(app);

export const isAnEmulatorActive = !!(
  import.meta.env.VITE_FIREBASE_EMULATORS === "true" &&
  import.meta.env.VITE_FIREBASE_EMULATOR_HOSTNAME &&
  import.meta.env.VITE_FIREBASE_EMULATOR_FIRESTORE_PORT &&
  import.meta.env.VITE_FIREBASE_EMULATOR_FUNCTION_PORT &&
  import.meta.env.VITE_FIREBASE_EMULATOR_STORAGE_PORT
);

if (isAnEmulatorActive) {
  console.warn(
    "ENABLING FIREBASE EMULATORS",
    !import.meta.env.VITE_FIREBASE_EMULATOR_AUTH_URL
      ? "EXCEPT FOR AUTH EMULATOR"
      : "",
  );

  // When using the emulators with persistence, shutting down the
  // emulators doesn't clear persistence and, instead, we
  // must manually clear indexedDB persistence when we want to
  // clearIndexedDbPersistence(db);

  connectFirestoreEmulator(
    db,
    import.meta.env.VITE_FIREBASE_EMULATOR_HOSTNAME,
    Number(import.meta.env.VITE_FIREBASE_EMULATOR_FIRESTORE_PORT),
  );

  if (import.meta.env.VITE_FIREBASE_EMULATOR_AUTH_URL) {
    connectAuthEmulator(auth, import.meta.env.VITE_FIREBASE_EMULATOR_AUTH_URL, {
      disableWarnings: true,
    });
  } else {
    console.warn(
      "AuthEmulator NOT ACTIVE!!! VITE_FIREBASE_EMULATOR_AUTH_URL was not provided",
    );
  }

  connectFunctionsEmulator(
    functions,
    import.meta.env.VITE_FIREBASE_EMULATOR_HOSTNAME,
    Number(import.meta.env.VITE_FIREBASE_EMULATOR_FUNCTION_PORT),
  );

  connectStorageEmulator(
    storage,
    import.meta.env.VITE_FIREBASE_EMULATOR_HOSTNAME,
    Number(import.meta.env.VITE_FIREBASE_EMULATOR_STORAGE_PORT),
  );
} else {
  // Only run this if we are not using the emulators.

  // Global error handler for non-promise errors
  globalThis.onerror = (message, source, lineno, colno, error) => {
    logEvent(analytics, "exception", {
      description: `${message} at ${source}:${lineno}:${colno}`,
      fatal: true,
      error,
      message,
      source,
      lineNumber: lineno,
      columnNumber: colno,
    });
  };

  // Global error handler for unhandled Promise rejections
  globalThis.onunhandledrejection = (event) => {
    logEvent(analytics, "exception", {
      description: `Unhandled Rejection: ${event.reason}`,
      fatal: false,
      event,
    });
  };
}

// We are disabling Firestore persistence because of very poor
// performance.
// https://commsbylevels.com/threads/Xc1VHhMQ3yG5v02y6odCjd
//
// // We are only using single-tab persistence as a workaround for
// // https://github.com/levelshealth/comms/issues/397
// enableIndexedDbPersistence(db)
//   .then(() => console.debug("Offline persistence enabled in this tab."))
//   .catch((err: FirebaseError) => {
//     if (err.code === "failed-precondition") return;
//     console.error("Offline persistence failed.", err);
//   });

/**
 * Returns a reference to the callable HTTPS trigger with the given name
 * that has both argument and return value type safety.
 */
export const getTypedCallableFn = <T extends keyof ICallableFnsTypeMap>(
  name: T,
  options?: HttpsCallableOptions | undefined,
) => {
  return httpsCallable<ICallableFnsTypeMap[T][0], ICallableFnsTypeMap[T][1]>(
    functions,
    name,
    options,
  );
};
