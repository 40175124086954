import { css, cx } from "@emotion/css";
import { isEqual } from "@libs/utils/isEqual";
import { ComponentType, Fragment, MouseEventHandler } from "react";
import { distinctUntilChanged, map } from "rxjs";
import { List } from "~/components/list";
import { useListContext } from "~/components/list";
import { ICommandArgs } from "~/services/command.service";
import { ShortcutHintContents } from "~/services/hint-service";
import { useObservable } from "~/utils/useObservable";

export const CommandEntry: ComponentType<{
  currentPath: string[];
  command: Required<ICommandArgs>;
  mode: "hotkey" | "search";
  index?: number;
  onClick: MouseEventHandler<HTMLDivElement>;
}> = (props) => {
  const { command } = props;

  const listContext = useListContext();

  const isInCurrentPath = isEqual(command.path, props.currentPath);

  const isFocused = useObservable(
    () =>
      listContext.focusableOrActiveEntryId$.pipe(
        map((id) => id === props.command.id),
        distinctUntilChanged(),
      ),
    {
      synchronous: true,
      deps: [props.command.id],
    },
  );

  const entryId = `kbar-command-${command.id.replaceAll(" ", "_")}`;

  if (props.children) {
    return (
      <List.Entry id={command.id} data={command} relativeOrder={props.index}>
        <div
          id={entryId}
          className={entryCSS(isFocused)}
          onClick={props.onClick}
        >
          {props.children}
        </div>
      </List.Entry>
    );
  }

  return (
    <List.Entry id={command.id} data={command} relativeOrder={props.index}>
      <div id={entryId} className={entryCSS(isFocused)} onClick={props.onClick}>
        <div className="flex flex-1">
          {!isInCurrentPath && (
            <>
              <span className="inline-block max-w-[9rem] truncate text-slate-8">
                {command.path.map((segment, index) => {
                  return (
                    <Fragment key={index}>
                      <span>{segment}</span>

                      {index < command.path.length - 1 && (
                        <span className="mx-2">/</span>
                      )}
                    </Fragment>
                  );
                })}
              </span>

              <span className="text-slate-8 mx-2">/</span>
            </>
          )}

          <span className="flex-1">{command.label}</span>
        </div>

        {isInCurrentPath && command.hotkeys.length > 0 && (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          <ShortcutHint mode={props.mode} hint={command.hotkeys[0]!} />
        )}
      </div>
    </List.Entry>
  );
};

export const ShortcutHint: ComponentType<{
  hint: string;
  mode: "hotkey" | "search";
}> = (props) => {
  return (
    <div
      className={cx(
        "flex items-center",
        props.mode === "hotkey" ? "mr-4" : "ml-4",
      )}
    >
      <ShortcutHintContents
        hint={props.hint}
        keyClassName={cx(
          props.mode === "hotkey"
            ? "text-white bg-blue-9"
            : "text-black bg-transparent border border-slate-11",
        )}
        adverbClassName={cx(
          props.mode === "hotkey" ? "text-blue-9 font-medium" : "text-slate-10",
        )}
      />
    </div>
  );
};

export function entryCSS(isFocused: boolean) {
  return cx(_entryCSS, isFocused && "bg-slate-5 border-black");
}

const _entryCSS = cx(
  "px-8 py-3 border-l-2 border-white flex items-center",
  "leading-snug hover:cursor-pointer",
  css`
    .hotkey-mode & {
      flex-direction: row-reverse;
    }
  `,
);
