import {
  ComponentType,
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import {
  getDraftDataStorageKey,
  IDraft,
  mapRecipientOptionToDraftRecipient,
  unsendDraft,
  updateNewDraft,
  useSyncDraftBetweenTabs,
} from "~/services/draft.service";
import {
  IPostEditorControl,
  IRichTextEditorRef,
  onElementMouseDownFocusTiptap,
  PostEditor,
  PostEditorErrors,
} from "~/form-components/post-editor";
import { useControl } from "solid-forms-react";
import { observable, useControlState } from "~/form-components/utils";
import { sessionStorageService } from "~/services/session-storage.service";
import { DragTargetOverlay } from "~/components/DragTargetOverlay";
import { useImageDropHandlers } from "~/form-components/post-editor/extensions/image";
import { css, cx } from "@emotion/css";
import { toast } from "~/services/toast-service";
import dayjs from "dayjs";
import { filter, throttleTime } from "rxjs";
import { TThreadRecipientsRef } from "~/form-components/ThreadRecipients";
import { useSidebarLayoutContext } from "~/page-layouts/sidebar-layout";
import { slate } from "@radix-ui/colors";
import {
  tour,
  useConfigurePrivateMessageLesson,
} from "~/services/lesson-service/lessons/private-message-walkthrough";
import { useIsTourInProgress, useLesson } from "~/services/lesson-service";
import {
  useBottomScrollShadow,
  useTopScrollShadow,
} from "~/utils/useScrollShadow";
import { UnreachableCaseError } from "@libs/utils/errors";
import * as ThreadLayout from "~/page-layouts/thread-layout";
import { useSetBackgroundColor } from "~/services/theme.service";
import {
  cancelSaveNewDraft,
  DraftActions,
  makePrivateCommandCallback,
  useAddSharedChannelsOnRecipientChanges,
  useAutosaveDraft,
  useRegisterSharedComposeNewMessageCommands,
  useUpdateDraftTypeOnRecipientChanges,
  useUpdateRecipientsOnDraftTypeChanges,
  useUpdateRecipientsOnVisibilityChanges,
  useUpdateVisibilityOnRecipientChanges,
} from "../utils";
import {
  closeComposeNewThreadDialog,
  openComposeNewThreadDialog,
} from "../../page-dialog-state";
import { ComposeInfoPanel } from "../ComposeInfoPanel";
import { Header } from "../Header";
import {
  createComposeMessageForm,
  IComposeMessageForm,
  IComposeMessageFormValue,
  usePromptToRemoveRecipientOnMentionRemoval,
} from "~/components/ComposeMessageContext";
import {
  useAddNewChannelMentionsAsRecipients,
  useAddNewUserMentionsAsRecipients,
} from "~/components/ComposeReplyBase";
import { ThreadVisibility } from "@libs/firestore-models";
import { HeaderFields } from "./HeaderFields";

export const ComposeNewThread: ComponentType<{
  initialFormValues: IComposeMessageFormValue;
}> = (props) => {
  const control = useControl(() =>
    createComposeMessageForm(props.initialFormValues, {
      recipientsRequired: true,
    }),
  );

  useSetBackgroundColor(slate.slate3);

  const lesson = useLesson(tour.lessonName);
  const isLessonInProgress = useIsTourInProgress(tour);

  useConfigurePrivateMessageLesson();

  const treatLessonAsCompleted =
    isLessonInProgress ||
    lesson === undefined ||
    (lesson?.version === tour.lessonVersion && lesson?.completed);

  useUpdateDraftTypeOnRecipientChanges(control);
  useUpdateRecipientsOnDraftTypeChanges(control);
  useUpdateVisibilityOnRecipientChanges(control);
  useUpdateRecipientsOnVisibilityChanges(control);
  useAddSharedChannelsOnRecipientChanges({
    control,
    walkthroughNotCompleted: !treatLessonAsCompleted,
  });

  const editorRef = useRef<IRichTextEditorRef>(null);
  const toRecipientsRef = useRef<TThreadRecipientsRef>(null);

  useAutosaveDraft(control);

  const onClose = useCallback(() => {
    cancelSaveNewDraft(control.rawValue.postId);
    closeComposeNewThreadDialog();
  }, [control]);

  useSyncDraftBetweenTabs(control, editorRef, onClose);

  useRegisterSharedComposeNewMessageCommands({
    control,
    submit,
  });

  useFocusRecipientsWhenSidebarOutletIsFocused(toRecipientsRef);

  useHandleTourEvents({
    control,
  });

  useAddNewChannelMentionsAsRecipients(
    control.controls.body.controls.channelMentions,
    control.controls.recipients.controls.to,
  );

  useAddNewUserMentionsAsRecipients(
    control.controls.body.controls.userMentions,
    control.controls.recipients.controls.to,
  );

  usePromptToRemoveRecipientOnMentionRemoval(control);

  const {
    showDragTarget,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDrop,
    onPaste,
  } = useImageDropHandlers(editorRef, control.rawValue.postId);

  const visibility = useControlState(
    () => control.rawValue.visibility,
    [control],
  );

  const draftType = useControlState(() => control.rawValue.type, [control]);

  const scrollboxRef = useRef<HTMLDivElement>(null);
  const contentTopRef = useRef<HTMLDivElement>(null);
  const contentBottomRef = useRef<HTMLDivElement>(null);

  const calcTopScrollShadow = useTopScrollShadow({
    scrollboxRef,
    targetRef: contentTopRef,
  });

  const calcBottomScrollShadow = useBottomScrollShadow({
    scrollboxRef,
    targetRef: contentBottomRef,
  });

  // useTopScrollShadow and useBottomScrollShadow only reevaluate the need for
  // a shadow on scroll events. If a user deletes content from the editor
  // we may no longer need a scroll shadow but a scroll event will not have
  // been emitted. We handle this possibility here.
  useEffect(() => {
    const sub = observable(() => control.rawValue.body.content)
      .pipe(throttleTime(100, undefined, { leading: false, trailing: true }))
      .subscribe(() => {
        calcTopScrollShadow();
        calcBottomScrollShadow();
      });

    return () => sub.unsubscribe();
  }, [control, calcTopScrollShadow, calcBottomScrollShadow]);

  return (
    <>
      <main className="MainPanel md-w:mr-[280px] h-dynamic-screen flex flex-col">
        <Header control={control} isLessonComplete={treatLessonAsCompleted} />

        <ThreadLayout.ContentPanel className="mx-auto flex-1 flex flex-col overflow-hidden">
          <form
            onSubmit={(e) => e.preventDefault()}
            onDragOver={onDragOver}
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            onPaste={onPaste}
            className={cx(
              formCSS,
              visibility === "private" && "private-message",
            )}
          >
            {showDragTarget && (
              <DragTargetOverlay onDragLeave={onDragLeave}>
                Embed Image
              </DragTargetOverlay>
            )}

            <HeaderFields
              control={control}
              contentTopRef={contentTopRef}
              visibility={visibility}
              toRecipientsRef={toRecipientsRef}
              treatLessonAsCompleted={treatLessonAsCompleted}
            />

            <Content
              control={control}
              editorRef={editorRef}
              scrollboxRef={scrollboxRef}
            />

            <Footer
              ref={contentBottomRef}
              threadVisibility={visibility}
              draftType={draftType}
            />
          </form>

          <div className="h-8" />
        </ThreadLayout.ContentPanel>
      </main>

      <ComposeInfoPanel control={control} />
    </>
  );
};

function useFocusRecipientsWhenSidebarOutletIsFocused(
  threadRecipientsRef: RefObject<TThreadRecipientsRef>,
) {
  const { focusEvent$ } = useSidebarLayoutContext();

  useEffect(() => {
    const sub = focusEvent$
      .pipe(filter((e) => e === "Outlet"))
      .subscribe(() => {
        threadRecipientsRef.current?.focus();
      });

    return () => sub.unsubscribe();
  }, [threadRecipientsRef, focusEvent$]);
}

function useHandleTourEvents(args: { control: IComposeMessageForm }) {
  const { control } = args;
  const { focusEvent$ } = useSidebarLayoutContext();

  useEffect(() => {
    const sub = tour.event$.subscribe((e) => {
      switch (e) {
        case "set-shared": {
          if (control.rawValue.visibility === "shared") return;
          makePrivateCommandCallback(control);
          return;
        }
        case "set-private": {
          if (control.rawValue.visibility === "private") return;
          makePrivateCommandCallback(control);
          return;
        }
        case "focus-outlet": {
          focusEvent$.next("Outlet");
          return;
        }
        case "focus-sidebar": {
          focusEvent$.next("Sidebar");
          return;
        }
        default: {
          throw new UnreachableCaseError(e);
        }
      }
    });

    return () => sub.unsubscribe();
  }, [control, focusEvent$]);
}

const Footer = forwardRef<
  HTMLDivElement,
  { threadVisibility: ThreadVisibility | null; draftType: IDraft["type"] }
>((props, ref) => {
  return (
    <div
      ref={ref}
      className="flex p-4 sm-w:px-8 border-t border-mauve-5 space-x-3"
    >
      <DraftActions
        visibility={props.threadVisibility}
        draftType={props.draftType}
      />
    </div>
  );
});

const formCSS = cx(
  "flex flex-col flex-1 bg-white relative shadow-lg",
  "overflow-hidden",
  css`
    max-height: calc(100% - 2rem);

    input {
      background-color: transparent;
    }

    &.private-message {
      margin-top: 1rem;
    }
  `,
);

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  async (
    values: IComposeMessageFormValue,
    options: { sendImmediately?: boolean } = {},
  ) => {
    console.log("submitting...", values);

    if (values.visibility === null) {
      console.error("Attempted to send post with visibility === null");
      return;
    }

    // We want the new post form to close immediately without
    // waiting for this promise to resolve.
    // See `createNewDraft` jsdoc.
    updateNewDraft({
      type: values.type,
      postId: values.postId,
      branchedFrom: null,
      visibility: values.visibility,
      subject: values.subject,
      bodyHTML: values.body.content,
      to: values.recipients.to.map((r) =>
        mapRecipientOptionToDraftRecipient(r, values.type),
      ),
      cc: values.recipients.cc.map((r) =>
        mapRecipientOptionToDraftRecipient(r, values.type),
      ),
      bcc: values.recipients.bcc.map((r) =>
        mapRecipientOptionToDraftRecipient(r, values.type),
      ),
      channelMentions: values.body.channelMentions,
      userMentions: values.body.userMentions,
      scheduledToBeSentAt: options.sendImmediately
        ? new Date()
        : dayjs().add(20_000, "ms").toDate(),
    })
      .then(() => console.log("submitted successfully!"))
      .catch(console.error);

    const key = getDraftDataStorageKey(values.postId);

    sessionStorageService.setItem(key, {
      sent: true,
    });

    sessionStorageService.deleteItem(key);

    if (options.sendImmediately) {
      toast("vanilla", {
        subject: "Message sent!",
      });
    } else {
      toast("undo", {
        subject: "Message sent!",
        onAction: async () => {
          const success = await unsendDraft({ postId: values.postId });

          if (!success) return;

          openComposeNewThreadDialog(values.postId);
        },
      });
    }

    closeComposeNewThreadDialog();
  },
);

// Naming this component "Content" rather than "Body" because
// the placeholder is generated based on the field name and I
// think "Content..." makes a better placeholder than "Body..."
// and I want to name the component after the field name.
const Content: ComponentType<{
  control: IPostEditorControl;
  editorRef: RefObject<IRichTextEditorRef>;
  scrollboxRef: RefObject<HTMLDivElement>;
}> = (props) => {
  return (
    <div
      className="flex flex-col flex-1 overflow-y-auto px-4 min-h-[12rem]"
      onClick={(e) => onElementMouseDownFocusTiptap(e, props.editorRef)}
    >
      <PostEditor
        ref={props.editorRef}
        scrollboxRef={props.scrollboxRef}
        control={props.control}
      />

      <PostEditorErrors control={props.control} />
    </div>
  );
};
