import { IconButton } from "@mui/material";
import { capitalize } from "lodash-es";
import { ComponentType, forwardRef, PropsWithChildren } from "react";
import { MdClose } from "react-icons/md";
import {
  CLOSE_DRAFT_COMMAND_ID,
  IComposeMessageForm,
} from "~/components/ComposeMessageContext";
import { Tooltip } from "~/components/Tooltip";
import { callCommandById } from "~/services/command.service";
import { AvailableLessonBadge } from "~/services/lesson-service";
import {
  getClassForPrivateMessageTourStep,
  tour,
} from "~/services/lesson-service/lessons/private-message-walkthrough";
import * as ThreadLayout from "~/page-layouts/thread-layout";
import { useControlState } from "~/form-components/utils";
import { cx } from "@emotion/css";
import { Link } from "react-router-dom";
import { RiGitBranchLine } from "react-icons/ri";
import { ThreadVisibility } from "@libs/firestore-models";
import { BsLockFill } from "react-icons/bs";
import { SwitchCase } from "~/components/SwitchCase";
import { UnreachableCaseError } from "@libs/utils/errors";

export const Header = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    control: IComposeMessageForm;
    isLessonComplete?: boolean;
    branchedFromThreadId?: string;
    branchedThreadSubject?: string | null;
    branchedThreadVisibility?: ThreadVisibility;
  }>
>((props, ref) => {
  const threadType = useControlState(
    () => props.control.rawValue.type,
    [props.control],
  );

  const visibility = useControlState(
    () => props.control.rawValue.visibility,
    [props.control],
  );

  return (
    <ThreadLayout.Header
      ref={ref}
      theme={visibility === "private" ? "dark" : "light"}
    >
      <ThreadLayout.ContentPanel
        className={cx(
          "mx-auto flex pt-7 pb-6",
          getClassForPrivateMessageTourStep(7),
        )}
      >
        <div className="flex-1 flex flex-col px-6 sm-w:px-8">
          <div className="flex">
            <h1 className="compose-new-post-header text-2xl">
              New{" "}
              <VisibilityLabel
                visibility={visibility}
                isLessonComplete={props.isLessonComplete}
              />{" "}
              <SwitchCase deps={[threadType, props.branchedFromThreadId]}>
                {() => {
                  switch (threadType) {
                    case "COMMS": {
                      return props.branchedFromThreadId ? (
                        <>Branch</>
                      ) : (
                        <>Message</>
                      );
                    }
                    case "EMAIL": {
                      return props.branchedFromThreadId ? (
                        <>Email Branch</>
                      ) : (
                        <>Email</>
                      );
                    }
                    default: {
                      throw new UnreachableCaseError(threadType);
                    }
                  }
                }}
              </SwitchCase>
            </h1>

            <span className="flex-1" />

            <div className="flex justify-center items-center">
              <Tooltip side="bottom" content="Close">
                <span className="rounded-full group">
                  {/* Doesn't need to be tabbable because of hotkeys */}
                  <IconButton
                    tabIndex={-1}
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      callCommandById(CLOSE_DRAFT_COMMAND_ID);
                    }}
                    className="scale-125"
                  >
                    <span className="text-slate-9 group-hover:text-black">
                      <MdClose />
                    </span>
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </div>

          {props.branchedThreadSubject && props.branchedFromThreadId && (
            <div className="mt-2 flex items-center">
              <RiGitBranchLine size="1.5rem" className="mr-2" />

              {props.branchedThreadVisibility === "private" && (
                <BsLockFill size="1.2rem" className="mr-2" />
              )}

              <Link
                to={`/threads/${props.branchedFromThreadId}`}
                className="italic hover:underline truncate"
              >
                {props.branchedThreadSubject}
              </Link>
            </div>
          )}
        </div>
      </ThreadLayout.ContentPanel>
    </ThreadLayout.Header>
  );
});

const VisibilityLabel: ComponentType<{
  visibility?: null | "private" | "shared";
  isLessonComplete?: boolean;
}> = (props) => {
  if (!props.visibility) return null;
  if (props.isLessonComplete) {
    return <>{capitalize(props.visibility)}</>;
  }

  if (props.visibility === "private") {
    return (
      <Tooltip
        side="bottom"
        content="Click to learn about Shared and Private messages."
      >
        <span
          className="post-visibility-header text-violet-8 inline-flex"
          onClick={() => tour.start()}
        >
          {capitalize(props.visibility)}{" "}
          <AvailableLessonBadge
            className="relative ml-1 align-super text-[60%] pointer-events-none"
            inline
          />
        </span>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      side="bottom"
      content="Click to learn about Shared and Private messages."
    >
      <span className="text-violet-9" onClick={() => tour.start()}>
        {capitalize(props.visibility)}
        <AvailableLessonBadge
          className="relative ml-1 align-super text-[60%] pointer-events-none"
          inline
        />
      </span>
    </Tooltip>
  );
};
