import dayjs from "dayjs";

/**
 * Formats a dayjs datetime into a relative time string
 * like "tomorrow at 10am" or "today at 10am".
 */
export function convertDateTimeToRelativeString_DayAtTime(
  datetime: dayjs.Dayjs,
) {
  if (!datetime) return;

  const now = dayjs();

  const differenceInDays =
    datetime.startOf("day").diff(now.startOf("day"), "date") /
    MILLISECONDS_IN_DAY;

  let time = rtf.format(differenceInDays, "day");
  time += " at ";
  time +=
    datetime.get("minutes") === 0
      ? datetime.format("ha")
      : datetime.format("h:mma");

  return time;
}

export const MILLISECONDS_IN_HOUR = 1000 * 60 * 60;
export const MILLISECONDS_IN_DAY = MILLISECONDS_IN_HOUR * 24;

const rtf = new Intl.RelativeTimeFormat("en", {
  localeMatcher: "best fit",
  numeric: "auto",
  style: "long",
});
