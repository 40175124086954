import { cx } from "@emotion/css";
import { forwardRef, PropsWithChildren } from "react";

export const ContentPanel = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ className?: string }>
>((props, ref) => {
  return (
    <div
      ref={ref}
      className={cx(
        "ContentPanel max-w-[775px] min-w-0 w-full px-4 sm-w:px-6",
        props.className,
      )}
    >
      {props.children}
    </div>
  );
});
