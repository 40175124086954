import { IPostDoc } from "@libs/firestore-models";
import {
  ComponentType,
  Dispatch,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";
import { isEqual } from "@libs/utils/isEqual";
import { List } from "~/components/list";
import { cx } from "@emotion/css";
import { filter, Observable } from "rxjs";
import { CollapsedPost, ExpandedPost } from "~/components/thread-post-entry";

export const BranchedPostEntry: ComponentType<{
  post: IPostDoc;
  isLastPost: boolean;
  collapsePostEvents: Observable<"expand" | "collapse" | string>;
  relativeOrder: number;
}> = memo((props) => {
  const [isClosed, setIsClosed] = useState(props.isLastPost ? false : true);

  useHandleCollapsePostEvents({
    postId: props.post.id,
    collapsePostEvents: props.collapsePostEvents,
    setIsClosed,
  });

  const cssClasses = useMemo(() => {
    return isClosed
      ? cx(
          "Post flex p-4 sm-w:px-8 sm-w:py-4 my-1",
          "border-l-[3px] border-transparent bg-transparent",
          "focus:outline-none focus:border-black focus:bg-white focus:shadow-lg",
          "hover:cursor-pointer hover:outline-none hover:bg-white hover:shadow-lg",
        )
      : cx(
          "Post bg-white my-4 shadow-lg border-l-[3px] border-white",
          "focus:outline-none focus-within:border-black",
        );
  }, [isClosed]);

  return (
    <List.Entry<IPostDoc>
      id={props.post.id}
      data={props.post}
      relativeOrder={props.relativeOrder}
    >
      <div
        className={cssClasses}
        onKeyDown={(e) => {
          if (
            e.key !== "Enter" ||
            e.target instanceof HTMLAnchorElement ||
            e.target instanceof HTMLButtonElement
          ) {
            // if the user has focused an anchor or button element and they've
            // pressed "Enter", we don't want to collapse the entry.
            return;
          }

          setIsClosed((s) => !s);
        }}
        onClick={() => {
          if (!isClosed) return;

          setIsClosed(false);
        }}
      >
        {isClosed ? (
          <CollapsedPost post={props.post} />
        ) : (
          <ExpandedPost
            post={props.post}
            onHeaderClick={() => {
              setIsClosed(true);
            }}
          />
        )}
      </div>
    </List.Entry>
  );
}, isEqual);

function useHandleCollapsePostEvents(args: {
  postId: string;
  collapsePostEvents: Observable<"expand" | "collapse" | string>;
  setIsClosed: Dispatch<React.SetStateAction<boolean>>;
}) {
  const { postId, collapsePostEvents, setIsClosed } = args;

  useEffect(() => {
    const sub = collapsePostEvents
      .pipe(filter((e) => e === "expand" || e === "collapse" || e === postId))
      .subscribe((e) => {
        if (e === "expand" || e === "collapse") {
          setIsClosed(e === "collapse");
        } else {
          setIsClosed(false);
        }
      });

    return () => sub.unsubscribe();
  }, [postId, collapsePostEvents, setIsClosed]);
}
