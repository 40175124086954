import { cx } from "@emotion/css";
import { ComponentType } from "react";

export const NotificationCountBadge: ComponentType<{
  count: number;
  className?: string;
}> = ({ count, className = "" }) => {
  return (
    <span
      className={cx(
        "notification-count",
        "flex rounded mr-3 py-[2px] px-2 group-hover:hidden",
        "justify-center items-center",
        className,
      )}
    >
      {count}
    </span>
  );
};
