import { Observable } from "rxjs";
import { useObservable } from "~/utils/useObservable";
import { ComponentType, RefObject, useEffect } from "react";
import {
  createFormControl,
  createFormGroup,
  useControl,
} from "solid-forms-react";
import { observable } from "~/form-components/utils";
import { navigateService } from "~/services/navigate.service";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { ISearchEditorRef } from "~/form-components/search-editor/SearchEditorBase";
import { ISearchEditorProps } from "~/form-components/search-editor/SearchEditor";
import { ParsedToken } from "@libs/utils/searchQueryParser";
import { useGetSearchQueryFromURL } from "./useGetSearchQueryFromURL";
import { onlySearchSeenPostsControl } from "./state";
import { getParsedToken_IsSeen, useSearch } from "~/services/search-service";
import { graphql } from "~/gql";
import { uniqBy } from "lodash-es";
import { getPost, getThread } from "~/services/post.service";
import { isNonNullable } from "@libs/utils/predicates";
import { stringComparer, timestampComparer } from "@libs/utils/comparers";
import { throwUnreachableCaseError } from "@libs/utils/errors";

/* -------------------------------------------------------------------------------------------------
 * useAndInitializeSearch
 * -----------------------------------------------------------------------------------------------*/

export function useAndInitializeSearch(args: {
  editorRef: RefObject<ISearchEditorRef>;
}) {
  const { editorRef } = args;

  const searchControl = useControl(() =>
    createFormGroup({
      queryHTML: createFormControl(""),
      queryText: createFormControl(""),
      parsedQuery: createFormControl([]),
    }),
  );

  const { queryAsHTML, queryAsPlainText } = useGetSearchQueryFromURL();

  const {
    searchResults,
    isSearchPending,
    endOfSearch$,
    setSearchQuery,
    onBottomOfPageInView,
  } = useSearch({
    gqlQueryWithFullTextSearch: SEARCH_QUERY,
    gqlQueryWithoutFullTextSearch: FILTER_QUERY,
    queryLimit: QUERY_LIMIT,
    mapApolloQueryResultData(data) {
      return {
        size: data?.messages.length || 0,
        data: (async () => {
          const results = uniqBy(
            data?.messages.map((m) =>
              m.type === "COMMS"
                ? m
                : m.type === "EMAIL"
                ? m
                : m.type === "EMAIL_SECRET"
                ? {
                    firestoreId: m.typeSpecificJson.forPostId,
                    threadFirestoreId: m.typeSpecificJson.forThreadId,
                  }
                : throwUnreachableCaseError(m.type),
            ) || [],
            (m) => m.threadFirestoreId,
          );

          const threads = await Promise.all(
            results.map(async (r) => {
              const [thread, message] = await Promise.all([
                getThread(r.threadFirestoreId),
                getPost(r.firestoreId),
              ]);

              if (!thread || !message) return null;

              return {
                ...thread,
                __local: {
                  fromPost: message,
                },
              };
            }),
          );

          return threads
            .filter(isNonNullable)
            .sort(
              (a, b) =>
                timestampComparer(
                  b.__local.fromPost.sentAt,
                  a.__local.fromPost.sentAt,
                ) ||
                timestampComparer(
                  b.__local.fromPost.scheduledToBeSentAt,
                  a.__local.fromPost.scheduledToBeSentAt,
                ) ||
                stringComparer(a.id, b.id),
            );
        })(),
      };
    },
  });

  // Important to run this hook before we focus and select the input
  // which happens in useFocusSearchInputIfNoResults.
  useSetSearchInput({
    editorRef,
    searchControl,
    setSearchQuery,
    queryAsHTML,
  });

  useInitializeControl_OnlySearchSeenPosts();

  return {
    searchControl,
    queryAsPlainText,
    searchResults,
    isSearchPending,
    endOfSearch$,
    onBottomOfPageInView,
  };
}

/* -------------------------------------------------------------------------------------------------
 * performSearch
 * -----------------------------------------------------------------------------------------------*/

export function performSearch(query: string) {
  if (!query) {
    navigateService(`/search`, {
      replace: true,
    });

    return;
  }

  navigateService(`/search?q=${encodeURIComponent(query)}`, {
    replace: true,
  });
}

/* -----------------------------------------------------------------------------------------------*/

/** Important to run this hook before we focus and select the input. */
function useSetSearchInput(args: {
  editorRef: RefObject<ISearchEditorRef>;
  searchControl: ISearchEditorProps["control"];
  setSearchQuery: (parsedQuery: ParsedToken[]) => void;
  queryAsHTML: string | null;
}) {
  const { editorRef, searchControl, setSearchQuery, queryAsHTML } = args;

  useEffect(() => {
    if (!editorRef.current) return;
    if (queryAsHTML === null) return;

    const sub = editorRef.current.onCreate$.subscribe((editor) => {
      editor.commands.setContent(queryAsHTML, true, {
        preserveWhitespace: true,
      });

      // setting the editor content will update the searchControl
      // syncronously
      const isValidSearch = searchControl.isValid;
      const parsedQuery = searchControl.rawValue.parsedQuery;
      const onlySearchSeenPosts = onlySearchSeenPostsControl.value;

      if (!isValidSearch || parsedQuery.length === 0) {
        setSearchQuery([]);
      } else if (onlySearchSeenPosts) {
        setSearchQuery([...parsedQuery, getParsedToken_IsSeen()]);
      } else {
        setSearchQuery(parsedQuery);
      }
    });

    return () => sub.unsubscribe();
  }, [editorRef, searchControl, setSearchQuery, queryAsHTML]);

  useEffect(() => {
    const sub = observable(() => onlySearchSeenPostsControl.value).subscribe(
      (onlySearchSeenPosts) => {
        const isValidSearch = searchControl.isValid;
        const parsedQuery = searchControl.rawValue.parsedQuery;

        if (!isValidSearch || parsedQuery.length === 0) {
          setSearchQuery([]);
        } else if (onlySearchSeenPosts) {
          setSearchQuery([...parsedQuery, getParsedToken_IsSeen()]);
        } else {
          setSearchQuery(parsedQuery);
        }
      },
    );

    return () => sub.unsubscribe();
  }, [searchControl, setSearchQuery]);
}

/* -----------------------------------------------------------------------------------------------*/

function useInitializeControl_OnlySearchSeenPosts() {
  const { currentUser } = useAuthGuardContext();

  useEffect(() => {
    const localStorageKey = `${currentUser.id}.search.filterOnOnlySeenPosts`;

    const onlySeenPosts = localStorage.getItem(localStorageKey);

    onlySearchSeenPostsControl.setValue(onlySeenPosts !== "false");

    const sub = observable(() => onlySearchSeenPostsControl.value).subscribe(
      (val) => {
        localStorage.setItem(localStorageKey, JSON.stringify(val));
      },
    );

    return () => sub.unsubscribe();
  }, [currentUser.id]);

  return onlySearchSeenPostsControl;
}

/* -------------------------------------------------------------------------------------------------
 * IndicateIfNoMoreSearchResults
 * -----------------------------------------------------------------------------------------------*/

export const IndicateIfNoMoreSearchResults: ComponentType<{
  endOfSearch$: Observable<boolean>;
}> = (props) => {
  const isEndOfSearch = useObservable(() => props.endOfSearch$, {
    deps: [props.endOfSearch$],
  });

  if (!isEndOfSearch) return null;

  return (
    <div className="uppercase text-slate-9 mt-8 flex-1 flex justify-center">
      end
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const gqlMessageFields = `
  firestoreId
  bodyText
  bodyHtml
  sentAt
  scheduledToBeSentAt

  sender {
    firestoreId
    name
  }
`;

/* -----------------------------------------------------------------------------------------------*/

// This fragment is automatically added to the SEARCH_QUERY and FILTER_QUERY's
// by graphql-codegen (i.e. it is used, even if it doesn't appear to be).
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SEARCH_THREAD_FRAGMENT = graphql(`
  fragment SearchThreadFields on Threads {
    firestoreId
    visibility
    subject

    firstMessage {
      firestoreId
      sender {
        firestoreId
        name
      }
    }

    lastMessage {
      firestoreId
      sentAt
      scheduledToBeSentAt
      bodyText
    }

    distinctSenders(args: { sort_order: "DESC" }, limit: 4) {
      firestoreId
      name
    }

    inboxNotifications {
      userFirestoreId
      threadFirestoreId
      hasReminder
      remindAt
      doneLastModifiedBy
    }
  }
`);

/* -----------------------------------------------------------------------------------------------*/

const QUERY_LIMIT = 50;

// /* -----------------------------------------------------------------------------------------------*/

// /** Used when there is a search query string */
// const SEARCH_QUERY = graphql(`
//   query FullTextSearchQuery(
//     $query: String!
//     $threadsWhere: ThreadsBoolExp
//     $offset: Int!
//     $limit: Int!
//   ) {
//     threads: searchThreadsSortedSentAtDesc(
//       args: { search: $query }
//       where: $threadsWhere
//       offset: $offset
//       limit: $limit
//     ) {
//       ...SearchThreadFields
//     }
//   }
// `);

// // /** Used when there is a search query string */
// // const SEARCH_QUERY = gql`
// //   query MySearchQuery(
// //     $query: String!,
// //     $where: ThreadsBoolExp,
// //     $messagesWhere: MessagesBoolExp,
// //     $offset: Int!
// //   ) {
// //     threads: searchThreads(
// //       args: { search: $query }
// //       where: $where
// //       orderBy: [{ lastMessageSentAt: DESC }, { lastMessageScheduledToBeSentAt: DESC }]
// //       offset: $offset
// //       limit: ${QUERY_LIMIT}
// //     ) {
// //       ${gqlThreadFields}

// //       # messages: searchMessages(
// //       #   args: { search_query: $query }
// //       #   where: $messagesWhere,
// //       #   orderBy: [{ sentAt: DESC }, { scheduledToBeSentAt: DESC }]
// //       #   limit: 3
// //       # ) {
// //       #   ${gqlMessageFields}
// //       # }
// //     }
// //   }
// // `;

// /* -----------------------------------------------------------------------------------------------*/

// /**
//  * Performance optimized variation.
//  * Used when there is a search query string and we're filtering
//  * only on seen docs.
//  */
// const SEARCH_SEEN_QUERY = graphql(`
//   query SeenFullTextSearchQuery(
//     $query: String!
//     $threadsWhere: ThreadsBoolExp
//     $offset: Int!
//     $limit: Int!
//   ) {
//     threads: searchSeenThreadsSortedSentAtDesc(
//       args: { search: $query }
//       where: $threadsWhere
//       offset: $offset
//       limit: $limit
//     ) {
//       ...SearchThreadFields
//     }
//   }
// `);

// /* -----------------------------------------------------------------------------------------------*/

// /** Used when there is NOT a search query string */
// const FILTER_QUERY = graphql(`
//   query NonFullTextSearchQuery(
//     $threadsWhere: ThreadsBoolExp!
//     $offset: Int!
//     $limit: Int!
//   ) {
//     threads(
//       where: $threadsWhere
//       orderBy: [
//         { lastMessageSentAt: DESC }
//         { lastMessageScheduledToBeSentAt: DESC }
//       ]
//       offset: $offset
//       limit: $limit
//     ) {
//       ...SearchThreadFields
//     }
//   }
// `);

// // /** Used when there is NOT a search query string */
// // const FILTER_QUERY = gql`
// //   query MyFilterQuery(
// //     $where: ThreadsBoolExp!,
// //     $messagesWhere: MessagesBoolExp,
// //     $offset: Int!
// //   ) {
// //     threads(
// //       where: $where
// //       orderBy: [{ lastMessageSentAt: DESC }, { lastMessageScheduledToBeSentAt: DESC }]
// //       offset: $offset
// //       limit: ${QUERY_LIMIT}
// //     ) {
// //       ${gqlThreadFields}

// //       messages(
// //         where: $messagesWhere,
// //         orderBy: [{ sentAt: DESC }, { scheduledToBeSentAt: DESC }]
// //         limit: 3
// //       ) {
// //         ${gqlMessageFields}
// //       }
// //     }
// //   }
// // `;

// /* -----------------------------------------------------------------------------------------------*/

/* -----------------------------------------------------------------------------------------------*/

/**
 * Used when there is a search query string.
 * We've found that search is *much* faster if we return message ids and then manually
 * join them with the threadIds on the client side vs doing this in the database.
 */
const SEARCH_QUERY = graphql(`
  query FullTextSearchQuery(
    $query: String!
    $messagesWhere: MessagesBoolExp
    $offset: Int!
    $limit: Int!
  ) {
    messages: searchMessages(
      args: { search: $query }
      where: $messagesWhere
      orderBy: [{ sentAt: DESC }, { scheduledToBeSentAt: DESC }]
      offset: $offset
      limit: $limit
    ) {
      firestoreId
      threadFirestoreId
      type
      typeSpecificJson
    }
  }
`);

/* -----------------------------------------------------------------------------------------------*/

/** Used when there is NOT a search query string */
const FILTER_QUERY = graphql(`
  query NonFullTextSearchQuery(
    $messagesWhere: MessagesBoolExp!
    $offset: Int!
    $limit: Int!
  ) {
    messages(
      where: $messagesWhere
      orderBy: [{ sentAt: DESC }, { scheduledToBeSentAt: DESC }]
      offset: $offset
      limit: $limit
    ) {
      firestoreId
      threadFirestoreId
      type
      typeSpecificJson
    }
  }
`);

/* -----------------------------------------------------------------------------------------------*/
