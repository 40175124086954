import { Constructor } from "type-fest";
import { EmailAddress } from "./email-rfc";

export function isNonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export function isDefined<T>(value: T): value is Exclude<T, undefined> {
  return value !== undefined;
}

/**
 * This function just returns its argument without doing anything.
 * It exists to solely to improve typescript ergonomics.
 */
export function castToNonNullablePredicate<T>(
  fn: (item: T, index: number) => unknown,
) {
  return fn as (item: T, index: number) => item is NonNullable<T>;
}

export function isNonNullObject<T extends object = Record<string, unknown>>(
  value: unknown,
): value is T {
  return typeof value === "object" && value !== null;
}

export function isInstanceOf<T>(clazz: Constructor<T>) {
  return ((value) => value instanceof clazz) as (value: unknown) => value is T;
}

export function isAddressIncludedInEmailAddresses(
  address: string,
  emailAddresses: EmailAddress[],
  caseInsensitive = false,
) {
  if (caseInsensitive) {
    address = address.toLowerCase();

    return emailAddresses.some((email) =>
      email.addresses
        ? email.addresses.some((m) => m.address.toLowerCase() === address)
        : email.address.toLowerCase() === address,
    );
  }

  return emailAddresses.some((email) =>
    email.addresses
      ? email.addresses.some((m) => m.address === address)
      : email.address === address,
  );
}
