import { css, cx } from "@emotion/css";
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ComponentType,
  useMemo,
} from "react";
import { SuggestionProps } from "@tiptap/suggestion";
import { onSuggestionDialogClose, onSuggestionDialogOpen } from "../utils";
import { Avatar } from "~/components/Avatar";
import { IAcceptedOrganizationMemberDoc } from "~/services/organization.service";
import { IChannelDocWithCurrentUserData } from "~/services/channels.service";
import { BsLockFill } from "react-icons/bs";
import { usePostEditorContext } from "../../context";
import { useControlState } from "~/form-components/utils";
import { ThreadVisibility } from "@libs/firestore-models";
import { oneLineTrim } from "common-tags";

/**
 * This code was largely taken from https://tiptap.dev/api/nodes/mention#usage
 */

type WithIndex<T> = T & { index: number };

type IMentionItem = WithIndex<
  IAcceptedOrganizationMemberDoc | IChannelDocWithCurrentUserData
>;

interface IMentionAttributes {
  id: string;
  label: string;
  subject: string;
}

export const MentionDropdown = forwardRef<
  { onKeyDown(o: { event: KeyboardEvent }): boolean | undefined },
  SuggestionProps<IMentionItem>
>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { control } = usePostEditorContext();

  const visibility =
    useControlState(() => control.rawValue.visibility, [control]) || "shared";

  useEffect(() => {
    // We need to track whether a mentioned list is opened or not
    // for the EditorOverflowHandler. See `EditorOverflowHandler.ts`
    // for more info.
    onSuggestionDialogOpen();

    return onSuggestionDialogClose;
  });

  const [people, channels] = useMemo(() => {
    return props.items.reduce(
      (store, curr) => {
        if (curr.__docType === "IOrganizationMemberDoc") {
          store[0].push(curr);
        } else {
          store[1].push(curr);
        }

        return store;
      },
      [
        [] as WithIndex<IAcceptedOrganizationMemberDoc>[],
        [] as WithIndex<IChannelDocWithCurrentUserData>[],
      ],
    );
  }, [props.items]);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (!item) return;

    const mentionProps: IMentionAttributes = {
      id: item.id,
      label: item.__docType === "IChannelDoc" ? item.name : item.user.name,
      subject: item.__docType === "IChannelDoc" ? "channel" : "user",
    };

    // The SuggestionProps are typed as requiring props.command to
    // receive the same type as props.items[0] but, in actually,
    // they don't need to be the same and we aren't passing the
    // same interface.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props.command(mentionProps as any);
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length,
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = (e: Event) => {
    const item = props.items[selectedIndex];

    if (!item) return;

    if (item.__docType === "IOrganizationMemberDoc") {
      selectItem(selectedIndex);
      return;
    }

    const isDisabled =
      item.classification === "public"
        ? visibility === "private"
        : visibility === "shared";

    if (isDisabled) {
      alert(oneLineTrim`
        Cannot add ${item.classification} channel to 
        ${visibility} thread.
      `);

      e.preventDefault();
    } else {
      selectItem(selectedIndex);
    }
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler(event);
        return true;
      }

      return false;
    },
  }));

  return (
    <div
      className={cx(
        "bg-white rounded overflow-hidden",
        `w-[330px] pb-3`,
        mentionListStyles,
      )}
    >
      <PeopleList
        people={people}
        selectedIndex={selectedIndex}
        selectItem={selectItem}
      />

      {people.length > 0 && channels.length > 0 && (
        <hr className="text-slate-5 mt-3 mb-1" />
      )}

      <ChannelList
        channels={channels}
        selectedIndex={selectedIndex}
        visibility={visibility}
        selectItem={selectItem}
      />
    </div>
  );
});

const mentionListStyles = css`
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
`;

const PeopleList: ComponentType<{
  people: WithIndex<IAcceptedOrganizationMemberDoc>[];
  selectedIndex: number;
  selectItem: (index: number) => void;
}> = (props) => {
  if (props.people.length === 0) return null;

  return (
    <SectionWrapper>
      <SectionHeader label="People" />

      {props.people.map((person) => (
        <Entry
          key={person.id}
          item={person}
          isSelected={props.selectedIndex === person.index}
          onClick={() => props.selectItem(person.index)}
        >
          <Avatar
            label={person.user.name}
            photoURL={person.user.photoURL}
            fontSize="11px"
            width="1.5rem"
            className="mx-3"
          />
          <span className="truncate">{person.user.name}</span>
        </Entry>
      ))}
    </SectionWrapper>
  );
};

const ChannelList: ComponentType<{
  channels: WithIndex<IChannelDocWithCurrentUserData>[];
  selectedIndex: number;
  visibility: ThreadVisibility;
  selectItem: (index: number) => void;
}> = (props) => {
  if (props.channels.length === 0) return null;

  return (
    <SectionWrapper>
      <SectionHeader label="Channels" />

      {props.channels.map((channel) => {
        const isDisabled =
          channel.classification === "public"
            ? props.visibility === "private"
            : props.visibility === "shared";

        return (
          <Entry
            key={channel.id}
            item={channel}
            isSelected={props.selectedIndex === channel.index}
            isDisabled={isDisabled}
            onClick={(e) => {
              if (isDisabled) {
                alert(oneLineTrim`
                  Cannot add ${channel.classification} channel to 
                  ${props.visibility} thread.
                `);

                e.preventDefault();
              } else {
                props.selectItem(channel.index);
              }
            }}
          >
            <span className="ml-3">#</span>
            <span className="inline-flex items-center shrink whitespace-nowrap overflow-hidden">
              <span className="truncate">{channel.name}</span>
              {channel.classification === "private" && (
                <BsLockFill className="ml-1 scale-75" />
              )}
            </span>
            <span className="ml-1 text-slate-9 truncate flex-1">
              (
              {channel.__local.knownChannelGroups.map((g) => g.name).join(", ")}
              )
            </span>
          </Entry>
        );
      })}
    </SectionWrapper>
  );
};

const SectionWrapper: ComponentType<{}> = (props) => {
  return <div className="flex flex-col">{props.children}</div>;
};

const SectionHeader: ComponentType<{
  label: string;
}> = (props) => {
  return (
    <h4 className="text-xs uppercase text-slate-9 font-medium mx-4 my-2">
      {props.label}
    </h4>
  );
};

const Entry: ComponentType<{
  item: { id: string };
  isSelected: boolean;
  isDisabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}> = (props) => {
  return (
    <button
      type="button"
      className={cx(
        "flex items-center text-left mx-1 pr-3 py-1 rounded",
        "relative text-sm",
        props.isSelected ? "bg-slate-5" : "bg-transparent",
        props.isDisabled && "text-slate-9",
      )}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};
